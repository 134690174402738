import { Button } from "@mui/material";
import { Window } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { insertImageFile } from "./../utils";

import { ALLOWED_IMAGE_EXTENSION, MB, MEGA } from "../../../constants/fileUpload";
import { SUCCESS_STATUS_CODE } from "../../../constants/http";
import { imageLockSubject } from "../../../hooks/richtext";
import { showError } from "../../../hooks/toast";
import "./InsertImageDialog.scss";

const INITIAL_WIDTH = 500;
const INITIAL_HEIGHT = 325;

const STYLE_CLEAR = "both";
const STYLE_MARGIN_TOP = "15px";

export const InsertImageDialog = (props) => {
  const { view, imageNode, uploadUrl, getResultUrl, richTextConfiguration, uid } = props;
  const [fileUrl, setFileUrl] = React.useState(null);
  const { t } = useTranslation();

  const richTextImageLock = imageLockSubject.getValue();

  const onClose = () => {
    props.onClose.call(undefined);
  };

  const onInsert = () => {
    const position = null;

    if (fileUrl) {
      insertImageFile({
        view,
        fileUrl,
        position,
        attrs,
        richTextConfiguration,
        uid,
      });
    }

    view.focus();
    onClose();
  };

  const state = view?.state;

  let attrs = {};

  if (state?.selection?.node && state.selection.node.type === state.schema.nodes[imageNode]) {
    attrs = state.selection.node.attrs;
  }

  const onFileAdd = (event) => {
    const { newState } = event;
    for (const stateOnFileAdd of newState) {
      const { validationErrors } = stateOnFileAdd;

      if (validationErrors?.includes("invalidFileExtension")) {
        showError(t("richtext.extensionError"));
      }

      if (validationErrors?.includes("invalidMaxFileSize")) {
        showError(t("richtext.fileSizeError") + `${richTextConfiguration?.imageSizeLimit}${MB}`);
      }
    }
  };

  const onStatusChange = (event) => {
    const { status, response } = event.response;
    if (status === SUCCESS_STATUS_CODE) {
      const url = getResultUrl(response.id);
      setFileUrl(url);
    }
  };

  const restrictions = {
    allowedExtensions: ALLOWED_IMAGE_EXTENSION,
  };

  if (richTextConfiguration?.imageSizeLimit >= 0) {
    restrictions.maxFileSize = richTextConfiguration?.imageSizeLimit * MEGA;
  }

  return ReactDOM.createPortal(
    <Window
      title={t("richtext.uploadWindowTitle")}
      onClose={onClose}
      initialWidth={INITIAL_WIDTH}
      initialHeight={INITIAL_HEIGHT}
      resizable={false}
      className="insert-image-dialog"
    >
      <div>
        <Upload
          batch={false}
          multiple={false}
          withCredentials={true}
          saveUrl={uploadUrl}
          saveField="file"
          onStatusChange={onStatusChange}
          onAdd={onFileAdd}
          defaultFiles={[]}
          disabled={richTextImageLock}
          restrictions={restrictions}
        />
        <div>
          <small>{t("richtext.allowedExtensions")}</small>
        </div>
        {richTextConfiguration?.imageSizeLimit >= 0 && (
          <div>
            <small>
              {t("richtext.maximumFileSize")} {richTextConfiguration?.imageSizeLimit}
              {MB}
            </small>
          </div>
        )}
      </div>
      {
        <div
          className={"text-right"}
          style={{
            clear: STYLE_CLEAR,
            marginTop: STYLE_MARGIN_TOP,
          }}
        >
          <Button onClick={onClose}>{t("richtext.cancel")}</Button>
          <Button color="primary" onClick={onInsert} disabled={fileUrl === null}>
            {t("richtext.insert")}
          </Button>
        </div>
      }
    </Window>,
    document.body
  );
};
