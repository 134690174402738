import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FILE_UPLOAD_RESTRICTIONS, MAX_FILE_SIZE_MB } from "../../../constants/fileUpload";
import {
  getFileSizeErrors,
  getRestrictedFileExtension,
  getRestrictedFileSize,
  validateFileRestrictions,
} from "../../../helpers/fileUpload";
import { resetUploadingFiles, useUploadingFiles } from "../../../hooks/fileUpload";
import { kpiRatingSystemSubject } from "../../../hooks/kpiRatingSystem";
import { getProjectFileUrl, getProjectFiles, useProjectFilesResponse } from "../../../hooks/project";
import {
  addRatingReasonFile,
  ratingReasonFilesSubject,
  removeRatingReasonFile,
  setRatingReasonFiles,
  uploadRatingReasonFile,
  uploadRatingReasonFileKey,
  useRatingReasonFiles,
} from "../../../hooks/ratingIndicatorFiles";
import { showError } from "../../../hooks/toast";
import { useEvent } from "../../../hooks/utils/useEvent";
import { FilesTable } from "../../FilesTable/FilesTable";
import { KPIControl } from "../../KPIControl/KPIControl";
import { LinkDialog } from "../../LinkDialog/LinkDialog";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { EvaluationReason } from "../../RatingSystem/EvaluationReason/EvaluationReason";
import { SelectFileDialog } from "../../SelectFileDialog/SelectFileDialog";

import { loadMessages } from "@progress/kendo-react-intl";
import i18next from "i18next";
import { addBreakLines, adjustRGBFormat, removeExtraBreakLines } from "../../../helpers/richtext";
import kendoDeMessages from "./../../../locales/kendo-de.json";
import kendoEnMessages from "./../../../locales/kendo-en.json";

loadMessages(kendoEnMessages, "en");
loadMessages(kendoDeMessages, "de");

export const CurrentBlock = ({
  onChange,
  row,
  readOnly,
  projectId,
  kpiRatingSystemId,
  permissions,
  restrictions = FILE_UPLOAD_RESTRICTIONS,
}) => {
  const { t } = useTranslation();
  const data = row || {};
  const { UID } = data;
  const [reasonState, setReasonState] = useState("");
  const [links, setLinks] = useState([]);
  const [kpi, setKpi] = useState(null);
  const files = useRatingReasonFiles();
  const [popoverTarget, setPopoverTarget] = useState(null);
  const [selectFileOpen, setSelectFileOpen] = useState(null);
  const [linkDialog, setLinkDialog] = useState(null);
  const [fileValidationError, setFileValidationError] = useState(false);
  const uploadInputRef = useRef(null);
  const uploadingFiles = useUploadingFiles().filter((item) => item.key === uploadRatingReasonFileKey);
  const projectFilesResponse = useProjectFilesResponse();
  const evaluationStandardLinks = data.links || [];
  const evaluationStandardFiles = data.files || [];
  const accessRestricted = kpiRatingSystemSubject.getValue()?.data?.accessRestricted;
  const isViewer = !accessRestricted || !!permissions.permissionReader;
  const messages = i18next.language === "de" ? kendoDeMessages : kendoEnMessages;

  useEffect(() => {
    const { kpi: kpiInner } = data || {};
    setRatingReasonFiles(data.evaluationFiles || []);
    setLinks(data.evaluationLinks || []);
    setKpi(kpiInner);
    resetUploadingFiles();
    setReasonState(data.reason ?? "");
  }, [UID]);

  const saveChanges = useEvent((updatedLinks, updatedKpi) => {
    onChange({
      UID,
      fields: {
        reason: reasonState,
        kpi: updatedKpi || kpi,
      },
      links: updatedLinks || links,
      files: ratingReasonFilesSubject.getValue(),
    });
  });

  const saveChangesReason = (reasonEditorValue) => {
    let reason = reasonState;
    if (reasonEditorValue) {
      const { event } = reasonEditorValue;
      let html = event?.html ?? "";

      html = adjustRGBFormat(html);
      html = addBreakLines(html);
      html = removeExtraBreakLines(html);

      reason = html;
      setReasonState(reason);
    }
    saveChanges();
  };

  const changeLink = (dataChangeLink) => {
    if (linkDialog === true) {
      const updated = [...links, dataChangeLink];
      setLinks(updated);
      saveChanges(updated);
    } else {
      const updated = [...links];
      updated[linkDialog] = dataChangeLink;
      setLinks(updated);
      saveChanges(updated);
    }
    setLinkDialog(null);
  };

  const deleteLink = (index) => {
    const updated = [...links];
    updated.splice(index, 1);
    setLinks(updated);
    saveChanges(updated);
  };

  const onFileSelect = (event) => {
    const errors = getFileSizeErrors(event, MAX_FILE_SIZE_MB);
    if (!errors) {
      [...event.target.files].forEach((file) => {
        const validationError = validateFileRestrictions(file, restrictions);
        setFileValidationError(!!validationError);
        if (!validationError) {
          uploadRatingReasonFile(projectId, file)
            .then((response) => {
              addRatingReasonFile({ fileId: response.id, name: response.originalName });
              saveChanges();
            })
            .catch((error) => {
              if (error && error.type !== "abort") {
                if (error.status === 409) {
                  showError(t("file.error.409"));
                } else {
                  showError(t("error.500"));
                }
              }
            });
        } else {
          showError(messages[`upload.${validationError}`]);
        }
      });
      uploadInputRef.current.value = "";
    } else {
      let errorMessage = "";
      errors.forEach((error) => {
        errorMessage +=
          t("file.tooLarge", {
            fileName: error.fileName,
            fileSize: error.fileSize,
          }) + "\n";
      });
      errorMessage += t("file.maxSize", { maxFileSize: MAX_FILE_SIZE_MB });
      showError(errorMessage);
    }
  };

  const removeFile = (file) => {
    removeRatingReasonFile(filesProcessed.findIndex((item) => item?.fileId === file?.fileId));
    saveChanges();
  };

  const oneProjectFileSelect = (value) => {
    addRatingReasonFile({ fileId: value.id, name: value.name, userTags: value.userTags || [] });
    saveChanges();
  };

  const onKpiChange = ({ value, estimation }) => {
    const updated = { ...kpi, currentValue: value, estimation };
    setKpi(updated);
    saveChanges(null, updated);
  };

  const postFilesLoading = !!uploadingFiles.length;
  const filesProcessed = (files || []).map((item) => ({
    ...item,
    editable: true,
    userTags: item.userTags || [],
  }));

  const availableFiles = (projectFilesResponse.data || []).filter((file) => {
    return !filesProcessed.find((item) => item.fileId === file.id);
  });

  return (
    <LoadingOverlay spinner className="kpi-rating-reason-dialog rating-reason-dialog auto-height">
      {!!kpi && (
        <>
          <div className="padding-bottom">
            <h4 className="header no-margin">{t("ratingSystem.columnGroup.kpi")}</h4>
            <KPIControl
              autoFocus
              kpiUnit={kpi.unit}
              kpiName={kpi.name}
              value={kpi.currentValue}
              estimation={kpi.estimation}
              onChange={onKpiChange}
              readOnly={readOnly}
              linkedGeneralAttributeName={kpi?.linkedGeneralAttributeName}
            />
          </div>
        </>
      )}
      <>
        {!!isViewer && !!evaluationStandardLinks.length && (
          <>
            <h4 className="links-header">{t("grouping.links")}</h4>
            <div className="links chips">
              {evaluationStandardLinks.map((item, index) => (
                <Chip
                  key={index}
                  clickable={true}
                  onClick={() => {
                    window.open(item.value);
                  }}
                  label={item.title || item.value}
                />
              ))}
            </div>
          </>
        )}
        {!!isViewer && !!evaluationStandardFiles.length && (
          <>
            <h4 className="links-header">{t("grouping.files")}</h4>
            <div className="links chips">
              {evaluationStandardFiles.map((item, index) => (
                <Chip
                  key={index}
                  clickable={true}
                  onClick={() => {
                    window.open(`/api/sustainability/indicator/file/${item.fileId}/preview`);
                  }}
                  label={item.name}
                />
              ))}
            </div>
          </>
        )}
      </>
      {!!readOnly && (
        <>
          {!!data.reason && (
            <>
              <h4 className="header">{t("ratingSystem.reason.title")}</h4>
              <div className="rich-text-content" dangerouslySetInnerHTML={{ __html: data.reason }} />
            </>
          )}
          {!!isViewer && !!links.length && (
            <>
              <h4 className="links-header">{t("grouping.links")}</h4>
              <div className="links chips">
                {links.map((item, index) => (
                  <Chip
                    key={index}
                    clickable={true}
                    onClick={() => {
                      window.open(item.value);
                    }}
                    label={item.title || item.value}
                  />
                ))}
              </div>
            </>
          )}
          {!!isViewer && !!files.length && (
            <>
              <h4 className="links-header">{t("grouping.files")}</h4>
              <div className="links">
                <FilesTable
                  noSearch
                  readOnly
                  files={files || []}
                  getFilePath={(item) => getProjectFileUrl(projectId, item.fileId)}
                />
              </div>
            </>
          )}
        </>
      )}

      {!readOnly && (
        <>
          <h4 className="header">{t("ratingSystem.reason.title")}</h4>
          <EvaluationReason value={data.reason} onBlur={saveChangesReason} kpiRatingSystemId={kpiRatingSystemId} />
          {!!isViewer && (
            <>
              <h4 className="links-header">
                {t("grouping.links")}{" "}
                <IconButton color="primary" size="small" className="add-button" onClick={() => setLinkDialog(true)}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </h4>
              {!links.length && <div className="empty text-center">{t("main.empty")}</div>}
              {!!links.length && (
                <div className="links chips">
                  {links.map((item, index) => (
                    <Chip
                      key={index}
                      clickable
                      label={item.title || item.value}
                      onDelete={() => deleteLink(index)}
                      onClick={() => setLinkDialog(index)}
                    />
                  ))}
                </div>
              )}
              <h4 className="links-header">
                {t("grouping.files")}{" "}
                <IconButton
                  color="primary"
                  size="small"
                  className="add-button"
                  onClick={(event) => setPopoverTarget(event.target)}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </h4>
              {fileValidationError && (
                <div className="file-upload-restrictions">
                  <div>
                    <small>
                      {t("fileUpload.allowedExtensions")} {getRestrictedFileExtension(FILE_UPLOAD_RESTRICTIONS)}
                    </small>
                  </div>
                  <div>
                    <small>
                      {t("fileUpload.maximumFileSize")} {getRestrictedFileSize(FILE_UPLOAD_RESTRICTIONS)}
                    </small>
                  </div>
                </div>
              )}
              <input ref={uploadInputRef} type="file" color="primary" onChange={onFileSelect} hidden multiple />
              {!filesProcessed.length && <div className="empty text-center">{t("main.empty")}</div>}
              {(!!filesProcessed.length || postFilesLoading) && (
                <FilesTable
                  noSearch
                  tagsReadOnly
                  files={filesProcessed}
                  uploadingFiles={uploadingFiles}
                  deleteFile={(item) => removeFile(item)}
                  updateFiles={onChange}
                  getFilePath={(item) => getProjectFileUrl(projectId, item.fileId)}
                />
              )}
            </>
          )}
        </>
      )}

      <Popover
        open={!!popoverTarget}
        anchorEl={popoverTarget}
        onClose={() => setPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setPopoverTarget(null);
            uploadInputRef.current && uploadInputRef.current.click();
          }}
        >
          {t("ratingSystem.reason.addLocalFile")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPopoverTarget(null);
            setSelectFileOpen(true);
            getProjectFiles(projectId);
          }}
        >
          {t("ratingSystem.reason.addProjectFile")}
        </MenuItem>
      </Popover>

      <SelectFileDialog
        open={selectFileOpen}
        files={availableFiles}
        onClose={() => setSelectFileOpen(false)}
        isLoading={projectFilesResponse.loading}
        getFilePath={(item) => `/api/project/${projectId}/file/${item.fileId}`}
        onSelect={oneProjectFileSelect}
      />

      <LinkDialog
        key={linkDialog}
        open={linkDialog !== null}
        onClose={() => setLinkDialog(null)}
        titleText={linkDialog === true ? t("grouping.addLink") : t("grouping.editLink")}
        data={linkDialog !== null ? links[linkDialog] : null}
        onSave={changeLink}
      />
    </LoadingOverlay>
  );
};
