import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_CLIENT } from "../../../constants/main";
import { TYPE_CHECKBOX, TYPE_DATE, TYPE_SELECT } from "../../../constants/projectAttribute";
import { showDialog } from "../../../hooks/dialog";
import { getProjectFilterFields } from "../../../hooks/project";
import { useEvent } from "../../../hooks/utils/useEvent";
import "./ProjectAdvancedFilter.scss";
import { ProjectAdvancedFilterDialog } from "./ProjectAdvancedFilterDialog";

export const ProjectAdvancedFilter = ({ filters, onChange }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    getProjectFilterFields();
  }, []);

  const onAddClick = useEvent(() => {
    const onAdd = (filter) => onChange([...filters, filter]);

    showDialog({
      className: "small",
      closeOnClickOutside: true,
      getContent: (onClose) => <ProjectAdvancedFilterDialog filters={filters} onAdd={onAdd} onClose={onClose} />,
    });
  });

  const onRemove = useEvent((index) => {
    const updated = [...filters];
    updated.splice(index, 1);
    onChange(updated);
  });

  const processValue = (value, field) => {
    if (field?.type === TYPE_SELECT) {
      const found = field?.details?.options?.find((item) => item.value === value);
      return (found?.details || {})[language] || value;
    }
    if (field?.type === TYPE_CHECKBOX) {
      return value === "true" ? t("main.yes") : t("main.no");
    }
    if (field?.type === TYPE_DATE) {
      return moment(value).format(DATE_FORMAT_CLIENT);
    }
    return value;
  };

  return (
    <div className="project-advanced-filter">
      <div className="filter-items">
        {(filters || []).map(({ field, value }, index) => (
          <div key={index} className="filter-item">
            {(field?.details || {})[language] || field.name}: {processValue(value, field)}
            <IconButton size="small" onClick={() => onRemove(index)}>
              <CancelIcon />
            </IconButton>
          </div>
        ))}
      </div>
      <IconButton size="small" onClick={onAddClick}>
        <AddCircleOutlineIcon />
      </IconButton>
    </div>
  );
};
