import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditRoadIcon from "@mui/icons-material/EditAttributes";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  PERMISSION_PROJECT_GROUP_DELETE,
  PERMISSION_PROJECT_GROUP_EDIT,
  PERMISSION_PROJECT_GROUP_READ,
} from "../../constants/permissions";
import { hasPermission } from "../../helpers/permission";
import { AnalyticsSVG } from "../../svg";

export const ProjectGroupActionsColumnTemplate = ({ props, setPopoverTarget, t }) => {
  if (!hasPermission(PERMISSION_PROJECT_GROUP_EDIT) && !hasPermission(PERMISSION_PROJECT_GROUP_DELETE)) {
    return null;
  }

  return (
    <>
      {hasPermission(PERMISSION_PROJECT_GROUP_EDIT) && (
        <Tooltip title={t("main.edit")}>
          <NavLink to={"/projectGroup/" + props.id + "?mode=edit"}>
            <IconButton color="primary" size="small">
              <EditRoadIcon />
            </IconButton>
          </NavLink>
        </Tooltip>
      )}
      {hasPermission(PERMISSION_PROJECT_GROUP_READ) && (
        <Tooltip title={t("main.analyze")}>
          <NavLink to={"/projectGroup/analyze/" + props.id}>
            <IconButton color="primary" size="small">
              <AnalyticsSVG />
            </IconButton>
          </NavLink>
        </Tooltip>
      )}
      {hasPermission(PERMISSION_PROJECT_GROUP_DELETE) && (
        <IconButton onClick={(event) => setPopoverTarget({ target: event.target, props })} size="small">
          <MoreVertIcon />
        </IconButton>
      )}
    </>
  );
};

export const ProjectActionsColumnTemplate = ({ props, rootProps, setRemoveProjectConfirmation }) => {
  if (!hasPermission(PERMISSION_PROJECT_GROUP_EDIT)) {
    return null;
  }

  if (props?.id) {
    return null;
  }

  return (
    <IconButton onClick={() => setRemoveProjectConfirmation(rootProps.id)} size="small">
      <DeleteOutlinedIcon />
    </IconButton>
  );
};
