import { EditorTools } from "@progress/kendo-react-editor";

const {
    ForeColor
  } = EditorTools;

const VIEW_TYPE = 'gradient';

const KENDO_ICON = 'foreground-color';

export const SPTForeColor = props => (
    <ForeColor {...props} colorPickerProps={{
      view: VIEW_TYPE,
      gradientSettings: { opacity: false },
      icon: KENDO_ICON,
      popupSettings: {
        className: 'rich-text-animation-container'
      }
    }} />
);