import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { FormController } from "../../components/FormController/FormController";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { ProjectTypeSelect } from "../../components/SustainabilitySystem/SustainabilitySystemFields/ProjectTypeSelect/ProjectTypeSelect";
import { TYPE_NEW_BUILDING, TYPE_PAST } from "../../constants/sustainabilitySystem";
import { TENANT_TYPE_ESG, TENANT_TYPE_PAST } from "../../constants/tenant";
import { getUserTenantType } from "../../hooks/auth";
import { createKpiSystem, useKpiSystemCreateResponse } from "../../hooks/kpiSystem";
import { showError, showSuccess } from "../../hooks/toast";
import { CloseSVG, SaveSVG } from "../../svg";
import { processMessage } from "../../utils";
import "./CreateKPISystem.scss";

export const CreateKPISystemPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenantType = getUserTenantType();

  const response = useKpiSystemCreateResponse();

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      name: "",
      systemSource: "",
      systemVersion: "",
      projectTypes: [tenantType === TENANT_TYPE_ESG ? TYPE_NEW_BUILDING : TYPE_PAST],
      description: "",
    },
  });

  const save = async () => {
    const values = getValues();
    try {
      const system = await createKpiSystem({
        ...values,
      });
      showSuccess(t("kpiSystem.createdMessage"));
      navigate("/sustainabilitySystem/kpiSystem/" + system.id);
    } catch (error) {
      if (error.status === 409) {
        showError(processMessage(t("kpiSystem.error.409"), [values.name, values.systemVersion]));
      } else {
        showError(t("kpiSystem.error.500"));
      }
    }
  };

  const onSubmit = () => save();

  return (
    <LoadingOverlay spinner active={response.loading} className="create-kpi-system">
      <div className="form-step">
        <div>
          <h1 className="page-title small">{t("kpiSystem.createTitle")}</h1>
          <Paper className="page-content small">
            <h2>{t("kpiSystem.baseData")}</h2>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
              <FormController
                control={control}
                name="systemSource"
                required
                label={t("kpiSystem.systemSource")}
                render={({ field, fieldState, label }) => (
                  <TextField
                    error={fieldState.invalid}
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    label={label + " *"}
                    {...field}
                  />
                )}
              />
              <FormController
                control={control}
                name="name"
                required
                label={t("kpiSystem.name")}
                render={({ field, fieldState, label }) => (
                  <TextField
                    error={fieldState.invalid}
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    label={label + " *"}
                    {...field}
                  />
                )}
              />
              <FormController
                control={control}
                name="systemVersion"
                required
                label={t("kpiSystem.systemVersion")}
                render={({ field, fieldState, label }) => (
                  <TextField
                    error={fieldState.invalid}
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    label={label + " *"}
                    {...field}
                  />
                )}
              />
              <ProjectTypeSelect control={control} disabled={tenantType === TENANT_TYPE_PAST} />
              <Controller
                control={control}
                name="description"
                render={({ field }) => (
                  <TextField fullWidth label={t("kpiSystem.description")} {...field} inputProps={{ maxLength: 255 }} />
                )}
              />
              <div className="fabs">
                <Fab color="secondary" onClick={() => navigate(-1)}>
                  <CloseSVG className="fab-svg" />
                </Fab>
                <Fab type="submit" color="primary">
                  <SaveSVG className="fab-svg" />
                </Fab>
              </div>
            </form>
          </Paper>
        </div>
      </div>
    </LoadingOverlay>
  );
};
