import React from "react";
import "./StatusIndicator.scss";

export const StatusIndicator = ({ className, top, bottom, middle, setPopoverTarget }) => {

  const topClassName = top > 0 ? 'green' : top < 0 ? 'red' : '';
  const middleClassName = middle > 0 ? 'green' : middle < 0 ? 'red' : '';
  const bottomClassName = bottom > 0 ? 'green' : bottom < 0 ? 'red' : '';

  return (
    <div className={'status-indicator' + (className ? ' ' + className : '')}
         onMouseOver={setPopoverTarget ? event => setPopoverTarget(event.currentTarget) : null}
         onMouseLeave={setPopoverTarget ? () => setPopoverTarget(null) : null}>
      <div className={'indicator-part ' + topClassName}/>
      <div className={'indicator-part ' + middleClassName}/>
      <div className={'indicator-part ' + bottomClassName}/>
    </div>
  );
};
