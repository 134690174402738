import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FloatingLabelTextBox } from "../FloatingInputs";
import { conditionNotIndicator } from "../NewRatingSystemESG.validation";
import { BaseBlock } from "./BaseBlock";

export const RESPONSIBILITY_BLOCK_ID = "resposibilityBlock";

export const ResponsibilityBlock = ({ dataItem, tableFn, readOnly }) => {
  const { t } = useTranslation();
  if (conditionNotIndicator(dataItem)) return null;
  return (
    <BaseBlock title={t("newRatingSystem.esg.sideMenu.responsibilityBlock.title")} id={RESPONSIBILITY_BLOCK_ID}>
      <div className="k-px-5">
        <FloatingLabelTextBox
          label={t("newRatingSystem.esg.sideMenu.responsibilityBlock.responsibility")}
          value={dataItem?.responsible ?? ""}
          onChange={(value) => tableFn?.onItemChange([{ dataItem, field: "responsible", value }])}
          className="k-w-full"
          readOnly={readOnly}
          autoSelect
        />
      </div>
    </BaseBlock>
  );
};

ResponsibilityBlock.propTypes = {
  dataItem: PropTypes.object,
  tableFn: PropTypes.object,
  readOnly: PropTypes.bool,
};
