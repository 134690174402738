import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { STATUS_RELEASED } from "../../../constants/kpiSystem";
import { getKpiSystems, useKpiSystemsResponse } from "../../../hooks/kpiSystem";
import { addProjectKpiSystems, useProjectAddKpiSystemsResponse } from "../../../hooks/project";
import { showError } from "../../../hooks/toast";
import { CheckTree } from "../../CheckTree/CheckTree";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import "./ProjectKpiSystemsDialog.scss";

export const ProjectKPISystemsDialog = ({ open, onClose, project, onSave }) => {
  const { t } = useTranslation();

  const projectId = (project || {}).id;

  const selected = (project || {}).kpiRatingSystems || [];
  const selectedValues = selected.map((item) => item.kpiSystemId);
  const [values, setValues] = useState([]);

  const response = useKpiSystemsResponse();
  const systems = response.data || [];
  const addProjectSystemsResponse = useProjectAddKpiSystemsResponse();

  const load = () => getKpiSystems(STATUS_RELEASED).catch(console.error);
  useEffect(() => {
    if (open) {
      load();
    }
  }, [open]);

  useEffect(() => {
    if (projectId) {
      setValues([]);
    }
  }, [projectId]);

  const processValues = () => {
    const res = [];
    values.forEach((item) => {
      if (selectedValues.indexOf(item) === -1) {
        res.push(item);
      }
    });
    return res;
  };

  const save = () =>
    addProjectKpiSystems(projectId, processValues())
      .then(onSave)
      .catch((error) => {
        console.error(error);
        showError(t("error.500"));
      });

  const systemsTree = systems.map((item) => ({
    name: item.id,
    text: item.name,
  }));

  return (
    <Dialog open={open} onClose={onClose} className="project-kpi-systems-dialog small">
      <DialogTitle>
        {t("projects.systems.addKPITitle")}{" "}
        <IconButton onClick={onClose} size="small">
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <LoadingOverlay spinner active={response.loading || addProjectSystemsResponse.loading} className="auto-height">
        <div className="overflow-y-auto">
          <CheckTree
            key={projectId}
            getRowClassName={(row, index, level) => (level === 0 ? (index % 2 ? "even" : "odd") : "")}
            multiple
            lastLevelOnly
            items={systemsTree}
            disabledValues={selectedValues}
            values={[...selectedValues, ...values]}
            onChange={(updated) => setValues([...selectedValues, ...updated])}
            collapsible
          />
          {!systemsTree.length && <p className="text-center">{t("main.empty")}</p>}
        </div>
        <div className="buttons text-right">
          <Button onClick={onClose}>{t("main.cancel")}</Button>
          <Button color="primary" onClick={save} disabled={!values.length}>
            {t("main.save")}
          </Button>
        </div>
      </LoadingOverlay>
    </Dialog>
  );
};
