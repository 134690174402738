import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { STATUS_IN_PROGRESS, STATUS_LOCKED, STATUS_RELEASED } from "../../constants/sustainabilitySystem";
import { processMessage } from "../../utils";
import { StatusBadge } from "../StatusBadge/StatusBadge";
import "./StatusSelect.scss";

const STATUSES_BY_STATUS = {
  undefined: [STATUS_IN_PROGRESS],
  [STATUS_IN_PROGRESS]: [STATUS_IN_PROGRESS, STATUS_RELEASED],
  [STATUS_RELEASED]: [STATUS_IN_PROGRESS, STATUS_RELEASED, STATUS_LOCKED],
  [STATUS_LOCKED]: [STATUS_IN_PROGRESS, STATUS_RELEASED, STATUS_LOCKED],
};

export const StatusSelect = ({
  control,
  disabled,
  value,
  onChange,
  statuesByStatus = STATUSES_BY_STATUS,
  labelTranslationPrefix = "sustainabilitySystem.status.",
}) => {
  const { t } = useTranslation();
  const status = value || STATUS_IN_PROGRESS;
  return (
    <Controller
      control={control}
      name="status"
      rules={{ required: false }}
      render={({ field, formState, fieldState }) => {
        return (
          <FormControl fullWidth required={false} className="status-select">
            <Select
              fullWidth
              {...field}
              disabled={disabled}
              id="sustainabilitySystemType"
              onChange={(event) => {
                field.onChange(event);
                onChange && onChange(event);
              }}
            >
              {statuesByStatus[status].map((item) => (
                <MenuItem key={item} value={item}>
                  <StatusBadge className={"status-" + item}>{t(labelTranslationPrefix + item)}</StatusBadge>
                </MenuItem>
              ))}
            </Select>
            {formState.isSubmitted && fieldState.invalid && (
              <FormHelperText error>
                {processMessage(t("validation.fieldRequired"), [t(labelTranslationPrefix + "title")])}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
