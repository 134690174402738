import React from "react";
import { useTranslation } from "react-i18next";
import { STATUS_RELEASED, STATUS_IN_PROGRESS, STATUS_LOCKED } from "../../../../constants/sustainabilitySystem";
import { FilterBlocks } from "../../../FilterBlocks/FilterBlocks";
import "./StatusFilter.scss";

export const StatusFilter = ({ value, onChange, records, disabled }) => {
  const { t } = useTranslation();

  const amounts = {
    [STATUS_IN_PROGRESS]: 0,
    [STATUS_RELEASED]: 0,
    [STATUS_LOCKED]: 0
  };

  (records || []).forEach(systems => {
    amounts[systems.status]++;
  });

  const filterOptions = [
    {
      name: STATUS_IN_PROGRESS,
      title: t('sustainabilitySystem.status.' + STATUS_IN_PROGRESS),
      amount: amounts[STATUS_IN_PROGRESS]
    },
    {
      name: STATUS_RELEASED,
      title: t('sustainabilitySystem.status.' + STATUS_RELEASED),
      amount: amounts[STATUS_RELEASED]
    },
    {
      name: STATUS_LOCKED,
      title: t('sustainabilitySystem.status.' + STATUS_LOCKED),
      amount: amounts[STATUS_LOCKED]
    }
  ];

  return (
    <FilterBlocks filterOptions={filterOptions} value={value} onChange={onChange} allowDeselect showReset={!!value}
                  disabled={disabled}/>
  );
};
