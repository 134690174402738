import SearchIcon from "@mui/icons-material/Search";
import { Fab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddUserTenantDialog } from "../../components/AddUserTenantDialog/AddUserTenantDialog";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { UserRolesTooltipPopover } from "../../components/Users/UserRolesTooltipPopover/UserRolesTooltipPopover";
import { UserTenantRolesDialog } from "../../components/Users/UserTenantRolesDialog/UserTenantRolesDialog";
import { UsersFilter } from "../../components/Users/UsersFilter/UsersFilter";
import { UsersTable } from "../../components/Users/UsersTable/UsersTable";
import { handleError } from "../../helpers/error";
import { checkPopState } from "../../hooks";
import { useUser } from "../../hooks/auth";
import { showMuiDialog } from "../../hooks/dialog";
import {
  addUserTenant,
  deleteUser,
  deleteUserTenant,
  getTenantUsersList,
  getUsersList,
  resetUsersPageState,
  useDeleteUserResponse,
  useRemoveUserTenantResponse,
  useTenantUsersListResponse,
  useUsersListResponse,
  useUsersPageLogic,
} from "../../hooks/users";
import { useTimeout } from "../../hooks/utils/useTimeout";
import { PlusSVG } from "../../svg";
import "./TenantUsers.scss";

export const TenantUsersPage = () => {
  const { t } = useTranslation();

  const { tenant } = useUser();
  const response = useTenantUsersListResponse();
  const addUserListResponse = useUsersListResponse();
  const deleteResponse = useDeleteUserResponse();
  const removeTenantResponse = useRemoveUserTenantResponse();
  const [usersList, setUsersList] = useState([]);
  const [addUserList, setAddUserList] = useState([]);

  const readOnly = false;

  const { searchValue, setSearchValue, filterValue, setFilterValue } = useUsersPageLogic();
  const [, setSearchTimeout] = useTimeout();

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [removeConfirmation, setRemoveConfirmation] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [manageRolesDialog, setManageRolesDialog] = useState(null);

  useEffect(() => {
    if (!addUserListResponse.loading && usersList.length > 0) {
      const filteredUserList = addUserListResponse.data
        .map((x) => x.user)
        .filter((x) => !usersList.some((y) => x.id === y.user.id));
      setAddUserList(filteredUserList);
    }
  }, [addUserListResponse.loading, JSON.stringify(usersList)]);

  useEffect(() => {
    if (!response.loading && response.data && tenant) {
      response.data.forEach((x) => {
        x.userTenants = x.userTenants.filter((y) => y.tenant.id === tenant.id);
        return x;
      });

      setUsersList(response.data);
    }
  }, [response.loading]);

  useEffect(() => {
    if (!checkPopState()) {
      resetUsersPageState();
    }
    load();
  }, []);

  const load = () => {
    getTenantUsersList().then().catch(handleError);
    getUsersList().then().catch(handleError);
  };

  const addToTenant = (user) => {
    if (tenant) {
      addUserTenant(user.id, tenant.id)
        .then(() => load())
        .catch(handleError);
    }
  };

  const search = (value) => {
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(value || "").trim());
      }, 500)
    );
  };

  const deleteHandler = () => {
    setDeleteConfirmation(null);
    deleteUser(deleteConfirmation.id).then(load).catch(handleError);
  };

  const removeTenantHandler = () => {
    setRemoveConfirmation(null);
    const { user, tenant: tenantInner } = removeConfirmation;
    deleteUserTenant(user.id, tenantInner.id).then(load).catch(handleError);
  };

  const openAddUserDialog = () =>
    showMuiDialog((props) => <AddUserTenantDialog userList={addUserList} onSave={addToTenant} {...props} />);

  const loading =
    response.loading || addUserListResponse.loading || deleteResponse.loading || removeTenantResponse.loading;

  return (
    <div className="users-page page-with-table">
      <LoadingOverlay spinner active={loading}>
        <div className="page-header large flex-row">
          <div className="flex-col title-box no-margin">
            <h1>{t("users.title")}</h1>
          </div>
          <div className="flex-col search-box no-margin">
            <SearchIcon/>
            <input
              type="text"
              defaultValue={searchValue || ""}
              className="search-input input-shadow"
              placeholder={t("main.search")}
              onChange={(event) => search(event.target.value)}
            />
          </div>
        </div>

        <div className="page-layout">
          <UsersFilter onChange={setFilterValue} value={filterValue} records={filtered}/>
          <UsersTable
            data={usersList}
            setFiltered={setFiltered}
            loading={response.loading}
            readOnly={readOnly}
            onManageRoles={setManageRolesDialog}
            onRemoveTenant={(user, tenantInner) => setRemoveConfirmation({ user, tenant: tenantInner })}
            onDelete={(user) => setDeleteConfirmation(user)}
            hideColumns={{
              superAdmin: true,
              name: true,
            }}
            hideOptions={{
              editUser: true,
              addTenant: true,
              deleteUser: true,
            }}
          />
        </div>
      </LoadingOverlay>

      <UserRolesTooltipPopover/>

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={t("users.userDeleteConfirmationTitle")}
        bodyText={t("users.userDeleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />

      <ConfirmationDialog
        open={!!removeConfirmation}
        onClose={() => setRemoveConfirmation(null)}
        onConfirm={removeTenantHandler}
        titleText={t("users.tenantRemoveConfirmationTitle")}
        bodyText={t("users.tenantRemoveConfirmation")}
        confirmText={t("main.remove")}
        color="secondary"
      />
      {manageRolesDialog && (
        <UserTenantRolesDialog
          {...manageRolesDialog}
          onChange={load}
          open={manageRolesDialog}
          onClose={() => setManageRolesDialog(null)}
        />
      )}
      <div className="fabs">
        <Fab type="button" color="primary" onClick={openAddUserDialog} disabled={loading}>
          <PlusSVG className="fab-svg"/>
        </Fab>
      </div>
    </div>
  );
};
