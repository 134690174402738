import { isSuperAdmin } from "../../hooks/auth";
import { AdministrationIcon } from "../../svg/sidebar/icon-administration";
import { HomeIcon } from "../../svg/sidebar/icon-home";
import { PointIcon } from "../../svg/sidebar/icon-point";
import { ProjectsIcon } from "../../svg/sidebar/icon-project";
import { ProjectGroupsIcon } from "../../svg/sidebar/icon-project groups";
import { RatingSystemIcon } from "../../svg/sidebar/icon-rating-system";
import { ResourcesIcon } from "../../svg/sidebar/icon-resources";

import React from "react";
import {
  PERMISSION_ACTION_READ,
  PERMISSION_BENCHMARK_READ,
  PERMISSION_KPI_READ,
  PERMISSION_KPI_SYSTEM_READ,
  PERMISSION_PROJECT_GROUP_READ,
  PERMISSION_PROJECT_READ,
  PERMISSION_SUPER_ADMIN,
  PERMISSION_SUSTAINABILITY_SYSTEM_READ,
  PERMISSION_TENANT_ADMIN,
  PERMISSION_TEXTBLOCK_READ,
} from "../../constants/permissions";
import "./DefaultLayout.scss";

export const HEADER_INDEX = 100;
export const FOOTER_INDEX = 200;

export const itemList = [
  {
    index: HEADER_INDEX,
    id: 0,
    href: "/",
    selected: false,
  },
  {
    separator: true,
  },
  {
    text: "menu.index",
    href: "/",
    svgIcon: <HomeIcon />,
    hiddenCondition: isSuperAdmin,
    id: 1,
    selected: window.location.pathname === "/",
  },
  {
    text: "menu.systems.title",
    svgIcon: <RatingSystemIcon />,
    dataExpanded: window.location.pathname.includes("/sustainabilitySystem"),
    id: 2,
    href: "/sustainabilitySystem",
    permissions: [PERMISSION_SUSTAINABILITY_SYSTEM_READ],
    isParent: true,
    selected: window.location.pathname.includes("/sustainabilitySystem"),
    hiddenCondition: isSuperAdmin,
  },

  {
    text: "menu.systems.rating",
    href: "/sustainabilitySystem",
    permissions: [PERMISSION_SUSTAINABILITY_SYSTEM_READ],
    svgIcon: <PointIcon />,
    parentId: 2,
    pID: 2,
    level: 1,
    id: 8,
  },
  {
    text: "menu.systems.kpi",
    href: "/sustainabilitySystem/kpiSystem",
    permissions: [PERMISSION_KPI_SYSTEM_READ],
    svgIcon: <PointIcon />,
    parentId: 2,
    pID: 2,
    level: 1,
    id: 9,
  },
  {
    text: "menu.systems.benchmark",
    href: "/sustainabilitySystem/benchmark",
    permissions: [PERMISSION_BENCHMARK_READ],
    svgIcon: <PointIcon />,
    parentId: 2,
    pID: 2,
    level: 1,
    id: 10,
  },

  {
    text: "menu.resources.title",
    href: "/settings",
    permissions: [PERMISSION_ACTION_READ, PERMISSION_KPI_READ, PERMISSION_TEXTBLOCK_READ],
    hiddenCondition: isSuperAdmin,
    svgIcon: <ResourcesIcon />,
    dataExpanded: window.location.pathname.includes("/settings"),
    id: 3,
    isParent: true,
    selected: window.location.pathname.includes("/settings"),
  },
  {
    text: "menu.resources.actions",
    href: "/settings/actions",
    permissions: [PERMISSION_ACTION_READ],
    svgIcon: <PointIcon />,
    selected: false,
    parentId: 3,
    pID: 3,
    level: 1,
    id: 11,
  },
  {
    text: "menu.resources.kpi",
    href: "/settings/kpi",
    permissions: [PERMISSION_KPI_READ],
    svgIcon: <PointIcon />,
    selected: false,
    parentId: 3,
    pID: 3,
    level: 1,
    id: 12,
  },
  {
    text: "menu.resources.textblock",
    href: "/settings/textblock",
    permissions: [PERMISSION_TEXTBLOCK_READ],
    svgIcon: <PointIcon />,
    selected: false,
    parentId: 3,
    pID: 3,
    level: 1,
    id: 13,
  },

  {
    text: "menu.project.title",
    href: "/project",
    permissions: [PERMISSION_PROJECT_READ],
    hiddenCondition: isSuperAdmin,
    svgIcon: <ProjectsIcon />,
    selected: /\/project\/?/.test(window.location.pathname),
    id: 4,
  },

  {
    text: "menu.projectGroups.title",
    href: "/projectGroup",
    permissions: [PERMISSION_PROJECT_GROUP_READ],
    hiddenCondition: isSuperAdmin,
    svgIcon: <ProjectGroupsIcon />,
    selected: window.location.pathname.includes("/projectGroup"),
    id: 5,
  },
  {
    text: "menu.admin.title",
    href: "/admin",
    permissions: [PERMISSION_SUPER_ADMIN, PERMISSION_TENANT_ADMIN],
    svgIcon: <AdministrationIcon />,
    dataExpanded: window.location.pathname.includes("/admin"),
    id: 6,
    isParent: true,
    selected: window.location.pathname.includes("/admin"),
  },
  {
    text: "menu.admin.users",
    href: "/admin/tenant/users",
    permissions: [PERMISSION_TENANT_ADMIN],
    svgIcon: <PointIcon />,
    hiddenCondition: isSuperAdmin,
    selected: false,
    parentId: 6,
    pID: 6,
    level: 1,
    id: 14,
  },
  {
    text: "menu.admin.users",
    href: "/admin/users",
    permissions: [PERMISSION_SUPER_ADMIN],
    svgIcon: <PointIcon />,
    selected: false,
    parentId: 6,
    pID: 6,
    level: 1,
    id: 15,
  },
  {
    text: "menu.admin.roles",
    href: "/admin/roles",
    permissions: [PERMISSION_SUPER_ADMIN],
    svgIcon: <PointIcon />,
    selected: false,
    parentId: 6,
    pID: 6,
    level: 1,
    id: 16,
  },
  {
    text: "menu.admin.tenants",
    href: "/admin/tenants",
    permissions: [PERMISSION_SUPER_ADMIN],
    svgIcon: <PointIcon />,
    selected: false,
    parentId: 6,
    pID: 6,
    level: 1,
    id: 17,
  },
  {
    text: "menu.admin.projectAttributes",
    href: "/admin/projectAttributes",
    permissions: [PERMISSION_SUPER_ADMIN],
    svgIcon: <PointIcon />,
    selected: false,
    parentId: 6,
    pID: 6,
    level: 1,
    id: 18,
  },
  {
    text: "menu.admin.generalAttributes",
    href: "/admin/generalAttributes",
    permissions: [PERMISSION_SUPER_ADMIN],
    svgIcon: <PointIcon />,
    selected: false,
    parentId: 6,
    pID: 6,
    level: 1,
    id: 19,
  },
  {
    index: FOOTER_INDEX,
    id: 7,
  },
];
