import TextField from "@mui/material/TextField";
import { FormController } from "../FormController/FormController";

export const TextFieldController = ({ control, name, required, label, readOnly, multiline, maxLength }) => {
  return (
    <FormController
      control={control}
      name={name}
      required={required}
      label={label}
      render={({ field, fieldState, label: labelInner }) => (
        <TextField
          multiline={multiline}
          error={fieldState.invalid}
          disabled={readOnly}
          fullWidth
          inputProps={{ maxLength }}
          label={labelInner + (required ? " *" : "")}
          {...field}
          value={field.value || ""}
        />
      )}
    />
  );
};
