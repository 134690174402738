export const CollapseSidebarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.414" height="17" viewBox="0 0 17.414 17">
    <g id="icon-collapse-sidebar" transform="translate(-2 -3)">
      <path
        id="Path_38"
        data-name="Path 38"
        d="M19.094,10,13,16.094l6.094,6.094"
        transform="translate(-1.094 -4.423)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <rect
        id="Rectangle_99"
        data-name="Rectangle 99"
        width="4.688"
        height="15"
        rx="2.344"
        transform="translate(3 4)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
);
