import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEvent } from "../../../hooks/utils/useEvent";
import { processMessage } from "../../../utils";
import { FormController } from "../../FormController/FormController";
import "./ProjectAttributeOption.scss";

export const ProjectAttributeOption = ({
  data,
  onSave,
  onChange,
  onDelete,
  onValidate,
  isUnique,
  withTranslations,
  onDirtyChange,
  readOnly,
}) => {
  const { t } = useTranslation();
  const isEdit = !!data;

  const getDefaultValues = () => ({
    value: "",
    details: { en: "", de: "" },
  });

  const { handleSubmit, control, reset, getValues, formState, trigger, setError } = useForm({
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  useEffect(() => {
    onDirtyChange && onDirtyChange(formState.isDirty);
    if (!formState.isDirty) {
      reset(data || getDefaultValues());
    }
  }, [formState.isDirty]);

  const save = useEvent(() => {
    const values = getValues();
    if (isUnique(values.value)) {
      onSave(values);
      reset(getDefaultValues());
    } else {
      setError("value", { type: "unique" });
    }
  });
  const handleDelete = useEvent(() => onDelete(data));
  const handleChange = useEvent(() => {
    if (!isEdit) {
      return;
    }
    trigger().then((isValid) => {
      const values = getValues();
      if (isUnique(values.value)) {
        onValidate(values.value, isValid);
        if (isValid) {
          onChange(values);
        }
      } else {
        onValidate(values.value, false);
        setError("value", { type: "unique" });
      }
    });
  });

  return (
    <div className="project-attribute-option">
      <div className="flex-row">
        <div className="k-flex-1">
          <FormController
            control={control}
            name="value"
            required
            label={t("projectAttributes.optionValue")}
            rules={{ unique: true }}
            render={({ field, fieldState, label }) => (
              <>
                <TextField
                  error={fieldState.invalid}
                  fullWidth
                  label={label + " *"}
                  {...field}
                  onKeyUp={handleChange}
                  disabled={readOnly}
                />
                {fieldState.error?.type === "unique" && formState.isSubmitted && (
                  <p className="MuiFormHelperText-root Mui-error">{processMessage(t("validation.unique"), [""])}</p>
                )}
              </>
            )}
          />
        </div>
        {!!withTranslations && (
          <>
            <div className="k-flex-1">
              <FormController
                control={control}
                name="details.en"
                required
                label={t("projectAttributes.optionLabel") + " EN"}
                render={({ field, fieldState, label }) => (
                  <TextField
                    error={fieldState.invalid}
                    fullWidth
                    label={label + " *"}
                    {...field}
                    onKeyUp={handleChange}
                    disabled={readOnly}
                  />
                )}
              />
            </div>
            <div className="k-flex-1">
              <FormController
                control={control}
                name="details.de"
                required
                label={t("projectAttributes.optionLabel") + " DE"}
                render={({ field, fieldState, label }) => (
                  <TextField
                    error={fieldState.invalid}
                    fullWidth
                    label={label + " *"}
                    {...field}
                    onKeyUp={handleChange}
                    disabled={readOnly}
                  />
                )}
              />
            </div>
          </>
        )}
        {!readOnly && (
          <div className="option-actions">
            {!isEdit && (
              <IconButton size="small" color="primary" onClick={handleSubmit(save)} disabled={!formState.isDirty}>
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            )}
            {isEdit && (
              <IconButton size="small" color="secondary" onClick={handleDelete}>
                <DeleteOutlinedIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
