import CloseIcon from "@mui/icons-material/Close";
import { FormControl, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, LocalizationProvider, load, loadMessages } from "@progress/kendo-react-intl";
import weekData from 'cldr-core/supplemental/weekData.json';
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from "cldr-dates-full/main/de/dateFields.json";
import deNumbers from "cldr-numbers-full/main/de/numbers.json";
import moment from "moment/moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_SERVER } from "../../../constants/main";
import { TYPE_CHECKBOX, TYPE_DATE, TYPE_SELECT } from "../../../constants/projectAttribute";
import { useProjectFilterFieldsResponse } from "../../../hooks/project";
import { useEvent } from "../../../hooks/utils/useEvent";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import kendoDeMessages from './../../../locales/kendo-de.json';
import "./ProjectAdvancedFilterDialog.scss";

load(
  deNumbers,
  deDateFields,
  deCaGregorian,
  weekData
)
loadMessages(kendoDeMessages, 'de');

const SELECT_TYPES = [TYPE_SELECT, TYPE_CHECKBOX];

export const ProjectAdvancedFilterDialog = ({ filters, onAdd, onClose }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const { data, loading } = useProjectFilterFieldsResponse();

  const availableFilters = useMemo(
    () => (data || []).filter((item) => !filters.some((filter) => filter.field?.name === item.name)),
    [loading, filters]
  );

  const [field, setField] = useState(availableFilters.length ? availableFilters[0] : null);

  const availableValues = useMemo(() => {
    if (field?.type === TYPE_SELECT) {
      return field?.details?.options || [];
    }
    if (field?.type === TYPE_CHECKBOX) {
      return [
        {
          value: "true",
          label: t("main.yes"),
        },
        {
          value: "false",
          label: t("main.no"),
        },
      ];
    }
    return [];
  }, [field]);

  const [value, setValue] = useState(null);

  const onSave = useEvent(() => {
    onAdd({ field, value });
    onClose();
  });

  const onTypeSelect = useEvent((event) => {
    setField(event.target.value);
    setValue(null);
  });

  const onValueSelect = useEvent((event) => {
    setValue(event.target.value);
  });

  return (
    <div className="project-advanced-filter-dialog">
      <div className="flex-row custom-dialog-header">
        <h2 className="flex-auto">{t("projects.filter.additionalFilter")}</h2>
        <IconButton className="close-button" size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="dialog-body">
        <LoadingOverlay spinner active={loading} className="auto-height">
          <FormControl fullWidth>
            <InputLabel htmlFor="selectFilterField">{t("projects.filter.additionalField")}</InputLabel>
            <Select value={field || ""} id="selectFilterField" onChange={onTypeSelect} searchable="false">
              {availableFilters.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {(item?.details || {})[language] || item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </LoadingOverlay>
        {!!field && SELECT_TYPES.indexOf(field.type) !== -1 && (
          <FormControl fullWidth>
            <InputLabel htmlFor="selectFilterValue">{t("projects.filter.additionalValue")}</InputLabel>
            <Select value={value || ""} id="selectFilterValue" onChange={onValueSelect} searchable="false">
              {availableValues?.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {(item?.details || {})[language] || item.label || item.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {!!field && field.type === TYPE_DATE && (
          <FormControl fullWidth>
            <LocalizationProvider language={i18n.language}>
              <IntlProvider locale={i18n.language}>
                <DatePicker
                  className="advanced-filter-datepicker"
                  label={t("projects.filter.additionalValue")}
                  placeholder=""
                  fillMode="flat"
                  format={"dd.MM.yyyy"}
                  value={value ? moment(value, DATE_FORMAT_SERVER).toDate() : null}
                  onChange={(valueOnChange) => {
                    setValue(valueOnChange ? moment(valueOnChange.value).format(DATE_FORMAT_SERVER) : valueOnChange.value);
                  }}
                />
              </IntlProvider>
            </LocalizationProvider>
          </FormControl>
        )}
      </div>
      <div className="buttons text-right">
        <Button onClick={onClose}>{t("main.close")}</Button>
        <Button onClick={onSave} color="primary" disabled={!field || !value}>
          {t("main.add")}
        </Button>
      </div>
    </div>
  );
};
