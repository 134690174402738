import AddIcon from "@mui/icons-material/Add";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./IndicatorValueRangeDialog.scss";

export const IndicatorValueRangeDialog = ({ open, onClose, onChange, valueRangeList, allowCustomValue }) => {
  const { t } = useTranslation();

  const [allowCustom, setAllowCustom] = useState(false);
  const [range, setRange] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open) {
      setAllowCustom(!!allowCustomValue);
      setRange(valueRangeList || []);
      setErrors({});
    }
  }, [valueRangeList, allowCustomValue, open]);

  const setValue = (value, index) => {
    const updated = [...range];
    if (!updated[index]) {
      updated[index] = {};
    }
    updated[index].value = value;
    setRange(updated);
    resetError(index);
  };

  const setScore = (systemScore, index) => {
    const updated = [...range];
    if (!updated[index]) {
      updated[index] = {};
    }
    updated[index].systemScore = systemScore;
    setRange(updated);
    resetError(index);
  };

  const deleteValue = (index) => {
    const updated = [...range];
    updated.splice(index, 1);
    setRange(updated);
    resetError(index);
  };

  const resetError = (index) => {
    const updatedErrors = { ...errors };
    delete updatedErrors[index];
    setErrors(updatedErrors);
  };

  const validate = () => {
    let valid = true;
    const updatedErrors = {};
    const indexesByValue = {};
    range.forEach((item, index) => {
      if (!item?.value || item?.value?.length > 255) {
        updatedErrors[index] = true;
        valid = false;
      }
      if (item?.value) {
        if (indexesByValue[item?.value] === undefined) {
          indexesByValue[item?.value] = index;
        } else {
          updatedErrors[indexesByValue[item?.value]] = true;
          updatedErrors[index] = true;
          valid = false;
        }
      }
    });
    setErrors(updatedErrors);
    return valid;
  };

  const onSave = () => {
    if (validate()) {
      onChange(range, allowCustom);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="indicator-value-range-dialog medium">
      <DialogTitle>
        {t("grouping.valueType.title")}{" "}
        <IconButton onClick={onClose} size="small">
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <div className="dialog-body">
        <label>
          <Checkbox color="primary" checked={allowCustom} onChange={() => setAllowCustom(!allowCustom)} />
          <span className="label">{t("grouping.valueType.allowCustomValue")}</span>
        </label>
        <h3>{t("grouping.valueType.valueRange")}</h3>
        {range.map((item, index) => (
          <div key={index} className="range-row">
            <TextField
              error={errors[index]}
              fullWidth
              value={item?.value || ""}
              onChange={(event) => setValue(event.target.value, index)}
              InputProps={{ placeholder: t("grouping.valueType.valueRange") + " *" }}
              inputProps={{ maxLength: 255 }}
            />
            <TextField
              fullWidth
              value={item.systemScore}
              type="number"
              className="second-input"
              onChange={(event) => setScore(event.target.value, index)}
              InputProps={{ placeholder: t("grouping.valueType.systemScore") }}
              inputProps={{ min: 0 }}
            />
            <IconButton size="small" onClick={() => deleteValue(index)}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </div>
        ))}
        <Button
          color="primary"
          className="with-left-icon text-uppercase no-padding"
          onClick={() => setRange([...range, { systemScore: "0" }])}
        >
          <AddIcon />
          {t("grouping.valueType.addValue")}
        </Button>
      </div>
      <div className="buttons text-right">
        <Button onClick={() => onClose()}>{t("main.close")}</Button>
        <Button color="primary" onClick={onSave} disabled={!range.length}>
          {t("main.save")}
        </Button>
      </div>
    </Dialog>
  );
};
