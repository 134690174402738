import AnalyticsIcon from "@mui/icons-material/Assessment";
import EditRoadIcon from "@mui/icons-material/EditAttributes";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { NavLink } from "react-router-dom";
import { AWARD_CALCULATION_TYPE_POINT } from "../../../constants/sustainabilitySystem";
import { onlyDecimals, roundDecimal } from "../../../utils";
import { StatusBadge } from "../../StatusBadge/StatusBadge";
import "./ProjectTemplates.scss";

export const NameColumnTemplate = (props, field, t) => {
  return (
    <div className="flex-row name-column-template name-column-template-collapse">
      {!!props.accessRestricted && (
        <Tooltip title={t("projects.accessRestricted")}>
          <LockOutlinedIcon />
        </Tooltip>
      )}
      <NavLink to={"/project/" + props.id} className="column-link flex-auto">
        {props[field]}
      </NavLink>
    </div>
  );
};

export const RatingSystemNameColumnTemplate = (childRow, field, row, showTooltip, setShowTooltip) => (
  <NavLink
    to={!childRow.kpiSystemId ? "/project/ratingSystem/" + childRow.id : "/project/kpiRatingSystem/" + childRow.id}
    className="column-link"
    onMouseEnter={
      !childRow.kpiSystemId && setShowTooltip
        ? (event) =>
            setShowTooltip({
              data: childRow,
              target: event.currentTarget,
            })
        : null
    }
    onMouseLeave={setShowTooltip ? () => setShowTooltip(null) : null}
  >
    <span>
      {childRow.systemSource} {childRow.name} {childRow.systemVersion}
    </span>
    {!childRow.kpiSystemId && !!childRow.selectedAward && !!childRow.selectedAward.fileId && (
      <img src={`/api/sustainability/award/file/${childRow.selectedAward.fileId}/preview`} hidden alt="" />
    )}
  </NavLink>
);

export const RatingSystemScoreColumnTemplate = (childRow) => {
  if (!childRow.id) {
    return null;
  }
  if (childRow.kpiSystemId) {
    return "-";
  }
  const isPoint = childRow.awardCalculationType === AWARD_CALCULATION_TYPE_POINT;
  return (
    <div className="flex-row">
      <div className={"flex-left"}>
        <span className="text-muted">
          {!isPoint && `${childRow.score}/${childRow.overallMaxSystemScore}`}
          {!!isPoint && onlyDecimals(childRow.superScore, 2) + "%"}
        </span>
      </div>
      <div className={"flex-right"}>
        <span>
          {!!isPoint && `${childRow.score}/${childRow.overallMaxSystemScore}`}
          {!isPoint && onlyDecimals(childRow.superScore, 2) + "%"}
        </span>
      </div>
    </div>
  );
};

export const RatingSuperScoreColumnTemplate = ({ kpiSystemId, superScore }) => {
  return kpiSystemId ? "-" : superScore ? onlyDecimals(roundDecimal(superScore), 2) + "%" : "";
};

export const StatusColumnTemplate = (props, t) =>
  !!props.ratingStatus && (
    <StatusBadge className={"status-" + props.ratingStatus}>
      {t("ratingSystem.status." + props.ratingStatus)}
    </StatusBadge>
  );

export const AwardColumnTemplate = ({ selectedAward }) =>
  !!selectedAward && (
    <div className="award-column">
      {!!selectedAward.fileId && (
        <img className="award-image" src={`/api/sustainability/award/file/${selectedAward.fileId}/preview`} alt="" />
      )}
      <div className="award-title">{selectedAward.title}</div>
    </div>
  );

export const ActionsColumnTemplate = ({ props, rootProps, openActionsPopoverTarget, t }) => {
  const hasChildElement = !!props.id;
  const isRootElement =
    !hasChildElement || !rootProps.ratingSystems.length || rootProps.ratingSystems[0].id === props.id;
  const isRatingDashboardAvailable = props.visibleOnDashboard;
  const access = { accessRestricted: rootProps?.accessRestricted, projectId: rootProps?.id };
  const permissions = rootProps.projectPermission;

  if (!permissions.member) {
    return null;
  }

  return (
    <>
      {isRootElement && !hasChildElement && (
        <>
          <IconButton
            size="small"
            onClick={(event) => openActionsPopoverTarget({ target: event.target, props, rootProps, ...access })}
          >
            <MoreVertIcon />
          </IconButton>
        </>
      )}
      {isRootElement && hasChildElement && (
        <>
          <Tooltip title={t("groupingContext.executeEvaluation")}>
            <NavLink
              to={
                (!props.kpiSystemId ? "/project/ratingSystem/" : "/project/kpiRatingSystem/") + props.id + "?mode=edit"
              }
            >
              <IconButton color="primary" size="small">
                <EditRoadIcon />
              </IconButton>
            </NavLink>
          </Tooltip>
          <Tooltip title={t("groupingContext.showRatingDashboard")}>
            {!isRatingDashboardAvailable ? (
              <NavLink
                to={"/project/" + rootProps.id + (props.kpiSystemId ? "/kpiDashboard/" : "/dashboard/") + props.id}
                onClick={(e) => e.preventDefault()}
                className="no-events"
              >
                <IconButton color="primary" size="small" disabled>
                  <AnalyticsIcon />
                </IconButton>
              </NavLink>
            ) : (
              <NavLink
                to={"/project/" + rootProps.id + (props.kpiSystemId ? "/kpiDashboard/" : "/dashboard/") + props.id}
              >
                <IconButton color="primary" size="small">
                  <AnalyticsIcon />
                </IconButton>
              </NavLink>
            )}
          </Tooltip>
          {permissions.member && (
            <IconButton
              size="small"
              onClick={(event) => openActionsPopoverTarget({ target: event.target, props, rootProps, ...access })}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </>
      )}
      {!isRootElement && (
        <>
          <Tooltip title={t("groupingContext.executeEvaluation")}>
            <NavLink
              to={
                (!props.kpiSystemId ? "/project/ratingSystem/" : "/project/kpiRatingSystem/") + props.id + "?mode=edit"
              }
            >
              <IconButton color="primary" size="small">
                <EditRoadIcon />
              </IconButton>
            </NavLink>
          </Tooltip>
          <Tooltip title={t("groupingContext.showRatingDashboard")}>
            {!isRatingDashboardAvailable ? (
              <NavLink
                to={"/project/" + rootProps.id + (props.kpiSystemId ? "/kpiDashboard/" : "/dashboard/") + props.id}
                onClick={(e) => e.preventDefault()}
                className="no-events"
              >
                <IconButton color="primary" size="small" disabled>
                  <AnalyticsIcon />
                </IconButton>
              </NavLink>
            ) : (
              <NavLink
                to={"/project/" + rootProps.id + (props.kpiSystemId ? "/kpiDashboard/" : "/dashboard/") + props.id}
              >
                <IconButton color="primary" size="small">
                  <AnalyticsIcon />
                </IconButton>
              </NavLink>
            )}
          </Tooltip>
          {permissions.member && (
            <IconButton
              size="small"
              onClick={(event) => openActionsPopoverTarget({ target: event.target, props, rootProps, ...access })}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </>
      )}
    </>
  );
};
