export const STATUS_NOT_RATED = 'NOT_RATED';
export const STATUS_IN_PROCESSING = 'IN_PROCESSING';
export const STATUS_RATED = 'RATED';
export const STATUS_CLOSED = 'CLOSED';
export const STATUS_NOT_APPLICABLE = 'NOT_APPLICABLE';

export const INDICATOR_STATUSES = [STATUS_NOT_RATED, STATUS_IN_PROCESSING, STATUS_RATED, STATUS_CLOSED, STATUS_NOT_APPLICABLE];

export const STATUS_VERIFICATION = 'VERIFICATION';
export const STATUS_REPORTING = 'REPORTING';
export const STATUS_SCREENING = 'SCREENING';

export const RATING_SYSTEM_STATUSES = [STATUS_SCREENING, STATUS_VERIFICATION, STATUS_REPORTING];

export const SYSTEM_SCORE_TYPE = 'SCORE';
export const SUPER_SCORE_TYPE = 'SUPER_SCORE';
export const KPI_TYPE = 'KPI';

export const KPI_STATUS_ACTIVE = 'ACTIVE';
export const KPI_STATUS_NONE = 'NONE';
export const KPI_STATUS_WAITING = 'WAITING';
export const KPI_STATUS_CHANGED = 'CHANGED';

export const KPI_STATUSES = [KPI_STATUS_ACTIVE, KPI_STATUS_NONE, KPI_STATUS_WAITING];

export const SCORE_TYPES = { SYSTEM_SCORE_TYPE, SUPER_SCORE_TYPE, KPI_TYPE };
export const GOAL_TYPES = [SYSTEM_SCORE_TYPE, SUPER_SCORE_TYPE];

export const RANGE_COLORS = ['#48a064', '#9fce63', '#fcef4f', '#f5c242', '#eb4b42'];
export const RANGE_COLORS_ESG = [
  { from: 0, color: RANGE_COLORS[4] },
  { from: 20, color: RANGE_COLORS[3] },
  { from: 40, color: RANGE_COLORS[2] },
  { from: 60, color: RANGE_COLORS[1] },
  { from: 80, color: RANGE_COLORS[0] }
];
export const RANGE_COLORS_PAST = [
  { to: 5, color: RANGE_COLORS[0] },
  { to: 4.5, color: RANGE_COLORS[1] },
  { to: 3.5, color: RANGE_COLORS[2] },
  { to: 2.5, color: RANGE_COLORS[3] },
  { to: 1.5, color: RANGE_COLORS[4] }
];

export const MISSING_REASON_FILTER = "MISSING_REASON";
export const MISSING_SCORE_FILTER = "MISSING_SCORE";
export const MISSING_ACTION_FILTER = "MISSING_ACTION";

export const INDICATOR_GROUPING_TYPE = 'indicator'; 

export const DEFAULT_PAST_SUSTAINABILITY_SYSTEM_MAX_SYSTEM_SCORE = 5;
export const DEFAULT_PAST_SUSTAINABILITY_SYSTEM_WEIGHTING_FACTOR = 5;