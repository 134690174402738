
export const WAIT_TIME_TO_SEARCH_ADDRESS = 1000; // wait time (ms) to trigger a search
export const AZURE_MAPS_SUBSCRIPTION_KEY = process.env.REACT_APP_AZURE_MAPS_KEY || 'RbCcI-krXtrgP59D3eJ8Iokat1WybiSWM8d1_AL-OvA';
export const AZURE_MAPS_ADDRESS_SEARCH = 'https://atlas.microsoft.com/search/address/json?&subscription-key=(azureSubscriptionKey)&api-version=1.0&language=(userLang)&query=(searchTerm)';
export const AZURE_MAPS_DEFAULT_PINCOLOR = '#1c304d';
export const AZURE_MAPS_CLUSTER_PROPERTIES = {
  //Scale the size of the clustered bubble based on the number of points inthe cluster.
  radius: [
    'step',
    ['get', 'point_count'],
    20, //Default of 20 pixel radius.
    10,
    30, //If point_count >= 10, radius is 30 pixels.
    20,
    40, //If point_count >= 20, radius is 40 pixels.
  ],

  //Change the color of the cluster based on the value on the point_cluster property of the cluster.
  color: [
    'step',
    ['get', 'point_count'],
    AZURE_MAPS_DEFAULT_PINCOLOR,
    1,
    AZURE_MAPS_DEFAULT_PINCOLOR,
  ],
  strokeWidth: 0,
  filter: ['has', 'point_count'], //Only rendered data points which have a point_count property, which clusters do.
};
export const AZURE_MAPS_CLUSTER_CLICK_ZOOM_DURATION = 500;

// https://docs.microsoft.com/en-us/azure/azure-maps/supported-languages
export const AZURE_MAPS_LANG_EN_GB = 'en-GB';
export const AZURE_MAPS_LANG_DE_DE = 'de-DE';

export const AZURE_MAPS_INITIAL_POSITION = [10, 51]; // [-97, 39];
export const AZURE_MAPS_INITIAL_ZOOM = 5;
