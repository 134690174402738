import CloseIcon from "@mui/icons-material/Close";
import { DialogActions, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../../hooks/auth";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import "./SelectMigrationTargetDialog.scss";

export const SelectMigrationTargetDialog = React.memo(
  ({ open, onClose, title, loading, migrationTargets, onSelect }) => {
    const { t } = useTranslation();
    const user = useUser();

    return (
      <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth={true}>
        <LoadingOverlay
          spinner
          active={loading}
          className="select-migration-tenant-dialog auto-height fit-dialog-content"
        >
          <DialogTitle>
            {title}
            <IconButton className="close-button" size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Table className="modal-table with-borders">
              <TableHead>
                <TableRow>
                  <TableCell className="name-cell">{t("systemMigration.tenantName")}</TableCell>
                  <TableCell className="company-cell">{t("systemMigration.tenantCompany")}</TableCell>
                  <TableCell className="actions-cell" />
                </TableRow>
              </TableHead>
              <TableBody>
                {migrationTargets?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="name-cell">
                      {item.name}{" "}
                      {user.tenant.id === item.id && (
                        <i>
                          <b>({t("projects.copy.currentTenant")})</b>
                        </i>
                      )}
                    </TableCell>
                    <TableCell className="company-cell">{item.companyName}</TableCell>
                    <TableCell className="actions-cell">
                      <Button size="small" color="primary" onClick={() => onSelect(item.id)}>
                        {t("main.copy")}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {!migrationTargets?.length && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <div className="text-center text-muted">{t("main.empty")}</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t("main.close")}</Button>
          </DialogActions>
        </LoadingOverlay>
      </Dialog>
    );
  }
);
