import { Configuration, KpiTemplateEndpointApi } from "../generated-api";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { MIN_PAGE_SIZE } from "../constants/main";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { createTablePageLogic } from "./tablePageLogic";

const kpiApi = new KpiTemplateEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const kpiPageSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKPIPageResponse = hookFromSubject(kpiPageSubject);
export const searchKPI = ({ page, itemsPerPage, sort, search }) => {
  const params = { page: page || 0, itemsPerPage: itemsPerPage || MIN_PAGE_SIZE };
  if (sort) {
    params.sort = sort;
  }
  if (search) {
    params.search = search;
  }
  return promiseToSubject(kpiApi.apiKpiTemplatePageGet({ params }), kpiPageSubject);
};

export const kpiDeleteSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKPIDeleteResponse = hookFromSubject(kpiDeleteSubject);
export const deleteKPI = (id) =>
  promiseToSubject(kpiApi.apiKpiTemplateIdDelete({ id }), kpiDeleteSubject);

export const kpiListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKPIListResponse = hookFromSubject(kpiListSubject);
export const listKPI = () =>
  promiseToSubject(kpiApi.apiKpiTemplateListGet(), kpiListSubject);

export const kpiCreateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKPICreateResponse = hookFromSubject(kpiCreateSubject);
export const createKPI = (item) =>
  promiseToSubject(kpiApi.apiKpiTemplatePost({ item }), kpiCreateSubject);

export const kpiUpdateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKPIUpdateResponse = hookFromSubject(kpiUpdateSubject);
export const updateKPI = (item) =>
  promiseToSubject(kpiApi.apiKpiTemplatePut({ item }), kpiUpdateSubject);

export const kpiGeneralAttributesSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKPIGeneralAttributesResponse = hookFromSubject(kpiGeneralAttributesSubject);
export const getKPIGeneralAttributes = () =>
  promiseToSubject(kpiApi.apiKpiTemplateGeneralAttributesGet(), kpiGeneralAttributesSubject);

export const [useKPIPageLogic, resetKPIPageState] = createTablePageLogic();
