import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { INDICATOR_VALUE_TYPE_RANGE, INDICATOR_VALUE_TYPE_TEXT } from "../../../constants/sustainabilitySystem";
import { calculateUniversalScore } from "../../../helpers/ratingSystem";
import { addBreakLines, adjustRGBFormat, removeExtraBreakLines } from "../../../helpers/richtext";
import { setRatingSystemPotentialChanged } from "../../../hooks/ratingSystem";
import { useEvent } from "../../../hooks/utils/useEvent";
import { FormController } from "../../FormController/FormController";
import { IndicatorValueInput } from "../../IndicatorValueInput/IndicatorValueInput";
import { KPIControl } from "../../KPIControl/KPIControl";
import { EvaluationReason } from "../EvaluationReason/EvaluationReason";

const CUSTOM_VALUE_TAG = "POTENTIAL";

export const PotentialBlock = ({ onChange, row, readOnly, ratingSystemId }) => {
  const { t } = useTranslation();
  const data = row || {};
  const { UID } = data;
  const unit = data.unit;
  const [kpi, setKpi] = useState(null);
  const [reasonState, setReasonState] = useState("");
  const valueType = data.valueType || INDICATOR_VALUE_TYPE_TEXT;
  const allowCustomValue = !!data.allowCustomValue;
  const [valueRangeList, setValueRangeList] = useState(data.valueRangeList || []);

  useEffect(() => {
    setValueRangeList(data?.valueRangeList);
  }, [data?.valueRangeList]);

  useEffect(() => {
    const { potentialEvaluationSystemScore, kpi: kpiInner } = data || {};
    reset({
      potentialEvaluationValue: (data || {})?.potentialEvaluationValue || "",
      customPotentialValue: (data || {})?.customPotentialValue || "",
      potentialEvaluationSystemScore: potentialEvaluationSystemScore || (potentialEvaluationSystemScore === 0 ? 0 : ""),
      potentialReason: (data || {})?.potentialReason || "",
      potentialEvaluationScoringValue: (data || {})?.potentialEvaluationScoringValue || "",
    });
    setKpi(kpiInner);
    setReasonState(data.potentialReason ?? "");
  }, [UID, data?.potentialEvaluationSystemScore, data?.potentialEvaluationValue, data?.customPotentialValue]);

  const { control, reset, setValue, getValues } = useForm({
    defaultValues: {
      potentialEvaluationValue: "",
      customPotentialValue: "",
      potentialEvaluationSystemScore: "",
      potentialEvaluationScoringValue: "",
    },
  });

  const saveChanges = useEvent((updatedKpi, updatedValueRangeList) => {
    const {
      potentialEvaluationValue,
      potentialEvaluationSystemScore,
      customPotentialValue,
      potentialEvaluationScoringValue,
    } = getValues();
    const { maxSystemScore } = row;
    let value = +potentialEvaluationSystemScore;

    if (potentialEvaluationSystemScore < -maxSystemScore) {
      value = -maxSystemScore;
      setValue("potentialEvaluationSystemScore", value);
    }
    if (+potentialEvaluationSystemScore > +maxSystemScore) {
      value = +maxSystemScore;
      setValue("potentialEvaluationSystemScore", value);
    }

    let manualPotentialEvaluationSystemScore = data.manualPotentialEvaluationSystemScore;
    if (
      data.potentialEvaluationValue !== potentialEvaluationValue ||
      data.customPotentialValue !== customPotentialValue ||
      data.potentialEvaluationSystemScore !== potentialEvaluationSystemScore
    ) {
      setRatingSystemPotentialChanged(UID);
      manualPotentialEvaluationSystemScore = 1;
    }
    onChange({
      UID,
      fields: {
        potentialEvaluationValue,
        customPotentialValue,
        potentialEvaluationScoringValue,
        potentialEvaluationSystemScore: potentialEvaluationSystemScore === "" ? null : value,
        potentialUniversalScore: calculateUniversalScore(value, maxSystemScore),
        manualPotentialEvaluationSystemScore,
        potentialReason: reasonState,
        kpi: updatedKpi || kpi,
      },
      valueRangeList: updatedValueRangeList || valueRangeList,
    });
  });

  const saveChangesReason = (reasonEditorValue) => {
    let reason = reasonState;
    if (reasonEditorValue) {
      const { event } = reasonEditorValue;
      let html = event?.html ?? "";

      html = adjustRGBFormat(html);
      html = addBreakLines(html);
      html = removeExtraBreakLines(html);

      reason = html;
      setReasonState(reason);
    }
    saveChanges();
  };

  const onKpiChange = ({ value }) => {
    const updated = { ...kpi, potentialValue: value };
    setKpi(updated);
    saveChanges(updated);
  };

  const isRange = valueType === INDICATOR_VALUE_TYPE_RANGE;

  return (
    <>
      {!!kpi && (
        <>
          <div className="padding-bottom">
            <h4 className="header no-margin">{t("ratingSystem.columnGroup.kpi")}</h4>
            <KPIControl
              kpiUnit={kpi.unit}
              kpiName={kpi.name}
              value={kpi.potentialValue}
              noEstimated
              onChange={onKpiChange}
              readOnly={readOnly}
              linkedGeneralAttributeName={kpi?.linkedGeneralAttributeName}
            />
          </div>
        </>
      )}
      {!isRange && (
        <div className="small-padding-bottom">
          <FormController
            control={control}
            name="potentialEvaluationSystemScore"
            label={t("ratingSystem.potential.evaluationSystemScore")}
            render={({ field, fieldState, label }) => (
              <>
                <TextField
                  onWheel={(event) => event.preventDefault()}
                  error={fieldState.invalid}
                  type="number"
                  fullWidth
                  disabled={readOnly || !data.maxSystemScore}
                  inputProps={{ step: 0.0001, min: -data.maxSystemScore, max: data.maxSystemScore }}
                  label={label}
                  {...field}
                  onBlur={() => saveChanges()}
                />
                <div className="chars-left">0 - {data.maxSystemScore}</div>
              </>
            )}
          />
        </div>
      )}
      <div className="small-padding-bottom">
        {isRange && data.visibleOnTable && (
          <IndicatorValueInput
            value={getValues("potentialEvaluationValue")}
            valueType={valueType}
            valueRangeList={valueRangeList}
            enableCustomValueOption={allowCustomValue}
            customValueTag={CUSTOM_VALUE_TAG}
            maxCustomValue={data?.maxSystemScore}
            unit={unit}
            control={control}
            disabled={readOnly}
            name="potentialEvaluationValue"
            title={
              isRange ? t("ratingSystem.potential.evaluationSystemScore") : t("ratingSystem.reason.evaluationValue")
            }
            onBlur={(event) => {
              const value = event.target.value;
              const option = (valueRangeList || []).find((item) => item.value === value);
              if (option?.systemScore) {
                setValue("potentialEvaluationSystemScore", option.systemScore);
              }
              saveChanges();
            }}
            onSelect={(value, systemScore, isCustom) => {
              if (systemScore !== null && systemScore !== undefined) {
                setValue("potentialEvaluationSystemScore", systemScore);

                if (isCustom) {
                  const updated = [
                    ...valueRangeList.filter((item) => !item?.custom || item?.customValueTag !== CUSTOM_VALUE_TAG),
                    { value, systemScore, custom: true, customValueTag: CUSTOM_VALUE_TAG },
                  ];
                  setValueRangeList(updated);
                  saveChanges(null, updated);
                }
              }
            }}
          />
        )}
      </div>
      {data.visibleOnTable && (
        <div className="small-padding-bottom">
          <FormController
            control={control}
            name="potentialEvaluationScoringValue"
            label={t("ratingSystem.reason.evaluationValue")}
            render={({ field, fieldState, label }) => (
              <FormControl error={fieldState.invalid} fullWidth>
                <TextField
                  {...field}
                  error={fieldState.invalid}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  label={label}
                  disabled={readOnly}
                  onBlur={() => saveChanges()}
                />
                {!!unit && !!data.visibleOnTable && <div className="value-unit">{unit}</div>}
              </FormControl>
            )}
          />
        </div>
      )}
      <>
        {!!readOnly && !!data.potentialReason && (
          <>
            <h4 className="header">{t("ratingSystem.potential.reason")}</h4>
            <div className="rich-text-content" dangerouslySetInnerHTML={{ __html: data.potentialReason }} />
          </>
        )}
        {!readOnly && (
          <>
            <h4 className="header">{t("ratingSystem.potential.reason")}</h4>
            <EvaluationReason value={data.potentialReason} onBlur={saveChangesReason} ratingSystemId={ratingSystemId} />
          </>
        )}
      </>
    </>
  );
};
