import {
  OTHER_TYPES,
  TYPE_AVIATION,
  TYPE_BUILDING,
  TYPE_COMPANY,
  TYPE_DISTRICT,
  TYPE_ESG,
  TYPE_FOOTBALL,
  TYPE_HEALTH_CARE,
  TYPE_HOSPITALITY,
  TYPE_LIVING,
  TYPE_OFFICE,
  TYPE_OTHERS,
  TYPE_PRODUCT,
  TYPE_REAL_ESTATE,
  TYPE_SPORT,
  TYPE_WELLBEING
} from "./sustainabilitySystem";

export const KPI_PREFIX = 'kpi_';

export const ESG_TYPES = [
  { name: TYPE_COMPANY },
  {
    name: TYPE_REAL_ESTATE,
    items: [
      { name: TYPE_BUILDING },
      { name: TYPE_DISTRICT }
    ]
  },
  { name: TYPE_PRODUCT },
  {
    name: TYPE_WELLBEING,
    items: [
      { name: TYPE_AVIATION },
      { name: TYPE_OFFICE },
      { name: TYPE_HEALTH_CARE },
      { name: TYPE_HOSPITALITY },
      { name: TYPE_LIVING }
    ]
  },
  {
    name: TYPE_SPORT,
    items: [
      { name: TYPE_FOOTBALL }
    ]
  }
];

export const PROJECT_TYPES = [
  {
    name: TYPE_ESG,
    items: ESG_TYPES
  },
  {
    name: TYPE_OTHERS,
    items: OTHER_TYPES
  }
];

export const USAGE_TYPE_MULTIPLE_USE = 'MULTIPLE_USE';
export const USAGE_TYPE_OFFICE = 'OFFICE';
export const USAGE_TYPE_RESIDENTIAL = 'RESIDENTIAL';

export const USAGE_TYPES = [USAGE_TYPE_MULTIPLE_USE, USAGE_TYPE_OFFICE, USAGE_TYPE_RESIDENTIAL];

export const CONDITION_EXISTING = 'EXISTING';
export const CONDITION_NEW_BUILDING = 'NEW_BUILDING';
export const CONDITION_RENOVATION = 'RENOVATION';

export const CONDITIONS = [CONDITION_EXISTING, CONDITION_NEW_BUILDING, CONDITION_RENOVATION];

export const MAX_NUMBER_VALUE = 999999.99;
export const NUMBER_RULES = { min: 0, max: MAX_NUMBER_VALUE };

export const INTERNAL_EXTERNAL_ID_MAX_LENGTH = 255;
export const PROJECT_HEADER_INTERNAL_EXTERNAL_ID_MAX_LENGTH = 100;

export const MAX_BUDGET_VALUE = 999999999.99;
export const BUDGET_NUMBER_RULES = { min: 0, max: MAX_BUDGET_VALUE };

export const ACCEPT_IMAGE_FILE_TYPES = ['image/jpg', 'image/png', 'image/jpeg'];

export const DECISION_CASE_EMPTY_VALUE = 'EMPTY_VALUE';
export const DECISION_CASE_EXISTING_VALUE = 'EXISTING_VALUE';
export const DECISION_CASE_REPLACE_ATTRIBUTE = 'REPLACE_ATTRIBUTE';

export const KPI_DECISION_ACCEPT = "ACCEPT";
export const KPI_DECISION_REJECT = "REJECT";

export const DATA_SOURCE_KPI = 'KPI';
export const DATA_SOURCE_PROJECT = 'PROJECT';

export const PROJECT_PERMISSION = {
  OWNER: 'permissionOwner',
  MEMBER: 'member',
  CHANGE_APPLIER: 'permissionChangeApplier',
  DOCUMENT_UPLOAD: 'permissionDocumentUpload',
  STATUS_CHANGER: 'permissionStatusChanger',
  ACCESS: 'permissionReader'
}