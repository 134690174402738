import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../constants/main";
import { PERMISSION_TEXTBLOCK_CREATE_IN_RATING_SYSTEM } from "../../constants/permissions";
import { hasPermission } from "../../helpers/permission";
import { showMuiDialog } from "../../hooks/dialog";
import { useKpiSelectedRow } from "../../hooks/kpiRatingSystem";
import {
  createSettingsTextblock,
  getSuggestedKpiTextblockList,
  getSuggestedTextblockList,
  resetTextblockPageState,
  updatedKpiTextblockAutoTagged,
  updatedTextblockAutoTagged,
  useSuggestedTextblockResponse,
  useTextblockPageLogic,
} from "../../hooks/settingsTextblock";
import { addTextblock, useAddedTextblocks } from "../../hooks/textblock";
import { showError } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { useTimeout } from "../../hooks/utils/useTimeout";
import { getTableState } from "../../pages/RatingSystem/NewRatingSystemTable";
import { processMessage } from "../../utils";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import { SimpleTableWithSort } from "../SimpleTableWithSort/SimpleTableWithSort";
import { TextBlockDialog } from "./TextBlockDialog";
import "./TextBlockSelectDialog.scss";

const MAX_DISPLAY_TEXT_LENGTH = 100;

export const TextBlockSelectDialog = ({ open, onClose, onSelect, ratingSystemId, kpiRatingSystemId }) => {
  const { t } = useTranslation();

  const { selected: indicator } = getTableState();
  const kpi = useKpiSelectedRow();

  const response = useSuggestedTextblockResponse();
  const [, setSearchTimeout] = useTimeout();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const {
    searchValue,
    setSearchValue,
    sortValue,
    onSort,
    updateState,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    resetState,
  } = useTextblockPageLogic();

  const addedTextblocks = useAddedTextblocks();

  useEffect(() => {
    resetTextblockPageState();
  }, []);

  useEffect(() => {
    if (!response.loading) {
      const responseData = response.data || {};
      const dataInner = responseData.content || [];
      const totalInner = (responseData.meta || {}).total || 0;
      setData(dataInner);
      setTotal(totalInner);
    }
  }, [response.loading, response?.data?.content?.length]);

  const load = () => {
    if (response.loading) {
      response.abort();
    }

    if (kpiRatingSystemId) {
      const args = {
        kpiRatingSystemId,
        kpiId: kpi?.id,
      };

      getSuggestedKpiTextblockList(args, {
        page: page + 1,
        itemsPerPage: rowsPerPage,
        sort: sortValue.field ? (sortValue.desc ? "-" : "") + sortValue.field : null,
        name: searchValue,
      });
    } else {
      const args = {
        ratingSystemId,
        indicatorId: indicator?.id,
      };

      getSuggestedTextblockList(args, {
        page: page + 1,
        itemsPerPage: rowsPerPage,
        sort: sortValue.field ? (sortValue.desc ? "-" : "") + sortValue.field : null,
        name: searchValue,
      });
    }
  };

  const afterAddTextBlock = () => {
    updateState({ sortValue: { field: "id", desc: true }, searchValue: "", resetState: resetState + 1 });
  };

  useEffect(load, [searchValue, sortValue.field, sortValue.desc, page, rowsPerPage, ratingSystemId, kpiRatingSystemId]);

  const search = useEvent((event) => {
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(event.target.value || "").trim());
      }, 500)
    );
  });

  const handleAddButton = (row) => {
    addTextblock(row.id);
    autoTaggedTextBlock(row);
    onSelect(row);
  };

  const autoTaggedTextBlock = async (row) => {
    try {
      const textBlockId = row.id;
      if (kpiRatingSystemId) {
        await updatedKpiTextblockAutoTagged(kpiRatingSystemId, kpi?.id, textBlockId);
      } else {
        await updatedTextblockAutoTagged(ratingSystemId, indicator?.id, textBlockId);
      }

      load();
    } catch (error) {
      if (error.status === 409) {
        showError(processMessage(t("textblock.error.409"), [row.name]));
      } else {
        showError(t("error.500"));
      }
    }
  };

  const columns = useMemo(() => {
    return [
      {
        field: "name",
        headerText: t("textblock.name"),
        sortable: true,
        template: (row) => (
          <div className="textblock-name">
            {row.suggested && <StarIcon />}
            <span>{row.name}</span>
          </div>
        ),
      },
      {
        field: "text",
        headerText: t("textblock.text"),
        template: (row) =>
          row.text?.length > MAX_DISPLAY_TEXT_LENGTH ? (
            <Tooltip title={row.text}>
              <div>{row.text.substring(0, MAX_DISPLAY_TEXT_LENGTH)}...</div>
            </Tooltip>
          ) : (
            row.text
          ),
        sortable: true,
      },
      {
        field: "tags",
        headerText: t("textblock.tags"),
        template: (row) => (row.tags || []).join(", "),
      },
      {
        className: "action-cell text-right",
        headerClassName: "single-action-header-cell",
        template: (row) => {
          const selected = addedTextblocks.includes(row.id);
          return (
            <div>
              <IconButton
                color="primary"
                size="small"
                title={t("textblock.dialog.selectTitle")}
                onClick={() => handleAddButton(row)}
                className={selected ? "selected-add-button" : "select-add-button"}
              >
                {!selected && <AddCircleOutlineOutlinedIcon />}
                {selected && <CheckCircleOutlineIcon />}
              </IconButton>
            </div>
          );
        },
      },
    ];
  }, [response.loading, addedTextblocks, data.length, indicator?.id]);

  const pagination = useMemo(
    () => ({
      labelRowsPerPage: t("pagination.rowsPerPage"),
      labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
      rowsPerPageOptions: PAGE_SIZES,
      count: total,
      page: page,
      show: total > MIN_PAGE_SIZE ? "true" : "false",
      onPageChange: (event, pagePagination) => setPage(pagePagination),
      rowsPerPage: rowsPerPage,
      onRowsPerPageChange: (eventPagination) => setRowsPerPage(eventPagination.target.value),
      className: "modal-pagination",
    }),
    [total, page, rowsPerPage]
  );

  const saveTextBlockDialog = async (values, onCloseSaveTextBlock) => {
    try {
      await createSettingsTextblock(values);
      onCloseSaveTextBlock();
      afterAddTextBlock();
    } catch (error) {
      if (error.status === 409) {
        showError(processMessage(t("textblock.error.409"), [values.name]));
      } else {
        showError(t("error.500"));
      }
    }
  };

  const showTextblockDialog = () =>
    showMuiDialog((props) => <TextBlockDialog onSave={saveTextBlockDialog} {...props} />);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={false} className="full-height">
      <LoadingOverlay spinner active={response.loading} className="full-height fit-dialog-content">
        <DialogTitle style={{ marginBottom: 25 }}>
          {t("textblock.dialog.selectTitle")}
          <IconButton onClick={onClose} size="small" className={"pull-right"}>
            <CloseOutlinedIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ overflow: "hidden" }} className="modal-dialog-content">
          <div className="search-box modal-search">
            <SearchIcon />
            <input
              type="text"
              key={resetState}
              defaultValue={searchValue || ""}
              className="search-input input-shadow"
              placeholder={t("main.search")}
              onChange={search}
            />
          </div>

          <SimpleTableWithSort
            rootClassName="no-margin"
            className="overflow-x-hidden table-select-textblock"
            data={data}
            pagination={pagination}
            sortValue={sortValue}
            onSort={onSort}
            columns={columns}
            stickyHeader
          />
        </DialogContent>

        <DialogActions>
          {hasPermission(PERMISSION_TEXTBLOCK_CREATE_IN_RATING_SYSTEM) && (
            <Button type="button" color="primary" onClick={showTextblockDialog}>
              <AddIcon fontSize="inherit" /> {t("textblock.dialog.addTextBlock")}
            </Button>
          )}
          <Button type="button" onClick={onClose}>
            {t("main.close")}
          </Button>
        </DialogActions>
      </LoadingOverlay>
    </Dialog>
  );
};
