import { HUNDRED } from "../constants/main";
import {
  GROUPING_TYPE_CATEGORY,
  GROUPING_TYPE_CRITERION,
  GROUPING_TYPE_INDICATOR,
  SYSTEM_LOGIC_DGNB,
} from "../constants/sustainabilitySystem";
import {
  VALIDATION_ERROR_KO_VALUE,
  VALIDATION_ERROR_MAX_LENGTH,
  VALIDATION_ERROR_REQUIRED,
  VALIDATION_ERROR_SUM_WEIGHT,
  VALIDATION_ERROR_UNIQUE,
  VALIDATION_ERROR_WEIGHT_FACTOR,
} from "../constants/validation";
import {
  forEachRecursive,
  forEachRecursiveReverseStrict,
  isExcludedElement,
  mapRecursive,
  processMessage,
  roundDecimal,
} from "../utils";

export const conditionIsIndicator = (item) => item.type === GROUPING_TYPE_INDICATOR;
export const conditionNotIndicator = (item) => item.type !== GROUPING_TYPE_INDICATOR;
export const conditionIsCriterion = (item) => item.type === GROUPING_TYPE_CRITERION;
export const conditionIsCategory = (item) => item.type === GROUPING_TYPE_CATEGORY;
export const conditionNotCategory = (item) => item.type !== GROUPING_TYPE_CATEGORY;
export const conditionIsGrouping = (item) => conditionIsCriterion(item) || conditionIsCategory(item);

export const conditionIsIndicatorIncluded = (item) => conditionIsIndicator(item) && !isExcludedElement(item);
export const conditionNotIndicatorIncluded = (item) => conditionNotIndicator(item) && !isExcludedElement(item);
export const conditionIsCriterionIncluded = (item) => conditionIsCriterion(item) && !isExcludedElement(item);
export const conditionIsCategoryIncluded = (item) => conditionIsCategory(item) && !isExcludedElement(item);
export const conditionNotCategoryIncluded = (item) => conditionNotCategory(item) && !isExcludedElement(item);

const validations = [
  {
    field: "systemReference",
    type: VALIDATION_ERROR_UNIQUE,
  },
  {
    field: "systemReference",
    type: VALIDATION_ERROR_MAX_LENGTH,
    maxLength: 255,
  },
  {
    field: "name",
    type: VALIDATION_ERROR_REQUIRED,
    condition: conditionNotIndicator,
  },
  {
    field: "name",
    type: VALIDATION_ERROR_MAX_LENGTH,
    maxLength: 255,
    condition: conditionNotIndicator,
  },
  {
    field: "indicatorName",
    type: VALIDATION_ERROR_REQUIRED,
    condition: conditionIsIndicator,
  },
  {
    field: "indicatorName",
    type: VALIDATION_ERROR_MAX_LENGTH,
    maxLength: 255,
    condition: conditionNotIndicator,
  },
  {
    field: "koValue",
    type: VALIDATION_ERROR_KO_VALUE,
  },
];

export const validationErrorMessage = (validationErrors = [], t) => {
  if (validationErrors.find((item) => VALIDATION_ERROR_UNIQUE === item.type)) {
    return t("sustainabilitySystem.uniqueValidationError");
  }
  if (
    validationErrors.find(
      (item) =>
        [VALIDATION_ERROR_REQUIRED, VALIDATION_ERROR_MAX_LENGTH, VALIDATION_ERROR_WEIGHT_FACTOR].indexOf(item.type) !==
        -1
    )
  ) {
    return t("sustainabilitySystem.validationError");
  }
  if (validationErrors.find((item) => [VALIDATION_ERROR_KO_VALUE].indexOf(item.type) !== -1)) {
    return t("sustainabilitySystem.koValueSizeError");
  }
  let parent = null;
  const weightErrors = validationErrors.filter((item) => {
    if (item.type === VALIDATION_ERROR_SUM_WEIGHT) {
      if (!parent) {
        parent = item.parentPath;
      }
      return parent === item.parentPath;
    }
    return false;
  });
  if (weightErrors.length) {
    return processMessage(t("sustainabilitySystem.weightValidationError"), [
      weightErrors.map((item) => item.name).join(", "),
      weightErrors[0].sum,
    ]);
  }
};

const validateGrouping = (grouping = []) => {
  const validationErrors = [];

  const uniques = {};
  forEachRecursive(grouping, (node, tree, index, level, parent, path) => {
    for (let validation of validations) {
      const value = node[validation.field];
      if (!!validation.condition && !validation.condition(node)) {
        continue;
      }
      if (validation.type === VALIDATION_ERROR_REQUIRED && !value) {
        validationErrors.push({ ...validation, path });
      }
      if (validation.type === VALIDATION_ERROR_MAX_LENGTH && value && value.length > validation.maxLength) {
        validationErrors.push({ ...validation, path });
      }
      if (validation.type === VALIDATION_ERROR_KO_VALUE && !!value && value > node["maxSystemScore"]) {
        validationErrors.push({ ...validation, path });
      }
      if (validation.type === VALIDATION_ERROR_UNIQUE && value) {
        if (!uniques[validation.field]) {
          uniques[validation.field] = [];
        }
        if (uniques[validation.field].indexOf(value) !== -1) {
          validationErrors.push({ ...validation, path });
        } else {
          uniques[validation.field].push(value);
        }
      }
    }
  });

  return validationErrors;
};

export const validateGroupingESG = (grouping = []) => {
  const validationErrors = validateGrouping(grouping);

  const validateWeight = (nodes = [], path) => {
    const groupings = nodes.filter(conditionNotIndicatorIncluded);
    if (groupings.length) {
      let allFactorsSet = true;
      groupings.forEach((item) => {
        if (!item.weightingFactor) {
          allFactorsSet = false;
        }
      });
      if (!allFactorsSet) {
        nodes.forEach((item, i) => {
          if (conditionNotIndicatorIncluded(item) && !item.weightingFactor) {
            validationErrors.push({
              type: VALIDATION_ERROR_WEIGHT_FACTOR,
              field: "weightingFactor",
              path: [...path, i],
              parentPath: path,
              name: item.name,
            });
          }
        });
      }
    }
  };

  validateWeight(grouping, []);
  forEachRecursive(grouping, (node, tree, index, level, parent, path) => validateWeight(node.children, path));
  return validationErrors;
};

export const validateGroupingPAST = (grouping = []) => {
  const validationErrors = validateGrouping(grouping);

  const validateWeight = (nodes = [], path) => {
    if (nodes.length) {
      let allFactorsSet = true;
      nodes.forEach((item) => {
        if (!item.weightingFactor) {
          allFactorsSet = false;
        }
      });
      if (!allFactorsSet) {
        nodes.forEach((item, i) => {
          if (!item.weightingFactor) {
            validationErrors.push({
              type: VALIDATION_ERROR_WEIGHT_FACTOR,
              field: "weightingFactor",
              path: [...path, i],
              parentPath: path,
              name: item.name,
            });
          }
        });
      }
    }
  };

  validateWeight(grouping, []);
  forEachRecursive(grouping, (node, tree, index, level, parent, path) => validateWeight(node.children, path));
  return validationErrors;
};

export const validateAwards = (awards = []) => {
  const validationErrors = [];
  awards.forEach((item, index) => {
    if (!item.scoreFrom && item.scoreFrom !== 0) {
      validationErrors.push({ field: "scoreFrom", type: VALIDATION_ERROR_REQUIRED, path: [index] });
    }
    if (!item.scoreTo) {
      validationErrors.push({ field: "scoreTo", type: VALIDATION_ERROR_REQUIRED, path: [index] });
    }
    if (!item.title) {
      validationErrors.push({ field: "title", type: VALIDATION_ERROR_REQUIRED, path: [index] });
    }
    if (item.title && item.title.length > 25) {
      validationErrors.push({ field: "title", type: VALIDATION_ERROR_MAX_LENGTH, maxLength: 25, path: [index] });
    }
  });
  return validationErrors;
};

export const calculateGroupingMaxScore = (grouping) => {
  let maxSystemScore = 0;
  grouping.forEach((item) => {
    if (isExcludedElement(item) || !item.maxSystemScore || item.maxSystemScoreLimit === 0) {
      return null;
    }
    const limit = item.maxSystemScoreLimit;
    const maxScore = +item.maxSystemScore;
    const score = limit !== null && limit < maxScore ? limit : maxScore;
    maxSystemScore += score * (+item.weightingFactor || 1);
  });
  return maxSystemScore || 0;
};

export const sumGroupingWeightedMaxSystemScore = (grouping) => {
  let maxSystemScore = 0;
  grouping.forEach((item) => {
    if (isExcludedElement(item)) {
      return null;
    }
    maxSystemScore += +item.weightedMaxSystemScore;
  });
  return maxSystemScore || 0;
};

export const setIndicatorsWeighting = (indicators = []) => {
  return indicators.map((item) => {
    if (isExcludedElement(item)) {
      return { ...item };
    }
    let weighting;
    if (!indicators.find((indicator) => !+indicator.maxSystemScore)) {
      let maxSystemScoreSum = 0;
      indicators.forEach((indicator) => {
        maxSystemScoreSum += +indicator.maxSystemScore;
      });
      weighting = maxSystemScoreSum ? item.maxSystemScore / maxSystemScoreSum : 0;
    }
    item.weighting = weighting;
    return { ...item, weighting };
  });
};

export const setProportionalWeights = (data, totalScore, systemLogic) => {
  forEachRecursiveReverseStrict(data, (node) => {
    const { weightingFactor } = node;
    if (conditionIsCriterionIncluded(node)) {
      const maxSystemScoreLimit = +node.maxSystemScoreLimit;
      const maxSystemScore = +node.maxSystemScore;
      let score = maxSystemScore;
      if (maxSystemScoreLimit && maxSystemScoreLimit < maxSystemScore) {
        score = maxSystemScoreLimit;
      }
      if (systemLogic === SYSTEM_LOGIC_DGNB && score > HUNDRED) {
        score = HUNDRED;
      }
      node.weightedMaxSystemScore = score ? roundDecimal(score * (+weightingFactor || 1)) : "";
    }
    if (conditionIsCategoryIncluded(node)) {
      let sum = 0;
      node.children.filter(conditionIsCriterionIncluded).forEach((item) => {
        sum += +item.weightedMaxSystemScore;
      });
      if (systemLogic === SYSTEM_LOGIC_DGNB) {
        node.weightedMaxSystemScore = sum;
      } else {
        const maxSystemScoreLimit = +node.maxSystemScoreLimit;
        const maxSystemScore = +node.maxSystemScore;
        let score = maxSystemScore;
        if (maxSystemScoreLimit && maxSystemScoreLimit < maxSystemScore) {
          score = maxSystemScoreLimit;
        }
        node.weightedMaxSystemScore = roundDecimal(score * (+weightingFactor || 1));
      }
    }
  });
  forEachRecursiveReverseStrict(data, (node, tree, i, level, parent) => {
    if (conditionIsCriterionIncluded(node)) {
      node.relativeProportionMaxSystemScore =
        parent.weightedMaxSystemScore > 0 ? (node.weightedMaxSystemScore / parent.weightedMaxSystemScore) * HUNDRED : 0;
    }
    if (conditionIsCategoryIncluded(node)) {
      let sumProportional = 0;
      node.children.filter(conditionIsCriterionIncluded).forEach((item) => {
        sumProportional += item.relativeProportionMaxSystemScore;
      });
      node.relativeProportionMaxSystemScore = sumProportional;
    }
  });
  if (systemLogic === SYSTEM_LOGIC_DGNB) {
    forEachRecursiveReverseStrict(data, (node, tree, i, level, parent) => {
      if (conditionIsCriterionIncluded(node)) {
        node.maxSystemScoreProportion = (parent.weightingFactor || 1) * node.relativeProportionMaxSystemScore;
      }
      if (conditionIsCategoryIncluded(node)) {
        let sumAbsolute = 0;
        node.children.filter(conditionIsCriterionIncluded).forEach((item) => {
          sumAbsolute += item.maxSystemScoreProportion;
        });
        node.maxSystemScoreProportion = sumAbsolute;
      }
      if (conditionIsIndicatorIncluded(node)) {
        node.relativeProportionMaxSystemScore =
          node.maxSystemScore * (parent.weightingFactor / parent.weightedMaxSystemScore) * HUNDRED;
        node.maxSystemScoreProportion =
          (parent.maxSystemScoreProportion / Math.min(parent.maxSystemScore, HUNDRED)) * node.maxSystemScore;
      }
    });
  } else {
    forEachRecursive(data, (node, tree, i, level, parent) => {
      let parentMaxSystemScore = +totalScore;
      let parentProportionalWeight = 1;

      if (parent) {
        parentMaxSystemScore =
          parent.maxSystemScoreLimit !== null && parent.maxSystemScoreLimit !== undefined
            ? +parent.maxSystemScoreLimit
            : +parent.maxSystemScore;
        parentProportionalWeight = +parent.maxSystemScoreProportion / HUNDRED;
      }
      if (parentMaxSystemScore) {
        let proportionalWeight = null;
        const nodeFactor = +node.weightingFactor || 1;
        const nodeMaxSystemScore =
          node.maxSystemScoreLimit !== null && node.maxSystemScoreLimit !== undefined
            ? +node.maxSystemScoreLimit
            : +node.maxSystemScore;
        if (nodeMaxSystemScore) {
          if (conditionIsIndicatorIncluded(node)) {
            proportionalWeight = (nodeMaxSystemScore / parentMaxSystemScore) * parentProportionalWeight;
          } else {
            const nodeValue = nodeMaxSystemScore * nodeFactor;
            proportionalWeight = (nodeValue / parentMaxSystemScore) * parentProportionalWeight;
          }
          if (proportionalWeight > parentProportionalWeight) {
            proportionalWeight = parentProportionalWeight;
          }
        }
        node.maxSystemScoreProportion = proportionalWeight * HUNDRED;
      }
    });
  }
};

export const processAwards = (awards) =>
  awards.map(({ fileId, id, scoreFrom, scoreTo, title, potentialSideThreshold, actualSideThreshold, internalId }) => ({
    fileId,
    id,
    scoreFrom,
    scoreTo,
    title,
    potentialSideThreshold,
    actualSideThreshold,
    internalId,
  }));

export const processGroupingESG = (grouping) => {
  return mapRecursive(
    grouping,
    (
      {
        id,
        uuid,
        sustainabilitySystemId,
        systemReference,
        indicatorName,
        name,
        type,
        weight,
        weightingFactor,
        weightedMaxSystemScore,
        byFactor,
        evaluationStandard,
        maxSystemScore,
        maxSystemScoreLimit,
        maxSystemScoreProportion,
        relativeProportionMaxSystemScore,
        koValue,
        kpis,
        evaluationStandardLinks,
        evaluationStandardFiles,
        unit,
        sideRequirement,
        valueType,
        valueRangeList,
        allowCustomValue,
        originIndicatorElementUuid,
        originGroupingElementUuid,
        visibleOnTable,
        excludable,
        elementAwardThresholds,
      },
      children
    ) => {
      if (type !== GROUPING_TYPE_INDICATOR) {
        const indicatorElements = setIndicatorsWeighting(children.filter(conditionIsIndicator));
        const groupingElements = children.filter(conditionNotIndicator);
        return {
          id,
          uuid,
          sustainabilitySystemId,
          systemReference,
          name,
          type,
          weight,
          weightingFactor,
          weightedMaxSystemScore,
          byFactor,
          maxSystemScore,
          maxSystemScoreLimit,
          maxSystemScoreProportion,
          relativeProportionMaxSystemScore,
          koValue,
          indicatorElements,
          groupingElements,
          sideRequirement,
          originGroupingElementUuid,
          excludable,
          elementAwardThresholds,
        };
      }
      return {
        id,
        uuid,
        sustainabilitySystemId,
        systemReference,
        type,
        name: indicatorName,
        evaluationStandard,
        maxSystemScore,
        maxSystemScoreProportion,
        relativeProportionMaxSystemScore,
        koValue,
        kpi: !!kpis && !!kpis.length,
        kpis: (kpis || []).map((item) => ({ name: item.name, unit: item.unit })),
        evaluationStandardLinks,
        evaluationStandardFiles,
        unit,
        valueType,
        valueRangeList,
        allowCustomValue,
        originIndicatorElementUuid,
        visibleOnTable,
        excludable,
        elementAwardThresholds,
      };
    }
  );
};

export const processGroupingPAST = (grouping) => {
  return mapRecursive(
    grouping,
    (
      {
        id,
        uuid,
        sustainabilitySystemId,
        systemReference,
        indicatorName,
        name,
        type,
        weight,
        weightingFactor,
        maxSystemScore,
        koValue,
        evaluationStandard,
        originIndicatorElementUuid,
        excludable,
      },
      children
    ) => {
      if (type !== GROUPING_TYPE_INDICATOR) {
        const indicatorElements = children.filter(conditionIsIndicator);
        const groupingElements = children.filter(conditionNotIndicator);
        return {
          id,
          uuid,
          sustainabilitySystemId,
          systemReference,
          name,
          type,
          weightingFactor,
          maxSystemScore,
          koValue,
          indicatorElements,
          groupingElements,
          excludable,
        };
      }
      return {
        id,
        uuid,
        sustainabilitySystemId,
        systemReference,
        type,
        name: indicatorName,
        weightingFactor,
        maxSystemScore,
        koValue,
        evaluationStandard,
        originIndicatorElementUuid,
        excludable,
      };
    }
  );
};
