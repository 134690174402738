import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useUser } from "../../../hooks/auth";
import { copyProjectGenerateTimelinePost, copyProjectPost } from "../../../hooks/project";
import { setUserCurrentTenant } from "../../../hooks/users";
import { useEvent } from "../../../hooks/utils/useEvent";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";

export const ProjectCopyConfirmationDialog = ({
  open,
  onClose,
  closeParent,
  tenant,
  projectId,
  projectName,
  refreshProjectList,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUser();
  const [dialogBodyText, setDialogBodyText] = useState(
    t("projects.copy.dialogConfirmCopyBody", {
      projectName: projectName || "",
      tenantName: tenant?.name || "",
    })
  );
  const [dialogConfirmButtonText, setDialogConfirmButtonText] = useState(t("projects.copy.dialogConfirmCopyButton"));
  const [copied, setCopied] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(true);

  const goToProject = useEvent(async () => {
    if (!!tenant && user.tenant.id !== tenant.id) {
      setDialogOpen(false);
      sessionStorage.setItem("afterReloadPath", "/project/" + copied);
      await setUserCurrentTenant(tenant.id);
    } else {
      navigate("/project/" + copied);
      onClose();
      closeParent();
    }
  });

  const callCopyProjectAPI = useEvent(async () => {
    setDialogOpen(false);
    try {
      const projectCopyResponse = await copyProjectPost({
        projectId: projectId,
        sourceTenantId: user.tenant.id,
        targetTenantId: tenant.id,
      });
      await copyProjectGenerateTimelinePost({
        projectId: projectCopyResponse.id,
        tenantId: tenant.id,
      });
      setDialogBodyText(
        t("projects.copy.copySuccess", {
          tenantName: tenant.name || "",
          projectId: projectCopyResponse.id || "",
        })
      );
      if (user.tenant.id === tenant.id) {
        refreshProjectList();
      }
      setCopied(projectCopyResponse.id);
    } catch (e) {
      setDialogBodyText(t("projects.copy.copyError", { tenantName: tenant.name || "" }));
    }
    setDialogConfirmButtonText(null);
    setDialogOpen(true);
  });

  return (
    <ConfirmationDialog
      open={open && dialogOpen}
      titleText={t("projects.copy.copyTitle")}
      bodyText={dialogBodyText}
      closeText={t("main.close")}
      cancelText={t("main.close")}
      onCancel={onClose}
      onClose={onClose}
      confirmText={dialogConfirmButtonText}
      thirdActionText={!!copied && t("projects.copy.toProject")}
      thirdAction={goToProject}
      onConfirm={callCopyProjectAPI}
      color="primary"
    />
  );
};
