import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSettingsTextblockCreateResponse, useSettingsTextblockUpdateResponse } from "../../hooks/settingsTextblock";
import { useEvent } from "../../hooks/utils/useEvent";
import { Chips } from "../Chips/Chips";
import { FormController } from "../FormController/FormController";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";

export const TextBlockDialog = ({ open, data, onClose, onSave }) => {
  const { t } = useTranslation();
  const isEdit = !!data;

  const createResponse = useSettingsTextblockCreateResponse();
  const updateResponse = useSettingsTextblockUpdateResponse();

  const { handleSubmit, control, reset, getValues } = useForm({
    defaultValues: { name: "", text: "" },
  });

  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (data) {
      const { name, text } = data;
      reset({ name, text });
      setTags(data?.tags || []);
    }
  }, [data]);

  const save = useEvent(() => {
    onSave({ ...getValues(), tags }, onClose);
  });

  const loading = createResponse.loading || updateResponse.loading;
  const dialogTitle = t(isEdit ? "textblock.dialog.editTitle" : "textblock.dialog.createTitle");

  return (
    <Dialog open={open} onClose={onClose}>
      <LoadingOverlay spinner active={loading} className="auto-height text-block-dialog fit-dialog-content">
        <DialogTitle>
          {dialogTitle}
          <IconButton onClick={onClose} size="small">
            <CloseOutlinedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormController
            control={control}
            name="name"
            required
            label={t("textblock.name")}
            noErrorText
            render={({ field, fieldState, label }) => (
              <TextField error={fieldState.invalid} fullWidth label={label + " *"} {...field} />
            )}
          />
          <FormController
            control={control}
            name="text"
            required
            label={t("textblock.text")}
            noErrorText
            render={({ field, fieldState, label }) => (
              <TextField multiline error={fieldState.invalid} fullWidth label={label + " *"} {...field} />
            )}
          />
          <Chips chips={tags} onChange={setTags} changeOnBlur />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("main.cancel")}</Button>
          <Button color="primary" onClick={handleSubmit(save)}>
            {t("main.save")}
          </Button>
        </DialogActions>
      </LoadingOverlay>
    </Dialog>
  );
};
