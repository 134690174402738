import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MenuItem, Popover } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../../constants/main";
import { TENANT_STATUS_ENABLED } from "../../../constants/tenant";
import { useTenantsPageLogic } from "../../../hooks/tenant";
import { processMessage } from "../../../utils";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import "./TenantsTable.scss";

export const TenantsTable = ({ data, setFiltered, loading, onEdit, onEditWhitelabel, onDelete }) => {
  const { t } = useTranslation();
  const { searchValue, filterValue, sortValue, onSort, page, rowsPerPage, setPage, setRowsPerPage } =
    useTenantsPageLogic();

  const [processed, setProcessed] = useState([]);
  const [popoverTarget, setPopoverTarget] = useState(null);

  useEffect(() => {
    if (!loading) {
      let updated = [...data];
      // search
      const searchQuery = String(searchValue).trim().toLowerCase();
      if (searchQuery) {
        updated = updated.filter((item) => {
          return (
            String(item.name).toLowerCase().indexOf(searchQuery) !== -1 ||
            String(item.companyName).toLowerCase().indexOf(searchQuery) !== -1 ||
            String(item.type).toLowerCase().indexOf(searchQuery) !== -1
          );
        });
      }
      setFiltered(updated);
      // filter
      updated = updated.filter((item) => {
        if (filterValue) {
          return item.status === filterValue;
        }
        return [];
      });
      // sort
      updated.sort((a, b) => {
        if (a.id && !b.id) {
          return 1;
        }
        if (b.id && !a.id) {
          return -1;
        }
        if (sortValue.field) {
          if (a[sortValue.field] > b[sortValue.field]) {
            return sortValue.desc ? -1 : 1;
          }
          if (a[sortValue.field] < b[sortValue.field]) {
            return sortValue.desc ? 1 : -1;
          }
        }
        return 0;
      });
      setProcessed(updated);
      if (page * rowsPerPage > updated.length) {
        setPage(Math.floor(updated.length / rowsPerPage));
      }
    }
  }, [loading, searchValue, sortValue, filterValue, page, rowsPerPage, data]);

  const pagination = {
    labelRowsPerPage: t("tenants.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: processed.length,
    page: page,
    show: processed.length > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pagePagination) => setPage(pagePagination),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const columns = [
    {
      field: "name",
      headerText: t("tenants.name"),
      headerClassName: "name-column",
      sortable: true,
    },
    {
      field: "companyName",
      headerText: t("tenants.companyName"),
      headerClassName: "company-name-column",
      sortable: true,
    },
    {
      field: "type",
      headerText: t("tenants.type"),
      headerClassName: "type-column",
      sortable: true,
    },
    {
      field: "status",
      headerText: t("tenants.status"),
      headerClassName: "status-column",
      sortable: true,
      template: (row) => (
        <span className={row.status?.toLowerCase() || ""}>
          {row.status === TENANT_STATUS_ENABLED ? t("tenants.enabled") : t("tenants.disabled")}
        </span>
      ),
    },
    {
      field: "defaultWhitelabel",
      headerText: t("whitelabel.title"),
      headerClassName: "whitelabel-column",
      template: (row) =>
        row.defaultWhitelabel === null ? (
          ""
        ) : row.defaultWhitelabel ? ( // temporary check for case when old tenant no have whitelabel, shall remove after set whitelabel for all tenants
          <span style={{ color: "var(--NEUTRAL_TEXT_COLOR)" }}>{t("whitelabel.whitelabelTypes.standard")}</span>
        ) : (
          <span style={{ color: "var(--PRIMARY_BACKGROUND_COLOR)" }}>{t("whitelabel.whitelabelTypes.adapt")}</span>
        ),
    },
    {
      field: "tenantIsDefault",
      headerClassName: "status-column",
      template: (row) => !!row.tenantIsDefault && <span className="default">{t("tenants.default")}</span>,
    },
    {
      field: "actions",
      headerClassName: "actions-column",
      template: (row) => (
        <IconButton onClick={(e) => setPopoverTarget({ target: e.target, row })} size="small">
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <SimpleTableWithSort
        className="tenants-table"
        data={processed}
        sortValue={sortValue}
        onSort={onSort}
        pagination={pagination}
        passThrough={{ t, onDelete, onEdit }}
        columns={columns}
      />
      <Popover
        open={!!popoverTarget}
        anchorEl={popoverTarget?.target}
        onClose={() => setPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {popoverTarget?.row?.defaultWhitelabel !== null && ( // temporary check for case when old tenant no have whitelabel, shall remove after set whitelabel for all tenants
          <MenuItem
            onClick={() => {
              onEditWhitelabel(popoverTarget?.row);
              setPopoverTarget(null);
            }}
          >
            {t("whitelabel.title")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            onEdit(popoverTarget?.row);
            setPopoverTarget(null);
          }}
        >
          {t("tenants.dialog.edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete(popoverTarget?.row);
            setPopoverTarget(null);
          }}
        >
          {t("tenants.dialog.delete")}
        </MenuItem>
      </Popover>
    </>
  );
};
