import React from "react";
import { useTranslation } from "react-i18next";
import { FilterBlocks } from "../../FilterBlocks/FilterBlocks";
import "./TenantsFilter.scss";
import { TENANT_STATUS_ENABLED, TENANT_STATUS_DISABLED } from "../../../constants/tenant";

export const TenantsFilter = ({ value, onChange, records, disabled }) => {
  const { t } = useTranslation();

  const amounts = {
    [TENANT_STATUS_ENABLED]: 0,
    [TENANT_STATUS_DISABLED]: 0,
  };

  (records || []).forEach(item => {
    item.status === TENANT_STATUS_ENABLED ? amounts[TENANT_STATUS_ENABLED]++ : amounts[TENANT_STATUS_DISABLED]++;
  });

  const filterOptions = [
    {
      name: TENANT_STATUS_ENABLED,
      title: t('tenants.filter.' + TENANT_STATUS_ENABLED),
      amount: amounts[TENANT_STATUS_ENABLED]
    },
    {
      name: TENANT_STATUS_DISABLED,
      title: t('tenants.filter.' + TENANT_STATUS_DISABLED),
      amount: amounts[TENANT_STATUS_DISABLED]
    }
  ];

  return (
    <FilterBlocks filterOptions={filterOptions} value={value} onChange={onChange} allowDeselect showReset={!!value}
                  disabled={disabled}/>
  );
};
