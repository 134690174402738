import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { SimpleTableWithSort } from "../../components/SimpleTableWithSort/SimpleTableWithSort";
import { TextBlockDialog } from "../../components/TextBlock/TextBlockDialog";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../constants/main";
import { PERMISSION_TEXTBLOCK_CREATE, PERMISSION_TEXTBLOCK_EDIT } from "../../constants/permissions";
import { hasPermission } from "../../helpers/permission";
import { hideMuiDialog, showMuiDialog } from "../../hooks/dialog";
import {
  createSettingsTextblock,
  deleteSettingsTextblock,
  getAdminProjectAttributeList,
  resetTextblockPageState,
  updateSettingsTextblock,
  useSettingsTextblockCreateResponse,
  useSettingsTextblockDeleteResponse,
  useSettingsTextblockListResponse,
  useSettingsTextblockUpdateResponse,
  useTextblockPageLogic,
} from "../../hooks/settingsTextblock";
import { showError } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { useTimeout } from "../../hooks/utils/useTimeout";
import { processMessage } from "../../utils";
import "./SettingsTextBlock.scss";

const MAX_DISPLAY_TEXT_LENGTH = 100;

export const SettingsTextBlockPage = () => {
  const { t } = useTranslation();

  const response = useSettingsTextblockListResponse();
  const updateResponse = useSettingsTextblockUpdateResponse();
  const createResponse = useSettingsTextblockCreateResponse();
  const deleteResponse = useSettingsTextblockDeleteResponse();

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const { searchValue, setSearchValue, sortValue, onSort, page, rowsPerPage, setPage, setRowsPerPage, resetState } =
    useTextblockPageLogic();
  const [, setSearchTimeout] = useTimeout();

  const [textBlockDialogIndex, setTextBlockDialogIndex] = useState(null);
  const [textBlockDialogData, setTextBlockDialogData] = useState(null);

  const openTextBlockDialog = useEvent((dataInner = null) => {
    setTextBlockDialogData(dataInner);
    const textBlockDialogIndexInner = showMuiDialog((props) => (
      <TextBlockDialog data={dataInner} onSave={saveTextBlockDialog} {...props} />
    ));
    setTextBlockDialogIndex(textBlockDialogIndexInner);
  });

  const closeTextBlockDialog = useEvent(() => {
    hideMuiDialog(textBlockDialogIndex);
    setTextBlockDialogIndex(null);
  });

  const saveTextBlockDialog = useEvent(async (values) => {
    try {
      if (textBlockDialogData) {
        await updateSettingsTextblock({ ...textBlockDialogData, ...values });
      } else {
        await createSettingsTextblock(values);
      }
      closeTextBlockDialog();
      afterChange();
    } catch (error) {
      if (error.status === 409) {
        showError(processMessage(t("textblock.error.409"), [values.name]));
      } else {
        showError(t("error.500"));
      }
    }
  });

  const load = () => {
    getAdminProjectAttributeList({
      page: page + 1,
      itemsPerPage: rowsPerPage,
      sort: sortValue.field ? (sortValue.desc ? "-" : "") + sortValue.field : undefined,
      name: searchValue,
    })
      .then()
      .catch((error) => {
        console.error(error);
        showError(t("error.500"));
      });
  };

  useEffect(() => {
    resetTextblockPageState();
  }, []);

  useEffect(load, [searchValue, sortValue.field, sortValue.desc, page, rowsPerPage]);

  useEffect(() => {
    if (!response.loading) {
      const responseData = response.data || {};
      const dataInner = responseData.content || [];
      const totalTotal = (responseData.meta || {}).total || 0;
      setData(dataInner);
      setTotal(totalTotal);
    }
  }, [response.loading]);

  const deleteHandler = useEvent(async () => {
    setDeleteConfirmation(null);
    try {
      await deleteSettingsTextblock(deleteConfirmation.id);
      afterChange();
    } catch (error) {
      showError(t("error.500"));
    }
  });

  const search = useEvent((event) => {
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(event.target.value || "").trim());
      }, 500)
    );
  });

  const afterChange = () => (page === 0 ? load() : setPage(0));

  const columns = useMemo(() => {
    const columnsInner = [
      {
        field: "name",
        headerText: t("textblock.name"),
        sortable: true,
      },
      {
        field: "text",
        headerText: t("textblock.text"),
        template: (row) =>
          row.text?.length > MAX_DISPLAY_TEXT_LENGTH ? (
            <Tooltip title={row.text}>
              <div>{row.text.substring(0, MAX_DISPLAY_TEXT_LENGTH)}...</div>
            </Tooltip>
          ) : (
            row.text
          ),
        sortable: true,
      },
      {
        field: "tags",
        headerText: t("textblock.tags"),
        template: (row) => (row.tags || []).join(", "),
      },
    ];
    if (hasPermission(PERMISSION_TEXTBLOCK_EDIT)) {
      columnsInner.push({
        className: "action-cell",
        headerClassName: "action-header-cell",
        headerText: t("main.actions"),
        template: (row) => (
          <>
            <IconButton color="primary" size="small" onClick={() => openTextBlockDialog(row)}>
              <EditIcon />
            </IconButton>
            <IconButton size="small" color="secondary" onClick={() => setDeleteConfirmation(row)}>
              <DeleteOutlinedIcon />
            </IconButton>
          </>
        ),
      });
    }
    return columnsInner;
  }, []);

  const pagination = useMemo(
    () => ({
      labelRowsPerPage: t("pagination.rowsPerPage"),
      labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
      rowsPerPageOptions: PAGE_SIZES,
      count: total,
      page: page,
      show: total > MIN_PAGE_SIZE ? "true" : "false",
      onPageChange: (event, pagePagination) => setPage(pagePagination),
      rowsPerPage: rowsPerPage,
      onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
    }),
    [total, page, rowsPerPage]
  );

  const loading = response.loading || updateResponse.loading || createResponse.loading || deleteResponse.loading;

  return (
    <>
      <div className="textblock-page page-with-table">
        <div className="page-header large flex-row">
          <div className="flex-col title-box no-margin">
            <h1>{t("textblock.title")}</h1>
          </div>
          <div className="flex-col search-box no-margin">
            <SearchIcon />
            <input
              type="text"
              key={resetState}
              defaultValue={searchValue || ""}
              className="search-input input-shadow"
              placeholder={t("main.search")}
              onChange={search}
            />
          </div>
        </div>

        <div className="page-layout">
          <LoadingOverlay spinner active={loading} className="table-loading">
            <SimpleTableWithSort
              className="repaint"
              data={data}
              pagination={pagination}
              sortValue={sortValue}
              onSort={onSort}
              columns={columns}
            />
          </LoadingOverlay>
        </div>

        <ConfirmationDialog
          open={!!deleteConfirmation}
          onClose={() => setDeleteConfirmation(null)}
          onConfirm={deleteHandler}
          titleText={t("textblock.deleteConfirmationTitle")}
          bodyText={t("textblock.deleteConfirmation")}
          confirmText={t("main.delete")}
          color="secondary"
        />

        {hasPermission(PERMISSION_TEXTBLOCK_CREATE) && (
          <div className="fabs">
            <Fab type="button" color="primary" onClick={() => openTextBlockDialog()}>
              <AddIcon className="fab-svg" />
            </Fab>
          </div>
        )}
      </div>
    </>
  );
};
