import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { RoleDataDialog } from "../../components/RoleDataDialog/RoleDataDialog";
import { RoleFilter } from "../../components/Roles/RolesFilter/RolesFilter";
import { RolesTable } from "../../components/Roles/RolesTable/RolesTable";
import { handleError } from "../../helpers/error";
import { checkPopState } from "../../hooks";
import { showDialog } from "../../hooks/dialog";
import {
  resetRolesPageState,
  roleDeleteById,
  roleList,
  useRoleDeleteByIdResponse,
  useRoleListResponse,
  useRolesPageLogic,
} from "../../hooks/role";
import { getTenantList, useTenantListResponse } from "../../hooks/tenant";
import { useTimeout } from "../../hooks/utils/useTimeout";
import { HintArrowSVG } from "../../svg";
import "./Roles.scss";

export const Roles = () => {
  const { t } = useTranslation();

  const response = useRoleListResponse();
  const deleteResponse = useRoleDeleteByIdResponse();
  const tenantListResponse = useTenantListResponse();
  const readOnly = false;

  const tenantList = tenantListResponse.data || [];
  const [tenant, setTenant] = useState(null);
  const [init, setInit] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const { searchValue, setSearchValue, filterValue, setFilterValue } = useRolesPageLogic();
  const [, setSearchTimeout] = useTimeout();
  const [filtered, setFiltered] = useState([]);
  const rolesList = response.data || [];

  useEffect(() => {
    getTenantList().then().catch(console.error);
  }, []);

  useEffect(() => {
    if (!checkPopState()) {
      resetRolesPageState();
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init && tenant) {
      load();
    }
  }, [init, tenant]);

  const load = () => roleList(tenant?.id).then().catch(handleError);
  const search = (value) => {
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(value || "").trim());
      }, 500)
    );
  };

  const selectTenant = (event) => setTenant(tenantList.find((item) => item.id === event.target.value));
  const deleteHandler = () => {
    setDeleteConfirmation(null);
    roleDeleteById(deleteConfirmation.id).then(load).catch(handleError);
  };

  const openRoleDialog = (row) => {
    const readOnlyInner = false;
    showDialog({
      className: "small",
      closeOnClickOutside: !!readOnlyInner,
      getContent: (onClose) => (
        <RoleDataDialog row={row} tenantId={tenant?.id} onClose={onClose} onChange={load} readOnly={readOnlyInner} />
      ),
    });
  };

  return (
    <LoadingOverlay spinner active={tenantListResponse.loading || deleteResponse.loading} className="auto-height">
      <div className="roles-page page-with-table">
        <div className="page-header large flex-row">
          <div className="flex-col title-box no-margin">
            <h1>{t("roles.title")}</h1>
          </div>
          <div className="flex-col search-box no-margin">
            <SearchIcon />
            <input
              type="text"
              defaultValue={searchValue || ""}
              className="search-input input-shadow"
              disabled={!tenant}
              placeholder={t("main.search")}
              onChange={(event) => search(event.target.value)}
            />
          </div>
        </div>

        <div className="page-layout">
          <div className="flex-row-filter">
            <RoleFilter onChange={setFilterValue} value={filterValue} records={filtered} disabled={!tenant} />
            <FormControl className="select-tenant">
              <InputLabel htmlFor="rolesSelectTenant">{t("roles.tenant") + " *"}</InputLabel>
              <Select id="rolesSelectTenant" onChange={selectTenant} value={tenant?.id || ""}>
                {tenantList.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {!!tenant && (
            <LoadingOverlay spinner active={response.loading} className="table-loading">
              <RolesTable
                data={rolesList}
                loading={response.loading}
                onDelete={(row) => setDeleteConfirmation(row)}
                onEdit={openRoleDialog}
                setFiltered={setFiltered}
                readOnly={readOnly}
              />
            </LoadingOverlay>
          )}
          {!tenant && (
            <div className="not-selected-hint">
              <p>{t("roles.selectTenantHint")}</p>
              <HintArrowSVG />
            </div>
          )}
        </div>

        <ConfirmationDialog
          open={!!deleteConfirmation}
          onClose={() => setDeleteConfirmation(null)}
          onConfirm={deleteHandler}
          titleText={t("roles.roleDeleteConfirmationTitle")}
          bodyText={t("roles.roleDeleteConfirmation")}
          confirmText={t("main.delete")}
          color="secondary"
        />

        {!!tenant && !readOnly && (
          <div className="fabs">
            <Fab type="button" color="primary" onClick={() => openRoleDialog({})}>
              <AddIcon className="fab-svg" />
            </Fab>
          </div>
        )}
      </div>
    </LoadingOverlay>
  );
};
