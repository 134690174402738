import React from "react";
import "./ContentBlock.scss";
import { ContentBlockError } from "./ContentBlockError/ContentBlockError";

export const ContentBlock = ({ error, children, className, innerClassName, hidden }) => {
  return (<div className={"content-block-component " + (className || '')} hidden={hidden}>
    {error && "object" === typeof error && error.length ?
      error.map((item, index) => (
        <ContentBlockError key={index} show={!!item} text={item}/>
      ))
      :
      <ContentBlockError show={!!error} text={error}/>
    }
    <div className={'content-block-inner ' + (innerClassName || '')}>
      {children}
    </div>
  </div>);
};
