import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormController } from "../FormController/FormController";
import "./LinkDialog.scss";

export const LinkDialog = ({ open, onClose, titleText, data, onSave }) => {
  const { t } = useTranslation();

  const { control, getValues, trigger } = useForm({
    defaultValues: { value: (data || {}).value || "", title: (data || {}).title || "" },
  });

  const onSubmit = async () => {
    const result = await trigger();
    if (result) {
      onSave(getValues());
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="link-dialog small">
      <DialogTitle>
        {titleText}{" "}
        <IconButton onClick={onClose} size="small">
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>

      <FormController
        control={control}
        name="value"
        required
        label={t("link.value")}
        render={({ field, fieldState, label }) => (
          <TextField
            error={fieldState.invalid}
            fullWidth
            inputProps={{ maxLength: 255 }}
            label={label + " *"}
            {...field}
          />
        )}
      />
      <FormController
        control={control}
        name="title"
        label={t("link.title")}
        render={({ field, fieldState, label }) => (
          <TextField error={fieldState.invalid} fullWidth inputProps={{ maxLength: 255 }} label={label} {...field} />
        )}
      />

      <div className="buttons text-right">
        <Button onClick={onClose}>{t("main.cancel")}</Button>
        <Button color="primary" onClick={onSubmit}>
          {t("main.save")}
        </Button>
      </div>
    </Dialog>
  );
};
