import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import { v4 as getUUID } from "uuid";
import { setPopState } from "../../hooks";
import "./Breadcrumb.scss";

export const Breadcrumb = ({ pathParts, className }) => {
  const parts = pathParts || [];

  return (
    <div className={"breadcrumb" + (className ? ` ${className}` : "")}>
      {parts.map((item, index) => {
        const isFirst = !index;
        const isLast = index === parts.length - 1;

        return (
          <div key={getUUID()} className="crumb">
            {!isLast && item.url && (
              <NavLink to={item.url} onClick={setPopState}>
                {isFirst && <ArrowBackIcon />}
                <span>{item.text || "-"}</span>
              </NavLink>
            )}
            {!isLast && !item.url && item.onClick && (
              <Link
                onClick={(event) => {
                  setPopState();
                  item.onClick(event);
                }}
              >
                {isFirst && <ArrowBackIcon />}
                <span>{item.text || "-"}</span>
              </Link>
            )}
            {isLast && <span>{item.text || "-"}</span>}
          </div>
        );
      })}
    </div>
  );
};

Breadcrumb.propTypes = {
  pathParts: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  className: PropTypes.string,
};
