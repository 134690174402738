import React from "react";
import { AuthenticationType } from 'azure-maps-control';
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapLayerProvider,
  AzureMapsProvider,
} from 'react-azure-maps';
import "./ProjectsMap.scss";
import { AZURE_MAPS_CLUSTER_CLICK_ZOOM_DURATION, AZURE_MAPS_CLUSTER_PROPERTIES, AZURE_MAPS_SUBSCRIPTION_KEY } from "../../constants/azure";

const azureSubscriptionKey = AZURE_MAPS_SUBSCRIPTION_KEY;

export const ProjectsMap = ({ initialPosition, initialZoom, coordinates, projectDetailsCallback, mapLanguage }) => {

  const option = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: azureSubscriptionKey,
    },
    center: initialPosition,
    zoom: initialZoom,
    // style: 'night',
    view: 'Auto',
    language: mapLanguage ? mapLanguage : 'en-GB'
  };

  const bubbleLayerOptions = AZURE_MAPS_CLUSTER_PROPERTIES;

  const mouseOn = (e) => {
    e.map.getCanvas().style.cursor = 'pointer';
  }

  const mouseLeave = (e) => {
    e.map.getCanvas().style.cursor = '';
  }

  const clusterClicked = (e) => {
    if (e && e.shapes && e.shapes.length > 0 && e.shapes[0].properties.cluster) {
      //Get the clustered point from the event.
      const cluster = e.shapes[0];

      //Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.
      e.map.sources
        .getById('projectsMap')
        .getClusterExpansionZoom(cluster.properties.cluster_id)
        .then(function (zoom) {
          //Update the map camera to be centered over the cluster.
          e.map.setCamera({
            center: cluster.geometry.coordinates,
            zoom: zoom,
            type: 'ease',
            duration: AZURE_MAPS_CLUSTER_CLICK_ZOOM_DURATION,
          });
        });
    }
  }

  const pinClick = (e) => {
    const projectId = e.shapes[0].getProperties().id;
    const projsOnSameLatLong = e.shapes[0].getProperties().projsOnSameLatLong;
    projectDetailsCallback({ projectId: projectId, projsOnSameLatLong: projsOnSameLatLong });
  }

  return (
    <>
      {coordinates &&
        <div className="mapWrapper" style={{display: "grid"}}>
          <AzureMapsProvider>
            <div className={'projectsMap'}>
              <AzureMap options={option}>
                <AzureMapDataSourceProvider
                  id={'projectsMap'}
                  options={{ cluster: true, clusterMaxZoom: 15, clusterRadius: 30 }}
                  collection={coordinates}
                >
                  <AzureMapLayerProvider
                    id={'BubbleLayer LayerProvider'}
                    options={bubbleLayerOptions}
                    type="BubbleLayer"
                    events={{
                      mouseenter: mouseOn,
                      mouseleave: mouseLeave,
                      click: clusterClicked,
                    }}
                  />
                  <AzureMapLayerProvider
                    id={'BubbleLayer2 LayerProvider'}
                    options={{
                      iconOptions: {
                        image: 'none', //Hide the icon image.
                      },
                      textOptions: {
                        textField: ['get', 'point_count_abbreviated'],
                        offset: [0, 0.4],
                        color: "white"
                      },
                    }}
                    type="SymbolLayer"
                  />
                  <AzureMapLayerProvider
                    id={'BubbleLayer3 LayerProvider'}
                    options={{
                      filter: ['!', ['has', 'point_count']], //Filter out clustered points from this layer.
                      textOptions: {
                        textField: ['get', 'pinNumber'],
                        offset: [0, -1.1],
                        color: "white"
                      },
                      iconOptions: {
                        image: 'marker-darkblue',
                      },
                    }}
                    events={{
                      mouseenter: mouseOn,
                      mouseleave: mouseLeave,
                      click: pinClick,
                    }}
                    type="SymbolLayer"
                  />
                </AzureMapDataSourceProvider>
              </AzureMap>
            </div>
          </AzureMapsProvider>
        </div>
      }
    </>
  )
};
