import { isObjectChanged, isObjectDeepChanged, mergeArrayOfObjects, mergeObject, mergeRecursive } from "./merge";
import { mapSustainabilitySystemGroupings } from "../sustainabilitySystem";
import { GROUPING_TYPE_CRITERION, GROUPING_TYPE_INDICATOR } from "../../constants/sustainabilitySystem";

export const mergeRatingSystem = (initial = {}, current = {}, updated = {}) => {
  let withConflicts = false;

  const fieldsToMerge = [
    'score',
    'superScore',
    'potentialScore',
    'potentialSuperScore',
    'overallMaxSystemScore',
    'ratingStatus',
    'koFailed',
    'selectedAwardId',
    'analysisDate'
  ];

  const [merged, objectConflicts] = mergeObject(initial, current, updated, fieldsToMerge);
  if (objectConflicts) {
    withConflicts = true;
  }
  merged.modifiedDateTime = current.modifiedDateTime;

  const groupingFieldsToMerge = [
    'UID',
    'id',

    'reason',
    'evaluationValue',
    'evaluationSystemScore',
    'universalScore',
    'indicatorStatus',
    'potentialEvaluationValue',
    'potentialEvaluationSystemScore',
    'potentialUniversalScore',
    'potentialReason',
    'weightedPotentialSystemScore',
    'manualPotentialEvaluationSystemScore',
    'maxSystemScoreProportion',
    'weightingFactor',
    'weightedSystemScore',
    'degreeOfFulfillment',
    'excluded',

    'absoluteDegreeOfFulfillment',
    'maxSystemScore',
    'weightedMaxSystemScore',
    'relativeProportionMaxSystemScore',
  ];

  initial?.ratingSystemGoals?.forEach(ratingGoal => {
    ratingGoal?.goals?.forEach(goal => delete goal.year);
  });

  const [ratingSystemGoals, ratingSystemGoalsConflict] = mergeArrayOfObjects(
    initial?.ratingSystemGoals,
    current?.ratingSystemGoals,
    updated?.ratingSystemGoals,
    null, "id");
  merged.ratingSystemGoals = ratingSystemGoals;
  if (ratingSystemGoalsConflict) {
    withConflicts = true;
  }

  const mergeFn = (a, b, c) => {
    let withConflict = false;
    let result;
    if (b) {
      const [mergedInner, mergeConflict] = mergeObject(a, b, c, groupingFieldsToMerge);
      result = mergedInner;
      if (mergeConflict) {
        withConflict = true;
      }
      if (result.type === GROUPING_TYPE_INDICATOR) {
        const [valueRangeList, valueRangeListConflict] = mergeArrayOfObjects(a?.valueRangeList, b?.valueRangeList, c?.valueRangeList, null, "value");
        result.valueRangeList = valueRangeList;
        if (valueRangeListConflict) {
          withConflict = true;
        }
        if (a.kpi) {
          const [kpi, kpiConflict] = mergeObject(a?.kpi, b?.kpi, c?.kpi);
          result.kpi = kpi;
          if (kpiConflict) {
            withConflict = true;
          }
        }
        const [evaluationFiles, evaluationFilesConflict] = mergeArrayOfObjects(
          a?.evaluationFiles,
          b?.evaluationFiles,
          c?.evaluationFiles,
          null, "id");
        result.evaluationFiles = evaluationFiles;
        if (evaluationFilesConflict) {
          withConflict = true;
        }
        const [evaluationLinks, evaluationLinksConflict] = mergeArrayOfObjects(
          a?.evaluationLinks,
          b?.evaluationLinks,
          c?.evaluationLinks,
          null, "id");
        result.evaluationLinks = evaluationLinks;
        if (evaluationLinksConflict) {
          withConflict = true;
        }
      }
      if (result.type === GROUPING_TYPE_INDICATOR || result.type === GROUPING_TYPE_CRITERION) {
        const actionFieldsSet = new Set();
        Object.keys({...a?.actions, ...b?.actions, ...c?.actions}).forEach(key => actionFieldsSet.add(key));
        actionFieldsSet.delete('tags');
        const [actions, actionsConflict] = mergeArrayOfObjects(
          a?.actions,
          b?.actions,
          c?.actions,
          [...actionFieldsSet], "id");
        result.actions = actions;
        if (actionsConflict) {
          withConflict = true;
        }
      }
    } else {
      result = c;
      withConflict = true;
    }

    return [result, withConflict];
  };

  const isChangedFn = (a, b) => {
    let isChanged = isObjectChanged(a, b, groupingFieldsToMerge);
    if (isObjectChanged(a?.kpi, b?.kpi)
      || isObjectDeepChanged(a?.evaluationFiles, b?.evaluationFiles)
      || isObjectDeepChanged(a?.evaluationLinks, b?.evaluationLinks)
      || isObjectDeepChanged(a?.actions, b?.actions)) {
      isChanged = true;
    }
    return isChanged;
  };

  const [children, childrenConflicts] = mergeRecursive(
    mapSustainabilitySystemGroupings(initial.groupingElements),
    mapSustainabilitySystemGroupings(current.groupingElements),
    mapSustainabilitySystemGroupings(updated.groupingElements),
    mergeFn, isChangedFn, 'children', 'UID'
  );
  if (childrenConflicts) {
    withConflicts = true;
  }

  return [{ ...merged, children }, withConflicts];
};

