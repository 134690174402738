import { Configuration, SustainabilitySystemEndpointApi } from "../generated-api";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { createTablePageLogic } from "./tablePageLogic";

const sustainabilitySystemApi = new SustainabilitySystemEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const sustainabilitySystemsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSustainabilitySystemsResponse = hookFromSubject(sustainabilitySystemsSubject);
export const getSustainabilitySystems = () =>
  promiseToSubject(sustainabilitySystemApi.apiSustainabilitySystemListGet(), sustainabilitySystemsSubject);

export const sustainabilitySystemSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSustainabilitySystemResponse = hookFromSubject(sustainabilitySystemSubject);
export const getSustainabilitySystem = (id) =>
  promiseToSubject(sustainabilitySystemApi.apiSustainabilitySystemIdGet({ id }), sustainabilitySystemSubject);

export const sustainabilitySystemCreateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSustainabilitySystemCreateResponse = hookFromSubject(sustainabilitySystemCreateSubject);
export const createSustainabilitySystem = (request) =>
  promiseToSubject(sustainabilitySystemApi.apiSustainabilitySystemPost({ request }), sustainabilitySystemCreateSubject);

export const sustainabilitySystemUpdateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSustainabilitySystemUpdateResponse = hookFromSubject(sustainabilitySystemUpdateSubject);
export const updateSustainabilitySystem = (id, request) =>
  promiseToSubject(sustainabilitySystemApi.apiSustainabilitySystemPut({
    request: { ...request, id }
  }), sustainabilitySystemUpdateSubject);

export const sustainabilitySystemDeleteSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSustainabilitySystemDeleteResponse = hookFromSubject(sustainabilitySystemDeleteSubject);
export const deleteSustainabilitySystem = (id) =>
  promiseToSubject(sustainabilitySystemApi.apiSustainabilitySystemIdDelete({ id }), sustainabilitySystemDeleteSubject);

export const sustainabilitySystemCloneSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSustainabilitySystemCloneResponse = hookFromSubject(sustainabilitySystemCloneSubject);
export const cloneSustainabilitySystem = (id) =>
  promiseToSubject(sustainabilitySystemApi.apiSustainabilitySystemIdClonePost({ id }), sustainabilitySystemCloneSubject);

export const migrationTargetsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useMigrationTargetsResponse = hookFromSubject(migrationTargetsSubject);
export const getMigrationTargets = () =>
  promiseToSubject(sustainabilitySystemApi.apiSustainabilitySystemMigrationTargetsGet(), migrationTargetsSubject);

export const migrationSubject = new BehaviorSubject(new PromiseSubjectState());
export const useMigrationResponse = hookFromSubject(migrationSubject);
export const migrateSystem = (id, tenantId) =>
  promiseToSubject(sustainabilitySystemApi.apiSustainabilitySystemIdMigrationExecuteTenantIdPost({id, tenantId}), migrationSubject);

export const sustainabilitySystemRevisionsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSustainabilitySystemRevisionsResponse = hookFromSubject(sustainabilitySystemRevisionsSubject);
export const getSustainabilitySystemRevisions = (id) =>
  promiseToSubject(sustainabilitySystemApi.apiSustainabilitySystemIdRevisionsGet({ id }), sustainabilitySystemRevisionsSubject);

export const sustainabilitySystemRevisionByIdSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSustainabilitySystemRevisionByIdResponse = hookFromSubject(sustainabilitySystemRevisionByIdSubject);
export const getSustainabilitySystemRevisionById = (id, revisionId) =>
  promiseToSubject(sustainabilitySystemApi.apiSustainabilitySystemIdRevisionRevisionIdGet({
    id,
    revisionId
  }), sustainabilitySystemRevisionByIdSubject);

sustainabilitySystemUpdateSubject.subscribe(event => {
  if (!event.loading && !event.error) {
    sustainabilitySystemSubject.next(event);
  }
});

export const [useSystemsPageLogic, resetSystemsPageState] = createTablePageLogic();


export const sustainabilitySystemRichTextImageUploadSubject = new BehaviorSubject(new PromiseSubjectState());
export const sustainabilitySystemRichTextImageUploadResponse = hookFromSubject(sustainabilitySystemRichTextImageUploadSubject);
export const sustainabilitySystemRichTextImageUpload = (file) =>
sustainabilitySystemApi.apiSustainabilitySystemRichtextImagePost({file}, sustainabilitySystemRichTextImageUploadResponse);  
