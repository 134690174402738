export const DEFAULT_UNIT = 'm';

export const BENCHMARK_UNITS = ["kg CO2e / m2", "kg CO2e / m2 a", "kWh / m2 a", "kWh / m2", "m3 / h m2"];

export const BENCHMARK_UNITS_MAP = {
  "kg CO2e / m2": "kg CO2e",
  "kg CO2e / m2 a": "kg CO2e",
  "kWh / m2 a": "kWh",
  "kWh / m2": "kWh",
  "m3 / h m2": "m³/h"
};

// If this list get changed, please update the UT in the BE for CalculationUtilTest
export const UNIT_OPTIONS = [
  { "value": "m", "label": "m" },
  { "value": "km", "label": "km" },
  { "value": "m²", "label": "m²" },
  { "value": "ar", "label": "ar" },
  { "value": "m³", "label": "m³" },
  { "value": "l", "label": "l" },
  { "value": "s", "label": "s" },
  { "value": "a", "label": "a" },
  { "value": "l/flush", "label": "l/flush" },
  { "value": "l/min", "label": "l/min" },
  { "value": "kg", "label": "kg" },
  { "value": "t", "label": "t" },
  { "value": "kg/m³", "label": "kg/m³" },
  { "value": "kg CO2e", "label": "kg CO2e" },
  { "value": "t CO2e", "label": "t CO2e" },
  { "value": "t CO2e /a", "label": "t CO2e /a" },
  { "value": "kg CO2e /a", "label": "kg CO2e /a" },
  { "value": "kg CO2e /m²", "label": "kg CO2e /m²" },
  { "value": "kg CO2e /m²a", "label": "kg CO2e /m²a" },
  { "value": "kW", "label": "kW" },
  { "value": "MW", "label": "MW" },
  { "value": "kWh", "label": "kWh" },
  { "value": "MWh", "label": "MWh" },
  { "value": "kWh/m²*a", "label": "kWh/m²*a" },
  { "value": "°C", "label": "°C" },
  { "value": "m³/h", "label": "m³/h" },
  { "value": "Pa", "label": "Pa" },
  { "value": "m³/h*m²", "label": "m³/h*m²" },
  { "value": "m/s", "label": "m/s" },
  { "value": "ft", "label": "ft" },
  { "value": "m²/Pers", "label": "m²/Pers" },
  { "value": "Euro  €", "label": "kEuro  €" },
  { "value": "Euro  € / m²", "label": "Euro  € / m²" },
  { "value": "yards", "label": "yards" },
  { "value": "mile", "label": "mile" },
  { "value": "sqft", "label": "sqft" },
  { "value": "acre", "label": "acre" },
  { "value": "cft (ft³)", "label": "cft (ft³)" },
  { "value": "gallon", "label": "gallon" },
  { "value": "sec", "label": "sec" },
  { "value": "min", "label": "min" },
  { "value": "a", "label": "a" },
  { "value": "gpf", "label": "gpf" },
  { "value": "gpm", "label": "gpm" },
  { "value": "Ib", "label": "Ib" },
  { "value": "US t", "label": "US t" },
  { "value": "lb/cft", "label": "lb/cft" },
  { "value": "kBTU/hr", "label": "kBTU/hr" },
  { "value": "MBTU/hr", "label": "MBTU/hr" },
  { "value": "kBTU", "label": "kBTU" },
  { "value": "MBTU", "label": "MBTU" },
  { "value": "kBTU/sqft*a", "label": "kBTU/sqft*a" },
  { "value": "F", "label": "F" },
  { "value": "cfm", "label": "cfm" },
  { "value": "inch of water (39°F)", "label": "inch of water (39°F)" },
  { "value": "cfm/sf", "label": "cfm/sf" },
  { "value": "ft/min", "label": "ft/min" },
  { "value": "Pers/1000sqft", "label": "Pers/1000sqft" },
  { "value": "USD $", "label": "USD $" },
  { "value": "USD $ / sqft", "label": "USD $ / sqft" },
  { "value": "litre/a", "label": "litre/a" },
  { "value": "kWh/a", "label": "kWh/a" },
  { "value": "t/a", "label": "t/a" },
  { "value": "CO2e t / Person a", "label": "CO2e t / Person a" },
  { "value": "m³/a", "label": "m³/a" },
  { "value": "%", "label": "%" },
  { "value": "mg/m³", "label": "mg/m³" },
  { "value": "µg/m³", "label": "µg/m³" },
  { "value": "h-1", "label": "h-1" },
  { "value": "l / min", "label": "l / min" },
  { "value": "€/a", "label": "€/a" },
  { "value": "#", "label": "#" }
];
