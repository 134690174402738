import FormHelperText from "@mui/material/FormHelperText";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { processMessage } from "../../utils";

export const DynamicFormController = ({ control, name, label, required, render, rules }) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ ...rules, required: required }}
      render={({ field, formState, fieldState }) => {
        return (
          <>
            {!!render && render({ field, formState, fieldState, label, name, required })}
            {formState.isSubmitted && fieldState.invalid && (
              <FormHelperText error>
                {fieldState.error.type === "required" && processMessage(t("validation.fieldRequired"), [label])}
                {fieldState.error.type === "min" && processMessage(t("validation.min"), [rules.min])}
                {fieldState.error.type === "max" && processMessage(t("validation.max"), [rules.max])}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};
