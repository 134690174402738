import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { handleError } from "../../../helpers/error";
import { useTenantListResponse } from "../../../hooks/tenant";
import { addUserTenant, useAddUserTenantResponse } from "../../../hooks/users";
import { useTimeout } from "../../../hooks/utils/useTimeout";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";

export const AddUserTenantDialog = ({ user, open, onClose, onChange }) => {
  const { t } = useTranslation();

  const tenantListResponse = useTenantListResponse();
  const addUserTenantResponse = useAddUserTenantResponse();
  const tenantList = (tenantListResponse.data || []).filter((tenant) => {
    return !user.userTenants.some((item) => item.id === tenant.id);
  });
  const [tenantId, setTenantId] = useState(null);

  const [, setResponseTimeout] = useTimeout();

  const afterChange = () =>
    setResponseTimeout(
      setTimeout(() => {
        onChange();
        onClose();
      }, 0)
    );

  const onSubmit = () => addUserTenant(user.id, tenantId).then(afterChange).catch(handleError);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm" sx={{ zIndex: 999999999 }}>
      <DialogTitle>
        {t("users.addTenantHeader")}
        <IconButton className="close-button" size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingOverlay spinner active={addUserTenantResponse.loading} className="add-user-tenant-dialog auto-height">
          <LoadingOverlay spinner active={tenantListResponse.loading} className="auto-height">
            <FormControl className="select-tenant" fullWidth>
              <InputLabel htmlFor="rolesSelectTenant">{t("roles.tenant") + " *"}</InputLabel>
              {/* TODO: Add proper fix for this input */}
              <Select
                id="rolesSelectTenant"
                disabled={!tenantList.length}
                onChange={(event) => setTenantId(event.target.value)}
                value={tenantId || ""}
                MenuProps={{ sx: { zIndex: 999999999 } }}
              >
                {tenantList.map((item, index) => (
                  <MenuItem value={item.id} key={getUUID()}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </LoadingOverlay>
        </LoadingOverlay>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("main.cancel")}</Button>
        <Button onClick={onSubmit} disabled={!tenantId} color="primary">
          {t("main.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddUserTenantDialog.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
