export const ExpandSidebarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.414" height="17" viewBox="0 0 17.414 17">
    <g id="icon-expand-sidebar" transform="translate(1.414 1)">
      <path
        id="Path_38"
        data-name="Path 38"
        d="M13,10l6.094,6.094L13,22.188"
        transform="translate(-13 -8.423)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <rect
        id="Rectangle_99"
        data-name="Rectangle 99"
        width="4.688"
        height="15"
        rx="2.344"
        transform="translate(10.312)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
);
