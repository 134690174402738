import React from 'react';
import "./SystemScoreTable.scss";
import { useTranslation } from "react-i18next";
import { roundDecimal } from "../../../../utils";
import * as d3 from "d3";

export const SystemScoreTablePAST = ({ data }) => {
  const { t } = useTranslation();
  const { score } = (data || {});

  const colorInterpolator1 = d3.interpolateRgb('#EB4B42', '#F5C242');
  const colorInterpolator2 = d3.interpolateRgb('#F5C242', '#FCEF4F');
  const colorInterpolator3 = d3.interpolateRgb('#FCEF4F', '#48A064');

  const steps1 = 29;
  const steps2 = 22;
  const steps3 = 50;

  const colorArray1 = d3.range(0, (1 + 1 / steps1), 1 / (steps1 - 1)).map(function (d) {
    return colorInterpolator1(d)
  });
  const colorArray2 = d3.range(0, (1 + 1 / steps2), 1 / (steps2 - 1)).map(function (d) {
    return colorInterpolator2(d)
  });
  const colorArray3 = d3.range(0, (1 + 1 / steps3), 1 / (steps3 - 1)).map(function (d) {
    return colorInterpolator3(d)
  });

  const colors = [...colorArray1, ...colorArray2, ...colorArray3];
  const value = score ? Math.round((score - 1) / 4 * 100) : 0;
  const color = colors[value];

  return (
    <div className="system-score-table past">
      <span className="title">{t('dashboard.pastOverallScoreTitle')}</span>
      <span className="score" style={{ color: score ? color : undefined }}>
        {score ? roundDecimal(score, 100) : '-'}
      </span>
    </div>
  );
};
