import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Fab from "@mui/material/Fab";
import React, { useCallback, useState } from "react";
import { ImageViewer } from "../ImageViewer/ImageViewer";
import "./ImageBlocks.scss";

export const ImageBlocks = React.memo(({ images, onChange, getImagePath, readOnly }) => {
  const [image, setImage] = useState(null);

  const removeImage = useCallback(
    (event, index) => {
      event.stopPropagation();
      const updated = [...images];
      updated.splice(index, 1);
      onChange(updated);
    },
    [images]
  );

  return (
    <div className="image-blocks">
      {images.map((item, index) => (
        <div key={index} className="image-block" onClick={() => setImage(getImagePath(item))}>
          <img src={getImagePath(item)} alt="img" />
          {!readOnly && (
            <Fab size="small" onClick={(event) => removeImage(event, index)} color="primary" className="gray">
              <DeleteOutlineIcon className="fab-svg" />
            </Fab>
          )}
        </div>
      ))}
      <ImageViewer image={image} onClose={() => setImage(null)} open={!!image} />
    </div>
  );
});
