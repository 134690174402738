import { Popper } from "@mui/base";
import AddLinkOutlinedIcon from "@mui/icons-material/AddLinkOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PlaceIcon from "@mui/icons-material/Place";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { MenuItem, MenuList, Paper } from "@mui/material";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CARBONE_URL } from "../../constants/main";
import { generateCriteriumReport } from "../../hooks/project";
import { showPromise } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { createRecursiveChange, statusItemRender, statusValueRender } from "./NewRatingSystem.util";
import "./NewRatingSystemContextMenu.scss";
import { getSystemPageState } from "./NewRatingSystemESG";
import { statusValues } from "./NewRatingSystemESG.data";
import { conditionIsCategory, conditionIsCriterion, conditionIsIndicator } from "./NewRatingSystemESG.validation";
import { setSideMenuState } from "./NewRatingSystemSideMenu";
import { getTableState, setTableState, tableFnSubject } from "./NewRatingSystemTable";
import { ACTION_ADD_BUTTON_ID, ACTION_BLOCK_ESG_ID } from "./side-menu-blocks/ActionBlockESG";
import {
  ACTUAL_BLOCK_ESG_ID,
  ACTUAL_FILES_SUB_BLOCK_ID,
  ACTUAL_FILES_UPLOAD_ID,
  ACTUAL_LINKS_ADD_BUTTON_ID,
  ACTUAL_LINKS_SUB_BLOCK_ID,
} from "./side-menu-blocks/ActualBlockESG";
import { POTENTIAL_BLOCK_ESG_ID } from "./side-menu-blocks/PotentialBlockESG";
import { RESPONSIBILITY_BLOCK_ID } from "./side-menu-blocks/ResponsibilityBlock";

const field = "indicatorStatus";

const onExplanationClick = (context, t, language) => {
  const { system } = getSystemPageState();
  const lang = language === "en" ? "en-us" : "de-de";
  const promise = new Promise((resolve, reject) =>
    generateCriteriumReport(system?.id, context?.dataItem?.uuid, lang)
      .then(({ data }) => {
        window.open(`${CARBONE_URL}${data?.renderId}`, "_blank");
        resolve();
      })
      .catch(reject)
  );

  showPromise(promise, {
    pending: t("newRatingSystem.esg.criteriumReport.pending"),
    success: t("newRatingSystem.esg.criteriumReport.success"),
    error: t("newRatingSystem.esg.criteriumReport.error"),
  });
};

const CategoryMenu = ({ dataItem, readOnly, editable, tableFn }) =>
  conditionIsCategory(dataItem) && (
    <>
      <div className="k-pos-relative context-menu-element-name">
        <span className="k-px-4 k-bg-white k-px-1 k-z-10 k-pos-relative">
          {dataItem?.systemReference ?? dataItem?.name}
        </span>
        <div className="k-pos-absolute"></div>
      </div>
      {!readOnly && editable && (
        <li className="k-px-4 dropdown-li">
          <DropDownList
            className="status-dropdown"
            fillMode="flat"
            data={statusValues}
            onChange={({ value }) => tableFn?.onItemChange(createRecursiveChange(dataItem, field, value?.value))}
            textField="text"
            dataItemKey="value"
            popupSettings={{ width: "auto" }}
            valueRender={statusValueRender}
            itemRender={statusItemRender}
            disabled={readOnly || !editable}
          />
        </li>
      )}
    </>
  );

CategoryMenu.propTypes = {
  dataItem: PropTypes.object,
  readOnly: PropTypes.bool,
  editable: PropTypes.bool,
  tableFn: PropTypes.object,
};

const CriteriaMenu = ({ dataItem, readOnly, editable, tableFn, onClose }) => {
  const { t } = useTranslation();
  return (
    conditionIsCriterion(dataItem) && (
      <>
        <div className="k-pos-relative context-menu-element-name">
          <span className="k-px-4 k-bg-white k-px-1 k-z-10 k-pos-relative">
            {dataItem?.systemReference ?? dataItem?.name}
          </span>
          <div className="k-pos-absolute"></div>
        </div>
        {!readOnly && editable && (
          <li className="k-px-4 dropdown-li">
            <DropDownList
              className="status-dropdown"
              fillMode="flat"
              data={statusValues}
              onChange={({ value }) => tableFn?.onItemChange(createRecursiveChange(dataItem, field, value?.value))}
              textField="text"
              dataItemKey="value"
              popupSettings={{ width: "auto" }}
              valueRender={statusValueRender}
              itemRender={statusItemRender}
              disabled={readOnly || !editable}
            />
          </li>
        )}
        <MenuItem
          className="k-gap-2"
          onClick={() => {
            tableFn?.onSelectionChange({ dataItem });
            setSideMenuState((prev) => ({
              ...prev,
              open: true,
              scrollTo: `#${ACTION_BLOCK_ESG_ID}`,
              interact: {
                selector: `#${ACTION_BLOCK_ESG_ID} #${ACTION_ADD_BUTTON_ID}`,
                func: "click",
              },
            }));
            onClose();
          }}
        >
          <TextSnippetOutlinedIcon fontSize="small" />
          {t("newRatingSystem.esg.contextMenu.manageActions")}
        </MenuItem>
        <MenuItem
          className="k-gap-2"
          onClick={() => {
            tableFn?.onSelectionChange({ dataItem });
            setSideMenuState((prev) => ({
              ...prev,
              open: true,
              scrollTo: `#${ACTION_BLOCK_ESG_ID}`,
            }));
            onClose();
          }}
        >
          <PlaceIcon fontSize="small" />
          {t("newRatingSystem.esg.contextMenu.goToAction")}
        </MenuItem>
      </>
    )
  );
};

CriteriaMenu.propTypes = {
  dataItem: PropTypes.object,
  readOnly: PropTypes.bool,
  editable: PropTypes.bool,
  tableFn: PropTypes.object,
  onClose: PropTypes.func,
};

const IndicatorMenu = ({ dataItem, readOnly, editable, tableFn, onClose }) => {
  const value = dataItem[field] ? statusValues.find((x) => x.value === dataItem[field]) : statusValues[0];
  const { t } = useTranslation();

  return (
    conditionIsIndicator(dataItem) && (
      <>
        <div className="k-pos-relative context-menu-element-name">
          <span className="k-px-4 k-bg-white k-px-1 k-z-10 k-pos-relative">
            {dataItem?.systemReference ?? dataItem?.name}
          </span>
          <div className="k-pos-absolute"></div>
        </div>
        <li className="k-px-4 dropdown-li">
          <DropDownList
            className="status-dropdown"
            fillMode="flat"
            data={statusValues}
            value={value}
            onChange={({ value }) => tableFn?.onItemChange([{ dataItem, field, value: value?.value }])}
            textField="text"
            dataItemKey="value"
            popupSettings={{ width: "auto" }}
            valueRender={statusValueRender}
            itemRender={statusItemRender}
            disabled={readOnly || !editable}
          />
        </li>
        <MenuItem
          className="k-gap-2"
          onClick={() => {
            tableFn?.onSelectionChange({ dataItem });
            setSideMenuState((prev) => ({ ...prev, open: true, autoOpen: true }));
            onClose();
          }}
        >
          <OpenInNewIcon fontSize="small" />
          {t("newRatingSystem.esg.contextMenu.openInSideMenu")}
        </MenuItem>
        <MenuItem
          className="k-gap-2"
          onClick={() => {
            tableFn?.onSelectionChange({ dataItem });
            setSideMenuState((prev) => ({
              ...prev,
              open: true,
              scrollTo: `#${ACTION_BLOCK_ESG_ID}`,
              interact: {
                selector: `#${ACTION_BLOCK_ESG_ID} #${ACTION_ADD_BUTTON_ID}`,
                func: "click",
              },
            }));
            onClose();
          }}
        >
          <TextSnippetOutlinedIcon fontSize="small" />
          {t("newRatingSystem.esg.contextMenu.manageActions")}
        </MenuItem>
        <MenuItem
          className="k-gap-2"
          onClick={() => {
            tableFn?.onSelectionChange({ dataItem });
            setSideMenuState((prev) => ({
              ...prev,
              open: true,
              scrollTo: `#${ACTUAL_BLOCK_ESG_ID}`,
            }));
            onClose();
          }}
        >
          <PlaceIcon fontSize="small" />
          {t("newRatingSystem.esg.contextMenu.goToActual")}
        </MenuItem>
        {!readOnly && editable && (
          <>
            <MenuItem
              className="k-gap-2 k-pl-10"
              onClick={() => {
                tableFn?.onSelectionChange({ dataItem });
                const linksSubBlockSelector = `#${ACTUAL_BLOCK_ESG_ID} #${ACTUAL_LINKS_SUB_BLOCK_ID}`;
                setSideMenuState((prev) => ({
                  ...prev,
                  open: true,
                  scrollTo: linksSubBlockSelector,
                  interact: { selector: `${linksSubBlockSelector} #${ACTUAL_LINKS_ADD_BUTTON_ID}`, func: "click" },
                }));
                onClose();
              }}
            >
              <AddLinkOutlinedIcon fontSize="small" />
              {t("newRatingSystem.esg.contextMenu.addLink")}
            </MenuItem>
            <MenuItem
              className="k-gap-2 k-pl-10"
              onClick={() => {
                tableFn?.onSelectionChange({ dataItem });
                const filesSubBlockSelector = `#${ACTUAL_BLOCK_ESG_ID} #${ACTUAL_FILES_SUB_BLOCK_ID}`;
                setSideMenuState((prev) => ({
                  ...prev,
                  open: true,
                  scrollTo: filesSubBlockSelector,
                  interact: {
                    selector: `${filesSubBlockSelector} #${ACTUAL_FILES_UPLOAD_ID} #select-button`,
                    func: "click",
                  },
                }));
                onClose();
              }}
            >
              <UploadFileOutlinedIcon fontSize="small" />
              {t("newRatingSystem.esg.contextMenu.addFile")}
            </MenuItem>
          </>
        )}
        <MenuItem
          className="k-gap-2"
          onClick={() => {
            tableFn?.onSelectionChange({ dataItem });
            setSideMenuState((prev) => ({ ...prev, open: true, scrollTo: `#${POTENTIAL_BLOCK_ESG_ID}` }));
            onClose();
          }}
        >
          <PlaceIcon fontSize="small" />
          {t("newRatingSystem.esg.contextMenu.goToPotential")}
        </MenuItem>
        <MenuItem
          className="k-gap-2"
          onClick={() => {
            tableFn?.onSelectionChange({ dataItem });
            setSideMenuState((prev) => ({
              ...prev,
              open: true,
              scrollTo: `#${ACTION_BLOCK_ESG_ID}`,
            }));
            onClose();
          }}
        >
          <PlaceIcon fontSize="small" />
          {t("newRatingSystem.esg.contextMenu.goToAction")}
        </MenuItem>
        <MenuItem
          className="k-gap-2"
          onClick={() => {
            tableFn?.onSelectionChange({ dataItem });
            setSideMenuState((prev) => ({ ...prev, open: true, scrollTo: `#${RESPONSIBILITY_BLOCK_ID}` }));
            onClose();
          }}
        >
          <PlaceIcon fontSize="small" />
          {t("newRatingSystem.esg.contextMenu.goToResponsibility")}
        </MenuItem>
      </>
    )
  );
};

IndicatorMenu.propTypes = {
  dataItem: PropTypes.object,
  readOnly: PropTypes.bool,
  editable: PropTypes.bool,
  tableFn: PropTypes.object,
  onClose: PropTypes.func,
};

export const StatusThContextMenu = ({ open, onClose, anchorEl, editable, readOnly }) => {
  const popperRef = useRef(null);
  const { t } = useTranslation();
  const tableFn = tableFnSubject.getValue();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popperRef.current && !popperRef.current?.contains(event.target)) onClose();
    };

    const handleEscKey = (event) => {
      if (event.key === "Escape") onClose();
    };

    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("keydown", handleEscKey);
    document.addEventListener("wheel", onClose);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("keydown", handleEscKey);
      document.removeEventListener("wheel", onClose);
    };
  }, []);

  return (
    <Popper ref={popperRef} open={open} anchorEl={anchorEl} placement="top-start">
      <Paper>
        <MenuList>
          <div className="k-pos-relative context-menu-element-name">
            <span className="k-px-4 k-bg-white k-px-1 k-z-10 k-pos-relative">
              {t("newRatingSystem.esg.columns.status.updateAll")}
            </span>
            <div className="k-pos-absolute"></div>
          </div>
          <li className="k-px-4 dropdown-li">
            <DropDownList
              className="status-dropdown"
              fillMode="flat"
              data={statusValues}
              onChange={({ value }) => tableFn?.onItemChange(createRecursiveChange(null, field, value?.value))}
              textField="text"
              dataItemKey="value"
              popupSettings={{ width: "auto" }}
              valueRender={statusValueRender}
              itemRender={statusItemRender}
              disabled={readOnly || !editable}
            />
          </li>
        </MenuList>
      </Paper>
    </Popper>
  );
};

StatusThContextMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.object,
  editable: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export const NewRatingSystemContextMenu = ({ context, readOnly, editable, tableFn = {} }) => {
  const onClose = useEvent((additional) => setTableState((prev) => ({ ...prev, context: null, ...additional })));
  const popperRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (getTableState()?.context && !popperRef.current?.contains(event.target)) onClose();
    };

    const handleEscKey = (event) => {
      if (event.key === "Escape") onClose();
    };

    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("keydown", handleEscKey);
    document.addEventListener("wheel", onClose);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("keydown", handleEscKey);
      document.removeEventListener("wheel", onClose);
    };
  }, []);

  return (
    <Popper ref={popperRef} open={!!context} anchorEl={context?.event?.target} className="context-menu">
      <Paper>
        <MenuList>
          <CategoryMenu
            dataItem={context?.dataItem}
            readOnly={readOnly}
            editable={editable}
            tableFn={tableFn}
            onClose={onClose}
          />
          <CriteriaMenu
            dataItem={context?.dataItem}
            readOnly={readOnly}
            editable={editable}
            tableFn={tableFn}
            onClose={onClose}
          />
          <IndicatorMenu
            dataItem={context?.dataItem}
            readOnly={readOnly}
            editable={editable}
            tableFn={tableFn}
            onClose={onClose}
          />
          <MenuItem className="k-gap-2" onClick={() => onExplanationClick(context, t, i18n.language)}>
            <TextSnippetOutlinedIcon fontSize="small" />
            {t("newRatingSystem.esg.contextMenu.explanations")}
          </MenuItem>
        </MenuList>
      </Paper>
    </Popper>
  );
};

NewRatingSystemContextMenu.propTypes = {
  context: PropTypes.object,
  readOnly: PropTypes.bool,
  editable: PropTypes.bool,
  tableFn: PropTypes.object,
};
