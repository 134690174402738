import { Popover } from "@mui/material";
import React from "react";

export const TextPopover = ({ showTooltip, setShowTooltip, children }) => {
  return (
    <Popover
      className={"award-popover"}
      open={!!showTooltip}
      anchorEl={(showTooltip || {}).target}
      onClose={() => setShowTooltip(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      disableRestoreFocus
    >
      {children}
    </Popover>
  );
};
