import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_CLIENT } from "../../../constants/main";
import { PERMISSION_SUPER_ADMIN } from "../../../constants/permissions";
import { hasPermission } from "../../../helpers/permission";
import { useUser } from "../../../hooks/auth";
import { useProjectAvailableUsersResponse } from "../../../hooks/project";
import { updateProjectGroup, useProjectGroupUpdateResponse } from "../../../hooks/projectGroup";
import { showSuccess } from "../../../hooks/toast";
import { useEvent } from "../../../hooks/utils/useEvent";
import { FormController } from "../../FormController/FormController";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { LeadSelect } from "../../Project/LeadSelect/LeadSelect";
import "./ProjectGroupMainBlock.scss";

const TEXT_MAX_LENGTH = 1000;

export const ProjectGroupMainBlock = ({ data, readOnly }) => {
  const { t } = useTranslation();

  const projectGroupUpdateResponse = useProjectGroupUpdateResponse();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { name: "", description: "", ownerName: "", creationDate: "", projectGroupLead: "" },
  });

  useEffect(
    () =>
      reset({
        name: data.name,
        description: data.description,
        owner: data.owner,
        ownerName: data.ownerName,
        createdDateTime: data.createdDateTime,
        projectGroupLead: data.projectGroupLead,
      }),
    [data]
  );

  const onSubmit = useEvent((values) =>
    updateProjectGroup(data.id, values)
      .then(() => {
        showSuccess(t("projectGroup.updateSuccessMessage"));
      })
      .catch(console.error)
  );

  const { user } = useUser();
  const isOwner = user.uuid === data?.owner;
  const isSuperAdmin = user.superAdmin || hasPermission(PERMISSION_SUPER_ADMIN) || false;
  const loading = projectGroupUpdateResponse.loading;

  const projectAvailableUsersResponse = useProjectAvailableUsersResponse();
  const projectAvailableUsers = projectAvailableUsersResponse.data || [];
  const projectGroupPermissions = data.projectGroupPermissions || [];
  const owner = data.owner || [];

  return (
    <LoadingOverlay spinner active={loading} className="project-group-main-block auto-height">
      <h2 className="block-header">{t("projectGroup.mainBlockTitle")}</h2>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
        <FormController
          control={control}
          name="name"
          required
          label={t("projectGroup.name")}
          render={({ field, fieldState, label }) => (
            <TextField
              {...field}
              fullWidth
              error={fieldState.invalid}
              label={label + (!readOnly ? " *" : "")}
              inputProps={{ maxLength: 255, readOnly }}
              value={field.value || ""}
              className={readOnly ? "read-only" : ""}
            />
          )}
        />
        <FormController
          control={control}
          name="description"
          required
          label={t("projectGroup.description")}
          render={({ field, fieldState, label }) => (
            <>
              <TextField
                {...field}
                fullWidth
                error={fieldState.invalid}
                label={label + (!readOnly ? " *" : "")}
                inputProps={{ maxLength: TEXT_MAX_LENGTH, readOnly }}
                value={field.value || ""}
                multiline
                className={readOnly ? "read-only" : ""}
              />
              {!readOnly && (
                <div className="chars-left">
                  {t("main.charactersLeft")}: {TEXT_MAX_LENGTH - (field.value || "").length}
                </div>
              )}
            </>
          )}
        />
        {data.ownerName && (
          <FormController
            control={control}
            name="ownerName"
            required
            label={t("projectGroup.ownerName")}
            render={({ field, fieldState, label }) => (
              <TextField
                {...field}
                fullWidth
                error={fieldState.invalid}
                label={label}
                inputProps={{ maxLength: 255, readOnly: true }}
                value={field.value || ""}
                className="read-only"
              />
            )}
          />
        )}
        {data.createdDateTime && (
          <FormController
            control={control}
            name="createdDateTime"
            required
            label={t("projectGroup.creationDate")}
            render={({ field, fieldState, label }) => (
              <TextField
                {...field}
                fullWidth
                error={fieldState.invalid}
                label={label}
                inputProps={{ maxLength: 255, readOnly: true }}
                value={field.value ? moment.utc(field.value).local().format(DATE_FORMAT_CLIENT) : ""}
                className="read-only"
              />
            )}
          />
        )}

        {!!data.id && (
          <LeadSelect
            control={control}
            projectPermissions={projectGroupPermissions}
            owner={owner}
            projectAvailableUsers={projectAvailableUsers}
            isGroup
            disabled={readOnly || !(isOwner || isSuperAdmin)}
          />
        )}

        {!readOnly && (
          <div className="buttons text-right">
            <Button type="submit" color="primary">
              {t("main.save")}
            </Button>
          </div>
        )}
      </form>
    </LoadingOverlay>
  );
};
