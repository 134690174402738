import {
  migrateSystem,
  useMigrationTargetsResponse
} from "../../../hooks/system";
import React from "react";
import { SelectMigrationTargetDialog } from "../../SelectMigrationTargetDialog/SelectMigrationTargetDialog";
import { showError, showSuccess } from "../../../hooks/toast";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../hooks/auth";
import { getUserAvailableTenantsForMigration } from "../../../utils";

export const SustainabilitySystemCopyToTenantDialog = React.memo(({ systemId, ...props }) => {
  const { t } = useTranslation();
  const user = useUser();
  
  const availableTenants = getUserAvailableTenantsForMigration(user);

  return (
    <SelectMigrationTargetDialog
      title={t('systemMigration.dialogHeader')}
      onSelect={tenantId => {
        migrateSystem(systemId, tenantId)
          .then(() => showSuccess(t('systemMigration.systemMigrated')))
          .catch(error => {
            showError(t('error.500'));
            console.error(error);
          });
      }}
      loading={useMigrationTargetsResponse.loading}
      migrationTargets={availableTenants}
      {...props}
    />
  );
});
