import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { KPI_VALUE_TYPE_ABSOLUTE, KPI_VALUE_TYPE_RELATIVE } from "../../../constants/dashboard";
import { getHeightInPoints } from "../../../helpers/canvas";
import { searchBenchmarks, useBenchmarkPageResponse } from "../../../hooks/benchmark";
import {
  getKpiRatingSystemBenchmarkChart,
  useKpiRatingSystemBenchmarkChartResponse,
} from "../../../hooks/kpiRatingSystem";
import {
  benchmarksLoadedForKpiSubject,
  getRatingSystemBenchmarkChart,
  setBenchmarksLoadedForKpi,
  useRatingSystemBenchmarkChartResponse,
} from "../../../hooks/ratingSystem";
import { useEvent } from "../../../hooks/utils/useEvent";
import { localeString } from "../../../utils";
import { ChartBase } from "../../ChartBase/ChartBase";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { TooltipPopover } from "../../TooltipPopover/TooltipPopover";
import { KPIBenchmarkAutocomplete } from "./KPIBenchmarkAutocomplete";
import { renderKPIChart } from "./renderKPIChart";

export const KPIChart = React.memo(
  ({ ratingSystemId, data, grossFloorArea, isKpiSystem, forwardedRef, scrollBack, unfolded }) => {
    const { t } = useTranslation();
    const [potentialPopover, setPotentialPopover] = useState(null);
    const [currentPopover, setCurrentPopover] = useState(null);
    const [goalPopover, setGoalPopover] = useState(null);
    const [chartKey, setChartKey] = useState(0);
    const [limitedXRange, setLimitedXRange] = useState([]);
    const [height, setHeight] = useState(0);
    const [benchmarkData, setBenchmarkData] = useState([]);
    const [selectedBenchmark, setSelectedBenchmark] = useState("");
    const [benchmarks, setBenchmarks] = useState([]);
    const valueType = selectedBenchmark ? KPI_VALUE_TYPE_RELATIVE : KPI_VALUE_TYPE_ABSOLUTE;

    const pageLimit = 20;
    const benchmarkValueField = valueType === KPI_VALUE_TYPE_RELATIVE ? "value" : "absoluteValue";
    const valueField = valueType === KPI_VALUE_TYPE_RELATIVE ? "relativeValue" : "absoluteValue";
    const goalField = valueType === KPI_VALUE_TYPE_RELATIVE ? "goal" : "absoluteGoal";
    const potentialField = valueType === KPI_VALUE_TYPE_RELATIVE ? "potentialRelativeValue" : "potentialValue";
    const estimatedField = valueType === KPI_VALUE_TYPE_RELATIVE ? "estimatedRelativeValue" : "estimatedAbsoluteValue";
    const measuredField = valueType === KPI_VALUE_TYPE_RELATIVE ? "measuredRelativeValue" : "measuredAbsoluteValue";

    const items = data?.items || [];

    const benchmarkPageResponse = useBenchmarkPageResponse();
    const loadedBenchmarks = benchmarkPageResponse?.data?.content;

    const ratingSystemBenchmarkChartResponse = useRatingSystemBenchmarkChartResponse();
    const kpiRatingSystemBenchmarkChartResponse = useKpiRatingSystemBenchmarkChartResponse();
    const benchmarkChartResponse = isKpiSystem
      ? kpiRatingSystemBenchmarkChartResponse
      : ratingSystemBenchmarkChartResponse;

    const benchmarksMemo = useMemo(() => benchmarks, [JSON.stringify(benchmarks)]);

    useEffect(() => {
      const benchmarksLoadedKpi = benchmarksLoadedForKpiSubject.getValue();
      if (
        loadedBenchmarks &&
        benchmarksLoadedKpi?.name === data?.kpiName &&
        benchmarksLoadedKpi?.unit === data?.kpiUnit
      ) {
        setBenchmarks(
          loadedBenchmarks.filter((item) => item.kpiName === data?.kpiName && item.kpiUnit === data?.kpiUnit)
        );
      }
    }, [loadedBenchmarks]);

    const benchmark = useMemo(() => {
      return benchmarkData.map((item) => ({
        ...item,
        value: item[benchmarkValueField],
      }));
    }, [benchmarkData, benchmarkValueField]);

    const loadBenchmarks = useEvent((search) => {
      const benchmarksLoadedKpi = benchmarksLoadedForKpiSubject.getValue();
      if (
        !benchmarksLoadedKpi ||
        benchmarksLoadedKpi.name !== data?.kpiName ||
        benchmarksLoadedKpi.unit !== data?.kpiUnit ||
        search
      ) {
        const kpi = { name: data?.kpiName, unit: data?.kpiUnit };
        setBenchmarksLoadedForKpi(kpi);
        searchBenchmarks({
          page: 1,
          itemsPerPage: 10,
          kpis: [kpi],
          status: "RELEASED",
          search: search || "",
        });
      }
    });

    const sortBenchmark = (dataSortBenchmark) =>
      (dataSortBenchmark || []).sort((a, b) => {
        if (a?.year > b?.year) {
          return 1;
        } else if (a?.year < b?.year) {
          return -1;
        }
        return 0;
      });

    const selectBenchmark = useEvent((dataSelectedBenchmark) => {
      const benchmarkId = dataSelectedBenchmark?.id || "";
      setSelectedBenchmark(benchmarkId);
      if (benchmarkId) {
        if (isKpiSystem) {
          getKpiRatingSystemBenchmarkChart(ratingSystemId, benchmarkId)
            .then((response) => setBenchmarkData(sortBenchmark(response)))
            .catch(console.error);
        } else {
          getRatingSystemBenchmarkChart(ratingSystemId, benchmarkId)
            .then((response) => setBenchmarkData(sortBenchmark(response)))
            .catch(console.error);
        }
      } else {
        setBenchmarkData([]);
      }
    });

    const values = useMemo(
      () =>
        items.map((item) => ({
          value: item[valueField],
          potential: item[potentialField],
          estimated: item[estimatedField],
          measured: item[measuredField],
          goal: item[goalField],
          year: item.year,
        })),
      [items, valueField, goalField]
    );

    const [valuesByYear, minYear, maxYear, maxGoal, maxValue] = useMemo(() => {
      let valuesByYearInner = {};
      let maxValueInner = 0;
      let maxGoalInner = 0;
      let minYearInner = 0;
      let maxYearInner = 0;

      values.forEach((item) => {
        const { year } = item;
        valuesByYearInner[year] = item;
      });

      values.forEach((item) => {
        if (!maxValueInner || maxValueInner < item.value) {
          maxValueInner = item.value || 0;
        }
        if (!maxValueInner || maxValueInner < item.potential) {
          maxValueInner = item.potential || 0;
        }
        if (!maxValueInner || maxValueInner < item.goal) {
          maxValueInner = item.goal || 0;
        }
        if (!minYearInner || minYearInner > item.year) {
          minYearInner = item.year;
        }
        if (!maxYearInner || maxYearInner < item.year) {
          maxYearInner = item.year;
        }
      });

      benchmark?.forEach((item) => {
        const { year, value } = item;
        if (!valuesByYearInner[year]) {
          valuesByYearInner[year] = { year };
        }
        valuesByYearInner[year].benchmark = value;
        if (!maxValueInner || maxValueInner < value) {
          maxValueInner = value || 0;
        }
        if (!minYearInner || minYearInner > item.year) {
          minYearInner = item.year;
        }
        if (!maxYearInner || maxYearInner < item.year) {
          maxYearInner = item.year;
        }
      });
      return [valuesByYearInner, minYearInner, maxYearInner, maxGoalInner, maxValueInner];
    }, [values, benchmark]);

    const yearsRange = useMemo(() => {
      const res = [];
      for (let i = minYear; i <= maxYear; i++) {
        res.push(i);
      }
      return res;
    }, [minYear, maxYear]);

    useEffect(() => {
      setChartKey(chartKey + 1);
    }, [valueType]);

    const renderMethod = ({
      canvas,
      width: renderWidth,
      height: renderHeight,
      heightInPoints: renderHeightInPoints,
      values: renderValues,
      limitedXRange: renderLimitedXRange,
    }) => {
      setLimitedXRange(renderLimitedXRange);
      setHeight(renderHeight);
      if (canvas && renderWidth && renderHeight) {
        renderKPIChart(
          canvas,
          renderWidth,
          renderHeight,
          {
            values: renderValues?.values,
            benchmark: renderValues?.benchmark,
          },
          {
            limitedYearsRange: renderLimitedXRange,
            valuesByYear: valuesByYear,
            heightInPoints: renderHeightInPoints || 1,
            linesAmount: (valuesRange.length - 1) * 2,
          }
        );
      }
    };

    let heightInPoints = getHeightInPoints(maxValue);
    let goalInPoints = getHeightInPoints(maxGoal);
    if (goalInPoints > heightInPoints) {
      heightInPoints = goalInPoints;
    }
    let valueStep = 1;
    if (heightInPoints) {
      valueStep = heightInPoints / 10;
    }

    const valuesRange = useMemo(() => {
      const res = [];
      if (heightInPoints) {
        for (let i = 0; i <= heightInPoints; i += valueStep) {
          res.push(heightInPoints > 100 ? Math.round(i) : Math.round(i * 100) / 100);
        }
      } else {
        for (let i = 0; i < 10; i++) {
          res.push(i);
        }
      }
      return res;
    }, [heightInPoints]);

    const topContent = useMemo(
      () => (
        <div className="flex-row">
          <div className="left-part flex-auto">
            <h2>
              {!!scrollBack && (
                <IconButton size="small" color="primary" onClick={scrollBack}>
                  <ArrowUpwardIcon />
                </IconButton>
              )}
              {data?.kpiName} {data?.kpiUnit}
            </h2>
          </div>
          <div className="right-part"></div>
        </div>
      ),
      [data]
    );

    const rightContent = useMemo(
      () => (
        <div>
          {!!grossFloorArea && (
            <h3 className="project-gross-floor-area">
              {t("project.grossFloorArea")} = {grossFloorArea}
            </h3>
          )}
          <div className="legend">
            <div className="sample-line" />
            <div className="muted-legend">{t("dashboard.kpi.legend.goal")}</div>
          </div>
          <div className="legend">
            <div className="sample-line estimated-line" />
            <div className="muted-legend">{t("dashboard.kpi.legend.estimated")}</div>
          </div>
          <div className="legend">
            <div className="sample-line measured-line" />
            <div className="muted-legend">{t("dashboard.kpi.legend.measured")}</div>
          </div>
          <div className="legend">
            <div className="sample-line potential-line" />
            <div className="muted-legend">{t("dashboard.kpi.legend.potential")}</div>
          </div>
          <div className="legend">
            <div className="sample-line benchmark-line" />
            <div className="muted-legend">{t("dashboard.kpi.legend.benchmark")}</div>
          </div>
          <KPIBenchmarkAutocomplete
            loading={benchmarkPageResponse.loading}
            onValueChange={selectBenchmark}
            onTextChange={loadBenchmarks}
            onOpen={loadBenchmarks}
            options={benchmarksMemo}
            value={selectedBenchmark}
          />
        </div>
      ),
      [grossFloorArea, benchmarksMemo, selectedBenchmark]
    );

    const goalPopoverContent = useMemo(() => {
      const { target, open } = goalPopover || {};
      const popoverData = goalPopover?.data || {};
      const { year, goal } = popoverData;
      return (
        <TooltipPopover
          target={target}
          open={!!open}
          className="text-tooltip"
          content={
            <>
              <div>{year}</div>
              <span className="muted">{t("dashboard.kpi.legend.goal")}: </span>
              <span>
                {localeString(goal)} {data?.kpiUnit}
              </span>
            </>
          }
        />
      );
    }, [data, goalPopover]);

    const currentPopoverContent = useMemo(() => {
      const { target, open } = currentPopover || {};
      const popoverData = currentPopover?.data || {};
      const { measured, estimated, value, year } = popoverData;
      return (
        <TooltipPopover
          target={target}
          open={!!open}
          className="text-tooltip"
          content={
            <>
              <div>{year}</div>
              <div>
                <span className="muted">{t("dashboard.kpi.legend.current")}: </span>
                <span>
                  {localeString(value)} {data?.kpiUnit}
                </span>
              </div>
              {!!estimated && (
                <div>
                  <span className="muted">{t("dashboard.kpi.legend.estimated")}: </span>
                  <span>
                    {localeString(estimated)} {data?.kpiUnit}
                  </span>
                </div>
              )}
              {!!measured && (
                <div>
                  <span className="muted">{t("dashboard.kpi.legend.measured")}: </span>
                  <span>
                    {localeString(measured)} {data?.kpiUnit}
                  </span>
                </div>
              )}
            </>
          }
        />
      );
    }, [data, currentPopover]);

    const potentialPopoverContent = useMemo(() => {
      const { target, open } = potentialPopover || {};
      const popoverData = potentialPopover?.data || {};
      const { potential, year } = popoverData;
      return (
        <TooltipPopover
          target={target}
          open={!!open}
          className="text-tooltip"
          content={
            <>
              <div>{year}</div>
              <div>
                <span className="muted">{t("dashboard.kpi.legend.potential")}: </span>
                <span>
                  {localeString(potential)} {data?.kpiUnit}
                </span>
              </div>
            </>
          }
        />
      );
    }, [data, potentialPopover]);

    const footerContent = useMemo(
      () => (
        <>
          {goalPopoverContent}
          {currentPopoverContent}
          {potentialPopoverContent}
        </>
      ),
      [goalPopoverContent, currentPopoverContent, potentialPopoverContent]
    );

    const chartKeyArray = useMemo(() => limitedXRange.map(() => getUUID()), [limitedXRange]);
    const chartInnerContent = useMemo(
      () => (
        <div className="value-dots flex-row">
          {limitedXRange.map((item, index) => {
            const value = valuesByYear[item] || {};
            const { estimated, goal, measured, potential } = value;
            const bothValues = (!!estimated || !!measured) && !!potential;

            return (
              <div className="value-dot-column k-flex-1" key={chartKeyArray[index]}>
                {!!value && !!height && !!heightInPoints && (
                  <div className="value-dot-bar-wrapper">
                    {(!!estimated || !!measured) && (
                      <Box
                        className={"current-bar" + (bothValues ? " translate-left" : "")}
                        style={{ height: (height / heightInPoints) * ((estimated || 0) + (measured || 0)) }}
                        onMouseOver={(event) =>
                          setCurrentPopover({ target: event.currentTarget, data: value, open: true })
                        }
                        onMouseOut={() => setCurrentPopover({ ...currentPopover, open: false })}
                      />
                    )}
                    {!!potential && (
                      <Box
                        className={"potential-bar" + (bothValues ? " translate-right" : "")}
                        style={{ height: (height / heightInPoints) * potential }}
                        onMouseOver={(event) =>
                          setPotentialPopover({
                            target: event.currentTarget,
                            data: value,
                            open: true,
                          })
                        }
                        onMouseOut={() => setPotentialPopover({ ...potentialPopover, open: false })}
                      />
                    )}
                    {!!goal && (
                      <div className="value-dot-bar" style={{ height: (height / heightInPoints) * goal }}>
                        <div className="value-dot-line" />
                        <Box
                          className="value-dot-wrapper"
                          onMouseOver={(event) =>
                            setGoalPopover({ target: event.currentTarget, data: value, open: true })
                          }
                          onMouseOut={() => setGoalPopover({ ...goalPopover, open: false })}
                        >
                          <div className="value-dot" />
                        </Box>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ),
      [limitedXRange, valuesByYear, height, heightInPoints, goalPopover, potentialPopover, currentPopover, benchmark]
    );

    const valuesMemo = useMemo(() => ({ values, benchmark }), [values, benchmark]);

    return (
      <LoadingOverlay active={benchmarkChartResponse.loading} spinner className="auto-height">
        <ChartBase
          xFieldName="year"
          forwardedRef={forwardedRef}
          key={chartKey + (unfolded ? "_u" : "")}
          className={"dashboard-kpi-chart"}
          pageLimit={pageLimit}
          xRange={yearsRange}
          yRange={valuesRange}
          xLabel={t("dashboard.kpi.legend.year")}
          yLabel={false}
          renderMethod={renderMethod}
          values={valuesMemo}
          heightInPoints={heightInPoints}
          topContent={topContent}
          rightContent={rightContent}
          footerContent={footerContent}
          focusLast={false}
        >
          {chartInnerContent}
        </ChartBase>
      </LoadingOverlay>
    );
  }
);

KPIChart.propTypes = {
  ratingSystemId: PropTypes.string,
  data: PropTypes.object,
  grossFloorArea: PropTypes.number,
  isKpiSystem: PropTypes.bool,
  forwardedRef: PropTypes.object,
  scrollBack: PropTypes.func,
  unfolded: PropTypes.bool,
};
