export const ChevronUpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="8.414" viewBox="0 0 14.828 8.414">
    <g id="icon-chevron-up" transform="translate(1.414 1)">
      <path
        id="Path_148"
        data-name="Path 148"
        d="M6,15l6-6,6,6"
        transform="translate(-6 -9)"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
);
