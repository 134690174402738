import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { v4 as getUUID } from "uuid";
import { ADVANCED_TABLE_ID } from "../../components/AdvancedTable/AdvancedTable";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { DashboardLink } from "../../components/Dashboard/DashboardLink/DashboardLink";
import { Dock } from "../../components/Dock/Dock";
import { KPIProjectFieldsDecisions } from "../../components/KPIProjectFieldsDecisions/KPIProjectFieldsDecisions";
import { GroupingElementDock } from "../../components/KPIRatingSystem/GroupingElementDock/GroupingElementDock";
import { KPIElementDock } from "../../components/KPIRatingSystem/KPIElementDock/KPIElementDock";
import { KPIRatingSystemGrouping } from "../../components/KPIRatingSystem/KPIRatingSystemGrouping/KPIRatingSystemGrouping";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { RatingSystemGoals } from "../../components/RatingSystem/RatingSystemGoals/RatingSystemGoals";
import { StatusBadge } from "../../components/StatusBadge/StatusBadge";
import { TwoOptionsToggle } from "../../components/TwoOptionsToggle/TwoOptionsToggle";
import { VersionsDialog } from "../../components/Versions/VersionsDialog/VersionsDialog";
import { GROUPING_TYPE_KPI } from "../../constants/kpiSystem";
import { DATE_FORMAT_SERVER } from "../../constants/main";
import { PROJECT_PERMISSION } from "../../constants/project";
import { STATUS_REPORTING, STATUS_SCREENING, STATUS_VERIFICATION } from "../../constants/ratingSystem";
import { STATUS_IN_PROGRESS, STATUS_LOCKED } from "../../constants/sustainabilitySystem";
import {
  canApplyChanges,
  getWaitingLinkedKPIs,
  groupingElementsFromChildren,
  kpiRatingSystemFromEvaluate,
  processGrouping,
} from "../../helpers/kpiRatingSystem";
import { mergeKPIRatingSystem } from "../../helpers/merge/kpiRatingSystem";
import { canEdit } from "../../helpers/ratingSystem";
import { getActionSuggestion, setActionsProjectId } from "../../hooks/action";
import { useTableScrollX, useTableScrollY } from "../../hooks/advancedTable";
import { useEditSafeExit } from "../../hooks/editing";
import { useUploadingFiles } from "../../hooks/fileUpload";
import {
  acceptKpiRatingSystemChanges,
  declineKpiRatingSystemChanges,
  evaluateKpiRatingSystem,
  getKpiRatingSystem,
  getKpiRatingSystemRevisionById,
  getKpiRatingSystemRevisions,
  kpiSelectedRowSubject,
  setKpiRatingSystemGrouping,
  setKpiSelectedRow,
  useKpiRatingSystemAcceptChangesResponse,
  useKpiRatingSystemDeclineChangesResponse,
  useKpiRatingSystemEvaluateResponse,
  useKpiRatingSystemGrouping,
  useKpiRatingSystemResponse,
  useKpiRatingSystemRevisionByIdResponse,
  useKpiRatingSystemRevisionsResponse,
  useKpiSelectedRow,
} from "../../hooks/kpiRatingSystem";
import { getProjectGroup, useProjectGroupResponse } from "../../hooks/projectGroup";
import { uploadRatingReasonFileKey } from "../../hooks/ratingIndicatorFiles";
import { showError, showSuccess, showWarning } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { useProjectPermissions } from "../../hooks/utils/usePagePermissions";
import "../../pages/RatingSystem/RatingSystem.scss";
import { CloseSVG, ManageHistorySVG, SaveSVG } from "../../svg";
import { deepCopy, findRecursive, mapRecursive, processMessage, scrollElementByIdTo } from "../../utils";
import { validateRatingSystemGrouping } from "../../validation/ratingSystem";
import "./KPIRatingSystem.scss";

export const KPIRatingSystemPage = ({ analyze }) => {
  const mode = new URLSearchParams(window.location.search).get("mode");
  const { kpiRatingSystemId, groupId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const response = useKpiRatingSystemResponse();
  const system = deepCopy(response.data || {});
  const [updateCounter, setUpdateCounter] = useState(0);
  const groupResponse = useProjectGroupResponse();
  const group = groupResponse.data || {};
  const projectId = system.projectId;
  const permissions = system.projectPermission ?? {};

  useProjectPermissions(permissions, PROJECT_PERMISSION.ACCESS, response.loading);

  const [isEdit, setIsEdit] = useState(mode === "edit" && permissions.member);
  const [submitted, setSubmitted] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [acceptChangesInProgress, setAcceptChangesInProgress] = useState(false);
  const [linkedKpis, setLinkedKpis] = useState([]);
  const [askLater, setAskLater] = useState(false);
  const [header, setHeader] = useState({});
  const [previousHeader, setPreviousHeader] = useState(null);
  const [ratingStatus, setRatingStatus] = useState(null);
  const editDisabled = !isEdit || !canEdit(permissions);
  const selectedRow = useKpiSelectedRow();
  const grouping = useKpiRatingSystemGrouping();
  const [showGoals, setShowGoals] = useState(false);
  const [previousGrouping, setPreviousGrouping] = useState(null);
  const [ratingSystemGoals, setRatingSystemGoals] = useState([]);
  const [versionDialog, setVersionDialog] = useState(false);
  const [editIsDisabled, setEditIsDisabled] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [saveMerged, setSaveMerged] = useState(false);
  const versions = useKpiRatingSystemRevisionsResponse();
  const versionById = useKpiRatingSystemRevisionByIdResponse();
  const evaluateResponse = useKpiRatingSystemEvaluateResponse();
  const uploadingFiles = useUploadingFiles().filter((item) => item.key === uploadRatingReasonFileKey);

  const ratingSystemAcceptChangesResponse = useKpiRatingSystemAcceptChangesResponse();
  const ratingSystemDeclineChangesResponse = useKpiRatingSystemDeclineChangesResponse();

  useEditSafeExit(isEdit);

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const groupingErrors = useMemo(() => validateRatingSystemGrouping(grouping), [grouping]);

  useEffect(() => {
    if (kpiRatingSystemId) {
      if (mode === "edit" && permissions.member) {
        window.history.replaceState(null, null, window.location.pathname);
      }
      setKpiSelectedRow(null);
      load();
      getVersions();
    }
  }, [kpiRatingSystemId]);

  useEffect(() => {
    if (projectId) {
      setActionsProjectId(projectId);
      // getProjectUserPermissions(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (groupId) {
      getProjectGroup(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    if (kpiRatingSystemId && isEdit) {
      getActionSuggestion(undefined, kpiRatingSystemId);
    }
  }, [kpiRatingSystemId, isEdit]);

  useEffect(() => {
    if (saveMerged) {
      try {
        setSaveMerged(false);
        onSubmit();
      } catch (e) {
        showError(t("error.500"));
      }
    }
  }, [saveMerged]);

  useEffect(() => {
    if (system?.groupingElements?.length) {
      setLinkedKpis(getWaitingLinkedKPIs(system.groupingElements));
    }
  }, [system?.groupingElements?.length]);

  const mergeAndSave = async (updated) => {
    try {
      const current = await getKpiRatingSystem(kpiRatingSystemId);
      const updatedSystem = kpiRatingSystemFromEvaluate(response.data, updated);
      const [merged, withConflicts] = mergeKPIRatingSystem(response.data, current, updatedSystem);
      merged.groupingElements = groupingElementsFromChildren(merged.children);
      if (withConflicts) {
        showWarning(processMessage(t("main.savedWithConflicts"), [current?.modifier]));
      }
      resetData(merged);
      setSaveMerged(true);
    } catch (e) {
      showError(t("error.500"));
    }
  };

  useEffect(() => {
    if (!!system && isAcceptChangesRequired(system)) {
      setShowUpdateConfirmation(true);
    }
  }, [system.id]);

  const updateSelectedRow = (groupingInner) => {
    const selectedRowInner = kpiSelectedRowSubject.getValue();
    if (selectedRowInner) {
      const found = findRecursive(groupingInner, (item) => item.uuid === selectedRowInner.uuid);
      if (found) {
        setKpiSelectedRow(found);
      }
    }
  };

  const updateGrouping = useEvent((groupingInner) => {
    setKpiRatingSystemGrouping(groupingInner);
    updateSelectedRow(groupingInner);
  });

  const onSubmit = async () => {
    setSubmitted(true);
    setErrorMessage(null);
    let updated;
    if (!groupingErrors.length) {
      try {
        const { kpiElements, groupingElements } = processGrouping(grouping);
        const goals = (ratingSystemGoals || []).map((item) => ({
          ...item,
          goals: (item.goals || []).map((goal) => ({
            value: goal.value,
            date: moment().set("year", goal.year).endOf("year").format(DATE_FORMAT_SERVER),
          })),
          UID: undefined,
          typeKey: undefined,
        }));
        updated = {
          kpiRatingSystemId,
          projectId,
          kpiElements,
          groupingElements,
          ratingStatus,
          ratingSystemGoals: goals,
          modifiedDateTime: system?.modifiedDateTime,
        };
        await evaluateKpiRatingSystem(updated);
        setIsEdit(false);
        load();
        getVersions();
        showSuccess(t("ratingSystem.updatedMessage"));
      } catch (error) {
        if (error.status === 409) {
          error.json().then((parsedBody) => {
            if (parsedBody?.message === "error.optimistic.lock") {
              mergeAndSave(updated);
            }
          }, console.error);
        } else {
          showError(t("ratingSystem.errorSaving"));
        }
      }
      setSubmitted(false);
    } else {
      setErrorMessage(t("ratingSystem.validationError"));
    }
  };

  const isAcceptChangesRequired = (data) =>
    !askLater && data.updated && canApplyChanges(data.ratingStatus, permissions);

  const load = () => {
    getKpiRatingSystem(kpiRatingSystemId)
      .then((data) => {
        if (data.status === STATUS_LOCKED || data.status === STATUS_IN_PROGRESS) {
          showWarning(
            processMessage(t("ratingSystem.warningSustainabilitySystemStatus"), [
              [data.systemSource, data.name, data.systemVersion].join(" "),
            ])
          );
          navigate(`/project/${projectId}`);
        }
        resetData(data);

        setAcceptChangesInProgress(false);
      })
      .catch((error) => {
        console.error(error);
        showError(t("error.500"));
        navigate(`/project/${projectId}`);
      });
  };

  const getVersions = () => {
    getKpiRatingSystemRevisions(kpiRatingSystemId)
      .then((data) => {
        setSelectedVersion(data[data.length - 1]?.revisionNumber || null);
      })
      .catch((error) => {
        console.error(error);
        showError(t("error.500"));
      });
  };

  const sortedVersions = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return [...data].sort((a, b) => b.revisionNumber - a.revisionNumber);
  };

  const versionHandler = (revisionNumber, isCurrent) => {
    getKpiRatingSystemRevisionById(kpiRatingSystemId, revisionNumber)
      .then((data) => {
        resetData(data);
        setSelectedVersion(revisionNumber);
        setEditIsDisabled(!isCurrent);
      })
      .catch(() => {
        showError(t("error.500"));
      });
    setVersionDialog(false);
  };

  const acceptChanges = () => {
    setShowUpdateConfirmation(false);
    setAcceptChangesInProgress(true);
    acceptKpiRatingSystemChanges(kpiRatingSystemId)
      .then((values) => {
        setPreviousGrouping(grouping);
        setPreviousHeader(header);
        resetData(values);
      })
      .catch((error) => {
        console.error(error);
        setAcceptChangesInProgress(false);
        load();
      });
  };

  const declineChanges = () => {
    setShowUpdateConfirmation(false);
    setAcceptChangesInProgress(true);
    declineKpiRatingSystemChanges(kpiRatingSystemId)
      .then(() => {
        setPreviousGrouping(grouping);
        setPreviousHeader(header);
      })
      .catch((error) => {
        console.error(error);
        setAcceptChangesInProgress(false);
      })
      .finally(load);
  };

  const askDecisionLater = () => {
    setShowUpdateConfirmation(false);
    setAskLater(true);
  };

  const resetData = (values, disableEditing) => {
    if (disableEditing) {
      setIsEdit(false);
    }
    const data = values || system || {};
    setRatingStatus(data.ratingStatus);
    setHeader({
      name: data.name || "",
      description: data.description || "",
      status: data.status || "",
      ratingStatus: data.ratingStatus || "",
      systemSource: data.systemSource || "",
      systemVersion: data.systemVersion || "",
      projectTypes: data.projectTypes || [],
    });
    const processedGroupings = deepCopy(
      mapRecursive(
        data.groupingElements || [],
        (node, children) => {
          return {
            UID: String(node.id || getUUID()),
            ...node,
            children: [
              ...(node.kpiElements || []).map((item) => {
                return {
                  UID: item.id ? "i_" + item.id : getUUID(),
                  ...item,
                  kpiName: item?.kpi?.name,
                  currentValue: item?.kpi?.currentValue,
                  estimation: item?.kpi?.estimation,
                  potentialValue: item?.kpi?.potentialValue,
                  type: GROUPING_TYPE_KPI,
                };
              }),
              ...children,
            ],
          };
        },
        "groupingElements"
      )
    );
    updateGrouping(processedGroupings);
    if (selectedRow) {
      updateSelectedRow(processedGroupings);
    }
    const { ratingSystemGoals: ratingSystemGoalsInner } = data;
    processGoals(ratingSystemGoalsInner);
    setSubmitted(false);
    setUpdateCounter(updateCounter + 1);
  };

  const processGoals = (goals) => {
    const processedGoals = (goals || []).map((node) => ({ UID: String(node.id || getUUID()), ...node }));
    setRatingSystemGoals(processedGoals);
  };

  const renderDockContent = () => {
    if (!selectedRow) {
      return null;
    }
    return selectedRow.type === GROUPING_TYPE_KPI ? (
      <KPIElementDock
        data={selectedRow}
        projectId={projectId}
        rows={grouping}
        onChange={updateGrouping}
        readOnly={editDisabled}
        kpiRatingSystemId={kpiRatingSystemId}
        permissions={permissions}
      />
    ) : (
      <GroupingElementDock data={selectedRow} rows={grouping} onChange={updateGrouping} readOnly={editDisabled} />
    );
  };

  const loading =
    ratingSystemAcceptChangesResponse.loading ||
    ratingSystemDeclineChangesResponse.loading ||
    response.loading ||
    evaluateResponse.loading ||
    versions.loading ||
    versionById.loading;
  const saveDisabled = !!uploadingFiles.length;

  // Auto scroll back to position after saving
  const tableScrollPosition = { x: useTableScrollX(), y: useTableScrollY() };
  const [lastScrollPosition, setLastScrollPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (submitted) {
      setLastScrollPosition({ x: tableScrollPosition.x, y: tableScrollPosition.y });
    }
  }, [submitted]);

  useEffect(() => {
    scrollElementByIdTo(ADVANCED_TABLE_ID, lastScrollPosition);
  }, [updateCounter, loading]);
  // End auto scroll back to position after saving

  const afterDecisions = useEvent(() => {
    setLinkedKpis([]);
    load();
  });

  const memoGrouping = useMemo(() => grouping, [JSON.stringify(grouping)]);
  const memoGroupingErrors = useMemo(() => groupingErrors, [JSON.stringify(groupingErrors)]);

  const analyzePart = analyze ? "/analyze" : "";
  let pathParts = [];
  if (groupId) {
    pathParts.push({ url: "/projectGroup", text: t("menu.projectGroups.title") });
    pathParts.push({ url: "/projectGroup" + analyzePart + "/" + groupId, text: group.name });
    pathParts.push({
      url: "/projectGroup" + analyzePart + "/" + groupId + "/project/" + projectId,
      text: system.projectName || t("menu.project.back"),
    });
  } else {
    pathParts.push({ url: `/project`, text: t("menu.project.title") });
    pathParts.push({ url: `/project/${projectId}`, text: system.projectName || t("menu.project.back") });
  }
  pathParts.push({ text: header.systemSource || "" });

  const decisionsDisabled = !system.id || isAcceptChangesRequired(system) || acceptChangesInProgress;

  return (
    <LoadingOverlay spinner active={loading} className="kpi-rating-system-page rating-system-page">
      <KPIProjectFieldsDecisions
        projectId={projectId}
        linkedKpis={linkedKpis}
        disabled={decisionsDisabled}
        onFinish={afterDecisions}
      />

      <form autoComplete={"off"} className="content-panel">
        <div className="page-header">
          <div className="flex-row">
            <Breadcrumb pathParts={pathParts} />
            <div className="flex-auto text-right">
              <DashboardLink system={system} />
              <Button
                color="primary"
                className="right-header-button"
                onClick={() => setVersionDialog(true)}
                disabled={isEdit}
              >
                <ManageHistorySVG fontSize="inherit" style={{ fill: isEdit ? "rgba(0, 0, 0, 0.26)" : "" }} />
                {t("main.version")}
              </Button>
            </div>
          </div>
          <div className="flex-row">
            <h2 className="page-title">{header.systemSource || ""}</h2>
            <div>
              <FormControl fullWidth required={false} className="status-select">
                <Select
                  fullWidth
                  onChange={(event) => setRatingStatus(event.target.value)}
                  disabled={!permissions.permissionStatusChanger || !isEdit}
                  value={ratingStatus || ""}
                  label={t("ratingSystem.status.title")}
                >
                  {(ratingStatus === STATUS_SCREENING ||
                    header.ratingStatus === STATUS_SCREENING ||
                    header.ratingStatus === STATUS_VERIFICATION) && (
                    <MenuItem key={STATUS_SCREENING} value={STATUS_SCREENING}>
                      <StatusBadge className={"status-" + STATUS_SCREENING}>
                        {t("ratingSystem.status." + STATUS_SCREENING)}
                      </StatusBadge>
                    </MenuItem>
                  )}
                  <MenuItem key={STATUS_VERIFICATION} value={STATUS_VERIFICATION}>
                    <StatusBadge className={"status-" + STATUS_VERIFICATION}>
                      {t("ratingSystem.status." + STATUS_VERIFICATION)}
                    </StatusBadge>
                  </MenuItem>
                  {(ratingStatus === STATUS_REPORTING ||
                    header.ratingStatus === STATUS_VERIFICATION ||
                    header.ratingStatus === STATUS_REPORTING) && (
                    <MenuItem key={STATUS_REPORTING} value={STATUS_REPORTING}>
                      <StatusBadge className={"status-" + STATUS_REPORTING}>
                        {t("ratingSystem.status." + STATUS_REPORTING)}
                      </StatusBadge>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="flex-auto text-right">
              <TwoOptionsToggle
                value={showGoals}
                onChange={setShowGoals}
                option1={{ value: false, label: t("sustainabilitySystem.step.table") }}
                option2={{ value: true, label: t("ratingSystem.label.goals") }}
              />
            </div>
          </div>
        </div>

        <div className="top-panel" hidden={!system.id}>
          <div className="base-data">
            <div className="flex-row">
              <div>
                <h2>{t("sustainabilitySystem.baseData")}</h2>
              </div>
              <div className="flex-auto" />
              <div className="text-right">
                <IconButton onClick={() => setCollapsed(!collapsed)} size="small" className="collapse-trigger">
                  {!!collapsed && <ExpandMoreIcon />}
                  {!collapsed && <ExpandLessIcon />}
                </IconButton>
              </div>
            </div>
            <div className={"collapsible " + (collapsed ? "collapsed" : "")}>
              <div className="collapsible-inner flex-row">
                <div className="name-col">
                  <TextField
                    fullWidth
                    value={header.name || ""}
                    label={t("kpiSystem.name")}
                    disabled
                    className={!!previousHeader && !!header && previousHeader.name !== header.name ? "changed" : ""}
                  />
                </div>
                <div className="version-col">
                  <TextField
                    fullWidth
                    value={header.systemVersion || ""}
                    label={t("kpiSystem.systemVersionShort")}
                    disabled
                    className={
                      !!previousHeader && !!header && previousHeader.systemVersion !== header.systemVersion
                        ? "changed"
                        : ""
                    }
                  />
                </div>
                <div className="project-type-col">
                  <TextField
                    value={(header.projectTypes || []).map((item) => t("sustainabilitySystem.type." + item)).join(", ")}
                    fullWidth
                    label={t("sustainabilitySystem.type.title")}
                    disabled
                    className={
                      !!previousHeader &&
                      !!header &&
                      (previousHeader.projectTypes || []).join() !== (header.projectTypes || []).join()
                        ? "changed"
                        : ""
                    }
                  />
                </div>
                <div className="description-col">
                  <TextField
                    fullWidth
                    value={header.description || ""}
                    label={t("kpiSystem.description")}
                    disabled
                    className={
                      !!previousHeader && !!header && previousHeader.description !== header.description ? "changed" : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dock
          key={updateCounter}
          show={!!selectedRow}
          hidden={!!showGoals}
          content={renderDockContent()}
          onClose={() => setKpiSelectedRow(null)}
        >
          <KPIRatingSystemGrouping
            values={memoGrouping}
            previousValues={previousGrouping}
            onChange={updateGrouping}
            submitted={submitted}
            readOnly={editDisabled}
            selectedRow={selectedRow}
            errors={memoGroupingErrors}
            errorMessage={!!memoGroupingErrors.length && errorMessage}
          />
        </Dock>

        <div hidden={!showGoals} className="goals-wrapper">
          <RatingSystemGoals
            values={ratingSystemGoals}
            onChange={setRatingSystemGoals}
            readOnly={editDisabled}
            grouping={memoGrouping}
            kpiOnly
          />
        </div>

        {permissions.member && (
          <div className="fabs">
            {!isEdit && (
              <Fab type="button" color="primary" onClick={() => setIsEdit(true)} disabled={editIsDisabled}>
                <EditIcon className="fab-svg" />
              </Fab>
            )}
            {isEdit && (
              <>
                <Fab color="secondary" onClick={() => resetData(null, true)}>
                  <CloseSVG className="fab-svg" />
                </Fab>
                <Fab color="primary" onClick={handleSubmit(onSubmit)} disabled={saveDisabled}>
                  <SaveSVG className="fab-svg" />
                </Fab>
              </>
            )}
          </div>
        )}
      </form>

      <ConfirmationDialog
        open={!!showUpdateConfirmation}
        onConfirm={acceptChanges}
        onCancel={declineChanges}
        thirdAction={askDecisionLater}
        titleText={t("ratingSystem.acceptChangesTitle")}
        bodyText={t("kpiRatingSystem.acceptChanges")}
        thirdActionText={t("ratingSystem.askLater")}
        confirmText={t("main.accept")}
        color="secondary"
      />

      <VersionsDialog
        open={versionDialog}
        onClose={() => setVersionDialog(false)}
        onAction={versionHandler}
        titleText={t("versions.titleRating")}
        data={sortedVersions(versions.data)}
        selectedVersion={selectedVersion}
        loading={versionById.loading}
      />
    </LoadingOverlay>
  );
};
