import EditIcon from "@mui/icons-material/Edit";
import Fab from "@mui/material/Fab";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useUser } from "../../hooks/auth";
import { showError, showSuccess } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { CloseSVG, SaveSVG } from "../../svg";
import { FormController } from "../FormController/FormController";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import { UserWidget } from "../UserWidget/UserWidget";
import "./UserProfile.css";

import FormControl from "@mui/material/FormControl";
import { PERMISSION_USER_PROFILE_EDIT } from "../../constants/permissions";
import { USERS_DRESO } from "../../constants/users";
import { hasPermission } from "../../helpers/permission";
import {
  getUserProfile,
  updateUserProfile,
  useGetUserProfileResponse,
  useUpdateUserProfileResponse,
} from "../../hooks/users";

export const UserProfile = () => {
  const { t } = useTranslation();
  const response = useUser();
  const userId = response.user.id;
  const [isEdit, setIsEdit] = useState(!userId);
  const [editIsDisabled] = useState(false);

  const getResponse = useGetUserProfileResponse();
  const updateResponse = useUpdateUserProfileResponse();
  const { control, handleSubmit, reset } = useForm();
  const { firstName, lastName, encodedPicture, email } = response?.user || {};
  const fullName = [firstName || "", lastName || ""].join(" ");
  const navigate = useNavigate();
  const loading = response.loading || getResponse.loading || updateResponse.loading;
  const canStartEdit = hasPermission(PERMISSION_USER_PROFILE_EDIT);
  const externalUser = email && !email.includes(USERS_DRESO);

  useEffect(() => {
    if (userId) {
      if (getResponse.data && String(getResponse.id) === userId) {
        resetData();
      }
      load();
    }
  }, [userId]);

  const load = () => {
    if (externalUser) {
      getUserProfile(userId)
        .then(resetData)
        .catch(() => {
          showError(t("userProfile.error.load"));
          navigate("/admin/userProfile");
        });
    }
  };

  const handleCancel = useEvent(() => {
    setIsEdit(false);
  });

  const onSubmit = async (values) => {
    try {
      const updated = { ...values };
      const data = await updateUserProfile(updated);
      resetData(data);
      showSuccess(t("userProfile.updatedMessage"));
    } catch (error) {
      showError(t("error.500"));
    }
  };

  const resetData = (values) => {
    const data = values || response.data || {};
    reset({ ...data });
  };

  return (
    <LoadingOverlay spinner active={loading} className="project-edit-page">
      <form autoComplete={"off"}>
        {externalUser && (
          <div>
            <div className="page-layout">
              <h2 className="flex-auto">{t("userProfile.mainTitle")}</h2>
              <div className="flex-row content-block">
                <div className="flex-col-col1">
                  <div className="row">
                    <FormController
                      control={control}
                      name="title"
                      required="true"
                      label={t("userProfile.title") + " *"}
                      render={({ field, label }) => (
                        <TextField
                          fullWidth
                          label={label}
                          {...field}
                          value={field.value || ""}
                          disabled={!isEdit}
                          inputProps={{ maxLength: 16 }}
                        />
                      )}
                    />
                  </div>

                  <div className="row">
                    <FormController
                      control={control}
                      name="firstName"
                      required="true"
                      label={t("userProfile.firstName") + " *"}
                      render={({ field, label }) => (
                        <TextField
                          fullWidth
                          label={label}
                          {...field}
                          value={field.value || ""}
                          disabled={!isEdit}
                          inputProps={{ maxLength: 64 }}
                        />
                      )}
                    />
                  </div>

                  <div className="row">
                    <FormController
                      control={control}
                      name="streetAddress"
                      required="true"
                      label={t("userProfile.streetAddress") + " *"}
                      render={({ field, label }) => (
                        <TextField
                          fullWidth
                          label={label}
                          {...field}
                          value={field.value || ""}
                          disabled={!isEdit}
                          inputProps={{ maxLength: 256 }}
                        />
                      )}
                    />
                  </div>

                  <div className="row">
                    <FormController
                      control={control}
                      name="city"
                      required="true"
                      label={t("userProfile.city") + " *"}
                      render={({ field, label }) => (
                        <TextField
                          fullWidth
                          label={label}
                          {...field}
                          value={field.value || ""}
                          disabled={!isEdit}
                          inputProps={{ maxLength: 64 }}
                        />
                      )}
                    />
                  </div>

                  <div className="row">
                    <FormController
                      control={control}
                      name="company"
                      label={t("userProfile.company")}
                      render={({ field, label }) => (
                        <TextField fullWidth label={label} {...field} value={field.value || ""} disabled />
                      )}
                    />
                  </div>

                  <div className="text-field-mandatory">
                    <span>{t("userProfile.fieldMandatory")}</span>
                  </div>
                </div>

                <div className="flex-col-col2">
                  <div className="row">
                    <FormController
                      control={control}
                      name="lastName"
                      required="true"
                      label={t("userProfile.lastName") + " *"}
                      render={({ field, label }) => (
                        <TextField
                          fullWidth
                          label={label}
                          {...field}
                          value={field.value || ""}
                          disabled={!isEdit}
                          inputProps={{ maxLength: 64 }}
                        />
                      )}
                    />
                  </div>

                  <div className="row">
                    <FormController
                      control={control}
                      name="postalCode"
                      required="true"
                      label={t("userProfile.postalCode") + " *"}
                      render={({ field, label }) => (
                        <TextField
                          fullWidth
                          label={label}
                          {...field}
                          value={field.value || ""}
                          disabled={!isEdit}
                          inputProps={{ maxLength: 16 }}
                        />
                      )}
                    />
                  </div>

                  <div className="row">
                    <FormController
                      control={control}
                      name="country"
                      required="true"
                      label={t("userProfile.country") + " *"}
                      render={({ field, label }) => (
                        <TextField
                          fullWidth
                          label={label}
                          {...field}
                          value={field.value || ""}
                          disabled={!isEdit}
                          inputProps={{ maxLength: 64 }}
                        />
                      )}
                    />
                  </div>

                  <div className="row">
                    <FormController
                      control={control}
                      name="email"
                      label={t("userProfile.email")}
                      render={({ field, label }) => (
                        <TextField fullWidth label={label} {...field} value={field.value || ""} disabled />
                      )}
                    />
                  </div>
                </div>

                <div className="flex-col-col3">
                  <div className="row">
                    <div className="cell">
                      <UserWidget fullName={fullName} encodedPicture={encodedPicture} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {canStartEdit && (
              <div className="fabs">
                {!isEdit && (
                  <Fab type="button" color="primary" onClick={() => setIsEdit(true)} disabled={editIsDisabled}>
                    <EditIcon className="fab-svg" />
                  </Fab>
                )}
                {isEdit && (
                  <>
                    <Fab color="secondary" onClick={handleCancel}>
                      <CloseSVG className="fab-svg" />
                    </Fab>
                    <Fab color="primary" onClick={handleSubmit(onSubmit)}>
                      <SaveSVG className="fab-svg" />
                    </Fab>
                  </>
                )}
              </div>
            )}
          </div>
        )}
        {!externalUser && (
          <div className="page-layout">
            <h2 className="flex-auto">{t("userProfile.mainTitle")}</h2>
            <div className="flex-row content-block">
              <div className="flex-col-col1">
                <div className="row">
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      disabled
                      label={t("userProfile.firstName")}
                      defaultValue={firstName}
                      className="read-only"
                    />
                  </FormControl>
                </div>

                <div className="row">
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      disabled
                      label={t("userProfile.lastName")}
                      defaultValue={lastName}
                      className="read-only"
                    />
                  </FormControl>
                </div>

                <div className="row">
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      disabled
                      label={t("userProfile.email")}
                      defaultValue={email}
                      className="read-only"
                    />
                  </FormControl>
                </div>
              </div>

              <div className="flex-col-col3">
                <div className="row">
                  <div className="cell">
                    <UserWidget fullName={fullName} encodedPicture={encodedPicture} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </LoadingOverlay>
  );
};
