import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useState } from "react";

export const CollapsiblePanel = ({ children, header }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className={"collapsible-panel" + (collapsed ? " collapsed" : "")}>
      <div className={"collapsible-header" + (collapsed ? " collapsed" : "")} onClick={() => setCollapsed(!collapsed)}>
        <div>{header}</div>
        <ArrowDropDownIcon />
      </div>
      <div className={"collapsible" + (collapsed ? " collapsed" : "")}>{children}</div>
    </div>
  );
};
