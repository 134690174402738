import i18next from "i18next";
import React from "react";
import { CUSTOM_VALUE } from "../../constants/sustainabilitySystem";
import {
  processAllIndicatorsWeighting,
  processMaxSystemScore,
  processMaxSystemScoreProportions,
} from "./NewRatingSystem.sustainabilitySystem";
import { isExcludedElement } from "./NewRatingSystem.utils";
import { recalculateGroupingScore, recalculatePotentialGroupingScore } from "./NewRatingSystemESG.ratingSystem";
import { conditionIsCategory, conditionIsCriterion, conditionIsIndicator } from "./NewRatingSystemESG.validation";

export const isJSONEmpty = (obj) => {
  for (let key in obj) {
    if (Object.hasOwn(obj, key)) return false;
  }

  return true;
};

export const actionCondition = (row) => row?.evaluationSystemScore !== row?.potentialEvaluationSystemScore;

export const isExcluded = (element, parentField, map) => {
  if (element?.excluded) return true;

  let parentUuid = element[parentField];
  while (parentUuid) {
    const parent = map[parentUuid];
    if (parent?.excluded) return true;
    parentUuid = parent[parentField];
  }

  return false;
};

export const mapColumns = (
  column,
  editItemField,
  editCellMap,
  filterCellMap,
  readOnly,
  showFilter,
  hiddenColumnMap = {}
) => {
  if (column.children) {
    let newColumn = { ...column };
    newColumn.children = column.children
      .filter((c) => !hiddenColumnMap[c.field ?? c.title]?.hidden)
      .map((c) => mapColumns(c, editItemField, editCellMap, filterCellMap, readOnly, showFilter, hiddenColumnMap));
    return newColumn;
  }

  return {
    ...column,
    editCell:
      editCellMap[column.field] && editItemField === column.field && !readOnly ? editCellMap[column.field] : null,
    filterCell: showFilter ? filterCellMap[column?.field] : null,
  };
};

export const rowClassName = (dataItem) => {
  if (conditionIsCategory(dataItem)) return "category-row";
  else if (conditionIsCriterion(dataItem)) return "criterion-row";
  else if (conditionIsIndicator(dataItem)) return "indicator-row";
  return "";
};

const createColumnsData = (column, index, path) => {
  let result = { ...column, orderIndex: index, id: `_${index}_column`, path };
  index++;

  if (column.children) {
    result.children = column.children.map((c, childIndex) => {
      const childrenPath = path.concat(["children", childIndex]);
      const [result, i] = createColumnsData(c, index, childrenPath);
      index = i;
      return result;
    });
  }

  return [result, index];
};

export const initializeColumns = (columns) => {
  let index = 0;
  return columns.map((column, columnIndex) => {
    const [result, i] = createColumnsData(column, index, [columnIndex]);
    index = i;
    return result;
  });
};

export const getColumnByPath = (columns, path = []) => {
  let result = columns;
  for (const p of path) {
    if (!result && !result?.[p]) return null;
    result = (result ?? columns)?.[p];
  }

  return result;
};

export const createColumnMap = (columns, valueFn) =>
  columns.reduce((acc, column) => {
    let result = {};
    if (column.children)
      result = {
        ...result,
        [column.title]: valueFn ? valueFn(column) : column,
        ...createColumnMap(column.children, valueFn),
      };
    else result = { ...result, [column.field]: valueFn ? valueFn(column) : column };
    return { ...acc, ...result };
  }, {});

export const createColumnNestedMap = (columns, value) =>
  columns.reduce((acc, column) => {
    let result = { [column.field]: value ?? column };
    if (column.children) result = { ...result, [column.field]: createColumnNestedMap(column.children, value) };
    return { ...acc, ...result };
  }, {});

const setLevel = (array, level, levelArray) => {
  array.forEach((element) => {
    element.level = level;
    if (!levelArray[level]) levelArray[level] = [];
    levelArray[level].push(element);
    if (element.children) setLevel(element.children, level + 1, levelArray);
  });
};

export const createNestedArray = (elementsMap, parentField, subItemsField, expandedField, editField, selectedField) => {
  const nestedArray = [];

  for (const key in elementsMap) {
    const element = elementsMap[key];
    element[expandedField] = true;
    element[editField] = null;
    element[selectedField] = false;

    if (element[parentField]) {
      const parentElement = elementsMap[element[parentField]];
      if (parentElement) {
        if (!parentElement[subItemsField]) parentElement[subItemsField] = [];
        parentElement[subItemsField].push(element);
      }
    } else {
      nestedArray.push(element);
    }
  }

  const levelArray = [];
  setLevel(nestedArray, 0, levelArray);

  return { nestedArray, levelArray };
};

export const getOrderIndex = (column, index, parent) => {
  if (!parent?.length) return column.orderIndex;
  let neighbor = parent[index];
  if (neighbor) return neighbor.orderIndex - 1;

  neighbor = parent[index - 1];
  if (neighbor) return column.orderIndex + (column.orderIndex - neighbor.orderIndex);

  neighbor = parent[index + 1];
  if (neighbor) return column.orderIndex + (neighbor.orderIndex - column.orderIndex);

  return column.orderIndex;
};

export const calculateTrafficLight = (indicator) => {
  indicator.trafficLightScore = calculateTrafficLightScore(indicator);
  indicator.trafficLightReason = calculateTrafficLightReason(indicator);
  indicator.trafficLightActions = calculateTrafficLightAction(indicator);
  return parseInt(`${indicator.trafficLightScore}${indicator.trafficLightReason}${indicator.trafficLightActions}`, 2);
};

export const calculateTrafficLightScore = ({ evaluationSystemScore }) =>
  !!evaluationSystemScore || evaluationSystemScore === 0 ? 1 : -1;

export const calculateTrafficLightReason = ({ reason }) => (reason ? 1 : -1);

export const calculateTrafficLightAction = (indicator) => {
  const { actions } = indicator || {};

  const mustHaveActions = actionCondition(indicator);
  const shouldHaveActions = indicator?.evaluationSystemScore !== indicator?.potentialEvaluationSystemScore ? -1 : 0;
  return mustHaveActions && actions?.length > 0 ? 1 : shouldHaveActions;
};

const sortChildrenByPosition = (column) => {
  if (column.children)
    column.children = column.children.map(sortChildrenByPosition).sort((a, b) => a.position - b.position);
  return column;
};

export const sortElementsByPosition = (columns) => {
  const sortedColumns = columns.map(sortChildrenByPosition).sort((a, b) => a.position - b.position);
  return sortedColumns;
};

export const someMap = (map, callback) => {
  for (const key in map ?? {}) {
    const result = callback?.(map[key]);
    if (result) return true;
  }

  return false;
};

const levelArrayForEach = (callback, levelArray) => {
  for (let levelIndex = levelArray.length - 1; levelIndex >= 0; levelIndex--) {
    const level = levelArray[levelIndex];
    for (const element of level) callback(element);
  }
};

export const forEachMap = (dataItem, map, parentField, callback, levelArray) => {
  if (levelArray) return levelArrayForEach(callback, levelArray);

  let element = dataItem;
  while (element) {
    callback(element);
    element = map[element[parentField]];
  }
};

const levelArrayReverse = (callback, levelArray) => {
  for (const level of levelArray) {
    for (const element of level) callback(element);
  }
};

export const forEachReverseMap = (dataItem, map, parentField, callback, levelArray) => {
  if (levelArray) return levelArrayReverse(callback, levelArray);

  let element = dataItem;
  const stack = [];
  while (element) {
    stack.push(element);
    element = map[element[parentField]];
  }

  stack.toReversed().forEach(callback);
};

export const systemScoreItemRender = (li, itemProps) => {
  return React.cloneElement(
    li,
    li.props,
    itemProps?.dataItem?.value !== CUSTOM_VALUE
      ? `${itemProps?.dataItem?.value} : ${itemProps?.dataItem?.systemScore}`
      : i18next.t(`common.systemScoreValues.${CUSTOM_VALUE}`) ?? "asdf"
  );
};

export const systemScoreValueRender = (element, value) => {
  if (!value) return element;

  return React.cloneElement(
    element,
    {
      ...element.props,
    },
    value?.value !== CUSTOM_VALUE
      ? `${value?.value} : ${value?.systemScore}`
      : i18next.t(`common.systemScoreValues.${CUSTOM_VALUE}`)
  );
};

export const filterHidableColumns = (column) => column?.hidable || column?.children?.some((child) => child?.hidable);

export const recalculateFields = (dataItem, map, grouping, parentField, systemLogic, levelArray) => {
  processMaxSystemScore(dataItem, map, parentField, levelArray);
  processMaxSystemScoreProportions(dataItem, map, grouping, parentField, systemLogic, levelArray);
  processAllIndicatorsWeighting(dataItem, map, parentField, levelArray);
};

export const updateGrouping = (dataItem, map, grouping, parentField, systemLogic, levelArray) => {
  console.time("updateGrouping");
  recalculateFields(dataItem, map, grouping, parentField, systemLogic, levelArray);

  forEachMap(
    dataItem,
    map,
    parentField,
    (node) => (node.excludedFromCalculation = isExcludedElement(node)),
    levelArray
  );

  recalculateGroupingScore(dataItem, map, parentField, systemLogic, levelArray);
  recalculatePotentialGroupingScore(dataItem, map, parentField, systemLogic, levelArray);
  console.timeEnd("updateGrouping");
};
