import { getProjectTypeName } from "../../SustainabilitySystem/SustainabilitySystemFields/ProjectTypeSelect/ProjectTypeSelect";
import { ESG_TYPES, PROJECT_HEADER_INTERNAL_EXTERNAL_ID_MAX_LENGTH } from "../../../constants/project";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./ProjectHeader.scss";
import { substrIfLenghty } from "../../../utils";
import { getUserTenantType } from "../../../hooks/auth";
import { TENANT_TYPE_ESG } from "../../../constants/tenant";
import { OTHER_TYPES } from "../../../constants/sustainabilitySystem";
import { getProjectAttributeList, useProjectAttributeListResponse } from "../../../hooks/project";
import {
  UNDERSCORE_LOCATION,
  TYPE_CHECKBOX,
  TYPE_DATE,
  TYPE_LOCATION,
  TYPE_SELECT,
} from "../../../constants/projectAttribute";
import { DATE_FORMAT_CLIENT, DATE_FORMAT_SERVER } from "../../../constants/main";
import moment from "moment";

export const ProjectHeader = ({ project }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const tenantType = getUserTenantType();
  const projectAttributeListResponse = useProjectAttributeListResponse();
  const projectAttributes = projectAttributeListResponse.data || {};
  const fields = projectAttributes?.fields || [];

  useEffect(() => {
    if (project?.projectTypes?.length) {
      getProjectAttributeList(project.projectTypes[0]);
    }
  }, [project?.projectTypes?.length]);

  function processOption(details, value) {
    const option = (details?.options || []).find((item) => item.value === value);
    if (option) {
      value = (option.details || {})[language] || value;
    }
    return value;
  }

  function processValue(type, value, name, details) {
    switch (type) {
      case TYPE_LOCATION:
        value = (project.customFields || {})[name + UNDERSCORE_LOCATION];
        break;
      case TYPE_SELECT:
        value = processOption(details, value);
        break;
      case TYPE_CHECKBOX:
        value = value ? t("main.yes") : t("main.no");
        break;
      case TYPE_DATE:
        value = value ? moment(value, DATE_FORMAT_SERVER).format(DATE_FORMAT_CLIENT) : null;
        break;
      default:
        break;
    }
    return value;
  }

  return (
    <div className="project-header content-block flex-row">
      <div className={"left-block" + (projectAttributes?.fields?.length ? " right-bar-separator" : "")}>
        <h2 className="large">{project.name}</h2>
        <div className="muted">
          {(Array.isArray(project.projectTypes) ? project.projectTypes : [])
            .map((item) => getProjectTypeName(item, tenantType === TENANT_TYPE_ESG ? ESG_TYPES : OTHER_TYPES, t))
            .join(", ")}
        </div>
        <p>{project.description}</p>
        <br />
        {!!(
          project.internalProjectId ||
          project.externalProjectId ||
          project.clientName ||
          project.ownerName ||
          project.createdDateTime
        ) && (
          <div className="secondary-block">
            {!!project.internalProjectId && (
              <div className="data-block">
                <label>
                  <b>{t("project.internalProjectId")}:</b>
                  <span className="k-ml-2">
                    {substrIfLenghty(project.internalProjectId, PROJECT_HEADER_INTERNAL_EXTERNAL_ID_MAX_LENGTH, " ...")}
                  </span>
                </label>
              </div>
            )}
            {!!project.externalProjectId && (
              <div className="data-block">
                <label>
                  <b>{t("project.externalProjectId")}:</b>
                  <span className="k-ml-2">
                    {substrIfLenghty(project.externalProjectId, PROJECT_HEADER_INTERNAL_EXTERNAL_ID_MAX_LENGTH, " ...")}
                  </span>
                </label>
              </div>
            )}
            {!!project.clientName && (
              <div className="data-block">
                <label>
                  <b>{t("project.clientName")}:</b>
                  <span className="k-ml-2">{project.clientName}</span>
                </label>
              </div>
            )}
            {!!project.ownerName && (
              <div className="data-block">
                <label>
                  <b>{t("project.ownerName")}:</b>
                  <span className="k-ml-2">{project.ownerName}</span>
                </label>
              </div>
            )}
            {!!project.createdDateTime && (
              <div className="data-block">
                <label>
                  <b>{t("project.creationDate")}:</b>
                  <span className="k-ml-2">
                    {moment.utc(project.createdDateTime).local().format(DATE_FORMAT_CLIENT)}
                  </span>
                </label>
              </div>
            )}
            {!!project.projectLead && (
              <div className="data-block">
                <label>
                  <b>{t("project.projectLead")}:</b>
                  <span className="k-ml-2">{project.projectLeadName}</span>
                </label>
              </div>
            )}
          </div>
        )}
      </div>
      {!!projectAttributes?.fields?.length && (
        <div className="right-block flex-auto">
          <div className="secondary-block">
            {(fields || []).map(({ name, type, details, required }, index) => {
              const label = (details || {})[language] ? (details || {})[language] : name;
              let value = (project.customFields || {})[name];
              value = processValue(type, value, name, details);

              if (!value && !required) {
                return null; // Skip rendering this div
              }
              return (
                <div key={index} className={"data-block" + (type === TYPE_LOCATION ? " location" : "")}>
                  <div className="value">{value || "-"}</div>
                  {type !== TYPE_LOCATION && <label>{label}</label>}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
