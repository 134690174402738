import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { loginRequest } from "../authConfig";
import { SYSTEM_TENANT_NAME } from "../constants/tenant";

export const resetAuthCookie = () =>
  (document.cookie = `auth.access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`);

let tokenRefreshTimeout;

export const handleTokenResponse = (response) => {
  const { account, accessToken, idTokenClaims } = response;
  setAccount(account);
  setAccessToken(accessToken);
  const { exp } = idTokenClaims;
  const accessTokenToExpire = exp * 1000 - new Date().getTime();
  clearTimeout(tokenRefreshTimeout);
  tokenRefreshTimeout = setTimeout(() => {
    const request = {
      ...loginRequest(),
      account: accountSubject.getValue(),
    };
    acquireToken(request, msalInstanceSubject.getValue());
  }, accessTokenToExpire - 60000);
};

export const acquireToken = (request, instance) => {
  instance
    .acquireTokenSilent(request)
    .then(handleTokenResponse)
    .catch(() => {
      instance.acquireTokenRedirect(request);
    });
};

export const accessTokenSubject = new BehaviorSubject(null);
export const useAccessToken = hookFromSubject(accessTokenSubject);
export const setAccessToken = (accessToken) => {
  document.cookie = `auth.access_token=${accessToken}; path=/`;
  accessTokenSubject.next(accessToken);
};

export const accountSubject = new BehaviorSubject(null);
export const useAccount = hookFromSubject(accountSubject);
export const setAccount = (account) => accountSubject.next(account);

export const getAuthorizationHeader = () => "Bearer " + accessTokenSubject.getValue();

export const msalInstanceSubject = new BehaviorSubject(null);
export const setMsalInstance = (instance) => msalInstanceSubject.next(instance);
export const handleUnauthorized = (response) => {
  if (response.status === 401) {
    msalInstanceSubject.getValue()?.loginRedirect(loginRequest);
  }
};

export const userSubject = new BehaviorSubject(null);
export const useUser = hookFromSubject(userSubject);
export const setUser = (user) => {
  const permissions = user?.permissions || [];
  const richTextConfiguration = user?.richTextConfiguration || {};
  userSubject.next(
    user
      ? {
          ...user,
          permissions,
        }
      : null
  );
  setPermissions(permissions);
  setRichTextConfiguration(richTextConfiguration);
};

export const resetUserTenant = () => {
  userSubject.next({ ...userSubject.getValue(), tenant: null, permissions: null });
};
export const getUserTenantType = () => userSubject.getValue()?.tenant?.type;

export const getUserTenantName = () => userSubject.getValue()?.tenant?.name;

const logout = (callback) => {
  resetAuthCookie();
  fetch("/api/logout", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthorizationHeader(),
    },
  })
    .then()
    .catch(console.error)
    .finally(callback);
};

export const handleLogout = (instance) => {
  const callback = () => instance.logoutRedirect();
  logout(callback);
  setTimeout(callback, 500);
};

export const permissionsSubject = new BehaviorSubject([]);
export const usePermissions = hookFromSubject(permissionsSubject);
export const setPermissions = (permissions) => permissionsSubject.next(permissions);

export const richTextConfigurationSubject = new BehaviorSubject({});
export const useRichTextConfiguration = hookFromSubject(richTextConfigurationSubject);
export const setRichTextConfiguration = (richTextConfiguration) =>
  richTextConfigurationSubject.next(richTextConfiguration);

export const isSuperAdmin = () => userSubject.getValue()?.tenant?.name === SYSTEM_TENANT_NAME;
