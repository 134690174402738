import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { DEFAULT_PAGE_SIZE } from "../constants/main";
import { useEvent } from "./utils/useEvent";

export const createTablePageLogic = (defaults = {}) => {
  const getPageDefaultState = () => ({
    searchValue: "",
    filterValue: null,
    sortValue: { field: null },
    filterValues: {},
    kendoFilters: {},
    total: 0,
    page: 0,
    rowsPerPage: DEFAULT_PAGE_SIZE,
    ...defaults,
    resetState: new Date().getTime(),
    actionsPopoverProps: {},
  });

  const pageStateSubject = new BehaviorSubject(getPageDefaultState());
  const usePageState = hookFromSubject(pageStateSubject);

  const usePageLogic = () => {
    const state = usePageState();

    const updateState = useEvent((changes) => pageStateSubject.next({ ...state, ...changes }));
    const setRowsPerPage = (rowsPerPage) => updateState({ rowsPerPage, page: 0 });
    const setPage = (page) => updateState({ page });
    const setTotal = (total) => updateState({ total });
    const setSearchValue = (searchValue) => updateState({ searchValue, page: 0 });
    const setFilterValue = (filterValue) => updateState({ filterValue, page: 0 });
    const setFilterValues = (filterValues) => updateState({ filterValues, page: 0 });
    const setKendoFilters = (kendoFilters) => updateState({ kendoFilters, page: 0 });
    const setSortValue = (sortValue) => updateState({ sortValue });
    const onSort = (field) =>
      setSortValue({
        field: field !== state.sortValue.field || !state.sortValue.desc ? field : null,
        desc: state.sortValue.field === field ? !state.sortValue.desc : false,
      });
    const resetSort = () => setSortValue({ sortValue: getPageDefaultState().sortValue });

    const setActionsPopoverProps = (actionsPopoverProps) => updateState({ actionsPopoverProps });

    return {
      ...state,
      setSearchValue,
      setFilterValue,
      setSortValue,
      onSort,
      resetSort,
      setFilterValues,
      setKendoFilters,
      setTotal,
      setPage,
      setRowsPerPage,
      updateState,
      setActionsPopoverProps,
    };
  };

  const resetPageState = () => pageStateSubject.next(getPageDefaultState());

  return [usePageLogic, resetPageState];
};
