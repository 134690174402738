import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { PromiseSubjectState } from "react-rxjs-easy";
import { indicatorActionsListSubject } from "../../../hooks/action";
import { showDialog } from "../../../hooks/dialog";
import { forEachRecursive } from "../../../utils";
import { AddActionsDialog } from "../../AddActionsDialog/AddActionsDialog";
import { CollapsiblePanel } from "../../CollapsiblePanel/CollapsiblePanel";
import { ActionsBlock } from "../../RatingSystem/IndicatorElementDockBlocks/ActionsBlock";
import { CurrentBlock } from "./CurrentBlock";
import { PotentialBlock } from "./PotentialBlock";

export const KPIElementDock = ({ rows, data, readOnly, projectId, onChange, kpiRatingSystemId, permissions }) => {
  const { t } = useTranslation();
  const {
    kpi: { name, unit },
  } = data || { kpi: {} };

  const onReasonChange = ({ UID, fields, links, files }) => {
    const updated = [...rows];
    const { kpi } = fields || {};
    if (kpi) {
      forEachRecursive(updated, (node) => {
        if (node.kpi) {
          if (UID === node.UID || (node.kpi.name === kpi.name && node.kpi.unit === kpi.unit)) {
            node.kpi.currentValue = kpi.currentValue;
            node.kpi.estimation = kpi.estimation;
            node.currentValue = kpi.currentValue;
            node.estimation = kpi.estimation;
          }
        }
      });
    }
    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        const { reason } = fields || {};
        node.reason = reason;
        node.evaluationLinks = links || [];
        node.evaluationFiles = files || [];
        return true;
      }
    });
    onChange(updated);
  };

  const onPotentialChange = ({ UID, fields }) => {
    const updated = [...rows];
    const { kpi } = fields || {};
    if (kpi) {
      forEachRecursive(updated, (node) => {
        if (node.kpi) {
          if (UID === node.UID || (node.kpi.name === kpi.name && node.kpi.unit === kpi.unit)) {
            node.kpi.potentialValue = kpi.potentialValue;
            node.potentialValue = kpi.potentialValue;
          }
        }
      });
    }
    onChange(updated);
  };

  const onActionsChange = ({ UID, actions = [] }) => {
    const updated = [...rows];
    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        node.actions = actions;
        return true;
      }
    });
    onChange(updated);
    indicatorActionsListSubject.next(new PromiseSubjectState(actions));
  };

  const showAddActionsDialog = (row, passThrough) => {
    const { readOnly: readOnlyInner } = passThrough || {};
    showDialog({
      className: "xlarge",
      closeOnClickOutside: !!readOnlyInner,
      getContent: (onClose) => (
        <AddActionsDialog
          title={`${row.kpi.name} (${row.kpi.unit})`}
          row={row}
          onClose={onClose}
          readOnly={readOnlyInner}
          onChange={onActionsChange}
          grouping={rows}
          changeGrouping={onChange}
        />
      ),
    });
  };

  return (
    <div className="indicator-element-dock">
      <div className="data-block white-block">
        <h3>
          {name} ({unit})
        </h3>
      </div>

      <div className="data-block current">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.currentESG")}</h3>}>
          <CurrentBlock
            row={data}
            readOnly={readOnly}
            onChange={onReasonChange}
            projectId={projectId}
            kpiRatingSystemId={kpiRatingSystemId}
            permissions={permissions}
          />
        </CollapsiblePanel>
      </div>

      <div className="data-block potential">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.potential")}</h3>}>
          <PotentialBlock row={data} readOnly={readOnly} onChange={onPotentialChange} />
        </CollapsiblePanel>
      </div>

      <div className="data-block">
        <CollapsiblePanel
          header={
            <h3>
              {t("ratingSystem.columnGroup.actions")}
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  showAddActionsDialog(data, { readOnly });
                }}
                size="small"
              >
                <DescriptionOutlinedIcon />
              </IconButton>
            </h3>
          }
        >
          <ActionsBlock row={data} readOnly={readOnly} onChange={onActionsChange} />
        </CollapsiblePanel>
      </div>
    </div>
  );
};
