import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import "./StatusBadge.scss";

export const StatusBadge = ({ className, children, translate = false }) => {
  const { t } = useTranslation();
  return <div className={"status-badge " + (className || "")}>{translate ? t(children) : children}</div>;
};

StatusBadge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
  translate: PropTypes.bool,
};
