import CloseIcon from "@mui/icons-material/Close";
import { DialogActions, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SUSTAINABILITY_SYSTEM } from "../../../constants/main";
import { TENANT_TYPE_PAST } from "../../../constants/tenant";
import { addBreakLines, adjustRGBFormat, removeExtraBreakLines, replaceImageData } from "../../../helpers/richtext";
import { getUserTenantType } from "../../../hooks/auth";
import { resetImageSourceMap } from "../../../hooks/richtext";
import { TextImageContainer } from "../../../models/TextImageContainer";
import { resetSelectedImageValues } from "../../RichText/plugins/ImageHandlingPlugin";
import "./AdditionalFieldsDialog.scss";
import { AdditionalFieldsTabContent } from "./AdditionalFieldsTabContent";

export const ADDITIONAL_FIELDS_DIALOG_ID = "additional-fields-dialog";

export const AdditionalFieldsDialog = ({ open, onClose, systemId, data, onChange, readOnly, systemType }) => {
  const { t } = useTranslation();
  const tenantType = getUserTenantType();

  useEffect(() => {
    if (open) {
      resetImageSourceMap();
    }
  }, [open]);

  const [activeTab, setActiveTab] = useState(0);
  const values = {
    methodologyChapter: new TextImageContainer(),
    projectDescriptionChapter: new TextImageContainer(),
    statementForOverallRating: new TextImageContainer(),
  };

  const [originalValues, setOriginalValues] = useState({
    methodologyChapter: new TextImageContainer(),
    projectDescriptionChapter: new TextImageContainer(),
    statementForOverallRating: new TextImageContainer(),
  });

  const [richTextDescriptionChapter, setRichTextDescriptionChapter] = useState(null);
  const [richTextMethodologyChapter, setRichTextMethodologyChapter] = useState(null);
  const [richTextStatementForOverallRating, setRichTextStatementForOverallRating] = useState(null);

  const [closeDisabled, setCloseDisabled] = useState(false);

  useEffect(() => {
    if (data) {
      setOriginalValues(data);
    }
  }, [data]);

  const save = () => {
    let result = { ...values };
    if (richTextDescriptionChapter) {
      const { event, imageSources } = richTextDescriptionChapter;
      let html = event.html;

      html = replaceImageData(html, imageSources);
      html = adjustRGBFormat(html);
      html = addBreakLines(html);
      html = removeExtraBreakLines(html);

      result.projectDescriptionChapter.text = html;
    }

    if (richTextMethodologyChapter) {
      const { event, imageSources } = richTextMethodologyChapter;
      let html = event.html;

      html = replaceImageData(html, imageSources);
      html = adjustRGBFormat(html);
      html = addBreakLines(html);
      html = removeExtraBreakLines(html);

      result.methodologyChapter.text = html;
    }

    if (richTextStatementForOverallRating) {
      const { event, imageSources } = richTextStatementForOverallRating;
      let html = event.html;

      html = replaceImageData(html, imageSources);
      html = adjustRGBFormat(html);
      html = addBreakLines(html);
      html = removeExtraBreakLines(html);

      result.statementForOverallRating.text = html;
    }

    onChange(result);
    onClose();
  };

  const onDialogClose = (event, reason) => {
    if (!closeDisabled) {
      onClose(event, reason);
    }
  };

  return (
    <Dialog
      id={ADDITIONAL_FIELDS_DIALOG_ID}
      onClose={onDialogClose}
      open={open}
      maxWidth="md"
      fullWidth={true}
      disableEnforceFocus={true}
    >
      <div className="rating-system-additional-fields-dialog">
        <DialogTitle>
          {tenantType === TENANT_TYPE_PAST
            ? t("ratingSystem.additionalFields.header")
            : t("ratingSystem.introductionFields.header")}
          <IconButton className="close-button" size="small" onClick={onClose} disabled={closeDisabled}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {tenantType === TENANT_TYPE_PAST && systemType !== SUSTAINABILITY_SYSTEM && (
            <>
              <Tabs value={activeTab} onChange={(event, index) => setActiveTab(index)}>
                <Tab
                  label={t("ratingSystem.additionalFields.methodology")}
                  onClick={() => resetSelectedImageValues()}
                />
                <Tab
                  label={t("ratingSystem.additionalFields.projectDescription")}
                  onClick={() => resetSelectedImageValues()}
                />
                <Tab
                  label={t("ratingSystem.additionalFields.statementForOverallRating")}
                  onClick={() => resetSelectedImageValues()}
                />
              </Tabs>
              <div hidden={activeTab !== 0}>
                <AdditionalFieldsTabContent
                  readOnly={readOnly}
                  systemId={systemId}
                  systemType={systemType}
                  values={originalValues.methodologyChapter}
                  onChange={(event) => setRichTextMethodologyChapter(event)}
                  onLoading={(loadingStatus) => setCloseDisabled(loadingStatus)}
                />
              </div>
              <div hidden={activeTab !== 1}>
                <AdditionalFieldsTabContent
                  readOnly={readOnly}
                  systemId={systemId}
                  systemType={systemType}
                  values={originalValues.projectDescriptionChapter}
                  onChange={(event) => setRichTextDescriptionChapter(event)}
                  onLoading={(loadingStatus) => setCloseDisabled(loadingStatus)}
                />
              </div>
              <div hidden={activeTab !== 2}>
                <AdditionalFieldsTabContent
                  readOnly={readOnly}
                  systemId={systemId}
                  systemType={systemType}
                  values={originalValues.statementForOverallRating}
                  onChange={(event) => setRichTextStatementForOverallRating(event)}
                  onLoading={(loadingStatus) => setCloseDisabled(loadingStatus)}
                />
              </div>
            </>
          )}
          {tenantType !== TENANT_TYPE_PAST && (
            <AdditionalFieldsTabContent
              readOnly={readOnly}
              systemId={systemId}
              systemType={systemType}
              values={originalValues.projectDescriptionChapter}
              onChange={(event) => setRichTextDescriptionChapter(event)}
              onLoading={(loadingStatus) => setCloseDisabled(loadingStatus)}
            />
          )}
          {tenantType === TENANT_TYPE_PAST && systemType === SUSTAINABILITY_SYSTEM && (
            <AdditionalFieldsTabContent
              readOnly={readOnly}
              systemId={systemId}
              systemType={systemType}
              values={originalValues.methodologyChapter}
              onChange={(event) => setRichTextMethodologyChapter(event)}
              onLoading={(loadingStatus) => setCloseDisabled(loadingStatus)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>{t(readOnly ? "main.close" : "main.cancel")}</Button>
          {!readOnly && (
            <Button color="primary" onClick={save}>
              {t("main.save")}
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

AdditionalFieldsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  systemId: PropTypes.number.isRequired,
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  systemType: PropTypes.string,
};
