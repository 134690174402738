import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import IconButton from "@mui/material/IconButton";
import React from "react";
import "./SearchBadge.scss";

export const SearchBadge = ({ field, value, onClose }) => {
  let textValue =
    typeof value === "string"
      ? value
      : value
          .map((x) => x.text)
          .reduce((result, currentValue) => `${result}, ${currentValue}`, "")
          .substring(2);

  return (
    <div className="search-badge">
      <SearchOutlinedIcon className="search-icon" />
      {field}: {textValue}
      <IconButton onClick={onClose} size="small">
        <CloseOutlinedIcon />
      </IconButton>
    </div>
  );
};
