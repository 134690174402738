import { Button } from "@progress/kendo-react-buttons";
import { EditorToolsSettings } from "@progress/kendo-react-editor";
import * as React from "react";
import { InsertImageDialog } from "./InsertImageDialog";

const imageSettings = EditorToolsSettings.image;

export const InsertImageTool = ({ view, uploadUrl, getResultUrl, richTextConfiguration, uid }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const toggleDialog = () => setOpenDialog(!openDialog);

  const state = view && view.state;
  const nodeType = state ? state.schema.nodes[imageSettings.node] : undefined;

  return (
    <>
      <Button
        onClick={toggleDialog}
        disabled={!nodeType || !state}
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title="Insert Image"
        {...imageSettings.props}
      />
      {openDialog && view && (
        <InsertImageDialog
          key="insertImageDialog"
          view={view}
          onClose={toggleDialog}
          imageNode={imageSettings.node}
          uploadUrl={uploadUrl}
          getResultUrl={getResultUrl}
          richTextConfiguration={richTextConfiguration}
          uid={uid}
        />
      )}
    </>
  );
};
