import React from "react";
import "./GroupingElementBeforeBarChart.scss";
import { useTranslation } from "react-i18next";
import { setMaxPercentsVisible } from "../../../hooks/dashboard";
import { getUserTenantType } from "../../../hooks/auth";
import { TENANT_TYPE_PAST } from "../../../constants/tenant";

export const GroupingElementBeforeBarChart = () => {
  const { t } = useTranslation();
  const tenantType = getUserTenantType();

  return <div className="grouping-element-before-bar-chart">
    <div className="vertical-block">
      <div className="title">
        <div className="hit-box"
             onPointerEnter={() => setMaxPercentsVisible(true)}
             onPointerLeave={() => setMaxPercentsVisible(false)}
        />
        <label>
          {tenantType === TENANT_TYPE_PAST ? t('dashboard.details.beforeChartPAST') : t('dashboard.details.beforeChartESG')}
        </label>
      </div>
    </div>
  </div>
};
