import TableRow from "@mui/material/TableRow";
import React from "react";
import { v4 as getUUID } from "uuid";
import { useIsCurrentDragRow } from "../../hooks/advancedTable";
import { AdvancedTableBodyCell } from "./AdvancedTableBodyCell";

export const AdvancedTableBodyRow = React.memo((props) => {
  const {
    columnsProcessed,
    getColumnPosition,
    row,
    readOnly,
    showHoverControls,
    rowIndex,
    onRowSelect,
    rowClassName,
    groupingField,
    changedRow,
    changedFieldsByUID,
    passThrough,
    columnGroupStates,
    disableRowDrag,
    stickyAmount,
    isSelected,
    rowGrabStart,
    rowCollapsed,
    toggleRowCollapsed,
    submitted,
    updateValue,
    errorRows,
  } = props;

  useIsCurrentDragRow(row.UID);

  return (
    <TableRow
      id={row.UID}
      onMouseOver={!readOnly ? (event) => showHoverControls(event, rowIndex, row.UID) : null}
      onClick={onRowSelect ? () => onRowSelect(row) : null}
      className={rowClassName(row, rowIndex) + (isSelected ? " selected" : "")}
    >
      {(columnsProcessed || []).map((column, index) => {
        const columnStyle = { paddingLeft: column.groupingColumn ? 20 + (row.level || 0) * 48 + "px" : 0 };
        const collapsible = column.groupingColumn && !!(row[groupingField] || []).length;
        const fieldName = column.getField ? column.getField(row) : column.field;
        let className =
          ("function" === typeof column.className
            ? column.className(row, index, passThrough)
            : column.className || "") + (changedRow || (changedFieldsByUID || {})[fieldName] ? " changed" : "");
        const { columnGroup, firstInGroup, lastInGroup } = columnGroupStates[index];
        if (column.columnGroup) {
          className += " " + (columnGroup.className || "");
          if (firstInGroup) {
            className += " first-in-group";
          }
          if (lastInGroup) {
            className += " last-in-group";
          }
        }

        className += index < stickyAmount ? " sticky" : "";

        const propsAdvanceTableBodyCell = {
          row,
          column,
          index,
          passThrough,
          readOnly,
          disableRowDrag,
          rowGrabStart,
          rowCollapsed,
          toggleRowCollapsed,
          submitted,
          updateValue,
          className,
          columnStyle,
          collapsible,
        };

        return (
          <AdvancedTableBodyCell
            key={getUUID()}
            {...propsAdvanceTableBodyCell}
            getColumnPosition={getColumnPosition}
            errorMark={errorRows[row.UID]}
            stickyAmount={stickyAmount}
          />
        );
      })}
    </TableRow>
  );
});
