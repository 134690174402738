import TextField from "@mui/material/TextField";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormController } from "../../FormController/FormController";

export const ProjectNameField = ({ control, disabled }) => {
  const { t } = useTranslation();

  return (
    <FormController
      control={control}
      name="name"
      required
      label={t("project.name")}
      render={({ field, fieldState, label }) => (
        <TextField
          error={fieldState.invalid}
          fullWidth
          inputProps={{ maxLength: 50 }}
          label={label + " *"}
          {...field}
          disabled={disabled}
        />
      )}
    />
  );
};
