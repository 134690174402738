import { FilterTh, SPTBackendTextFilter, TranslateTh } from "../../components/TreeList/treeListComponents";
import {
  ActionsColumn,
  DateColumn,
  NameColumn,
  RatingSystemNameColumn,
  RatingSystemPASTScoreColumn,
  RatingSystemScoreColumn,
  RatingSystemSuperScoreColumn,
  StatusColumn,
} from "./Projects.components";

const commonStartingColumns = [
  {
    field: "name",
    title: "projects.name",
    expandable: true,
    cell: NameColumn,
    headerCell: TranslateTh,
    filterCell: SPTBackendTextFilter,
    width: "12.5%",
  },
  {
    field: "clientName",
    title: "projects.clientName",
    headerCell: TranslateTh,
    filterCell: SPTBackendTextFilter,
    width: "12.5%",
  },
  {
    field: "systemSource",
    title: "projects.ratingSystem",
    cell: RatingSystemNameColumn,
    headerCell: TranslateTh,
    filterCell: SPTBackendTextFilter,
    width: "25%",
    sortable: false,
  },
];

const commonEndingColumns = [
  {
    field: "ratingStatus",
    title: "projects.status",
    cell: StatusColumn,
    headerCell: TranslateTh,
    filterCell: FilterTh,
    width: "8%",
    sortable: false,
  },
  {
    field: "modifiedDateTime",
    title: "projects.lastUpdate",
    headerCell: TranslateTh,
    filterCell: FilterTh,
    cell: DateColumn,
    width: "8%",
    sortable: false,
  },
  {
    className: "action-cell",
    title: "main.actions",
    cell: ActionsColumn,
    headerCell: TranslateTh,
    filterCell: FilterTh,
    width: "8%",
    sortable: false,
  },
];

export const columnsESG = [
  ...commonStartingColumns,
  {
    field: "score",
    title: "projects.score",
    cell: RatingSystemScoreColumn,
    headerCell: TranslateTh,
    filterCell: FilterTh,
    width: "8%",
    sortable: false,
  },
  {
    field: "superScore",
    title: "projects.superScore",
    cell: RatingSystemSuperScoreColumn,
    headerCell: TranslateTh,
    filterCell: FilterTh,
    width: "8%",
    sortable: false,
  },
  ...commonEndingColumns,
];

export const columnsPAST = [
  ...commonStartingColumns,
  {
    field: "score",
    title: "projects.pastScore",
    headerCell: TranslateTh,
    cell: RatingSystemPASTScoreColumn,
    filterCell: FilterTh,
    width: "8%",
    sortable: false,
  },
  ...commonEndingColumns,
];
