import { BehaviorSubject } from "rxjs";
import { hookFromSubject } from "react-rxjs-easy";
import { uploadFile } from "./fileUpload";

export const uploadIndicatorFile = (file) => uploadFile(file, '/api/sustainability/indicator/file');

export const indicatorFilesSubject = new BehaviorSubject([]);
export const useIndicatorFiles = hookFromSubject(indicatorFilesSubject);
export const setIndicatorFiles = (files = []) => indicatorFilesSubject.next(files);
export const addIndicatorFile = file => indicatorFilesSubject.next([...indicatorFilesSubject.getValue(), file]);
export const removeIndicatorFile = fileIndex => indicatorFilesSubject.next(indicatorFilesSubject.getValue().filter((item, index) => index !== fileIndex));
