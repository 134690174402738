import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useTranslation } from "react-i18next";
import "./InfoDialog.scss";

export const InfoDialog = ({ open, onClose, titleText, bodyText, showOkButton }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} className="info-dialog small">
      <DialogTitle className={titleText ? "" : "no-title"}>
        {titleText}{" "}
        <IconButton onClick={onClose} size="small">
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <p className="dialog-content">{bodyText}</p>
      {!!showOkButton && (
        <div className="text-center">
          <Button onClick={onClose}>{t("main.ok")}</Button>
        </div>
      )}
    </Dialog>
  );
};
