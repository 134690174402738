import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { deDE, enUS } from "@mui/material/locale";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { connectReactHooks } from "react-rxjs-easy";
import App from "./App";
import { getAuthVariables, msalConfig } from "./authConfig";
import { LANGUAGE_EN } from "./constants/language";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";

connectReactHooks(useEffect, useState);

const consoleError = console.error;
console.error = function (error) {
  if (error !== "Warning: Failed %s type: %s%s") {
    consoleError(...arguments);
  }
};

getAuthVariables().then(() => {
  const config = msalConfig();
  const msalInstance = new PublicClientApplication(config);

  const theme = createTheme(
    {
      components: {
        MuiTextField: {
          defaultProps: {
            variant: "standard",
          },
        },
        MuiSelect: {
          defaultProps: {
            variant: "standard",
          },
        },
      },
    },
    i18n.language === LANGUAGE_EN ? enUS : deDE
  );
  const container = document.getElementById("root");
  const root = createRoot(container);

  root.render(
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
