import { mapRecursive } from "../utils";
import { v4 as getUUID } from "uuid";
import { GROUPING_TYPE_KPI } from "../constants/kpiSystem";

export const mapKPISystemGroupings = groupingElements => mapRecursive(groupingElements || [], (node, children) => {
  return {
    UID: String(node.id || getUUID()),
    ...node,
    children: [...(node.kpiElements || []).map(item => {
      const processed = {
        UID: item.id ? 'i_' + item.id : getUUID(),
        ...item,
        type: GROUPING_TYPE_KPI
      };
      return processed;
    }), ...children]
  };
}, 'groupingElements');
