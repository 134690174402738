export const MAX_FILE_SIZE_MB = 200;

export const ALLOWED_IMAGE_EXTENSION = [".jpg", ".png", ".jpeg"];

export const MEGA = 1000000;

export const MB = "MB";

export const FILE_UPLOAD_RESTRICTIONS = { 
    allowedExtensions: [ 'png', 'jpg', 'jpeg', 'rtf', 'pdf', 'docx', 'doc', 'xlsx', 'xls', 'xlsm', 'pptx', 'ppt' ],
    maxFileSize: 100 * MEGA
};

export const IMAGE_UPLOAD_RESTRICTIONS = { 
    allowedExtensions: [ 'png', 'jpg', 'jpeg' ], 
    maxFileSize: 10 * MEGA
};
