import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { getAuthorizationHeader, msalInstanceSubject } from "./auth";
import { loginRequest } from "../authConfig";

export const uploadingFilesSubject = new BehaviorSubject([]);
export const useUploadingFiles = hookFromSubject(uploadingFilesSubject);

export const uploadFile = (file, url, key) => {
  const authHeader = getAuthorizationHeader();
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    const request = new XMLHttpRequest();

    const progressHandler = event => {
      uploadingFilesSubject.next([...uploadingFilesSubject.getValue().map(item => {
        if (item.request !== request) {
          return item;
        } else {
          return { ...item, progress: event.loaded / event.total };
        }
      })]);
    };

    const completeHandler = event => {
      uploadingFilesSubject.next([...uploadingFilesSubject.getValue().filter(item => {
        if (item.request !== request) {
          return true;
        } else {
          const responseText = event.target.responseText;
          if (event.target.status === 200) {
            try {
              let data = JSON.parse(responseText);
              resolve(data);
            } catch (error) {
              reject(error);
            }
          } else if (event.target.status === 401) {
            msalInstanceSubject.getValue()?.loginRedirect(loginRequest());
          } else {
            reject(request);
          }
          return false;
        }
      })]);
    };

    const errorHandler = error => {
      uploadingFilesSubject.next([...uploadingFilesSubject.getValue().filter(item => item.request !== request)]);
      reject(error);
    };

    const abortHandler = event => {
      uploadingFilesSubject.next([...uploadingFilesSubject.getValue().filter(item => item.request !== request)]);
      reject(event);
    };

    request.upload.addEventListener("progress", progressHandler, false);
    request.addEventListener("load", completeHandler, false);
    request.addEventListener("error", errorHandler, false);
    request.addEventListener("abort", abortHandler, false);

    request.open('POST', url);
    request.setRequestHeader('Authorization', authHeader);

    uploadingFilesSubject.next([...uploadingFilesSubject.getValue(), {
      file,
      url,
      key,
      request,
      progress: 0
    }]);

    try {
      request.send(formData);
    } catch (error) {
      uploadingFilesSubject.next([...uploadingFilesSubject.getValue().filter(item => item.request !== request)]);
      reject(error);
    }
  });
};

export const cancelUploadingFile = (file) => {
  const uploadState = uploadingFilesSubject.getValue().find(item => item.file === file);
  if (uploadState) {
    const { request } = uploadState;
    request.abort();
    uploadingFilesSubject.next(uploadingFilesSubject.getValue().filter(item => item !== uploadState));
  }
};

export const resetUploadingFiles = () => {
  uploadingFilesSubject.getValue().forEach(item => {
    const { request } = item;
    request.abort();
  });
  uploadingFilesSubject.next([]);
};


