import { DatePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, load, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import weekData from 'cldr-core/supplemental/weekData.json';
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from "cldr-dates-full/main/de/dateFields.json";
import deNumbers from "cldr-numbers-full/main/de/numbers.json";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_SERVER } from "../../constants/main";
import kendoDeMessages from '../../locales/kendo-de.json';
import { FormController } from "../FormController/FormController";
import "./DateFieldController.scss";

load(
  deNumbers,
  deDateFields,
  deCaGregorian,
  weekData
)
loadMessages(kendoDeMessages, 'de');

export const DateFieldController = ({ control, name, required, label, readOnly }) => {
  const { i18n } = useTranslation();

  return (
    <LocalizationProvider language={i18n.language}>
    <IntlProvider locale={i18n.language}>
      <FormController
        control={control}
        name={name}
        required={required}
        label={label}
        render={({ field, fieldState, label: labelInner }) => (
          <DatePicker
            className="datepickerController"
            label={labelInner + (required ? " *" : "")}
            placeholder=""
            size="small"
            fillMode="flat"
            format={"dd.MM.yyyy"}
            {...field}
            aria-readonly={readOnly}
            valid={!fieldState.invalid}
            value={field.value ? moment(field.value, DATE_FORMAT_SERVER).toDate() : null}
            onChange={(value) => {
              field.onChange(value ? moment(value.value).format(DATE_FORMAT_SERVER) : value.value);
            }}
          />
        )}
      />
    </IntlProvider>
    </LocalizationProvider>
  );
};
