import TextField from "@mui/material/TextField";
import React from "react";
import { useTranslation } from "react-i18next";
import { INTERNAL_EXTERNAL_ID_MAX_LENGTH } from "../../../constants/project";
import { FormController } from "../../FormController/FormController";

export const ProjectIdField = ({ control, disabled, name, required, maxLength }) => {
  const { t } = useTranslation();

  return (
    <FormController
      control={control}
      name={name}
      required={required}
      label={t("project." + name)}
      render={({ field, fieldState, label }) => (
        <TextField
          inputProps={{ maxLength: maxLength || INTERNAL_EXTERNAL_ID_MAX_LENGTH }}
          type="text"
          error={fieldState.invalid}
          fullWidth
          disabled={disabled}
          label={required ? label + " *" : label}
          {...field}
        />
      )}
    />
  );
};
