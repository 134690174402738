import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./AttributeStatusFilter.scss";
import { ATTRIBUTE_STATUS_ACTIVE, ATTRIBUTE_STATUS_INACTIVE } from "../../../constants/projectAttribute";
import { FilterBlocks } from "../../FilterBlocks/FilterBlocks";

const getCountsByStatuses = (data = []) => {
  const res = {};
  (data || []).forEach(item => {
    if (!res[item.status]) {
      res[item.status] = 0;
    }
    res[item.status]++;
  });
  return res;
};

export const AttributeStatusFilter = ({ value, onChange, disabled, values }) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState(getCountsByStatuses(values));

  useEffect(() => {
    setStatus(getCountsByStatuses(values));
  }, [values]);

  const filterOptions = [
    {
      name: ATTRIBUTE_STATUS_ACTIVE,
      title: t('generalAttributes.status.' + ATTRIBUTE_STATUS_ACTIVE),
      amount: status[ATTRIBUTE_STATUS_ACTIVE] || 0
    },
    {
      name: ATTRIBUTE_STATUS_INACTIVE,
      title: t('generalAttributes.status.' + ATTRIBUTE_STATUS_INACTIVE),
      amount: status[ATTRIBUTE_STATUS_INACTIVE] || 0
    }
  ];

  return (
    <FilterBlocks filterOptions={filterOptions} value={value} onChange={onChange} allowDeselect showReset={!!value}
                  disabled={disabled}/>
  );
};
