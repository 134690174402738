import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import afterFrame from "afterframe";
import jp from "jsonpath";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { v4 as getUUID } from "uuid";
import { ADVANCED_TABLE_ID } from "../../components/AdvancedTable/AdvancedTable";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { ContentBlock } from "../../components/ContentBlock/ContentBlock";
import { DashboardLink } from "../../components/Dashboard/DashboardLink/DashboardLink";
import { SystemScoreProgressESG } from "../../components/Dashboard/SystemScore/SystemScoreProgress/SystemScoreProgressESG";
import { SystemScoreTableESG } from "../../components/Dashboard/SystemScore/SystemScoreTable/SystemScoreTableESG";
import { DatePickerDialog } from "../../components/DatePickerDialog/DatePickerDialog";
import { Dock } from "../../components/Dock/Dock";
import { InfoDialog } from "../../components/InfoDialog/InfoDialog";
import { KPIProjectFieldsDecisions } from "../../components/KPIProjectFieldsDecisions/KPIProjectFieldsDecisions";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { AdditionalFieldsDialog } from "../../components/RatingSystem/AdditionalFieldsDialog/AdditionalFieldsDialog";
import { GroupingElementDockESG } from "../../components/RatingSystem/GroupingElementDock/GroupingElementDockESG";
import { IndicatorElementDockESG } from "../../components/RatingSystem/IndicatorElementDockESG/IndicatorElementDockESG";
import { RatingSystemGoals } from "../../components/RatingSystem/RatingSystemGoals/RatingSystemGoals";
import { RatingSystemGroupingESG } from "../../components/RatingSystem/RatingSystemGrouping/RatingSystemGroupingESG";
import { StatusBadge } from "../../components/StatusBadge/StatusBadge";
import { TwoOptionsToggle } from "../../components/TwoOptionsToggle/TwoOptionsToggle";
import { VersionsDialog } from "../../components/Versions/VersionsDialog/VersionsDialog";
import { DATE_FORMAT_SERVER } from "../../constants/main";
import { PROJECT_PERMISSION } from "../../constants/project";
import {
  STATUS_CLOSED,
  STATUS_NOT_APPLICABLE,
  STATUS_REPORTING,
  STATUS_SCREENING,
  STATUS_VERIFICATION,
} from "../../constants/ratingSystem";
import {
  AWARD_CALCULATION_TYPE_PERCENT,
  GROUPING_TYPE_INDICATOR,
  STATUS_IN_PROGRESS,
  STATUS_LOCKED,
  SYSTEM_LOGIC_DGNB,
} from "../../constants/sustainabilitySystem";
import { mergeRatingSystem } from "../../helpers/merge/ratingSystem";
import {
  calculateOverallSuperScore,
  calculateOverallSystemScore,
  calculatePotentialOverallSuperScore,
  calculatePotentialOverallSystemScore,
  canApplyChanges,
  canEdit,
  canViewProject,
  checkKOFailed,
  checkThresholdFailed,
  getAward,
  getWaitingLinkedKPIs,
  groupingElementsFromChildren,
  mapRatingSystemGroupings,
  ratingSystemFromEvaluate,
  recalculateGroupingScore,
  recalculatePotentialGroupingScore,
} from "../../helpers/ratingSystem";
import {
  processAllIndicatorsWeighting,
  processMaxSystemScore,
  processMaxSystemScoreProportions,
} from "../../helpers/sustainabilitySystem";
import { getActionSuggestion, setActionsProjectId } from "../../hooks/action";
import { useTableScrollX, useTableScrollY } from "../../hooks/advancedTable";
import { showDialog, showMuiDialog } from "../../hooks/dialog";
import { useUploadingFiles } from "../../hooks/fileUpload";
import { getProjectGroup, useProjectGroupResponse } from "../../hooks/projectGroup";
import { uploadRatingReasonFileKey } from "../../hooks/ratingIndicatorFiles";
import {
  acceptRatingSystemChanges,
  declineRatingSystemChanges,
  evaluateRatingSystem,
  getRatingSystem,
  getRatingSystemRevisionById,
  getRatingSystemRevisions,
  indicatorsWithActionsByIdSubject,
  resetRatingSystemPotentialsChanged,
  selectedRowSubject,
  setRatingSystemGrouping,
  setSelectedRow,
  useRatingSystemAcceptChangesResponse,
  useRatingSystemDeclineChangesResponse,
  useRatingSystemEvaluateResponse,
  useRatingSystemGrouping,
  useRatingSystemResponse,
  useRatingSystemRevisionByIdResponse,
  useRatingSystemRevisionsResponse,
  useSelectedRow,
} from "../../hooks/ratingSystem";
import { showError, showSuccess, showWarning } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { useProjectPermissions } from "../../hooks/utils/usePagePermissions";
import { TextImageContainer } from "../../models/TextImageContainer";
import { CloseSVG, DownloadSVG, ManageHistorySVG, SaveSVG } from "../../svg";
import {
  createChapterFileArray,
  deepCopy,
  findRecursive,
  forEachRecursive,
  getIndicatorsWithActionsById,
  isExcludedElement,
  mapRecursive,
  nullIfDash,
  processMessage,
  scrollElementByIdTo,
} from "../../utils";
import { validateRatingSystemGrouping } from "../../validation/ratingSystem";
import { calculateGroupingMaxScore, sumGroupingWeightedMaxSystemScore } from "../../validation/sustainabilitySystem";
import "./RatingSystem.scss";

export const RatingSystemESG = ({ ratingSystemId, groupId, analyze }) => {
  console.time("Page render");
  afterFrame(() => console.timeEnd("Page render"));

  const mode = new URLSearchParams(window.location.search).get("mode");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const response = useRatingSystemResponse();
  const system = deepCopy(response.data || {});
  const permissions = system.projectPermission ?? {};

  useProjectPermissions(permissions, PROJECT_PERMISSION.ACCESS, response.loading);

  const [updateCounter, setUpdateCounter] = useState(0);
  const groupResponse = useProjectGroupResponse();
  const group = groupResponse.data || {};
  const projectId = system.projectId;
  const awards = system.awards || [];
  const [isEdit, setIsEdit] = useState(mode === "edit" && permissions.member);
  const [submitted, setSubmitted] = useState(false);
  const [koFailed, setKoFailed] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [reEvaluate, setReEvaluate] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [acceptChangesInProgress, setAcceptChangesInProgress] = useState(false);
  const [linkedKpis, setLinkedKpis] = useState([]);
  const [askLater, setAskLater] = useState(false);
  const [header, setHeader] = useState({});
  const [previousHeader, setPreviousHeader] = useState(null);
  const [ratingStatus, setRatingStatus] = useState(null);
  const [score, setScore] = useState(0);
  const [potentialScore, setPotentialScore] = useState(0);
  const canView = canViewProject(system.accessRestricted, permissions);
  const editDisabled = !isEdit || !canEdit(permissions);
  const selectedRow = useSelectedRow();
  const [allIndicatorsHandled, setAllIndicatorsHandled] = useState(false);
  const grouping = useRatingSystemGrouping();
  const [showGoals, setShowGoals] = useState(false);
  const [previousGrouping, setPreviousGrouping] = useState(null);
  const [ratingSystemGoals, setRatingSystemGoals] = useState([]);
  const [versionDialog, setVersionDialog] = useState(false);
  const [editIsDisabled, setEditIsDisabled] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [saveMerged, setSaveMerged] = useState(false);
  const versions = useRatingSystemRevisionsResponse();
  const versionById = useRatingSystemRevisionByIdResponse();
  const evaluateResponse = useRatingSystemEvaluateResponse();
  const uploadingFiles = useUploadingFiles().filter((item) => item.key === uploadRatingReasonFileKey);

  const ratingSystemAcceptChangesResponse = useRatingSystemAcceptChangesResponse();
  const ratingSystemDeclineChangesResponse = useRatingSystemDeclineChangesResponse();

  const awardCalculationType = (header || {}).awardCalculationType || AWARD_CALCULATION_TYPE_PERCENT;
  const overallMaxSystemScore = (header || {}).overallMaxSystemScore || 0;
  const superScore = calculateOverallSuperScore(header, grouping, score, system.systemLogic);
  const potentialSuperScore = calculatePotentialOverallSuperScore(header, grouping, potentialScore, system.systemLogic);
  const isPercent = awardCalculationType === AWARD_CALCULATION_TYPE_PERCENT;

  useEffect(() => {
    if (!isEdit) {
      return;
    }

    const handleTabClose = (event) => {
      event.preventDefault();

      return (event.returnValue = ""); // display message is resolved by the browser, regardless of what we put here
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [isEdit]);

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const groupingErrors = useMemo(() => validateRatingSystemGrouping(grouping), [grouping]);

  useEffect(() => {
    if (ratingSystemId) {
      if (mode === "edit" && permissions.member) {
        window.history.replaceState(null, null, window.location.pathname);
      }
      setSelectedRow(null);
      load();
      getVersions();
      resetRatingSystemPotentialsChanged();
    }
  }, [ratingSystemId]);

  useEffect(() => {
    if (!!system && isAcceptChangesRequired(system)) {
      setShowUpdateConfirmation(true);
    }
  }, [system.id]);

  useEffect(() => {
    if (projectId) {
      setActionsProjectId(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (groupId) {
      getProjectGroup(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    if (ratingSystemId && isEdit) {
      getActionSuggestion(ratingSystemId);
      setPreviousGrouping(null);
    }
  }, [ratingSystemId, isEdit]);

  useEffect(() => {
    if (reEvaluate) {
      setReEvaluate(false);
      updateGrouping(grouping, system?.systemLogic);
      onSubmit();
      if (checkScoreChanged()) {
        setShowInfo(true);
      }
    }
  }, [reEvaluate]);

  useEffect(() => {
    if (saveMerged) {
      try {
        setSaveMerged(false);
        onSubmit();
      } catch (e) {
        showError(t("error.500"));
      }
    }
  }, [saveMerged]);

  useEffect(() => {
    if (system?.groupingElements?.length) {
      setLinkedKpis(getWaitingLinkedKPIs(system.groupingElements));
    }
  }, [system?.groupingElements?.length]);

  useEffect(() => {
    if (!isEdit && !submitted) {
      const data = system || {};
      resetData(data);
    }
  }, [isEdit, submitted]);

  const mergeAndSave = async (updated) => {
    try {
      const current = await getRatingSystem(ratingSystemId);
      const updatedSystem = ratingSystemFromEvaluate(response.data, updated);
      const [merged, withConflicts] = mergeRatingSystem(response.data, current, updatedSystem);
      merged.groupingElements = groupingElementsFromChildren(merged.children);
      if (withConflicts) {
        showWarning(processMessage(t("main.savedWithConflicts"), [current?.modifier]));
      }
      resetData(merged);
      setSaveMerged(true);
    } catch (e) {
      showError(t("error.500"));
    }
  };

  const onChange = useEvent((values) => {
    updateGrouping(values, system?.systemLogic);
  });

  const checkScoreChanged = () => {
    let res = false;
    if (previousGrouping) {
      const nodesByUUID = {};
      forEachRecursive(previousGrouping, (node) => {
        nodesByUUID[node.uuid] = node;
      });
      forEachRecursive(grouping, (node) => {
        if (isExcludedElement(node)) {
          return true;
        }
        if (nodesByUUID[node.uuid] && nodesByUUID[node.uuid].maxSystemScore !== node.maxSystemScore) {
          res = true;
          return true;
        }
      });
    }
    return res;
  };

  const checkAllIndicatorsHandled = (groupingCheckAllIndicatorsHandled) => {
    let res = true;
    forEachRecursive(groupingCheckAllIndicatorsHandled, (node) => {
      if (isExcludedElement(node)) {
        return true;
      }
      if (node.type === GROUPING_TYPE_INDICATOR) {
        if ([STATUS_CLOSED, STATUS_NOT_APPLICABLE].indexOf(node.indicatorStatus) === -1) {
          res = false;
          return true;
        }
      }
    });
    return res;
  };

  const updateSelectedRow = (groupingUpdateSelectedRow) => {
    const selectedRowInner = selectedRowSubject.getValue();
    if (selectedRowInner) {
      const found = findRecursive(groupingUpdateSelectedRow, (item) => item.uuid === selectedRowInner.uuid);
      if (found) {
        setSelectedRow(found);
      }
    }
  };

  const recalculateFields = (data, systemLogic) => {
    let updated = [...data];
    updated = processMaxSystemScore(updated);
    processMaxSystemScoreProportions(updated, systemLogic);

    setHeader((prev) => ({
      ...prev,
      overallMaxSystemScore:
        systemLogic === SYSTEM_LOGIC_DGNB
          ? sumGroupingWeightedMaxSystemScore(updated)
          : calculateGroupingMaxScore(updated),
    }));

    processAllIndicatorsWeighting(updated);
    return updated;
  };

  const updateGrouping = useEvent((values, systemLogic) => {
    const groupingInner = recalculateFields(values, systemLogic);

    forEachRecursive(groupingInner, (node) => {
      node.excludedFromCalculation = isExcludedElement(node);
    });

    recalculateGroupingScore(groupingInner, systemLogic || system.systemLogic);
    recalculatePotentialGroupingScore(groupingInner, systemLogic || system.systemLogic);
    setRatingSystemGrouping(groupingInner);
    updateSelectedRow(groupingInner);

    processScores(groupingInner, systemLogic);

    setAllIndicatorsHandled(checkAllIndicatorsHandled(groupingInner));
    setKoFailed(checkKOFailed(groupingInner));
  });

  const mapActions = (actions) => {
    if (!actions) {
      return undefined;
    }
    return actions.map((item) => ({ ...item, UID: undefined, tags: undefined }));
  };

  const processGrouping = () => {
    const indicatorElements = [];
    const groupingElements = [];
    mapRecursive(grouping, (node, children) => {
      const { id } = node;
      nullIfDash(node);
      if (node.type === GROUPING_TYPE_INDICATOR) {
        const {
          reason,
          evaluationValue,
          evaluationScoringValue,
          customCurrentValue,
          customPotentialValue,
          evaluationSystemScore,
          evaluationLinks,
          evaluationFiles,
          universalScore,
          indicatorStatus,
          potentialEvaluationValue,
          potentialEvaluationScoringValue,
          potentialEvaluationSystemScore,
          potentialUniversalScore,
          potentialReason,
          kpis,
          actions,
          manualPotentialEvaluationSystemScore,
          maxSystemScoreProportion,
          weightingFactor,
          weightedSystemScore,
          degreeOfFulfillment,
          weightedPotentialSystemScore,
          kpi,
          excluded,
          relativeDegreeOfFulfillmentPotential,
          absoluteDegreeOfFulfillmentPotential,
          differenceRelativeDegreeOfFulfillment,
          differenceAbsoluteDegreeOfFulfillment,
          responsible,
          valueRangeList,
        } = node;
        indicatorElements.push({
          id,
          reason,
          evaluationValue,
          evaluationScoringValue,
          customCurrentValue,
          customPotentialValue,
          evaluationSystemScore,
          evaluationLinks,
          evaluationFiles,
          universalScore,
          indicatorStatus,
          potentialEvaluationValue,
          potentialEvaluationScoringValue,
          potentialEvaluationSystemScore,
          potentialUniversalScore,
          potentialReason,
          weightedPotentialSystemScore,
          manualPotentialEvaluationSystemScore,
          maxSystemScoreProportion,
          weightingFactor,
          weightedSystemScore,
          degreeOfFulfillment,
          kpis,
          actions: mapActions(actions),
          kpi,
          excluded,
          relativeDegreeOfFulfillmentPotential,
          absoluteDegreeOfFulfillmentPotential,
          differenceRelativeDegreeOfFulfillment,
          differenceAbsoluteDegreeOfFulfillment,
          responsible,
          valueRangeList,
        });
      } else {
        const {
          evaluationSystemScore,
          universalScore,
          potentialEvaluationSystemScore,
          potentialUniversalScore,
          maxSystemScoreProportion,
          weightingFactor,
          weightedSystemScore,
          degreeOfFulfillment,
          absoluteDegreeOfFulfillment,
          actions,
          weightedPotentialSystemScore,
          excluded,
          maxSystemScore,
          weightedMaxSystemScore,
          relativeProportionMaxSystemScore,
          relativeDegreeOfFulfillmentPotential,
          absoluteDegreeOfFulfillmentPotential,
          differenceRelativeDegreeOfFulfillment,
          differenceAbsoluteDegreeOfFulfillment,
          responsible,
        } = node;
        groupingElements.push({
          id,
          evaluationSystemScore,
          universalScore,
          potentialEvaluationSystemScore,
          potentialUniversalScore,
          weightedPotentialSystemScore,
          maxSystemScoreProportion,
          weightingFactor,
          weightedSystemScore,
          degreeOfFulfillment,
          absoluteDegreeOfFulfillment,
          actions: mapActions(actions),
          excluded,
          maxSystemScore,
          weightedMaxSystemScore,
          relativeProportionMaxSystemScore,
          relativeDegreeOfFulfillmentPotential,
          absoluteDegreeOfFulfillmentPotential,
          differenceRelativeDegreeOfFulfillment,
          differenceAbsoluteDegreeOfFulfillment,
          responsible,
        });
      }
      return { ...node, children };
    });
    return { indicatorElements, groupingElements };
  };

  const onSubmit = () => {
    if (system.ratingStatus !== ratingStatus && ratingStatus === STATUS_REPORTING) {
      showDialog({
        className: "small",
        closeOnClickOutside: false,
        getContent: (onClose) => (
          <DatePickerDialog
            title={t("ratingSystem.analysisDate.title")}
            fieldLabel={t("ratingSystem.analysisDate.fieldLabel")}
            defaultValue={moment(new Date()).format(DATE_FORMAT_SERVER)}
            onClose={onClose}
            onChange={(value) => {
              save({ analysisDate: value });
            }}
          />
        ),
      });
    } else {
      save();
    }
  };

  const save = async (params) => {
    setSubmitted(true);
    setErrorMessage(null);
    let updated;
    if (!groupingErrors.length) {
      const { analysisDate } = params || {};
      try {
        const { indicatorElements, groupingElements } = processGrouping();
        const {
          methodologyChapter,
          projectDescriptionChapter,
          overallMaxSystemScore: overallMaxSystemScoreSave,
        } = header;
        updated = {
          ratingSystemId,
          projectId,
          indicatorElements,
          groupingElements,
          score,
          superScore,
          potentialScore,
          potentialSuperScore,
          methodologyChapter,
          overallMaxSystemScore: overallMaxSystemScoreSave,
          projectDescriptionChapter,
          ratingStatus,
          koFailed,
          analysisDate,
          ratingSystemGoals: (ratingSystemGoals || []).map((item) => ({
            ...item,
            goals: (item.goals || []).map((goal) => ({
              value: goal.value,
              date: moment().set("year", goal.year).endOf("year").format(DATE_FORMAT_SERVER),
            })),
            UID: undefined,
            typeKey: undefined,
          })),
          selectedAwardId: actualAward?.id || null,
          modifiedDateTime: system?.modifiedDateTime,
        };

        await evaluateRatingSystem(updated);
        setIsEdit(false);
        load();
        getVersions();
        showSuccess(t("ratingSystem.updatedMessage"));
      } catch (error) {
        if (error.status === 409) {
          error.json().then((parsedBody) => {
            if (parsedBody?.message === "error.optimistic.lock") {
              mergeAndSave(updated);
            }
          }, console.error);
        } else {
          showError(t("ratingSystem.errorSaving"));
        }
      }
      setSubmitted(false);
    } else {
      setErrorMessage(t("ratingSystem.validationError"));
    }
  };

  const isAcceptChangesRequired = (data) =>
    !askLater && data.updated && canApplyChanges(data.ratingStatus, permissions);

  const load = () => {
    getRatingSystem(ratingSystemId)
      .then((data) => {
        if (data.status === STATUS_LOCKED || data.status === STATUS_IN_PROGRESS) {
          showWarning(
            processMessage(t("ratingSystem.warningSustainabilitySystemStatus"), [
              [data.systemSource, data.name, data.systemVersion].join(" "),
            ])
          );
          navigate(projectId ? `/project/${projectId}` : "/project");
        }
        resetData(data);

        setAcceptChangesInProgress(false);
      })
      .catch((e) => {
        console.error(e);
        showError(t("error.500"));
        navigate(projectId ? `/project/${projectId}` : "/project");
      });
  };

  const getVersions = () => {
    getRatingSystemRevisions(ratingSystemId)
      .then((data) => {
        setSelectedVersion(data[data.length - 1]?.revisionNumber || null);
      })
      .catch(() => {
        showError(t("error.500"));
      });
  };

  const sortedVersions = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return [...data].sort((a, b) => b.revisionNumber - a.revisionNumber);
  };

  const versionHandler = (revisionNumber, isCurrent) => {
    getRatingSystemRevisionById(ratingSystemId, revisionNumber)
      .then((data) => {
        resetData(data);
        setSelectedVersion(revisionNumber);
        setEditIsDisabled(!isCurrent);
      })
      .catch(() => {
        showError(t("error.500"));
      });
    setVersionDialog(false);
  };

  const acceptChanges = () => {
    setShowUpdateConfirmation(false);
    setAcceptChangesInProgress(true);
    acceptRatingSystemChanges(ratingSystemId)
      .then((values) => {
        setPreviousGrouping(grouping);
        setPreviousHeader(header);
        resetData(values);
        setReEvaluate(true);
      })
      .catch((error) => {
        console.error(error);
        showError(t("ratingSystem.acceptChangesError"));
        askDecisionLater();
      });
  };

  const declineChanges = () => {
    setShowUpdateConfirmation(false);
    setAcceptChangesInProgress(true);
    declineRatingSystemChanges(ratingSystemId)
      .then(() => {
        setPreviousGrouping(grouping);
        setPreviousHeader(header);
      })
      .catch((error) => {
        console.error(error);
        setAcceptChangesInProgress(false);
      })
      .finally(load);
  };

  const askDecisionLater = () => {
    setShowUpdateConfirmation(false);
    setAskLater(true);
  };

  const resetData = (values, disableEditing) => {
    if (disableEditing) {
      setIsEdit(false);
    }
    const data = values || system || {};
    setRatingStatus(data.ratingStatus);
    setHeader({
      name: data.name || "",
      awards: data.awards || [],
      systemVersion: data.systemVersion || "",
      systemSource: data.systemSource || "",
      projectTypes: Array.isArray(data.projectTypes) ? data.projectTypes : [],
      description: data.description || "",
      status: data.status || "",
      ratingStatus: data.ratingStatus || "",
      overallMaxSystemScore: data.overallMaxSystemScore || 0,
      awardCalculationType: data.awardCalculationType,
      methodologyChapter: data.methodologyTextImageDTO || new TextImageContainer(),
      projectDescriptionChapter: data.projectDescriptionTextImageDTO || {},
    });

    const processedGroupings = deepCopy(mapRatingSystemGroupings(data.groupingElements));
    const { systemLogic, ratingSystemGoals: ratingSystemGoalsResetData } = data;
    recalculateGroupingScore(processedGroupings, systemLogic);
    recalculatePotentialGroupingScore(processedGroupings, systemLogic);
    updateGrouping(processedGroupings, systemLogic);
    if (selectedRow) {
      updateSelectedRow(processedGroupings);
    }
    processScores(processedGroupings, systemLogic);
    processGoals(ratingSystemGoalsResetData);
    setSubmitted(false);
    setUpdateCounter(updateCounter + 1);
  };

  const processScores = (groupings, systemLogic) => {
    const scoreInner = calculateOverallSystemScore(groupings, systemLogic);
    setScore(scoreInner);
    const potentialScoreInner = calculatePotentialOverallSystemScore(groupings, systemLogic);
    setPotentialScore(potentialScoreInner);
  };

  const processGoals = (goals) => {
    const processedGoals = (goals || []).map((node) => ({ UID: String(node.id || getUUID()), ...node }));
    setRatingSystemGoals(processedGoals);
  };

  const renderDockContent = () => {
    if (!selectedRow) {
      return null;
    }
    return selectedRow.type === GROUPING_TYPE_INDICATOR ? (
      <IndicatorElementDockESG
        data={selectedRow}
        projectId={projectId}
        ratingSystemId={ratingSystemId}
        readOnly={
          editDisabled ||
          selectedRow.indicatorStatus === STATUS_CLOSED ||
          selectedRow.indicatorStatus === STATUS_NOT_APPLICABLE
        }
        rows={grouping}
        onChange={onChange}
        setOverallSystemScore={setScore}
        permissions={permissions}
        setPotentialOverallSystemScore={setPotentialScore}
        systemLogic={system.systemLogic}
        awards={awards}
      />
    ) : (
      <GroupingElementDockESG
        data={selectedRow}
        readOnly={
          editDisabled ||
          selectedRow.indicatorStatus === STATUS_CLOSED ||
          selectedRow.indicatorStatus === STATUS_NOT_APPLICABLE
        }
        rows={grouping}
        onChange={onChange}
        awards={awards}
      />
    );
  };

  const loading =
    ratingSystemAcceptChangesResponse.loading ||
    ratingSystemDeclineChangesResponse.loading ||
    groupResponse.loading ||
    response.loading ||
    evaluateResponse.loading ||
    versions.loading ||
    versionById.loading;
  const saveDisabled = !!uploadingFiles.length;

  // Auto scroll back to position after saving
  const tableScrollPosition = { x: useTableScrollX(), y: useTableScrollY() };
  const [lastScrollPosition, setLastScrollPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (submitted) {
      setLastScrollPosition({ x: tableScrollPosition.x, y: tableScrollPosition.y });
    }
  }, [submitted]);

  useEffect(() => {
    scrollElementByIdTo(ADVANCED_TABLE_ID, lastScrollPosition);
  }, [updateCounter, loading]);
  // End auto scroll back to position after saving

  const elementList = jp.query(grouping, "$..children[*]").concat(grouping);
  const actualSideThresholdFailed = checkThresholdFailed(
    system.awards || [],
    elementList,
    isPercent ? superScore : score,
    isPercent ? "universalScore" : "evaluationSystemScore"
  );
  const actualAward = getAward(
    system.awards || [],
    elementList,
    isPercent ? superScore : score,
    isPercent ? "universalScore" : "evaluationSystemScore"
  );
  const potentialAward = getAward(
    system.awards || [],
    elementList,
    isPercent ? potentialSuperScore : potentialScore,
    isPercent ? "potentialUniversalScore" : "potentialEvaluationSystemScore"
  );

  const scoreSystemData = {
    id: response?.data?.id,
    awards: response?.data?.awards || [],
    score,
    superScore,
    potentialScore,
    potentialSuperScore,
    awardCalculationType,
    overallMaxSystemScore,
    actualAward,
    potentialAward,
    groupingelements: system?.groupingElements ?? [],
  };

  const actualSideThresholdFailedMessage = () => {
    if (actualSideThresholdFailed) {
      if (actualAward) {
        return processMessage(t("award.sideRequirementLower"), [actualAward.title]);
      }
      return t("award.sideRequirementFailed");
    }
    return "";
  };

  const showAdditionalFieldsModal = useEvent(() => {
    const { methodologyChapter, projectDescriptionChapter } = header;
    showMuiDialog((props) => (
      <AdditionalFieldsDialog
        systemId={ratingSystemId}
        projectId={projectId}
        readOnly={!isEdit}
        data={{ methodologyChapter, projectDescriptionChapter }}
        onChange={({
          methodologyChapter: methodologyChapterInner,
          projectDescriptionChapter: projectDescriptionChapterInner,
        }) => {
          projectDescriptionChapterInner = createChapterFileArray(projectDescriptionChapterInner);
          setHeader((prev) => ({
            ...prev,
            methodologyChapter: methodologyChapterInner,
            projectDescriptionChapter: projectDescriptionChapterInner,
          }));
        }}
        {...props}
      />
    ));
  });

  const afterDecisions = useEvent(() => {
    setLinkedKpis([]);
    load();
  });

  const koAwardMessage = koFailed ? t("award.KO") : "";

  const memoGrouping = useMemo(() => grouping, [JSON.stringify(grouping)]);
  const memoGroupingErrors = useMemo(() => groupingErrors, [JSON.stringify(groupingErrors)]);

  useEffect(() => {
    indicatorsWithActionsByIdSubject.next(getIndicatorsWithActionsById(grouping));
  }, [memoGrouping]);

  const analyzePart = analyze ? "/analyze" : "";
  let pathParts = [];
  if (groupId) {
    pathParts.push({ url: "/projectGroup", text: t("menu.projectGroups.title") });
    pathParts.push({ url: "/projectGroup" + analyzePart + "/" + groupId, text: group.name });
    pathParts.push({
      url: "/projectGroup" + analyzePart + "/" + groupId + "/project/" + projectId,
      text: system.projectName,
    });
  } else {
    pathParts.push({ url: `/project`, text: t("menu.project.title") });
    pathParts.push({ url: `/project/${projectId}`, text: system.projectName });
  }
  pathParts.push({ text: header.systemSource || "" });

  const decisionsDisabled =
    !system.id ||
    isAcceptChangesRequired(system) ||
    !canApplyChanges(system.ratingStatus, permissions) ||
    acceptChangesInProgress;

  return (
    <LoadingOverlay spinner active={loading} className="rating-system-page">
      <KPIProjectFieldsDecisions
        projectId={projectId}
        linkedKpis={linkedKpis}
        disabled={decisionsDisabled}
        onFinish={afterDecisions}
      />

      <form autoComplete={"off"} className="content-panel">
        <div className="page-header">
          <div className="flex-row">
            <Breadcrumb pathParts={pathParts} />
            <div className="flex-auto text-right">
              <DashboardLink system={system} />
              <Button
                color="primary"
                className="right-header-button"
                onClick={() => setVersionDialog(true)}
                disabled={isEdit}
              >
                <ManageHistorySVG fontSize="inherit" style={{ fill: isEdit ? "rgba(0, 0, 0, 0.26)" : "" }} />
                {t("main.version")}
              </Button>
              {!!canView && !loading && (
                <a
                  color="primary"
                  href={`/api/rating/system/${ratingSystemId}/files/download`}
                  target="_blank"
                  rel="noreferrer"
                  className="right-header-button MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary"
                >
                  <DownloadSVG fontSize="inherit" />
                  {t("ratingSystem.downloadAllFiles")}
                </a>
              )}
            </div>
          </div>
          <div className="flex-row">
            <h2 className="page-title">{header.systemSource || ""}</h2>
            <div>
              <FormControl fullWidth required={false} className="status-select">
                <Select
                  fullWidth
                  onChange={(event) => setRatingStatus(event.target.value)}
                  disabled={!permissions.permissionStatusChanger || !isEdit}
                  value={ratingStatus || ""}
                  label={t("ratingSystem.status.title")}
                >
                  {(ratingStatus === STATUS_SCREENING ||
                    header.ratingStatus === STATUS_SCREENING ||
                    header.ratingStatus === STATUS_VERIFICATION) && (
                    <MenuItem key={STATUS_SCREENING} value={STATUS_SCREENING}>
                      <StatusBadge className={"status-" + STATUS_SCREENING}>
                        {t("ratingSystem.status." + STATUS_SCREENING)}
                      </StatusBadge>
                    </MenuItem>
                  )}
                  <MenuItem key={STATUS_VERIFICATION} value={STATUS_VERIFICATION}>
                    <StatusBadge className={"status-" + STATUS_VERIFICATION}>
                      {t("ratingSystem.status." + STATUS_VERIFICATION)}
                    </StatusBadge>
                  </MenuItem>
                  {(ratingStatus === STATUS_REPORTING ||
                    header.ratingStatus === STATUS_VERIFICATION ||
                    header.ratingStatus === STATUS_REPORTING) &&
                    allIndicatorsHandled && (
                      <MenuItem key={STATUS_REPORTING} value={STATUS_REPORTING}>
                        <StatusBadge className={"status-" + STATUS_REPORTING}>
                          {t("ratingSystem.status." + STATUS_REPORTING)}
                        </StatusBadge>
                      </MenuItem>
                    )}
                </Select>
              </FormControl>
            </div>
            <div className="flex-auto text-right">
              <TwoOptionsToggle
                value={showGoals}
                onChange={setShowGoals}
                option1={{ value: false, label: t("sustainabilitySystem.step.table") }}
                option2={{ value: true, label: t("ratingSystem.label.goals") }}
              />
            </div>
            <div className="flex-auto text-right">
              <Button size="small" color="primary" className="right-header-button" onClick={showAdditionalFieldsModal}>
                {t("ratingSystem.introductionFields.button")}
              </Button>
            </div>
          </div>
        </div>

        <div className="top-panel" hidden={!system.id}>
          <div className="base-data">
            <div className="flex-row">
              <div>
                <h2>{t("sustainabilitySystem.baseData")}</h2>
              </div>
              <div className="flex-auto" />
              <div className="text-right">
                <IconButton onClick={() => setCollapsed(!collapsed)} size="small" className="collapse-trigger">
                  {!!collapsed && <ExpandMoreIcon />}
                  {!collapsed && <ExpandLessIcon />}
                </IconButton>
              </div>
            </div>
            <div className={"collapsible " + (collapsed ? "collapsed" : "")}>
              <div className="collapsible-inner flex-row">
                <div className="version-col">
                  <TextField
                    fullWidth
                    value={header.name || ""}
                    label={t("sustainabilitySystem.name")}
                    disabled
                    className={!!previousHeader && !!header && previousHeader.name !== header.name ? "changed" : ""}
                  />
                </div>
                <div className="version-col">
                  <TextField
                    fullWidth
                    value={header.systemVersion || ""}
                    label={t("sustainabilitySystem.systemVersionShort")}
                    disabled
                    className={
                      !!previousHeader && !!header && previousHeader.systemVersion !== header.systemVersion
                        ? "changed"
                        : ""
                    }
                  />
                </div>
                <div className="project-type-col">
                  <TextField
                    value={(header.projectTypes || []).map((item) => t("sustainabilitySystem.type." + item)).join(", ")}
                    fullWidth
                    label={t("sustainabilitySystem.type.title")}
                    disabled
                    className={
                      !!previousHeader &&
                      !!header &&
                      (previousHeader.projectTypes || []).join() !== (header.projectTypes || []).join()
                        ? "changed"
                        : ""
                    }
                  />
                </div>
                <div className="description-col">
                  <TextField
                    fullWidth
                    value={header.description || ""}
                    label={t("sustainabilitySystem.description")}
                    disabled
                    className={
                      !!previousHeader && !!header && previousHeader.description !== header.description ? "changed" : ""
                    }
                  />
                </div>
              </div>

              <ContentBlock
                innerClassName="flex-row"
                hidden={!!showGoals}
                error={[koAwardMessage, actualSideThresholdFailedMessage()]}
              >
                <SystemScoreTableESG data={scoreSystemData} koFailed={koFailed} />
                <div
                  className="clickable-awardprogress-float-right"
                  title="Go to dashboard"
                  onClick={() => navigate(`/project/${projectId}/dashboard/${ratingSystemId}`)}
                >
                  <SystemScoreProgressESG data={scoreSystemData} />
                </div>
              </ContentBlock>
            </div>
          </div>
        </div>

        <Dock
          key={updateCounter}
          show={!!selectedRow && !selectedRow.excluded}
          hidden={!!showGoals}
          content={renderDockContent()}
          onClose={() => setSelectedRow(null)}
        >
          <RatingSystemGroupingESG
            values={memoGrouping}
            previousValues={previousGrouping}
            onChange={onChange}
            projectId={projectId}
            submitted={submitted}
            readOnly={editDisabled}
            selectedRow={selectedRow && !selectedRow.excluded ? selectedRow : null}
            score={score}
            potentialScore={potentialScore}
            ratingSystemId={ratingSystemId}
            superScore={superScore}
            potentialSuperScore={potentialSuperScore}
            systemLogic={system.systemLogic}
            errors={memoGroupingErrors}
            errorMessage={!!memoGroupingErrors.length && errorMessage}
          />
        </Dock>

        <div hidden={!showGoals} className="goals-wrapper">
          <RatingSystemGoals
            values={ratingSystemGoals}
            onChange={setRatingSystemGoals}
            readOnly={editDisabled}
            grouping={memoGrouping}
            score={score}
            superScore={superScore}
          />
        </div>

        {permissions.member && (
          <div className="fabs">
            {!isEdit && (
              <Fab type="button" color="primary" onClick={() => setIsEdit(true)} disabled={editIsDisabled}>
                <EditIcon className="fab-svg" />
              </Fab>
            )}
            {isEdit && (
              <>
                <Fab color="secondary" onClick={() => resetData(null, true)}>
                  <CloseSVG className="fab-svg" />
                </Fab>
                <Fab color="primary" onClick={handleSubmit(onSubmit)} disabled={saveDisabled}>
                  <SaveSVG className="fab-svg" />
                </Fab>
              </>
            )}
          </div>
        )}
      </form>

      <InfoDialog
        open={!!showInfo}
        onClose={() => setShowInfo(false)}
        titleText=""
        bodyText={t("ratingSystem.infoScoreChanged")}
        showOkButton
      />

      <ConfirmationDialog
        open={!!showUpdateConfirmation}
        onConfirm={acceptChanges}
        onCancel={declineChanges}
        thirdAction={askDecisionLater}
        titleText={t("ratingSystem.acceptChangesTitle")}
        bodyText={t("ratingSystem.acceptChanges")}
        thirdActionText={t("ratingSystem.askLater")}
        confirmText={t("main.accept")}
        color="secondary"
      />

      <VersionsDialog
        open={versionDialog}
        onClose={() => setVersionDialog(false)}
        onAction={versionHandler}
        titleText={t("versions.titleRating")}
        data={sortedVersions(versions.data)}
        selectedVersion={selectedVersion}
        loading={versionById.loading}
      />
    </LoadingOverlay>
  );
};
