import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useBeforeUnload, useBlocker } from "react-router-dom";

export const useEditSafeExit = (block) => {
  const { t } = useTranslation();

  useBeforeUnload(
    useCallback(
      (event) => {
        if (block) return event.preventDefault();
      },
      [block]
    )
  );

  const blocker = useBlocker(
    useCallback(
      ({ currentLocation, nextLocation }) => block && currentLocation.pathname !== nextLocation.pathname,
      [block]
    )
  );

  useEffect(() => {
    if (blocker.state === "blocked" && window.confirm(t("common.unsavedChanges"))) blocker.proceed();
    else if (blocker.state === "blocked") blocker.reset();
  }, [blocker.state]);
};
