import CodeIcon from "@mui/icons-material/Code";
import Button from "@mui/material/Button";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { TENANT_TYPE_PAST } from "../../../constants/tenant";
import { getUserTenantType } from "../../../hooks/auth";
import { roundDecimal } from "../../../utils";
import { DateColumnTemplate } from "../../ColumnTemplate/DateColumnTemplate";
import {
  AwardColumnTemplate,
  RatingSystemNameColumnTemplate,
  StatusColumnTemplate,
} from "../../Project/ProjectTemplates/ProjectTemplates";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import "./DashboardRatingSystems.scss";

export const DashboardRatingSystems = ({ ratingSystems, systemId, onSystemChange, expanded, isKpiSystem }) => {
  const { t } = useTranslation();
  const data = ratingSystems || [];
  const tenantType = getUserTenantType();
  const [collapsed, setCollapsed] = useState(!expanded);

  const sorted = data.sort((a, b) => {
    if (String(a.id) === systemId) {
      return -1;
    }
    if (String(b.id) === systemId) {
      return 1;
    }
    return 0;
  });
  const processed = collapsed ? (sorted.length ? [sorted[0]] : []) : sorted;

  const columns = useMemo(() => {
    const items = [];

    if (tenantType === TENANT_TYPE_PAST) {
      items.push(
        ...[
          {
            field: "systemSource",
            headerText: t("projects.ratingSystem"),
            template: (childRow, field, row) => RatingSystemNameColumnTemplate(childRow, field, row),
          },
          {
            field: "score",
            className: "text-right nowrap",
            headerClassName: "text-right",
            headerText: t("projects.pastScore"),
            template: (props) => (props.score ? roundDecimal(props.score, 100) : ""),
          },
        ]
      );
    } else {
      items.push({
        field: "systemSource",
        headerText: t("projects.ratingSystem"),
        template: (row) => (
          <NavLink
            to={row.kpiSystemId ? "/project/kpiRatingSystem/" + row.id : "/project/ratingSystem/" + row.id}
            onClick={(e) => e.stopPropagation()}
          >
            {row.systemSource} {row.name} {row.systemVersion}
          </NavLink>
        ),
      });
      if (!isKpiSystem) {
        items.push({
          field: "selectedAward",
          headerText: t("projects.award"),
          template: AwardColumnTemplate,
        });
      }
    }

    return [
      ...items,
      ...[
        {
          field: "ratingStatus",
          className: "status-cell",
          headerText: t("projects.status"),
          template: (props) => StatusColumnTemplate(props, t),
        },
        {
          field: "modifiedDateTime",
          className: "status-cell",
          headerText: t("projects.lastUpdate"),
          template: DateColumnTemplate,
        },
      ],
    ];
  }, [tenantType]);

  return (
    <div className="dashboard-rating-systems content-block">
      <SimpleTableWithSort
        data={processed}
        onRowClick={(row) => {
          onSystemChange(row.id, !!row.kpiSystemId);
          setCollapsed(true);
        }}
        rowClassName={(row) => (!!systemId && String(row.id) === systemId ? "selected" : "")}
        columns={columns}
      />
      <div className="content-block-footer">
        <Button onClick={() => setCollapsed(!collapsed)} className="collapse-button">
          {t(collapsed ? "main.expand" : "main.collapse")}
          <CodeIcon />
        </Button>
      </div>
    </div>
  );
};
