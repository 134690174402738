import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  GROUPING_TYPE_CATEGORY,
  GROUPING_TYPE_CRITERION,
  GROUPING_TYPE_INDICATOR,
} from "../../../constants/sustainabilitySystem";

export const AddMenuPopover = ({
  row,
  hideControls,
  addPopoverTarget,
  setAddPopoverTarget,
  addCategory,
  addChildCategory,
  addIndicator,
}) => {
  const { t } = useTranslation();
  if (!row) {
    return null;
  }
  const isIndicator = row.type === GROUPING_TYPE_INDICATOR;
  return (
    <>
      <Popover
        open={!!addPopoverTarget}
        anchorEl={addPopoverTarget}
        onClose={() => setAddPopoverTarget(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        {!isIndicator && (
          <>
            {(!row.parent || row.parent.type === GROUPING_TYPE_CATEGORY) && (
              <MenuItem onClick={() => addCategory(row, hideControls, GROUPING_TYPE_CATEGORY)}>
                {t("grouping.addCategory")}
              </MenuItem>
            )}
            {row.type === GROUPING_TYPE_CATEGORY && (
              <MenuItem onClick={() => addChildCategory(row, hideControls, GROUPING_TYPE_CATEGORY)}>
                {t("grouping.addChildCategory")}
              </MenuItem>
            )}
            {!!row.parent && (
              <MenuItem onClick={() => addCategory(row, hideControls, GROUPING_TYPE_CRITERION)}>
                {t("grouping.addCriteria")}
              </MenuItem>
            )}
            {!row.parent ? (
              <MenuItem onClick={() => addChildCategory(row, hideControls, GROUPING_TYPE_CRITERION)}>
                {t("grouping.addCriteria")}
              </MenuItem>
            ) : (
              <MenuItem onClick={() => addChildCategory(row, hideControls, GROUPING_TYPE_CRITERION)}>
                {t("grouping.addChildCriteria")}
              </MenuItem>
            )}
          </>
        )}
        {((!!row.parent && row.parent.type === GROUPING_TYPE_CRITERION) || row.type === GROUPING_TYPE_CRITERION) && (
          <MenuItem onClick={() => addIndicator(row, hideControls)}>{t("grouping.addIndicator")}</MenuItem>
        )}
      </Popover>
    </>
  );
};
