CanvasRenderingContext2D.prototype.customRoundRect = function (x, y, width, height, radius) {
  const cornerRadius = { upperLeft: 0, upperRight: 0, lowerLeft: 0, lowerRight: 0 };
  if (typeof radius === "object") {
    Object.keys(radius).forEach((side) => {
      cornerRadius[side] = radius[side];
    });
  }

  this.moveTo(x + cornerRadius.upperLeft, y);
  this.lineTo(x + width - cornerRadius.upperRight, y);
  this.quadraticCurveTo(x + width, y, x + width, y + cornerRadius.upperRight);
  this.lineTo(x + width, y + height - cornerRadius.lowerRight);
  this.quadraticCurveTo(x + width, y + height, x + width - cornerRadius.lowerRight, y + height);
  this.lineTo(x + cornerRadius.lowerLeft, y + height);
  this.quadraticCurveTo(x, y + height, x, y + height - cornerRadius.lowerLeft);
  this.lineTo(x, y + cornerRadius.upperLeft);
  this.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
};

export const drawBackgroundLine = (ctx, x1, y1, x2, y2, color, width) => {
  ctx.beginPath();
  ctx.moveTo(Math.round(x1), Math.round(y1));
  ctx.lineTo(Math.round(x2), Math.round(y2));
  ctx.closePath();

  ctx.lineWidth = width || 1;
  ctx.strokeStyle = color;
  ctx.stroke();
};

export const drawValueBar = (ctx, x, y, barWidth, barHeight, lineWidth, color, radius) => {
  ctx.beginPath();
  const halfWidth = barWidth / 2;
  ctx.customRoundRect(
    Math.round(x - halfWidth),
    Math.round(y - barHeight),
    Math.round(barWidth),
    Math.round(barHeight),
    radius || {
      upperLeft: 5,
      upperRight: 5,
    }
  );
  ctx.closePath();

  ctx.lineWidth = lineWidth || 1;
  ctx.fillStyle = color || "#0A3774";
  ctx.fill();
};

export const drawBar = (ctx, x, y, barWidth, barHeight, lineWidth = 1, color = "#0A3774", radius = {}) => {
  ctx.beginPath();
  const halfWidth = barWidth / 2;
  ctx.customRoundRect(
    Math.round(x - halfWidth),
    Math.round(y - barHeight),
    Math.round(barWidth),
    Math.round(barHeight),
    radius
  );
  ctx.closePath();

  ctx.lineWidth = lineWidth;
  ctx.fillStyle = color;
  ctx.fill();
};

export const getHeightInPoints = (value, force10) => {
  const length = String(value).split(".")[0].length;
  let multiplierString = "1";
  if (force10 && value >= 1 && value <= 10) {
    multiplierString += "0";
  } else {
    for (let i = 1; i < length; i++) {
      multiplierString += "0";
    }
  }

  const multiplier = +multiplierString;
  return Math.ceil(value / multiplier) * multiplier;
};

export const generatePalette = (size = 1, maxValue = 234, minValue = 0) => {
  const res = [];
  for (let i = 0, colorPartIndex = 0, progressStep = 0, darkStep = 0, shiftColor = false; i < size; i++) {
    if (colorPartIndex > 2) {
      colorPartIndex = 0;
      progressStep++;
      darkStep++;
      if (darkStep > 2) {
        darkStep = 0;
        shiftColor = !shiftColor;
      }
    }
    const progress = progressStep / (size / 3 - 1 || 1);
    const secondValue = minValue + Math.round((maxValue - minValue) * progress);
    const thirdValue = shiftColor ? minValue + maxValue / 2 - (maxValue / 2) * progress : minValue;
    const mainValue = maxValue;

    const rPart = colorPartIndex === 2 ? mainValue : colorPartIndex === 0 ? secondValue : thirdValue;
    const gPart = colorPartIndex === 1 ? mainValue : colorPartIndex === 2 ? secondValue : thirdValue;
    const bPart = colorPartIndex === 0 ? mainValue : colorPartIndex === 1 ? secondValue : thirdValue;
    const multiplier = darkStep === 1 ? 0.7 : darkStep === 2 ? 0.8 : 1;

    res.push(
      `rgb(${Math.ceil(rPart * multiplier)}, ${Math.ceil(gPart * multiplier)}, ${Math.ceil(bPart * multiplier)})`
    );

    colorPartIndex++;
  }
  return res;
};
