import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { Button, IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { CARBONE_URL } from "../../constants/main";
import { generateCriteriumReport } from "../../hooks/project";
import { showPromise } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { pauseEvent } from "./NewRatingSystem.util";
import { getSystemPageState } from "./NewRatingSystemESG";
import "./NewRatingSystemSideMenu.scss";
import { ActionBlockESG } from "./side-menu-blocks/ActionBlockESG";
import { ActualBlockESG } from "./side-menu-blocks/ActualBlockESG";
import { MetricsBlockESG } from "./side-menu-blocks/MetricsBlockESG";
import { PotentialBlockESG } from "./side-menu-blocks/PotentialBlockESG";
import { ResponsibilityBlock } from "./side-menu-blocks/ResponsibilityBlock";

export const SIDE_MENU_ID = "new-rating-system-side-menu";

export const defaultSideMenuState = {
  open: false,
  autoOpen: true,
  scrollTo: null,
  interact: null,
  transitioning: false,
};
const sideMenuStateSubject = new BehaviorSubject(defaultSideMenuState);
export const setSideMenuState = (v) => {
  const previous = sideMenuStateSubject.getValue();
  const current = typeof v === "function" ? v(sideMenuStateSubject.getValue()) : v;

  if (current.open !== previous.open) current.transitioning = true;
  sideMenuStateSubject.next(current);
};

export const getSideMenuState = () => sideMenuStateSubject.getValue();

const useSideMenuState = hookFromSubject(sideMenuStateSubject);

const onExplanationClick = (context, t, language) => {
  const { system } = getSystemPageState();
  const lang = language === "en" ? "en-us" : "de-de";
  const promise = new Promise((resolve, reject) =>
    generateCriteriumReport(system?.id, context?.dataItem?.uuid, lang)
      .then(({ data }) => {
        window.open(`${CARBONE_URL}${data?.renderId}`, "_blank");
        resolve();
      })
      .catch(reject)
  );

  showPromise(promise, {
    pending: t("newRatingSystem.esg.criteriumReport.pending"),
    success: t("newRatingSystem.esg.criteriumReport.success"),
    error: t("newRatingSystem.esg.criteriumReport.error"),
  });
};

export const NewRatingSystemSideMenu = React.memo(
  ({ selected, system, height, tableFn = {}, onClose = () => null, readOnly = true }) => {
    const state = useSideMenuState();
    const [resize, setResize] = useState({ style: {} });
    const [titleOnView, setTitleOnView] = useState(true);
    const resizing = resize?.startX !== undefined;
    const { t, i18n } = useTranslation();

    useEffect(() => {
      if (state.transitioning) return;

      if (state.scrollTo)
        document
          .querySelector(`#${SIDE_MENU_ID} ${state.scrollTo}`)
          .scrollIntoView({ behavior: "smooth", block: "start" });

      if (state.interact)
        document
          .querySelector(`#${SIDE_MENU_ID} ${state.interact?.selector}`)
          ?.[state.interact?.func](state.interact?.args);

      setSideMenuState((prev) => ({ ...prev, scrollTo: null, interact: null }));
    }, [state.scrollTo, state.interact, state.transitioning]);

    useEffect(() => {
      if (selected && state.autoOpen) setSideMenuState((prev) => ({ ...prev, open: true }));
    }, [selected]);

    const onResizeStart = useEvent((event) => {
      pauseEvent(event);
      setResize((prev) => ({ ...prev, startX: event.pageX }));
    });

    useEffect(() => {
      const onResize = (event) => {
        setResize((prev) => {
          if (prev.startX !== undefined) {
            pauseEvent(event);
            const deltaX = event.pageX - prev.startX;
            const width = Math.min(Math.max(prev.startWidth - deltaX, prev.minWidth), prev.maxWidth);
            return {
              ...prev,
              style: { width },
            };
          }

          return prev;
        });
      };

      const onResizeEnd = (event) => {
        setResize((prev) => {
          if (prev.startX !== undefined) {
            const { minWidth, maxWidth, style } = prev;
            pauseEvent(event);
            return { startWidth: style.width, minWidth, maxWidth, style };
          }
          return prev;
        });
      };

      const onWindowResize = () => {
        const parentNode = document.querySelector(`#${SIDE_MENU_ID}`)?.parentNode;
        if (parentNode?.offsetWidth)
          setResize((prev) => ({
            ...prev,
            minWidth: parentNode.offsetWidth / 5,
            maxWidth: parentNode.offsetWidth / 2,
          }));
      };

      window.addEventListener("resize", onWindowResize);
      document.addEventListener("pointermove", onResize);
      document.addEventListener("pointerup", onResizeEnd);

      return () => {
        window.removeEventListener("resize", onWindowResize);
        document.removeEventListener("pointermove", onResize);
        document.removeEventListener("pointerup", onResizeEnd);
      };
    }, []);

    return (
      <div
        id={SIDE_MENU_ID}
        className={
          "k-d-flex k-pos-relative k-w-0" + (resizing ? " resizing" : "") + (!selected ? " k-overflow-hidden" : "")
        }
        style={state.open ? { ...resize.style, height } : { width: 0, height }}
        onTransitionEnd={({ propertyName }) =>
          propertyName === "width" && setSideMenuState((prev) => ({ ...prev, transitioning: false }))
        }
        ref={(element) => {
          const parentNode = element?.parentNode;
          if (!resize.startWidth && parentNode?.offsetWidth)
            setResize((prev) => ({
              ...prev,
              style: { width: parentNode.offsetWidth / 4 },
              startWidth: parentNode.offsetWidth / 4,
              minWidth: parentNode.offsetWidth / 5,
              maxWidth: parentNode.offsetWidth / 2,
            }));
        }}
      >
        <Button
          className={"side-menu-show k-pos-absolute k-gap-2" + (state.open ? " fade-hide" : "")}
          onTransitionEnd={(event) => {
            if (event.propertyName === "opacity" && state.open) event.target.classList.add("hide");
          }}
          onClick={() => setSideMenuState({ autoOpen: true, open: true })}
        >
          <MenuOpenIcon fontSize="large" />
          {t("newRatingSystem.esg.sideMenu.openSideMenu")}
        </Button>
        <div
          className={
            "k-w-5 k-justify-content-center resizer" +
            (resizing ? " resizing" : "") +
            (state.open ? " k-d-flex" : " k-d-none")
          }
          onPointerDown={onResizeStart}
        >
          <div className="resize-bar"></div>
        </div>
        <div
          className={
            "side-menu-content k-overflow-auto k-flex-col k-gap-5 k-flex-1 " + (state.open ? "k-d-flex" : "k-d-none")
          }
          onScroll={(event) => setTitleOnView(event.target.scrollTop <= 20)}
        >
          <div className="side-menu-header k-d-flex k-align-items-center k-font-bold k-pt-5 k-px-5 k-gap-2 k-pos-sticky side-menu-header k-top-0 k-left-0 k-z-10">
            <span className={"k-flex-1 fade-in-section" + (titleOnView ? " active" : "")}>{selected?.name}</span>
            <Tooltip title={t("newRatingSystem.esg.sideMenu.explanationsReport")}>
              <IconButton
                className="new-rating-system-side-menu-operations"
                onClick={() => onExplanationClick({ dataItem: selected }, t, i18n.language)}
              >
                <TextSnippetOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                resize?.style?.width === resize?.maxWidth
                  ? t("newRatingSystem.esg.sideMenu.contract")
                  : t("newRatingSystem.esg.sideMenu.expand")
              }
            >
              <IconButton
                className="new-rating-system-side-menu-operations"
                onClick={() =>
                  setResize((prev) => {
                    const width = resize?.style?.width === resize?.maxWidth ? resize?.minWidth : resize?.maxWidth;

                    return {
                      ...prev,
                      startWidth: width,
                      style: { width },
                    };
                  })
                }
              >
                <OpenInFullIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("newRatingSystem.esg.sideMenu.close")}>
              <IconButton
                className="new-rating-system-side-menu-operations"
                onClick={() => {
                  setSideMenuState({ autoOpen: false, open: false });
                  onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
          {!selected && (
            <>
              <HelpOutlineIcon className="no-element-selected-icon" />
              <div className="no-element-selected-info">No element has been selected</div>
            </>
          )}
          <div
            className={
              "element-data-container fade-in-section k-d-flex k-flex-col k-gap-5" + (selected ? " active" : "")
            }
          >
            <MetricsBlockESG tableFn={tableFn} dataItem={selected} system={system} />
            <ActualBlockESG tableFn={tableFn} dataItem={selected} system={system} readOnly={readOnly} />
            <PotentialBlockESG tableFn={tableFn} dataItem={selected} system={system} readOnly={readOnly} />
            <ActionBlockESG tableFn={tableFn} dataItem={selected} readOnly={readOnly} />
            <ResponsibilityBlock tableFn={tableFn} dataItem={selected} readOnly={readOnly} />
          </div>
        </div>
      </div>
    );
  }
);

NewRatingSystemSideMenu.propTypes = {
  selected: PropTypes.object,
  system: PropTypes.object,
  height: PropTypes.number,
  onClose: PropTypes.func,
  tableFn: PropTypes.object,
  readOnly: PropTypes.bool,
};
