import React from 'react';
import './SystemScoreProgress.scss';
import { useTranslation } from "react-i18next";
import { AwardBarFlagSVG } from "../../../../svg";
import { roundDecimal } from "../../../../utils";

export const SystemScoreProgressPAST = ({ data }) => {
  const { t } = useTranslation();

  const { score } = data || {};
  const width = (score - 1) / 4 * 100;

  return (
    <div className="system-score-progress past">
      <span className="title">{t('dashboard.pastScoreTitle')}</span>
      <div className="progress-bar">
        <section className="scores">
          {!!score && score > 1 && <AwardBarFlagSVG/>}
          {!!score && <div className="score actual" style={{ width: `${width}%` }}>
            <span>{roundDecimal(score, 100)}</span>
          </div>}
        </section>
        <section className={'awards'} data-max={'5'}>
          <div className='segment'/>
          <div className='segment'/>
          <div className='segment'/>
        </section>
      </div>
    </div>
  );
};
