import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ADVANCED_TABLE_ID } from "../../components/AdvancedTable/AdvancedTable";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { ContentBlock } from "../../components/ContentBlock/ContentBlock";
import { DashboardLink } from "../../components/Dashboard/DashboardLink/DashboardLink";
import { SystemScoreProgressPAST } from "../../components/Dashboard/SystemScore/SystemScoreProgress/SystemScoreProgressPAST";
import { SystemScoreTablePAST } from "../../components/Dashboard/SystemScore/SystemScoreTable/SystemScoreTablePAST";
import { DatePickerDialog } from "../../components/DatePickerDialog/DatePickerDialog";
import { Dock } from "../../components/Dock/Dock";
import { InfoDialog } from "../../components/InfoDialog/InfoDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { AdditionalFieldsDialog } from "../../components/RatingSystem/AdditionalFieldsDialog/AdditionalFieldsDialog";
import { GroupingElementDockPAST } from "../../components/RatingSystem/GroupingElementDock/GroupingElementDockPAST";
import { IndicatorElementDockPAST } from "../../components/RatingSystem/IndicatorElementDockPAST/IndicatorElementDockPAST";
import { RatingSystemGroupingPAST } from "../../components/RatingSystem/RatingSystemGrouping/RatingSystemGroupingPAST";
import { StatusBadge } from "../../components/StatusBadge/StatusBadge";
import { VersionsDialog } from "../../components/Versions/VersionsDialog/VersionsDialog";
import { DATE_FORMAT_SERVER } from "../../constants/main";
import { PROJECT_PERMISSION } from "../../constants/project";
import {
  STATUS_CLOSED,
  STATUS_NOT_APPLICABLE,
  STATUS_REPORTING,
  STATUS_SCREENING,
  STATUS_VERIFICATION,
} from "../../constants/ratingSystem";
import { GROUPING_TYPE_INDICATOR, STATUS_IN_PROGRESS, STATUS_LOCKED } from "../../constants/sustainabilitySystem";
import { mergeRatingSystem } from "../../helpers/merge/ratingSystem";
import {
  calculatePASTOverallSystemScore,
  calculatePASTPercentsAndScore,
  canEdit,
  checkPASTKOFailed,
  groupingElementsFromChildren,
  mapRatingSystemGroupings,
  ratingSystemFromEvaluate,
} from "../../helpers/ratingSystem";
import { getActionSuggestion, setActionsProjectId } from "../../hooks/action";
import { useTableScrollX, useTableScrollY } from "../../hooks/advancedTable";
import { showDialog, showMuiDialog } from "../../hooks/dialog";
import { useEditSafeExit } from "../../hooks/editing";
import { useUploadingFiles } from "../../hooks/fileUpload";
import { getProjectUserPermissions, uploadProjectPictureKey } from "../../hooks/project";
import { getProjectGroup, useProjectGroupResponse } from "../../hooks/projectGroup";
import { uploadRatingReasonFileKey } from "../../hooks/ratingIndicatorFiles";
import {
  acceptRatingSystemChanges,
  declineRatingSystemChanges,
  evaluateRatingSystem,
  getRatingSystem,
  getRatingSystemRevisionById,
  getRatingSystemRevisions,
  selectedRowSubject,
  setRatingSystemGrouping,
  setSelectedRow,
  useRatingSystemAcceptChangesResponse,
  useRatingSystemDeclineChangesResponse,
  useRatingSystemEvaluateResponse,
  useRatingSystemGrouping,
  useRatingSystemResponse,
  useRatingSystemRevisionByIdResponse,
  useRatingSystemRevisionsResponse,
  useSelectedRow,
} from "../../hooks/ratingSystem";
import { showError, showSuccess, showWarning } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { useProjectPermissions } from "../../hooks/utils/usePagePermissions";
import { TextImageContainer } from "../../models/TextImageContainer";
import { CloseSVG, ManageHistorySVG, SaveSVG } from "../../svg";
import {
  createChapterFileArray,
  deepCopy,
  findRecursive,
  forEachRecursive,
  isExcludedElement,
  mapRecursive,
  processMessage,
  scrollElementByIdTo,
} from "../../utils";
import "./RatingSystem.scss";

const MANAGE_HISTORY_RGBA = "rgba(0, 0, 0, 0.26)";

export const RatingSystemPAST = ({ ratingSystemId, groupId, analyze }) => {
  const mode = new URLSearchParams(window.location.search).get("mode");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const response = useRatingSystemResponse();
  const system = deepCopy(response.data || {});
  const [updateCounter, setUpdateCounter] = useState(0);
  const groupResponse = useProjectGroupResponse();
  const group = groupResponse.data || {};
  const projectId = system.projectId;
  const permissions = system.projectPermission ?? {};
  const updateMethodologyChapter = system.sustainabilityMethodologyChapterVersion !== system.methodologyChapterVersion;

  useProjectPermissions(permissions, PROJECT_PERMISSION.ACCESS, response.loading);

  const [isEdit, setIsEdit] = useState(mode === "edit" && permissions.member);
  const [submitted, setSubmitted] = useState(false);
  const [koFailed, setKoFailed] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [reEvaluate, setReEvaluate] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showMethodologyChanged, setShowMethodologyChanged] = useState(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [askLater, setAskLater] = useState(false);
  const [header, setHeader] = useState({});
  const [previousHeader, setPreviousHeader] = useState(null);
  const [ratingStatus, setRatingStatus] = useState(null);
  const [score, setScore] = useState(0);
  const editDisabled = !isEdit || !canEdit(permissions);
  const selectedRow = useSelectedRow();
  const [allIndicatorsHandled, setAllIndicatorsHandled] = useState(false);
  const [grouping, setGrouping] = useState([]);
  const [previousGrouping, setPreviousGrouping] = useState(null);
  const [versionDialog, setVersionDialog] = useState(false);
  const [editIsDisabled, setEditIsDisabled] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [saveMerged, setSaveMerged] = useState(false);
  const versions = useRatingSystemRevisionsResponse();
  const versionById = useRatingSystemRevisionByIdResponse();
  const evaluateResponse = useRatingSystemEvaluateResponse();
  const uploadingFiles = useUploadingFiles().filter((item) => item.key === uploadRatingReasonFileKey);
  const uploadingImages = useUploadingFiles().filter((item) => item.key === uploadProjectPictureKey);

  const ratingSystemAcceptChangesResponse = useRatingSystemAcceptChangesResponse();
  const ratingSystemDeclineChangesResponse = useRatingSystemDeclineChangesResponse();
  const ratingSystemGrouping = useRatingSystemGrouping();

  useEditSafeExit(isEdit);

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    setGrouping(ratingSystemGrouping);
  });

  useEffect(() => {
    if (ratingSystemId) {
      if (mode === "edit" && permissions.member) {
        window.history.replaceState(null, null, window.location.pathname);
      }
      setSelectedRow(null);
      load();
      getVersions();
    }
  }, [ratingSystemId]);

  useEffect(() => {
    if (projectId) {
      setActionsProjectId(projectId);
      getProjectUserPermissions(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (groupId) {
      getProjectGroup(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    if (ratingSystemId && isEdit) {
      getActionSuggestion(ratingSystemId);
      setPreviousGrouping(null);
    }
  }, [ratingSystemId, isEdit]);

  useEffect(() => {
    if (reEvaluate) {
      setReEvaluate(false);
      updateGrouping(grouping);
      onSubmit();
      if (checkScoreChanged()) {
        setShowInfo(true);
      }
    }
  }, [reEvaluate]);

  useEffect(() => {
    if (!!system && isAcceptChangesRequired(system)) {
      setShowUpdateConfirmation(true);
    }
  }, [system.id]);

  useEffect(() => {
    if (saveMerged) {
      try {
        setSaveMerged(false);
        onSubmit();
      } catch (e) {
        showError(t("error.500"));
      }
    }
  }, [saveMerged]);

  useEffect(() => {
    if (!isEdit && !submitted) {
      const data = system || {};
      resetData(data);
    }
  }, [isEdit, submitted]);

  const mergeAndSave = async (updated) => {
    try {
      const current = await getRatingSystem(ratingSystemId);
      const updatedSystem = ratingSystemFromEvaluate(response.data, updated);
      const [merged, withConflicts] = mergeRatingSystem(response.data, current, updatedSystem);
      merged.groupingElements = groupingElementsFromChildren(merged.children);
      if (withConflicts) {
        showWarning(processMessage(t("main.savedWithConflicts"), [current?.modifier]));
      }
      resetData(merged);
      setSaveMerged(true);
    } catch (e) {
      showError(t("error.500"));
    }
  };

  const checkScoreChanged = () => {
    let res = false;
    if (previousGrouping) {
      const nodesByUUID = {};
      forEachRecursive(previousGrouping, (node) => {
        nodesByUUID[node.uuid] = node;
      });
      forEachRecursive(grouping, (node) => {
        if (isExcludedElement(node)) {
          return true;
        }
        if (nodesByUUID[node.uuid] && nodesByUUID[node.uuid].maxSystemScore !== node.maxSystemScore) {
          res = true;
          return true;
        }
      });
    }
    return res;
  };

  const checkAllIndicatorsHandled = (groupingCheckAllIndicatorsHandled) => {
    let res = true;
    forEachRecursive(groupingCheckAllIndicatorsHandled, (node) => {
      if (isExcludedElement(node)) {
        return true;
      }
      if (node.type === GROUPING_TYPE_INDICATOR) {
        if ([STATUS_CLOSED, STATUS_NOT_APPLICABLE].indexOf(node.indicatorStatus) === -1) {
          res = false;
          return true;
        }
      }
    });
    return res;
  };

  const updateSelectedRow = (groupingInner) => {
    const found = findSelectedRow(groupingInner);
    if (found) {
      setSelectedRow(found);
    }
  };

  const findSelectedRow = (groupingInner) => {
    let found = null;
    const selectedRowInner = selectedRowSubject.getValue();
    if (selectedRowInner) {
      found = findRecursive(groupingInner, (item) => item.uuid === selectedRowInner.uuid);
    }
    return found;
  };

  const updateGrouping = useEvent((groupingInner) => {
    forEachRecursive(groupingInner, (node) => {
      node.excludedFromCalculation = isExcludedElement(node);
    });

    calculatePASTPercentsAndScore(groupingInner);
    setRatingSystemGrouping(groupingInner);
    setAllIndicatorsHandled(checkAllIndicatorsHandled(groupingInner));
    setScore(calculatePASTOverallSystemScore(groupingInner));
    setKoFailed(checkPASTKOFailed(groupingInner));
    updateSelectedRow(groupingInner);
    setGrouping(groupingInner);
  });

  const mapActions = (actions) => {
    if (!actions) {
      return undefined;
    }
    return actions.map((item) => ({ ...item, UID: undefined, tags: undefined }));
  };

  const processGrouping = () => {
    const indicatorElements = [];
    const groupingElements = [];
    mapRecursive(grouping, (node, children) => {
      const { id } = node;
      if (node.type === GROUPING_TYPE_INDICATOR) {
        const {
          reason,
          weightingFactor,
          evaluationSystemScore,
          universalScore,
          indicatorStatus,
          actions,
          maxSystemScoreProportion,
          excluded,
          responsible,
          evaluationFiles,
          customName,
          customEvaluationStandard,
        } = node;
        indicatorElements.push({
          id,
          reason,
          weightingFactor,
          evaluationSystemScore,
          maxSystemScoreProportion,
          excluded,
          universalScore,
          indicatorStatus,
          actions: mapActions(actions),
          responsible,
          evaluationFiles,
          customName,
          customEvaluationStandard,
        });
      } else {
        const {
          universalScore,
          weightingFactor,
          maxSystemScoreProportion,
          actions,
          summary,
          fileIds,
          topTopicsAndActions,
          maxSystemScore,
          weightedMaxSystemScore,
          relativeProportionMaxSystemScore,
          excluded,
          responsible,
          customName,
          customEvaluationStandard,
        } = node;
        groupingElements.push({
          id,
          universalScore,
          weightingFactor,
          maxSystemScoreProportion,
          actions: mapActions(actions),
          summary,
          fileIds,
          topTopicsAndActions,
          maxSystemScore,
          weightedMaxSystemScore,
          relativeProportionMaxSystemScore,
          excluded,
          responsible,
          customName,
          customEvaluationStandard,
        });
      }
      return { ...node, children };
    });
    return { indicatorElements, groupingElements };
  };

  const onSubmit = () => {
    if (system.ratingStatus !== ratingStatus && ratingStatus === STATUS_REPORTING) {
      showDialog({
        className: "small",
        closeOnClickOutside: false,
        getContent: (onClose) => (
          <DatePickerDialog
            title={t("ratingSystem.analysisDate.title")}
            fieldLabel={t("ratingSystem.analysisDate.fieldLabel")}
            defaultValue={moment(new Date()).format(DATE_FORMAT_SERVER)}
            onClose={onClose}
            onChange={(value) => {
              save({ analysisDate: value });
            }}
          />
        ),
      });
    } else {
      save();
    }
  };

  const save = async (params) => {
    setSubmitted(true);
    const { analysisDate } = params || {};
    let updated;
    try {
      const { indicatorElements, groupingElements } = processGrouping();
      const { methodologyChapter, projectDescriptionChapter, statementForOverallRating, overallMaxSystemScore } =
        header;
      updated = {
        ratingSystemId,
        projectId,
        indicatorElements,
        groupingElements,
        score,
        ratingStatus,
        koFailed,
        analysisDate,
        methodologyChapter,
        overallMaxSystemScore,
        projectDescriptionChapter,
        statementForOverallRating,
        modifiedDateTime: system?.modifiedDateTime,
      };
      await evaluateRatingSystem(updated);
      setIsEdit(false);
      load();
      getVersions();
      showSuccess(t("ratingSystem.updatedMessage"));
    } catch (error) {
      if (error.status === 409) {
        error.json().then((parsedBody) => {
          if (parsedBody?.message === "error.optimistic.lock") {
            mergeAndSave(updated);
          }
        }, console.error);
      } else {
        showError(t("ratingSystem.errorSaving"));
      }
    }
    setSubmitted(false);
  };

  const isAcceptChangesRequired = (data) =>
    !askLater && data.updated && data.ratingStatus !== STATUS_REPORTING && permissions.permissionChangeApplier;

  const load = () => {
    getRatingSystem(ratingSystemId)
      .then((data) => {
        const projectIdInner = data.projectId;
        if (data.status === STATUS_LOCKED || data.status === STATUS_IN_PROGRESS) {
          showWarning(
            processMessage(t("ratingSystem.warningSustainabilitySystemStatus"), [
              [data.systemSource, data.name, data.systemVersion].join(" "),
            ])
          );
          navigate(`/project/${projectIdInner}`);
        }
        resetData(data);
      })
      .catch(() => {
        showError(t("error.500"));
        navigate("/project");
      });
  };

  const getVersions = () => {
    getRatingSystemRevisions(ratingSystemId)
      .then((data) => {
        setSelectedVersion(data[data.length - 1]?.revisionNumber || null);
      })
      .catch(() => {
        showError(t("error.500"));
      });
  };

  const sortedVersions = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return [...data].sort((a, b) => b.revisionNumber - a.revisionNumber);
  };

  const versionHandler = (revisionNumber, isCurrent) => {
    getRatingSystemRevisionById(ratingSystemId, revisionNumber)
      .then((data) => {
        resetData(data);
        setSelectedVersion(revisionNumber);
        setEditIsDisabled(!isCurrent);
      })
      .catch(() => {
        showError(t("error.500"));
      });
    setVersionDialog(false);
  };

  const acceptChanges = () => {
    setShowUpdateConfirmation(false);
    acceptRatingSystemChanges(ratingSystemId)
      .then((values) => {
        setPreviousGrouping(grouping);
        setPreviousHeader(header);

        if (updateMethodologyChapter) {
          setShowMethodologyChanged(true);
        }

        resetData(values);
        setReEvaluate(true);
      })
      .catch((error) => {
        console.error(error);
        load();
      });
  };

  const declineChanges = () => {
    setShowUpdateConfirmation(false);
    declineRatingSystemChanges(ratingSystemId)
      .then(() => {
        setPreviousGrouping(grouping);
        setPreviousHeader(header);
      })
      .catch(console.error)
      .finally(load);
  };

  const askDecisionLater = () => {
    setShowUpdateConfirmation(false);
    setAskLater(true);
  };

  const resetData = (values, disableEditing) => {
    if (disableEditing) {
      setIsEdit(false);
    }
    const data = values || response.data || {};
    setRatingStatus(data.ratingStatus);
    setHeader({
      name: data.name || "",
      systemVersion: data.systemVersion || "",
      systemSource: data.systemSource || "",
      projectTypes: Array.isArray(data.projectTypes) ? data.projectTypes : [],
      description: data.description || "",
      status: data.status || "",
      ratingStatus: data.ratingStatus || "",
      overallMaxSystemScore: data.overallMaxSystemScore || 0,
      methodologyChapter: data.methodologyTextImageDTO || new TextImageContainer(),
      projectDescriptionChapter: data.projectDescriptionTextImageDTO || new TextImageContainer(),
      statementForOverallRating: data.statementForOverallRating || new TextImageContainer(),
    });
    const processedGroupings = deepCopy(mapRatingSystemGroupings(data.groupingElements));
    updateGrouping(processedGroupings);
    if (selectedRow) {
      updateSelectedRow(processedGroupings);
    }
    setScore(calculatePASTOverallSystemScore(processedGroupings));
    setSubmitted(false);
    setUpdateCounter(updateCounter + 1);
  };

  const changeRatingStatus = (value) => {
    setRatingStatus(value);
  };

  const showAdditionalFieldsModal = useEvent(() => {
    const { methodologyChapter, projectDescriptionChapter, statementForOverallRating } = header;
    showMuiDialog((props) => (
      <AdditionalFieldsDialog
        systemId={ratingSystemId}
        projectId={projectId}
        readOnly={!isEdit}
        data={{ methodologyChapter, projectDescriptionChapter, statementForOverallRating }}
        onChange={({
          methodologyChapter: methodologyChapterInner,
          projectDescriptionChapter: projectDescriptionChapterInner,
          statementForOverallRating: statementForOverallRatingInner,
        }) => {
          projectDescriptionChapterInner = createChapterFileArray(projectDescriptionChapterInner);
          methodologyChapterInner = createChapterFileArray(methodologyChapterInner);
          statementForOverallRatingInner = createChapterFileArray(statementForOverallRatingInner);
          setHeader({
            ...header,
            methodologyChapter: methodologyChapterInner,
            projectDescriptionChapter: projectDescriptionChapterInner,
            statementForOverallRating: statementForOverallRatingInner,
          });
        }}
        {...props}
      />
    ));
  });

  const renderDockContent = () => {
    const selectedRowFound = findSelectedRow(grouping);
    if (!selectedRowFound) {
      return null;
    }

    const readOnly =
      editDisabled ||
      selectedRow.indicatorStatus === STATUS_CLOSED ||
      selectedRow.indicatorStatus === STATUS_NOT_APPLICABLE;
    return selectedRow.type === GROUPING_TYPE_INDICATOR ? (
      <IndicatorElementDockPAST
        data={selectedRowFound}
        projectId={projectId}
        readOnly={readOnly}
        rows={grouping}
        onChange={updateGrouping}
        permissions={permissions}
        ratingSystemId={ratingSystemId}
      />
    ) : (
      <GroupingElementDockPAST
        projectId={projectId}
        data={selectedRowFound}
        readOnly={readOnly}
        rows={grouping}
        onChange={updateGrouping}
        ratingSystemId={ratingSystemId}
      />
    );
  };

  const loading =
    ratingSystemAcceptChangesResponse.loading ||
    ratingSystemDeclineChangesResponse.loading ||
    groupResponse.loading ||
    response.loading ||
    evaluateResponse.loading ||
    versions.loading ||
    versionById.loading;
  const saveDisabled = !!uploadingFiles.length || !!uploadingImages.length;

  // Auto scroll back to position after saving
  const tableScrollPosition = { x: useTableScrollX(), y: useTableScrollY() };
  const [lastScrollPosition, setLastScrollPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (submitted) {
      setLastScrollPosition({ x: tableScrollPosition.x, y: tableScrollPosition.y });
    }
  }, [submitted]);

  useEffect(() => {
    scrollElementByIdTo(ADVANCED_TABLE_ID, lastScrollPosition);
  }, [updateCounter, loading]);
  // End auto scroll back to position after saving

  const scoreSystemData = {
    id: response?.data?.id,
    score,
  };

  const memoGrouping = useMemo(() => grouping, [JSON.stringify(grouping)]);

  const analyzePart = analyze ? "/analyze" : "";
  let pathParts = [];
  if (groupId) {
    pathParts.push({ url: "/projectGroup", text: t("menu.projectGroups.title") });
    pathParts.push({ url: "/projectGroup" + analyzePart + "/" + groupId, text: group.name });
    pathParts.push({
      url: "/projectGroup" + analyzePart + "/" + groupId + "/project/" + projectId,
      text: system.projectName,
    });
  } else {
    pathParts.push({ url: `/project`, text: t("menu.project.title") });
    pathParts.push({ url: `/project/${projectId}`, text: system.projectName });
  }
  pathParts.push({ text: header.systemSource || "" });

  return (
    <LoadingOverlay spinner active={loading} className="rating-system-page">
      <form autoComplete={"off"} className="content-panel">
        <div className="page-header">
          <div className="flex-row">
            <Breadcrumb pathParts={pathParts} />
            <div className="flex-auto text-right">
              <DashboardLink system={system} />
              <Button
                color="primary"
                className="right-header-button"
                onClick={() => setVersionDialog(true)}
                disabled={isEdit}
              >
                <ManageHistorySVG fontSize="inherit" style={{ fill: isEdit ? MANAGE_HISTORY_RGBA : "" }} />
                {t("main.version")}
              </Button>
            </div>
          </div>
          <div className="flex-row">
            <h2 className="page-title">{header.systemSource || ""}</h2>
            <div>
              <FormControl fullWidth required={false} className="status-select">
                <Select
                  fullWidth
                  onChange={(event) => changeRatingStatus(event.target.value)}
                  disabled={!permissions.permissionStatusChanger || !isEdit}
                  value={ratingStatus || ""}
                  label={t("ratingSystem.status.title")}
                >
                  {(ratingStatus === STATUS_SCREENING ||
                    header.ratingStatus === STATUS_SCREENING ||
                    header.ratingStatus === STATUS_VERIFICATION) && (
                    <MenuItem key={STATUS_SCREENING} value={STATUS_SCREENING}>
                      <StatusBadge className={"status-" + STATUS_SCREENING}>
                        {t("ratingSystem.status." + STATUS_SCREENING)}
                      </StatusBadge>
                    </MenuItem>
                  )}
                  <MenuItem key={STATUS_VERIFICATION} value={STATUS_VERIFICATION}>
                    <StatusBadge className={"status-" + STATUS_VERIFICATION}>
                      {t("ratingSystem.status." + STATUS_VERIFICATION)}
                    </StatusBadge>
                  </MenuItem>
                  {(ratingStatus === STATUS_REPORTING ||
                    header.ratingStatus === STATUS_VERIFICATION ||
                    header.ratingStatus === STATUS_REPORTING) &&
                    allIndicatorsHandled && (
                      <MenuItem key={STATUS_REPORTING} value={STATUS_REPORTING}>
                        <StatusBadge className={"status-" + STATUS_REPORTING}>
                          {t("ratingSystem.status." + STATUS_REPORTING)}
                        </StatusBadge>
                      </MenuItem>
                    )}
                </Select>
              </FormControl>
            </div>
            <div className="flex-auto text-right">
              <Button size="small" color="primary" className="right-header-button" onClick={showAdditionalFieldsModal}>
                {t("ratingSystem.additionalFields.button")}
              </Button>
            </div>
          </div>
        </div>

        <div className="top-panel">
          <div className="base-data">
            <div className="flex-row">
              <div>
                <h2>{t("sustainabilitySystem.baseData")}</h2>
              </div>
              <div className="flex-auto" />
              <div className="text-right">
                <IconButton onClick={() => setCollapsed(!collapsed)} size="small" className="collapse-trigger">
                  {!!collapsed && <ExpandMoreIcon />}
                  {!collapsed && <ExpandLessIcon />}
                </IconButton>
              </div>
            </div>
            <div className={"collapsible " + (collapsed ? "collapsed" : "")}>
              <div className="collapsible-inner flex-row">
                <div className="version-col">
                  <TextField
                    fullWidth
                    value={header.name || ""}
                    label={t("sustainabilitySystem.name")}
                    disabled
                    className={!!previousHeader && !!header && previousHeader.name !== header.name ? "changed" : ""}
                  />
                </div>
                <div className="version-col">
                  <TextField
                    fullWidth
                    value={header.systemVersion || ""}
                    label={t("sustainabilitySystem.systemVersionShort")}
                    disabled
                    className={
                      !!previousHeader && !!header && previousHeader.systemVersion !== header.systemVersion
                        ? "changed"
                        : ""
                    }
                  />
                </div>
                <div className="project-type-col">
                  <TextField
                    value={(header.projectTypes || []).map((item) => t("sustainabilitySystem.type." + item)).join(", ")}
                    fullWidth
                    label={t("sustainabilitySystem.type.title")}
                    disabled
                    className={
                      !!previousHeader &&
                      !!header &&
                      (previousHeader.projectTypes || []).join() !== (header.projectTypes || []).join()
                        ? "changed"
                        : ""
                    }
                  />
                </div>
                <div className="description-col">
                  <TextField
                    fullWidth
                    value={header.description || ""}
                    label={t("sustainabilitySystem.description")}
                    disabled
                    className={
                      !!previousHeader && !!header && previousHeader.description !== header.description ? "changed" : ""
                    }
                  />
                </div>
              </div>

              <ContentBlock innerClassName="flex-row">
                <SystemScoreTablePAST data={scoreSystemData} />
                <div
                  className="clickable-awardprogress-float-right"
                  title="Go to dashboard"
                  onClick={() => navigate(`/project/${projectId}/dashboard/${ratingSystemId}`)}
                >
                  <SystemScoreProgressPAST data={scoreSystemData} />
                </div>
              </ContentBlock>
            </div>
          </div>
        </div>

        <Dock
          key={updateCounter}
          show={!!selectedRow}
          content={renderDockContent()}
          onClose={() => setSelectedRow(null)}
        >
          <RatingSystemGroupingPAST
            values={memoGrouping}
            previousValues={previousGrouping}
            onChange={updateGrouping}
            projectId={projectId}
            submitted={submitted}
            readOnly={editDisabled}
            selectedRow={selectedRow}
            score={score}
            ratingSystemId={ratingSystemId}
          />
        </Dock>

        {permissions.member && (
          <div className="fabs">
            {!isEdit && (
              <Fab type="button" color="primary" onClick={() => setIsEdit(true)} disabled={editIsDisabled}>
                <EditIcon className="fab-svg" />
              </Fab>
            )}
            {isEdit && (
              <>
                <Fab color="secondary" onClick={() => resetData(null, true)}>
                  <CloseSVG className="fab-svg" />
                </Fab>
                <Fab color="primary" onClick={handleSubmit(onSubmit)} disabled={saveDisabled}>
                  <SaveSVG className="fab-svg" />
                </Fab>
              </>
            )}
          </div>
        )}
      </form>

      <InfoDialog
        open={!!showInfo}
        onClose={() => setShowInfo(false)}
        titleText=""
        bodyText={t("ratingSystem.infoScoreChanged")}
        showOkButton
      />

      <InfoDialog
        open={!!showMethodologyChanged}
        onClose={() => setShowMethodologyChanged(false)}
        titleText=""
        bodyText={t("ratingSystem.methodologyChapterChanged")}
        showOkButton
      />

      <ConfirmationDialog
        open={!!showUpdateConfirmation}
        onConfirm={acceptChanges}
        onCancel={declineChanges}
        thirdAction={askDecisionLater}
        titleText={t("ratingSystem.acceptChangesTitle")}
        bodyText={t("ratingSystem.acceptChanges")}
        thirdActionText={t("ratingSystem.askLater")}
        confirmText={t("main.accept")}
        color="secondary"
      />

      <VersionsDialog
        open={versionDialog}
        onClose={() => setVersionDialog(false)}
        onAction={versionHandler}
        titleText={t("versions.titleRating")}
        data={sortedVersions(versions.data)}
        selectedVersion={selectedVersion}
        loading={versionById.loading}
      />
    </LoadingOverlay>
  );
};
