import React, { useEffect, useState } from 'react';
import { useUser } from "../../hooks/auth";

const PRIMARY_BACKGROUND_COLOR = '#002965';
const SECONDARY_BACKGROUND_COLOR = '#62B0FF';

const PRIMARY_TEXT_COLOR = '#FFFFFF';
const SECONDARY_TEXT_COLOR = '#002965';
const NEUTRAL_BACKGROUND_COLOR = '#DFE2E8';
const NEUTRAL_TEXT_COLOR = '#707070';

const RED_COLOR = '#FF391D';
const RED_HOVER_COLOR = '#DD341D';
const ORANGE_COLOR = '#EB6200';
const GREEN_COLOR = '#198038';
const BLUE_COLOR = '#005EBC';

const defaultTheme = `
  :root {
    --PRIMARY_BACKGROUND_COLOR: ${PRIMARY_BACKGROUND_COLOR};
    --PRIMARY_BACKGROUND_LOW_OPACITY_COLOR: ${PRIMARY_BACKGROUND_COLOR}11;
    --SECONDARY_BACKGROUND_COLOR: ${SECONDARY_BACKGROUND_COLOR};
    --PRIMARY_TEXT_COLOR: ${PRIMARY_TEXT_COLOR};
    --SECONDARY_TEXT_COLOR: ${SECONDARY_TEXT_COLOR};
    --NEUTRAL_BACKGROUND_COLOR: ${NEUTRAL_BACKGROUND_COLOR};
    --NEUTRAL_TEXT_COLOR: ${NEUTRAL_TEXT_COLOR};
    --RED_COLOR: ${RED_COLOR};
    --RED_HOVER_COLOR: ${RED_HOVER_COLOR};
    --ORANGE_COLOR: ${ORANGE_COLOR};
    --ORANGE_BACKGROUND_COLOR: ${ORANGE_COLOR}33;
    --GREEN_COLOR: ${GREEN_COLOR};
    --GREEN_BACKGROUND_COLOR: ${GREEN_COLOR}33;
    --BLUE_COLOR:  ${BLUE_COLOR};
    --BLUE_BACKGROUND_COLOR: ${BLUE_COLOR}33;
  }`;

export const getColor = (name) =>
  getComputedStyle(document.body).getPropertyValue('--' + name) || '';

export const getDefaultColors = () => {
  return {
    PRIMARY_BACKGROUND_COLOR,
    SECONDARY_BACKGROUND_COLOR
  }
};

export const WhitelabelControl = () => {
  const user = useUser();

  const [updatedTheme, setUpdatedColors] = useState('');

  useEffect(() => {
    if (user?.whitelabelDetails?.primaryColour && user?.whitelabelDetails?.potentialColour) {
      colorUpdater(user.whitelabelDetails.primaryColour, user.whitelabelDetails.potentialColour);
    }
  }, [user?.whitelabelDetails]);

  const colorUpdater = (primaryColor, secondaryColor) => {
    setUpdatedColors(() => `
      :root {
        --PRIMARY_BACKGROUND_COLOR: #${primaryColor};
        --PRIMARY_BACKGROUND_LOW_OPACITY_COLOR: #${primaryColor}11;
        --SECONDARY_BACKGROUND_COLOR: #${secondaryColor};
      }`
    );
  };

  return (
    <style>
      {defaultTheme}
      {updatedTheme}
    </style>
  );
};
