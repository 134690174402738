import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SimpleTableWithSort.scss";

export const SimpleTableWithSort = ({
  data,
  columns,
  rowClassName,
  className,
  emptyText,
  childrenRecordsName,
  pagination,
  sortValue,
  onSort,
  passThrough,
  bodyHeader,
  disableSort,
  onRowClick,
  bodyFooter,
  hideEmptyMessage,
  headerWithSubs,
  stickyHeader,
  collapsedComponent,
  tableClassName,
  collapseLabel,
  startCollapsed,
  rootClassName,
  stickyAmmount,
}) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const [collapsedRows, setCollapsedRows] = useState({});
  const [collapseAllRows, setCollapseAllRows] = useState(startCollapsed || false);

  useEffect(() => {
    if (!collapseAllRows) {
      setCollapsedRows({});
      return;
    }

    let collapsed = [];
    data.map((d) => {
      return (collapsed[d.id] = true);
    });
    setCollapsedRows(collapsed);
  }, [data, collapseAllRows]);

  return (
    <div className={"simple-table-sort " + (rootClassName ? rootClassName : "")}>
      <TableContainer ref={tableRef} className={className}>
        <Table stickyHeader={!!stickyHeader} className={tableClassName}>
          <TableHead>
            <TableRow>
              {(columns || []).map((column, index) => {
                const collapseAll = column.singleCollapse;
                const collapseClassName = !collapseAll ? "collapse-control" : "single-collapse-control";
                const stickyAmmountClassName = !!stickyAmmount && index < stickyAmmount ? " sticky-column" : "";
                if (column.sortable && !disableSort) {
                  return (
                    <TableCell
                      key={index}
                      className={`sortable ${column.headerClassName || ""} ${headerWithSubs ? "with-subs" : ""}`}
                      onClick={() => (onSort ? onSort(column.sortField || column.field) : null)}
                    >
                      {collapseAll && (
                        <div className={collapseClassName + stickyAmmountClassName}>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              setCollapseAllRows(!collapseAllRows);
                              e.stopPropagation();
                            }}
                          >
                            {!!collapseAllRows && <KeyboardArrowDownIcon />}
                            {!collapseAllRows && <KeyboardArrowUpIcon />}
                          </IconButton>
                        </div>
                      )}
                      {headerWithSubs ? (
                        <div className="with-subs-cell">
                          <p className="title">{column.headerText || ""}</p>
                          <p className="sub">{column.headerSub || ""}</p>
                        </div>
                      ) : (
                        column.headerText || ""
                      )}
                      {(sortValue || {}).field === column.sortField || (sortValue || {}).field === column.field ? (
                        sortValue.desc ? (
                          <ArrowUpwardOutlinedIcon />
                        ) : (
                          <ArrowDownwardOutlinedIcon />
                        )
                      ) : null}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={index}
                      style={{ minWidth: column.minWidth, width: column.width }}
                      className={
                        `${column.headerClassName || ""} ${headerWithSubs ? "with-subs" : ""}` + stickyAmmountClassName
                      }
                    >
                      {collapseAll && (
                        <div className={collapseClassName}>
                          <IconButton size="small" onClick={() => setCollapseAllRows(!collapseAllRows)}>
                            {!!collapseAllRows && <KeyboardArrowDownIcon />}
                            {!collapseAllRows && <KeyboardArrowUpIcon />}
                          </IconButton>
                        </div>
                      )}
                      {headerWithSubs ? (
                        <div className="with-subs-cell">
                          <p>{column.headerText || ""}</p>
                          <p className="sub">{column.headerSub || ""}</p>
                        </div>
                      ) : (
                        column.headerText || ""
                      )}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!bodyHeader && (
              <TableRow className="body-header-row">
                <TableCell colSpan={columns.length}>{bodyHeader}</TableCell>
              </TableRow>
            )}
            {(data || []).map((row, index) => {
              let childRows = [];
              if (childrenRecordsName && row && row[childrenRecordsName]) {
                childRows = row[childrenRecordsName];
              }

              const rowBorderClassName = childRows.length > 1 && !collapsedRows[row.id] ? "no-bottom-border " : "";
              const currentCollapseOpen = row?.UID === passThrough?.editRow?.UID;
              const firstChildColumnIndex = columns.findIndex((item) => item.childRecord);
              const classNameTableBody =
                rowBorderClassName +
                ("function" === typeof rowClassName ? rowClassName(row, index) : rowClassName || "") +
                (collapsedComponent && currentCollapseOpen ? "collapse-open" : "");

              return (
                <React.Fragment key={index}>
                  <TableRow
                    key={index + "_" + 0}
                    className={classNameTableBody}
                    onClick={onRowClick ? (e) => onRowClick(row, e) : undefined}
                  >
                    {(columns || []).map((column, columnIndex) => {
                      const singleCollapse = column.singleCollapse;
                      const childRow = childRows.length === 1 ? childRows[0] || {} : {};
                      const withCollapseControl = firstChildColumnIndex - 1 === columnIndex && childRows.length > 1;
                      const withCollapseLabel = firstChildColumnIndex === columnIndex && childRows.length > 1;
                      let classNameTableRow =
                        (withCollapseControl ? "with-collapse-control " : "") +
                        (withCollapseLabel ? "with-collapse-label " : "");
                      classNameTableRow +=
                        "function" === typeof column.className ? column.className(row, index) : column.className || "";
                      classNameTableRow += !!stickyAmmount && columnIndex < stickyAmmount ? " sticky-column" : "";

                      return (
                        <TableCell key={columnIndex} className={classNameTableRow}>
                          {(singleCollapse || withCollapseControl) &&
                            !!row.children &&
                            row.children.length > 1 &&
                            columnIndex === 0 && (
                              <div className={!singleCollapse ? "collapse-control" : "single-collapse-control"}>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    row.id &&
                                    setCollapsedRows({
                                      ...collapsedRows,
                                      [row.id]: !collapsedRows[row.id],
                                    })
                                  }
                                >
                                  {!!collapsedRows[row.id] && <KeyboardArrowDownIcon />}
                                  {!collapsedRows[row.id] && <KeyboardArrowUpIcon />}
                                </IconButton>
                              </div>
                            )}
                          {(singleCollapse || withCollapseControl) &&
                            !!row.children &&
                            row.children.length <= 1 &&
                            columnIndex === 0 && <div className="collapse-button-compensation">&nbsp;</div>}
                          {!column.childRecord &&
                            (column.template
                              ? column.template(row, column.field, row, passThrough)
                              : row[column.field] || "")}
                          {!!column.childRecord &&
                            (column.template
                              ? column.template(childRow, column.field, row, passThrough)
                              : childRow[column.field] || "")}
                          {!singleCollapse && !!withCollapseLabel && !!collapsedRows[row.id] && (
                            <div className="collapse-label">
                              <Button
                                size="small"
                                onClick={() =>
                                  row.id &&
                                  setCollapsedRows({
                                    ...collapsedRows,
                                    [row.id]: false,
                                  })
                                }
                              >
                                {collapseLabel || t("main.collapsedMultipleRecords")}
                              </Button>
                            </div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {!collapsedRows[row.id] &&
                    childRows.length > 1 &&
                    childRows.map((childRow, childIndex) => {
                      const rowBottonBorderClassName = childIndex + 1 < childRows.length ? "no-bottom-border " : "";
                      const classNameChildRow =
                        "child-row " +
                        rowBottonBorderClassName +
                        ("function" === typeof rowClassName ? rowClassName(childRow, index) : rowClassName || "");

                      return (
                        <TableRow key={index + "_" + childIndex} className={classNameChildRow}>
                          {(columns || []).map((column, columnIndex) => (
                            <TableCell
                              key={columnIndex}
                              className={
                                "function" === typeof column.className
                                  ? column.className(childRow, index)
                                  : column.className || ""
                              }
                            >
                              {!!column.childRecord &&
                                (column.template
                                  ? column.template(childRow, column.field, row)
                                  : childRow[column.field] || "")}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  {!!collapsedComponent && (
                    <TableRow key={index + "_collapse_" + 0} className="collapse-row">
                      <TableCell style={{ paddingBottom: "0", paddingTop: 0, height: "max-content" }} colSpan={20}>
                        <Collapse in={currentCollapseOpen} unmountOnExit>
                          {collapsedComponent}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
            {(data || []).length === 0 && !hideEmptyMessage && (
              <TableRow key={-1}>
                <TableCell key={-1} colSpan={(columns || []).length || 1} className="empty-cell">
                  {emptyText || t("main.empty")}
                </TableCell>
              </TableRow>
            )}
            {!!bodyFooter && (
              <TableRow className="body-footer-row">
                <TableCell colSpan={columns.length}>{bodyFooter}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!!pagination && (pagination.show === "true" || pagination.show === undefined) && (
        <TablePagination component="div" {...pagination} />
      )}
    </div>
  );
};
