import { PropTypes } from "prop-types";
import React from "react";
import { useParams } from "react-router";
import { TENANT_TYPE_ESG, TENANT_TYPE_PAST } from "../../constants/tenant";
import { getUserTenantType } from "../../hooks/auth";
import { NewRatingSystemESG } from "./NewRatingSystemESG";
import "./RatingSystem.scss";
import { RatingSystemESG } from "./RatingSystemESG";
import { RatingSystemPAST } from "./RatingSystemPAST";

export const RatingSystem = ({ analyze, old }) => {
  const tenantType = getUserTenantType();
  const { ratingSystemId, groupId } = useParams();

  console.log(tenantType);
  switch (tenantType) {
    case TENANT_TYPE_ESG:
      return old ? (
        <RatingSystemESG groupId={groupId} ratingSystemId={ratingSystemId} analyze={analyze} />
      ) : (
        <NewRatingSystemESG groupId={groupId} ratingSystemId={ratingSystemId} analyze={analyze} />
      );
    case TENANT_TYPE_PAST:
      return <RatingSystemPAST groupId={groupId} ratingSystemId={ratingSystemId} analyze={analyze} />;
    default:
      return <NewRatingSystemESG />;
  }
};

RatingSystem.propTypes = {
  analyze: PropTypes.bool,
  old: PropTypes.bool,
};
