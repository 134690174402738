import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { FormController } from "../FormController/FormController";

export const ComboFieldController = ({ control, name, required, label, readOnly, options }) => {
  const { t } = useTranslation();

  const valuesList = (options || []).map(({ name: nameInner }) => nameInner);

  return (
    <FormController
      control={control}
      name={name}
      required={required}
      label={label}
      render={({ field, fieldState, label: labelInner }) => (
        <Autocomplete
          {...field}
          fullWidth
          clearOnEscape={!required}
          noOptionsText={t("main.empty")}
          options={valuesList}
          clearOnBlur={!required}
          freeSolo
          value={field.value || ""}
          onInputChange={(event, value) => {
            field.onChange(value);
          }}
          disabled={readOnly}
          onBlur={(event) => {
            field.onChange(event.target.value);
          }}
          onChange={(event, value) => {
            field.onChange(value);
          }}
          renderInput={(params) => (
            <TextField error={fieldState.invalid} {...params} label={labelInner + (required ? " *" : "")} />
          )}
          renderTags={(option) => <Typography noWrap>{option || ""}</Typography>}
          getOptionLabel={(option) => option || ""}
        />
      )}
    />
  );
};
