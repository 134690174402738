export const MenuIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="icon-menu" transform="translate(-2 -3.5)">
      <rect
        id="Rectangle_103"
        data-name="Rectangle 103"
        width="16"
        height="16"
        transform="translate(2 3.5)"
        opacity="0"
      />
      <g id="Group_121" data-name="Group 121" transform="translate(0 -0.171)">
        <circle id="Ellipse_16" data-name="Ellipse 16" cx="2" cy="2" r="2" transform="translate(8 3.671)" />
        <circle id="Ellipse_17" data-name="Ellipse 17" cx="2" cy="2" r="2" transform="translate(8 15.671)" />
        <circle id="Ellipse_18" data-name="Ellipse 18" cx="2" cy="2" r="2" transform="translate(8 9.671)" />
      </g>
    </g>
  </svg>
);
