import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../../constants/main";
import { PERMISSION_PROJECT_READ } from "../../../constants/permissions";
import { TENANT_TYPE_PAST } from "../../../constants/tenant";
import { hasPermission } from "../../../helpers/permission";
import { getUserTenantType } from "../../../hooks/auth";
import {
  resetProjectGroupsProjectsState,
  searchProjectGroupProjects,
  useProjectGroupProjectsLogic,
  useProjectGroupProjectsPageResponse,
} from "../../../hooks/project";
import {
  removeProjectGroupProject,
  setProjectGroupProjectLocationsPopover,
  useProjectGroupProjectRemoveResponse,
} from "../../../hooks/projectGroup";
import { showError } from "../../../hooks/toast";
import { useTimeout } from "../../../hooks/utils/useTimeout";
import { LanSVG } from "../../../svg";
import { processMessage } from "../../../utils";
import { DateColumnTemplate } from "../../ColumnTemplate/DateColumnTemplate";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import {
  RatingSuperScoreColumnTemplate,
  RatingSystemScoreColumnTemplate,
  StatusColumnTemplate,
} from "../../Project/ProjectTemplates/ProjectTemplates";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import { ProjectGroupProjectsLocationsPopover } from "../ProjectGroupProjectsLocationsPopover/ProjectGroupProjectsLocationsPopover";
import { ProjectActionsColumnTemplate } from "../ProjectGroupTemplates";
import "./ProjectGroupProjects.scss";

export const RatingSystemNameColumnTemplate = (childRow) =>
  childRow &&
  `${childRow.systemSource ? childRow.systemSource : ""} ${childRow.name ? childRow.name : ""} ${
    childRow.systemVersion ? childRow.systemVersion : ""
  }`;

export const ProjectGroupProjects = ({
  selectedProjectGroupId,
  projectGroupId,
  readOnly,
  withSearch,
  withTooltip,
  analyze,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const tenantType = getUserTenantType();

  const [processed, setProcessed] = useState([]);
  const [total, setTotal] = useState(0);
  const [init, setInit] = useState(false);

  const [removeProjectConfirmation, setRemoveProjectConfirmation] = useState(null);
  const { page, setPage, rowsPerPage, setRowsPerPage, searchValue, setSearchValue, resetState } =
    useProjectGroupProjectsLogic();
  const [, setSearchTimeout] = useTimeout();

  const projectGroupProjectsPageResponse = useProjectGroupProjectsPageResponse();
  const projectGroupProjectRemoveResponse = useProjectGroupProjectRemoveResponse();

  useEffect(() => {
    resetProjectGroupsProjectsState();
  }, []);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    } else {
      load();
    }
  }, [selectedProjectGroupId, searchValue, rowsPerPage]);

  useEffect(() => {
    if (init) {
      load();
    }
    setInit(true);
  }, [page]);

  useEffect(() => {
    if (!projectGroupProjectsPageResponse.loading) {
      const responseData = projectGroupProjectsPageResponse.data || {};
      const projects = responseData.content || [];
      const totalValue = (responseData.meta || {}).total || 0;
      setProcessed(
        projects.map((item) => ({
          ...item,
          children: [...(item.ratingSystems || []), ...(item.kpiRatingSystems || [])],
        }))
      );
      setTotal(totalValue);
    }
  }, [projectGroupProjectsPageResponse.loading, projectGroupProjectsPageResponse.data]);

  const RatingSystemColumnTemplate = useCallback(
    (props) => {
      let link = "/projectGroup/" + (analyze ? "analyze/" : "") + projectGroupId;
      if (props?.kpiSystemId) {
        link += "/project/kpiRatingSystem/" + props?.id;
      } else {
        link += "/project/ratingSystem/" + props?.id;
      }
      return hasPermission(PERMISSION_PROJECT_READ) ? (
        <NavLink to={link}> {RatingSystemNameColumnTemplate(props)} </NavLink>
      ) : (
        RatingSystemNameColumnTemplate(props)
      );
    },
    [projectGroupId]
  );

  const columns = useMemo(() => {
    let items;

    if (tenantType === TENANT_TYPE_PAST) {
      items = [
        {
          field: "name",
          headerText: t("projects.project"),
          className: "name-column",
          sortable: false,
          template: (props, field, { id, name }) =>
            hasPermission(PERMISSION_PROJECT_READ) ? (
              <NavLink to={"/projectGroup/" + (analyze ? "analyze/" : "") + projectGroupId + "/project/" + id}>
                {name}
              </NavLink>
            ) : (
              name
            ),
        },
        {
          field: "clientName",
          className: "text-center",
          headerClassName: "text-center",
          headerText: t("projects.clientName"),
        },
        {
          field: "systemSource",
          headerText: t("projects.ratingSystem"),
          childRecord: true,
          template: RatingSystemColumnTemplate,
        },
        {
          field: "score",
          className: "text-right nowrap",
          headerClassName: "text-right",
          headerText: t("projects.pastScore"),
          childRecord: true,
        },
      ];
    } else {
      items = [
        {
          field: "name",
          headerText: t("projects.project"),
          headerClassName: "name-column",
          sortable: false,
          template: (props, field, { id, name }) =>
            hasPermission(PERMISSION_PROJECT_READ) ? (
              <NavLink to={"/projectGroup/" + (analyze ? "analyze/" : "") + projectGroupId + "/project/" + id}>
                {name}
              </NavLink>
            ) : (
              name
            ),
        },
        {
          field: "clientName",
          className: "text-center",
          headerClassName: "text-center",
          headerText: t("projects.clientName"),
        },
        {
          field: "systemSource",
          headerText: t("projects.ratingSystem"),
          childRecord: true,
          template: RatingSystemColumnTemplate,
        },
        {
          field: "score",
          className: "system-score-column nowrap",
          headerClassName: "text-right",
          headerText: t("projects.score"),
          childRecord: true,
          template: RatingSystemScoreColumnTemplate,
        },
        {
          field: "superScore",
          className: "text-right nowrap",
          headerClassName: "text-right",
          headerText: t("projects.superScore"),
          childRecord: true,
          template: RatingSuperScoreColumnTemplate,
        },
      ];
    }

    if (withTooltip) {
      items = [
        {
          className: "tooltip-column",
          template: (row) => (
            <IconButton
              size="small"
              onClick={(event) =>
                setProjectGroupProjectLocationsPopover({
                  data: row,
                  target: event.currentTarget,
                })
              }
            >
              <LanSVG />
            </IconButton>
          ),
        },
        ...items,
      ];
    }

    items = [
      ...items,
      ...[
        {
          field: "ratingStatus",
          className: "status-cell",
          headerText: t("projects.status"),
          childRecord: true,
          template: (props) => StatusColumnTemplate(props, t),
        },
        {
          field: "modifiedDateTime",
          className: "status-cell",
          headerText: t("projects.lastUpdate"),
          childRecord: true,
          template: DateColumnTemplate,
        },
      ],
    ];

    if (!readOnly) {
      items = [
        ...items,
        {
          className: "action-cell text-right",
          headerClassName: "action-header-cell",
          headerText: " ",
          childRecord: true,
          template: (props, field, rootProps) =>
            ProjectActionsColumnTemplate({
              props,
              rootProps,
              setRemoveProjectConfirmation,
            }),
        },
      ];
    }

    return items;
  }, [tenantType, readOnly, language]);

  const removeProjectHandler = () => {
    removeProjectGroupProject(selectedProjectGroupId, removeProjectConfirmation)
      .then(() => {
        setRemoveProjectConfirmation(null);
        setPage(0);
        setTimeout(() => load(), 0);
      })
      .catch(console.error);
  };

  const search = (value) => {
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(value || "").trim());
      }, 500)
    );
  };

  const load = () =>
    searchProjectGroupProjects({
      groupId: selectedProjectGroupId,
      page: page + 1,
      itemsPerPage: rowsPerPage,
      search: searchValue,
    }).catch((error) => {
      console.error(error);
      showError(t("project.error.loadList"));
    });

  const pagination = {
    labelRowsPerPage: t("projects.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: total,
    page: page,
    show: total > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pageOnChange) => setPage(pageOnChange),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const loading = projectGroupProjectsPageResponse.loading || projectGroupProjectRemoveResponse.loading;

  return (
    <div className="project-group-projects">
      {!withSearch && (
        <div className="flex-row">
          <h2>{t("projectGroup.hierarchyBlock.allProjects")}</h2>
          {!!total && <div className="flex-auto items-amount">{total}</div>}
        </div>
      )}
      {!!withSearch && (
        <div className="search-box">
          <SearchIcon />
          <input
            type="text"
            key={resetState}
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            placeholder={t("main.search")}
            onChange={(event) => search(event.target.value)}
          />
        </div>
      )}

      <LoadingOverlay spinner className="table-loading auto-height" active={loading}>
        <SimpleTableWithSort
          className="repaint"
          data={processed}
          childrenRecordsName="children"
          columns={columns}
          pagination={pagination}
          collapseLabel={t("project.collapsedMultipleRecords")}
        />
      </LoadingOverlay>

      <ConfirmationDialog
        open={!!removeProjectConfirmation}
        onClose={() => setRemoveProjectConfirmation(null)}
        onConfirm={removeProjectHandler}
        titleText={t("projectGroup.projects.removeConfirmationTitle")}
        bodyText={t("projectGroup.projects.removeConfirmation")}
        confirmText={t("main.remove")}
        color="secondary"
      />
      {!!withTooltip && <ProjectGroupProjectsLocationsPopover projectGroupId={projectGroupId} />}
    </div>
  );
};
