import React from "react";
import "./ProgressSquare.scss";

export const ProgressSquare = ({ value, rangeColors }) => {
  let color;
  if (value !== undefined) {
    rangeColors?.forEach((item) => {
      if (!color || (item.from !== undefined && item.from <= value) || (item.to !== undefined && item.to > value)) {
        color = item.color;
      }
    });
  }

  return <div className="progress-square k-m-auto" style={{ backgroundColor: color }} />;
};
