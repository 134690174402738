import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, load, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import weekData from 'cldr-core/supplemental/weekData.json';
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from "cldr-dates-full/main/de/dateFields.json";
import deNumbers from "cldr-numbers-full/main/de/numbers.json";
import React, { useEffect, useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BENCHMARK_UNITS, BENCHMARK_UNITS_MAP } from "../../../constants/unit";
import { useKPIListResponse } from "../../../hooks/kpi";
import { ContentBlock } from "../../ContentBlock/ContentBlock";
import { DynamicFormController } from "../../DynamicFormController/DynamicFormController";
import { GoalsDialogCalendar } from "../../RatingSystem/RatingSystemGoals/GoalsDialog/GoalsDialogCalendar";
import kendoDeMessages from './../../../locales/kendo-de.json';
import "./BenchmarkGoals.scss";

load(
  deNumbers,
  deDateFields,
  deCaGregorian,
  weekData
)

loadMessages(kendoDeMessages, 'de');

const UNIT_OPTIONS = BENCHMARK_UNITS.map((item) => ({ value: item, label: item }));

export const BenchmarkGoals = ({ readOnly, control, getValues, setValue, submitted }) => {
  const { t, i18n } = useTranslation();
  const kpiListResponse = useKPIListResponse();
  const kpiList = useMemo(
    () =>
      (kpiListResponse.data || []).map((item) => ({
        data: { name: item.name, unit: item.unit },
        text: `${item.name} (${item.unit})`,
      })),
    [kpiListResponse.data]
  );
  const [selectedKpi, setSelectedKpi] = useState(null);
  const { kpiUnit, kpiName, unit } = getValues();
  const { fields, append, remove } = useFieldArray({ control, name: "goals" });
  const [selectedUnit, setSelectedUnit] = useState(null);

  const [optionsKpis, preSelectedKpi, optionsLoaded] = useMemo(() => {
    const optionsKpisInner = selectedUnit
      ? kpiList.filter((item) => item.data.unit === BENCHMARK_UNITS_MAP[selectedUnit.value])
      : [];
    const preSelectedKpiInner = optionsKpisInner.find(
      (item) => item.data?.name === kpiName && item.data?.unit === kpiUnit
    );
    return [optionsKpisInner, preSelectedKpiInner, !!selectedUnit && !!kpiList.length];
  }, [kpiList, selectedUnit, kpiName, kpiUnit]);

  useEffect(() => {
    const selectedUnitInner = UNIT_OPTIONS.find((item) => item.value === unit);
    setSelectedUnit(selectedUnitInner);
  }, [unit]);

  useEffect(() => {
    if (optionsLoaded && !selectedKpi) {
      setSelectedKpi(preSelectedKpi);
      if (!preSelectedKpi && kpiUnit && kpiName) {
        setValue("kpiUnit", null);
        setValue("kpiName", null);
      }
    }
  }, [optionsLoaded, preSelectedKpi, kpiUnit, kpiName, selectedKpi]);

  return (
    <ContentBlock className="benchmark-goals">
      <Grid container spacing={1}>
        <Grid xs={6} item>
          <Autocomplete
            size="small"
            className={readOnly ? "read-only" : ""}
            loading={UNIT_OPTIONS.loading}
            key={UNIT_OPTIONS.loading}
            options={UNIT_OPTIONS}
            value={selectedUnit || null}
            disabled={readOnly}
            getOptionLabel={(value) => value.label || ""}
            renderInput={(params) => (
              <TextField {...params} label={t("benchmark.selectUnit") + " *"} error={!selectedUnit && submitted} />
            )}
            onChange={(event, value) => {
              setValue("unit", value?.value || "");
              setSelectedUnit(value);
              const optionsKpisOnChange = kpiList.filter((item) => item.data.unit === value.value);
              if (optionsKpisOnChange.length === 1) {
                const option = optionsKpisOnChange[0];
                setValue("kpiUnit", option?.data?.unit || "");
                setValue("kpiName", option?.data?.name || "");
              } else {
                setValue("kpiUnit", null);
                setValue("kpiName", null);
              }
            }}
          />
        </Grid>
        <Grid xs={6} item>
          <Autocomplete
            size="small"
            className={readOnly ? "read-only" : ""}
            loading={kpiListResponse.loading}
            key={kpiListResponse.loading}
            options={optionsKpis}
            value={selectedKpi || null}
            disabled={readOnly}
            disableClearable
            getOptionLabel={(value) => value?.text || ""}
            renderInput={(params) => (
              <TextField {...params} label={t("benchmark.selectKpi") + " *"} error={!selectedKpi && submitted} />
            )}
            onChange={(event, value) => {
              setValue("kpiUnit", value?.data?.unit || "");
              setValue("kpiName", value?.data?.name || "");
              setSelectedKpi(value);
            }}
          />
        </Grid>
      </Grid>
      <div className="divider">
        <div className="header-wert">{t("benchmark.value")}</div>
        <div className="header-date">{t("benchmark.date")}</div>
      </div>
      {fields.map((item, index) => (
        <React.Fragment key={item.id}>
          <Grid container spacing={1}>
            <Grid xs={6} item>
              <div className="field-wrapper">
                <DynamicFormController
                  control={control}
                  name={`goals.${index}.value`}
                  required
                  label={index === 0 ? t("benchmark.baseline") : t("benchmark.value")}
                  defaultValue={item.value}
                  render={({ field, fieldState, label }) => (
                    <TextField
                      error={fieldState.invalid}
                      fullWidth
                      inputProps={{ maxLength: 50 }}
                      type="number"
                      label={label + " *"}
                      {...field}
                      value={field.value}
                      disabled={readOnly}
                      className={"number-no-appearance" + (readOnly ? " read-only" : "")}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                      }}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid xs={5} item>
              <div className="field-wrapper">
                <DynamicFormController
                  control={control}
                  name={`goals.${index}.date`}
                  required
                  label={t("benchmark.date")}
                  defaultValue={item?.date}
                  render={({ field, fieldState, label }) => (
                    <LocalizationProvider language={i18n.language}>
                      <IntlProvider locale={i18n.language}>
                        <DatePicker
                          className={readOnly ? " read-only goals-datepicker" : "goals-datepicker"}
                          disabled={readOnly}
                          valid={!fieldState.invalid}
                          fillMode="flat"
                          format="yyyy"
                          calendar={GoalsDialogCalendar}
                          label={label + " *"}
                          placeholder=""
                          {...field}
                          value={field.value? new Date(field?.value) : null}
                          ref={null}
                        />
                      </IntlProvider>
                    </LocalizationProvider>
                  )}
                />
              </div>
            </Grid>
            {index > 1 && (
              <Grid xs={1} item className="centered">
                {!readOnly && (
                  <IconButton size="small" onClick={() => remove(index)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              </Grid>
            )}
          </Grid>
          {(!readOnly || index !== fields.length - 1) && <div className="divider" />}
        </React.Fragment>
      ))}
      {!readOnly && (
        <div className="buttons">
          <Button onClick={() => append({ value: "", date: null })}>
            <span className="value-button">
              <AddIcon /> {t("benchmark.addValue")}
            </span>
          </Button>
        </div>
      )}
    </ContentBlock>
  );
};
