import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { PromiseSubjectState } from "react-rxjs-easy";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { PermissionsDialog } from "../../components/PermissionsDialog/PermissionsDialog";
import { LeadSelect } from "../../components/Project/LeadSelect/LeadSelect";
import { ProjectCustomFields } from "../../components/Project/ProjectCustomFields/ProjectCustomFields";
import { ProjectDescriptionField } from "../../components/Project/ProjectFields/ProjectDescriptionField";
import { ProjectIdField } from "../../components/Project/ProjectFields/ProjectIdField";
import { ProjectNameField } from "../../components/Project/ProjectFields/ProjectNameField";
import { ProjectFiles } from "../../components/Project/ProjectFiles/ProjectFiles";
import { ProjectImages } from "../../components/Project/ProjectImages/ProjectImages";
import { ProjectRatingSystems } from "../../components/Project/ProjectRatingSystems/ProjectRatingSystems";
import { ProjectTypeSelect } from "../../components/SustainabilitySystem/SustainabilitySystemFields/ProjectTypeSelect/ProjectTypeSelect";
import { ESG_TYPES, PROJECT_PERMISSION } from "../../constants/project";
import { OTHER_TYPES } from "../../constants/sustainabilitySystem";
import { TENANT_TYPE_ESG } from "../../constants/tenant";
import { mergeProject } from "../../helpers/merge/project";
import { canUpload, ownerCheck } from "../../helpers/project";
import { getUserTenantType, useUser } from "../../hooks/auth";
import { showMuiDialog } from "../../hooks/dialog";
import { resetUploadingFiles, useUploadingFiles } from "../../hooks/fileUpload";
import {
  createProject,
  getProject,
  getProjectAttributeList,
  getProjectAttributeListSubject,
  getProjectAvailableUsers,
  getProjectPermissions,
  protectProject,
  unprotectProject,
  updateProject,
  useProjectAttributeListResponse,
  useProjectAvailableUsersResponse,
  useProjectCreateResponse,
  useProjectPermissionsGetResponse,
  useProjectProtectChangeResponse,
  useProjectResponse,
  useProjectUpdateResponse,
} from "../../hooks/project";
import { showError, showSuccess, showWarning } from "../../hooks/toast";
import { useProjectPermissions } from "../../hooks/utils/usePagePermissions";
import { CloseSVG, SaveSVG } from "../../svg";
import { processMessage } from "../../utils";
import "./ProjectEdit.scss";
import { createProjectRequestEmptyStringToNull, getFormDefaultValues } from "./projectUtils";

export const ProjectEditPage = () => {
  const { projectId } = useParams();
  const response = useProjectResponse();
  const [project, setProject] = useState({});
  const permissions = project.projectPermission ?? {};

  useProjectPermissions(permissions, PROJECT_PERMISSION.MEMBER, response.loading);

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const navigate = useNavigate();
  const tenantType = getUserTenantType();

  const { user } = useUser();
  const projectOwner = (response.data && response.data.owner) || "";
  const projectAvailableUsersResponse = useProjectAvailableUsersResponse();
  const projectAvailableUsers = projectAvailableUsersResponse.data || [];
  const projectAttributeListResponse = useProjectAttributeListResponse();
  const projectAttributes = projectAttributeListResponse.data || {};
  const projectPermissionsResponse = useProjectPermissionsGetResponse();
  const projectPermissions = projectPermissionsResponse.data || [];

  const updateResponse = useProjectUpdateResponse();
  const createResponse = useProjectCreateResponse();
  const protectResponse = useProjectProtectChangeResponse();
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const uploadingFiles = useUploadingFiles();
  const [saveMerged, setSaveMerged] = useState(false);

  const isOwner = ownerCheck(project, permissions, user.userUuid);

  const defaultValues = getFormDefaultValues();
  const { control, handleSubmit, reset, setValue, getValues } = useForm({ defaultValues });

  const editDisabled = !permissions;

  const projectTypes = getValues("projectTypes");
  useEffect(() => {
    if (projectTypes[0] && !editDisabled) {
      getProjectAttributeList(projectTypes[0]);
    }
    if (!projectTypes[0]) {
      getProjectAttributeListSubject.next(new PromiseSubjectState());
    }
  }, [projectTypes[0], editDisabled]);

  useEffect(() => {
    if (projectId) {
      if (response.data && String(response.data.id) === projectId) {
        resetData(response.data);
      }
      load();
      resetUploadingFiles();
    }
  }, [projectId]);

  useEffect(() => {
    const { projectLead } = getValues();

    if (projectLead && !projectPermissionsResponse.loading && projectPermissionsResponse.data) {
      if (!projectPermissions.some((item) => item.userUuid === project.projectLead && item.permissionOwner)) {
        setValue("projectLead", "");
      }
    }
  }, [projectPermissionsResponse.loading, project.id]);

  useEffect(() => {
    if (project.id) {
      getProjectPermissions(project.id);
    }
  }, [project.id]);

  useEffect(() => {
    if (project.id && isOwner) {
      getProjectAvailableUsers();
    }
  }, [project.id, isOwner]);

  useEffect(() => {
    if (!projectId && projectAttributes?.fields?.length) {
      const values = {};
      projectAttributes.fields.forEach(({ name, details }) => {
        if (details?.defaultValue) {
          values[name] = details.defaultValue;
        }
      });
      reset({ ...getValues(), customFields: values });
    }
  }, [projectAttributes?.fields]);

  const goToDetails = (id) => navigate(`/project/${id || projectId}`);
  const goToList = () => navigate("/project");

  useEffect(() => {
    if (saveMerged) {
      try {
        setSaveMerged(false);
        onSubmit(getValues());
      } catch (e) {
        showError(t("error.500"));
      }
    }
  }, [saveMerged]);

  const mergeAndSave = async (updated) => {
    try {
      const current = await getProject(projectId);
      const [merged, withConflicts] = mergeProject(project, current, updated);
      if (withConflicts) {
        showWarning(processMessage(t("main.savedWithConflicts"), [current?.modifier]));
      }
      resetData(merged);
      setSaveMerged(true);
    } catch (e) {
      showError(t("error.500"));
    }
  };

  const load = () => {
    getProject(projectId)
      .then(resetData)
      .catch(() => {
        showError(t("project.error.load"));
        goToList();
      });
  };

  const onSubmit = async (values) => {
    let updated;
    try {
      const customFields = values?.customFields || {};
      Object.keys(customFields).forEach((key) => {
        if ("object" === typeof customFields[key]) {
          const obj = customFields[key];
          delete customFields[key];
          Object.keys(obj).forEach((key2) => {
            customFields[key + "__" + key2] = obj[key2];
          });
        }
      });
      updated = {
        ...values,
        pictureIds: images,
        projectFiles: files,
        ratingSystems: undefined,
        modifiedDateTime: project?.modifiedDateTime,
      };
      if (!projectId) {
        createProjectRequestEmptyStringToNull(updated);
        const data = await createProject(updated);
        showSuccess(t("project.createdMessage"));
        goToDetails(data.id);
      } else {
        await updateProject(projectId, updated);
        goToDetails();
        showSuccess(t("project.updatedMessage"));
      }
    } catch (error) {
      if (error.status === 409) {
        error.json().then((parsedBody) => {
          if (parsedBody?.message === "error.optimistic.lock") {
            mergeAndSave(updated);
          }
        }, console.error);
      } else {
        showError(t("error.500"));
      }
    }
  };

  const resetData = (values) => {
    setProject(values);
    const data = values || response.data || {};
    const customFields = data.customFields || {};
    const combined = {};
    Object.keys(customFields).forEach((key) => {
      if (key.indexOf("__") !== -1) {
        const [name, subField] = key.split("__");
        if (!combined[name]) {
          combined[name] = {};
        }
        combined[name][subField] = customFields[key];
        delete customFields[key];
      }
    });
    reset({
      ...data,
      projectLead: data?.projectLead || "",
      customFields: { ...customFields, ...combined },
      pictureIds: undefined,
    });
    setImages([...data.pictureIds]);
    setFiles([...data.projectFiles]);
  };

  const toggleAccessRestriction = () => {
    if (project.accessRestricted) {
      unprotectProject(project.id).then(load).catch(console.error);
    } else {
      protectProject(project.id).then(load).catch(console.error);
    }
  };

  const showPermissionsModal = () =>
    showMuiDialog((props) => (
      <PermissionsDialog
        projectId={project.id}
        data={projectPermissions.filter((p) => p.userUuid !== projectOwner)}
        projectAvailableUsers={projectAvailableUsers.filter((p) => p.userUuid !== projectOwner)}
        onChange={() => {
          props.onClose();
          getProjectPermissions(project.id);
        }}
        {...props}
      />
    ));

  const pathParts = [
    { url: "/project", text: t("menu.project.title") },
    { url: projectId ? `/project/${projectId}` : undefined, text: projectId ? project.name : t("menu.project.create") },
  ];

  if (projectId) {
    pathParts.push({ text: t("menu.project.edit") });
  }

  const canUploadToProject = editDisabled || !canUpload(permissions);

  const loading =
    response.loading ||
    updateResponse.loading ||
    createResponse.loading ||
    protectResponse.loading ||
    projectAttributeListResponse.loading ||
    projectPermissionsResponse.loading ||
    projectAvailableUsersResponse.loading;

  return (
    <LoadingOverlay spinner active={loading} className="project-edit-page">
      <form autoComplete={"off"}>
        <div className="page-header">
          <div className="flex-row">
            <Breadcrumb pathParts={pathParts} />
            {isOwner && !!projectId && (
              <div className="flex-auto text-right">
                <Button color="primary" className="right-header-button" onClick={showPermissionsModal}>
                  <GroupOutlinedIcon />
                  {t("project.permissions")}
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="page-layout">
          <div className="flex-row">
            <div className="content-block k-flex-1">
              <div className="flex-row w-100">
                <h2 className="flex-auto">{t("project.step.baseData")}</h2>
                {isOwner && !!projectId && (
                  <div>
                    <Button className="access-toggle-button" onClick={toggleAccessRestriction}>
                      {t(project.accessRestricted ? "project.accessRestricted" : "project.accessNotRestricted")}
                      {project.accessRestricted ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
                    </Button>
                  </div>
                )}
              </div>

              <ProjectNameField control={control} />
              <ProjectTypeSelect
                control={control}
                types={tenantType === TENANT_TYPE_ESG ? ESG_TYPES : OTHER_TYPES}
                disabled={projectId}
                singleSelect
              />
              <ProjectDescriptionField control={control} />
              <ProjectIdField control={control} name="internalProjectId" />
              <ProjectIdField control={control} name="externalProjectId" />
              <ProjectIdField control={control} name="clientName" maxLength={100} />

              {!!projectId && isOwner && (
                <LeadSelect
                  control={control}
                  projectPermissions={projectPermissions}
                  owner={project.owner}
                  projectAvailableUsers={projectAvailableUsers}
                />
              )}
            </div>

            {!!projectAttributes?.fields?.length && (
              <div className="content-block k-flex-1">
                <h2>{(projectAttributes?.details || {})[language]}</h2>
                <ProjectCustomFields
                  getValues={getValues}
                  namePrefix="customFields"
                  control={control}
                  setValue={setValue}
                  editDisabled={editDisabled}
                  fields={projectAttributes?.fields}
                />
              </div>
            )}
          </div>

          {!!projectId && <ProjectRatingSystems project={project} readOnly={true} />}

          <div className="content-block">
            <h2>{t("project.step.images")}</h2>
            <ProjectImages projectId={projectId} value={images} onChange={setImages} readOnly={canUploadToProject} />
          </div>

          <div className="content-block files-block">
            <h2 className="files-header">{t("project.step.files")}</h2>
            <ProjectFiles
              projectId={projectId}
              value={files}
              onChange={(filesInner) => setFiles([...filesInner])}
              readOnly={canUploadToProject}
            />
          </div>
        </div>

        {!!permissions && (
          <div className="fabs">
            <Fab
              color="secondary"
              onClick={() => {
                resetUploadingFiles();
                projectId ? goToDetails() : goToList();
              }}
            >
              <CloseSVG className="fab-svg" />
            </Fab>
            <Fab color="primary" disabled={uploadingFiles.length} onClick={handleSubmit(onSubmit)}>
              <SaveSVG className="fab-svg" />
            </Fab>
          </div>
        )}
      </form>
    </LoadingOverlay>
  );
};
