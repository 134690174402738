import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useTranslation } from "react-i18next";
import { useKPIListResponse } from "../../../hooks/kpi";
import { findInValue } from "../../../utils";

const filterOption = (option, search) =>
  !search ||
  findInValue(option.name, search) ||
  findInValue(option.unit, search) ||
  !!(option.tags || []).find((item) => findInValue(item, search));

export const KPIColumnTemplate = (row, fieldName, { changeKPI, readOnly }) => {
  const { t } = useTranslation();
  const response = useKPIListResponse();

  if (readOnly) {
    return <div className="kpi-name">{row?.kpiName ? `${row.kpiName} (${row.kpiUnit})` : ""}</div>;
  }

  const options = (response.data || []).map((item) => ({
    name: item.name,
    unit: item.unit,
    description: item.description,
    tags: item.tags,
  }));

  const onChange = (valueOnChange) => changeKPI(row.UID, valueOnChange?.name, valueOnChange?.unit);
  const value = row.kpiName ? { name: row.kpiName, unit: row.kpiUnit } : null;

  return (
    <Autocomplete
      fullWidth
      clearOnEscape
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue ? newValue : null);
      }}
      isOptionEqualToValue={(val1, val2) => val1.name === val2.name && val1.unit === val2.unit}
      loading={response.loading}
      options={options}
      renderTags={(option) => {
        if (!option) {
          return "";
        }
        return (
          <div className="option-with-icon">
            <div className="option-with-icon-title">{option.name + " (" + option.unit + ")"}</div>
            {!!option.description && (
              <Tooltip arrow title={option.description} placement="top">
                <InfoOutlinedIcon />
              </Tooltip>
            )}
          </div>
        );
      }}
      getOptionLabel={(option) => {
        if (!option) {
          return "";
        }
        return option.name + " (" + option.unit + ")";
      }}
      filterOptions={(optionsInner, { inputValue }) => optionsInner.filter((item) => filterOption(item, inputValue))}
      noOptionsText={t("main.empty")}
      renderInput={(params) => <TextField {...params} label="" />}
    />
  );
};
