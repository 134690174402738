export const getFormDefaultValues = () => {
  return {
    name: "",
    description: "",
    projectTypes: [],
    internalProjectId: "",
    externalProjectId: "",
    clientName: "",
    projectLead: ""
  }
};

export const createProjectRequestEmptyStringToNull = (request) => {
  request.buildingYear = request.buildingYear === "" ? null : request.buildingYear;
  request.grossFloorArea = request.grossFloorArea === "" ? null : request.grossFloorArea;
  request.netFloorArea = request.netFloorArea === "" ? null : request.netFloorArea;
  request.energyReferenceArea = request.energyReferenceArea === "" ? null : request.energyReferenceArea;
  request.costReferenceArea = request.costReferenceArea === "" ? null : request.costReferenceArea;
};
