import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FILE_UPLOAD_RESTRICTIONS, MAX_FILE_SIZE_MB } from "../../constants/fileUpload";
import {
  getFileSizeErrors,
  getRestrictedFileExtension,
  getRestrictedFileSize,
  validateFileRestrictions,
} from "../../helpers/fileUpload";
import { cancelUploadingFile, useUploadingFiles } from "../../hooks/fileUpload";
import { showError } from "../../hooks/toast";
import "./FilesList.scss";

import { loadMessages } from "@progress/kendo-react-intl";
import i18next from "i18next";
import kendoDeMessages from "./../../locales/kendo-de.json";
import kendoEnMessages from "./../../locales/kendo-en.json";

loadMessages(kendoEnMessages, "en");
loadMessages(kendoDeMessages, "de");

export const FilesList = React.memo(
  ({ files, readOnly, addFile, removeFile, uploadFile, getFilePath, restrictions = FILE_UPLOAD_RESTRICTIONS }) => {
    const { t } = useTranslation();
    const uploadInputRef = useRef(null);
    const uploadingFiles = useUploadingFiles();
    const messages = i18next.language === "de" ? kendoDeMessages : kendoEnMessages;
    const [fileValidationError, setFileValidationError] = useState(false);

    const onFileSelect = (event) => {
      const errors = getFileSizeErrors(event, MAX_FILE_SIZE_MB);
      if (!errors) {
        [...event.target.files].forEach((file) => {
          const validationError = validateFileRestrictions(file, restrictions);
          setFileValidationError(!!validationError);
          if (!validationError) {
            uploadFile(file)
              .then((response) => {
                addFile({ fileId: response.id, name: response.originalName });
              })
              .catch((error) => {
                if (error && error.type !== "abort") {
                  if (error.status === 409) {
                    showError(t("file.error.409"));
                  } else {
                    showError(t("error.500"));
                  }
                }
              });
          } else {
            showError(messages[`upload.${validationError}`]);
          }
        });
        uploadInputRef.current.value = "";
      } else {
        let errorMessage = "";
        errors.forEach((error) => {
          errorMessage +=
            t("file.tooLarge", {
              fileName: error.fileName,
              fileSize: error.fileSize,
            }) + "\n";
        });
        errorMessage += t("file.maxSize", { maxFileSize: MAX_FILE_SIZE_MB });
        showError(errorMessage);
      }
    };

    const postFilesLoading = !!uploadingFiles.length;

    return (
      <div className="files-list">
        {!!readOnly && (
          <div>
            <h2 className="files-header">{t("grouping.files")}</h2>
            {(!files || !files.length) && <div className="empty text-center">{t("main.empty")}</div>}
            {!!files && !!files.length && (
              <div className="files">
                {files.map((item, index) => (
                  <Chip
                    key={index}
                    clickable={true}
                    onClick={() => {
                      window.open(getFilePath(item.fileId));
                    }}
                    label={item.name}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        {!readOnly && (
          <>
            <h2 className="files-header">
              {t("grouping.files")}{" "}
              <IconButton
                color="primary"
                size="small"
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </h2>
            <input ref={uploadInputRef} type="file" color="primary" onChange={onFileSelect} hidden multiple />
            {fileValidationError && (
              <div className="file-upload-restrictions">
                <div>
                  <small>
                    {t("fileUpload.allowedExtensions")} {getRestrictedFileExtension(FILE_UPLOAD_RESTRICTIONS)}
                  </small>
                </div>
                <div>
                  <small>
                    {t("fileUpload.maximumFileSize")} {getRestrictedFileSize(FILE_UPLOAD_RESTRICTIONS)}
                  </small>
                </div>
              </div>
            )}
            {(!files || !files.length) && <div className="empty text-center">{t("main.empty")}</div>}
            {((!!files && !!files.length) || postFilesLoading) && (
              <div>
                {files.map((item, index) => (
                  <Chip
                    key={"file_" + index}
                    id={"file_" + index}
                    clickable={false}
                    label={item.name}
                    onDelete={() => removeFile(index)}
                  />
                ))}
                {uploadingFiles.map((item, index) => (
                  <Chip
                    key={"uploading_" + index}
                    id={"uploading_" + index}
                    clickable={false}
                    label={item.file.name}
                    className="uploading"
                    onDelete={() => cancelUploadingFile(item.file)}
                  />
                ))}
                <style>
                  {uploadingFiles.map(
                    (item, index) =>
                      "#uploading_" + index + ":before { background-size: " + item.progress * 90 + "% 100%; }\n"
                  )}
                </style>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);
