import { deepCopy, forEachRecursive, mapRecursive } from "../utils";
import { conditionIsCriterion, conditionIsGrouping } from "../validation/sustainabilitySystem";
import { conditionIsKPI } from "../validation/kpiSystem";
import { GROUPING_TYPE_KPI } from "../constants/kpiSystem";
import { KPI_STATUS_CHANGED, KPI_STATUS_WAITING, STATUS_REPORTING } from "../constants/ratingSystem";

export const getNodeChangedFields = (values, previousValues) => {

  const fieldMap = {
    kpiName: 'kpiName',
    name: 'name',
    type: 'type',
    kpi: 'kpi'
  };

  if (values && previousValues) {
    const changedFields = {};
    Object.keys(fieldMap).forEach(column => {
      const field = fieldMap[column];
      if (values[column] !== previousValues[column]) {
        if (Array.isArray(field)) {
          field.forEach(item => {
            changedFields[item] = true;
          });
        } else {
          changedFields[field] = true;
        }
      }
    });
    return Object.keys(changedFields);
  }
  return null;
};

export const processGrouping = grouping => {
  const mapActions = actions => {
    if (!actions) {
      return undefined;
    }
    return actions.map(item => ({ ...item, UID: undefined, tags: undefined }));
  };

  const kpiElements = [];
  const groupingElements = [];
  mapRecursive(grouping, (node, children) => {
    const { id } = node;
    if (node.type === GROUPING_TYPE_KPI) {
      const { reason, evaluationLinks, evaluationFiles, kpi, actions } = node;
      kpiElements.push({ id, reason, evaluationLinks, evaluationFiles, kpi, actions: mapActions(actions) });
    } else {
      groupingElements.push({ id, actions: mapActions(node.actions) });
    }
    return { ...node, children };
  });
  return { kpiElements, groupingElements };
};

export const mapKpiGroupings = groupingElements => mapRecursive(groupingElements, (node, children) => ({
  ...node,
  children: (node.kpiElements && node.kpiElements.length ? node.kpiElements : children).map(item => ({
    ...item
  }))
}), 'groupingElements');


export const kpiRatingSystemFromEvaluate = (system, evaluate) => {
  const evaluateData = { ...deepCopy(evaluate) };
  const groupingElements = evaluateData.groupingElements;
  const kpiElements = evaluateData.kpiElements;
  delete evaluateData.groupingElements;
  delete evaluateData.kpiElements;
  delete evaluateData.ratingSystemId;
  const updated = { ...deepCopy(system), ...evaluateData };
  const groupingsByKey = {};
  const kpisByKey = {};
  groupingElements?.forEach(item => groupingsByKey[item.id] = item);
  kpiElements?.forEach(item => kpisByKey[item.id] = item);
  forEachRecursive(updated.groupingElements, node => {
    const groupingElement = groupingsByKey[node.id] || {};
    Object.keys(groupingElement).forEach(key => {
      node[key] = groupingElement[key];
    });
    const kpis = node?.kpiElements || [];
    kpis.forEach(nodeKpiElements => {
      const kpiElement = kpisByKey[nodeKpiElements.id] || {};
      Object.keys(kpiElement).forEach(key => {
        nodeKpiElements[key] = kpiElement[key];
      });
    })
  }, 'groupingElements');
  return updated;
};

export const groupingElementsFromChildren = children => {
  const groupingElements = [...children];
  forEachRecursive(children, node => {
    if (conditionIsGrouping(node)) {
      node.groupingElements = node.children?.filter(conditionIsGrouping) || [];
      if (conditionIsCriterion(node)) {
        node.kpiElements = node.children?.filter(conditionIsKPI) || [];
      }
    }
  });
  return groupingElements;
};

export const getWaitingLinkedKPIs = groupingElements => {
  const linkedKpis = [];
  forEachRecursive(groupingElements || [], node => {
    node.kpiElements?.forEach(({ kpi }) => {
      if ([KPI_STATUS_WAITING, KPI_STATUS_CHANGED].includes(kpi?.linkStatus) && !linkedKpis.some(item => item.nameAndUnit === kpi.nameAndUnit)) {
        linkedKpis.push(kpi);
      }
    });
  }, 'groupingElements');
  return linkedKpis;
}

export const canView = (revision, { permissionReader }) => !!revision && (!revision.accessRestricted || !!permissionReader);

export const canEdit = ({ member }) => member;

export const canViewProject = (accessRestricted = false, { permissionReader } = {}) => !accessRestricted || !!permissionReader;

export const canApplyChanges = (ratingStatus = null, { permissionChangeApplier } = {}) => ratingStatus !== STATUS_REPORTING && !!permissionChangeApplier;
