import CancelRounded from "@mui/icons-material/CancelRounded";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Button, CircularProgress } from "@mui/material";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { onlyDecimals, roundDecimal } from "../../utils";
import { GroupingElementChartESG } from "../Dashboard/GroupingElementChart/GroupingElementChartESG";
import "./ProjectDetailsCard.scss";

export const ProjectDetailsCard = ({
  projectDetails,
  closeProjectDetailsCard,
  isLoadingProjectDetails,
  projsOnSameLatLong,
  projsOnSameLatLongIndex,
  setProjsOnSameLatLongIndex,
}) => {
  const { t } = useTranslation();
  const [ratingSystem, setRatingSystem] = useState(null);

  useEffect(() => {
    if (projectDetails.ratingSystems[0]) {
      const id = projectDetails.ratingSystems[0].id;
      setSelectedRatingSystem(id);
      return;
    }
    setRatingSystem(null);
  }, [projectDetails]);

  const getLoading = () => {
    return (
      <div style={{ textAlign: "center" }}>
        {" "}
        <CircularProgress />{" "}
      </div>
    );
  };
  const setSelectedRatingSystem = (id) => {
    setRatingSystem(projectDetails?.ratingSystems.find((f) => f.id === id));
  };
  const getRatingSystemsValues = () => {
    return projectDetails.ratingSystems?.length > 0
      ? projectDetails.ratingSystems.map((m) => {
          return { value: m.id, label: `${m.name} ${m.systemVersion}` };
        })
      : null;
  };
  const handleNext = () => {
    setProjsOnSameLatLongIndex(projsOnSameLatLongIndex + 1);
  };
  const handleBack = () => {
    setProjsOnSameLatLongIndex(projsOnSameLatLongIndex - 1);
  };

  return (
    <div className="projectDetailsCardWrapper" style={{ display: "grid" }}>
      {isLoadingProjectDetails
        ? getLoading()
        : projectDetails && (
            <div id="projectDetailsCard" className="projectDetailsCard">
              <div className="upperSection">
                <div className="closeCardButton">
                  <CancelRounded onClick={(e) => closeProjectDetailsCard()} className="closeCardIcon" />
                </div>
                <div className="projectImageContainer">
                  <NavLink to={`/project/${projectDetails.id}`}>
                    <img
                      className="projectImage"
                      alt="Project"
                      src={
                        projectDetails.pictureIds[0]
                          ? `/api/project/${projectDetails.id}/picture/${projectDetails.pictureIds[0]}`
                          : "/images/no_image.png"
                      }
                    />
                  </NavLink>
                </div>
              </div>
              <div>
                <div className="bottomSection">
                  <div className="showInline leftColumn">
                    <div className="projectName">
                      <NavLink to={`/project/${projectDetails.id}`}>{projectDetails.name}</NavLink>
                    </div>
                    <div className="projectAddressZipCity">{projectDetails.location}</div>
                    <div className="projectAddressUsageTypeAndCondition">
                      {projectDetails.usageType &&
                        t("project.usageTypes." + projectDetails.usageType) +
                          (projectDetails.condition ? ", " : "")}{" "}
                      {projectDetails.condition && t("project.conditions." + projectDetails.condition)}
                    </div>
                    <div className="projectType">
                      {projectDetails.projectTypes.map((m) => t("sustainabilitySystem.type." + m)).join(", ")}
                    </div>
                    <div className="ratingSystem">RATING SYSTEM</div>
                    {ratingSystem ? (
                      <div className="ratingSystemSelector">
                        <Select
                          id="ratingSystemSelector"
                          onChange={(e) => setSelectedRatingSystem(e.value)}
                          options={getRatingSystemsValues()}
                          value={
                            ratingSystem
                              ? {
                                  value: ratingSystem.id,
                                  label: `${ratingSystem.name} ${ratingSystem.systemVersion}`,
                                }
                              : null
                          }
                          isSearchable={false}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        />
                      </div>
                    ) : (
                      "No rating System"
                    )}
                    <div className={`projectStatus ${ratingSystem?.ratingStatus}`}>
                      {ratingSystem?.ratingStatus
                        ? t("ratingSystem.status." + ratingSystem?.ratingStatus).toUpperCase()
                        : ""}
                    </div>

                    {ratingSystem && ratingSystem.selectedAward ? (
                      <div className="ratingSystemAward">
                        <div className="ratingSystemAwardImage">
                          <img
                            alt="Sustainability award"
                            width="75px"
                            height="75px"
                            src={`/api/sustainability/award/file/${ratingSystem?.selectedAward.fileId}/preview`}
                          />
                        </div>
                        <div className="ratingSystemAwardTitle">{ratingSystem?.selectedAward.title}</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="showInline rightColumn">
                    <div className="superScoreChart">
                      {ratingSystem ? (
                        <NavLink to={`/project/${projectDetails.id}/dashboard/${ratingSystem.id}`}>
                          <GroupingElementChartESG
                            data={{
                              potentialScore: roundDecimal(ratingSystem.potentialSuperScore),
                              universalScore: onlyDecimals(roundDecimal(ratingSystem.superScore), 2),
                              title: t("projects.superScore"),
                            }}
                          />
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  {projsOnSameLatLong && (
                    <div className="projectsOnSamePositionPagination">
                      <div className="showInline fullwidth">
                        {projsOnSameLatLong.length < 31 && (
                          <Button
                            size="small"
                            className="multiprojsSameLatLongBackButton"
                            onClick={handleBack}
                            disabled={projsOnSameLatLongIndex === 0}
                          >
                            <KeyboardArrowLeft /> Back
                          </Button>
                        )}
                        <div style={{ display: "inline-block" }}>
                          <Stepper nonLinear activeStep={projsOnSameLatLongIndex} connector={null}>
                            {projsOnSameLatLong.map((projectId, index) => (
                              <Step key={projectId}>
                                <StepButton color="inherit" onClick={() => setProjsOnSameLatLongIndex(index)} />
                              </Step>
                            ))}
                          </Stepper>
                        </div>
                        {projsOnSameLatLong.length < 31 && (
                          <Button
                            size="small"
                            className="multiprojsSameLatLongNextButton"
                            onClick={handleNext}
                            disabled={projsOnSameLatLongIndex === projsOnSameLatLong.length - 1}
                          >
                            Next <KeyboardArrowRight />
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
    </div>
  );
};
