import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../../constants/main";
import {
  addProjectGroupToProjectGroup,
  resetProjectGroupsPageState,
  searchProjectGroups,
  useProjectGroupChildrenResponse,
  useProjectGroupParentResponse,
  useProjectGroupsPageLogic,
  useProjectGroupsPageResponse,
  useSelectedProjectGroupChildren,
} from "../../../hooks/projectGroup";
import { showError } from "../../../hooks/toast";
import { useTimeout } from "../../../hooks/utils/useTimeout";
import { processMessage } from "../../../utils";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import "./ChildProjectGroupsDialog.scss";

export const ChildProjectGroupsDialog = ({ onClose, onChange, parentProjectGroupId, projectGroupId }) => {
  const { t } = useTranslation();

  const projectGroupsPageResponse = useProjectGroupsPageResponse();
  const projectGroupParentResponse = useProjectGroupParentResponse();
  const projectGroupChildrenResponse = useProjectGroupChildrenResponse();
  const projectGroupChildren = useSelectedProjectGroupChildren() || [];
  const [processed, setProcessed] = useState([]);
  const [total, setTotal] = useState(0);
  const inputRef = useRef(null);

  const {
    searchValue,
    setSearchValue,
    sortValue,
    setSortValue,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    resetState,
  } = useProjectGroupsPageLogic();
  const [, setSearchTimeout] = useTimeout();

  useEffect(() => {
    if (!projectGroupsPageResponse.loading && projectGroupsPageResponse.data) {
      const responseData = projectGroupsPageResponse.data || {};
      const items = responseData.content || [];
      const totalValue = (responseData.meta || {}).total || 0;
      setProcessed(items);
      setTotal(totalValue);
    }
  }, [projectGroupsPageResponse.loading, projectGroupsPageResponse.data]);

  const load = () =>
    searchProjectGroups({
      page: page + 1,
      itemsPerPage: rowsPerPage,
      sort: sortValue.field ? (sortValue.desc ? "-" : "") + sortValue.field : undefined,
      name: searchValue,
      projectGroupId: projectGroupId,
    }).catch(console.error);

  useEffect(() => {
    resetProjectGroupsPageState();
    setProcessed([]);
    setTotal(0);
  }, []);

  useEffect(() => {
    load();
  }, [searchValue, sortValue.field, sortValue.desc, page, rowsPerPage]);

  const onSort = (field) =>
    setSortValue({
      field: field !== sortValue.field || !sortValue.desc ? field : null,
      desc: sortValue.field === field ? !sortValue.desc : false,
    });

  const search = (value) => {
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(value || "").trim());
      }, 500)
    );
  };

  const isAddDisabled = (childId) => {
    return (
      childId === projectGroupId ||
      childId === parentProjectGroupId ||
      projectGroupChildren.some((item) => item.id === childId)
    );
  };

  const addChildProjectGroup = (childId) => {
    addProjectGroupToProjectGroup(childId, parentProjectGroupId)
      .then(() => {
        onChange();
        load();
      })
      .catch((error) => {
        console.error(error);
        showError(t("error.500"));
      });
  };

  const pagination = {
    labelRowsPerPage: t("projectGroups.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: total,
    page: page,
    show: total > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pageOnChange) => setPage(pageOnChange),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const columns = [
    {
      field: "name",
      headerClassName: "name-column",
      headerText: t("projectGroups.name"),
      sortable: true,
    },
    {
      field: "description",
      headerClassName: "name-description",
      headerText: t("projectGroups.description"),
    },
    {
      className: "action-cell",
      headerClassName: "action-header-cell",
      headerText: "",
      template: (row) => (
        <div>
          <Button disabled={isAddDisabled(row.id)} onClick={() => addChildProjectGroup(row.id)}>
            {t("main.add")}
          </Button>
        </div>
      ),
    },
  ];

  const loading =
    projectGroupsPageResponse.loading || projectGroupParentResponse.loading || projectGroupChildrenResponse.loading;

  return (
    <div className="child-project-groups-dialog">
      <h2>
        {t("projectGroup.modalChildProjectGroupsTitle")}
        <IconButton onClick={onClose} size="small" className={"pull-right"}>
          <CloseOutlinedIcon />
        </IconButton>
      </h2>

      <div
        className="search-box"
        onClick={() => {
          inputRef.current.focus();
        }}
      >
        <SearchIcon />
        <input
          type="text"
          key={resetState}
          defaultValue={searchValue || ""}
          className="search-input input-shadow"
          ref={inputRef}
          placeholder={t("main.search")}
          onChange={(event) => search(event.target.value)}
        />
      </div>

      <LoadingOverlay spinner className="auto-height" active={loading}>
        <SimpleTableWithSort
          key={loading}
          className="overflow-visible"
          tableClassName="modal-table"
          data={processed}
          childrenRecordsName="ratingSystems"
          pagination={pagination}
          sortValue={sortValue}
          onSort={onSort}
          columns={columns}
        />
      </LoadingOverlay>
    </div>
  );
};
