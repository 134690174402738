import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useMemo, useState } from "react";
import { getColumnGroupStates, getColumnPosition } from "../../helpers/advancedTable";
import { useTableScrollX } from "../../hooks/advancedTable";
import { CheckTree } from "../CheckTree/CheckTree";

export const AdvancedTableHead = ({
  singleLineHeader,
  columnsProcessed,
  disableColumnDrag,
  columnGrabStart,
  disableColumnLock,
  setPopoverTarget,
  columnResizeStart,
  columnGroups,
  passThrough,
  hiddenColumns,
  setHiddenColumns,
  columns,
  stickyAmount,
}) => {
  const tableScrollX = useTableScrollX();
  const lockedColumns = columnsProcessed.filter((item) => item.locked);
  const [columnsPopoverTarget, setColumnsPopoverTarget] = useState(null);

  const columnGroupStates = getColumnGroupStates(columnsProcessed, columnGroups);

  const hideableColumns = useMemo(() => (columns || []).filter((column) => column.hideable), []);
  const visibleColumns = hideableColumns
    .filter((item) => (hiddenColumns || []).indexOf(item.field || item.fieldKey) === -1)
    .map((item) => item.field || item.fieldKey);

  return (
    <TableHead>
      <TableRow className={singleLineHeader ? "single-line-header" : ""}>
        {(columnsProcessed || []).map((column, index) => {
          const columnPosition = getColumnPosition(index, columnsProcessed);

          const { columnGroup, firstInGroup, lastInGroup } = columnGroupStates[index];

          const className =
            "table-cell-head " +
            (column.headerClassName || "") +
            (column.locked ? " locked" : "") +
            (column.columnGroup ? " group" : "") +
            (lockedColumns.length === index + 1 ? " last" : "") +
            (index < stickyAmount ? " sticky" : "");

          const style = {
            width: ("number" === typeof column.width ? column.width + "px" : column.width) || "auto",
          };

          if (!disableColumnLock) {
            style.left = column.locked ? tableScrollX + columnPosition + "px" : "0";
          }

          if (index < stickyAmount) {
            style.left = columnPosition + "px";
          }

          let columnHeaderClassName = "";

          if (column.columnGroup) {
            columnHeaderClassName += "column-header-group " + (columnGroup.className || "");
            if (firstInGroup) {
              let width = column.width;
              let nextIndex = index + 1;
              while (
                columnsProcessed[nextIndex] &&
                columnsProcessed[nextIndex].columnGroup === column.columnGroup &&
                columnsProcessed[nextIndex].locked === column.locked
              ) {
                width += columnsProcessed[nextIndex].width;
                nextIndex++;
              }
              columnGroup.width = width;
              columnHeaderClassName += " first-in-group";
            }
            if (lastInGroup) {
              columnHeaderClassName += " last-in-group";
            }
          }

          return (
            <TableCell key={index} className={className} style={style}>
              {index === 0 && !!hideableColumns.length && (
                <div className="columns-button">
                  <IconButton size="small" onClick={(event) => setColumnsPopoverTarget(event.currentTarget)}>
                    <ViewWeekIcon />
                  </IconButton>
                  <Popover
                    open={!!columnsPopoverTarget}
                    anchorEl={columnsPopoverTarget}
                    onClose={() => setColumnsPopoverTarget(null)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                  >
                    <div className="popover-check-tree">
                      <CheckTree
                        multiple={true}
                        selectAllOption={true}
                        items={hideableColumns.map((item) => ({
                          name: item.field || item.fieldKey,
                          text: item.headerText,
                        }))}
                        values={visibleColumns || []}
                        onChange={(values) =>
                          setHiddenColumns(
                            hideableColumns
                              .map((item) => item.field || item.fieldKey)
                              .filter((item) => values.indexOf(item) === -1)
                          )
                        }
                      />
                    </div>
                  </Popover>
                </div>
              )}
              <div
                className={"column-header " + columnHeaderClassName}
                style={{ backgroundColor: columnGroup?.backgroundColor, color: columnGroup?.color }}
              >
                {lastInGroup && (
                  <div
                    className="column-grouping-title"
                    style={{
                      width: columnGroup.width + "px",
                      left: -columnGroup.width + column.width + "px",
                    }}
                  >
                    {columnGroup.title}
                  </div>
                )}
                {!disableColumnDrag && (
                  <div className="dnd-trigger" onMouseDown={(event) => columnGrabStart(event, column, index)}>
                    <DragIndicatorIcon />
                  </div>
                )}
                <div className="header-text">
                  {!!column.headerTemplate && column.headerTemplate(passThrough)}
                  {column.headerText || ""}
                </div>
                {(!disableColumnLock || !!column.search) && (
                  <IconButton
                    className="header-actions"
                    onClick={(event) =>
                      setPopoverTarget({
                        target: event.currentTarget,
                        column,
                        index,
                      })
                    }
                  >
                    <MoreHorizIcon />
                  </IconButton>
                )}
                <div className="resize-trigger" onMouseDown={(event) => columnResizeStart(event, column, index)}>
                  <div className="resize-trigger-inner" />
                </div>
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
