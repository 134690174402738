import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const ActionSelectTemplate = ({
  row,
  selectValues,
  field,
  passThrough,
  readOnly,
  includeEmptyValue,
  emptyValueString,
}) => {
  const { t } = useTranslation();

  const { onValueChange } = passThrough || {};
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(row[field]);
  }, []);

  if (!row) {
    return null;
  }

  const valueChange = (newValue) => {
    onValueChange(row, field, newValue);
    setValue(newValue);
  };

  return (
    <FormControl fullWidth>
      <Select
        className="selectMargin"
        defaultValue={value || ""}
        value={value || ""}
        onChange={(event) => valueChange(event.target.value)}
        options={selectValues}
        disabled={!!readOnly || !!passThrough.readOnly}
        searchable="false"
      >
        {selectValues.map((item, index) => (
          <MenuItem key={`${row.id}_${index}`} value={item}>
            {t("action.statuses." + item)}
          </MenuItem>
        ))}
        {includeEmptyValue && (
          <MenuItem key={`${row.id}_empty`} value={""}>
            {emptyValueString}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
