import PostAddIcon from "@mui/icons-material/PostAdd";
import { Button, MenuItem, MenuList } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { AddActionsDialog } from "../../../components/AddActionsDialog/AddActionsDialog";
import { showDialog } from "../../../hooks/dialog";
import { conditionIsCategory } from "../NewRatingSystemESG.validation";
import { BaseBlock } from "./BaseBlock";

export const ACTION_BLOCK_ESG_ID = "actionsBlock";
export const ACTION_ADD_BUTTON_ID = "addActionButton";

export const ActionBlockESG = ({ dataItem, tableFn, readOnly }) => {
  const { t } = useTranslation();

  if (conditionIsCategory(dataItem)) return null;

  const showAddActionsDialog = () => {
    showDialog({
      className: "xlarge",
      closeOnClickOutside: true,
      getContent: (onClose) => (
        <AddActionsDialog
          row={dataItem}
          onClose={onClose}
          readOnly={readOnly}
          onChange={({ actions }) => tableFn?.onItemChange([{ dataItem, field: "actions", value: actions }])}
        />
      ),
    });
  };

  return (
    <BaseBlock title={t("newRatingSystem.esg.sideMenu.actionBlock.title")} id={ACTION_BLOCK_ESG_ID}>
      <div className="k-d-flex k-flex-col">
        <MenuList>
          {dataItem?.actions?.map((action) => (
            <MenuItem key={getUUID()} onClick={showAddActionsDialog}>
              {action?.name}
            </MenuItem>
          ))}
        </MenuList>

        <Button id={ACTION_ADD_BUTTON_ID} className="k-gap-2" onClick={showAddActionsDialog}>
          <PostAddIcon />
          {t("newRatingSystem.esg.sideMenu.actionBlock.manage")}
        </Button>
      </div>
    </BaseBlock>
  );
};

ActionBlockESG.propTypes = {
  dataItem: PropTypes.object,
  tableFn: PropTypes.object,
  readOnly: PropTypes.bool,
};
