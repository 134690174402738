import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { PromiseSubjectState } from "react-rxjs-easy";
import {
  getProjectGroupChartProjects,
  getProjectGroupChartStructure,
  projectGroupChartProjectsSubject,
  projectGroupChartStructureSubject,
  useProjectGroupChartProjectsResponse,
  useProjectGroupChartStructureResponse,
} from "../../../hooks/projectGroup";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import "./ProjectGroupSpiderChart.scss";

export const ProjectGroupSpiderChart = React.memo(({ projectGroupId }) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [chartData, setChartData] = useState(null);
  const projectGroupChartProjectsResponse = useProjectGroupChartProjectsResponse();
  const projectGroupChartStructureResponse = useProjectGroupChartStructureResponse();

  useEffect(() => {
    projectGroupChartProjectsSubject.next(new PromiseSubjectState());
    projectGroupChartStructureSubject.next(new PromiseSubjectState());
    setSelectedOption(null);
    if (projectGroupId) {
      getProjectGroupChartProjects(projectGroupId);
    }
  }, [projectGroupId]);

  useEffect(() => {
    if (selectedOption) {
      const parts = selectedOption.split("_");
      getProjectGroupChartStructure(parts[0], parts[1]);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (!projectGroupChartProjectsResponse.loading) {
      setSelectedOption(null);
      setOptions(
        (projectGroupChartProjectsResponse.data || []).map(({ projectId, projectName, systemHash }) => ({
          value: [projectId, systemHash].join("_"),
          label: projectName,
        }))
      );
    }
  }, [projectGroupChartProjectsResponse.loading]);

  useEffect(() => {
    if (!projectGroupChartStructureResponse.loading) {
      let categories = [];
      const series = [];
      (projectGroupChartStructureResponse.data || []).forEach(
        ({ systemSource, ratingSystemName, systemVersion, elements }, index) => {
          series.push({
            name: [systemSource, ratingSystemName, systemVersion].join(" "),
            data: (elements || []).map((item) => item.score),
          });
          if (!index) {
            categories = (elements || []).map((item) => item.name);
          }
        }
      );
      setChartData({ categories, series });
    }
  }, [projectGroupChartStructureResponse.loading]);

  useEffect(() => {
    if (!selectedOption && options.length && projectGroupChartProjectsResponse.data) {
      setSelectedOption(options[0].value);
    }
  }, [options, selectedOption]);

  const state = {
    series: chartData?.series || [],
    options: {
      chart: {
        height: 640,
        type: "radar",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      title: {
        text: "",
      },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: chartData?.categories || [],
      },
    },
  };

  const loading = projectGroupChartProjectsResponse.loading || projectGroupChartStructureResponse.loading;

  return (
    <LoadingOverlay spinner active={loading} className="project-group-spider-chart auto-height">
      <div className="flex-row">
        <div className="k-flex-1">
          <Autocomplete
            size="small"
            key={options}
            options={options}
            value={options.length && selectedOption ? selectedOption : ""}
            isOptionEqualToValue={(option, value) => !!option && option.value === value}
            getOptionLabel={(option) =>
              (typeof option === "string" ? options.find((item) => item.value === option)?.label : option?.label) || ""
            }
            renderInput={(params) => (
              <TextField {...params} placeholder={t("projectGroup.spiderChart.selectProject")} />
            )}
            onChange={(event, selected) => {
              setSelectedOption(selected.value);
            }}
            disableClearable
          />
        </div>
        <div className="flex-2"></div>
      </div>
      <Chart
        key={projectGroupId + "_" + selectedOption}
        options={state.options}
        series={state.series}
        type="radar"
        height={640}
      />
    </LoadingOverlay>
  );
});
