import { useEffect, useState } from "react";

export const usePageData = (response) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!response.loading) {
      const responseData = response.data || {};
      const dataInner = responseData.content || [];
      const totalValue = (responseData.meta || {}).total || 0;
      setData(dataInner);
      setTotal(totalValue);
    }
  }, [response.loading, response.data]);

  return [data, total];
};
