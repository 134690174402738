import { v4 as getUUID } from "uuid";

export class AwardElement {
  UID;
  fileId;
  children = [];

  constructor(obj = {}) {
    this.UID = getUUID();
    this.internalId = this.UID;
    this.fileId = obj.fileId || null;
    this.scoreFrom = obj.scoreFrom || null;
    this.scoreTo = obj.scoreTo || null;
    this.title = obj.title || null;
  }
}
