import { TableCell } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { DATETIME_FORMAT_CLIENT } from "../../../constants/main";
import "./VersionsTable.scss";

export const VersionsTable = ({ data, onAction, selectedVersion }) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} className="versions-table">
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t("versions.version")}</TableCell>
            <TableCell>{t("versions.published")}</TableCell>
            <TableCell>{t("versions.userName")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data
              ? data.map((d, i) => (
                  <TableRow key={d.revisionNumber}>
                    <TableCell
                      className={selectedVersion === d.revisionNumber ? "selected" : ""}
                      component="th"
                      scope="row"
                      onClick={() => {
                        onAction(d.revisionNumber, i === 0);
                      }}
                    >
                      <span className="version">{i === 0 ? t("versions.currentVersion") : `V${d.revisionNumber}`}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment.utc(d.dateTime).local().format(DATETIME_FORMAT_CLIENT)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="userName">{d?.creator}</span>
                    </TableCell>
                  </TableRow>
                ))
              : null /* possible zero state component */
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};
