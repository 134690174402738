import { Box } from "@mui/material";
import jp from "jsonpath";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { PromiseSubjectState } from "react-rxjs-easy";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { ContentBlock } from "../../components/ContentBlock/ContentBlock";
import { DashboardRatingSystems } from "../../components/Dashboard/DashboardRatingSystems/DashboardRatingSystems";
import { DashboardTabs } from "../../components/Dashboard/DashboardTabs/DashboardTabs";
import { DashboardTimeline } from "../../components/Dashboard/DashboardTimeline/DashboardTimeline";
import { SunBurstChartESG } from "../../components/Dashboard/SunBurstChart/SunBurstChartESG";
import { SunBurstChartPAST } from "../../components/Dashboard/SunBurstChart/SunBurstChartPAST";
import { SystemScoreProgressESG } from "../../components/Dashboard/SystemScore/SystemScoreProgress/SystemScoreProgressESG";
import { SystemScoreProgressPAST } from "../../components/Dashboard/SystemScore/SystemScoreProgress/SystemScoreProgressPAST";
import { SystemScoreTableESG } from "../../components/Dashboard/SystemScore/SystemScoreTable/SystemScoreTableESG";
import { SystemScoreTablePAST } from "../../components/Dashboard/SystemScore/SystemScoreTable/SystemScoreTablePAST";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { PrintReportButton } from "../../components/PrintReportButton/PrintReportButton";
import { ProjectHeader } from "../../components/Project/ProjectHeader/ProjectHeader";
import { AWARD_CALCULATION_TYPE_PERCENT } from "../../constants/sustainabilitySystem";
import { TENANT_TYPE_PAST } from "../../constants/tenant";
import { canView, checkKOFailed, checkThresholdFailed, getAward } from "../../helpers/ratingSystem";
import { getUserTenantType } from "../../hooks/auth";
import {
  getProjectDashboard,
  getProjectUserPermissions,
  setDashboardSelectedGrouping,
  useProjectDashboardResponse,
} from "../../hooks/project";
import {
  getProjectDashboardRevision,
  getProjectDashboardTimeline,
  projectDashboardRevisionSubject,
  projectDashboardTimelineSubject,
  setBenchmarksLoadedForKpi,
  useProjectDashboardRevisionResponse,
  useProjectDashboardTimelineResponse,
} from "../../hooks/ratingSystem";
import { showError } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { filterRecursive, isExcludedGroupingElement, mapRecursive, processMessage } from "../../utils";

export const DashboardPage = () => {
  const { projectId, ratingSystemId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenantType = getUserTenantType();
  const response = useProjectDashboardResponse();
  const timelineResponse = useProjectDashboardTimelineResponse();
  const [project, setProject] = useState({});
  const [koFailed, setKoFailed] = useState(false);
  const [timelineData, setTimelineData] = useState([]);
  const [ratingSystems, setRatingSystems] = useState([]);
  const [revisionNumber, setRevisionNumber] = useState(null);
  const [lastRevisionNumber, setLastRevisionNumber] = useState(null);
  const revisionResponse = useProjectDashboardRevisionResponse();
  const [revision, setRevision] = useState({});
  const [unfolded, setUnfolded] = useState(false);
  const { i18n } = useTranslation();
  const permissions = revision.projectPermission ?? {};
  const isViewer = canView(revision, permissions);

  const getSystems = (data) => {
    return [...(data?.ratingSystems || []), ...(data?.kpiRatingSystems || [])];
  };

  useEffect(() => {
    getProjectDashboardTimeline(ratingSystemId);
    setBenchmarksLoadedForKpi(null);
    if (project.ratingSystems || project.kpiRatingSystems) {
      setRatingSystems(getSystems(project));
    }
    return () => {
      projectDashboardTimelineSubject.next(new PromiseSubjectState());
      projectDashboardRevisionSubject.next(new PromiseSubjectState());
    };
  }, [ratingSystemId]);

  useEffect(() => {
    if (!timelineResponse.loading && !!timelineResponse.data) {
      const data = timelineResponse.data || [];
      setTimelineData(data);
      const sorted = data.sort((a, b) => {
        if (a.dateTime > b.dateTime) {
          return 1;
        }
        if (a.dateTime < b.dateTime) {
          return -1;
        }
        return 0;
      });
      const lastRevisionNumberInner = sorted?.[sorted?.length - 1]?.revisionNumber;
      const revisionNumberInner = sorted?.[sorted?.length - 1]?.revisionNumber;
      setRevisionNumber(revisionNumberInner);
      setLastRevisionNumber(lastRevisionNumberInner);
      if (revisionNumberInner) {
        getProjectDashboardRevision(
          ratingSystemId,
          revisionNumberInner,
          revisionNumberInner === lastRevisionNumberInner
        );
      }
    }
  }, [timelineResponse.loading]);

  useEffect(() => {
    if (!revisionResponse.loading) {
      const data = revisionResponse.data || {};
      data.groupingElements = filterRecursive(
        data.groupingElements,
        (node) => {
          if (!isExcludedGroupingElement(node)) {
            if (node?.indicatorElements?.length) {
              node.indicatorElements = node.indicatorElements.filter((item) => !item.excluded);
            }
            return !!(node?.groupingElements?.length || node?.indicatorElements?.length);
          } else {
            return false;
          }
        },
        "groupingElements"
      );
      setRevision(data);
      const grouping = mapRecursive(
        data.groupingElements,
        (node, children) => {
          return { ...node, children: [...children, ...node.indicatorElements] };
        },
        "groupingElements"
      );
      setKoFailed(checkKOFailed(grouping));
    }
  }, [revisionResponse.loading]);

  useEffect(() => {
    if (projectId) {
      setDashboardSelectedGrouping(null);
      if (response.data && String(response.data.id) === projectId) {
        resetData();
      }
      load();
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId && !!revision && revision.accessRestricted) {
      getProjectUserPermissions(projectId);
    }
  }, [projectId, revision]);

  const load = () => {
    getProjectDashboard(projectId)
      .then(resetData)
      .catch(() => {
        showError(t("project.error.load"));
        navigate("/project");
      });
  };

  const resetData = (values) => {
    const data = values || response.data || {};
    setProject(data);
    if (!data.ratingSystems.length) {
      navigate("/project");
    } else {
      setRatingSystems(getSystems(data));
    }
  };

  const onSetRevisionNumber = (revisionNumberInner) => {
    setRevisionNumber(revisionNumberInner);
    if (revisionNumberInner) {
      const timelineItem = timelineData.find((item) => item.revisionNumber === revisionNumberInner);
      if (timelineItem) {
        setRatingSystems(
          getSystems(project).map((item) => {
            if (String(item.id) === ratingSystemId) {
              return { ...item, ratingStatus: timelineItem.status, actualAward: timelineItem.actualAward || null };
            } else {
              return { ...item };
            }
          })
        );
        getProjectDashboardRevision(ratingSystemId, revisionNumberInner, revisionNumberInner === lastRevisionNumber);
      }
    }
  };

  const awardCalculationType = revision.awardCalculationType || AWARD_CALCULATION_TYPE_PERCENT;
  const isPercent = awardCalculationType === AWARD_CALCULATION_TYPE_PERCENT;

  const elementList = jp
    .query(revision, "$..groupingElements[*]")
    .concat(jp.query(revision, "$..indicatorElements[*]"));

  const actualAward = getAward(
    revision.awards || [],
    elementList,
    isPercent ? revision?.superScore : revision?.score,
    isPercent ? "universalScore" : "evaluationSystemScore"
  );
  const potentialAward = getAward(
    revision.awards || [],
    elementList,
    isPercent ? revision?.potentialSuperScore : revision?.potentialScore,
    isPercent ? "potentialUniversalScore" : "potentialEvaluationSystemScore"
  );

  const scoreSystemData = {
    ...revision,
    awardCalculationType,
    actualAward,
    potentialAward,
  };

  const actualSideThresholdFailedMessage = () => {
    if (actualSideThresholdFailed) {
      if (actualAward) {
        return processMessage(t("award.sideRequirementLower"), [actualAward.title]);
      }
      return t("award.sideRequirementFailed");
    }
    return "";
  };

  const onSystemChange = useEvent((id, isKpi) => {
    if (isKpi) {
      navigate("/project/" + projectId + "/kpiDashboard/" + id);
    } else {
      navigate("/project/" + projectId + "/dashboard/" + id);
    }
  });

  const actualSideThresholdFailed = checkThresholdFailed(
    revision.awards || [],
    elementList,
    isPercent ? revision?.superScore : revision?.score,
    isPercent ? "universalScore" : "evaluationSystemScore"
  );
  const koAwardMessage = koFailed ? t("award.KO") : "";

  return (
    <LoadingOverlay spinner active={response.loading || revisionResponse.loading} className="dashboard-page">
      <div className="page-header flex-row">
        <Breadcrumb
          pathParts={[
            { url: "/project", text: t("menu.project.title") },
            { url: "/project/" + projectId, text: project.name },
            { text: t("dashboard.title") },
          ]}
        />
        <div className="flex-auto text-right">
          {!!isViewer && (
            <PrintReportButton
              projectId={projectId}
              ratingSystemId={ratingSystemId}
              language={i18n.language}
              tenantType={tenantType}
            />
          )}
        </div>
      </div>

      <div className="page-layout" hidden={!project.id}>
        <ProjectHeader project={project} />
        <DashboardRatingSystems
          ratingSystems={ratingSystems}
          systemId={ratingSystemId}
          onSystemChange={onSystemChange}
          expanded={!ratingSystemId}
        />

        {!!ratingSystemId && (
          <>
            {tenantType === TENANT_TYPE_PAST ? (
              <ContentBlock innerClassName="flex-row" error={koAwardMessage}>
                <SystemScoreTablePAST data={scoreSystemData} />
                <Box
                  className="clickable-awardprogress-float-right"
                  title={t("dashboard.backToRatingSystem")}
                  onClick={() => navigate(`/project/ratingSystem/${ratingSystemId}`)}
                >
                  <SystemScoreProgressPAST data={scoreSystemData} />
                </Box>
              </ContentBlock>
            ) : (
              <ContentBlock innerClassName="flex-row" error={[koAwardMessage, actualSideThresholdFailedMessage()]}>
                <SystemScoreTableESG data={scoreSystemData} koFailed={koFailed} />
                <Box
                  className="clickable-awardprogress-float-right"
                  title={t("dashboard.backToRatingSystem")}
                  onClick={() => navigate(`/project/ratingSystem/${ratingSystemId}`)}
                >
                  <SystemScoreProgressESG data={scoreSystemData} />
                </Box>
              </ContentBlock>
            )}
            <DashboardTimeline
              data={timelineData}
              setRevisionNumber={onSetRevisionNumber}
              revisionNumber={revisionNumber}
            />
            <div className="flex-row">
              {!unfolded &&
                (tenantType === TENANT_TYPE_PAST ? (
                  <SunBurstChartPAST data={revision} />
                ) : (
                  <SunBurstChartESG data={revision} />
                ))}
              <div className="flex-auto overflow-y-auto">
                <DashboardTabs
                  data={revision}
                  revisionNumber={revisionNumber}
                  lastRevision={lastRevisionNumber}
                  unfolded={unfolded}
                  setUnfolded={setUnfolded}
                  projectId={projectId}
                  ratingSystemId={ratingSystemId}
                  language={i18n.language}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </LoadingOverlay>
  );
};
