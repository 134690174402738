export const MIN_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE = 25;
export const PAGE_SIZES = [MIN_PAGE_SIZE, DEFAULT_PAGE_SIZE, 50];
export const SELECT_OPTION_OFF = "off";

export const DATE_FORMAT_SERVER = "YYYY-MM-DD";
export const DATE_FORMAT_CLIENT = "DD.MM.YYYY";
export const DATETIME_FORMAT_SERVER = "YYYY-MM-DDTHH:mm:ss";
export const DATETIME_FORMAT_CLIENT = "DD.MM.YYYY HH:mm";
export const DATETIME_S_FORMAT_CLIENT = "DD.MM.YYYY HH:mm:ss";

export const MUI_CLEAR_INPUT_REASON = "clear";

export const KEY_CODE_ENTER = 13;
export const KEY_CODE_SPACE = 32;
export const KEY_CODE_TAB = 9;

export const DASH = "-";

export const ZERO = 0;
export const HUNDRED = 100;

export const PERCENT_SIGN = "%";
export const ZERO_PERCENT = ZERO + PERCENT_SIGN;
export const HUNDRED_PERCENT = HUNDRED + PERCENT_SIGN;

export const SUSTAINABILITY_SYSTEM = "SUSTAINABILITY_SYSTEM";
export const RATING_SYSTEM = "RATING_SYSTEM";

export const DISALLOWED_CHARACTERS_REGEX = /[!\t\s\r\n"@#$%^&*()+={}[\];:'",<>?/\\|~`]/m;

export const PAST_MINIMUM_VALUE = 1;

export const LEFT_ALIGN = "left";
export const CENTER_ALIGN = "center";
export const RIGHT_ALIGN = "right";

export const isArrowKey = (key) =>
  key === KEY.ARROW_DOWN || key === KEY.ARROW_UP || key === KEY.ARROW_LEFT || key === KEY.ARROW_RIGHT;

export const KEY = {
  ENTER: "Enter",
  ESCAPE: "Escape",
  SPACE: " ",
  TAB: "Tab",
  ARROW_DOWN: "ArrowDown",
  ARROW_UP: "ArrowUp",
  ARROW_LEFT: "ArrowLeft",
  ARROW_RIGHT: "ArrowRight",
  HOME: "Home",
  END: "End",
  PAGE_UP: "PageUp",
  PAGE_DOWN: "PageDown",
  BACKSPACE: "Backspace",
  DELETE: "Delete",
  SHIFT: "Shift",
  CTRL: "Control",
  ALT: "Alt",
  META: "Meta",
  INSERT: "Insert",
  NUM_LOCK: "NumLock",
  CAPS_LOCK: "CapsLock",
  PAUSE: "Pause",
};

export const CARBONE_URL = "https://render.carbone.io/render/";
