import React, { useEffect, useState } from "react";
import "./BenchmarkFiles.scss";
import { showError } from "../../../hooks/toast";
import { FilesTable } from "../../FilesTable/FilesTable";
import {
  addBenchmarkFile,
  benchmarkFilesSubject,
  deleteBenchmarkTemporaryFile,
  removeBenchmarkFile,
  removeBenchmarkFileByFileUid,
  setBenchmarkFiles,
  useBenchmarkFiles
} from "../../../hooks/benchmark";
import { FILE_UPLOAD_RESTRICTIONS } from "../../../constants/fileUpload";
import { getRestrictedFileExtension, getRestrictedFileSize, showKendoValidationErrors } from "../../../helpers/fileUpload";
import { SUCCESS_STATUS_CODE } from "../../../constants/http";
import { Upload } from "@progress/kendo-react-upload";

import i18next from "i18next";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";

import kendoEnMessages from './../../../locales/kendo-en.json';
import kendoDeMessages from './../../../locales/kendo-de.json';
import { useTranslation } from "react-i18next";

loadMessages(kendoEnMessages, 'en');
loadMessages(kendoDeMessages, 'de');

export const BenchmarkFiles = ({ benchmarkId, value, onChange, readOnly, restrictions = FILE_UPLOAD_RESTRICTIONS }) => {
  const { t } = useTranslation();
  const files = useBenchmarkFiles();

  const apiFileUrl = '/api/benchmark/file/temporary';
  const [recentlyUploaded, setRecentlyUploaded] = useState([]);
  const [kendoUploadValue, setKendoUploadValue] = useState([]);
  const [fileValidationError, setFileValidationError] = useState(false);
  const messages = i18next.language === "de" ? kendoDeMessages : kendoEnMessages;

  useEffect(() => {
    setBenchmarkFiles(value ?? []);
  }, [benchmarkId, value]);

  const removeFile = (file) => {
    const index = [...value].findIndex(item => item?.id === file?.id);
    if (index !== -1) {
      removeBenchmarkFile(index);
      onChange(benchmarkFilesSubject.getValue());

      const recentlyUploadedFile = recentlyUploaded.find(x => file.id === x.id);
      if (recentlyUploadedFile) { 
        deleteBenchmarkTemporaryFile(recentlyUploadedFile);
        setKendoUploadValue(kendoUploadValue.filter(x => x.uid !== recentlyUploadedFile.fileUid));
      }
    }
  };

  const onAdd = ({ affectedFiles, newState }) => {
    setKendoUploadValue(newState);
    showKendoValidationErrors({ affectedFiles }, messages, showError);
    setFileValidationError(affectedFiles.some(x => !!x.validationErrors && x.validationErrors.length > 0));
  }

  const onStatusChange = ({ response, affectedFiles, newState }) => {
    if (response?.status === SUCCESS_STATUS_CODE) { 
      const [ file ] = affectedFiles;
      const fileDTO = { id: response.response.id, name: file.name, editable: true, fileUid: file.uid };
      const uploadedFiles = [...recentlyUploaded, fileDTO];

      addBenchmarkFile(fileDTO);
      setRecentlyUploaded(uploadedFiles);
      onChange(benchmarkFilesSubject.getValue());
    }

    setKendoUploadValue(newState);
  };

  const onBeforeRemove = (event) => {
    const [ file ] = event.files;
    event.additionalData = recentlyUploaded.find(x => file.uid === x.fileUid);
  };

  const onRemove = ({ response, affectedFiles, newState }) => {
    const [ file ] = affectedFiles;
    if (response?.status === SUCCESS_STATUS_CODE) { 
      setRecentlyUploaded(recentlyUploaded.filter(x => x.fileUid !== file.uid));
      removeBenchmarkFileByFileUid(file.uid);
      onChange(value.filter(x => x.fileUid !== file.uid));
    }

    setKendoUploadValue(newState);
  }

  const filesPath = '/api/benchmark/file/';
  const isEmpty = !files.length;

  return <div className={(readOnly ? ' read-only' : '')}>

      <div className="other">
        {!isEmpty &&
        <FilesTable files={files} readOnly={readOnly} deleteFile={removeFile}
                    updateFiles={onChange} filesPath={filesPath} ignoreEditable/>
        }

        {!readOnly && <div className="files-footer">
          <LocalizationProvider language={i18next.language}>
            <IntlProvider locale={i18next.language}>
              <Upload
                batch={false}
                multiple={true}
                files={kendoUploadValue}
                withCredentials={false}
                saveUrl={apiFileUrl}
                saveField="file"
                removeUrl={apiFileUrl}
                removeField="file"
                removeMethod="DELETE"
                onAdd={onAdd}
                onProgress={({ newState }) => setKendoUploadValue(newState)}
                onBeforeRemove={onBeforeRemove}
                onRemove={onRemove}
                onStatusChange={onStatusChange}
                restrictions={restrictions} />
            </IntlProvider>
          </LocalizationProvider>
          {fileValidationError && <div className="file-upload-restrictions">
            <div>
              <small>{t('fileUpload.allowedExtensions')} {getRestrictedFileExtension(FILE_UPLOAD_RESTRICTIONS)}</small>
            </div>
            <div>
              <small>{t('fileUpload.maximumFileSize')} {getRestrictedFileSize(FILE_UPLOAD_RESTRICTIONS)}</small>
            </div>
          </div>}
        </div>}
      </div>
    </div>;
};
