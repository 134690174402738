import { useEffect, useState } from "react";

export const useTimeout = () => {
  const [timeout, setTimeoutFn] = useState(0);
  useEffect(() => () => clearTimeout(timeout), []);

  const setTimeoutValue = (value) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setTimeoutFn(value);
  };

  return [timeout, setTimeoutValue];
};
