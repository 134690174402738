import IconButton from "@mui/material/IconButton";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { USERS_STATUS_DISABLED, USERS_STATUS_ENABLED } from "../../../constants/users";
import { processMessage } from "../../../utils";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import PropTypes from "prop-types";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../../constants/main";
import { useUsersPageLogic } from "../../../hooks/users";
import { UserAvatar } from "../../UserAvatar/UserAvatar";
import { UserRolesColumnTemplate } from "./UserTableTemplates";
import "./UsersTable.scss";

export const UsersTable = ({
  data,
  loading,
  onDelete,
  onEdit,
  onAddTenant,
  onRemoveTenant,
  onManageRoles,
  setFiltered,
  readOnly,
  hideColumns,
  hideOptions,
}) => {
  const { t } = useTranslation();

  const { searchValue, filterValue, sortValue, onSort, page, rowsPerPage, setPage, setRowsPerPage } =
    useUsersPageLogic();
  const [processed, setProcessed] = useState([]);
  const [popoverTarget, setPopoverTarget] = useState(null);

  const mappedData = useMemo(
    () =>
      (data || []).map((item) => ({
        ...item.user,
        fullName: [item.user.firstName, item.user.lastName].join(" "),
        userTenants: item.userTenants
          .map((itemMappedData) => ({ ...itemMappedData.tenant, roles: itemMappedData.roles }))
          .sort((a, b) => a.id - b.id),
      })),
    [data]
  );

  useEffect(() => {
    if (!loading) {
      let updated = [...mappedData];
      // search
      const searchQuery = String(searchValue).trim().toLowerCase();
      if (searchQuery) {
        updated = updated.filter((item) => {
          return (
            String(item.email).toLowerCase().indexOf(searchQuery) !== -1 ||
            [item.firstName, item.lastName].join("").toLowerCase().indexOf(searchQuery) !== -1 ||
            item.userTenants.some((itemInner) => String(itemInner.name).toLowerCase().indexOf(searchQuery) !== -1)
          );
        });
      }
      setFiltered(updated);
      updated = updated.filter((item) => {
        if (filterValue === USERS_STATUS_ENABLED) {
          return item.status.toLowerCase() === USERS_STATUS_ENABLED.toLowerCase();
        } else if (filterValue === USERS_STATUS_DISABLED) {
          return item.status.toLowerCase() === USERS_STATUS_DISABLED.toLowerCase();
        }
        return true;
      });
      // sort
      updated.sort((a, b) => {
        if (a.id && !b.id) {
          return 1;
        }
        if (b.id && !a.id) {
          return -1;
        }
        if (sortValue.field) {
          if (a[sortValue.field] > b[sortValue.field]) {
            return sortValue.desc ? -1 : 1;
          }
          if (a[sortValue.field] < b[sortValue.field]) {
            return sortValue.desc ? 1 : -1;
          }
        }
        return 0;
      });
      setProcessed(updated);
      if (page * rowsPerPage > updated.length) {
        setPage(Math.floor(updated.length / rowsPerPage));
      }
    }
  }, [loading, searchValue, sortValue, filterValue, page, rowsPerPage, mappedData]);

  const pagination = {
    labelRowsPerPage: t("users.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: processed.length,
    page: page,
    show: processed.length > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pagePagination) => setPage(pagePagination),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  let columns = [
    {
      field: "user",
      headerText: t("users.user"),
      headerClassName: "user-column",
      sortable: false,
      template: UserAvatar,
    },
    {
      field: "fullName",
      headerText: t("users.username"),
      headerClassName: "username-column",
      className: "clarity-mask",
      sortable: true,
    },
    {
      field: "email",
      headerText: "email",
      headerClassName: "email-column",
      className: "clarity-mask",
      sortable: true,
    },
    {
      field: "status",
      headerText: t("users.status.label"),
      headerClassName: "status-column",
      sortable: true,
      className: (row) => {
        return "text-uppercase user-status-" + row.status;
      },
      template: (row) => t("users.status." + row.status),
    },
    {
      field: "superAdmin",
      headerText: t("users.superAdmin"),
      headerClassName: "admin-column",
      sortable: false,
      template: (row) => (row.superAdmin ? t("users.superAdmin") : ""),
    },
    {
      field: "name",
      headerText: t("users.tenant"),
      headerClassName: "tenant-column",
      sortable: false,
      childRecord: true,
    },
    {
      field: "roles",
      headerText: t("users.roles"),
      headerClassName: "roles-column",
      sortable: false,
      childRecord: true,
      template: (row) => <UserRolesColumnTemplate {...row} />,
    },
  ];

  if (hideColumns) {
    columns = columns.filter((x) => !hideColumns[x.field]);
  }

  if (!readOnly) {
    columns.push({
      headerText: "",
      className: "actions-column",
      childRecord: true,
      template: (propsInner, field, rootPropsInner) => (
        <IconButton
          size="small"
          onClick={(event) => setPopoverTarget({ target: event.target, props: propsInner, rootProps: rootPropsInner })}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    });
  }

  const paged = processed.length > rowsPerPage ? [...processed].splice(page * rowsPerPage, rowsPerPage) : processed;
  const { target, rootProps, props } = popoverTarget || {};

  return (
    <>
      <SimpleTableWithSort
        className="users-table"
        data={paged}
        childrenRecordsName="userTenants"
        sortValue={sortValue}
        onSort={onSort}
        pagination={pagination}
        columns={columns}
      />
      <Popover
        open={!!popoverTarget}
        anchorEl={target}
        onClose={() => setPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {!hideOptions?.editUser && (
          <MenuItem
            onClick={() => {
              setPopoverTarget(null);
              onEdit({ user: rootProps });
            }}
          >
            {t("users.actions.editUser")}
          </MenuItem>
        )}
        {!hideOptions?.addTenant && (
          <MenuItem
            onClick={() => {
              console.log("qwer");
              setPopoverTarget(null);
              onAddTenant({ user: rootProps });
            }}
          >
            {t("users.actions.addTenant")}
          </MenuItem>
        )}
        {!hideOptions?.manageRoles && (
          <MenuItem
            disabled={!props?.id}
            onClick={() => {
              setPopoverTarget(null);
              onManageRoles({ user: rootProps, tenant: props });
            }}
          >
            {t("users.actions.manageRoles")}
          </MenuItem>
        )}
        {!hideOptions?.removeTenant && (
          <MenuItem
            disabled={!props?.id}
            onClick={() => {
              setPopoverTarget(null);
              onRemoveTenant(rootProps, props);
            }}
          >
            {t("users.actions.removeTenant")}
          </MenuItem>
        )}
        {!hideOptions?.deleteUser && (
          <MenuItem
            onClick={() => {
              setPopoverTarget(null);
              onDelete(rootProps);
            }}
          >
            {t("users.actions.deleteUser")}
          </MenuItem>
        )}
      </Popover>
    </>
  );
};

UsersTable.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onAddTenant: PropTypes.func,
  onRemoveTenant: PropTypes.func,
  onManageRoles: PropTypes.func,
  setFiltered: PropTypes.func,
  readOnly: PropTypes.bool,
  hideColumns: PropTypes.object,
  hideOptions: PropTypes.object,
};
