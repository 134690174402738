import { DialogActions, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useTranslation } from "react-i18next";

export const ConfirmationDialog = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  thirdAction,
  titleText,
  bodyText,
  confirmText,
  cancelText,
  thirdActionText,
  color,
  showLineBreaks,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={() => onClose && onClose()} className="small">
      <DialogTitle>{titleText}</DialogTitle>
      {!showLineBreaks && <DialogContent>{bodyText}</DialogContent>}
      {!!showLineBreaks && <DialogContent style={{ whiteSpace: "break-spaces" }}>{bodyText}</DialogContent>}
      <DialogActions>
        {!!thirdActionText && !!thirdAction && (
          <Button className="third-action" onClick={thirdAction}>
            {thirdActionText}
          </Button>
        )}
        <span className="flex-spacer"></span>
        <Button
          onClick={() => {
            if (onCancel) {
              onCancel();
            } else {
              onClose && onClose();
            }
          }}
        >
          {cancelText || t("main.cancel")}
        </Button>
        {!!onConfirm && !!confirmText && (
          <Button autoFocus onClick={() => onConfirm && onConfirm()} color={color}>
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
