import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React from "react";
import { useTranslation } from "react-i18next";
import { GROUPING_TYPE_INDICATOR } from "../../../constants/sustainabilitySystem";
import { roundDecimal } from "../../../utils";

export const WeightingColumnTemplate = (row) => {
  const factor = row.weightingFactor;
  const weighting = row.weighting ? roundDecimal(row.weighting) : "";

  return <div className="text-right">{(row.type === GROUPING_TYPE_INDICATOR ? weighting : factor) || ""}</div>;
};

export const WeightingColumnEditTemplate = (row, column, updateValue, submitted) => {
  const { t } = useTranslation();

  const onFactorChange = (value) => {
    updateValue(row.UID, "weightingFactor", roundDecimal(value));
  };

  return (
    <FormControl fullWidth>
      <TextField
        error={submitted && row.error && row.error.field === "weightingFactor"}
        type={"number"}
        key={row.weightingFactor}
        InputProps={{ placeholder: t("grouping.factor") }}
        inputProps={{ step: 0.0001 }}
        defaultValue={row.weightingFactor || 1}
        onBlur={(event) => onFactorChange(event.target.value)}
      />
    </FormControl>
  );
};
