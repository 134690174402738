import { Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TYPE_PAST } from "../../constants/sustainabilitySystem";
import {
  GET_LEFT_INFOBOX_IMAGE_DEFAULT_PATH,
  GET_LEFT_INFOBOX_URL,
  GET_LOGO_COLLAPSED_PATH,
  GET_LOGO_EXPANDED_PATH,
  GET_LOGO_PATH,
  GET_RIGHT_INFOBOX_IMAGE_DEFAULT_PATH,
  GET_RIGHT_INFOBOX_URL,
} from "../../constants/whitelabels";
import { showError } from "../../hooks/toast";
import {
  creteWhitelabelLeftInfoboxImageTemporary,
  creteWhitelabelLogoCollapsedTemporary,
  creteWhitelabelLogoExpandedTemporary,
  creteWhitelabelLogoTemporary,
  creteWhitelabelRightInfoboxImageTemporary,
  getWhitelabelTenant,
  updateWhitelabel,
  useUpdateWhitelabelResponse,
  useWhitelabelLeftInfoboxImageTemporaryResponse,
  useWhitelabelLogoCollapsedTemporaryResponse,
  useWhitelabelLogoExpandedTemporaryResponse,
  useWhitelabelLogoTemporaryResponse,
  useWhitelabelRightInfoboxImageTemporaryResponse,
  useWhitelabelTenantResponse,
} from "../../hooks/whitelabel";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import { MonoToggle } from "../MonoToggle/MonoToggle";
import { getDefaultColors } from "../WhitelabelControl/WhitelabelControl";
import "./WhitelabelDialog.scss";

const { PRIMARY_BACKGROUND_COLOR, SECONDARY_BACKGROUND_COLOR } = getDefaultColors();

export const WhitelabelDialog = ({ onClose, rowData }) => {
  const { t } = useTranslation();
  const whitelabelResponse = useWhitelabelTenantResponse();
  const whitelabelUpdateResponse = useUpdateWhitelabelResponse();
  const imageResponse = useWhitelabelLogoTemporaryResponse();
  const imageCollapsedResponse = useWhitelabelLogoCollapsedTemporaryResponse();
  const imageExpandedResponse = useWhitelabelLogoExpandedTemporaryResponse();
  const leftInfoboxImageResponse = useWhitelabelLeftInfoboxImageTemporaryResponse();
  const rightInfoboxImageResponse = useWhitelabelRightInfoboxImageTemporaryResponse();

  const [isAdapt, setIsAdapt] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [logoId, setLogoId] = useState(null);
  const [expandedLogoId, setExpandedLogoId] = useState(null);
  const [collapsedLogoId, setCollapsedLogoId] = useState(null);
  const [leftInfoboxImageId, setLeftInfoboxImage] = useState(null);
  const [rightInfoboxImageId, setRightInfoboxImage] = useState(null);
  const [leftInfoboxLink, setLeftInfoboxLink] = useState("");
  const [rightInfoboxLink, setRightInfoboxLink] = useState("");
  const [dashboardTitle, setDashboardTitle] = useState("");
  const isPast = rowData?.type === TYPE_PAST;

  const loading =
    whitelabelUpdateResponse.loading ||
    whitelabelResponse.loading ||
    imageResponse.loading ||
    imageCollapsedResponse.loading ||
    imageExpandedResponse.loading ||
    leftInfoboxImageResponse.loading ||
    rightInfoboxImageResponse.loading;

  useEffect(() => {
    setIsAdapt(!rowData?.defaultWhitelabel);

    if (rowData) {
      getWhitelabelTenant(rowData.id).then().catch(console.error);
    }
  }, [rowData]);

  useEffect(() => {
    if (whitelabelResponse?.data) {
      setPrimaryColor(
        whitelabelResponse.data?.primaryColour ? "#" + whitelabelResponse.data?.primaryColour : PRIMARY_BACKGROUND_COLOR
      );
      setSecondaryColor(
        whitelabelResponse.data?.potentialColour
          ? "#" + whitelabelResponse.data?.potentialColour
          : SECONDARY_BACKGROUND_COLOR
      );
      setLogoId(whitelabelResponse.data.logoId);
      setExpandedLogoId(whitelabelResponse.data.expandedLogoId);
      setCollapsedLogoId(whitelabelResponse.data.collapsedLogoId);
      setLeftInfoboxImage(whitelabelResponse.data.leftInfoboxImageId);
      setRightInfoboxImage(whitelabelResponse.data.rightInfoboxImageId);
      setLeftInfoboxLink(whitelabelResponse.data.leftInfoboxLink || GET_LEFT_INFOBOX_URL);
      setRightInfoboxLink(whitelabelResponse.data.rightInfoboxLink || GET_RIGHT_INFOBOX_URL);
      setDashboardTitle(whitelabelResponse.data.dashboardTitle);
    }
  }, [whitelabelResponse?.data]);

  useEffect(() => {
    if (!loading) {
      if (whitelabelUpdateResponse?.data && rowData) {
        rowData.defaultWhitelabel = !isAdapt;
        onClose();
      }
    }
  }, [whitelabelUpdateResponse?.data]);

  const reset = () => {
    imageResponse.data = null;
    imageExpandedResponse.data = null;
    imageCollapsedResponse.data = null;
    leftInfoboxImageResponse.data = null;
    rightInfoboxImageResponse.data = null;
  };

  useEffect(reset, [rowData?.id]);

  useEffect(() => {
    if (!isAdapt) {
      setPrimaryColor(PRIMARY_BACKGROUND_COLOR);
      setSecondaryColor(SECONDARY_BACKGROUND_COLOR);
      setLogoId(whitelabelResponse?.data?.logoId);
      setExpandedLogoId(whitelabelResponse?.data?.expandedLogoId);
      setCollapsedLogoId(whitelabelResponse?.data?.collapsedLogoId);
      setLeftInfoboxImage(whitelabelResponse?.data?.leftInfoboxImageId);
      setRightInfoboxImage(whitelabelResponse?.data?.rightInfoboxImageId);
      setLeftInfoboxLink(GET_LEFT_INFOBOX_URL);
      setRightInfoboxLink(GET_RIGHT_INFOBOX_URL);
      setDashboardTitle("");
    } else {
      setPrimaryColor(
        whitelabelResponse.data?.primaryColour ? "#" + whitelabelResponse.data?.primaryColour : PRIMARY_BACKGROUND_COLOR
      );
      setSecondaryColor(
        whitelabelResponse.data?.potentialColour
          ? "#" + whitelabelResponse.data?.potentialColour
          : SECONDARY_BACKGROUND_COLOR
      );
      setLogoId(imageResponse?.data?.id || whitelabelResponse?.data?.logoId || null);
      setExpandedLogoId(imageExpandedResponse?.data?.id || whitelabelResponse?.data?.expandedLogoId || null);
      setCollapsedLogoId(imageCollapsedResponse?.data?.id || whitelabelResponse?.data?.collapsedLogoId || null);
      setLeftInfoboxImage(leftInfoboxImageResponse?.data?.id || whitelabelResponse?.data?.leftInfoboxImageId || null);
      setRightInfoboxImage(
        rightInfoboxImageResponse?.data?.id || whitelabelResponse?.data?.rightInfoboxImageId || null
      );
      setLeftInfoboxLink(whitelabelResponse.data?.leftInfoboxLink);
      setRightInfoboxLink(whitelabelResponse.data?.rightInfoboxLink);
      setDashboardTitle(whitelabelResponse.data?.dashboardTitle);
    }
  }, [isAdapt]);

  const onSave = () => {
    updateWhitelabel({
      ...whitelabelResponse.data,
      logoId: logoId || whitelabelResponse.data.logoId,
      expandedLogoId: expandedLogoId || whitelabelResponse.data.expandedLogoId,
      collapsedLogoId: collapsedLogoId || whitelabelResponse.data.collapsedLogoId,
      leftInfoboxImageId: leftInfoboxImageId || whitelabelResponse.data.leftInfoboxImageId,
      rightInfoboxImageId: rightInfoboxImageId || whitelabelResponse.data.rightInfoboxImageId,
      primaryColour: primaryColor.replace("#", "").trim(),
      potentialColour: secondaryColor.replace("#", "").trim(),
      leftInfoboxLink: leftInfoboxLink,
      rightInfoboxLink: rightInfoboxLink,
      dashboardTitle: dashboardTitle,
      byDefault: !isAdapt,
    })
      .then()
      .catch(console.error);

    reset();
  };

  const onCancel = () => {
    if (!loading) {
      onClose();
    }
  };

  if (!whitelabelResponse?.data) {
    return null;
  }

  return (
    <Dialog open={!!rowData} onClose={() => onCancel()} className="whitelabel-dialog">
      <LoadingOverlay spinner active={loading} className="whitelabel-dialog-loader">
        <MonoToggle isChecked={isAdapt} onChange={setIsAdapt} label={t("whitelabel.whitelabelTypes.adapt")} />
        <div>
          <FormControl fullWidth>
            <TextField
              key={isAdapt ? 1 : 0}
              type="text"
              onChange={(event) => setDashboardTitle(event.target.value)}
              value={isAdapt ? dashboardTitle : t(isPast ? "dashboard.pastTitle" : "dashboard.esgTitle")}
              label={t("whitelabel.dashboardTitle")}
              disabled={!isAdapt}
            />
          </FormControl>
        </div>
        <div className="logos">
          <LogoUploader
            title={t("whitelabel.companyLogo")}
            imageId={logoId}
            setImageId={setLogoId}
            isAdapt={isAdapt}
            imageResponse={imageResponse}
            imageTemporaryCreator={creteWhitelabelLogoTemporary}
            imgPath={GET_LOGO_PATH}
          />
          <LogoUploader
            title={t("whitelabel.appLogoBig")}
            imageId={expandedLogoId}
            setImageId={setExpandedLogoId}
            isAdapt={isAdapt}
            imageResponse={imageExpandedResponse}
            imageTemporaryCreator={creteWhitelabelLogoExpandedTemporary}
            imgPath={GET_LOGO_EXPANDED_PATH}
          />
          <LogoUploader
            title={t("whitelabel.appLogoSmall")}
            imageId={collapsedLogoId}
            setImageId={setCollapsedLogoId}
            isAdapt={isAdapt}
            imageResponse={imageCollapsedResponse}
            imageTemporaryCreator={creteWhitelabelLogoCollapsedTemporary}
            imgPath={GET_LOGO_COLLAPSED_PATH}
          />
        </div>
        <div className="info-boxes">
          <LogoUploader
            title={t("whitelabel.leftInfobox")}
            imageId={leftInfoboxImageId}
            setImageId={setLeftInfoboxImage}
            isAdapt={isAdapt}
            imageResponse={leftInfoboxImageResponse}
            noMax
            className="large-image"
            imageTemporaryCreator={creteWhitelabelLeftInfoboxImageTemporary}
            imgPath={GET_LEFT_INFOBOX_IMAGE_DEFAULT_PATH}
          />
          <LogoUploader
            title={t("whitelabel.rightInfobox")}
            imageId={rightInfoboxImageId}
            setImageId={setRightInfoboxImage}
            isAdapt={isAdapt}
            imageResponse={rightInfoboxImageResponse}
            noMax
            className="large-image"
            imageTemporaryCreator={creteWhitelabelRightInfoboxImageTemporary}
            imgPath={GET_RIGHT_INFOBOX_IMAGE_DEFAULT_PATH}
          />
        </div>
        <div className="info-boxes">
          <FormControl>
            <TextField
              type="text"
              value={leftInfoboxLink}
              onChange={(event) => setLeftInfoboxLink(event.target.value)}
              label={t("whitelabel.leftInfoboxLink")}
              disabled={!isAdapt}
            />
          </FormControl>
          <FormControl>
            <TextField
              type="text"
              value={rightInfoboxLink}
              onChange={(event) => setRightInfoboxLink(event.target.value)}
              label={t("whitelabel.rightInfoboxLink")}
              disabled={!isAdapt}
            />
          </FormControl>
        </div>
        <div className="colors-wrapper">
          <p>Farben</p>
          <div className="inputs">
            <ColorInput
              label="Primärfarbe"
              setColor={setPrimaryColor}
              color={isAdapt ? primaryColor : PRIMARY_BACKGROUND_COLOR}
              readOnly={!isAdapt}
            />
            <ColorInput
              label="Potentialfarbe"
              setColor={setSecondaryColor}
              color={isAdapt ? secondaryColor : SECONDARY_BACKGROUND_COLOR}
              readOnly={!isAdapt}
            />
          </div>
        </div>
        <div className="text-right">
          <Button onClick={() => onCancel()}>{t("main.cancel")}</Button>
          <Button color="primary" onClick={() => onSave()}>
            {t("main.save")}
          </Button>
        </div>
      </LoadingOverlay>
    </Dialog>
  );
};

export const ColorInput = ({ label, color, setColor, readOnly }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(color || "");
  }, [color]);

  const onChange = (e) => {
    setValue(e.target.value);
    hexValidator(e.target.value);
  };

  const hexValidator = (valueHexValidator) => {
    if (/^([0-9A-F]{3}){1,2}$/i.test(valueHexValidator) && !readOnly) {
      setColor("#" + valueHexValidator);
    }
  };

  return (
    <FormControl className="color-input">
      <div className="color" style={{ background: color }} />
      <TextField type="text" value={value.replace("#", "")} onChange={onChange} label={label} disabled={readOnly} />
    </FormControl>
  );
};

export const LogoUploader = ({
  title,
  isAdapt,
  imageId,
  setImageId,
  imageResponse,
  imageTemporaryCreator,
  imgPath,
  noMax,
  className,
}) => {
  const { t } = useTranslation();

  const [file, setFile] = useState(null);
  const [dragOver, setDragOver] = useState(false);

  const inputUploadRef = React.useRef(null);
  const draftLogoRef = React.useRef(null);

  useEffect(() => {
    if (imageResponse?.data) {
      setImageId(imageResponse.data.id);
    }
  }, [imageResponse?.data]);

  useEffect(() => {
    const width = draftLogoRef.current?.width;
    const height = draftLogoRef.current?.height;

    if (!noMax && (width > 800 || height > 400)) {
      showError(t("whitelabel.logoOversize"));
    } else if (file) {
      imageTemporaryCreator(file).then().catch(console.error);
    }
  }, [file]);

  const getFile = (fileInner) => {
    const reader = new FileReader();
    const logoListener = () => {
      setFile(fileInner);
      draftLogoRef.current.removeEventListener("load", logoListener);
      reader.removeEventListener("load", readerListener);
    };
    const readerListener = () => {
      draftLogoRef.current.addEventListener("load", logoListener);
      draftLogoRef.current.src = reader.result;
    };

    reader.readAsDataURL(fileInner);
    reader.addEventListener("load", readerListener);

    inputUploadRef.current.value = "";
  };

  const dropHandler = (e) => {
    cancelEvent(e);
    setDragOver(false);

    const { files } = e.dataTransfer;

    if (files?.length && files[0].type.startsWith("image")) {
      getFile(files[0]);
    } else {
      showError(t("whitelabel.logoNotImage"));
    }
  };

  const dragEnterHandler = (e) => {
    cancelEvent(e);
    setDragOver(true);
  };

  const dragLeaveHandler = (e) => {
    cancelEvent(e);
    setDragOver(false);
  };

  const cancelEvent = (e) => e.preventDefault() & e.stopPropagation();

  return (
    <div className={"logo-wrapper" + (className ? " " + className : "")}>
      <p>{title}</p>
      <img className="draft-logo" src="" ref={draftLogoRef} alt="img" />
      {isAdapt ? (
        <>
          <div className="img-preview">
            {imageId ? (
              <img src={"/api/security/whitelabel/picture/" + imageId} alt="logo" />
            ) : (
              <img src={imgPath} alt="logo" />
            )}
          </div>
          <div
            className={`upload-area ${dragOver ? "drag-over" : ""}`}
            onDragOver={cancelEvent}
            onDragEnter={dragEnterHandler}
            onDragLeave={dragLeaveHandler}
            onDrop={dropHandler}
          >
            <label>
              Click to Upload
              <input
                id={title}
                ref={inputUploadRef}
                type="file"
                multiple={false}
                onChange={(e) => getFile(e.target.files[0])}
                accept="image/jpg,image/png,image/jpeg,image/svg+xml,image/gif"
              />
            </label>
            <span>
              {" "}
              or drag and drop <br /> SVG, PNG, JPG or GIF{!noMax && " (max. 800x400px)"}
            </span>
          </div>
        </>
      ) : (
        <img src={imgPath} alt="logo" />
      )}
    </div>
  );
};
