import { DrawerItem } from "@progress/kendo-react-layout";
import { NavLink } from "react-router-dom";
import { hasAnyPermissions } from "../../helpers/permission";

import { useMsal } from "@azure/msal-react";
import { Popup } from "@progress/kendo-react-popup";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GET_LOGO_PATH } from "../../constants/whitelabels";
import { SIDEBAR_OPEN_LOCAL_STORAGE, menuSubject, useMenu } from "../../hooks";
import { getUserTenantName, handleLogout, useUser } from "../../hooks/auth";
import { ChevronDownIcon } from "../../svg/sidebar/icon-chevron-down";
import { ChevronUpIcon } from "../../svg/sidebar/icon-chevron-up";
import { CollapseSidebarIcon } from "../../svg/sidebar/icon-collapse-sidebar";
import { ExpandSidebarIcon } from "../../svg/sidebar/icon-expand-sidebar";
import { MenuIcon } from "../../svg/sidebar/icon-menu";
import { UserIcon } from "../../svg/sidebar/icon-user";
import "./DefaultLayout.scss";

import { Box } from "@mui/material";
import { LANGUAGE_DE, LANGUAGE_EN } from "../../constants/language";
import { handleError } from "../../helpers/error";
import { updateUserLanguage } from "../../hooks/users";
import { FOOTER_INDEX, HEADER_INDEX } from "./Items";

import PropTypes from "prop-types";
import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";

const createClassName = (drawerOpen, selected, isSelected) =>
  `itemContainer  ${drawerOpen ? "itemContainerOpen" : ""} ${selected && !drawerOpen ? "selectedCollapsed" : ""} ${
    selected && drawerOpen ? "selectedExpanded" : ""
  } ${isSelected && "selectedExpanded"}`;

const canRenderItem = (props, drawerOpen) =>
  !(
    props.visible === false ||
    (props.permissions && !hasAnyPermissions(props.permissions)) ||
    props?.hiddenCondition?.() ||
    (props.level !== undefined && !drawerOpen)
  );

const handleClickOutside = (popupRef, setShowPopup) => (event) => {
  if (popupRef.current && !popupRef.current.contains(event.target)) {
    setShowPopup(false);
  }
};

const usePopupHandler = (showPopup, setShowPopup, popupRef) => {
  useEffect(() => {
    const handler = handleClickOutside(popupRef, setShowPopup);
    if (showPopup) {
      document.addEventListener("mousedown", handler);
    } else {
      document.removeEventListener("mousedown", handler);
    }
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [showPopup]);
};

const languageSubject = new BehaviorSubject(null);
const useLanguage = hookFromSubject(languageSubject);
const setLanguage = (language) => languageSubject.next(language);

const LanguageSwitcher = () => {
  const language = useLanguage();
  const { i18n } = useTranslation();
  const user = useUser();

  const changeLanguage = (value, update = true) => {
    i18n.changeLanguage(value);
    setLanguage(value);
    if (update) updateUserLanguage(value).then().catch(handleError);
  };

  useEffect(() => {
    if (user && !language) {
      const languageInner = user.user.language === "GERMAN" ? LANGUAGE_DE : LANGUAGE_EN;
      changeLanguage(languageInner, false);
    }
  }, [user?.id]);

  return (
    <Box onClick={() => changeLanguage(language === "de" ? "en" : "de")} className="popup-link">
      {language === "de" ? "Umschalten auf Englisch" : "Switch to German"}
    </Box>
  );
};

const PopupContent = ({ t, instance, showPopup, setShowPopup }) => {
  const popupRef = useRef();
  usePopupHandler(showPopup, setShowPopup, popupRef);

  return (
    <Popup show={showPopup} className={"popup-content"} animate={false}>
      <span ref={popupRef} style={{ display: "flex", flexDirection: "column" }}>
        <LanguageSwitcher />
        <NavLink to={`/tenants`} className="popup-link">
          {t("tenantSelect.title")}
        </NavLink>
        <Box onClick={() => handleLogout(instance)} className="popup-link">
          {t("main.signOut")}
        </Box>
      </span>
    </Popup>
  );
};

PopupContent.propTypes = {
  t: PropTypes.func,
  instance: PropTypes.object,
  showPopup: PropTypes.bool,
  setShowPopup: PropTypes.func,
};

const Header = ({ drawerOpen, setDrawerOpen, props }) => (
  <DrawerItem>
    <div className="header">
      {drawerOpen ? (
        <>
          <div className="content">
            <DrawerItem {...props} className="logo-container">
              <img src={GET_LOGO_PATH} alt="logo" className="logo" />
            </DrawerItem>
            <span className="tenant">{getUserTenantName()}</span>
          </div>
          <Box className="icon" onClick={() => setDrawerOpen(false)}>
            <CollapseSidebarIcon />
          </Box>
        </>
      ) : (
        <Box className="icon" style={{ padding: "5px", marginLeft: "0" }} onClick={() => setDrawerOpen(true)}>
          <ExpandSidebarIcon />
        </Box>
      )}
    </div>
  </DrawerItem>
);

Header.propTypes = {
  drawerOpen: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
  props: PropTypes.object,
};

const Footer = ({ fullName, t, instance, showPopup, setShowPopup }) => (
  <DrawerItem className="footer">
    <div className="content">
      <div className="avatar">
        <UserIcon />
      </div>

      <div className="info">
        <span className="name">{fullName}</span>
      </div>
    </div>
    <Box className="icon" onClick={() => setShowPopup(!showPopup)}>
      <MenuIcon />
    </Box>
    <PopupContent t={t} instance={instance} showPopup={showPopup} setShowPopup={setShowPopup} />
  </DrawerItem>
);

Footer.propTypes = {
  fullName: PropTypes.string,
  t: PropTypes.func,
  instance: PropTypes.object,
  showPopup: PropTypes.bool,
  setShowPopup: PropTypes.func,
};

const MainContent = ({ mainContentProps, drawerOpen, t }) => {
  const arrowDir = mainContentProps?.dataExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />;
  return (
    canRenderItem(mainContentProps, drawerOpen) && (
      <DrawerItem {...mainContentProps}>
        <div className={createClassName(drawerOpen, mainContentProps?.selected, mainContentProps?.isSelected)}>
          <span className={drawerOpen ? mainContentProps.level && "pointIcon" : "iconCollapsed"}>
            {mainContentProps.svgIcon}
          </span>
          <div className="item">
            <NavLink className="text">{t(mainContentProps.text)}</NavLink>
          </div>
          <div className="chevron">{mainContentProps?.dataExpanded && arrowDir}</div>
        </div>
      </DrawerItem>
    )
  );
};

MainContent.propTypes = {
  mainContentProps: PropTypes.object,
  drawerOpen: PropTypes.bool,
  t: PropTypes.func,
};

export const CustomItem = (props) => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const user = useUser();
  const menu = useMenu();
  const drawerOpen = menu.open;

  const setDrawerOpen = (open) => {
    menuSubject.next({ open });
    localStorage.setItem(SIDEBAR_OPEN_LOCAL_STORAGE, open);
  };

  const { firstName, lastName } = user?.user || {};
  const fullName = [firstName || "", lastName || ""].join(" ");
  const [showPopup, setShowPopup] = useState(false);

  if (props.index === HEADER_INDEX)
    return <Header drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} props={props} />;

  if (props.index === FOOTER_INDEX)
    return <Footer fullName={fullName} t={t} instance={instance} showPopup={showPopup} setShowPopup={setShowPopup} />;

  return <MainContent mainContentProps={props} drawerOpen={drawerOpen} t={t} />;
};

CustomItem.propTypes = {
  index: PropTypes.number,
};
