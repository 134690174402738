import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { STATUS_RELEASED } from "../../../../constants/benchmark";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../../../constants/main";
import { searchBenchmarks, useBenchmarkPageResponse } from "../../../../hooks/benchmark";
import { resetRatingSystemBenchmarksState, useRatingSystemBenchmarksLogic } from "../../../../hooks/ratingSystem";
import { useEvent } from "../../../../hooks/utils/useEvent";
import { useTimeout } from "../../../../hooks/utils/useTimeout";
import { processMessage } from "../../../../utils";
import { LoadingOverlay } from "../../../LoadingOverlay/LoadingOverlay";
import { SimpleTableWithSort } from "../../../SimpleTableWithSort/SimpleTableWithSort";
import "./BenchmarksDialog.scss";

export const BenchmarksDialog = ({ open, onClose, onSelect, kpis }) => {
  const { t } = useTranslation();

  const benchmarkPageResponse = useBenchmarkPageResponse();
  const [processed, setProcessed] = useState([]);
  const [total, setTotal] = useState(0);

  const { searchValue, setSearchValue, page, rowsPerPage, setPage, setRowsPerPage, resetState } =
    useRatingSystemBenchmarksLogic();
  const [, setSearchTimeout] = useTimeout();

  const load = () =>
    searchBenchmarks({
      page: page + 1,
      itemsPerPage: rowsPerPage,
      search: searchValue,
      status: STATUS_RELEASED,
      kpis,
    });

  useEffect(() => {
    if (!open) {
      resetRatingSystemBenchmarksState();
      setProcessed([]);
      setTotal(0);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      load();
    }
  }, [open, searchValue, page, rowsPerPage]);

  useEffect(() => {
    if (!benchmarkPageResponse.loading && benchmarkPageResponse.data) {
      setProcessed(benchmarkPageResponse.data?.content || []);
      setTotal(benchmarkPageResponse.data?.meta?.total);
    }
  }, [benchmarkPageResponse.loading]);

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerText: t("benchmark.name"),
        headerClassName: "name-column",
      },
      {
        field: "description",
        headerText: t("benchmark.description"),
        headerClassName: "company-name-column",
      },
      {
        field: "kpiUnit",
        headerText: t("benchmark.unit"),
        headerClassName: "type-column",
      },
    ],
    []
  );

  const search = useEvent((value) => {
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(value || "").trim());
      }, 500)
    );
  });

  const onRowClick = useEvent((row) => {
    onClose();
    onSelect(row.id);
  });

  const pagination = {
    labelRowsPerPage: t("pagination.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: total,
    page: page,
    show: total > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pagePagination) => setPage(pagePagination),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  return (
    <Dialog open={open} onClose={onClose} className="benchmarks-dialog">
      <LoadingOverlay spinner className="auto-height" active={benchmarkPageResponse.loading}>
        <div className="flex-row">
          <div className="flex-auto">
            <DialogTitle>{t("ratingSystem.benchmarkSelectTitle")}</DialogTitle>
          </div>
          <div className="k-flex-1 search-box">
            <SearchIcon />
            <input
              type="text"
              key={resetState}
              defaultValue={searchValue || ""}
              className="search-input input-shadow"
              placeholder={t("main.search")}
              onChange={(event) => search(event.target.value)}
            />
          </div>
        </div>

        <div className="dialog-body">
          <SimpleTableWithSort
            className="modal-table"
            data={processed}
            pagination={pagination}
            columns={columns}
            onRowClick={onRowClick}
          />
        </div>
      </LoadingOverlay>

      <div className="buttons text-right">
        <Button className="close-goal-dialog" onClick={() => onClose()}>
          {t("main.close")}
        </Button>
      </div>
    </Dialog>
  );
};
