import { ApplicationVersionEndpointApi, Configuration } from "../generated-api";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { getAuthorizationHeader, handleUnauthorized } from './auth';

const versionApi = new ApplicationVersionEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const SIDEBAR_OPEN_LOCAL_STORAGE = 'sidebar_open';

export const versionSubject = new BehaviorSubject(new PromiseSubjectState());
export const useVersionResponse = hookFromSubject(versionSubject);
export const getVersion = () =>
  promiseToSubject(versionApi.apiApplicationVersionCurrentGet(), versionSubject);

export const menuSubject = new BehaviorSubject({ open: localStorage.getItem(SIDEBAR_OPEN_LOCAL_STORAGE) === 'true' });
export const useMenu = hookFromSubject(menuSubject);

export const windowSizeSubject = new BehaviorSubject({ width: window.innerWidth, height: window.innerHeight });
export const useWindowSize = hookFromSubject(windowSizeSubject);
window.addEventListener('resize', () => {
  windowSizeSubject.next({ width: window.innerWidth, height: window.innerHeight });
});

export const windowScrollSubject = new BehaviorSubject({top: window.scrollY, left: window.scrollX});
export const useWindowScroll = hookFromSubject(windowScrollSubject);
window.addEventListener('scroll', () => {
  windowScrollSubject.next({top: window.scrollY, left: window.scrollX});
});

export const windowPopStateSubject = new BehaviorSubject(null);
export const setPopState = () => windowPopStateSubject.next(true);
export const checkPopState = () => {
  const state = windowPopStateSubject.getValue();
  windowPopStateSubject.next(false);
  return state;
};
window.onpopstate = () => setPopState();

