import React, { useEffect, useRef } from "react";
import { generateGroupingElementSvgESG } from "./groupingElementSvgESG";
import "./GroupingElementChart.scss";
import { useTranslation } from "react-i18next";

export const GroupingElementChartESG = ({ data }) => {
  const { i18n } = useTranslation();
  const chartRef = useRef(null);
  const middleRef = useRef(null);
  const { potentialScore, universalScore, title } = data;

  useEffect(() => {
      if (chartRef && chartRef.current) {
        chartRef.current.innerHTML = '';
        if (data) {
          chartRef.current.appendChild(generateGroupingElementSvgESG(universalScore, potentialScore));
        }
      }
    },
    [data, chartRef]
  );

  return (
    <div className="grouping-element-chart" data-cy={data.systemReference}>
      <div ref={chartRef} className="chart"/>
      <div ref={middleRef} className="middle-content">
        <div className="header-text text-hyphens" lang={i18n.language}>{title}</div>
        <div className="value-text">{universalScore}%</div>
        <div className={'potential-text' + (potentialScore < universalScore ? ' potential-text-low' : '')}>
          {potentialScore !== universalScore ? `(${potentialScore}%)` : ''}
        </div>
      </div>
    </div>
  );
};
