import { Configuration, ProjectFieldsEndpointApi } from "../generated-api";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { createTablePageLogic } from "./tablePageLogic";
import { ATTRIBUTE_STATUS_ACTIVE } from "../constants/projectAttribute";

const projectFieldsApi = new ProjectFieldsEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const getAdminProjectAttributeListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useAdminProjectAttributeListResponse = hookFromSubject(getAdminProjectAttributeListSubject);
export const getAdminProjectAttributeList = (projectType) =>
  promiseToSubject(projectFieldsApi.apiSecurityProjectFieldsProjectTypeGet({ projectType }), getAdminProjectAttributeListSubject);


export const updateAdminProjectAttributesSubject = new BehaviorSubject(new PromiseSubjectState());
export const useAdminProjectAttributesUpdateResponse = hookFromSubject(updateAdminProjectAttributesSubject);
export const updateAdminProjectAttributes = (projectType, descriptorDTO) =>
  promiseToSubject(projectFieldsApi.apiSecurityProjectFieldsProjectTypePut({
    projectType,
    descriptorDTO
  }), updateAdminProjectAttributesSubject);

export const getAdminGeneralProjectAttributeListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useAdminGeneralProjectAttributeListResponse = hookFromSubject(getAdminGeneralProjectAttributeListSubject);
export const getAdminGeneralProjectAttributeList = () =>
  promiseToSubject(projectFieldsApi.apiSecurityProjectFieldsGeneralGet(), getAdminGeneralProjectAttributeListSubject);

export const createAdminGeneralProjectAttributeSubject = new BehaviorSubject(new PromiseSubjectState());
export const useAdminGeneralProjectAttributeCreateResponse = hookFromSubject(createAdminGeneralProjectAttributeSubject);
export const createAdminGeneralProjectAttribute = (fieldDTO) =>
  promiseToSubject(projectFieldsApi.apiSecurityProjectFieldsGeneralPost({ fieldDTO }), createAdminGeneralProjectAttributeSubject);

export const updateAdminGeneralProjectAttributeSubject = new BehaviorSubject(new PromiseSubjectState());
export const useAdminGeneralProjectAttributeUpdateResponse = hookFromSubject(updateAdminGeneralProjectAttributeSubject);
export const updateAdminGeneralProjectAttribute = (fieldDTO) =>
  promiseToSubject(projectFieldsApi.apiSecurityProjectFieldsGeneralPut({ fieldDTO }), updateAdminGeneralProjectAttributeSubject);

export const clearAdminGeneralProjectAttributeSubject = new BehaviorSubject(new PromiseSubjectState());
export const useAdminGeneralProjectAttributeClearResponse = hookFromSubject(clearAdminGeneralProjectAttributeSubject);
export const clearAdminGeneralProjectAttribute = (id) =>
  promiseToSubject(projectFieldsApi.apiSecurityProjectFieldsGeneralIdClearPut({ id }, { returnResponse: true }), clearAdminGeneralProjectAttributeSubject);

export const projectAttributeDragStartSubject = new BehaviorSubject(null);
export const useProjectAttributeDragStart = hookFromSubject(projectAttributeDragStartSubject);
export const setProjectAttributeDragStart = value => projectAttributeDragStartSubject.next(value);
export const projectAttributeDragSubject = new BehaviorSubject(null);
export const useProjectAttributeDrag = hookFromSubject(projectAttributeDragSubject);
export const setProjectAttributeDrag = value => projectAttributeDragSubject.next(value);
export const projectAttributeLandingsSubject = new BehaviorSubject([]);
export const useProjectAttributeLandings = hookFromSubject(projectAttributeLandingsSubject);
export const setProjectAttributeLandings = value => projectAttributeLandingsSubject.next(value);

export const projectTypeAttributesSubject = new BehaviorSubject([]);
export const useProjectTypeAttributes = hookFromSubject(projectTypeAttributesSubject);
export const setProjectTypeAttributes = value => projectTypeAttributesSubject.next(value);

export const [useGeneralAttributeAddPageLogic, resetGeneralAttributeAddPageState] = createTablePageLogic();
export const [useGeneralProjectAttributePageLogic, resetGeneralProjectAttributePageState] =
  createTablePageLogic({ filterValue: ATTRIBUTE_STATUS_ACTIVE });
