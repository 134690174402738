import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import "./DecisionSection.scss";

export const DecisionSection = ({ title, rows, onAccept, onReject }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  return (
    <div className="decision-section">
      <p>{title}</p>
      <Table className="modal-table with-borders">
        <TableHead>
          <TableRow>
            <TableCell className="kpi-cell">{t("kpi.decisions.kpiName")}</TableCell>
            <TableCell className="projectAttribute-cell">{t("kpi.decisions.projectField")}</TableCell>
            <TableCell className="actions-cell" />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(({ kpi, field, decision }, index) => (
            <TableRow key={index}>
              <TableCell className="kpi-cell">
                {kpi.name} ({kpi.unit}){kpi.currentValue ? ": " + kpi.currentValue : ""}
              </TableCell>
              <TableCell className="projectAttribute-cell">
                {(field?.field?.details || {})[language]} ({field?.field?.name})
                {field?.value ? ": " + field?.value : ""}
              </TableCell>
              {!decision && (
                <TableCell className="actions-cell with-actions">
                  {!!onAccept && (
                    <Button size="small" color="primary" onClick={() => onAccept(kpi.nameAndUnit)}>
                      {t("kpi.decisions.accept")}
                    </Button>
                  )}
                  {!!onReject && (
                    <Button size="small" color="secondary" onClick={() => onReject(kpi.nameAndUnit)}>
                      {t("kpi.decisions.reject")}
                    </Button>
                  )}
                </TableCell>
              )}
              {!!decision && <TableCell className="actions-cell">{t("kpi.decisions.decision." + decision)}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
