import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PromiseSubjectState } from "react-rxjs-easy";
import { HUNDRED } from "../../../constants/main";
import { indicatorActionsListSubject } from "../../../hooks/action";
import { showDialog } from "../../../hooks/dialog";
import { forEachRecursive, roundDecimal } from "../../../utils";
import { AddActionsDialog } from "../../AddActionsDialog/AddActionsDialog";
import { CollapsiblePanel } from "../../CollapsiblePanel/CollapsiblePanel";
import { ResponsibleBlock } from "../../ResponsibleIndicator/ResponsibleBlock";
import { ActionsBlock } from "../IndicatorElementDockBlocks/ActionsBlock";
import { CurrentBlock } from "./CurrentBlock";

export const IndicatorElementDockPAST = ({
  rows,
  data,
  readOnly,
  projectId,
  onChange,
  permissions,
  ratingSystemId,
}) => {
  const { t } = useTranslation();
  const { indicatorName, customName, universalScore, maxSystemScoreProportion, koValue, UID } = data || {};
  const [editingName, setEditingName] = useState(false);
  const [customNameValue, setCustomNameValue] = useState("");

  const onCustomNameChange = ({ customName: customNameChange }) => {
    const updated = [...rows];

    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        node.customName = customNameChange ?? customNameValue;
        return true;
      }
    });

    onChange(updated);
    setEditingName(false);
  };

  useEffect(() => {
    setCustomNameValue(customName ?? indicatorName);
  }, [UID]);

  const onReasonChange = ({ UID: UIDCReasonChange, fields, files }) => {
    const updated = [...rows];
    forEachRecursive(updated, (node) => {
      if (node.UID === UIDCReasonChange) {
        const { reason, weightingFactor, evaluationSystemScore } = fields || {};
        node.reason = reason;
        node.weightingFactor = +weightingFactor;
        node.evaluationSystemScore = evaluationSystemScore;
        node.evaluationFiles = files || [];
        return true;
      }
    });
    onChange(updated);
  };

  const onActionsChange = ({ UID: UIDActionsChange, actions = [] }) => {
    const updated = [...rows];
    forEachRecursive(updated, (node) => {
      if (node.UID === UIDActionsChange) {
        node.actions = actions;
        return true;
      }
    });
    onChange(updated);
    indicatorActionsListSubject.next(new PromiseSubjectState(actions));
  };

  const showAddActionsDialog = (row, passThrough) => {
    const { readOnly: readOnlyAddActions } = passThrough || {};
    showDialog({
      className: "xlarge",
      closeOnClickOutside: !!readOnlyAddActions,
      getContent: (onClose) => (
        <AddActionsDialog
          row={row}
          onClose={onClose}
          readOnly={readOnlyAddActions}
          onChange={onActionsChange}
          grouping={rows}
          changeGrouping={onChange}
        />
      ),
    });
  };

  const onResponsibleChange = ({ UID: UIDResponsibleChange, fields = [] }) => {
    const updated = [...rows];
    forEachRecursive(updated, (node) => {
      if (node.UID === UIDResponsibleChange) {
        const { responsible } = fields || {};
        node.responsible = responsible;
        return true;
      }
    });
    onChange(updated);
  };

  const onCustomNameKeyUp = ({ key, target }) => {
    if (key.toLowerCase() === "enter") {
      setCustomNameValue(target.value);
      onCustomNameChange({ customName: target.value });
    }
  };

  const onCustomNameEditingToggle = () => {
    setEditingName(!editingName);
    customName && setCustomNameValue(customName);
  };

  const onCustomNameDefaultClick = () => {
    setCustomNameValue(indicatorName);

    if (!editingName) {
      onCustomNameChange({ customName: indicatorName });
    }
  };

  return (
    <div className="indicator-element-dock">
      <div className="data-block white-block">
        <h3 className={"indicator-element-name"}>
          {!editingName && (customName ?? indicatorName)}
          {!readOnly && editingName && (
            <TextField
              className="edit-name-input"
              value={customNameValue}
              onChange={({ target }) => setCustomNameValue(target.value)}
              onKeyUp={onCustomNameKeyUp}
            />
          )}
          {!readOnly && (
            <IconButton size="small" className="edit-name-button" onClick={onCustomNameEditingToggle}>
              {!editingName && <EditIcon />}
              {editingName && <CloseIcon />}
            </IconButton>
          )}
          {!readOnly && customName && customName !== indicatorName && (
            <IconButton size="small" onClick={onCustomNameDefaultClick}>
              <RefreshIcon className="horizontal-flip" />
            </IconButton>
          )}
          {!readOnly && editingName && (
            <IconButton size="small" color="primary" onClick={onCustomNameChange}>
              <CheckIcon />
            </IconButton>
          )}
        </h3>
      </div>
      <div className="data-block gray-block">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.metrics")}</h3>}>
          <table>
            <tbody>
              <tr className="title-row">
                <td>
                  <label>{t("ratingSystem.indicator.pastMaxSystemScoreProportion")}</label>
                </td>
                <td>
                  <label>{t("grouping.koValue")}</label>
                </td>
              </tr>
              <tr className="data-row">
                <td>
                  <span>{roundDecimal(maxSystemScoreProportion, HUNDRED)}%</span>
                </td>
                <td>
                  <span>{roundDecimal(koValue, HUNDRED) || ""}</span>
                </td>
              </tr>
              <tr className="title-row">
                <td>
                  <label>{t("ratingSystem.indicator.pastUniversalScore")}</label>
                </td>
              </tr>
              <tr className="data-row">
                <td>
                  <span>{roundDecimal(universalScore, HUNDRED)}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsiblePanel>
      </div>

      <div className="data-block current">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.currentPAST")}</h3>}>
          <CurrentBlock
            row={data}
            readOnly={readOnly}
            onChange={onReasonChange}
            projectId={projectId}
            permissions={permissions}
            ratingSystemId={ratingSystemId}
          />
        </CollapsiblePanel>
      </div>

      <div className="data-block">
        <CollapsiblePanel
          header={
            <h3>
              {t("ratingSystem.columnGroup.actions")}
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  showAddActionsDialog(data, { readOnly });
                }}
                size="small"
              >
                <DescriptionOutlinedIcon />
              </IconButton>
            </h3>
          }
        >
          <ActionsBlock row={data} readOnly={readOnly} onChange={onActionsChange} />
        </CollapsiblePanel>
      </div>

      <div className="data-block">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.responsible")}</h3>}>
          <ResponsibleBlock row={data} readOnly={readOnly} onChange={onResponsibleChange} />
        </CollapsiblePanel>
      </div>
    </div>
  );
};
