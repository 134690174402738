import CheckIcon from "@mui/icons-material/Check";
import Popover from "@mui/material/Popover";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { getMissingStatuses } from "../../../helpers/ratingSystem";
import { StatusIndicator } from "../StatusIndicator/StatusIndicator";

export const NewRatingSystemStatusIndicator = ({ row, setPopoverTarget }) => (
  <StatusIndicator
    setPopoverTarget={(target) => setPopoverTarget({ row, target })}
    top={row?.trafficLightScore}
    middle={row?.trafficLightReason}
    bottom={row?.trafficLightActions}
  />
);

NewRatingSystemStatusIndicator.propTypes = {
  row: PropTypes.object,
  setPopoverTarget: PropTypes.func,
};

export const RatingSystemStatusIndicator = ({ row, setPopoverTarget }) => (
  <StatusIndicator
    setPopoverTarget={(target) => setPopoverTarget({ row, target })}
    top={!row?.evaluationSystemScore && row?.evaluationSystemScore !== 0 ? -1 : 1}
    middle={!row?.reason ? -1 : 1}
    bottom={row?.actions?.length >= 1 ? 1 : row?.evaluationSystemScore !== row?.potentialEvaluationSystemScore ? -1 : 0}
  />
);

export const PASTRatingSystemStatusIndicator = ({ row, setPopoverTarget }) => (
  <StatusIndicator
    setPopoverTarget={(target) => setPopoverTarget({ row, target })}
    top={!row?.evaluationSystemScore && row?.evaluationSystemScore !== 0 ? -1 : 1}
    middle={!row?.reason ? -1 : 1}
    bottom={row?.evaluationSystemScore !== row?.maxSystemScore ? (row?.actions?.length < 1 ? -1 : 1) : 0}
  />
);

export const RatingSystemStatusIndicatorPopover = ({ popoverTarget, setPopoverTarget, actionCondition }) => {
  const { t } = useTranslation();
  const { row, target } = popoverTarget || {};
  const { reasonPoint, scorePoint, actionsPoint, mustHaveActions } = getMissingStatuses(row, actionCondition);
  const points = scorePoint + reasonPoint + actionsPoint;

  return (
    <Popover
      className={"tooltip-popover rating-system-status-indicator-popover"}
      open={!!target}
      anchorEl={target}
      onClose={() => setPopoverTarget(null)}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      disableRestoreFocus
    >
      <div className="flex-row">
        <span className="flex-auto">{t("ratingSystem.status.occupancyRate")}</span>
        <span>
          {points}{" "}
          <span className="text-muted">
            {t("main.of")} {mustHaveActions ? 3 : 2}
          </span>
        </span>
      </div>
      <div className="separator" />
      <div className="flex-row">
        <span className="flex-auto">{t("ratingSystem.status.systemScore")}</span>
        <span>{scorePoint ? <CheckIcon /> : null}</span>
      </div>
      <div className="flex-row">
        <span className="flex-auto">{t("ratingSystem.status.reason")}</span>
        <span>{reasonPoint ? <CheckIcon /> : null}</span>
      </div>
      <div className="flex-row">
        <span className={"flex-auto" + (!mustHaveActions ? " text-muted" : "")}>{t("ratingSystem.status.action")}</span>
        <span>{actionsPoint ? <CheckIcon /> : null}</span>
      </div>
    </Popover>
  );
};
