import { BenchmarkEndpointApi, Configuration } from "../generated-api";
import { BehaviorSubject, Subject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { MIN_PAGE_SIZE } from "../constants/main";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { createTablePageLogic } from "./tablePageLogic";
import { uploadFile } from "./fileUpload";

const benchmarkApi = new BenchmarkEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const benchmarkPageSubject = new BehaviorSubject(new PromiseSubjectState());
export const useBenchmarkPageResponse = hookFromSubject(benchmarkPageSubject);
export const searchBenchmarks = ({ page, itemsPerPage, sort, search, kpis, status }) => {
  const params = { page: page || 0, itemsPerPage: itemsPerPage || MIN_PAGE_SIZE };
  if (sort) {
    params.sort = sort;
  }
  if (search) {
    params.search = search;
  }
  if (kpis && kpis.length) {
    params.kpiName = 'in:' + kpis.map(item => item.name).join(',');
    params.kpiUnit = 'in:' + kpis.map(item => item.unit).join(',');
  }
  if (status) {
    params.status = 'in:' + status;
  }
  return promiseToSubject(benchmarkApi.apiBenchmarkPageGet({ params }), benchmarkPageSubject);
};

export const benchmarkStatusCountSubject = new BehaviorSubject(new PromiseSubjectState());
export const useBenchmarkStatusCountResponse = hookFromSubject(benchmarkStatusCountSubject);
export const getBenchmarkStatusCount = ({ search }) => {
  const params = {};
  if (search) {
    params.search = search;
  }
  return promiseToSubject(benchmarkApi.apiBenchmarkStatusCountGet({ params }), benchmarkStatusCountSubject);
};

export const uploadBenchmarkFileURL = '/api/benchmark/file/temporary';
export const uploadBenchmarkFileKey = 'benchmarkFile';
export const uploadBenchmarkFile = (file) => uploadFile(file, uploadBenchmarkFileURL, uploadBenchmarkFileKey);
export const deleteBenchmarkTemporaryFile = (file) => benchmarkApi.apiBenchmarkFileTemporaryDelete(file);

export const benchmarkFileUploadedSubject = new Subject();
export const useUploadedBenchmarkFile = hookFromSubject(benchmarkFileUploadedSubject);

export const benchmarkFilesSubject = new BehaviorSubject([]);
export const useBenchmarkFiles = hookFromSubject(benchmarkFilesSubject);
export const setBenchmarkFiles = (files = []) => benchmarkFilesSubject.next(files);
export const addBenchmarkFile = file => benchmarkFilesSubject.next([...benchmarkFilesSubject.getValue(), file]);
export const removeBenchmarkFile = fileIndex => benchmarkFilesSubject.next(benchmarkFilesSubject.getValue().filter((item, index) => index !== fileIndex));
export const removeBenchmarkFileByFileUid = fileUid => benchmarkFilesSubject.next(benchmarkFilesSubject.getValue().filter((item) => item.fileUid !== fileUid));

export const benchmarkSubject = new BehaviorSubject(new PromiseSubjectState());
export const useBenchmarkResponse = hookFromSubject(benchmarkSubject);
export const resetBenchmarkResponse = () => benchmarkSubject.next(new PromiseSubjectState());
export const getBenchmark = (id) =>
  promiseToSubject(benchmarkApi.apiBenchmarkIdGet({ id }), benchmarkSubject);

export const benchmarkDeleteSubject = new BehaviorSubject(new PromiseSubjectState());
export const useBenchmarkDeleteResponse = hookFromSubject(benchmarkDeleteSubject);
export const deleteBenchmark = (id) =>
  promiseToSubject(benchmarkApi.apiBenchmarkIdDelete({ id }), benchmarkDeleteSubject);

export const benchmarkCreateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useBenchmarkCreateResponse = hookFromSubject(benchmarkCreateSubject);
export const createBenchmark = (request) =>
  promiseToSubject(benchmarkApi.apiBenchmarkPost({ request }), benchmarkCreateSubject);

export const benchmarkUpdateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useBenchmarkUpdateResponse = hookFromSubject(benchmarkUpdateSubject);
export const updateBenchmark = (id, request) =>
  promiseToSubject(benchmarkApi.apiBenchmarkPut({ request: { ...request, id } }), benchmarkUpdateSubject);

benchmarkUpdateSubject.subscribe(event => {
  if (!event.loading && !event.error) {
    benchmarkSubject.next(event);
  }
});

export const benchmarkRevisionsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useBenchmarkRevisionsResponse = hookFromSubject(benchmarkRevisionsSubject);
export const getBenchmarkRevisions = (id) =>
  promiseToSubject(benchmarkApi.apiBenchmarkIdRevisionsGet({ id }), benchmarkRevisionsSubject);

export const benchmarkRevisionByIdSubject = new BehaviorSubject(new PromiseSubjectState());
export const useBenchmarkRevisionByIdResponse = hookFromSubject(benchmarkRevisionByIdSubject);
export const getBenchmarkRevisionById = (id, revisionId) =>
  promiseToSubject(benchmarkApi.apiBenchmarkIdRevisionRevisionIdGet({
    id,
    revisionId
  }), benchmarkRevisionByIdSubject);

export const benchmarkMigrationSubject = new BehaviorSubject(new PromiseSubjectState());
export const useBenchmarkMigrationResponse = hookFromSubject(benchmarkMigrationSubject);
export const migrateBenchmark = (id, tenantId) =>
  promiseToSubject(benchmarkApi.apiBenchmarkIdMigrationExecuteTenantIdPost({id, tenantId}), benchmarkMigrationSubject);

export const [useBenchmarkPageLogic, resetBenchmarkPageState] = createTablePageLogic();


export const benchmarkCloneSubject = new BehaviorSubject(new PromiseSubjectState());
export const useBenchmarkCloneResponse = hookFromSubject(benchmarkCloneSubject);
export const cloneBenchmark = (id) =>
  promiseToSubject(benchmarkApi.apiBenchmarkIdClonePost({id}), benchmarkMigrationSubject);
