import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { KPI_VALUE_TYPE_ABSOLUTE, KPI_VALUE_TYPE_RELATIVE } from "../../../constants/dashboard";
import { generatePalette, getHeightInPoints } from "../../../helpers/canvas";
import { searchBenchmarks, useBenchmarkPageResponse } from "../../../hooks/benchmark";
import {
  benchmarksLoadedForKpiSubject,
  getProjectGroupBenchmarkChart,
  getProjectGroupStatisticChartKpiDetails,
  setBenchmarksLoadedForKpi,
} from "../../../hooks/projectGroup";
import { useEvent } from "../../../hooks/utils/useEvent";
import { localeString } from "../../../utils";
import { ChartBase } from "../../ChartBase/ChartBase";
import { KPIBenchmarkAutocomplete } from "../../Dashboard/KPITab/KPIBenchmarkAutocomplete";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { TooltipPopover } from "../../TooltipPopover/TooltipPopover";
import "./ProjectGroupAnalyticsKPIDetailsChart.scss";
import { renderKPIDetailsChart } from "./renderChart";

export const ProjectGroupAnalyticsKPIDetailsChart = ({ projectGroupId, kpiName, kpiUnit }) => {
  const { t } = useTranslation();
  const [height, setHeight] = useState(0);
  const [popover, setPopover] = useState(null);
  const [limitedXRange, setLimitedXRange] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const projects = data?.projects || [];
  const goals = data?.goals || [];
  const [benchmarkData, setBenchmarkData] = useState([]);
  const [selectedBenchmark, setSelectedBenchmark] = useState("");
  const [benchmarks, setBenchmarks] = useState([]);
  const valueType = selectedBenchmark ? KPI_VALUE_TYPE_RELATIVE : KPI_VALUE_TYPE_ABSOLUTE;
  const valueField = valueType === KPI_VALUE_TYPE_ABSOLUTE ? "absoluteValue" : "relativeValue";
  const benchmarkValueField = valueType === KPI_VALUE_TYPE_ABSOLUTE ? "absoluteValue" : "value";
  const potentialValueField = valueType === KPI_VALUE_TYPE_ABSOLUTE ? "potentialValue" : "relativePotentialValue";
  const goalField = valueType === KPI_VALUE_TYPE_ABSOLUTE ? "absoluteGoal" : "relativeGoal";

  const benchmarkPageResponse = useBenchmarkPageResponse();
  const loadedBenchmarks = benchmarkPageResponse?.data?.content;

  useEffect(() => {
    const benchmarksLoadedKpi = benchmarksLoadedForKpiSubject.getValue();
    if (
      loadedBenchmarks &&
      benchmarksLoadedKpi?.name === data?.kpiName &&
      benchmarksLoadedKpi?.unit === data?.kpiUnit
    ) {
      setBenchmarks(
        loadedBenchmarks.filter((item) => item.kpiName === data?.kpiName && item.kpiUnit === data?.kpiUnit)
      );
    }
  }, [loadedBenchmarks]);

  const benchmark = useMemo(() => {
    return benchmarkData.map((item) => ({
      ...item,
      value: item[benchmarkValueField],
    }));
  }, [benchmarkData, benchmarkValueField]);

  const loadBenchmarks = useEvent((search) => {
    const benchmarksLoadedKpi = benchmarksLoadedForKpiSubject.getValue();
    if (
      !benchmarksLoadedKpi ||
      benchmarksLoadedKpi.name !== data?.kpiName ||
      benchmarksLoadedKpi.unit !== data?.kpiUnit ||
      search
    ) {
      const kpi = { name: data?.kpiName, unit: data?.kpiUnit };
      setBenchmarksLoadedForKpi(kpi);
      searchBenchmarks({
        page: 1,
        itemsPerPage: 10,
        kpis: [kpi],
        status: "RELEASED",
        search: search || "",
      });
    }
  });

  const sortBenchmark = (dataBenchmark) =>
    (dataBenchmark || []).sort((a, b) => {
      if (a?.year > b?.year) {
        return 1;
      } else if (a?.year < b?.year) {
        return -1;
      }
      return 0;
    });

  const selectBenchmark = useEvent((benchmarkSelect) => {
    const benchmarkId = benchmarkSelect?.id || "";
    setSelectedBenchmark(benchmarkId);
    if (benchmarkId) {
      getProjectGroupBenchmarkChart(projectGroupId, benchmarkId)
        .then((response) => setBenchmarkData(sortBenchmark(response?.values)))
        .catch(console.error);
    } else {
      setBenchmarkData([]);
    }
  });

  useEffect(() => {
    setLoading(true);
    getProjectGroupStatisticChartKpiDetails(projectGroupId, kpiName, kpiUnit)
      .then((dataInner) => setData(dataInner))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [projectGroupId, kpiName, kpiUnit]);

  const [xRange, yRange, heightInPoints, valuesByYear, goalsByYear] = useMemo(() => {
    const xRangeInner = [];
    const yRangeInner = [];
    const valuesByYearInner = {};
    const goalsByYearInner = {};
    let maxValue = 0;
    projects.forEach(({ values: valuesInner, projectId, projectName }) => {
      (valuesInner || []).forEach((item) => {
        const value = item[valueField];
        const potential = item[potentialValueField];
        const estimation = item.estimation;
        const { year } = item;
        if (year) {
          if (!valuesByYearInner[year]) {
            valuesByYearInner[year] = [];
          }
          valuesByYearInner[year].push({
            projectId,
            projectName,
            value,
            potential,
            estimation,
          });
        }
      });
    });
    Object.keys(valuesByYearInner).forEach((year) => {
      const valuesByYearInnerElement = valuesByYearInner[year];
      let sum = 0;
      let potentialSum = 0;
      valuesByYearInnerElement.forEach((item) => {
        sum += item.value;
        potentialSum += item.potential;
      });
      if (sum > maxValue) {
        maxValue = sum;
      }
      if (potentialSum > maxValue) {
        maxValue = potentialSum;
      }
    });
    let minYear;
    let maxYear;
    benchmark?.forEach((item) => {
      const { year, value } = item;
      if (!maxValue || maxValue < value) {
        maxValue = value || 0;
      }
      if (!minYear || minYear > year) {
        minYear = year;
      }
      if (!maxYear || maxYear < year) {
        maxYear = year;
      }
    });
    goals.forEach((goal) => {
      const { year } = goal;
      const goalValue = goal[goalField];
      goalsByYearInner[year] = goalValue;
      if (goalValue > maxValue) {
        maxValue = goalValue;
      }
      if (!minYear || minYear > year) {
        minYear = year;
      }
      if (!maxYear || maxYear < year) {
        maxYear = year;
      }
    });
    if (minYear && maxYear) {
      for (let i = minYear; i <= maxYear; i++) {
        xRangeInner.push(i);
      }
    }
    let heightInPointsInner = getHeightInPoints(maxValue + maxValue * 0.1);
    const heightStep = heightInPointsInner / 10;
    if (heightInPointsInner) {
      for (let i = 0; i <= heightInPointsInner; i += heightStep) {
        yRangeInner.push(heightInPointsInner > 100 ? Math.round(i) : Math.round(i * 100) / 100);
      }
    }
    return [xRangeInner, yRangeInner, heightInPointsInner, valuesByYearInner, goalsByYearInner];
  }, [data, valueType, benchmark]);

  const renderMethod = useEvent(
    ({
      canvas,
      width,
      height: heightRenderMethod,
      heightInPoints: heightInPointsRenderMethod,
      values: valuesRenderMethod,
      limitedXRange: limitedXRangeRenderMethod,
    }) => {
      setHeight(heightRenderMethod);
      setLimitedXRange(limitedXRangeRenderMethod);
      if (canvas && width && heightRenderMethod) {
        const {
          valuesByYear: valuesByYearRender,
          goalsByYear: goalsByYearRender,
          palette: paletteRender,
        } = valuesRenderMethod;
        renderKPIDetailsChart(canvas, width, heightRenderMethod, {
          benchmark,
          limitedXRange: limitedXRangeRenderMethod,
          heightInPoints: heightInPointsRenderMethod,
          valuesByYear: valuesByYearRender,
          goalsByYear: goalsByYearRender,
          palette: paletteRender,
          hasPotential,
        });
      }
    }
  );

  const palette = useMemo(() => {
    const colors = generatePalette(projects.length);
    const colorsByProjectId = {};
    projects.forEach((item, index) => {
      colorsByProjectId[item.projectId] = colors[index];
    });
    return colorsByProjectId;
  }, [projects.length]);

  const rightContent = useMemo(() => {
    let grossFloorArea = 0;
    projects.forEach((project) => {
      grossFloorArea += project.grossFloorArea;
    });
    return (
      <div>
        <h3 className="project-gross-floor-area">
          {t("project.grossFloorArea")} = {grossFloorArea}
        </h3>
        <KPIBenchmarkAutocomplete
          loading={benchmarkPageResponse.loading}
          onValueChange={selectBenchmark}
          onTextChange={loadBenchmarks}
          onOpen={loadBenchmarks}
          options={benchmarks}
          value={selectedBenchmark}
        />
        <div className="projects">
          {projects.map((item, index) => (
            <div className="project-item flex-row" key={index}>
              <div className="value-dot" style={{ backgroundColor: palette[item.projectId] }} />
              <div className="flex-auto">{item?.projectName}</div>
            </div>
          ))}
          <div className="project-item flex-row" key={-1}>
            <div className="value-dot" style={{ backgroundColor: "#548233" }} />
            <div className="flex-auto">{t("dashboard.kpi.legend.potential")}</div>
          </div>
          <b>(i) {t("dashboard.kpi.legend.estimatedHint")}</b>
        </div>
      </div>
    );
  }, [data, selectedBenchmark, benchmarks, valueType]);

  const values = useMemo(
    () => ({ valuesByYear, goalsByYear, palette, valueType }),
    [valuesByYear, goalsByYear, palette, valueType]
  );

  const hasPotential = Object.keys(valuesByYear)
    .map((year) => valuesByYear[year])
    .some((valuesPotential) => valuesPotential?.some(({ potential }) => potential));

  const chartInnerContent = useMemo(
    () => (
      <div className="value-dots flex-row">
        {limitedXRange.map((item, yearIndex) => (
          <div className="value-dot-column k-flex-1" key={yearIndex}>
            <div className="value-pair-wrapper">
              <div className="current-wrapper">
                {!!height && !!heightInPoints && (
                  <div className="value-dot-bar-wrapper">
                    {!!valuesByYear[item] &&
                      [...valuesByYear[item]].reverse().map((project, index) => (
                        <div
                          className="value-dot-bar"
                          key={index}
                          style={{ height: (height / heightInPoints) * project.value }}
                          onMouseOver={(event) =>
                            setPopover({
                              target: event.currentTarget,
                              data: { year: item, project },
                              open: true,
                              potential: false,
                            })
                          }
                          onMouseOut={() =>
                            setPopover({
                              ...popover,
                              open: false,
                              data: { ...popover?.data, year: null },
                            })
                          }
                        />
                      ))}
                  </div>
                )}
              </div>
              {hasPotential && (
                <div className="potential-wrapper">
                  {!!height && !!heightInPoints && (
                    <div className="value-dot-bar-wrapper">
                      {!!valuesByYear[item] &&
                        [...valuesByYear[item]].reverse().map((project, index) => (
                          <div
                            className="value-dot-bar"
                            key={index}
                            style={{ height: (height / heightInPoints) * project.potential }}
                            onMouseOver={(event) =>
                              setPopover({
                                target: event.currentTarget,
                                data: { year: item, project },
                                open: true,
                                potential: true,
                              })
                            }
                            onMouseOut={() =>
                              setPopover({
                                ...popover,
                                open: false,
                                data: { ...popover?.data, year: null },
                              })
                            }
                          />
                        ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    ),
    [limitedXRange, valuesByYear, height, heightInPoints, popover, valueType]
  );

  const footerContent = useMemo(() => {
    const { target, open } = popover || {};
    const { project, year } = popover?.data || {};
    const { projectName, value, potential, estimation } = project || {};
    const { kpiUnit: kpiUnitFooter } = data || {};

    const estimatedValue = (value * estimation) / 100;
    const measuredValue = (value * (100 - estimation)) / 100;

    return (
      <TooltipPopover
        anchorOrigin={{
          vertical: "center",
          horizontal: popover?.potential ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: popover?.potential ? "left" : "right",
        }}
        content={
          <>
            <div>{year}</div>
            <div>{projectName}</div>
            {!!popover?.potential && (
              <div>
                <span className="muted">{t("dashboard.kpi.legend.potential")}: </span>
                <span>
                  {localeString(potential)} {kpiUnitFooter}
                </span>
              </div>
            )}
            {!popover?.potential && (
              <>
                <div>
                  <span className="muted">{t("dashboard.kpi.legend.current")}: </span>
                  <span>
                    {localeString(value)} {kpiUnitFooter}
                  </span>
                </div>
                {!!estimatedValue && (
                  <div>
                    <span className="muted">{t("dashboard.kpi.legend.estimated")}: </span>
                    <span>
                      {localeString(estimatedValue)} {kpiUnitFooter}
                    </span>
                  </div>
                )}
                {!!measuredValue && (
                  <div>
                    <span className="muted">{t("dashboard.kpi.legend.measured")}: </span>
                    <span>
                      {localeString(measuredValue)} {kpiUnitFooter}
                    </span>
                  </div>
                )}
              </>
            )}
          </>
        }
        target={target}
        open={!!open}
        className="text-tooltip"
      />
    );
  }, [data, popover, limitedXRange]);

  const topContent = useMemo(
    () => (
      <div className="flex-row">
        <div className="left-part flex-auto">
          <h2>
            {data?.kpiName} {data?.kpiUnit}
          </h2>
        </div>
        <div className="right-part"></div>
      </div>
    ),
    [data, valueType]
  );

  if (!projects?.length) {
    return null;
  }

  return (
    <LoadingOverlay spinner active={loading} className="project-group-analytics-kpi-details-chart auto-height">
      <ChartBase
        className="kpi-details-chart"
        yRange={yRange}
        xRange={xRange}
        key={data}
        values={values}
        yLabel={t("projectGroup.analytics.detailsChart.value")}
        xLabel={t("projectGroup.analytics.detailsChart.year")}
        heightInPoints={heightInPoints}
        topContent={topContent}
        renderMethod={renderMethod}
        rightContent={rightContent}
        footerContent={footerContent}
      >
        {chartInnerContent}
      </ChartBase>
    </LoadingOverlay>
  );
};
