import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { DISALLOWED_CHARACTERS_REGEX } from "../../../constants/main";
import {
  ATTRIBUTE_TYPES,
  ATTRIBUTE_TYPES_WITH_OPTIONS,
  TYPE_CHECKBOX,
  TYPE_LOCATION,
  TYPE_SELECT,
} from "../../../constants/projectAttribute";
import {
  useAdminGeneralProjectAttributeCreateResponse,
  useAdminGeneralProjectAttributeUpdateResponse,
} from "../../../hooks/projectAttribute";
import { showError } from "../../../hooks/toast";
import { useEvent } from "../../../hooks/utils/useEvent";
import { processMessage } from "../../../utils";
import { FieldController } from "../../FieldController/FieldController";
import { FormController } from "../../FormController/FormController";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { ProjectAttributeOption } from "./ProjectAttributeOption";

export const ProjectAttributeDialog = ({ data, open, onClose, onChange, isUniqueName, readOnly }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const isEdit = !!data;
  const [updateCounter, setUpdateCounter] = useState(0);

  const createResponse = useAdminGeneralProjectAttributeCreateResponse();
  const updateResponse = useAdminGeneralProjectAttributeUpdateResponse();

  const defaultValues = useMemo(
    () => ({
      uuid: getUUID(),
      type: "",
      name: "",
      required: false,
      details: { en: "", de: "", defaultValue: "" },
    }),
    [data, open]
  );
  const { handleSubmit, control, reset, getValues, formState, setError, setValue } = useForm({ defaultValues });

  const values = getValues();

  const [options, setOptions] = useState([]);
  const [required, setRequired] = useState(false);
  const [validatedOptions, setValidatedOptions] = useState({});
  const [isOptionDirty, setIsOptionDirty] = useState(false);
  const [internalNameError, setInternalNameError] = useState(false);

  useEffect(() => {
    if (data) {
      reset(data);
      setRequired(data.required);
      setOptions(data.details?.options?.map((item) => ({ ...item, uuid: getUUID() })) ?? []);
      setValidatedOptions({});
    } else reset(defaultValues);
  }, [data, open]);

  const save = useEvent(() => {
    if (internalNameError) {
      showError(t("generalAttributes.specialCharacterError"));
      return;
    }

    const valuesInner = getValues();
    const { details } = valuesInner;
    if (isUniqueName(data?.id || valuesInner.uuid, valuesInner.name)) {
      onChange({ ...valuesInner, required, details: { ...details, options: isTypeWithOptions ? options : undefined } });
    } else {
      setError("name", { type: "unique" });
    }
  });

  const addOption = useEvent((option) => {
    setValue("details.defaultValue", "");
    setOptions([...options, { ...option }]);
  });

  const updateOption = useEvent((option) => {
    setValue("details.defaultValue", "");
    setOptions([...options].map((item) => (item.uuid === option.uuid ? { ...option } : item)));
  });

  const deleteOption = useEvent((option) => {
    setOptions([...options].filter((item) => item.uuid !== option.uuid));
    const updated = { ...validatedOptions };
    delete updated[option.uuid];
    setValidatedOptions(updated);
    setValue("details.defaultValue", "");
  });

  const validateOption = useEvent((value, valid) => setValidatedOptions({ ...validatedOptions, [value]: valid }));
  const isUniqueOption = useEvent((value) => options.filter((item) => item.value === value).length <= 1);

  const isTypeWithOptions = !!values.type && ATTRIBUTE_TYPES_WITH_OPTIONS.includes(values.type);
  const withInvalidOption = Object.keys(validatedOptions).some((key) => !validatedOptions[key]);
  const isSaveDisabled = (isTypeWithOptions && withInvalidOption) || isOptionDirty;
  const loading = createResponse.loading || updateResponse.loading;

  const checkValue = (event, field) => {
    let value = event.target.value;
    if (DISALLOWED_CHARACTERS_REGEX.test(value)) {
      setInternalNameError(true);
      showError(t("generalAttributes.specialCharacterError"));
      field.onChange(event);
    } else {
      setInternalNameError(false);
      event.target.value = value.split("__").join("_").split(" ").join("");
      field.onChange(event);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {t(
          readOnly
            ? "projectAttributes.dialog.viewTitle"
            : isEdit
            ? "projectAttributes.dialog.editTitle"
            : "projectAttributes.dialog.createTitle"
        )}
        <IconButton onClick={onClose} size="small">
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <LoadingOverlay spinner active={loading} className="auto-height">
          <FormController
            control={control}
            name="type"
            required
            label={t("projectAttributes.type.title")}
            render={({ field, fieldState, label }) => (
              <FormControl fullWidth disabled={readOnly || isEdit}>
                <InputLabel htmlFor="projectAttributeTypeSelect">{label + " *"}</InputLabel>
                <Select
                  id="projectAttributeTypeSelect"
                  fullWidth
                  {...field}
                  error={fieldState.invalid}
                  onChange={(event) => {
                    setValue("type", event.target.value);
                    setValue("details.defaultValue", "");
                    setUpdateCounter(updateCounter + 1);
                  }}
                >
                  {ATTRIBUTE_TYPES.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {t("projectAttributes.type." + item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <FormController
            control={control}
            name="name"
            required
            label={t("projectAttributes.name")}
            rules={{ unique: true }}
            render={({ field, fieldState, label }) => (
              <>
                <TextField
                  {...field}
                  fullWidth
                  error={fieldState.invalid || internalNameError}
                  label={label + " *"}
                  onChange={(event) => checkValue(event, field)}
                  disabled={readOnly || isEdit}
                />
                {fieldState.error?.type === "unique" && formState.isSubmitted && (
                  <p className="MuiFormHelperText-root Mui-error">{processMessage(t("validation.unique"), [label])}</p>
                )}
              </>
            )}
          />
          <div className="flex-row k-gap-4">
            <div className="k-flex-1">
              <FormController
                control={control}
                name="details.en"
                required
                label={t("projectAttributes.label") + " EN"}
                render={({ field, fieldState, label }) => (
                  <TextField error={fieldState.invalid} fullWidth label={label + " *"} {...field} disabled={readOnly} />
                )}
              />
            </div>
            <div className="k-flex-1">
              <FormController
                control={control}
                name="details.de"
                required
                label={t("projectAttributes.label") + " DE"}
                render={({ field, fieldState, label }) => (
                  <TextField error={fieldState.invalid} fullWidth label={label + " *"} {...field} disabled={readOnly} />
                )}
              />
            </div>
          </div>
          {!!values.type && values.type !== TYPE_CHECKBOX && (
            <>
              <FormController
                control={control}
                name="required"
                label={t("projectAttributes.required")}
                render={({ field, label }) => (
                  <label>
                    <Checkbox
                      {...field}
                      checked={required}
                      disabled={readOnly}
                      onChange={(event) => {
                        setRequired(event.target.checked);
                      }}
                    />
                    <span className="label">{label}</span>
                  </label>
                )}
              />
              {!!values.type && values.type !== TYPE_LOCATION && (
                <FieldController
                  getValues={getValues}
                  type={values.type}
                  control={control}
                  name="details.defaultValue"
                  label={t("projectAttributes.defaultValue")}
                  readOnly={readOnly}
                  options={options.map((item) => ({ name: item.value, text: (item?.details || {})[language] }))}
                />
              )}
            </>
          )}
          {isTypeWithOptions && (
            <div>
              <h3>{t("projectAttributes.optionsTitle")}</h3>
              {options.map((item) => (
                <ProjectAttributeOption
                  key={item.name}
                  data={item}
                  onChange={updateOption}
                  onDelete={deleteOption}
                  onValidate={validateOption}
                  isUnique={isUniqueOption}
                  withTranslations={values.type === TYPE_SELECT}
                  readOnly={readOnly}
                />
              ))}
              {!readOnly && (
                <ProjectAttributeOption
                  key={-1}
                  onSave={addOption}
                  isUnique={isUniqueOption}
                  withTranslations={values.type === TYPE_SELECT}
                  onDirtyChange={setIsOptionDirty}
                  readOnly={readOnly}
                />
              )}
            </div>
          )}
        </LoadingOverlay>
      </DialogContent>

      {!readOnly && (
        <DialogActions>
          <Button onClick={onClose}>{t("main.cancel")}</Button>
          <Button color="primary" onClick={handleSubmit(save)} disabled={isSaveDisabled}>
            {t("main.save")}
          </Button>
        </DialogActions>
      )}
      {!!readOnly && (
        <DialogActions>
          <Button onClick={onClose}>{t("main.close")}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
