import Button from "@mui/material/Button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GROUPING_TYPE_CATEGORY } from "../../../constants/sustainabilitySystem";
import { getNodeChangedFields } from "../../../helpers/kpiRatingSystem";
import { sortKPIGroupings } from "../../../helpers/sustainabilitySystem";
import { setKpiSelectedRow } from "../../../hooks/kpiRatingSystem";
import { CategorySVG, IndicatorSVG } from "../../../svg";
import { forEachRecursive } from "../../../utils";
import { conditionIsKPI, conditionNotKPI } from "../../../validation/kpiSystem";
import { AdvancedTable } from "../../AdvancedTable/AdvancedTable";
import { COLUMN_TYPE_TEXT } from "../../AdvancedTable/AdvancedTableCell";
import "../../RatingSystem/RatingSystemGrouping/RatingSystemGrouping.scss";
import { SearchBadge } from "../../SearchBadge/SearchBadge";
import "./KPIRatingSystemGrouping.scss";

export const KPIRatingSystemGrouping = React.memo((props) => {
  const { values, previousValues, onChange, readOnly, submitted, selectedRow, errors, errorMessage } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState({});

  useEffect(() => {
    const nodesByUUID = {};
    if (previousValues) {
      forEachRecursive(previousValues, (node) => {
        nodesByUUID[node.uuid] = node;
      });
    }
  }, [previousValues]);

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerText: t("kpiGrouping.groupingTitle"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionNotKPI,
        readOnly: true,
        getIcon: (row) => (row.type === GROUPING_TYPE_CATEGORY ? <CategorySVG /> : null),
        groupingColumn: true,
        minWidth: 200,
        width: 250,
        search: true,
      },
      {
        field: "kpiName",
        headerText: t("kpiGrouping.kpiName"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionIsKPI,
        startAdornment: <IndicatorSVG />,
        readOnly: true,
        minWidth: 150,
        width: 200,
        search: true,
        template: ({ kpi }) => {
          const { name, unit } = kpi || {};
          return `${name} (${unit})`;
        },

      },
      {
        field: "currentValue",
        headerText: t("kpiGrouping.currentValue"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionIsKPI,
        readOnly: true,
        minWidth: 150,
        width: 200,
      },
      {
        field: "potentialValue",
        headerText: t("kpiGrouping.potentialValue"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionIsKPI,
        readOnly: true,
        minWidth: 150,
        width: 200,
      },
    ],
    []
  );

  const searched = useMemo(() => columns.filter((item) => !!item.search && search[item.field]), [search]);

  const getRowClassName = useCallback((row) => {
    return "sustainability-row-" + row.type;
  }, []);

  const expandCondition = useCallback(
    (node) => node.kpi && node.kpi.currentValue && !node.kpi.estimation && node.kpi.estimation !== 0,
    [errors]
  );

  return (
    <div className="kpi-rating-system-grouping rating-system-grouping">
      {!!searched.length && (
        <div className="filters">
          {searched.map((item, index) => (
            <SearchBadge
              key={index}
              field={item.headerText}
              value={search[item.field].data}
              onClose={() => setSearch({ ...search, [item.field]: undefined })}
            />
          ))}
          <Button className="clear-filters" onClick={() => setSearch({})}>
            {t("main.clear")}
          </Button>
        </div>
      )}
      <AdvancedTable
        stickyAmount={2}
        data={values}
        errorMessage={errorMessage}
        previousData={previousValues}
        expandRowsByCondition={true}
        expandRowCondition={expandCondition}
        errorMarkRowsByCondition={true}
        errorMarkRowCondition={expandCondition}
        getNodeChangedFields={getNodeChangedFields}
        onChange={onChange}
        getRowClassName={getRowClassName}
        groupingField={"children"}
        onSearch={setSearch}
        search={search}
        columns={columns}
        sortRows={sortKPIGroupings}
        onRowSelect={(row) => {
          if (row?.type !== GROUPING_TYPE_CATEGORY) {
            setKpiSelectedRow(row);
          }
        }}
        selectedRow={selectedRow}
        submitted={submitted}
        readOnly={readOnly}
        disableRowDrag
        disableHoverControls
        disableColumnLock
      />
    </div>
  );
});
