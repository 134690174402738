import React from "react";
import { Chips } from "../../Chips/Chips";

export const ActionTagsTemplate = ({row, field, passThrough, readOnly}) => {
  const { onValueChange } = passThrough || {};

  if(!row) {
    return null;
  }

  return <Chips key={row.UID} chips={row.listTags || []} onChange={tags => onValueChange(row, field, tags)}
                readOnly={readOnly} changeOnBlur/>;
};
