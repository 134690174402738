import AddIcon from "@mui/icons-material/Add";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../../hooks/auth";
import { updateGroupProjectPermissions, useProjectGroupPermissionsPutResponse } from "../../hooks/projectGroup";
import { processMessage } from "../../utils";
import { CheckTree } from "../CheckTree/CheckTree";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import "./ProjectGroupPermissionsDialog.scss";

export const ProjectGroupPermissionsDialog = ({
  data,
  onClose,
  onChange,
  projectId,
  projectAvailableUsers,
  isEdit,
}) => {
  const { t } = useTranslation();

  const { user } = useUser();
  const userUUID = user.uuid;
  const [permissions, setPermissions] = useState(data?.filter((item) => item.userUuid !== userUUID) || []);
  const projectGroupPermissionsPutResponse = useProjectGroupPermissionsPutResponse();
  const usersByUUID = {};
  projectAvailableUsers.forEach((item) => {
    usersByUUID[item.uuid] = [item.firstName, item.lastName].join(" ");
  });
  const [submitted, setSubmitted] = useState(false);

  const filteredUsers = projectAvailableUsers
    .filter((item) => !permissions.some((permission) => permission.userUuid === item.uuid))
    .map((item) => item.uuid);

  const save = () => {
    setSubmitted(true);
    if (validatePermissions()) {
      updateGroupProjectPermissions(projectId, permissions).then(onChange).catch(console.error);
    }
  };

  const validatePermissions = () => !permissions.some((item) => !item.userUuid);

  const changeUser = (row, index, value) => {
    setPermissions(
      permissions.map((item, i) => {
        if (index === i) {
          return { ...item, userUuid: value };
        } else {
          return item;
        }
      })
    );
  };

  const changePermissions = (row, index, values) => {
    setPermissions(
      permissions.map((item, i) => {
        if (index === i) {
          const updated = { ...row };
          additionalPermissions.forEach((perm) => {
            updated[perm.name] = values.indexOf(perm.name) !== -1;
          });
          return updated;
        } else {
          return item;
        }
      })
    );
  };

  const additionalPermissions = [
    {
      name: "admin",
      text: t("permission.administrator"),
    },
  ];

  const loading = projectGroupPermissionsPutResponse.loading;

  return (
    <LoadingOverlay spinner className="auto-height permissions-dialog" active={loading}>
      <h2>
        {t("permission.modalTitle")}
        <IconButton onClick={onClose} size="small" className={"pull-right"}>
          <CloseOutlinedIcon />
        </IconButton>
      </h2>

      <div className="dialog-body">
        <Table className="modal-table">
          <TableHead>
            <TableRow>
              <TableCell className="user-cell">{t("permission.user")} *</TableCell>
              <TableCell className="permission-cell">{t("permission.additionalPermissions")}</TableCell>
              <TableCell className="actions-cell" />
            </TableRow>
          </TableHead>
          <TableBody>
            {(permissions || []).map((item, index) => {
              const selectedAdditionalPermissions = [];
              additionalPermissions.forEach((perm) => {
                if (item[perm.name]) {
                  selectedAdditionalPermissions.push(perm.name);
                }
              });

              const disabledValues =
                selectedAdditionalPermissions.indexOf("permissionOwner") !== -1
                  ? additionalPermissions
                      .filter((itemAddtional) => itemAddtional.name !== "permissionOwner")
                      .map((itemInner) => itemInner.name)
                  : null;

              return (
                <TableRow key={index}>
                  <TableCell className="user-cell">
                    <Autocomplete
                      disabled={!isEdit}
                      size="small"
                      key={projectAvailableUsers}
                      options={filteredUsers}
                      value={item.userUuid || null}
                      getOptionLabel={(value) => usersByUUID[value] || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("permission.user") + " *"}
                          error={!item.userUuid && submitted}
                        />
                      )}
                      onChange={(event, value) => changeUser(item, index, value)}
                      isOptionEqualToValue={(option, value) => {
                        return option?.id === value || option?.id === value?.id;
                      }}
                    />
                    <FormHelperText error>
                      {!item.userUuid &&
                        submitted &&
                        processMessage(t("validation.fieldRequired"), [t("permission.user")])}
                    </FormHelperText>
                  </TableCell>
                  <TableCell className="permission-cell">
                    <FormControl fullWidth>
                      <InputLabel htmlFor={"selectPermission_" + index}>
                        {t("permission.additionalPermissions")}
                      </InputLabel>
                      <Select
                        value={selectedAdditionalPermissions}
                        fullWidth
                        disabled={!isEdit}
                        label={t("permission.additionalPermissions")}
                        multiple={true}
                        id={"selectPermission_" + index}
                        displayEmpty
                        renderValue={(values) => {
                          if (!values || !values.length) {
                            return "";
                          }
                          return (
                            values
                              .map((value) => additionalPermissions.find((perm) => perm.name === value)?.text)
                              .join(", ") || ""
                          );
                        }}
                      >
                        <CheckTree
                          multiple={true}
                          disabledValues={disabledValues}
                          items={additionalPermissions}
                          values={selectedAdditionalPermissions}
                          onChange={(values) => changePermissions(item, index, values)}
                        />
                      </Select>
                    </FormControl>
                  </TableCell>
                  {!!isEdit && (
                    <TableCell className="actions-cell">
                      <IconButton
                        size="small"
                        onClick={() => setPermissions(permissions.filter((itemPermission, i) => index !== i))}
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {!permissions.length && (
              <TableRow>
                <TableCell colSpan={3}>
                  <div className="text-center text-muted">{t("main.empty")}</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {!!isEdit && (
          <Button color="primary" onClick={() => setPermissions([...permissions, {}])}>
            <AddIcon />
            {t("permission.addUser")}
          </Button>
        )}
      </div>

      {!!isEdit && (
        <div className="buttons text-right">
          <Button onClick={() => onClose()}>{t("main.cancel")}</Button>
          <Button color="primary" onClick={save} disabled={loading}>
            {t("main.save")}
          </Button>
        </div>
      )}
    </LoadingOverlay>
  );
};
