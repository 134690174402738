import { EditorTools } from "@progress/kendo-react-editor";

const {
    BackColor
  } = EditorTools;

const COLUMN_NUMBER = 8;

const COLOR_PALETTE = [
    'rgb(255, 255, 255)',
    'rgb(255, 255, 0)',
    'rgb(0, 255, 0)',
    'rgb(0, 255, 255)',
    'rgb(255, 0, 255)',
    'rgb(0, 0, 255)',
    'rgb(255, 0, 0)',
    'rgb(0, 0, 128)',
    'rgb(0, 128, 128)',
    'rgb(0, 128, 0)',
    'rgb(128, 0, 128)',
    'rgb(128, 0, 0)',
    'rgb(128, 128, 0)',
    'rgb(128, 128, 128)',
    'rgb(192, 192, 192)',
    'rgb(0, 0, 0)'
];

const KENDO_ICON = 'droplet';

export const SPTBackColor = props => (
    <BackColor {...props} colorPickerProps={{ 
        popupSettings: {
            className: 'rich-text-animation-container'
        },
        icon: KENDO_ICON,
        paletteSettings: { 
            columns: COLUMN_NUMBER,
            palette: COLOR_PALETTE
        } 
    }} />
);