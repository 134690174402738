import React, { useEffect, useMemo, useState } from "react";
import { ChartBase } from "../../ChartBase/ChartBase";
import {
  getProjectGroupStatisticChartKpiProjects,
  useProjectGroupStatisticChartKpiProjectsResponse
} from "../../../hooks/projectGroup";
import { useTranslation } from "react-i18next";
import { renderProjectVsKPIChart } from "./renderChart";
import { getHeightInPoints } from "../../../helpers/canvas";
import { TooltipPopover } from "../../TooltipPopover/TooltipPopover";
import { roundDecimal } from "../../../utils";

const HUNDRED = 100;
const TEN = 10;
const ZERO_DOT_ONE = 0.1;

export const ProjectsVsKPIChart = React.memo(({ projectGroupId, kpi, scoreType, alternateYLabel }) => {
  const { t } = useTranslation();

  const projectGroupStatisticChartKpiProjectsResponse = useProjectGroupStatisticChartKpiProjectsResponse();
  const data = projectGroupStatisticChartKpiProjectsResponse.data;
  const [height, setHeight] = useState(0);
  const [limitedXRange, setLimitedXRange] = useState([]);
  const [popover, setPopover] = useState(null);

  useEffect(() => {
    if (projectGroupId && (kpi || scoreType)) {
      getProjectGroupStatisticChartKpiProjects(projectGroupId, kpi?.kpiName, kpi?.kpiUnit, scoreType).then().catch(console.error);
    }
  }, [projectGroupId, kpi, scoreType]);

  const [xRange, yRange, heightInPoints, valuesById] = useMemo(() => {
    const xRangeInner = [];
    const yRangeInner = [];
    const valuesByIdInner = {};
    let maxValue = 0;
    data?.projects?.forEach(({ value, projectName, projectId }) => {
      xRangeInner.push({
        label: projectName,
        projectId: projectId
      });
      valuesByIdInner[projectId] = value;
      if (value > maxValue) {
        maxValue = value;
      }
    });
    let heightInPointsInner = getHeightInPoints(maxValue + maxValue * ZERO_DOT_ONE);
    const heightStep = heightInPointsInner / TEN;
    if (heightInPointsInner) {
      for (let i = 0; i <= heightInPointsInner; i += heightStep) {
        yRangeInner.push(heightInPointsInner > HUNDRED ? Math.round(i) : Math.round(i * HUNDRED) / HUNDRED);
      }
    }
    return [xRangeInner, yRangeInner, heightInPointsInner, valuesByIdInner];
  }, [data]);

  const renderMethod = ({ canvas, width, height: heightRenderMethod,
                          heightInPoints: heightInPointsRenderMethod, values, limitedXRange: limitedXRangeRenderMethod }) => {
    setHeight(heightRenderMethod);
    setLimitedXRange(limitedXRangeRenderMethod);
    if (canvas && width && heightRenderMethod) {
      renderProjectVsKPIChart(canvas, width, heightRenderMethod, values, {
        limitedXRange: limitedXRangeRenderMethod,
        valuesById: valuesById,
        heightInPoints: heightInPointsRenderMethod || 1,
        linesAmount: (yRange.length - 1) * 2
      });
    }
  };

  const footerContent = useMemo(() => (
    <TooltipPopover
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left', }}
      content={<div>
        <div>{popover?.data?.label}</div>
        <div>
          {!data?.scoreType && <span className="muted">{kpi?.kpiName} {kpi?.kpiUnit}: </span>}
          {data?.scoreType === 'super_score' &&
          <span className="muted">{t('projectGroup.analytics.superScore')}: </span>}
          {data?.scoreType === 'score' &&
          <span className="muted">{t('projectGroup.analytics.systemScore')}: </span>}
          {roundDecimal(valuesById[popover?.data?.projectId], HUNDRED)}
        </div>
      </div>} target={popover?.target}
      open={!!popover?.open} className="text-tooltip"
    />
  ), [data, popover]);

  const headerContent = useMemo(() => (
    <div className="header">
      <div className="legend">
        <div className="legend-item">
          <div className="sample-line"/>
          {t('projectGroup.analytics.project')}</div>
        <div className="legend-item">
          <div className="sample-line average"/>
          {t('projectGroup.analytics.average')}</div>
      </div>
    </div>
  ), [data]);

  const chartInnerContent = useMemo(() => (
    <div className="chart-inner">
      {limitedXRange.map((item, index) => (
        <div className="chart-bar-wrapper" key={index}>
          <div className="chart-bar"
               style={{ height: valuesById[item?.projectId] / heightInPoints * height + 'px' }}
               onMouseOver={event => setPopover({
                 target: event.currentTarget,
                 data: item,
                 open: true
               })}
               onMouseOut={() => setPopover({ ...popover, open: false })}
          />
        </div>
      ))}
    </div>
  ), [limitedXRange, data, height, heightInPoints, popover]);

  const getYLabel = (kpiYLabel) => {
    if (alternateYLabel) {
      return alternateYLabel;
    }
    return (kpiYLabel && kpiYLabel.kpiName ? kpiYLabel.kpiName : '') + ' ' + (kpiYLabel && kpiYLabel.kpiUnit ? kpiYLabel.kpiUnit : '');
  }

  return (
    <ChartBase className="projects-vs-kpi-chart"
               yRange={yRange}
               xRange={xRange}
               yLabel={getYLabel(kpi)}
               xLabel={t('projectGroup.analytics.projects')}
               values={data}
               pageLimit={TEN}
               renderMethod={renderMethod}
               heightInPoints={heightInPoints}
               topContent={headerContent}
               footerContent={footerContent}>
      {chartInnerContent}
    </ChartBase>
  );
});
