import SearchIcon from "@mui/icons-material/Search";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../../constants/main";
import { getProjectGroupSystemsCount, useProjectGroupSystemsCountResponse } from "../../../hooks/projectGroup";
import { usePagination } from "../../../hooks/utils/usePagination";
import { useTimeout } from "../../../hooks/utils/useTimeout";
import { processMessage } from "../../../utils";
import { ContentBlock } from "../../ContentBlock/ContentBlock";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import "./ProjectGroupAnalytics.scss";

export const ProjectGroupAnalytics = ({ projectGroupId }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [processed, setProcessed] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState({ field: "name" });
  const [, setSearchTimeout] = useTimeout();
  const { data, loading } = useProjectGroupSystemsCountResponse();
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination(0, MIN_PAGE_SIZE);
  const total = processed.length;

  useEffect(() => {
    getProjectGroupSystemsCount(projectGroupId).then().catch(console.error);
  }, [projectGroupId]);

  useEffect(() => {
    let processedInner = (data || []).map((item) => ({
      name: [item.systemSource, item.systemName, item.systemVersion].join(" "),
      count: item.count,
    }));
    if (searchValue) {
      const lowCaseSearchValue = searchValue.toLowerCase();
      processedInner = processedInner.filter(
        (item) =>
          String(item.name).toLowerCase().indexOf(lowCaseSearchValue) !== -1 || +lowCaseSearchValue === item.count
      );
    }
    if (sortValue && sortValue.field) {
      processedInner.sort((a, b) => {
        if (a[sortValue.field] > b[sortValue.field]) {
          return sortValue.desc ? -1 : 1;
        }
        if (a[sortValue.field] < b[sortValue.field]) {
          return sortValue.desc ? 1 : -1;
        }
        return 0;
      });
    }
    setProcessed(processedInner);
  }, [searchValue, sortValue, data]);

  const search = useCallback(
    (event) =>
      setSearchTimeout(
        setTimeout(() => {
          setSearchValue(String(event.target.value || "").trim());
        }, 500)
      ),
    []
  );

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerText: t("projectGroup.analytics.systemName"),
        className: "name-column",
        sortable: true,
      },
      {
        field: "count",
        headerText: t("projectGroup.analytics.systemsCount"),
        className: "number-column",
        sortable: true,
      },
    ],
    []
  );

  const onSort = (field) =>
    setSortValue({
      field: field !== sortValue.field || !sortValue.desc ? field : null,
      desc: sortValue.field === field ? !sortValue.desc : false,
    });

  const pagination = {
    labelRowsPerPage: t("pagination.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: total,
    page: page,
    show: total > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pageOnChange) => setPage(pageOnChange),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const limited = rowsPerPage < processed.length ? [...processed].splice(page * rowsPerPage, rowsPerPage) : processed;

  return (
    <ContentBlock className="project-group-analytics">
      <LoadingOverlay spinner active={loading} className="auto-height">
        <div
          className="search-box"
          onClick={() => {
            inputRef.current.focus();
          }}
        >
          <SearchIcon />
          <input
            type="text"
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            ref={inputRef}
            placeholder={t("main.search")}
            onChange={search}
          />
        </div>
        <SimpleTableWithSort
          data={limited}
          columns={columns}
          onSort={onSort}
          sortValue={sortValue}
          pagination={pagination}
        />
      </LoadingOverlay>
    </ContentBlock>
  );
};
