import { FilterAlt } from "@mui/icons-material";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ReplayIcon from "@mui/icons-material/Replay";
import { Button, Switch, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { expandField, idField } from "./NewRatingSystem.util";
import { setTableState } from "./NewRatingSystemTable";

export const NewRatingSystemTableOptions = ({ state, onClearAllFilters, onClearLayout }) => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip
        title={t("newRatingSystem.esg.tableOptions.showFilters")}
        PopperProps={{ style: { zIndex: 999 } }}
        className="k-d-flex k-align-items-center"
      >
        <Switch
          checked={state.showFilter ?? true}
          onChange={({ target: { checked } }) => setTableState((prev) => ({ ...prev, showFilter: checked }))}
        />
        <FilterAlt className={state.showFilter ? "show-filter" : "hide-filter"} />
      </Tooltip>
      <Tooltip title={t("newRatingSystem.esg.tableOptions.clearAllFilters")} PopperProps={{ style: { zIndex: 999 } }}>
        <Button onClick={onClearAllFilters}>
          <FilterAltOffIcon />
        </Button>
      </Tooltip>
      <Tooltip title={t("newRatingSystem.esg.tableOptions.collapse")} PopperProps={{ style: { zIndex: 999 } }}>
        <Button
          onClick={() =>
            setTableState((prev) => {
              if (prev.currentLevel <= 0) return prev;

              for (const level of prev.levelArray[prev.currentLevel - 1]) {
                prev.expanded[level[idField]] = false;
                level[expandField] = false;
              }

              return { ...prev, currentLevel: prev.currentLevel - 1 };
            })
          }
        >
          <ExpandLessOutlinedIcon />
        </Button>
      </Tooltip>
      <Tooltip title={t("newRatingSystem.esg.tableOptions.expand")} PopperProps={{ style: { zIndex: 999 } }}>
        <Button
          onClick={() =>
            setTableState((prev) => {
              if (prev.currentLevel >= prev.levelArray?.length - 1) return prev;

              for (const level of prev.levelArray[prev.currentLevel]) {
                prev.expanded[level[idField]] = true;
                level[expandField] = true;
              }

              return { ...prev, currentLevel: prev.currentLevel + 1 };
            })
          }
        >
          <ExpandMoreOutlinedIcon />
        </Button>
      </Tooltip>
      <Tooltip title={t("newRatingSystem.esg.tableOptions.setDefaultLayout")} PopperProps={{ style: { zIndex: 999 } }}>
        <Button onClick={onClearLayout}>
          <ReplayIcon />
        </Button>
      </Tooltip>
    </>
  );
};

NewRatingSystemTableOptions.propTypes = {
  state: PropTypes.object,
  onClearAllFilters: PropTypes.func,
  onClearLayout: PropTypes.func,
};
