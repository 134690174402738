import { AwardFileEndpointApi, Configuration } from "../generated-api";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";

const awardFileApi = new AwardFileEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const postAwardFileSubject = new BehaviorSubject(new PromiseSubjectState());
export const usePostAwardFileResponse = hookFromSubject(postAwardFileSubject);
export const postAwardFile = (file) =>
  promiseToSubject(awardFileApi.apiSustainabilityAwardFilePost({ file }), postAwardFileSubject);
