import { BehaviorSubject } from "rxjs";
import { hookFromSubject } from "react-rxjs-easy";

export const addedTextblocksSubject = new BehaviorSubject([]);
export const useAddedTextblocks = hookFromSubject(addedTextblocksSubject);
export const addTextblock = (id) => {
    const textblocks = addedTextblocksSubject.getValue();
    const index = textblocks.indexOf(id);
    if (index !== -1) { 
        textblocks.splice(index, 1);
    }
    addedTextblocksSubject.next([...textblocks, id])
    setTimeout(() => {
        addedTextblocksSubject.next(addedTextblocksSubject.getValue().filter((item, indexTimeout) => indexTimeout !== 0));
    }, 3000);
};
