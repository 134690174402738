import { DatePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, LocalizationProvider, load, loadMessages } from "@progress/kendo-react-intl";
import weekData from 'cldr-core/supplemental/weekData.json';
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from "cldr-dates-full/main/de/dateFields.json";
import deNumbers from "cldr-numbers-full/main/de/numbers.json";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_CLIENT, DATE_FORMAT_SERVER } from "../../../constants/main";
import kendoDeMessages from './../../../locales/kendo-de.json';
import "./ActionDateTemplate.scss";

load(
  deNumbers,
  deDateFields,
  deCaGregorian,
  weekData
)
loadMessages(kendoDeMessages, 'de');


export const ActionDateTemplate = ({ row, field, passThrough, readOnly }) => {
  const { onValueChange } = passThrough || {};
  const { i18n } = useTranslation();
  if (!row) {
    return null;
  }

  if (readOnly || (passThrough || {}).readOnly) {
    return row[field] ? moment(row[field]).format(DATE_FORMAT_CLIENT) : null;
  }

  return (
    <LocalizationProvider language={i18n.language}>
      <IntlProvider locale={i18n.language}>
        <DatePicker
          className="action-date-template"
          key={row.UID}
          value={row[field] ? new Date(row[field]) : null}
          size="small"
          format={"dd.MM.yyyy"}
          fillMode="flat"
          onChange={(value) => {
            onValueChange(row, field, value ? moment(value.value).format(DATE_FORMAT_SERVER) : value.value);
          }}
        />
      </IntlProvider>
    </LocalizationProvider>
  );
};
