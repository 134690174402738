import React, { useEffect } from "react";
import "./CustomDialogs.scss";
import { hideDialog, useDialogs } from "../../hooks/dialog";
import { getScrollbarWidth } from "../../utils";

export const CustomDialogs = () => {

  const dialogs = useDialogs();

  let lastIndex = -1;
  let openedAmount = 0;

  dialogs.forEach((dialog, index) => {
    if (dialog) {
      lastIndex = index;
      if (dialog.open) {
        openedAmount++;
      }
    }
  });

  useEffect(() => {
    const body = window.document.body;
    const bodyStyle = body.style;
    const scrollBarWidth = getScrollbarWidth();
    if (openedAmount) {
      bodyStyle.overflow = 'hidden';
      if (body.clientHeight !== body.parentNode.clientHeight) {
        bodyStyle.paddingRight = scrollBarWidth + 'px';
      }
    } else {
      bodyStyle.overflow = 'auto';
      bodyStyle.paddingRight = '';
    }
  }, [openedAmount]);

  const closeLastDialog = (event) => {
    if (lastIndex !== -1 && event.target && event.target.matches && event.target.matches('.custom-dialogs')) {
      const dialogData = dialogs[lastIndex].dialogData;
      if (dialogData.closeOnClickOutside === true || dialogData.closeOnClickOutside === undefined) {
        hideDialog(lastIndex);
      }
    }
  };

  return (
    <div className={'custom-dialogs' + (openedAmount > 0 ? ' show' : '')} onClick={closeLastDialog}>
      {dialogs.map((item, index) => {
        if (!item) {
          return null;
        }
        const data = item.dialogData || {};
        return <div key={index} className={'custom-dialog ' + (data.className || '') + (item.open ? ' open' : '')}>
          {data.getContent ? data.getContent(() => hideDialog(index)) : data.content}
        </div>
      })}
    </div>
  );
};
