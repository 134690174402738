import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LinkDialog } from "../LinkDialog/LinkDialog";
import "./LinksList.scss";

export const LinksList = React.memo(({ links, onChange, readOnly }) => {
  const { t } = useTranslation();
  const [linkDialog, setLinkDialog] = useState(null);

  const changeLink = (link) => {
    if (linkDialog === true) {
      onChange([...links, link]);
    } else {
      const updated = [...links];
      updated[linkDialog] = link;
      onChange(updated);
    }
    setLinkDialog(null);
  };

  const deleteLink = (index) => {
    const updated = [...links];
    updated.splice(index, 1);
    onChange(updated);
  };

  return (
    <div className="links-list">
      {!!readOnly && (
        <>
          <h2 className="links-header">{t("grouping.links")}</h2>
          {(!links || !links.length) && <div className="empty text-center">{t("main.empty")}</div>}
          {!!links && !!links.length && (
            <div className="links">
              {links.map((item, index) => (
                <Chip
                  key={index}
                  clickable={true}
                  onClick={() => {
                    window.open(item.value);
                  }}
                  label={item.title || item.value}
                />
              ))}
            </div>
          )}
        </>
      )}
      {!readOnly && (
        <>
          <h2 className="links-header">
            {t("grouping.links")}{" "}
            <IconButton color="primary" size="small" onClick={() => setLinkDialog(true)}>
              <AddCircleOutlineIcon />
            </IconButton>
          </h2>
          {(!links || !links.length) && <div className="empty text-center">{t("main.empty")}</div>}
          {!!links && !!links.length && (
            <div>
              {links.map((item, index) => (
                <Chip
                  key={index}
                  clickable
                  label={item.title || item.value}
                  onDelete={() => deleteLink(index)}
                  onClick={() => setLinkDialog(index)}
                />
              ))}
            </div>
          )}
        </>
      )}
      <LinkDialog
        key={linkDialog}
        open={linkDialog !== null}
        onClose={() => setLinkDialog(null)}
        titleText={linkDialog === true ? t("grouping.addLink") : t("grouping.editLink")}
        data={linkDialog !== null ? links[linkDialog] : null}
        onSave={changeLink}
      />
    </div>
  );
});
