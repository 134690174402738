import { EditorTools } from "@progress/kendo-react-editor";

const EDITOR_FONT_FAMILY = 'Calibri';
const EDITOR_FONT_COLOR = 'black';
const EDITOR_FONT_BOLD = 'bold';

const FORMAT_LIST = [
    {
        text: 'Paragraph',
        value: 'p',
        style: {
            color: EDITOR_FONT_COLOR,
            fontFamily: EDITOR_FONT_FAMILY
        }
    },
    {
        text: 'Heading 1',
        value: 'h1',
        style: {
            fontWeight: EDITOR_FONT_BOLD,
            color: EDITOR_FONT_COLOR,
            fontFamily: EDITOR_FONT_FAMILY
        }
    },
    {
        text: 'Heading 2',
        value: 'h2',
        style: {
            fontWeight: EDITOR_FONT_BOLD,
            color: EDITOR_FONT_COLOR,
            fontFamily: EDITOR_FONT_FAMILY
        }
    }
];

export const SPTFormatBlock = (props) => {

    const Component = EditorTools.createFormatBlockDropDownList({
        defaultItem: {
            text: 'Format'
        },
        items: FORMAT_LIST,
        commandName: 'FormatBlock',
    });

    return <Component {...props} popupSettings={{ className: 'rich-text-animation-container' }} />;
}