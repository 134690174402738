import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";

export const maxPercentsVisibleSubject = new BehaviorSubject(false);
export const useMaxPercentsVisible = hookFromSubject(maxPercentsVisibleSubject);
export const setMaxPercentsVisible = value => maxPercentsVisibleSubject.next(value);

export const sunburstDataSubject = new BehaviorSubject([]);
export const useSunburstData = hookFromSubject(sunburstDataSubject);
export const setSunburstData = value => sunburstDataSubject.next(value);
