import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { processMessage } from "../../../utils";
import { ProjectTypeSelect } from "../../SustainabilitySystem/SustainabilitySystemFields/ProjectTypeSelect/ProjectTypeSelect";
import "./KPISystemBaseData.scss";

export const KPISystemBaseData = ({ control, editDisabled }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="kpi-system-base-data">
      <div className="flex-row">
        <div>
          <h2>{t("kpiSystem.baseData")}</h2>
        </div>
        <div className="text-right flex-auto">
          <IconButton onClick={() => setCollapsed(!collapsed)} size="small" className="collapse-trigger">
            {!!collapsed && <ExpandMoreIcon />}
            {!collapsed && <ExpandLessIcon />}
          </IconButton>
        </div>
      </div>
      <div className={"collapsible " + (collapsed ? "collapsed" : "")}>
        <div className="collapsible-inner flex-row">
          <div className="name-col">
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field, formState, fieldState }) => (
                <>
                  <TextField
                    error={fieldState.invalid}
                    fullWidth
                    label={t("kpiSystem.name") + " *"}
                    {...field}
                    disabled={editDisabled}
                    inputProps={{ maxLength: 255 }}
                  />
                  {formState.isSubmitted && fieldState.invalid && (
                    <FormHelperText error>
                      {fieldState.error.type === "required" &&
                        processMessage(t("validation.fieldRequired"), [t("kpiSystem.name")])}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </div>
          <div className="version-col">
            <Controller
              control={control}
              name="systemVersion"
              rules={{ required: true }}
              render={({ field, formState, fieldState }) => (
                <>
                  <TextField
                    error={fieldState.invalid}
                    fullWidth
                    label={t("kpiSystem.systemVersionShort") + " *"}
                    {...field}
                    disabled={editDisabled}
                    inputProps={{ maxLength: 255 }}
                  />
                  {formState.isSubmitted && fieldState.invalid && (
                    <FormHelperText error>
                      {fieldState.error.type === "required" &&
                        processMessage(t("validation.fieldRequired"), [t("sustainabilitySystem.systemVersionShort")])}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </div>
          <div className="description-col">
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={t("kpiSystem.description")}
                  {...field}
                  disabled={editDisabled}
                  inputProps={{ maxLength: 255 }}
                />
              )}
            />
          </div>
          <div className="project-type-col">
            <ProjectTypeSelect control={control} disabled={editDisabled} />
          </div>
        </div>
      </div>
    </div>
  );
};
