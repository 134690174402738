import AddIcon from "@mui/icons-material/Add";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { showDialog } from "../../../hooks/dialog";
import {
  getProjectGroupChildren,
  getProjectSubGroup,
  projectGroupHierarchySelectionSubject,
  removeProjectGroupFromProjectGroup,
  setProjectGroupHierarchySelection,
  setSelectedProjectGroupChildren,
  useProjectGroupChildrenResponse,
  useProjectGroupHierarchySelection,
  useProjectGroupParentDeleteResponse,
  useProjectSubGroupResponse,
} from "../../../hooks/projectGroup";
import { useEvent } from "../../../hooks/utils/useEvent";
import { HintArrowSVG } from "../../../svg";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { AddProjectGroupProjectDialog } from "../AddProjectGroupProjectDialog/AddProjectGroupProjectDialog";
import { ChildProjectGroupsDialog } from "../ChildProjectGroupsDialog/ChildProjectGroupsDialog";
import { ProjectGroupDialog } from "../ProjectGroupDialog/ProjectGroupDialog";
import "./ProjectGroupHierarchy.scss";

export const ProjectGroupHierarchy = ({
  readOnly,
  projectGroupId,
  onSelectGroup,
  onAddProject,
  simplified,
  collapsed,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const projectGroupChildrenResponse = useProjectGroupChildrenResponse();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const deleteResponse = useProjectGroupParentDeleteResponse();
  const projectSubGroupResponse = useProjectSubGroupResponse();

  const [loadingLevels, setLoadingLevels] = useState(false);
  const [levels, setLevels] = useState([]);
  const selections = useProjectGroupHierarchySelection() || [];
  const [localSelections, setLocalSelections] = useState([]);
  const [projectGroupPopover, setProjectGroupPopover] = useState(null);
  const [addProjectGroupPopover, setAddProjectGroupPopover] = useState(null);

  const setSelections = (selectionsSet) => {
    setLocalSelections(selectionsSet);
    setProjectGroupHierarchySelection(selectionsSet);
  };

  const getSelections = () => projectGroupHierarchySelectionSubject.getValue();

  useLayoutEffect(() => {
    if (projectGroupId) {
      setSelections([{ id: 0 }]);
      getProjectGroupChildren(projectGroupId).then(setFirstLevel).catch(console.error);
    }
  }, [projectGroupId]);

  useEffect(() => {
    setSelectedProjectGroupChildren(getSelectedGroupChildren());
    const selectionsInner = getSelections();
    if (selectionsInner !== localSelections) {
      loadNextSelectedLevel();
    }
  }, [levels, selections]);

  const viewLevels = useMemo(
    () =>
      levels?.map((level, index) => {
        const items = [...(level || [])];
        return index === 0
          ? [
              {
                id: 0,
                name: t("projectGroup.hierarchyBlock.allProjects"),
              },
              ...items,
            ]
          : items;
      }),
    [levels, language]
  );

  useEffect(() => {
    let selected;
    const selectionsInner2 = getSelections();
    if (selectionsInner2.length) {
      selected = selectionsInner2[selectionsInner2.length - 1];
    }
    const selectedId = selected?.id || 0;
    onSelectGroup(projectGroupId === selectedId ? 0 : selectedId, selected?.name);
  }, [selections]);

  const loadNextSelectedLevel = useEvent(() => {
    let nextLevelId;
    let loadedLevel = 0;
    const selectionsSelectedLevel = getSelections();
    selectionsSelectedLevel.forEach((item, index) => {
      if (!localSelections[index] || localSelections[index].id !== item.id) {
        if (!nextLevelId) {
          nextLevelId = item.id;
        }
      } else {
        loadedLevel = index + 1;
      }
    });
    if (nextLevelId) {
      getProjectGroupChildren(nextLevelId)
        .then((data) => {
          const updatedLocalSelections = [];
          for (let i = 0; i <= loadedLevel; i++) {
            updatedLocalSelections.push(selectionsSelectedLevel[i]);
          }
          setLocalSelections(updatedLocalSelections);
          setLevel(data, loadedLevel + 1);
        })
        .catch(console.error);
    }
  });

  const setSelected = useEvent((id, levelIndex) => {
    const updated = [];
    const selectionsSelected = getSelections();
    for (let i = 0; i < levelIndex; i++) {
      updated[i] = selectionsSelected[i];
    }
    if (id) {
      if (!selectionsSelected[levelIndex]?.id || selectionsSelected[levelIndex].id !== id) {
        getProjectGroupChildren(id)
          .then((data) => setLevel(data, levelIndex + 1))
          .catch(console.error);
      } else {
        setLevel(levels[levelIndex + 1], levelIndex + 1);
      }
    } else {
      setLevel([], levelIndex + 1);
    }
    const item = viewLevels[levelIndex]?.find((itemInner) => itemInner.id === id);
    if (item) {
      updated[levelIndex] = { id, name: item?.name };
    }
    setSelections(updated);
  });

  const showAddGroupModal = useEvent(() => {
    closeAddProjectGroupMenu();
    showDialog({
      className: "medium",
      closeOnClickOutside: false,
      getContent: (onClose) => (
        <ProjectGroupDialog
          data={null}
          onClose={onClose}
          onChange={() => {
            onClose();
            reload();
          }}
          parentProjectGroupId={getSelectedId()}
        />
      ),
    });
  });

  const showAddExistingGroupModal = useEvent(() => {
    closeAddProjectGroupMenu();
    showDialog({
      className: "large",
      closeOnClickOutside: true,
      getContent: (onClose) => (
        <ChildProjectGroupsDialog
          onClose={onClose}
          onChange={reload}
          projectGroupId={projectGroupId}
          parentProjectGroupId={getSelectedId()}
        />
      ),
    });
  });

  const showAddProjectGroupProjectModal = useEvent(() => {
    showDialog({
      className: "large",
      closeOnClickOutside: true,
      getContent: (onClose) => (
        <AddProjectGroupProjectDialog onClose={onClose} onChange={onAddProject} projectGroupId={getSelectedId()} />
      ),
    });
  });

  const showEditExistingGroupModal = useEvent(() => {
    const { id } = projectGroupPopover?.data || {};
    getProjectSubGroup(id).then((data) => {
      showDialog({
        className: "medium",
        closeOnClickOutside: true,
        getContent: (onClose) => (
          <ProjectGroupDialog
            onClose={onClose}
            onChange={() => {
              onClose();
              reloadChangedLevels(id);
            }}
            data={data}
          />
        ),
      });
    });
    closeProjectGroupMenu();
  });

  const reloadChangedLevels = useEvent((changedId) => {
    setLoadingLevels(true);
    const indexesToReload = [];
    levels.forEach((level, index) => {
      if (level.some((item) => item.id === changedId)) {
        indexesToReload.push(index - 1);
      }
    });
    Promise.all(indexesToReload.map((index) => getProjectGroupChildren(getLevelSelectedId(index) || projectGroupId)))
      .then((data) => {
        const updated = [...levels];
        indexesToReload.forEach((indexToReload, index) => {
          const levelIndex = indexToReload + 1;
          const level = data[index] || [];
          updated[levelIndex] = [...level];
          setLevels(updated);
        });
      })
      .catch(console.error)
      .finally(() => setLoadingLevels(false));
  });

  const openAddProjectGroupMenu = useEvent((event) => {
    event.preventDefault();
    event.stopPropagation();
    setAddProjectGroupPopover(event.currentTarget);
  });

  const closeAddProjectGroupMenu = useEvent(() => setAddProjectGroupPopover(null));

  const closeProjectGroupMenu = useEvent(() => setProjectGroupPopover(null));

  const openDeleteConfirmation = useEvent(() => {
    setDeleteConfirmation(projectGroupPopover?.data);
    closeProjectGroupMenu();
  });
  const closeDeleteConfirmation = useEvent(() => setDeleteConfirmation(false));

  const reloadLevel = (parentLevelIndex, keepNextLevels) => {
    const selectedId = getLevelSelectedId(parentLevelIndex);
    const promise = getProjectGroupChildren(selectedId || projectGroupId);
    promise
      .then((data) => {
        const targetLevel = parentLevelIndex + 1;
        if (targetLevel > 0) {
          setLevel(data, targetLevel, keepNextLevels);
        } else {
          setLevel(data, 0, keepNextLevels);
        }
      })
      .catch(console.error);
    return promise;
  };

  const reload = () =>
    getProjectGroupChildren(getSelectedId())
      .then((data) => {
        if (levels.length > 1) {
          setLevel(data, levels.length - 1);
        } else {
          setLevel(data, 0);
        }
      })
      .catch(console.error);

  const setLevel = useEvent((data, levelIndex, keepNextLevels) => {
    let updated = [];
    if (keepNextLevels) {
      updated = [...(levels || [])];
    } else {
      for (let i = 0; i < levelIndex; i++) {
        updated[i] = levels[i] || [];
      }
    }
    updated[levelIndex] = [...(data || [])];
    setLevels(updated);
  });

  const setFirstLevel = useEvent((data, keepNextLevels) => setLevel(data, 0, keepNextLevels));

  const deleteHandler = useEvent(() => {
    const { id, levelIndex } = deleteConfirmation;
    const parentId = selections[levelIndex - 1]?.id || projectGroupId;
    removeProjectGroupFromProjectGroup(id, parentId)
      .then(() => {
        const isSelected = selections[levelIndex]?.id === id;
        reloadLevel(levelIndex - 1, !isSelected);

        if (isSelected) {
          const parentIndex = levelIndex - 1;
          setSelected(selections[levelIndex - 1]?.id || 0, parentIndex < 0 ? 0 : parentIndex);
        }

        closeDeleteConfirmation();
      })
      .catch(console.error);
  });

  const getSelectedId = () => {
    const selectionsSelectedId = getSelections();
    return selectionsSelectedId[selectionsSelectedId.length - 1]?.id || projectGroupId;
  };
  const getLevelSelectedId = (level) => {
    const selectionsLevelSelected = getSelections();
    return selectionsLevelSelected[level]?.id;
  };
  const getSelectedGroupChildren = () => {
    const selectionsGroupChildren = getSelections();
    return !selectionsGroupChildren[selectionsGroupChildren.length - 1]?.id
      ? levels[0]
      : levels[selectionsGroupChildren.length];
  };

  const loading =
    projectGroupChildrenResponse.loading || deleteResponse.loading || projectSubGroupResponse.loading || loadingLevels;

  return (
    <LoadingOverlay className="project-group-hierarchy auto-height" spinner active={loading}>
      {!simplified && (
        <div className="flex-row hierarchy-top-block">
          <h2 className="block-header flex-auto">{t("projectGroup.hierarchyBlock.title")}</h2>
          {!readOnly && (
            <div className="text-right group-buttons">
              <Button color="primary" onClick={showAddProjectGroupProjectModal}>
                <AddIcon className="svg-icon" />
                {t("projectGroup.hierarchyBlock.addProject")}
              </Button>
              <Button color="primary" onClick={openAddProjectGroupMenu}>
                <AddIcon className="svg-icon" />
                {t("projectGroup.hierarchyBlock.addProjectGroup")}
              </Button>
            </div>
          )}
        </div>
      )}

      {!readOnly && !projectGroupChildrenResponse.loading && !!levels.length && !levels[0]?.length && (
        <div className="please-add-hint">
          <p>{t("projectGroup.hierarchyBlock.pleaseAdd")}</p>
          <HintArrowSVG />
        </div>
      )}

      {!!levels.length && (!!levels[0]?.length || readOnly) && (
        <div className="hierarchy-scroll">
          <div className="flex-row">
            {viewLevels.map((level, levelIndex) => {
              const items = collapsed ? level.filter((item) => selections[levelIndex]?.id === item.id) : level;
              if (!items.length) {
                return null;
              }
              return (
                <div
                  key={levelIndex}
                  className={"hierarchy-level" + (readOnly ? " small" : "") + (collapsed ? " collapsed-level" : "")}
                >
                  <div className="hierarchy-level-inner">
                    {items.map((item) => (
                      <div
                        key={getUUID()}
                        className={"hierarchy-item" + (selections[levelIndex]?.id === item.id ? " active" : "")}
                        onClick={setSelected.bind(this, item.id, levelIndex)}
                      >
                        {!!item.id && (
                          <div className="hierarchy-item-icon">
                            <LibraryBooksIcon className={selections[levelIndex]?.id === item.id ? "active" : ""} />
                          </div>
                        )}
                        <span className="hierarchy-item-label flex-auto">{item.name}</span>
                        {!readOnly && !!item.id && (
                          <div className="hierarchy-more-icon">
                            <IconButton
                              size="small"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setProjectGroupPopover({
                                  data: { id: item.id, levelIndex },
                                  target: event.currentTarget,
                                });
                              }}
                            >
                              <MoreVertOutlinedIcon />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <Popover
        open={!!projectGroupPopover}
        anchorEl={projectGroupPopover?.target}
        onClose={closeProjectGroupMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        disableRestoreFocus
      >
        <MenuItem onClick={showEditExistingGroupModal}>{t("projectGroup.hierarchyBlock.editProjectGroup")}</MenuItem>
        <MenuItem onClick={openDeleteConfirmation}>{t("projectGroup.hierarchyBlock.removeProjectGroup")}</MenuItem>
      </Popover>

      <Popover
        open={!!addProjectGroupPopover}
        anchorEl={addProjectGroupPopover}
        onClose={closeAddProjectGroupMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        disableRestoreFocus
      >
        <MenuItem onClick={showAddExistingGroupModal}>
          {t("projectGroup.hierarchyBlock.addExistingProjectGroup")}
        </MenuItem>
        <MenuItem onClick={showAddGroupModal}>{t("projectGroup.hierarchyBlock.addNewProjectGroup")}</MenuItem>
      </Popover>

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={closeDeleteConfirmation}
        onConfirm={deleteHandler}
        titleText={t("projectGroup.hierarchyBlock.removeConfirmationTitle")}
        bodyText={t("projectGroup.hierarchyBlock.removeConfirmation")}
        confirmText={t("main.remove")}
        color="secondary"
      />
    </LoadingOverlay>
  );
};
