import React, { useCallback, useEffect, useState } from "react";
import "./ProjectImages.scss";
import {
  addProjectPicture,
  deleteProjectTemporaryImage,
  projectPicturesSubject,
  removeProjectPictureById,
  setProjectPictures,
  useProjectPictures
} from "../../../hooks/project";
import { ImageBlocks } from "../../ImageBlocks/ImageBlocks";
import { Upload } from "@progress/kendo-react-upload";
import { SUCCESS_STATUS_CODE } from "../../../constants/http";
import i18next from "i18next";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { IMAGE_UPLOAD_RESTRICTIONS } from "../../../constants/fileUpload";
import { getRestrictedFileExtension, getRestrictedFileSize, showKendoValidationErrors } from "../../../helpers/fileUpload";
import { showError } from "../../../hooks/toast";

import kendoEnMessages from './../../../locales/kendo-en.json';
import kendoDeMessages from './../../../locales/kendo-de.json';
import { useTranslation } from "react-i18next";

loadMessages(kendoEnMessages, 'en');
loadMessages(kendoDeMessages, 'de');

export const ProjectImages = ({ projectId, value, onChange, readOnly, restrictions = IMAGE_UPLOAD_RESTRICTIONS }) => {
  const { t } = useTranslation();
  const pictures = useProjectPictures();
  const apiFileUrl = `/api/project/${projectId}/image/temporary`;
  const [recentlyUploaded, setRecentlyUploaded] = useState([]);
  const [kendoUploadValue, setKendoUploadValue] = useState([]);
  const [fileValidationError, setFileValidationError] = useState(false);
  const messages = i18next.language === "de" ? kendoDeMessages : kendoEnMessages;

  useEffect(() => {
    setProjectPictures(value ?? []);
  }, [projectId, value]);

  const getImagePath = useCallback(item => `/api/project/${projectId}/picture/${item}`, []);

  const updatePictures = useCallback(updatePicture => {
      const recentlyUploadedFile = recentlyUploaded.find(x => updatePicture.indexOf(x.id) === -1);

      if (recentlyUploadedFile) { 
        deleteProjectTemporaryImage(projectId, recentlyUploadedFile);
        setKendoUploadValue(kendoUploadValue.filter(x => x.uid !== recentlyUploadedFile.fileUid));
      }

      projectPicturesSubject.next(updatePicture);
      onChange(updatePicture);
    }, [pictures]);

  const onAdd = ({ affectedFiles, newState }) => {
    setKendoUploadValue(newState);
    showKendoValidationErrors({ affectedFiles }, messages, showError);
    setFileValidationError(affectedFiles.some(x => !!x.validationErrors && x.validationErrors.length > 0));
  }

  const onStatusChange = ({ response, affectedFiles, newState }) => {
    if (response?.status === SUCCESS_STATUS_CODE) { 
      const [ file ] = affectedFiles;
      const uploadedFiles = [...recentlyUploaded, { id: response.response.id, name: file.name, userTags: [], editable: true, fileUid: file.uid }];

      setRecentlyUploaded(uploadedFiles);
      addProjectPicture(response.response.id);
      onChange(projectPicturesSubject.getValue());
    }

    setKendoUploadValue(newState);
  };

  const onBeforeRemove = (event) => {
    const [ file ] = event.files;
    event.additionalData = recentlyUploaded.find(x => file.uid === x.fileUid);
  };

  const onRemove = ({ response, affectedFiles, newState }) => {
    if (response?.status === SUCCESS_STATUS_CODE) { 
      const [ file ] = affectedFiles;
      const fileUploaded = recentlyUploaded.find(x => x.fileUid === file.uid);
      setRecentlyUploaded(recentlyUploaded.filter(x => x.fileUid !== file.uid));

      if (fileUploaded) { 
        removeProjectPictureById(fileUploaded?.id);
        onChange(projectPicturesSubject.getValue());
      }
    }

    setKendoUploadValue(newState);
  }
  
  return <div className={(readOnly ? ' read-only' : '')}>
      <div className="other">
        <ImageBlocks images={pictures || []} onChange={updatePictures} getImagePath={getImagePath} readOnly={readOnly}/>

        {!readOnly && <div className="images-footer">
          <LocalizationProvider language={i18next.language}>
            <IntlProvider locale={i18next.language}>
              <Upload 
                batch={false}
                multiple={true}
                files={kendoUploadValue}
                withCredentials={false}
                saveUrl={apiFileUrl}
                saveField="file"
                removeUrl={apiFileUrl}
                removeField="file"
                removeMethod="DELETE"
                onAdd={onAdd}
                onProgress={({ newState }) => setKendoUploadValue(newState)}
                onBeforeRemove={onBeforeRemove}
                onRemove={onRemove}
                onStatusChange={onStatusChange}
                restrictions={restrictions} />
            </IntlProvider>
          </LocalizationProvider>
          {fileValidationError && <div className="file-upload-restrictions">
            <div>
              <small>{t('fileUpload.allowedExtensions')} {getRestrictedFileExtension(IMAGE_UPLOAD_RESTRICTIONS)}</small>
            </div>
            <div>
              <small>{t('fileUpload.maximumFileSize')} {getRestrictedFileSize(IMAGE_UPLOAD_RESTRICTIONS)}</small>
            </div>
          </div>}
        </div>}
      </div>
    </div>;
};
