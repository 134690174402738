import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";
import "./ContentBlockError.scss";

export const ContentBlockError = ({ show, text, className }) => {
  if (!show) {
    return null;
  }
  return (
    <div className={"content-block-error " + (className ? className : "")}>
      <ErrorOutlineIcon />
      {text}
    </div>
  );
};
