import React from "react";
import { useIndicatorActionListResponse } from "../../../hooks/action";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";

export const ActionsBlock = ({ row }) => {
  const response = useIndicatorActionListResponse();
  const data = row.actions || response.data || [];

  return (
    <LoadingOverlay spinner active={response.loading} className="actions-block auto-height">
      <ul>
        {data.map((item, index) => (<li key={index}>
          {item.name}
        </li>))}
      </ul>
    </LoadingOverlay>
  )
};
