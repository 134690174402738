import EditIcon from "@mui/icons-material/Edit";
import Fab from "@mui/material/Fab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { ProjectFiles } from "../../components/Project/ProjectFiles/ProjectFiles";
import { ProjectHeader } from "../../components/Project/ProjectHeader/ProjectHeader";
import { ProjectImages } from "../../components/Project/ProjectImages/ProjectImages";
import { ProjectRatingSystems } from "../../components/Project/ProjectRatingSystems/ProjectRatingSystems";
import { canEdit, canView } from "../../helpers/project";
import { getProject, useProjectResponse } from "../../hooks/project";
import { getProjectGroup, useProjectGroupResponse } from "../../hooks/projectGroup";
import { showError } from "../../hooks/toast";
import { processMessage } from "../../utils";
import "./Project.scss";

export const ProjectPage = ({ analyze }) => {
  const { groupId, projectId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const response = useProjectResponse();
  const groupResponse = useProjectGroupResponse();
  const group = groupResponse.data || {};

  const [project, setProject] = useState({});
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const accessRestricted = project.accessRestricted;

  const permissions = project.projectPermission ?? {};

  const isViewer = canView(accessRestricted, permissions);

  const editDisabled = true;

  useEffect(() => {
    if (projectId) {
      if (response.data && String(response.data.id) === projectId) {
        resetData();
      }
      load();
    }
  }, [projectId]);

  useEffect(() => {
    if (groupId) {
      getProjectGroup(groupId);
    }
  }, [groupId]);

  const load = () => {
    getProject(projectId)
      .then(resetData)
      .catch(() => {
        showError(t("project.error.load"));
        navigate("/project");
      });
  };

  const resetData = (values) => {
    const data = values || response.data || {};
    setProject(data);
    setImages([...data.pictureIds]);
    setFiles([...data.projectFiles]);
  };

  const analyzePart = analyze ? "/analyze" : "";
  let pathParts = [];
  if (groupId) {
    pathParts.push({ url: "/projectGroup", text: t("menu.projectGroups.title") });
    pathParts.push({ url: "/projectGroup" + analyzePart + "/" + groupId, text: group.name });
  } else {
    pathParts.push({ url: "/project", text: t("menu.project.title") });
  }
  pathParts.push({ text: project.name });

  const canSeeAttachments = isViewer;
  const canEditProject = canEdit(permissions);

  return (
    <LoadingOverlay spinner active={response.loading || groupResponse.loading} className="project-page">
      <div className="page-header">
        <Breadcrumb pathParts={pathParts} />
      </div>

      <div className="page-layout" hidden={!project.id}>
        <ProjectHeader project={project} />

        <ProjectRatingSystems project={project} readOnly={!canEditProject} />

        {canSeeAttachments && (
          <div className="flex-row">
            <div className="content-block flex-auto left-block">
              <h2>{t("project.step.images")}</h2>
              <div className="amount">{processMessage(t("project.imagesAmount"), [images.length || 0])}</div>
              <ProjectImages projectId={projectId} value={images} onChange={setImages} readOnly={editDisabled} />
            </div>

            <div className="content-block files-block">
              <h2 className="files-header">{t("project.step.files")}</h2>
              <div className="amount">{processMessage(t("project.filesAmount"), [files.length || 0])}</div>
              <ProjectFiles projectId={projectId} value={files} onChange={setFiles} readOnly={editDisabled} />
            </div>
          </div>
        )}
      </div>

      {canEditProject && (
        <div className="fabs">
          <Fab
            type="button"
            color="primary"
            onClick={() => {
              navigate(`/project/${projectId}/edit`);
            }}
          >
            <EditIcon className="fab-svg" />
          </Fab>
        </div>
      )}
    </LoadingOverlay>
  );
};
