import React, { useCallback, useEffect, useState } from "react";
import { AdvancedTableBodyRow } from "./AdvancedTableBodyRow";
import { useSelectedRow } from "../../hooks/advancedTable";

export const AdvancedTableBodyRowWrapper = props => {
  const { row, updateValue } = props;

  const [data, setData] = useState({});
  const updateValueCallback = useCallback(updateValue, []);
  const selectedRow = useSelectedRow();
  const isSelected = row.UID === selectedRow?.UID;

  const toIgnore = {
    parent: true,
    children: true,
    groupingElements: true,
    indicatorElements: true
  };

  const isChanged = row.children?.length !== data.children?.length || Object.keys(row).map(key => ({
    key,
    value: row[key]
  })).some(item => !toIgnore[item.key] && item.value !== data[item.key]);

  useEffect(() => {
    if (isChanged) {
      setData(row);
    }
  }, [isChanged]);

  if (!data.UID) {
    return null;
  }

  return (
    <AdvancedTableBodyRow {...props} updateValue={updateValueCallback} row={data} isSelected={isSelected}/>
  );
};
