import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEvent } from "../../hooks/utils/useEvent";
import { roundDecimal } from "../../utils";
import "./KPIControl.scss";

export const KPIControl = React.memo(
  ({ kpiName, kpiUnit, value, estimation, onChange, noEstimated, readOnly, autoFocus, linkedGeneralAttributeName }) => {
    const { t } = useTranslation();
    const [kpiValue, setKpiValue] = useState(value);
    const onBlur = useEvent(() => onChange({ value: kpiValue, estimation }));
    const onValueChange = useEvent((event) => setKpiValue(roundDecimal(event.target.value, 100) || event.target.value));
    const onEstimatedChange = useEvent((event) => onChange({ value: kpiValue, estimation: event.target.value }));
    const scoreInputRef = useRef(null);

    useEffect(() => {
      setKpiValue(value);
    }, [value]);

    useEffect(() => {
      if (autoFocus && !readOnly && scoreInputRef?.current) {
        scoreInputRef.current.focus();
      }
    }, [readOnly, scoreInputRef?.current]);

    return (
      <div className="kpi-control">
        <div className="flex-row">
          <div className="kpi-field">
            <TextField
              onWheel={(event) => event.preventDefault()}
              inputRef={scoreInputRef}
              type="number"
              fullWidth
              value={kpiValue || ""}
              onChange={onValueChange}
              disabled={readOnly}
              label={kpiName}
              className="number-no-appearance"
              onBlur={onBlur}
              inputProps={{ step: 0.01, min: 0, max: 99999999.99 }}
            />
            <div className="value-unit">{kpiUnit}</div>
          </div>
          {!noEstimated && (
            <div className="estimation-field">
              <TextField
                onWheel={(event) => event.preventDefault()}
                error={value && !estimation && estimation !== 0}
                type="number"
                fullWidth
                onBlur={onBlur}
                value={!estimation && estimation !== 0 ? "" : estimation}
                disabled={readOnly}
                onChange={onEstimatedChange}
                label={t("ratingSystem.indicator.estimation")}
                className="number-no-appearance"
                inputProps={{ step: 0.01, min: 0, max: 100 }}
              />
              <div className="value-unit">%</div>
            </div>
          )}
        </div>
        {!!linkedGeneralAttributeName && (
          <span className="text-warning">
            {t("ratingSystem.indicator.kpiWarning", { name: linkedGeneralAttributeName })}
          </span>
        )}
      </div>
    );
  }
);
