import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject, Subject } from "rxjs";
import { useCallback, useEffect, useState } from "react";

export const tableScrollXSubject = new BehaviorSubject(0);
export const useTableScrollX = hookFromSubject(tableScrollXSubject);
export const setTableScrollX = value => tableScrollXSubject.next(value);

export const tableScrollYSubject = new BehaviorSubject(0);
export const useTableScrollY = hookFromSubject(tableScrollYSubject);
export const setTableScrollY = value => tableScrollYSubject.next(value);

export const tableOffsetXSubject = new BehaviorSubject(0);
export const useTableOffsetX = hookFromSubject(tableOffsetXSubject);
export const setTableOffsetX = value => tableOffsetXSubject.next(value);

export const columnRangesSubject = new BehaviorSubject([]);
export const useColumnRanges = hookFromSubject(columnRangesSubject);
export const setColumnRanges = value => columnRangesSubject.next(value);

export const columnWidthsSubject = new BehaviorSubject([]);
export const useColumnWidths = hookFromSubject(columnWidthsSubject);
export const setColumnWidths = value => columnWidthsSubject.next(value);

export const tableHoverControlsSubject = new BehaviorSubject(0);
export const useTableHoverControls = hookFromSubject(tableHoverControlsSubject);
export const setTableHoverControls = value => tableHoverControlsSubject.next(value);

export const tableScrollWidthSubject = new Subject();
export const useTableScrollWidth = hookFromSubject(tableScrollWidthSubject);
export const setTableScrollWidth = value => tableScrollWidthSubject.next(value);

export const dockResizeSubject = new BehaviorSubject({});
export const useDockResize = hookFromSubject(dockResizeSubject);
export const setDockResize = value => dockResizeSubject.next(value);

export const dragColumnSubject = new BehaviorSubject(null);
export const useDragColumn = hookFromSubject(dragColumnSubject);
export const setDragColumn = value => dragColumnSubject.next(value);

export const dragRowSubject = new BehaviorSubject(null);
export const useDragRow = hookFromSubject(dragRowSubject);
export const setDragRow = value => dragRowSubject.next(value);

export const currentDragRowSubject = new BehaviorSubject(null);
export const useIsCurrentDragRow = (UID) => {
  const [isDragRow, setIsDragRow] = useState(false);

  const handleChange = useCallback(value => {
    const currentDragRow = currentDragRowSubject.getValue();
    if ((UID === value?.UID || UID === currentDragRow?.UID) && currentDragRow?.UID !== value?.UID) {
      setIsDragRow(UID === value?.UID);
      currentDragRowSubject.next(value);
    }
  }, [UID]);

  useEffect(() => {
    const subscription = dragRowSubject.subscribe(handleChange);
    return () => subscription.unsubscribe();
  }, [UID]);

  return isDragRow;
};

export const selectedRowSubject = new BehaviorSubject(null);
export const useSelectedRow = hookFromSubject(selectedRowSubject);

export const resizeColumnSubject = new BehaviorSubject(null);
export const useResizeColumn = hookFromSubject(resizeColumnSubject);
export const setResizeColumn = value => resizeColumnSubject.next(value);

export const advancedTableDataSubject = new BehaviorSubject([]);
export const useAdvancedTableData = hookFromSubject(advancedTableDataSubject);
export const setAdvancedTableData = data => advancedTableDataSubject.next(data);
