import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Checkbox, Dialog, DialogTitle, FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TENANT_STATUSES, TENANT_STATUS_ENABLED, TENANT_STATUS_TYPES } from "../../../constants/tenant";
import { createTenant, updateTenant, useCreateTenantResponse, useUpdateTenantResponse } from "../../../hooks/tenant";
import { showError } from "../../../hooks/toast";
import { FormController } from "../../FormController/FormController";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { SimpleSelectController } from "../../SimpleSelectController/SimpleSelectController";
import "./TenantsDialog.scss";

export const TenantsDialog = ({ rowData, onSaved, onClose }) => {
  const { t } = useTranslation();
  const updateResponse = useUpdateTenantResponse();
  const createResponse = useCreateTenantResponse();

  const { control, getValues, trigger, reset } = useForm();
  const [title, setTitle] = React.useState("");

  const loading = createResponse.loading || updateResponse.loading;
  const isEdit = !!rowData?.id;

  useEffect(() => {
    if (rowData) {
      setTitle(isEdit ? t("tenants.dialog.edit") : t("tenants.dialog.create"));
      reset({
        id: rowData.id || "",
        name: rowData.name || "",
        companyName: rowData.companyName || "",
        type: rowData.type || "",
        status: rowData.status || "",
        tenantIsDefault: rowData.tenantIsDefault || false,
      });
    }
  }, [rowData]);

  useEffect(() => {
    if (!loading) {
      if (updateResponse.data || createResponse.data) {
        onSaved();
        onClose();
      }
    }
  }, [updateResponse.data, createResponse.data]);

  const onCancel = () => {
    if (!loading) {
      onClose();
    }
  };

  const onSubmit = async () => {
    const result = await trigger();
    if (result) {
      if (isEdit) {
        updateTenant({ ...getValues() })
          .then()
          .catch(handleError);
      } else {
        createTenant({ ...getValues() })
          .then()
          .catch(handleError);
      }
    }
  };

  const handleError = (error) => {
    console.error(error);
    showError(t("error.500"));
  };

  return (
    <Dialog open={!!rowData} onClose={onCancel} className="tenants-dialog">
      <LoadingOverlay spinner active={loading} className="tenants-dialog-loader">
        <DialogTitle>
          {title}
          <IconButton onClick={onClose} size="small">
            <CloseOutlinedIcon />
          </IconButton>
        </DialogTitle>
        <FormController
          control={control}
          name="name"
          required
          label={t("tenants.name")}
          render={({ field, fieldState, label }) => (
            <TextField
              error={fieldState.invalid}
              fullWidth
              inputProps={{ maxLength: 255 }}
              label={label + " *"}
              {...field}
            />
          )}
        />
        <FormController
          control={control}
          name="companyName"
          required
          label={t("tenants.companyName")}
          render={({ field, fieldState, label }) => (
            <TextField
              error={fieldState.invalid}
              fullWidth
              inputProps={{ maxLength: 255 }}
              label={label + " *"}
              {...field}
            />
          )}
        />
        <div className="flex-row flex-space-between">
          <SimpleSelectController
            control={control}
            name="type"
            required
            label={t("tenants.type")}
            disabled={isEdit}
            options={TENANT_STATUS_TYPES.map((item) => ({ name: item, text: item }))}
          />
          <SimpleSelectController
            control={control}
            name="status"
            required
            label={t("tenants.status")}
            options={TENANT_STATUSES.map((item) => ({
              name: item,
              text: item === TENANT_STATUS_ENABLED ? t("tenants.enabled") : t("tenants.disabled"),
            }))}
          />
        </div>
        <FormController
          control={control}
          name="tenantIsDefault"
          label={t("tenants.dialog.tenantIsDefault")}
          render={({ field, label }) => (
            <FormControlLabel
              control={<Checkbox checked={field.value} {...field} name={label} />}
              label={label}
              disabled={!isEdit}
            />
          )}
        />
        <div className="buttons text-right">
          <Button onClick={onCancel}>{t("main.cancel")}</Button>
          <Button color="primary" onClick={onSubmit}>
            {t("main.save")}
          </Button>
        </div>
      </LoadingOverlay>
    </Dialog>
  );
};
