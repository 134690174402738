export const getColumnPosition = (index, columnsProcessed) => {
  const widths = columnsProcessed.map(item => item.width);
  let res = 0;
  for (let i = 0; i < index; i++) {
    res += widths[i];
  }
  return res;
};

export const getColumnGroupStates = (columnsProcessed, columnGroups) => {
  const columnGroupStates = [];
  (columnsProcessed || []).forEach((column, index) => {
    const columnGroup = column.columnGroup ? columnGroups[column.columnGroup] : null;
    const previousColumn = columnsProcessed[index - 1] || null;
    const firstInGroup = column.columnGroup ? !previousColumn || previousColumn.columnGroup !== column.columnGroup || !!previousColumn.locked !== !!column.locked : false;
    const nextColumn = columnsProcessed[index + 1] || null;
    const lastInGroup = column.columnGroup ? !nextColumn || nextColumn.columnGroup !== column.columnGroup || !!nextColumn.locked !== !!column.locked : false;
    columnGroupStates[index] = { firstInGroup, lastInGroup, columnGroup };
  });
  return columnGroupStates;
};

export const expandRowCondition = (node, path, errors) => {
  const pathName = path.join('_');
  return (errors || []).some(error => pathName === error.path.join('_'));
};
