import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import { useTranslation } from "react-i18next";

import { useState } from "react";
import "./AddUserTenantDialog.scss";

export const AddUserTenantDialog = ({ open, onClose, userList, onSave }) => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState(null);

  const save = (user) => {
    onSave(user);
    onClose();
  };

  return (
    <Dialog className="add-user-tenant-dialog" open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        {t("users.addToTenantHeader")}
        <IconButton onClick={onClose} size="small">
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={userList}
          renderInput={(params) => <TextField label={t("users.user")} {...params} />}
          getOptionLabel={(user) => (user.firstName ? `${user.firstName} ` : "") + (user.lastName ?? "")}
          onChange={(event, user) => setSelectedUser(user)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("main.cancel")}</Button>
        <Button onClick={() => save(selectedUser)} color="primary">
          {t("main.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
