export const ResourcesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32.683 32.342">
    <g id="icon-resources" transform="translate(-2.658 -2.658)">
      <path
        id="Path_49"
        data-name="Path 49"
        d="M19,4,4,11.5,19,19l15-7.5L19,4"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_50"
        data-name="Path 50"
        d="M4,12l15,7.5L34,12"
        transform="translate(0 7)"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_51"
        data-name="Path 51"
        d="M4,16l15,7.5L34,16"
        transform="translate(0 10.5)"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
);
