import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GROUPING_TYPE_CATEGORY, GROUPING_TYPE_KPI } from "../../../constants/kpiSystem";
import { expandRowCondition } from "../../../helpers/advancedTable";
import { matchDropLogic, sortKPIGroupings } from "../../../helpers/sustainabilitySystem";
import { showDialog } from "../../../hooks/dialog";
import { GroupingElement } from "../../../models/GroupingElement";
import { CategorySVG, IndicatorSVG } from "../../../svg";
import { findRecursive, forEachRecursive } from "../../../utils";
import { conditionIsKPI, conditionNotKPI } from "../../../validation/kpiSystem";
import { AdvancedTable } from "../../AdvancedTable/AdvancedTable";
import { COLUMN_TYPE_TEXT } from "../../AdvancedTable/AdvancedTableCell";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { SearchBadge } from "../../SearchBadge/SearchBadge";
import { KPIDataDialog } from "../KPIDataDialog/KPIDataDialog";
import { AddMenuPopover } from "./AddMenuPopover";
import { KPIColumnTemplate } from "./KPIColumnTemplate";
import "./KPISystemGrouping.scss";

export const KPISystemGrouping = React.memo((props) => {
  const { values, onChange, readOnly, submitted, errors, errorMessage, kpiSystemId } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState({});
  const [addPopoverTarget, setAddPopoverTarget] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const showKPIDialog = (row, passThroughInner) => {
    const {
      readOnly: readOnlyInner,
      changeKPIFilesAndLinks: changeKPIFilesAndLinksInner,
      submitted: submittedInner,
      kpiSystemId: kpiSystemIdInner,
    } = passThroughInner || {};
    showDialog({
      className: "large",
      closeOnClickOutside: !!readOnlyInner,
      getContent: (onClose) => (
        <KPIDataDialog
          row={row}
          onClose={onClose}
          onChange={changeKPIFilesAndLinksInner}
          readOnly={readOnlyInner}
          submitted={submittedInner}
          kpiSystemId={kpiSystemIdInner}
        />
      ),
    });
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerText: t("grouping.groupingTitle"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionNotKPI,
        getIcon: (row) => (row.type === GROUPING_TYPE_CATEGORY ? <CategorySVG /> : null),
        groupingColumn: true,
        minWidth: 200,
        width: 335,
        maxLength: 255,
        search: true,
      },
      {
        field: "kpiName",
        headerText: t("grouping.kpiName"),
        type: COLUMN_TYPE_TEXT,
        className: "kpi-column",
        showCondition: conditionIsKPI,
        editableCondition: () => false,
        startAdornment: <IndicatorSVG />,
        getEndAdornment: (row, passThroughKpiName) => (
          <IconButton onClick={() => showKPIDialog(row, passThroughKpiName)} className={"no-padding"}>
            <DescriptionOutlinedIcon />
          </IconButton>
        ),
        template: KPIColumnTemplate,
        minWidth: 200,
        width: 335,
        search: true,
      },
    ],
    []
  );

  const searched = columns.filter((item) => !!item.search && search[item.field]);
  const errorsByPath = useMemo(() => {
    const errorsByPathSearched = {};
    errors.forEach((item) => {
      const path = item.path.join("_");
      if (!errorsByPathSearched[path]) {
        errorsByPathSearched[path] = item;
      }
    });
    return errorsByPathSearched;
  }, [errors]);

  const addRootGroupingElement = () => {
    const rootGroupingElement = new GroupingElement();
    const updated = [...values, rootGroupingElement];
    onChange(updated);
  };

  const addCategory = (row, hideControls, type) => {
    setAddPopoverTarget(null);
    hideControls();
    const groupingElement = new GroupingElement({ type });
    const updated = [...values];
    const { parent, index } = row;
    if (parent) {
      const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
      if (foundParent) {
        foundParent.children.splice(index + 1, 0, groupingElement);
      }
    } else {
      updated.splice(index + 1, 0, groupingElement);
    }
    onChange(updated);
  };

  const addChildCategory = (row, hideControls, type) => {
    setAddPopoverTarget(null);
    hideControls();
    const { index } = row;
    const childCategoryGroupingElement = new GroupingElement({ type });
    const updated = [...values];
    const found = findRecursive(updated, (node) => node.UID === row.UID);
    if (found) {
      found.children.splice(index + 1, 0, childCategoryGroupingElement);
    }
    onChange(updated);
  };

  const addKPI = (row, hideControls) => {
    setAddPopoverTarget(null);
    hideControls();
    const updated = [...values];
    const { parent, index } = row;
    const kpi = new GroupingElement({ type: GROUPING_TYPE_KPI });
    if (row.type === GROUPING_TYPE_KPI) {
      if (parent) {
        const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
        if (foundParent) {
          foundParent.children.splice(index + 1, 0, kpi);
        }
      } else {
        updated.splice(index + 1, 0, kpi);
      }
    } else {
      const found = findRecursive(updated, (node) => node.UID === row.UID);
      if (found) {
        found.children.splice(index + 1, 0, kpi);
      }
    }
    onChange(updated);
  };

  const renderAddPopover = (row, hideControls) => {
    return (
      <AddMenuPopover
        row={row}
        addCategory={addCategory}
        addChildCategory={addChildCategory}
        addKPI={addKPI}
        addPopoverTarget={addPopoverTarget}
        hideControls={hideControls}
        setAddPopoverTarget={setAddPopoverTarget}
      />
    );
  };

  const renderHoverControlButtons = (row, hideControls) => {
    return (
      <>
        <Fab color="primary" size="small" onClick={(event) => setAddPopoverTarget(event.target)}>
          <AddIcon />
        </Fab>
        <Fab color="secondary" size="small" onClick={() => setDeleteConfirmation({ row, hideControls })}>
          <DeleteOutlineIcon />
        </Fab>
      </>
    );
  };

  const deleteHandler = () => {
    setDeleteConfirmation(false);
    const { row, hideControls } = deleteConfirmation;
    hideControls();
    const updated = [...values];
    const { parent, index } = row;
    if (parent) {
      const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
      if (foundParent) {
        foundParent.children.splice(index, 1);
      }
    } else {
      updated.splice(index, 1);
    }
    onChange(updated);
  };

  const getRowClassName = (row) => "sustainability-row-" + row.type;

  const changeKPIFilesAndLinks = useCallback(
    (UID, links, files) => {
      const updated = [...values];
      forEachRecursive(updated, (node) => {
        if (node.UID === UID) {
          node.links = links || [];
          node.files = files || [];
          return true;
        }
      });
      onChange(updated);
    },
    [values]
  );

  const changeKPI = useCallback(
    (UID, kpiName, kpiUnit) => {
      const updated = [...values];
      forEachRecursive(updated, (node) => {
        if (node.UID === UID) {
          node.kpiName = kpiName;
          node.kpiUnit = kpiUnit;
          return true;
        }
      });
      onChange(updated);
    },
    [values]
  );

  const passThrough = useMemo(
    () => ({ readOnly, submitted, changeKPIFilesAndLinks, changeKPI, kpiSystemId }),
    [kpiSystemId, readOnly, submitted, values]
  );

  const deleteRow = (deleteConfirmation || {}).row;
  const isDeleteKPI = !!deleteRow && deleteRow.type === GROUPING_TYPE_KPI;

  const expandCondition = useCallback((node, path) => expandRowCondition(node, path, errors), [errors]);

  return (
    <div className="kpi-system-grouping">
      {!!searched.length && (
        <div className="filters">
          {searched.map((item, index) => (
            <SearchBadge
              key={index}
              field={item.headerText}
              value={search[item.field].data}
              onClose={() => setSearch({ ...search, [item.field]: undefined })}
            />
          ))}
          <Button className="clear-filters" onClick={() => setSearch({})}>
            {t("main.clear")}
          </Button>
        </div>
      )}
      <AdvancedTable
        stickyAmount={0}
        data={values}
        errorMessage={errorMessage}
        expandRowsByCondition={!!errorMessage}
        expandRowCondition={expandCondition}
        errorMarkRowsByCondition={!!errorMessage}
        errorMarkRowCondition={expandCondition}
        onChange={onChange}
        getRowClassName={getRowClassName}
        readOnly={readOnly}
        submitted={submitted}
        errorsByPath={errorsByPath}
        groupingField={"children"}
        onSearch={setSearch}
        search={search}
        columns={columns}
        footer={
          <div>
            {!readOnly && !(values || []).length && (
              <Button variant="contained" color="primary" onClick={addRootGroupingElement}>
                <AddIcon /> {t("grouping.addCategory")}
              </Button>
            )}
          </div>
        }
        sortRows={sortKPIGroupings}
        renderAddPopover={renderAddPopover}
        renderHoverControlButtons={renderHoverControlButtons}
        matchDropLogic={matchDropLogic}
        passThrough={passThrough}
        disableColumnLock
      />

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={t(isDeleteKPI ? "grouping.deleteKPIConfirmationTitle" : "grouping.deleteConfirmationTitle")}
        bodyText={t(isDeleteKPI ? "grouping.deleteKPIConfirmation" : "grouping.deleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />
    </div>
  );
});
