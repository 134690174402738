import React from "react";
import { useTranslation } from "react-i18next";
import { SimpleSelectController } from "../../SimpleSelectController/SimpleSelectController";

export const GoalTypeSelect = ({ control, options, required }) => {
  const { t } = useTranslation();

  return (
    <SimpleSelectController
      control={control} name="typeKey" required={required} label={t("ratingSystem.selectGoalType")} disabled={false}
      options={options} fullObject/>
  );
};
