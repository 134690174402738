import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { ACTION_CUSTOM, ACTION_GLOBAL, ACTION_STATUS_OPTIONS } from "../../../constants/action";
import { TENANT_TYPE_PAST } from "../../../constants/tenant";
import { findAction, useActionFindResponse } from "../../../hooks/action";
import { getUserTenantType } from "../../../hooks/auth";
import { showError } from "../../../hooks/toast";
import { forEachRecursive } from "../../../utils";
import { validateUniqueName } from "../../../validation/action";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import { TrafficLight } from "../../TrafficLight/TrafficLight";
import { ActionDateTemplate } from "./ActionDateTemplate";
import { ActionNumberTemplate } from "./ActionNumberTemplate";
import { ActionSelectTemplate } from "./ActionSelectTemplate";
import { ActionTextTemplate } from "./ActionTextTemplate";
import { AddActionsTableFooter } from "./AddActionsTableFooter";

const TrafficLightTemplate = (row, field, parentRow, passThrough) => (
  <TrafficLight
    value={row?.[field]}
    onChange={(value) => passThrough.onValueChange(row, field, value)}
    readOnly={passThrough.readOnly}
  />
);

export const AddActionsTable = ({
  data,
  readOnly,
  onChange,
  submitted,
  sustainabilityIndicatorElementUuid,
  originIndicatorElementUuid,
  sustainabilityGroupingElementUuid,
  originGroupingElementUuid,
  kpiSystemElementUuid,
  grouping,
}) => {
  const { t } = useTranslation();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const findActionResponse = useActionFindResponse();
  const tenantType = getUserTenantType();

  const onValueChange = (row, field, value) => {
    if (row[field] !== value) {
      row[field] = value;
      onChange([...data]);
    }
  };

  const deleteAction = (row) => {
    const updated = [...data];
    const index = updated.indexOf(row);
    if (index !== -1) {
      updated.splice(index, 1);
    }
    onChange(updated);
  };

  const addCustomAction = async (name) => {
    if (validateUniqueName(name, data)) {
      const action = (await findExistingAction(name)) || {};
      onChange([...data, { ...action, name, source: ACTION_CUSTOM, UID: getUUID() }]);
    } else {
      showError(t("ratingSystem.actions.uniqueValidationFailed"));
    }
  };

  const findExistingAction = useCallback(
    async (name, id, withinProject) => {
      let res = null;
      forEachRecursive(grouping, (node) => {
        if (node?.actions?.length) {
          const action = node.actions.find((item) => item.name === name);
          if (action) {
            res = action;
            return true;
          }
        }
      });
      if (!res && withinProject) {
        try {
          const object = await findAction(id);
          if (object) {
            res = { ...object, id: undefined };
          }
        } catch (error) {
          console.error(error);
        }
      }
      return res;
    },
    [grouping]
  );

  const addAction = async (option) => {
    if (option) {
      const { id, name, description, listTags, withinProject } = option;
      if (validateUniqueName(name, data)) {
        const action = await findExistingAction(name, id, withinProject);
        onChange([
          ...data,
          action
            ? { ...action, id: undefined }
            : {
                templateId: id,
                name,
                description,
                source: ACTION_GLOBAL,
                tags: listTags,
                UID: id,
              },
        ]);
      } else {
        showError(t("ratingSystem.actions.uniqueValidationFailed"));
      }
    }
  };

  const deleteHandler = () => {
    deleteAction(deleteConfirmation);
    setDeleteConfirmation(null);
  };

  const columns =
    tenantType === TENANT_TYPE_PAST
      ? [
          {
            field: "name",
            headerText: t("action.name"),
            headerClassName: "name-column capitalized",
            template: (row, field, parentRow, passThrough) => (
              <ActionTextTemplate row={row} field={field} passThrough={passThrough} maxLength={255} required />
            ),
          },
          {
            field: "description",
            headerText: t("action.description"),
            headerClassName: "description-column",
            minWidth: "400px",
            template: (row, field, parentRow, passThrough) => (
              <ActionTextTemplate row={row} field={field} passThrough={passThrough} maxLength={255} required />
            ),
          },
          {
            field: "suggestion",
            headerText: t("action.priority"),
            headerClassName: "default-column",
            width: "100px",
            minWidth: "90px",
            template: TrafficLightTemplate,
          },
          {
            field: "remarks",
            headerText: t("action.remarks"),
            headerClassName: "default-column",
            template: (row, field, parentRow, passThrough) => (
              <ActionTextTemplate row={row} field={field} passThrough={passThrough} maxLength={1024} />
            ),
          },
          {
            field: "responsible",
            headerText: t("action.responsible"),
            headerClassName: "responsible-column",
            template: (row, field, parentRow, passThrough) => (
              <ActionTextTemplate row={row} field={field} passThrough={passThrough} maxLength={24} />
            ),
          },
          {
            field: "endDate",
            headerText: t("action.endDate"),
            headerClassName: "date-column",
            width: "140px",
            minWidth: "135px",
            template: (row, field, parentRow, passThrough) => (
              <ActionDateTemplate row={row} field={field} passThrough={passThrough} />
            ),
          },
          {
            field: "status",
            headerText: t("action.status"),
            headerClassName: "default-column",
            width: "100px",
            minWidth: "100px",
            template: (row, field, parentRow, passThrough) => (
              <ActionSelectTemplate
                selectValues={ACTION_STATUS_OPTIONS}
                row={row}
                field={field}
                passThrough={passThrough}
                includeEmptyValue={true}
                emptyValueString={t("action.statuses.nostatus")}
              />
            ),
          },
        ]
      : [
          {
            field: "name",
            headerText: t("action.name"),
            headerClassName: "name-column capitalized",
            template: (row, field, parentRow, passThrough) => (
              <ActionTextTemplate
                row={row}
                field={field}
                passThrough={passThrough}
                readOnly={row.source !== ACTION_CUSTOM}
                maxLength={255}
                required
              />
            ),
          },
          {
            field: "description",
            headerText: t("action.description"),
            headerClassName: "description-column",
            template: (row, field, parentRow, passThrough) => (
              <ActionTextTemplate
                row={row}
                field={field}
                passThrough={passThrough}
                maxLength={255}
                readOnly={row.source !== ACTION_CUSTOM}
                required
              />
            ),
          },
          {
            field: "investmentAmount",
            headerText: t("action.investmentAmount"),
            template: (row, field, parentRow, passThrough) => (
              <ActionNumberTemplate row={row} field={field} passThrough={passThrough} max={9999999999.99} step={0.01} />
            ),
          },
          {
            field: "savingsOverLifecycle",
            headerText: t("action.savingsOverLifecycle"),
            template: (row, field, parentRow, passThrough) => (
              <ActionNumberTemplate row={row} field={field} passThrough={passThrough} max={99999999.99} step={0.01} />
            ),
          },
          {
            field: "lifecycleInYears",
            headerText: t("action.lifecycleInYears"),
            template: (row, field, parentRow, passThrough) => (
              <ActionNumberTemplate row={row} field={field} passThrough={passThrough} max={99.99} step={0.01} />
            ),
          },
          {
            field: "roiStatic",
            headerText: t("action.roiStatic"),
            template: (row, field, parentRow, passThrough) => (
              <ActionNumberTemplate row={row} field={field} passThrough={passThrough} max={99.99} step={0.01} />
            ),
          },
          {
            field: "operational",
            headerText: t("action.operational"),
            headerClassName: "default-column",
            template: TrafficLightTemplate,
          },
          {
            field: "suggestion",
            headerText: t("action.suggestion"),
            headerClassName: "default-column",
            template: TrafficLightTemplate,
          },
          {
            field: "remarks",
            headerText: t("action.remarks"),
            headerClassName: "default-column",
            template: (row, field, parentRow, passThrough) => (
              <ActionTextTemplate row={row} field={field} passThrough={passThrough} maxLength={1024} />
            ),
          },
          {
            field: "responsible",
            headerText: t("action.responsible"),
            headerClassName: "responsible-column",
            template: (row, field, parentRow, passThrough) => (
              <ActionTextTemplate row={row} field={field} passThrough={passThrough} maxLength={24} />
            ),
          },
          {
            field: "startDate",
            headerText: t("action.startDate"),
            headerClassName: "date-column",
            template: (row, field, parentRow, passThrough) => (
              <ActionDateTemplate row={row} field={field} passThrough={passThrough} />
            ),
          },
          {
            field: "endDate",
            headerText: t("action.endDate"),
            headerClassName: "date-column",
            template: (row, field, parentRow, passThrough) => (
              <ActionDateTemplate row={row} field={field} passThrough={passThrough} />
            ),
          },
          {
            field: "status",
            headerText: t("action.status"),
            headerClassName: "default-column",
            template: (row, field, parentRow, passThrough) => (
              <ActionSelectTemplate
                selectValues={ACTION_STATUS_OPTIONS}
                row={row}
                field={field}
                passThrough={passThrough}
                includeEmptyValue={true}
                emptyValueString={t("action.statuses.nostatus")}
              />
            ),
          },
        ];

  if (!readOnly) {
    columns.push({
      headerText: "",
      className: "actions-column",
      template: (row) => (
        <IconButton size="small" onClick={() => setDeleteConfirmation(row)}>
          <DeleteOutlineIcon />
        </IconButton>
      ),
    });
  }

  return (
    <LoadingOverlay spinner active={findActionResponse.loading} className="auto-height">
      <SimpleTableWithSort
        className="actions-table"
        data={data}
        passThrough={{ t, readOnly, onValueChange, submitted }}
        columns={columns}
        hideEmptyMessage={!readOnly}
        bodyFooter={
          readOnly ? null : (
            <AddActionsTableFooter
              addCustomAction={addCustomAction}
              addAction={addAction}
              sustainabilityIndicatorElementUuid={sustainabilityIndicatorElementUuid}
              originIndicatorElementUuid={originIndicatorElementUuid}
              sustainabilityGroupingElementUuid={sustainabilityGroupingElementUuid}
              originGroupingElementUuid={originGroupingElementUuid}
              kpiSystemElementUuid={kpiSystemElementUuid}
            />
          )
        }
      />
      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={t("actions.actionDeleteConfirmationTitle")}
        bodyText={t("actions.actionDeleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />
    </LoadingOverlay>
  );
};

AddActionsTable.propTypes = {
  data: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
  sustainabilityIndicatorElementUuid: PropTypes.string,
  originIndicatorElementUuid: PropTypes.string,
  sustainabilityGroupingElementUuid: PropTypes.string,
  originGroupingElementUuid: PropTypes.string,
  kpiSystemElementUuid: PropTypes.string,
  grouping: PropTypes.array,
};
