import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { v4 as getUUID } from "uuid";
import { ADVANCED_TABLE_ID } from "../../components/AdvancedTable/AdvancedTable";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { KPISystemBaseData } from "../../components/KPISystem/KPISystemBaseData/KPISystemBaseData";
import { KPISystemGrouping } from "../../components/KPISystem/KPISystemGrouping/KPISystemGrouping";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { StatusSelect } from "../../components/StatusSelect/StatusSelect";
import { SystemSource } from "../../components/SustainabilitySystem/SustainabilitySystemFields/SystemSource";
import { VersionsDialog } from "../../components/Versions/VersionsDialog/VersionsDialog";
import { GROUPING_TYPE_KPI, STATUS_IN_PROGRESS, STATUS_LOCKED, STATUS_RELEASED } from "../../constants/kpiSystem";
import {
  PERMISSION_KPI_READ,
  PERMISSION_KPI_SYSTEM_EDIT,
  PERMISSION_KPI_SYSTEM_STATUS_CHANGE,
} from "../../constants/permissions";
import { mergeKPISystem } from "../../helpers/merge/kpiSystem";
import { hasAnyPermissions, hasPermission } from "../../helpers/permission";
import { useTableScrollX, useTableScrollY } from "../../hooks/advancedTable";
import { useEditSafeExit } from "../../hooks/editing";
import { listKPI } from "../../hooks/kpi";
import {
  getKpiSystem,
  getKpiSystemRevisionById,
  getKpiSystemRevisions,
  updateKpiSystem,
  useKpiSystemResponse,
  useKpiSystemRevisionByIdResponse,
  useKpiSystemRevisionsResponse,
  useKpiSystemUpdateResponse,
} from "../../hooks/kpiSystem";
import { showError, showInfo, showSuccess, showWarning } from "../../hooks/toast";
import { CloseSVG, ManageHistorySVG, SaveSVG } from "../../svg";
import { mapRecursive, processMessage, scrollElementByIdTo } from "../../utils";
import { processGrouping, validateGrouping, validationErrorMessage } from "../../validation/kpiSystem";
import "./KPISystem.scss";

const STATUSES_BY_STATUS = {
  undefined: [STATUS_IN_PROGRESS],
  [STATUS_IN_PROGRESS]: [STATUS_IN_PROGRESS, STATUS_RELEASED],
  [STATUS_RELEASED]: [STATUS_IN_PROGRESS, STATUS_RELEASED, STATUS_LOCKED],
  [STATUS_LOCKED]: [STATUS_IN_PROGRESS, STATUS_RELEASED, STATUS_LOCKED],
};

export const KPISystemPage = () => {
  const mode = new URLSearchParams(window.location.search).get("mode");
  const { kpiSystemId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const editPermissions = hasAnyPermissions([PERMISSION_KPI_SYSTEM_EDIT, PERMISSION_KPI_SYSTEM_STATUS_CHANGE]);

  const response = useKpiSystemResponse();
  const system = response.data || {};
  const [isEdit, setIsEdit] = useState(mode === "edit" && editPermissions);
  const [submitted, setSubmitted] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [statusConfirmation, setStatusConfirmation] = useState(false);
  const [autoEdit, setAutoEdit] = useState(false);
  const [editIsDisabled, setEditIsDisabled] = useState(false);
  const [versionDialog, setVersionDialog] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [saveMerged, setSaveMerged] = useState(false);
  const versions = useKpiSystemRevisionsResponse();
  const versionById = useKpiSystemRevisionByIdResponse();
  const [updateCounter, setUpdateCounter] = useState(0);

  useEditSafeExit(isEdit);

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      name: "",
      systemSource: "",
      systemVersion: "",
      description: "",
      status: "",
      projectTypes: [],
    },
  });

  const [grouping, setGrouping] = useState([]);
  const updateResponse = useKpiSystemUpdateResponse();

  useEffect(() => {
    if (kpiSystemId) {
      if (mode === "edit" && editPermissions) {
        window.history.replaceState(null, null, window.location.pathname);
      }
      load();
      getVersions();
    }
  }, [kpiSystemId]);

  const sortedVersions = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return [...data].sort((a, b) => b.revisionNumber - a.revisionNumber);
  };

  const versionHandler = (revisionNumber, isCurrent) => {
    getKpiSystemRevisionById(kpiSystemId, revisionNumber)
      .then((data) => {
        resetData(data);
        setSelectedVersion(revisionNumber);
        setEditIsDisabled(!isCurrent);
      })
      .catch(() => {
        showError(t("error.500"));
      });
    setVersionDialog(false);
  };

  useEffect(() => {
    hasPermission(PERMISSION_KPI_READ) && listKPI();
  }, []);

  useEffect(() => {
    if (saveMerged) {
      try {
        setSaveMerged(false);
        onSubmit(getValues());
      } catch (e) {
        showError(t("error.500"));
      }
    }
  }, [saveMerged]);

  const mergeAndSave = async (updated) => {
    try {
      const current = await getKpiSystem(kpiSystemId);
      const [merged, withConflicts] = mergeKPISystem(system, current, updated);
      merged.groupingElements = processGrouping(merged.children);
      if (withConflicts) {
        showWarning(processMessage(t("main.savedWithConflicts"), [current?.modifier]));
      }
      resetData(merged);
      setSaveMerged(true);
    } catch (e) {
      showError(t("error.500"));
    }
  };

  const load = () => {
    getKpiSystem(kpiSystemId)
      .then((data) => resetData(data))
      .catch(() => {
        showError(t("kpiSystem.error.load"));
        navigate("/sustainabilitySystem/kpiSystem");
      });
  };

  const getVersions = () => {
    getKpiSystemRevisions(kpiSystemId)
      .then((data) => {
        setSelectedVersion(data[data.length - 1]?.revisionNumber || null);
      })
      .catch(() => {
        showError(t("error.500"));
      });
  };

  const submitForm = () => onSubmit(getValues());

  const handleValidationError = () => {
    let message = validationErrorMessage(groupingErrors, t);
    if (message) {
      setErrorMessage(message);
    }
  };

  const onSubmit = async (values) => {
    let updated;
    try {
      setFormValues(values);
      setSubmitted(true);
      setErrorMessage(null);
      if (!groupingErrors.length) {
        updated = {
          ...values,
          groupingElements: processGrouping(grouping),
          modifiedDateTime: system?.modifiedDateTime,
        };
        const data = await updateKpiSystem(kpiSystemId, updated);
        setSubmitted(false);
        resetData(data, !autoEdit);
        getVersions();
        showSuccess(t("kpiSystem.updatedMessage"));
      } else {
        handleValidationError();
      }
    } catch (error) {
      if (error.status === 409) {
        error.json().then((parsedBody) => {
          if (parsedBody?.message === "error.data.conflict") {
            showError(processMessage(t("kpiSystem.error.409"), [values.name]));
          } else if (parsedBody?.message === "error.optimistic.lock") {
            mergeAndSave(updated);
          }
        }, console.error);
      } else {
        showError(t("error.500"));
      }
    }
  };

  const onStatusChange = (event) => {
    const systemInner = response.data || {};
    if (
      isEdit &&
      systemInner.usedInProject &&
      currentStatus === STATUS_RELEASED &&
      currentStatus !== event.target.value
    ) {
      setStatusConfirmation(true);
      setAutoEdit(true);
    } else {
      setAutoEdit(false);
    }
    if (
      isEdit &&
      systemInner.usedInProject &&
      currentStatus !== STATUS_RELEASED &&
      event.target.value === STATUS_RELEASED
    ) {
      showInfo(t("kpiSystem.infoUsedInProject"));
    }
  };

  const resetData = (values, disableEditing) => {
    setAutoEdit(false);
    if (disableEditing) {
      setIsEdit(false);
    }
    const data = values || response.data || {};
    reset({
      name: data.name || "",
      systemSource: data.systemSource || "",
      systemVersion: data.systemVersion || "",
      projectTypes: data.projectTypes || [],
      description: data.description || "",
      status: data.status || STATUS_IN_PROGRESS,
    });
    const processedGroupings = mapRecursive(
      data.groupingElements || [],
      (node, children) => {
        return {
          UID: String(node.id || getUUID()),
          ...node,
          children: [
            ...(node.kpiElements || []).map((item) => {
              return {
                UID: item.id ? "i_" + item.id : getUUID(),
                ...item,
                type: GROUPING_TYPE_KPI,
              };
            }),
            ...children,
          ],
        };
      },
      "groupingElements"
    );
    setGrouping(processedGroupings);
    setSubmitted(false);
    setErrorMessage(null);
    setUpdateCounter(updateCounter + 1);
  };

  const groupingErrors = useMemo(() => validateGrouping(grouping), [grouping]);
  const currentStatus = (response.data || {}).status;
  const editDisabled =
    system.readOnly || !isEdit || currentStatus !== STATUS_IN_PROGRESS || !hasPermission(PERMISSION_KPI_SYSTEM_EDIT);

  useEffect(() => {
    if (submitted) {
      if (errorMessage) {
        setErrorMessage(null);
      }
      if (groupingErrors.length) {
        handleValidationError();
      }
    }
  }, [submitted, groupingErrors, formValues]);

  const loading = response.loading || updateResponse.loading || versionById.loading || versionById.loading;

  // Auto scroll back to position after saving
  const tableScrollPosition = { x: useTableScrollX(), y: useTableScrollY() };
  const [lastScrollPosition, setLastScrollPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (submitted) {
      setLastScrollPosition({ x: tableScrollPosition.x, y: tableScrollPosition.y });
    }
  }, [submitted]);

  useEffect(() => {
    scrollElementByIdTo(ADVANCED_TABLE_ID, lastScrollPosition);
  }, [updateCounter, loading]);
  // End auto scroll back to position after saving

  const canStartEdit =
    !system.readOnly &&
    ((hasPermission(PERMISSION_KPI_SYSTEM_EDIT) && [STATUS_RELEASED, STATUS_LOCKED].indexOf(currentStatus) === -1) ||
      hasPermission(PERMISSION_KPI_SYSTEM_STATUS_CHANGE));
  const canChangeStatus = !system.readOnly && isEdit && hasPermission(PERMISSION_KPI_SYSTEM_STATUS_CHANGE);

  const memoGrouping = useMemo(() => grouping, [JSON.stringify(grouping)]);
  const memoGroupingErrors = useMemo(() => groupingErrors, [JSON.stringify(groupingErrors)]);

  return (
    <LoadingOverlay spinner updateCounter={updateCounter} active={loading} className="kpi-system-page">
      <form autoComplete={"off"} id="kpiSystemForm" className="system-form">
        <div className="page-header">
          <div className="flex-row">
            <Breadcrumb
              pathParts={[
                { url: "/sustainabilitySystem/kpiSystem", text: t("menu.systems.kpi") },
                { text: system.systemSource },
              ]}
            />
            <div className="flex-auto text-right">
              <Button
                color="primary"
                className="right-header-button"
                onClick={() => setVersionDialog(true)}
                disabled={isEdit}
              >
                <ManageHistorySVG fontSize="inherit" style={{ fill: isEdit ? "rgba(0, 0, 0, 0.26)" : "" }}/>
                {t("main.version")}
              </Button>
            </div>
          </div>
          <div className="flex-row">
            <SystemSource
              systemSource={system.systemSource}
              control={control}
              editDisabled={editDisabled}
              isEdit={isEdit}
            />
            <div>
              <StatusSelect
                control={control}
                disabled={!canChangeStatus}
                value={currentStatus}
                onChange={onStatusChange}
                statuesByStatus={STATUSES_BY_STATUS}
                labelTranslationPrefix="kpiSystem.status."
              />
            </div>
            <div className="flex-auto"/>
          </div>
        </div>

        <KPISystemBaseData control={control} editDisabled={editDisabled} isEdit={isEdit}/>

        <div className="table-wrapper">
          <KPISystemGrouping
            values={memoGrouping}
            onChange={setGrouping}
            submitted={submitted}
            kpiSystemId={kpiSystemId}
            errors={memoGroupingErrors}
            readOnly={editDisabled}
            errorMessage={errorMessage}
          />
        </div>

        {canStartEdit && (
          <div className="fabs">
            {!isEdit && (
              <Fab type="button" color="primary" onClick={() => setIsEdit(true)} disabled={editIsDisabled}>
                <EditIcon className="fab-svg"/>
              </Fab>
            )}
            {isEdit && (
              <>
                <Fab color="secondary" onClick={() => resetData(null, true)}>
                  <CloseSVG className="fab-svg"/>
                </Fab>
                <Fab color="primary" onClick={handleSubmit(onSubmit)}>
                  <SaveSVG className="fab-svg"/>
                </Fab>
              </>
            )}
          </div>
        )}
      </form>

      <ConfirmationDialog
        open={!!statusConfirmation}
        onClose={() => {
          setStatusConfirmation(false);
          setValue("status", currentStatus);
        }}
        onConfirm={() => {
          setStatusConfirmation(false);
          submitForm();
        }}
        titleText={t("kpiSystem.changeStatusConfirmationTitle")}
        bodyText={t("kpiSystem.changeStatusConfirmation")}
        confirmText={t("main.save")}
        color="primary"
        showLineBreaks
      />

      <VersionsDialog
        open={versionDialog}
        onClose={() => setVersionDialog(false)}
        onAction={versionHandler}
        titleText={t("versions.titleSustainability")}
        data={sortedVersions(versions.data)}
        selectedVersion={selectedVersion}
        loading={versionById.loading}
      />
    </LoadingOverlay>
  );
};
