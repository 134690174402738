import { PERMISSION_SUPER_ADMIN, PERMISSION_TENANT_ADMIN } from "../constants/permissions";
import { userSubject } from "../hooks/auth";

export const hasPermission = (permission) => {
  const permissions = userSubject.getValue()?.permissions || [];
  return permissions.includes(permission) ||
    (permission !== PERMISSION_SUPER_ADMIN && permissions.includes(PERMISSION_TENANT_ADMIN));
};

export const hasPermissions = (permissions = []) => permissions.every(hasPermission);

export const hasAnyPermissions = (permissions = []) => permissions.some(hasPermission);
