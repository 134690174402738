import React from "react";
import "./GroupingElementBarChart.scss";
import { GroupingElementBeforeBarChart } from "../GroupingElementBeforeBarChart/GroupingElementBeforeBarChart";
import { roundDecimal } from "../../../utils";
import { useMaxPercentsVisible } from "../../../hooks/dashboard";

const MAX_BAR_HEIGHT = 370;

export const GroupingElementBarChartPAST = ({ data, isFirst, isBreak, maxProportion, onClick }) => {
  const { universalScore, title, proportion, uuid } = data;
  const maxPercentsVisible = useMaxPercentsVisible();
  const chartBarStyle = { height: (proportion / maxProportion * MAX_BAR_HEIGHT) + 'px' };
  const currentBarStyle = { height: ((universalScore - 1) / 4 * 100) + '%' };

  return (
    <div className={'grouping-element-bar-chart' + (isBreak ? ' last-in-row' : '')} data-cy={data.systemReference}>
      {!!isFirst && <GroupingElementBeforeBarChart/>}
      <div className="chart-bar" style={chartBarStyle} title={roundDecimal(proportion, 100) + '%'}
           onClick={() => onClick(uuid)}>
        <div className="maximum-text" style={{ opacity: maxPercentsVisible ? 1 : 0 }}>
          {roundDecimal(proportion, 100) + '%'}
        </div>
        <div className="bar-inner">
          <div className="current-bar" style={currentBarStyle} title={roundDecimal(universalScore, 100)}>
            {universalScore &&
              <div className="current-text">{roundDecimal(universalScore, 100)}</div>
            }
          </div>
        </div>
      </div>
      <div className="chart-footer">
        <div className="title-text">{title}</div>
      </div>
    </div>
  );
};
