import { v4 as getUUID } from "uuid";
import { GROUPING_TYPE_CATEGORY } from "../constants/sustainabilitySystem";

export class GroupingElement {
  UID;
  type;
  children = [];
  elementAwardThresholds = {};

  constructor(obj = {}) {
    this.UID = getUUID();
    this.type = obj.type || GROUPING_TYPE_CATEGORY;
    this.children = [...obj.children || []];
  }

  setElementAwardThresholds(elementAwardThresholds) {
    this.elementAwardThresholds = elementAwardThresholds;
  }
}
