import React from "react";
import "./ProgressBarDivision.scss"
import * as moment from "moment";
import { roundDecimal } from "../../../../utils";

export const ProgressBarDivision = (
  {
    date = null,
    titleValue = "",
    isCurrent = false,
    isGrowing = false,
    isSolid = false,
    percent = 0,
    milestoneSymbol = "",
    isStart
  }
) => {
  const className = "barDivisionWrapper"
    + (!isCurrent ? " barDivision-gray" : isGrowing ? " barDivision-increasing" : " barDivision-decreasing")
    + (isSolid ? "" : " barDivision-dashed");

  return (
    <div className={className} style={{ left: `calc( ${percent}% - 2px )` }}>
      <span className="title">{roundDecimal(titleValue, 100)}</span>
      <span className="year">{!!date && moment(date).format("YYYY")}</span>
      {isStart && <span className="milestone">{milestoneSymbol}</span>}
    </div>
  );
};
