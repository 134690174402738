import Button from "@mui/material/Button";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PromiseSubjectState } from "react-rxjs-easy";
import { getBreadcrumbPathParts } from "../../../helpers/dashboard";
import { getKpiRatingSystemKPIChart, useKpiRatingSystemKPIChartResponse } from "../../../hooks/kpiRatingSystem";
import { useDashboardSelectedGrouping } from "../../../hooks/project";
import {
  benchmarksLoadedForKpiSubject,
  getRatingSystemKPIChart,
  useRatingSystemKPIChartResponse,
} from "../../../hooks/ratingSystem";
import { useEvent } from "../../../hooks/utils/useEvent";
import { forEachRecursive } from "../../../utils";
import { Breadcrumb } from "../../Breadcrumb/Breadcrumb";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { KPIChart } from "./KPIChart";
import "./KPITab.scss";

export const KPITab = React.memo(({ revisionNumber, ratingSystem, isKpiSystem, load, lastRevision, unfolded }) => {
  const { t } = useTranslation();
  const selectedGrouping = useDashboardSelectedGrouping();
  const ratingSystemKPIChartResponse = useRatingSystemKPIChartResponse();
  const kpiRatingSystemKPIChartResponse = useKpiRatingSystemKPIChartResponse();
  const kpiChartResponse = isKpiSystem ? kpiRatingSystemKPIChartResponse : ratingSystemKPIChartResponse;
  const data = kpiChartResponse.data || {};
  const grossFloorArea = data.grossFloorArea;
  const pathParts = getBreadcrumbPathParts(selectedGrouping, t("dashboard.tabs.kpis"));
  const chartRefs = useRef([]);
  const indexRef = useRef(null);

  const charts = useMemo(() => {
    const chartsInner = data.charts || [];

    if (!selectedGrouping) {
      return chartsInner;
    }
    const filteredCharts = [];

    forEachRecursive([selectedGrouping], (node) => {
      node?.data?.kpis?.forEach((kpi) => {
        const chart = chartsInner.find((item) => item.kpiName === kpi.name && item.kpiUnit === kpi.unit);
        if (chart) {
          filteredCharts.push(chart);
        }
      });
    });

    return filteredCharts;
  }, [selectedGrouping, data.charts]);

  useEffect(() => {
    if (ratingSystem?.id && load) {
      if (isKpiSystem) {
        getKpiRatingSystemKPIChart(ratingSystem.id, revisionNumber, lastRevision).then().catch(console.error);
      } else {
        getRatingSystemKPIChart(ratingSystem.id, revisionNumber, lastRevision).then().catch(console.error);
      }
      benchmarksLoadedForKpiSubject.next(new PromiseSubjectState());
    }
  }, [ratingSystem, load]);

  const scrollToIndex = (index) => chartRefs?.current[index]?.scrollIntoView();
  const scrollBack = useEvent(() => indexRef?.current?.scrollIntoView());

  return (
    <div className="dashboard-kpi-tab" ref={indexRef}>
      <Breadcrumb pathParts={pathParts} />
      <LoadingOverlay className="auto-height" spinner active={kpiChartResponse.loading}>
        <div className="kpi-indexes">
          {charts.map(({ kpiName, kpiUnit }, index) => (
            <Button
              key={index}
              variant="contained"
              size="small"
              className="kpi-index-item"
              onClick={() => scrollToIndex(index)}
            >
              {kpiName} {kpiUnit}
            </Button>
          ))}
        </div>
        {charts.map((item, index) => (
          <KPIChart
            forwardedRef={(element) => (chartRefs.current[index] = element)}
            key={index}
            unfolded={unfolded}
            isKpiSystem={isKpiSystem}
            data={item}
            grossFloorArea={grossFloorArea}
            ratingSystemId={ratingSystem?.id}
            scrollBack={index > 0 ? scrollBack : undefined}
          />
        ))}
      </LoadingOverlay>
    </div>
  );
});
