import { useEffect } from "react";
import { useNavigate } from "react-router";
import { hasPermissions } from "../../helpers/permission";
import { usePermissions } from "../auth";

export const usePagePermissions = (permissions) => {
  const userPermissions = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasPermissions(permissions)) {
      navigate("/");
    }
  }, [userPermissions]);
};

export const useProjectPermissions = (permissions, permission, loading) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && permissions[permission] != null && !permissions[permission]) {
      navigate("/");
    }
  }, [permissions?.projectId, permissions?.userUuid]);
};
