import { AZURE_MAPS_ADDRESS_SEARCH } from "../constants/azure";


export const azureSearchAddress = (azureSubscriptionKey, language, searchTerm) => {
    return new Promise((resolve, reject) => {
        let url = AZURE_MAPS_ADDRESS_SEARCH
            .replace('(azureSubscriptionKey)', azureSubscriptionKey)
            .replace('(language)', language)
            .replace('(userLang)', language)
            .replace('(searchTerm)', searchTerm);
        fetch(url)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });

}
