import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import jp from "jsonpath";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { v4 as getUUID } from "uuid";
import { ADVANCED_TABLE_ID } from "../../components/AdvancedTable/AdvancedTable";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { AdditionalFieldsDialog } from "../../components/RatingSystem/AdditionalFieldsDialog/AdditionalFieldsDialog";
import { StatusSelect } from "../../components/StatusSelect/StatusSelect";
import { SustainabilitySystemAwards } from "../../components/SustainabilitySystem/SustainabilitySystemAwards/SustainabilitySystemAwards";
import { SustainabilitySystemBaseData } from "../../components/SustainabilitySystem/SustainabilitySystemBaseData/SustainabilitySystemBaseData";
import { SystemSource } from "../../components/SustainabilitySystem/SustainabilitySystemFields/SystemSource";
import { SustainabilitySystemGroupingESG } from "../../components/SustainabilitySystem/SustainabilitySystemGrouping/SustainabilitySystemGroupingESG";
import { TwoOptionsToggle } from "../../components/TwoOptionsToggle/TwoOptionsToggle";
import { VersionsDialog } from "../../components/Versions/VersionsDialog/VersionsDialog";
import { SUSTAINABILITY_SYSTEM } from "../../constants/main";
import {
  PERMISSION_KPI_READ,
  PERMISSION_SUSTAINABILITY_SYSTEM_EDIT,
  PERMISSION_SUSTAINABILITY_SYSTEM_STATUS_CHANGE,
} from "../../constants/permissions";
import {
  AWARD_CALCULATION_TYPE_PERCENT,
  STATUS_IN_PROGRESS,
  STATUS_LOCKED,
  STATUS_RELEASED,
  SYSTEM_LOGIC_STANDARD,
} from "../../constants/sustainabilitySystem";
import { mergeSustainabilitySystem } from "../../helpers/merge/sustainabilitySystem";
import { hasAnyPermissions, hasPermission } from "../../helpers/permission";
import { mapSustainabilitySystemGroupings } from "../../helpers/sustainabilitySystem";
import { useTableScrollX, useTableScrollY } from "../../hooks/advancedTable";
import { usePostAwardFileResponse } from "../../hooks/awardFile";
import { showMuiDialog } from "../../hooks/dialog";
import { useEditSafeExit } from "../../hooks/editing";
import { listKPI } from "../../hooks/kpi";
import {
  getSustainabilitySystem,
  getSustainabilitySystemRevisionById,
  getSustainabilitySystemRevisions,
  updateSustainabilitySystem,
  useSustainabilitySystemResponse,
  useSustainabilitySystemRevisionByIdResponse,
  useSustainabilitySystemRevisionsResponse,
  useSustainabilitySystemUpdateResponse,
} from "../../hooks/system";
import { showError, showInfo, showSuccess, showWarning } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { CloseSVG, ManageHistorySVG, SaveSVG } from "../../svg";
import { createChapterFileArray, mapRecursive, processMessage, scrollElementByIdTo } from "../../utils";
import {
  processAwards,
  processGroupingESG,
  validateAwards,
  validateGroupingESG,
  validationErrorMessage,
} from "../../validation/sustainabilitySystem";
import "./SustainabilitySystem.scss";

export const SustainabilitySystemESG = ({ sustainabilitySystemId }) => {
  const mode = new URLSearchParams(window.location.search).get("mode");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const editPermissions = hasAnyPermissions([
    PERMISSION_SUSTAINABILITY_SYSTEM_EDIT,
    PERMISSION_SUSTAINABILITY_SYSTEM_STATUS_CHANGE,
  ]);

  const response = useSustainabilitySystemResponse();
  const system = response.data || {};
  const [updateCounter, setUpdateCounter] = useState(0);
  const [isEdit, setIsEdit] = useState(mode === "edit" && editPermissions);
  const [submitted, setSubmitted] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [statusConfirmation, setStatusConfirmation] = useState(false);
  const [autoEdit, setAutoEdit] = useState(false);
  const [showAwards, setShowAwards] = React.useState(false);
  const [overallMaxSystemScore, setOverallMaxSystemScore] = useState(0);
  const [editIsDisabled, setEditIsDisabled] = useState(false);
  const [versionDialog, setVersionDialog] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [awardCalculationType, setAwardCalculationType] = useState(AWARD_CALCULATION_TYPE_PERCENT);
  const [systemLogic, setSystemLogic] = useState(null);
  const [saveMerged, setSaveMerged] = useState(false);
  const versions = useSustainabilitySystemRevisionsResponse();
  const versionById = useSustainabilitySystemRevisionByIdResponse();

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      name: "",
      systemSource: "",
      systemVersion: "",
      projectTypes: [],
      description: "",
      status: "",
      systemLogic: "",
    },
  });

  const [awards, setAwards] = useState([]);
  const postAwardFileResponse = usePostAwardFileResponse();

  const [grouping, setGrouping] = useState([]);
  const updateResponse = useSustainabilitySystemUpdateResponse();
  useEditSafeExit(isEdit);

  useEffect(() => {
    if (sustainabilitySystemId) {
      if (mode === "edit" && editPermissions) {
        window.history.replaceState(null, null, window.location.pathname);
      }
      load();
      getVersions();
    }
  }, [sustainabilitySystemId]);

  const sortedVersions = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return [...data].sort((a, b) => b.revisionNumber - a.revisionNumber);
  };

  const versionHandler = (revisionNumber, isCurrent) => {
    getSustainabilitySystemRevisionById(sustainabilitySystemId, revisionNumber)
      .then((data) => {
        resetData(data);
        setSelectedVersion(revisionNumber);
        setEditIsDisabled(!isCurrent);
      })
      .catch(() => {
        showError(t("error.500"));
      });
    setVersionDialog(false);
  };

  useEffect(() => {
    hasPermission(PERMISSION_KPI_READ) && listKPI();
  }, []);

  useEffect(() => {
    if (saveMerged) {
      try {
        setSaveMerged(false);
        onSubmit(getValues());
      } catch (e) {
        showError(t("error.500"));
      }
    }
  }, [saveMerged]);

  useEffect(() => {
    if (!isEdit && !submitted) {
      const data = system || {};
      resetData(data);
    }
  }, [isEdit, submitted]);

  const mergeAndSave = async (updated) => {
    try {
      const current = await getSustainabilitySystem(sustainabilitySystemId);
      const [merged, withConflicts] = mergeSustainabilitySystem(system, current, updated);
      merged.groupingElements = processGroupingESG(merged.children);
      if (withConflicts) {
        showWarning(processMessage(t("main.savedWithConflicts"), [current?.modifier]));
      }
      resetData(merged);
      setSaveMerged(true);
    } catch (e) {
      showError(t("error.500"));
    }
  };

  const load = () => {
    getSustainabilitySystem(sustainabilitySystemId)
      .then((data) => resetData(data))
      .catch(() => {
        showError(t("sustainabilitySystem.error.load"));
        navigate("/sustainabilitySystem");
      });
  };

  const getVersions = () => {
    getSustainabilitySystemRevisions(sustainabilitySystemId)
      .then((data) => {
        setSelectedVersion(data[data.length - 1]?.revisionNumber || null);
      })
      .catch(() => {
        showError(t("error.500"));
      });
  };

  const submitForm = () => onSubmit(getValues());

  const handleValidationError = () => {
    let awardsShown = showAwards;
    if (!showAwards && !groupingErrors.length) {
      awardsShown = true;
      setShowAwards(awardsShown);
    }
    if (showAwards && !awardErrors.length) {
      awardsShown = false;
      setShowAwards(awardsShown);
    }
    let message;
    if (!awardsShown) {
      message = validationErrorMessage(groupingErrors, t);
    } else {
      message = validationErrorMessage(awardErrors, t);
    }
    if (message) {
      setErrorMessage(message);
    }
  };

  const onSubmit = async (values) => {
    let updated;
    try {
      setFormValues(values);
      setSubmitted(true);
      setErrorMessage(null);
      if ((!groupingErrors.length && !awardErrors.length) || values.status !== STATUS_RELEASED) {
        updated = {
          ...values,
          awardCalculationType: awardCalculationType,
          overallMaxSystemScore: overallMaxSystemScore || 0,
          groupingElements: processGroupingESG(grouping),
          awards: processAwards(awards),
          systemLogic: systemLogic || SYSTEM_LOGIC_STANDARD,
          modifiedDateTime: system?.modifiedDateTime,
          projectDescriptionChapter: system?.projectDescriptionChapter,
        };
        const data = await updateSustainabilitySystem(sustainabilitySystemId, updated);
        setSubmitted(false);
        resetData(data, !autoEdit);
        getVersions();
        showSuccess(t("sustainabilitySystem.updatedMessage"));
      } else {
        handleValidationError();
      }
    } catch (error) {
      if (error.status === 409) {
        error.json().then((parsedBody) => {
          if (parsedBody?.message === "error.data.conflict") {
            showError(processMessage(t("sustainabilitySystem.error.409"), [values.name, values.systemVersion]));
          } else if (parsedBody?.message === "error.optimistic.lock") {
            mergeAndSave(updated);
          }
        }, console.error);
      } else {
        showError(t("error.500"));
      }
    }
  };

  const onStatusChange = (event) => {
    const systemInner = response.data || {};
    if (
      isEdit &&
      systemInner.usedInProject &&
      currentStatus === STATUS_RELEASED &&
      currentStatus !== event.target.value
    ) {
      setStatusConfirmation(true);
      setAutoEdit(true);
    } else {
      setAutoEdit(false);
    }
    if (
      isEdit &&
      systemInner.usedInProject &&
      currentStatus !== STATUS_RELEASED &&
      event.target.value === STATUS_RELEASED
    ) {
      showInfo(t("sustainabilitySystem.infoUsedInProject"));
    }
  };

  const resetData = (values, disableEditing) => {
    setAutoEdit(false);
    if (disableEditing) {
      setIsEdit(false);
    }
    const data = values || response.data || {};
    reset({
      name: data.name || "",
      systemVersion: data.systemVersion || "",
      systemSource: data.systemSource || "",
      projectTypes: Array.isArray(data.projectTypes) ? data.projectTypes : [],
      description: data.description || "",
      status: data.status || STATUS_IN_PROGRESS,
      systemLogic: data.systemLogic || SYSTEM_LOGIC_STANDARD,
      projectDescriptionChapter: data.projectDescriptionTextImageDTO || {},
    });
    const processedGroupings = mapSustainabilitySystemGroupings(data.groupingElements, data.awards);
    setAwardCalculationType(data.awardCalculationType);
    setGrouping(processedGroupings);
    setOverallMaxSystemScore(data.overallMaxSystemScore || 0);
    const processedAwards = (data.awards || []).map((node) => ({ UID: String(node.id || getUUID()), ...node }));
    sortAwards(processedAwards);
    setAwards(processedAwards);
    setSubmitted(false);
    setErrorMessage(null);
    setSystemLogic(data.systemLogic || SYSTEM_LOGIC_STANDARD);
    setUpdateCounter(updateCounter + 1);
  };

  const groupingErrors = useMemo(() => validateGroupingESG(grouping), [grouping]);
  const currentStatus = (response.data || {})?.status;
  const awardErrors = useMemo(() => validateAwards(awards), [awards]);
  const editDisabled =
    system.readOnly ||
    !isEdit ||
    currentStatus !== STATUS_IN_PROGRESS ||
    !hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_EDIT);

  useEffect(() => {
    if (submitted) {
      if (errorMessage) {
        setErrorMessage(null);
      }
      if ((!!groupingErrors.length || !!awardErrors.length) && formValues.status === STATUS_RELEASED) {
        handleValidationError();
      }
    }
  }, [submitted, groupingErrors, awardErrors, formValues]);

  const loading =
    response.loading ||
    updateResponse.loading ||
    postAwardFileResponse.loading ||
    versionById.loading ||
    versionById.loading;

  // Auto scroll back to position after saving
  const tableScrollPosition = { x: useTableScrollX(), y: useTableScrollY() };
  const [lastScrollPosition, setLastScrollPosition] = useState({ x: 0, y: 0 });
  const Y_AXIS_COMPENSATION = 1.7; // on edit mode, table gets a little taller due to the comboboxes
  useEffect(() => {
    if (submitted) {
      setLastScrollPosition({ x: tableScrollPosition.x, y: tableScrollPosition.y });
    }
  }, [submitted]);

  useEffect(() => {
    if (lastScrollPosition.y > 0 && !isEdit) {
      setLastScrollPosition({ x: lastScrollPosition.x, y: lastScrollPosition.y + Y_AXIS_COMPENSATION });
      scrollElementByIdTo(ADVANCED_TABLE_ID, lastScrollPosition);
    }
  }, [updateCounter, loading, grouping]);
  // End auto scroll back to position after saving

  const canStartEdit =
    !system.readOnly &&
    ((hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_EDIT) &&
        [STATUS_RELEASED, STATUS_LOCKED].indexOf(currentStatus) === -1) ||
      hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_STATUS_CHANGE));
  const canChangeStatus = !system.readOnly && isEdit && hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_STATUS_CHANGE);

  const memoGrouping = useMemo(() => grouping, [JSON.stringify(grouping)]);
  const memoGroupingErrors = useMemo(() => groupingErrors, [JSON.stringify(groupingErrors)]);

  const showAdditionalFieldsModal = useEvent(() => {
    createChapterFileArray("projectDescriptionChapter");
    const projectDescriptionChapter = system.projectDescriptionChapter ?? {};
    showMuiDialog((props) => (
      <AdditionalFieldsDialog
        systemId={sustainabilitySystemId}
        systemType={SUSTAINABILITY_SYSTEM}
        readOnly={editDisabled}
        data={{ projectDescriptionChapter }}
        onChange={({ projectDescriptionChapter: projectDescriptionChapterInner }) => {
          system.projectDescriptionChapter = createChapterFileArray(projectDescriptionChapterInner);
        }}
        {...props}
      />
    ));
  });

  function sortAwards(updatedAward) {
    updatedAward.sort((a, b) => a.scoreTo - b.scoreTo).sort((a, b) => a.scoreFrom - b.scoreFrom);
  }

  const onAwardChange = (updatedAward) => {
    const updatedGrouping = mapRecursive(grouping, (node) => {
      const awardIdList = updatedAward.map((award) => award?.internalId?.toLowerCase());
      const deletedIdList = jp
        .paths(node, "$.elementAwardThresholds.*")
        .map((path) => path[2])
        .filter((id) => !awardIdList.includes(id));

      if (!node?.elementAwardThresholds) {
        node.elementAwardThresholds = {};
      }

      deletedIdList.forEach((id) => {
        delete node.elementAwardThresholds[id];
      });

      awardIdList.forEach((id) => {
        if (node?.elementAwardThresholds[id] == null) {
          node.elementAwardThresholds[id] = null;
        }
      });

      return node;
    });
    sortAwards(updatedAward);
    setGrouping(updatedGrouping);
    setAwards(updatedAward);
  };

  return (
    <LoadingOverlay spinner active={loading} key={updateCounter} className="sustainability-system">
      <form autoComplete={"off"} id="sustainabilitySystemForm" className="sustainability-system-form">
        <div className="page-header">
          <div className="flex-row">
            <Breadcrumb
              pathParts={[
                { url: "/sustainabilitySystem", text: t("menu.systems.rating") },
                { text: system.systemSource },
              ]}
            />
            <div className="flex-auto text-right">
              <Button size="small" color="primary" className="right-header-button" onClick={showAdditionalFieldsModal}>
                {t("ratingSystem.introductionFields.button")}
              </Button>
              <Button
                color="primary"
                className="right-header-button"
                onClick={() => setVersionDialog(true)}
                disabled={isEdit}
              >
                <ManageHistorySVG fontSize="inherit" style={{ fill: isEdit ? "rgba(0, 0, 0, 0.26)" : "" }}/>
                {t("main.version")}
              </Button>
            </div>
          </div>
          <div className="flex-row">
            <SystemSource
              systemSource={system.systemSource}
              control={control}
              editDisabled={editDisabled}
              isEdit={isEdit}
            />
            <div>
              <StatusSelect
                control={control}
                disabled={!canChangeStatus}
                value={currentStatus}
                onChange={onStatusChange}
              />
            </div>
            <div className="flex-auto"/>
            <TwoOptionsToggle
              value={showAwards}
              onChange={setShowAwards}
              option1={{ value: false, label: t("sustainabilitySystem.tab.sustainabilitySystem") }}
              option2={{ value: true, label: t("sustainabilitySystem.tab.systemLogic") }}
            />
          </div>
        </div>

        <SustainabilitySystemBaseData control={control} editDisabled={editDisabled} isEdit={isEdit}/>

        <div hidden={!!showAwards} className="table-wrapper">
          <SustainabilitySystemGroupingESG
            key={systemLogic}
            values={memoGrouping}
            onChange={setGrouping}
            submitted={submitted}
            errors={memoGroupingErrors}
            readOnly={editDisabled}
            errorMessage={errorMessage}
            systemLogic={systemLogic}
            setOverallMaxSystemScore={setOverallMaxSystemScore}
            awards={awards}
            awardCalculationType={awardCalculationType}
          />
        </div>
        <div hidden={!showAwards} className="overflow-auto">
          <SustainabilitySystemAwards
            values={awards}
            onChange={onAwardChange}
            readOnly={editDisabled}
            submitted={submitted}
            errors={awardErrors}
            awardCalculationType={awardCalculationType}
            setAwardCalculationType={setAwardCalculationType}
            systemLogic={systemLogic}
            setSystemLogic={setSystemLogic}
          />
        </div>

        {canStartEdit && (
          <div className="fabs">
            {!isEdit && (
              <Fab type="button" color="primary" onClick={() => setIsEdit(true)} disabled={editIsDisabled}>
                <EditIcon className="fab-svg"/>
              </Fab>
            )}
            {isEdit && (
              <>
                <Fab color="secondary" onClick={() => resetData(null, true)}>
                  <CloseSVG className="fab-svg"/>
                </Fab>
                <Fab color="primary" onClick={handleSubmit(onSubmit)}>
                  <SaveSVG className="fab-svg"/>
                </Fab>
              </>
            )}
          </div>
        )}
      </form>

      <ConfirmationDialog
        open={!!statusConfirmation}
        onClose={() => {
          setStatusConfirmation(false);
          setValue("status", currentStatus);
        }}
        onConfirm={() => {
          setStatusConfirmation(false);
          submitForm();
        }}
        titleText={t("sustainabilitySystem.changeStatusConfirmationTitle")}
        bodyText={t("sustainabilitySystem.changeStatusConfirmation")}
        confirmText={t("main.save")}
        color="primary"
        showLineBreaks
      />

      <VersionsDialog
        open={versionDialog}
        onClose={() => setVersionDialog(false)}
        onAction={versionHandler}
        titleText={t("versions.titleSustainability")}
        data={sortedVersions(versions.data)}
        selectedVersion={selectedVersion}
        loading={versionById.loading}
      />
    </LoadingOverlay>
  );
};
