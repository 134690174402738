import { v4 as getUUID } from "uuid";

export class GoalElement {
  UID;
  id;
  name;
  type;
  kpiName;
  kpiUnit;
  goals;

  constructor(obj = {}) {
    this.UID = getUUID();
    this.id = obj.id;
    this.name = obj.name || '';
    this.type = obj.type || null;
    this.kpiName = obj.kpiName || null;
    this.kpiUnit = obj.kpiUnit || null;
    this.goals = obj.goals || [
      {value: '', date: null},
      {value: '', date: null}
    ]
  }
}
