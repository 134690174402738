import { PromiseSubjectState, hookFromSubject, promiseToSubject } from "react-rxjs-easy";
import { BehaviorSubject, Subject } from "rxjs";
import { MIN_PAGE_SIZE } from "../constants/main";
import { KPI_PREFIX } from "../constants/project";
import { Configuration, ProjectEndpointApi } from "../generated-api";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { azureSearchAddress } from "./azuremaps";
import { uploadFile } from "./fileUpload";
import { createTablePageLogic } from "./tablePageLogic";

const projectApi = new ProjectEndpointApi(
  new Configuration({
    basePath: window.location.origin,
    getHeaders: () => ({ Authorization: getAuthorizationHeader() }),
    responseHandler: handleUnauthorized,
  })
);

export const projectsPageSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectsPageResponse = hookFromSubject(projectsPageSubject);

const processKendoFilters = (kendoFilters, params) => {
  if (kendoFilters)
    for (const key in kendoFilters) {
      if (!params[key]) params[key] = kendoFilters[key];
    }
};

const processFilterValues = (filterValues, params) => {
  if (filterValues?.projectTypes?.length) params.projectTypes = "in:" + filterValues?.projectTypes.join(",");

  if (filterValues?.sustainabilitySystemId?.length)
    params["ratingSystems.sustainabilitySystemId"] = "in:" + filterValues?.sustainabilitySystemId.join(",");

  if (filterValues?.kpiSystemId?.length)
    params["kpiRatingSystems.kpiSystemId"] =
      "in:" + filterValues?.kpiSystemId.map((item) => item.split(KPI_PREFIX)[1]).join(",");
};

export const searchProjects = ({
  page,
  itemsPerPage,
  sort,
  name,
  ratingStatus,
  filterValues,
  kendoFilters,
  advancedFilters,
  restrictedProjects,
}) => {
  const params = { page: page || 0, itemsPerPage: itemsPerPage || MIN_PAGE_SIZE };
  if (sort && !restrictedProjects) params.sort = sort;

  if (name) params.search = (restrictedProjects ? "eq:" : "like:") + name;

  processFilterValues(filterValues, params);

  if (ratingStatus?.length) params["ratingSystems.ratingStatus"] = "in:" + ratingStatus;

  if (restrictedProjects) params.anonymizedSearch = "true";

  if (advancedFilters?.length)
    advancedFilters.forEach((item) => {
      params["custom__" + item.field.name] = "eq:" + item.value;
    });

  processKendoFilters(kendoFilters, params);

  projectsPageSubject.getValue().abort();
  return promiseToSubject(projectApi.apiProjectPageGet({ params }), projectsPageSubject);
};

export const projectRatingStatusCountSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectRatingStatusCountResponse = hookFromSubject(projectRatingStatusCountSubject);
export const getProjectRatingStatusCount = ({
  name,
  projectTypes,
  sustainabilitySystemId,
  kpiSystemId,
  advancedFilters,
  restrictedProjects,
}) => {
  const params = {};
  if (name && !restrictedProjects) {
    params.search = "like:" + name;
  }
  if (projectTypes?.length) {
    params.projectTypes = "in:" + projectTypes.join(",");
  }
  if (restrictedProjects) {
    params.anonymizedSearch = "true";
  }
  if (name && restrictedProjects) {
    params.search = "eq:" + name;
  }
  if (sustainabilitySystemId?.length) {
    params["ratingSystems.sustainabilitySystemId"] = "in:" + sustainabilitySystemId.join(",");
  }
  if (kpiSystemId?.length) {
    params["kpiRatingSystems.kpiSystemId"] = "in:" + kpiSystemId.map((item) => item.split(KPI_PREFIX)[1]).join(",");
  }
  if (advancedFilters?.length) {
    advancedFilters.forEach((item) => {
      params["custom__" + item.field.name] = "eq:" + item.value;
    });
  }
  projectRatingStatusCountSubject.getValue().abort();
  return promiseToSubject(projectApi.apiProjectRatingStatusCountGet({ params }), projectRatingStatusCountSubject);
};

export const projectSystemsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectSystemsResponse = hookFromSubject(projectSystemsSubject);
export const getProjectSystems = () =>
  promiseToSubject(projectApi.apiProjectSustainabilityKpiSystemsInProjectsGet(), projectSystemsSubject);

export const getProjectFileUrl = (projectId, id) => `/api/project/${projectId}/file/${id}`;

export const projectSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectResponse = hookFromSubject(projectSubject);
export const getProject = (id) => promiseToSubject(projectApi.apiProjectIdGet({ id }), projectSubject);

export const projectFileListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectFilesResponse = hookFromSubject(projectFileListSubject);
export const getProjectFiles = (id) =>
  promiseToSubject(projectApi.apiProjectIdFilesGet({ id }), projectFileListSubject);

export const projectFieldsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectFieldsResponse = hookFromSubject(projectFieldsSubject);
export const getProjectFields = (id) =>
  promiseToSubject(projectApi.apiProjectIdFieldsGet({ id }), projectFieldsSubject);

export const projectFieldsKPIDecisionsSubject = new BehaviorSubject([]);
export const useProjectFieldsKPIDecisions = hookFromSubject(projectFieldsKPIDecisionsSubject);
export const setProjectFieldsKPIDecisions = (value) => projectFieldsKPIDecisionsSubject.next(value || []);

export const projectKpiLinkActivateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectKpiLinkActivateResponse = hookFromSubject(projectKpiLinkActivateSubject);
export const activateProjectKpiLink = (id, projectKpiId, dataSource) =>
  promiseToSubject(
    projectApi.apiProjectIdKpiProjectKpiIdLinkActivateDataSourcePut({
      id,
      projectKpiId,
      dataSource,
    }),
    projectKpiLinkActivateSubject
  );

export const projectsMapSubject = new BehaviorSubject(new PromiseSubjectState());
export const projectsMapResponse = hookFromSubject(projectsMapSubject);
export const getProjectsMap = () => promiseToSubject(projectApi.apiProjectMapGet(), projectsMapSubject);

export const projectsUserIsMemberUserSubject = new BehaviorSubject(new PromiseSubjectState());
export const getProjectsUserIsMemberUser = () =>
  promiseToSubject(projectApi.apiProjectProjectsUserIsMemberGet(), projectsUserIsMemberUserSubject);

export const projectCreateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectCreateResponse = hookFromSubject(projectCreateSubject);
export const createProject = (request) =>
  promiseToSubject(projectApi.apiProjectPost({ request }), projectCreateSubject);

export const projectUpdateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectUpdateResponse = hookFromSubject(projectUpdateSubject);
export const updateProject = (id, request) =>
  promiseToSubject(projectApi.apiProjectPut({ requestDTO: { ...request, id } }), projectUpdateSubject);

export const projectDeleteSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectDeleteResponse = hookFromSubject(projectDeleteSubject);
export const deleteProject = (id) => promiseToSubject(projectApi.apiProjectIdDelete({ id }), projectDeleteSubject);

export const getProjectAttributeListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectAttributeListResponse = hookFromSubject(getProjectAttributeListSubject);
export const getProjectAttributeList = (projectType) =>
  promiseToSubject(projectApi.apiProjectFieldsProjectTypeGet({ projectType }), getProjectAttributeListSubject);

export const projectPictureDeleteSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectPictureDeleteResponse = hookFromSubject(projectPictureDeleteSubject);
export const deleteProjectPicture = (id) =>
  promiseToSubject(projectApi.apiProjectPictureIdDelete({ id }), projectPictureDeleteSubject);

const TEMPLATE_PROJECT_ID_IDENTIFIER = "{id}";

export const uploadProjectPictureURL = "/api/project/{id}/file/temporary";
export const uploadProjectPictureKey = "picture";
export const uploadProjectPicture = (id, file) =>
  uploadFile(file, uploadProjectPictureURL.replace(TEMPLATE_PROJECT_ID_IDENTIFIER, id), uploadProjectPictureKey);

export const projectPicturesSubject = new BehaviorSubject([]);
export const useProjectPictures = hookFromSubject(projectPicturesSubject);
export const setProjectPictures = (pictures = []) => projectPicturesSubject.next(pictures);
export const addProjectPicture = (picture) =>
  projectPicturesSubject.next([...projectPicturesSubject.getValue(), picture]);
export const removeProjectPicture = (pictureIndex) =>
  projectPicturesSubject.next(projectPicturesSubject.getValue().filter((item, index) => index !== pictureIndex));
export const removeProjectPictureById = (pictureId) =>
  projectPicturesSubject.next(projectPicturesSubject.getValue().filter((id) => id !== pictureId));

export const uploadProjectFileURL = "/api/project/{id}/file/temporary";
export const uploadProjectFileKey = "file";
export const uploadProjectFile = (id, file) =>
  uploadFile(file, uploadProjectFileURL.replace(TEMPLATE_PROJECT_ID_IDENTIFIER, id), uploadProjectFileKey);
export const deleteProjectTemporaryFile = (projectId, file) =>
  projectApi.apiProjectProjectIdFileTemporaryDelete({ ...file, projectId });
export const deleteProjectTemporaryImage = (projectId, file) =>
  projectApi.apiProjectProjectIdImageTemporaryDelete({ ...file, projectId });

export const projectFileUploadedSubject = new Subject();
export const useUploadedProjectFile = hookFromSubject(projectFileUploadedSubject);

export const projectFilesSubject = new BehaviorSubject([]);
export const useProjectFiles = hookFromSubject(projectFilesSubject);
export const setProjectFiles = (files = []) => projectFilesSubject.next(files);
export const addProjectFile = (file) => projectFilesSubject.next([...projectFilesSubject.getValue(), file]);
export const removeProjectFile = (fileIndex) =>
  projectFilesSubject.next(projectFilesSubject.getValue().filter((item, index) => index !== fileIndex));
export const removeProjectFileByFileUid = (fileUid) =>
  projectFilesSubject.next(projectFilesSubject.getValue().filter((item) => item.fileUid !== fileUid));

projectUpdateSubject.subscribe((event) => {
  if (!event.loading && !event.error) {
    projectSubject.next(event);
  }
});

export const projectAddSystemsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectAddSystemsResponse = hookFromSubject(projectAddSystemsSubject);
export const addProjectSystems = (id, systems = []) =>
  promiseToSubject(
    projectApi.apiProjectIdRatingSustainabilitySystemIdsPut({
      id,
      sustainabilitySystemIds: systems.join(","),
    }),
    projectAddSystemsSubject
  );

projectAddSystemsSubject.subscribe((event) => {
  if (!event.loading && !event.error) {
    projectSubject.next(event);
  }
});

export const projectAddKpiSystemsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectAddKpiSystemsResponse = hookFromSubject(projectAddKpiSystemsSubject);
export const addProjectKpiSystems = (id, systems = []) =>
  promiseToSubject(
    projectApi.apiProjectIdKpiKpiSystemIdsPut({
      id,
      kpiSystemIds: systems.join(","),
    }),
    projectAddKpiSystemsSubject
  );

projectAddKpiSystemsSubject.subscribe((event) => {
  if (!event.loading && !event.error) {
    projectSubject.next(event);
  }
});

export const projectDeleteSystemSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectDeleteSystemResponse = hookFromSubject(projectDeleteSystemSubject);
export const deleteProjectSystem = (projectId, ratingSystemId) =>
  promiseToSubject(
    projectApi.apiProjectProjectIdRatingRatingSystemIdDelete({
      projectId,
      ratingSystemId,
    }),
    projectDeleteSystemSubject
  );

export const projectDeleteKpiSystemSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectDeleteKpiSystemResponse = hookFromSubject(projectDeleteKpiSystemSubject);
export const deleteProjectKpiSystem = (projectId, kpiRatingSystemId) =>
  promiseToSubject(
    projectApi.apiProjectProjectIdKpiKpiRatingSystemIdDelete({
      projectId,
      kpiRatingSystemId,
    }),
    projectDeleteKpiSystemSubject
  );

export const projectUserPermissionsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectUserPermissionsResponse = hookFromSubject(projectUserPermissionsSubject);
export const getProjectUserPermissions = (id) =>
  promiseToSubject(projectApi.apiProjectIdPermissionsUserGet({ id }), projectUserPermissionsSubject);

export const projectAvailableUsersSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectAvailableUsersResponse = hookFromSubject(projectAvailableUsersSubject);
export const getProjectAvailableUsers = () =>
  promiseToSubject(projectApi.apiProjectUsersAvailableGet(), projectAvailableUsersSubject);

export const projectPermissionsGetSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectPermissionsGetResponse = hookFromSubject(projectPermissionsGetSubject);
export const getProjectPermissions = (id) =>
  promiseToSubject(projectApi.apiProjectIdPermissionsGet({ id }), projectPermissionsGetSubject);

export const projectPermissionsPutSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectPermissionsPutResponse = hookFromSubject(projectPermissionsPutSubject);
export const updateProjectPermissions = (id, request) =>
  promiseToSubject(projectApi.apiProjectIdPermissionsPut({ id, request }), projectPermissionsPutSubject);

export const projectProtectChangeSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectProtectChangeResponse = hookFromSubject(projectProtectChangeSubject);
export const protectProject = (id) =>
  promiseToSubject(projectApi.apiProjectIdProtectPut({ id }), projectProtectChangeSubject);
export const unprotectProject = (id) =>
  promiseToSubject(projectApi.apiProjectIdUnprotectPut({ id }), projectProtectChangeSubject);

export const projectDashboardSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectDashboardResponse = hookFromSubject(projectDashboardSubject);
export const getProjectDashboard = (id) =>
  promiseToSubject(projectApi.apiProjectIdDashboardGet({ id }), projectDashboardSubject);

export const dashboardSelectedGroupingSubject = new BehaviorSubject(null);
export const useDashboardSelectedGrouping = hookFromSubject(dashboardSelectedGroupingSubject);
export const setDashboardSelectedGrouping = (value) => dashboardSelectedGroupingSubject.next(value);

export const azureAddressSearchSubject = new BehaviorSubject(new PromiseSubjectState());
export const azureAddressSearchResponse = hookFromSubject(azureAddressSearchSubject);
export const azureAddressSearch = (azureSubscriptionKey, language, searchTerm) =>
  promiseToSubject(azureSearchAddress(azureSubscriptionKey, language, searchTerm), azureAddressSearchSubject);

export const projectFilterFieldsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectFilterFieldsResponse = hookFromSubject(projectFilterFieldsSubject);
export const getProjectFilterFields = () =>
  promiseToSubject(projectApi.apiProjectFilterFieldsGet(), projectFilterFieldsSubject);

export const [useProjectsPageLogic, resetProjectsPageState] = createTablePageLogic();

export const sinceLastVisitCountSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSinceLastVisitCountSubjectResponse = hookFromSubject(sinceLastVisitCountSubject);
export const getSinceLastVisitCount = (date) =>
  promiseToSubject(projectApi.apiProjectSinceLastVisitCountGet({ date }), sinceLastVisitCountSubject);

export const projectGroupProjectsPageSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupProjectsPageResponse = hookFromSubject(projectGroupProjectsPageSubject);
export const searchProjectGroupProjects = ({ page, itemsPerPage, groupId, search }) => {
  const params = { page: page || 0, itemsPerPage: itemsPerPage || MIN_PAGE_SIZE };
  if (groupId) {
    params.groupId = "IN:" + groupId;
  }
  if (search) {
    params.search = "like:" + search;
  }
  return promiseToSubject(projectApi.apiProjectPageGet({ params }), projectGroupProjectsPageSubject);
};

export const [useProjectGroupProjectsLogic, resetProjectGroupsProjectsState] = createTablePageLogic();

export const projectCopySubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectCopySubjectResponse = hookFromSubject(projectCopySubject);
export const copyProjectPost = (request) =>
  promiseToSubject(
    projectApi.apiProjectCopyProjectIdFromTenantSourceTenantIdToTargetTenantIdPost({ ...request }),
    projectCopySubject
  );

export const projectCopyGenerateTimelineSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectCopyGenerateTImelineSubjectResponse = hookFromSubject(projectCopyGenerateTimelineSubject);
export const copyProjectGenerateTimelinePost = (request) =>
  promiseToSubject(
    projectApi.apiProjectCopyGenerateTimelineTenantTenantIdProjectProjectIdPost(
      { ...request },
      { returnResponse: true }
    ),
    projectCopySubject
  );

export const generateCriteriumReport = (ratingSystemId, elementUuid, language) =>
  projectApi.apiProjectCriteriumReportRatingSystemIdGet({ ratingSystemId, elementUuid, language });
