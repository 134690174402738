import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Button from "@mui/material/Button";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SCORE_TYPES } from "../../../constants/ratingSystem";
import "./GoalCard.scss";
import { ProgressBarDivision } from "./ProgressBarDivision/ProgressBarDivision";

const getDateYear = (dateString) => {
  return moment(dateString).year();
};

export const GoalCard = ({
  onSetDeleteConfirmation,
  onSetShowDialog,
  readOnly,
  showDialog,
  card,
  kpis,
  score,
  superScore,
}) => {
  const { name, type, goals, kpiName, UID } = card || {};
  const { t } = useTranslation();
  const [goalsWithPercent, setGoalsWithPercent] = useState([]);
  const [currentRatingPercent, setCurrentRatingPercent] = useState(0);
  const [isGrowing, setIsGrowing] = useState(true);
  const currentYear = moment().year();
  let kpi;
  if (type === SCORE_TYPES.KPI_TYPE) {
    kpi = kpis.find((item) => item.name === kpiName);
  }
  const emptyKpi = !!kpi && kpi.currentValue === null;
  const currentRating =
    type === SCORE_TYPES.SYSTEM_SCORE_TYPE
      ? score
      : type === SCORE_TYPES.SUPER_SCORE_TYPE
      ? superScore
      : kpi
      ? kpi.currentValue || 0
      : 0;

  useEffect(() => {
    let currentGoal;
    goals.forEach((item) => {
      item.year = getDateYear(item.date);
      if (!currentGoal || item.year <= currentYear) {
        currentGoal = item;
      }
    });
    const currentGoalValue = currentGoal?.value || 0;
    const firstGoalValue = +(goals[0] || {}).value || 0;
    const lastGoalValue = +(goals[goals.length - 1] || {}).value || 0;
    const delta = lastGoalValue - firstGoalValue;
    const processedGoals = [];
    const largeValue = delta > 0 ? lastGoalValue : firstGoalValue;
    const smallValue = delta > 0 ? firstGoalValue : lastGoalValue;
    goals.forEach((item) => {
      if (item.value >= smallValue && item.value <= largeValue) {
        processedGoals.push({
          ...item,
          percent: ((item.value - firstGoalValue) * 100) / delta,
          isFuture: item.year > currentYear,
        });
      }
    });
    setGoalsWithPercent(processedGoals);
    const processedRatingPercent = ((currentRating - firstGoalValue) * 100) / delta;
    setCurrentRatingPercent(emptyKpi ? 0 : processedRatingPercent);
    setIsGrowing(delta > 1 ? currentRating >= currentGoalValue : currentRating <= currentGoalValue);
  }, [showDialog, card, kpis]);

  const milestoneSymbol =
    type === SCORE_TYPES.SYSTEM_SCORE_TYPE
      ? t("ratingSystem.points")
      : type === SCORE_TYPES.SUPER_SCORE_TYPE
      ? "%"
      : kpi
      ? kpi.unit
      : "";

  return (
    <div className="goalCard">
      <div className="inner">
        <p className="name">{name}</p>
        {type !== SCORE_TYPES.KPI_TYPE && (
          <p className="goalType">
            {t("action.type")}: {t("ratingSystem.goalType." + type)}
          </p>
        )}
        {!!kpi && (
          <div className="flex-row">
            <p className="goalType text-left flex-auto">
              {t("ratingSystem.goalType.label")}: {t("ratingSystem.goalType." + type)} {kpi.name}
            </p>
            {!!kpi.indicatorName && (
              <p className="goalType text-right flex-auto">
                {t("grouping.indicator")}: {kpi.indicatorName}
              </p>
            )}
          </div>
        )}
        <div className="chartContainer">
          <div className="background">
            <div className="progressBar" style={{ width: `${currentRatingPercent}%` }} />
            {goalsWithPercent.map((item, index) => (
              <ProgressBarDivision
                key={index}
                percent={item.percent}
                titleValue={item.value}
                date={item.date}
                isSolid={!item.isFuture}
                milestoneSymbol={milestoneSymbol}
                isStart={index === 0}
              />
            ))}
            {!(goals || []).some((item) => item.value === currentRating) &&
              !!currentRatingPercent &&
              currentRatingPercent <= 100 &&
              currentRatingPercent >= 0 && (
                <ProgressBarDivision
                  key="current"
                  percent={currentRatingPercent}
                  isSolid
                  isCurrent
                  isGrowing={isGrowing}
                  titleValue={currentRating}
                />
              )}
          </div>
        </div>
        {!readOnly && (
          <div className="buttons">
            <Button
              className="edit-btn"
              onClick={() => {
                onSetShowDialog({ ...card });
              }}
            >
              <CreateOutlinedIcon /> {t("award.edit")}
            </Button>
            <Button className="delete-btn delete-goal" onClick={() => onSetDeleteConfirmation(UID)}>
              <DeleteOutlinedIcon /> {t("award.delete")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
