import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { PromiseSubjectState } from "react-rxjs-easy";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { DashboardRatingSystems } from "../../components/Dashboard/DashboardRatingSystems/DashboardRatingSystems";
import { DashboardTabs } from "../../components/Dashboard/DashboardTabs/DashboardTabs";
import { DashboardTimeline } from "../../components/Dashboard/DashboardTimeline/DashboardTimeline";
import { SunBurstChartKPI } from "../../components/Dashboard/SunBurstChart/SunBurstChartKPI";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { ProjectHeader } from "../../components/Project/ProjectHeader/ProjectHeader";
import {
  getKpiDashboardRevision,
  getKpiDashboardTimeline,
  kpiDashboardRevisionSubject,
  kpiDashboardTimelineSubject,
  useKpiDashboardRevisionResponse,
  useKpiDashboardTimelineResponse,
} from "../../hooks/kpiRatingSystem";
import { getProjectDashboard, setDashboardSelectedGrouping, useProjectDashboardResponse } from "../../hooks/project";
import { setBenchmarksLoadedForKpi } from "../../hooks/ratingSystem";
import { showError } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { filterRecursive, isExcludedGroupingElement, mapRecursive } from "../../utils";

export const KPIDashboardPage = () => {
  const { projectId, kpiRatingSystemId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const response = useProjectDashboardResponse();
  const timelineResponse = useKpiDashboardTimelineResponse();
  const [project, setProject] = useState({});
  const [timelineData, setTimelineData] = useState([]);
  const [ratingSystems, setRatingSystems] = useState([]);
  const [revisionNumber, setRevisionNumber] = useState(null);
  const [lastRevisionNumber, setLastRevisionNumber] = useState(null);
  const revisionResponse = useKpiDashboardRevisionResponse();
  const [revision, setRevision] = useState({});
  const [unfolded, setUnfolded] = useState(false);

  const getSystems = (data) => {
    return [...(data?.ratingSystems || []), ...(data?.kpiRatingSystems || [])];
  };

  useEffect(() => {
    getKpiDashboardTimeline(kpiRatingSystemId);
    setBenchmarksLoadedForKpi(null);
    if (project.ratingSystems || project.kpiRatingSystems) {
      setRatingSystems(getSystems(project));
    }
    return () => {
      kpiDashboardTimelineSubject.next(new PromiseSubjectState());
      kpiDashboardRevisionSubject.next(new PromiseSubjectState());
    };
  }, [kpiRatingSystemId]);

  useEffect(() => {
    if (!timelineResponse.loading && !!timelineResponse.data) {
      const data = timelineResponse.data || [];
      setTimelineData(data);
      const sorted = data.sort((a, b) => {
        if (a.dateTime > b.dateTime) {
          return 1;
        }
        if (a.dateTime < b.dateTime) {
          return -1;
        }
        return 0;
      });
      const lastRevisionNumberInner = (sorted[sorted.length - 1] || {}).revisionNumber;
      const revisionNumberInner = (sorted[sorted.length - 1] || {}).revisionNumber;
      setRevisionNumber(revisionNumberInner);
      setLastRevisionNumber(lastRevisionNumberInner);
      if (revisionNumberInner) {
        getKpiDashboardRevision(
          kpiRatingSystemId,
          revisionNumberInner,
          revisionNumberInner === lastRevisionNumberInner
        );
      }
    }
  }, [timelineResponse.loading]);

  useEffect(() => {
    if (!revisionResponse.loading) {
      const data = revisionResponse.data || {};
      data.groupingElements = filterRecursive(
        data.groupingElements,
        (node) => {
          if (!isExcludedGroupingElement(node)) {
            if (node.kpiElements && node.kpiElements.length) {
              node.kpiElements = node.kpiElements.filter((item) => !item.excluded);
            }
            return !!(node?.groupingElements?.length || node?.kpiElements?.length);
          } else {
            return false;
          }
        },
        "groupingElements"
      );
      data.groupingElements = mapRecursive(
        data.groupingElements,
        (node, children) => {
          return { ...node, children: [...children, ...node.kpiElements] };
        },
        "groupingElements"
      );
      setRevision(data);
    }
  }, [revisionResponse.loading]);

  useEffect(() => {
    if (projectId) {
      setDashboardSelectedGrouping(null);
      if (response.data && String(response.data.id) === projectId) {
        resetData();
      }
      load();
    }
  }, [projectId]);

  const load = () => {
    getProjectDashboard(projectId)
      .then(resetData)
      .catch(() => {
        showError(t("project.error.load"));
        navigate("/project");
      });
  };

  const resetData = (values) => {
    const data = values || response.data || {};
    setProject(data);
    if (!data.kpiRatingSystems.length) {
      navigate("/project");
    } else {
      setRatingSystems(getSystems(data));
    }
  };

  const onSetRevisionNumber = (revisionNumberInner) => {
    setRevisionNumber(revisionNumberInner);
    if (revisionNumberInner) {
      const timelineItem = timelineData.find((item) => item.revisionNumber === revisionNumberInner);
      if (timelineItem) {
        setRatingSystems(
          getSystems(project).map((item) => {
            if (String(item.id) === kpiRatingSystemId) {
              return { ...item, ratingStatus: timelineItem.status, selectedAward: timelineItem.selectedAward || null };
            } else {
              return { ...item };
            }
          })
        );
        getKpiDashboardRevision(kpiRatingSystemId, revisionNumberInner, revisionNumberInner === lastRevisionNumber);
      }
    }
  };

  const onSystemChange = useEvent((id, isKpi) => {
    if (isKpi) {
      navigate("/project/" + projectId + "/kpiDashboard/" + id);
    } else {
      navigate("/project/" + projectId + "/dashboard/" + id);
    }
  });

  return (
    <LoadingOverlay spinner active={response.loading || revisionResponse.loading} className="dashboard-page">
      <div className="page-header flex-row">
        <Breadcrumb
          pathParts={[
            { url: "/project", text: t("menu.project.title") },
            { url: "/project/" + projectId, text: project.name },
            { text: t("dashboard.title") },
          ]}
        />
        <div className="flex-auto" />
      </div>

      <div className="page-layout" hidden={!project.id}>
        <ProjectHeader project={project} />
        <DashboardRatingSystems
          ratingSystems={ratingSystems}
          systemId={kpiRatingSystemId}
          onSystemChange={onSystemChange}
          isKpiSystem
        />

        <DashboardTimeline
          data={timelineData}
          setRevisionNumber={onSetRevisionNumber}
          revisionNumber={revisionNumber}
        />
        <div className="flex-row">
          {!unfolded && <SunBurstChartKPI data={revision} />}
          <div className="flex-auto overflow-y-auto">
            <DashboardTabs
              kpiTabs
              data={revision}
              revisionNumber={revisionNumber}
              lastRevision={lastRevisionNumber}
              unfolded={unfolded}
              setUnfolded={setUnfolded}
            />
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};
