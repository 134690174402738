import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ADVANCED_TABLE_ID } from "../../components/AdvancedTable/AdvancedTable";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { AdditionalFieldsDialog } from "../../components/RatingSystem/AdditionalFieldsDialog/AdditionalFieldsDialog";
import { StatusSelect } from "../../components/StatusSelect/StatusSelect";
import { SustainabilitySystemBaseData } from "../../components/SustainabilitySystem/SustainabilitySystemBaseData/SustainabilitySystemBaseData";
import { SystemSource } from "../../components/SustainabilitySystem/SustainabilitySystemFields/SystemSource";
import { SustainabilitySystemGroupingPAST } from "../../components/SustainabilitySystem/SustainabilitySystemGrouping/SustainabilitySystemGroupingPAST";
import { VersionsDialog } from "../../components/Versions/VersionsDialog/VersionsDialog";
import { SUSTAINABILITY_SYSTEM } from "../../constants/main";
import {
  PERMISSION_SUSTAINABILITY_SYSTEM_EDIT,
  PERMISSION_SUSTAINABILITY_SYSTEM_STATUS_CHANGE,
} from "../../constants/permissions";
import { STATUS_IN_PROGRESS, STATUS_LOCKED, STATUS_RELEASED } from "../../constants/sustainabilitySystem";
import { mergeSustainabilitySystem } from "../../helpers/merge/sustainabilitySystem";
import { hasAnyPermissions, hasPermission } from "../../helpers/permission";
import { mapSustainabilitySystemGroupings } from "../../helpers/sustainabilitySystem";
import { useTableScrollX, useTableScrollY } from "../../hooks/advancedTable";
import { showMuiDialog } from "../../hooks/dialog";
import { useEditSafeExit } from "../../hooks/editing";
import {
  getSustainabilitySystem,
  getSustainabilitySystemRevisionById,
  getSustainabilitySystemRevisions,
  updateSustainabilitySystem,
  useSustainabilitySystemResponse,
  useSustainabilitySystemRevisionByIdResponse,
  useSustainabilitySystemRevisionsResponse,
  useSustainabilitySystemUpdateResponse,
} from "../../hooks/system";
import { showError, showInfo, showSuccess, showWarning } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { CloseSVG, ManageHistorySVG, SaveSVG } from "../../svg";
import { createChapterFileArray, processMessage, scrollElementByIdTo } from "../../utils";
import {
  processGroupingPAST,
  validateGroupingPAST,
  validationErrorMessage,
} from "../../validation/sustainabilitySystem";
import "./SustainabilitySystem.scss";

export const SustainabilitySystemPAST = ({ sustainabilitySystemId }) => {
  const mode = new URLSearchParams(window.location.search).get("mode");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const editPermissions = hasAnyPermissions([
    PERMISSION_SUSTAINABILITY_SYSTEM_EDIT,
    PERMISSION_SUSTAINABILITY_SYSTEM_STATUS_CHANGE,
  ]);

  const response = useSustainabilitySystemResponse();
  const system = response.data || {};
  const [updateCounter, setUpdateCounter] = useState(0);
  const [isEdit, setIsEdit] = useState(mode === "edit" && editPermissions);
  const [submitted, setSubmitted] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [statusConfirmation, setStatusConfirmation] = useState(false);
  const [autoEdit, setAutoEdit] = useState(false);
  const [overallMaxSystemScore, setOverallMaxSystemScore] = useState(0);
  const [editIsDisabled, setEditIsDisabled] = useState(false);
  const [versionDialog, setVersionDialog] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [saveMerged, setSaveMerged] = useState(false);
  const versions = useSustainabilitySystemRevisionsResponse();
  const versionById = useSustainabilitySystemRevisionByIdResponse();

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: { name: "", systemSource: "", systemVersion: "", projectTypes: [], description: "", status: "" },
  });

  const [grouping, setGrouping] = useState([]);
  const updateResponse = useSustainabilitySystemUpdateResponse();

  useEditSafeExit(isEdit);

  useEffect(() => {
    if (sustainabilitySystemId) {
      if (mode === "edit" && editPermissions) {
        window.history.replaceState(null, null, window.location.pathname);
      }
      load();
      getVersions();
    }
  }, [sustainabilitySystemId]);

  const sortedVersions = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return [...data].sort((a, b) => b.revisionNumber - a.revisionNumber);
  };

  const versionHandler = (revisionNumber, isCurrent) => {
    getSustainabilitySystemRevisionById(sustainabilitySystemId, revisionNumber)
      .then((data) => {
        resetData(data);
        setSelectedVersion(revisionNumber);
        setEditIsDisabled(!isCurrent);
      })
      .catch(() => {
        showError(t("error.500"));
      });
    setVersionDialog(false);
  };

  useEffect(() => {
    if (saveMerged) {
      try {
        setSaveMerged(false);
        onSubmit(getValues());
      } catch (e) {
        showError(t("error.500"));
      }
    }
  }, [saveMerged]);

  useEffect(() => {
    if (!isEdit && !submitted) {
      const data = system || {};
      resetData(data);
    }
  }, [isEdit, submitted]);

  const mergeAndSave = async (updated) => {
    try {
      const current = await getSustainabilitySystem(sustainabilitySystemId);
      const [merged, withConflicts] = mergeSustainabilitySystem(system, current, updated);
      merged.groupingElements = processGroupingPAST(merged.children);
      if (withConflicts) {
        showWarning(processMessage(t("main.savedWithConflicts"), [current?.modifier]));
      }
      resetData(merged);
      setSaveMerged(true);
    } catch (e) {
      showError(t("error.500"));
    }
  };

  const load = () => {
    getSustainabilitySystem(sustainabilitySystemId)
      .then((data) => resetData(data))
      .catch((e) => {
        console.error(e);
        showError(t("sustainabilitySystem.error.load"));
        navigate("/sustainabilitySystem");
      });
  };

  const getVersions = () => {
    getSustainabilitySystemRevisions(sustainabilitySystemId)
      .then((data) => {
        setSelectedVersion(data[data.length - 1]?.revisionNumber || null);
      })
      .catch(() => {
        showError(t("error.500"));
      });
  };

  const submitForm = () => onSubmit(getValues());

  const onSubmit = async (values) => {
    let updated;
    try {
      setFormValues(values);
      setSubmitted(true);
      setErrorMessage(null);
      if (!groupingErrors.length || values.status !== STATUS_RELEASED) {
        updated = {
          ...values,
          overallMaxSystemScore: overallMaxSystemScore || 0,
          groupingElements: processGroupingPAST(grouping),
          modifiedDateTime: system?.modifiedDateTime,
          methodologyChapter: system?.methodologyChapter,
        };
        const data = await updateSustainabilitySystem(sustainabilitySystemId, updated);
        setSubmitted(false);
        resetData(data, !autoEdit);
        getVersions();
        showSuccess(t("sustainabilitySystem.updatedMessage"));
      } else {
        setErrorMessage(validationErrorMessage(groupingErrors, t));
      }
    } catch (error) {
      if (error.status === 409) {
        error.json().then((parsedBody) => {
          if (parsedBody?.message === "error.data.conflict") {
            showError(processMessage(t("sustainabilitySystem.error.409"), [values.name, values.systemVersion]));
          } else if (parsedBody?.message === "error.optimistic.lock") {
            mergeAndSave(updated);
          }
        }, console.error);
      } else {
        showError(t("error.500"));
      }
    }
  };

  const onStatusChange = (event) => {
    const systemInner = response.data || {};
    if (
      isEdit &&
      systemInner.usedInProject &&
      currentStatus === STATUS_RELEASED &&
      currentStatus !== event.target.value
    ) {
      setStatusConfirmation(true);
      setAutoEdit(true);
    } else {
      setAutoEdit(false);
    }
    if (
      isEdit &&
      systemInner.usedInProject &&
      currentStatus !== STATUS_RELEASED &&
      event.target.value === STATUS_RELEASED
    ) {
      showInfo(t("sustainabilitySystem.infoUsedInProject"));
    }
  };

  const resetData = (values, disableEditing) => {
    setAutoEdit(false);
    if (disableEditing) {
      setIsEdit(false);
    }
    const data = values || response.data || {};
    reset({
      name: data.name || "",
      systemVersion: data.systemVersion || "",
      systemSource: data.systemSource || "",
      projectTypes: Array.isArray(data.projectTypes) ? data.projectTypes : [],
      description: data.description || "",
      status: data.status || STATUS_IN_PROGRESS,
    });
    const processedGroupings = mapSustainabilitySystemGroupings(data.groupingElements);
    setGrouping(processedGroupings);
    setOverallMaxSystemScore(data.overallMaxSystemScore || 0);
    setSubmitted(false);
    setErrorMessage(null);
    setUpdateCounter(updateCounter + 1);
  };

  const groupingErrors = useMemo(() => validateGroupingPAST(grouping), [grouping]);

  useEffect(() => {
    if (submitted) {
      if (errorMessage) {
        setErrorMessage(null);
      }
      if (!!groupingErrors.length && formValues.status === STATUS_RELEASED) {
        setErrorMessage(validationErrorMessage(groupingErrors, t));
      }
    }
  }, [submitted, groupingErrors, formValues]);

  const currentStatus = (response.data || {}).status;
  const editDisabled =
    system.readOnly ||
    !isEdit ||
    currentStatus !== STATUS_IN_PROGRESS ||
    !hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_EDIT);

  const showAdditionalFieldsModal = useEvent(() => {
    createChapterFileArray("projectDescriptionChapter");
    const methodologyChapter = system.methodologyChapter ?? {};
    showMuiDialog((props) => (
      <AdditionalFieldsDialog
        systemId={sustainabilitySystemId}
        systemType={SUSTAINABILITY_SYSTEM}
        readOnly={editDisabled}
        data={{ methodologyChapter }}
        onChange={({ methodologyChapter: methodologyChapterInner }) => {
          system.methodologyChapter = createChapterFileArray(methodologyChapterInner);
        }}
        {...props}
      />
    ));
  });

  const loading = response.loading || updateResponse.loading || versionById.loading || versionById.loading;

  const canStartEdit =
    !system.readOnly &&
    ((hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_EDIT) &&
      [STATUS_RELEASED, STATUS_LOCKED].indexOf(currentStatus) === -1) ||
      hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_STATUS_CHANGE));
  const canChangeStatus = !system.readOnly && isEdit && hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_STATUS_CHANGE);

  // Auto scroll back to position after saving
  const tableScrollPosition = { x: useTableScrollX(), y: useTableScrollY() };
  const [lastScrollPosition, setLastScrollPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (submitted) {
      setLastScrollPosition({ x: tableScrollPosition.x, y: tableScrollPosition.y });
    }
  }, [submitted]);

  useEffect(() => {
    if (lastScrollPosition.y > 0) {
      scrollElementByIdTo(ADVANCED_TABLE_ID, lastScrollPosition);
    }
  }, [updateCounter, loading, grouping]);
  // End auto scroll back to position after saving

  const memoGrouping = useMemo(() => grouping, [JSON.stringify(grouping)]);
  const memoGroupingErrors = useMemo(() => groupingErrors, [JSON.stringify(groupingErrors)]);

  return (
    <LoadingOverlay spinner active={loading} key={updateCounter} className="sustainability-system">
      <form autoComplete={"off"} id="sustainabilitySystemForm" className="sustainability-system-form">
        <div className="page-header">
          <div className="flex-row">
            <Breadcrumb
              pathParts={[
                { url: "/sustainabilitySystem", text: t("menu.sustainabilitySystems.title") },
                { text: system.systemSource },
              ]}
            />
            <div className="flex-auto text-right">
              <Button size="small" color="primary" className="right-header-button" onClick={showAdditionalFieldsModal}>
                {t("ratingSystem.additionalFields.methodology")}
              </Button>
              <Button
                color="primary"
                className="right-header-button"
                onClick={() => setVersionDialog(true)}
                disabled={isEdit}
              >
                <ManageHistorySVG fontSize="inherit" style={{ fill: isEdit ? "rgba(0, 0, 0, 0.26)" : "" }} />
                {t("main.version")}
              </Button>
            </div>
          </div>
          <div className="flex-row">
            <SystemSource
              systemSource={system.systemSource}
              control={control}
              editDisabled={editDisabled}
              isEdit={isEdit}
            />
            <div>
              <StatusSelect
                control={control}
                disabled={!canChangeStatus}
                value={currentStatus}
                onChange={onStatusChange}
              />
            </div>
          </div>
        </div>

        <SustainabilitySystemBaseData control={control} editDisabled={editDisabled} isEdit={isEdit} />

        <div className="table-wrapper">
          <SustainabilitySystemGroupingPAST
            values={memoGrouping}
            onChange={setGrouping}
            readOnly={editDisabled}
            errorMessage={errorMessage}
            submitted={submitted}
            errors={memoGroupingErrors}
          />
        </div>

        {canStartEdit && (
          <div className="fabs">
            {!isEdit && (
              <Fab type="button" color="primary" onClick={() => setIsEdit(true)} disabled={editIsDisabled}>
                <EditIcon className="fab-svg" />
              </Fab>
            )}
            {isEdit && (
              <>
                <Fab color="secondary" onClick={() => resetData(null, true)}>
                  <CloseSVG className="fab-svg" />
                </Fab>
                <Fab color="primary" onClick={handleSubmit(onSubmit)}>
                  <SaveSVG className="fab-svg" />
                </Fab>
              </>
            )}
          </div>
        )}
      </form>

      <ConfirmationDialog
        open={!!statusConfirmation}
        onClose={() => {
          setStatusConfirmation(false);
          setValue("status", currentStatus);
        }}
        onConfirm={() => {
          setStatusConfirmation(false);
          submitForm();
        }}
        titleText={t("sustainabilitySystem.changeStatusConfirmationTitle")}
        bodyText={t("sustainabilitySystem.changeStatusConfirmation")}
        confirmText={t("main.save")}
        color="primary"
        showLineBreaks
      />

      <VersionsDialog
        open={versionDialog}
        onClose={() => setVersionDialog(false)}
        onAction={versionHandler}
        titleText={t("versions.titleSustainability")}
        data={sortedVersions(versions.data)}
        selectedVersion={selectedVersion}
        loading={versionById.loading}
      />
    </LoadingOverlay>
  );
};
