import React from "react";
import "./TrafficLight.scss";
import { TRAFFIC_LIGHT_GREEN, TRAFFIC_LIGHT_RED, TRAFFIC_LIGHT_YELLOW } from "../../constants/trafficLight";

export const TrafficLight = ({ value, onChange, readOnly }) => {
  return (
    <div className={'traffic-light' + (readOnly ? ' read-only' : '')}>
      <div className={'circle red' + (value === TRAFFIC_LIGHT_RED ? ' selected' : '')}
           onClick={!readOnly ? () => onChange(TRAFFIC_LIGHT_RED) : null}/>
      <div className={'circle yellow' + (value === TRAFFIC_LIGHT_YELLOW ? ' selected' : '')}
           onClick={!readOnly ? () => onChange(TRAFFIC_LIGHT_YELLOW) : null}/>
      <div className={'circle green' + (value === TRAFFIC_LIGHT_GREEN ? ' selected' : '')}
           onClick={!readOnly ? () => onChange(TRAFFIC_LIGHT_GREEN) : null}/>
    </div>
  );
};

export const TrafficLightMono = ({ value }) => {
  return (
    <div className='traffic-light' style={{pointerEvents: 'none'}}>
      <div className={`circle selected ${value ? value.toLowerCase() : ''}`} />
    </div>
  );
};
