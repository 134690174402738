import React, { useEffect, useRef } from "react";
import { mapRecursive } from "../../../utils";
import "./SunBurstChart.scss";
import { setDashboardSelectedGrouping, useDashboardSelectedGrouping } from "../../../hooks/project";
import { useTranslation } from "react-i18next";
import { generateSunburstSvgKPI } from "./sunBurstSvgKPI";

export const SunBurstChartKPI = ({ data }) => {
  const { i18n } = useTranslation();
  const chartRef = useRef(null);
  const middleRef = useRef(null);
  const selectedGrouping = useDashboardSelectedGrouping();

  const { groupingElements } = (data || {});
  const groupings = groupingElements || [];

  useEffect(() => {
    if (chartRef && chartRef.current) {
      chartRef.current.innerHTML = '';
      if (groupings && groupings.length) {
        const processed = {
          children: mapRecursive(groupings, (node, children) => {
            return {
              uuid: node.uuid,
              value: node.maxSystemScoreProportion,
              kpis: node.kpiElements?.map(item => item.kpi).filter(item => !!item),
              title: node.name,
              type: node.type,
              children
            };
          }, 'groupingElements')
        };

        chartRef.current.appendChild(generateSunburstSvgKPI({
          data: processed,
          value: selectedGrouping,
          onSelect: setDashboardSelectedGrouping,
          middleContent: middleRef.current
        }));
      }
    }
  }, [groupings, selectedGrouping, chartRef]);

  return (
    <div className="sun-burst-chart">
      <div ref={chartRef} className="chart"/>
      <div ref={middleRef} className="middle-content">
        <div className="header-text text-hyphens" lang={i18n.language}/>
        <div className="value-text"/>
        <div className="potential-text"/>
      </div>
    </div>
  );
};
