import { mergeArray, mergeArrayOfObjects, mergeObject } from "./merge";

export const mergeProject = (initial = {}, current = {}, updated = {}) => {
  let withConflicts = false;

  const fieldsToMerge = [
    'name',
    'description',
    'internalProjectId',
    'externalProjectId',
    'accessRestricted',
    'clientName',
    'condition',
    'usageType',
    'location',
    'latitude',
    'longitude',
    'buildingYear',
    'grossFloorArea',
    'netFloorArea',
    'energyReferenceArea',
    'costReferenceArea'
  ];

  const [merged, objectConflicts] = mergeObject(initial, current, updated, fieldsToMerge);
  if (objectConflicts) {
    withConflicts = true;
  }
  merged.modifiedDateTime = current.modifiedDateTime;

  const [projectTypes, projectTypesConflicts] = mergeArray(initial.projectTypes, current.projectTypes, updated.projectTypes);
  if (projectTypesConflicts) {
    withConflicts = true;
  }

  const [pictureIds, pictureIdsConflicts] = mergeArray(initial.pictureIds, current.pictureIds, updated.pictureIds);
  if (pictureIdsConflicts) {
    withConflicts = true;
  }

  const [customFields, customFieldsConflicts] = mergeObject(initial.customFields, current.customFields, updated.customFields);
  if (customFieldsConflicts) {
    withConflicts = true;
  }

  const projectFilesFieldsToMerge = [
    'name',
    'editable'
  ];

  const [projectFiles, projectFilesConflicts] = mergeArrayOfObjects(
    initial.projectFiles,
    current.projectFiles,
    updated.projectFiles,
    projectFilesFieldsToMerge,
    'id');
  if (projectFilesConflicts) {
    withConflicts = true;
  }

  return [{ ...merged, projectTypes, pictureIds, projectFiles, customFields }, withConflicts];
};
