import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { USERS_STATUSES } from "../../../constants/users";
import { handleError } from "../../../helpers/error";
import { updateUser, useUpdateResponse } from "../../../hooks/users";
import { useTimeout } from "../../../hooks/utils/useTimeout";
import { FormController } from "../../FormController/FormController";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";

export const UserDetailsDialog = ({ user, open, onClose, onChange }) => {
  const { t } = useTranslation();

  const updateResponse = useUpdateResponse();
  const [, setResponseTimeout] = useTimeout();

  const { control, getValues } = useForm({ defaultValues: { status: user.status, superAdmin: user.superAdmin } });

  const afterChange = () =>
    setResponseTimeout(
      setTimeout(() => {
        onChange();
        onClose();
      }, 0)
    );

  const onSubmit = () => {
    const values = getValues();

    updateUser(user.id, {
      email: user.email,
      firstName: user.firstName,
      language: user.language,
      lastName: user.lastName,
      pictureId: user.pictureId,
      userIsRegistered: user.userIsRegistered,
      uuid: user.uuid,
      company: user.company,
      ...values,
    })
      .then(afterChange)
      .catch(handleError);
  };

  const fullName = [user.firstName, user.lastName].join(" ");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {t("users.userDetailsHeader")}
        <IconButton className="close-button" size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingOverlay spinner active={updateResponse.loading} className="add-user-tenant-dialog auto-height">
          <FormControl fullWidth>
            <TextField fullWidth disabled label={t("users.name")} defaultValue={fullName} className="read-only" />
          </FormControl>
          <FormControl fullWidth>
            <TextField fullWidth disabled label={t("users.email")} defaultValue={user.email} className="read-only" />
          </FormControl>
          <FormController
            control={control}
            name="status"
            required
            label={t("users.status.label") + " *"}
            render={({ field, fieldState, label }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="userRoleStatusSelect">{label}</InputLabel>
                <Select id="userRoleStatusSelect" error={fieldState.invalid} fullWidth label={label} {...field}>
                  {USERS_STATUSES.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {t("users.status." + item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <FormController
            control={control}
            name="superAdmin"
            render={({ field }) => (
              <label>
                <Checkbox color="primary" {...field} defaultChecked={user.superAdmin} />
                <span className="label">{t("users.superAdmin")}</span>
              </label>
            )}
          />
          <FormController
            control={control}
            name="company"
            required
            render={({ field, label }) => (
              <FormControl fullWidth>
                <span className="flex-auto">{t("userProfile.company")}</span>
                <TextField
                  fullWidth
                  label={label}
                  {...field}
                  defaultValue={user.company}
                  inputProps={{ maxLength: 128 }}
                />
              </FormControl>
            )}
          />
        </LoadingOverlay>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("main.cancel")}</Button>
        <Button onClick={onSubmit} color="primary">
          {t("main.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
