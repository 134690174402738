import AddLinkOutlinedIcon from "@mui/icons-material/AddLinkOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Button, Chip, IconButton } from "@mui/material";
import { Hint } from "@progress/kendo-react-labels";
import { Upload, UploadFileStatus } from "@progress/kendo-react-upload";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { LinkDialog } from "../../../components/LinkDialog/LinkDialog";
import { EvaluationReason } from "../../../components/RatingSystem/EvaluationReason/EvaluationReason";
import { isNotEmpty } from "../../../components/RichText/utils";
import { FILE_UPLOAD_RESTRICTIONS } from "../../../constants/fileUpload";
import { CURRENT_CUSTOM_TAG, CUSTOM_VALUE, INDICATOR_VALUE_TYPE_RANGE } from "../../../constants/sustainabilitySystem";
import { addBreakLines, adjustRGBFormat, removeExtraBreakLines } from "../../../helpers/richtext";
import { getProjectFileUrl } from "../../../hooks/project";
import { showError } from "../../../hooks/toast";
import { roundDecimal } from "../../../utils";
import { FloatingLabelDropdownList, FloatingLabelNumericTextBox, FloatingLabelTextBox } from "../FloatingInputs";
import { getSystemPageState } from "../NewRatingSystemESG";
import { someMap, systemScoreItemRender, systemScoreValueRender } from "../NewRatingSystemESG.util";
import { conditionIsIndicator, conditionNotIndicator } from "../NewRatingSystemESG.validation";
import { BaseBlock } from "./BaseBlock";

export const ACTUAL_BLOCK_ESG_ID = "actualBlock";

export const ACTUAL_KPI_SUB_BLOCK_ID = "actualKpiSubBlock";
export const ACTUAL_SYSTEM_SCORE_SUB_BLOCK_ID = "actualSystemScoreSubBlock";
export const ACTUAL_VALUE_SUB_BLOCK_ID = "actualValueSubBlock";
export const ACTUAL_REASON_SUB_BLOCK_ID = "actualReasonSubBlock";
export const ACTUAL_LINKS_SUB_BLOCK_ID = "actualLinksSubBlock";
export const ACTUAL_FILES_SUB_BLOCK_ID = "actualFilesSubBlock";

export const ACTUAL_LINKS_ADD_BUTTON_ID = "actualLinksAddButton";
export const ACTUAL_FILES_UPLOAD_ID = "actualFilesUpload";

const KPISubBlock = ({ dataItem, readOnly, onChange = () => null }) => {
  const { t } = useTranslation();
  if (!dataItem?.kpi || conditionNotIndicator(dataItem)) return null;

  const onKpiValueChange = (value) => {
    const kpi = { ...dataItem.kpi, currentValue: value };
    onChange([{ dataItem, field: "kpi", value: kpi }]);
  };

  const onKpiEstimationChange = (value) => {
    const kpi = { ...dataItem.kpi, estimation: value };
    onChange([{ dataItem, field: "kpi", value: kpi }]);
  };

  return (
    <div className="k-d-flex k-gap-3 k-flex-col k-px-5" id={ACTUAL_KPI_SUB_BLOCK_ID}>
      <span className="k-text-uppercase k-font-bold">{t("newRatingSystem.esg.sideMenu.actualBlock.kpi")}</span>
      <div className="k-d-flex k-gap-3">
        <FloatingLabelNumericTextBox
          label={dataItem?.kpi?.name}
          className="k-flex-1"
          suffix={dataItem?.kpi?.unit}
          readOnly={readOnly}
          name="currentValue"
          value={dataItem?.kpi?.currentValue}
          onChange={onKpiValueChange}
          autoSelect
        />
        <FloatingLabelNumericTextBox
          label={t("newRatingSystem.esg.sideMenu.actualBlock.estimated")}
          className="k-flex-1"
          suffix="%"
          readOnly={readOnly}
          value={dataItem?.kpi?.estimation}
          onChange={onKpiEstimationChange}
          autoSelect
        />
      </div>
      <Hint className="kpi-warning">
        {t("ratingSystem.indicator.kpiWarning", { name: dataItem?.kpi?.linkedGeneralAttributeName })}
      </Hint>
    </div>
  );
};

KPISubBlock.propTypes = {
  dataItem: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

const SystemScoreSubBlock = ({ dataItem, readOnly, onChange = () => null }) => {
  const { t } = useTranslation();

  const isIndicator = conditionIsIndicator(dataItem);
  const isValueRange = isIndicator && dataItem?.valueType === INDICATOR_VALUE_TYPE_RANGE;
  const isCustomValue =
    isIndicator && isValueRange && dataItem?.allowCustomValue && dataItem?.evaluationValue === CUSTOM_VALUE;

  const valueRangeList =
    isValueRange &&
    (dataItem?.valueRangeList ?? [])
      .concat(
        dataItem?.allowCustomValue && dataItem?.valueRangeList?.every((item) => item?.value !== CUSTOM_VALUE)
          ? [{ value: CUSTOM_VALUE, systemScore: "" }]
          : []
      )
      .filter((item) => !item?.custom);

  const valueRangeValue = isValueRange && valueRangeList?.find((x) => x.value === dataItem?.evaluationValue);

  const customValue =
    isValueRange &&
    dataItem?.valueRangeList?.find((item) => item?.custom && item?.customValueTag === CURRENT_CUSTOM_TAG);

  const systemScoreChange =
    !isValueRange && ((value) => onChange([{ dataItem, field: "evaluationSystemScore", value }]));

  const evaluationValueChange =
    isValueRange &&
    (({ systemScore, value }) => {
      const appendCustomValue = customValue
        ? []
        : [
            {
              dataItem,
              field: "valueRangeList",
              value: dataItem?.valueRangeList?.concat([
                { custom: true, value: "", systemScore: "", customValueTag: CURRENT_CUSTOM_TAG },
              ]),
            },
          ];

      onChange([
        ...(value !== CUSTOM_VALUE
          ? [{ dataItem, field: "evaluationSystemScore", value: systemScore }]
          : appendCustomValue),
        { dataItem, field: "evaluationValue", value },
      ]);
    });

  const customValueChange =
    isCustomValue &&
    ((value) =>
      onChange([
        {
          dataItem,
          field: "valueRangeList",
          value: dataItem?.valueRangeList?.map((item) =>
            item?.custom && item?.customValueTag === CURRENT_CUSTOM_TAG ? { ...item, value } : item
          ),
        },
      ]));

  const customSystemScoreChange =
    isCustomValue &&
    ((value) =>
      onChange([
        { dataItem, field: "evaluationSystemScore", value },
        {
          dataItem,
          field: "valueRangeList",
          value: dataItem?.valueRangeList?.map((item) =>
            item?.custom && item?.customValueTag === CURRENT_CUSTOM_TAG ? { ...item, systemScore: value } : item
          ),
        },
      ]));

  return (
    <div id={ACTUAL_SYSTEM_SCORE_SUB_BLOCK_ID}>
      {!isIndicator && (
        <div className="k-d-flex k-flex-wrap k-gap-3 k-px-5">
          <div className="k-flex-basis-1/3 k-flex-grow k-text-right k-text-uppercase">
            {t("newRatingSystem.esg.sideMenu.actualBlock.systemScore")}
          </div>
          <div className="k-flex-basis-1/3 k-flex-grow k-text-right k-text-uppercase">
            {t("newRatingSystem.esg.sideMenu.actualBlock.superScore")}
          </div>
          <div className="k-flex-basis-1/3 k-flex-grow k-text-right">
            {roundDecimal(dataItem?.evaluationSystemScore, 100)}
          </div>
          <div className="k-flex-basis-1/3 k-flex-grow k-text-right">
            {roundDecimal(dataItem?.universalScore, 100)}%
          </div>
        </div>
      )}
      {isIndicator && (
        <>
          {!isValueRange && (
            <div className="k-px-5">
              <FloatingLabelNumericTextBox
                label={t("newRatingSystem.esg.sideMenu.actualBlock.systemScore")}
                name="evaluationSystemScore"
                className="k-w-full"
                value={dataItem?.evaluationSystemScore}
                onChange={systemScoreChange}
                max={dataItem?.maxSystemScore}
                readOnly={readOnly}
                min={-dataItem?.maxSystemScore}
                autoSelect
              />
              <Hint className="system-score-range">
                -{dataItem?.maxSystemScore} - {dataItem?.maxSystemScore}
              </Hint>
            </div>
          )}
          {isValueRange && (
            <>
              <div className="k-px-5">
                <FloatingLabelDropdownList
                  label={t("newRatingSystem.esg.sideMenu.actualBlock.systemScore")}
                  name="evaluationValue"
                  className="k-w-full"
                  value={valueRangeValue ?? ""}
                  data={valueRangeList}
                  textField="systemScore"
                  dataItemKey="value"
                  itemRender={systemScoreItemRender}
                  valueRender={systemScoreValueRender}
                  onChange={evaluationValueChange}
                  readOnly={readOnly}
                />
              </div>
              {isCustomValue && (
                <div className="k-px-5 k-d-flex k-gap-3">
                  <FloatingLabelTextBox
                    label={t("newRatingSystem.esg.sideMenu.actualBlock.value")}
                    name="customValue"
                    className="k-flex-1"
                    value={customValue?.value}
                    onChange={customValueChange}
                    readOnly={readOnly}
                    autoSelect
                  />
                  <FloatingLabelNumericTextBox
                    label={t("newRatingSystem.esg.sideMenu.actualBlock.systemScore")}
                    name="evaluationSystemScore"
                    className="k-flex-1"
                    value={dataItem?.evaluationSystemScore}
                    onChange={customSystemScoreChange}
                    readOnly={readOnly}
                    min={-dataItem?.maxSystemScore}
                    autoSelect
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

SystemScoreSubBlock.propTypes = {
  dataItem: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

const ValueSubBlock = ({ dataItem, readOnly, onChange = () => null }) => {
  const { t } = useTranslation();
  if (!dataItem?.visibleOnTable || conditionNotIndicator(dataItem)) return null;

  return (
    <div className="k-px-5" id={ACTUAL_VALUE_SUB_BLOCK_ID}>
      <FloatingLabelTextBox
        label={t("newRatingSystem.esg.sideMenu.actualBlock.value")}
        className="k-w-full"
        suffix={dataItem?.unit}
        name="evaluationScoringValue"
        value={dataItem?.evaluationScoringValue ?? ""}
        onChange={(value) => onChange([{ dataItem, field: "evaluationScoringValue", value }])}
        readOnly={readOnly}
        autoSelect
      />
    </div>
  );
};

ValueSubBlock.propTypes = {
  dataItem: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

const ReasonSubBlock = ({ dataItem, system, readOnly, onChange = () => null }) => {
  const { t } = useTranslation();
  if (conditionNotIndicator(dataItem)) return null;

  const saveChangesReason = (reasonEditorValue) => {
    if (reasonEditorValue) {
      const { event } = reasonEditorValue;
      let html = event?.html ?? "";

      html = adjustRGBFormat(html);
      html = addBreakLines(html);
      html = removeExtraBreakLines(html);

      onChange([{ dataItem, field: "reason", value: isNotEmpty(html) ? html : "" }]);
    }
  };

  return (
    <div className="k-px-5 k-d-flex k-flex-col k-gap-3" id={ACTUAL_REASON_SUB_BLOCK_ID}>
      <span className="k-text-uppercase k-font-bold">{t("newRatingSystem.esg.sideMenu.actualBlock.reason")}</span>
      <EvaluationReason
        itemUid={dataItem?.uuid}
        value={dataItem?.reason ?? ""}
        onBlur={saveChangesReason}
        ratingSystemId={system?.id}
        readOnly={readOnly}
      />
      {readOnly && <div className="rich-text-content" dangerouslySetInnerHTML={{ __html: dataItem?.reason ?? "" }} />}
    </div>
  );
};

ReasonSubBlock.propTypes = {
  dataItem: PropTypes.object,
  system: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

const LinksSubBlock = ({ dataItem, readOnly, onChange = () => null }) => {
  const { t } = useTranslation();
  const [linkDialog, setLinkDialog] = useState(null);

  if (conditionNotIndicator(dataItem)) return null;
  const links = dataItem?.evaluationLinks ?? [];

  const changeLink = (dataChangeLink) => {
    let updated = [...links];
    if (linkDialog === true) updated.push(dataChangeLink);
    else updated[linkDialog] = dataChangeLink;

    onChange([{ dataItem, field: "evaluationLinks", value: updated }]);
    setLinkDialog(null);
  };

  const deleteLink = (index) => {
    const updated = [...links];
    updated.splice(index, 1);
    onChange([{ dataItem, field: "evaluationLinks", value: updated }]);
  };

  return (
    <div className="k-d-flex k-flex-col k-gap-3" id={ACTUAL_LINKS_SUB_BLOCK_ID}>
      <span className="k-px-5 k-text-uppercase k-font-bold">
        {t("newRatingSystem.esg.sideMenu.actualBlock.linksTitle")}
      </span>
      <div className="k-d-flex k-gap-3 k-flex-wrap k-px-5">
        {links.map((item, index) =>
          readOnly ? (
            <Chip
              key={getUUID()}
              clickable={true}
              onClick={() => {
                window.open(item.value);
              }}
              label={item.title ?? item.value}
            />
          ) : (
            <Chip
              key={getUUID()}
              clickable={true}
              label={item.title ?? item.value}
              onDelete={() => deleteLink(index)}
              onClick={() => setLinkDialog(index)}
            />
          )
        )}
      </div>
      {!readOnly && (
        <>
          <Button id={ACTUAL_LINKS_ADD_BUTTON_ID} onClick={() => setLinkDialog(true)}>
            <AddLinkOutlinedIcon />
            {t("newRatingSystem.esg.sideMenu.actualBlock.addLink")}
          </Button>
          <LinkDialog
            open={linkDialog !== null}
            onClose={() => setLinkDialog(null)}
            titleText={linkDialog === true ? t("grouping.addLink") : t("grouping.editLink")}
            data={linkDialog !== null ? dataItem?.evaluationLinks?.[linkDialog] : null}
            onSave={changeLink}
          />
        </>
      )}
    </div>
  );
};

LinksSubBlock.propTypes = {
  dataItem: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

const checkFileExists = (fileName) => {
  const { map } = getSystemPageState();
  return someMap(map, (node) => node?.evaluationFiles?.some((file) => file.name === fileName));
};

const FilesSubBlock = ({ dataItem, system, readOnly, onChange = () => null }) => {
  const { t } = useTranslation();
  const [deleteFileConfirmation, setDeleteFileConfirmation] = useState(null);
  const [kendoFiles, setKendoFiles] = useState([]);

  useEffect(() => {
    setKendoFiles([]);
  }, [dataItem?.id]);

  const files = dataItem?.evaluationFiles ?? [];

  const onDelete = () => {
    if (!deleteFileConfirmation) return;

    const updated = [...files];
    updated.splice(deleteFileConfirmation?.index, 1);
    onChange([{ dataItem, field: "evaluationFiles", value: updated }]);
    setDeleteFileConfirmation(null);
  };

  return (
    <div className="k-d-flex k-flex-col k-gap-3" id={ACTUAL_FILES_SUB_BLOCK_ID}>
      <span className="k-px-5 k-text-uppercase k-font-bold">
        {t("newRatingSystem.esg.sideMenu.actualBlock.filesTitle")}
      </span>
      <div className="k-d-flex k-flex-col k-gap-2">
        {files.map((item, index) => (
          <div key={getUUID()} className="k-px-7 k-d-flex k-gap-2 k-align-items-center">
            <a
              href={getProjectFileUrl(system?.projectId, item.fileId)}
              target="_blank"
              className="k-flex-1"
              rel="noreferrer"
            >
              {item.name}
            </a>
            {!readOnly && (
              <IconButton onClick={() => setDeleteFileConfirmation({ item, index })}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>
      {!readOnly && (
        <span id={ACTUAL_FILES_UPLOAD_ID}>
          <Upload
            files={kendoFiles}
            className="k-mx-5"
            saveField="file"
            multiple={false}
            saveUrl={`/api/project/${system?.projectId}/file/temporary`}
            onAdd={(event) => setKendoFiles(event.newState)}
            onRemove={(event) => setKendoFiles(event.newState)}
            restrictions={FILE_UPLOAD_RESTRICTIONS}
            accept={FILE_UPLOAD_RESTRICTIONS.allowedExtensions.map((e) => "." + e).join(",")}
            onStatusChange={(event) => {
              if (event?.response?.status !== 200) console.error("error");

              const newFileName = event.response?.response?.originalName;
              const fileExists = checkFileExists(newFileName);

              if (!fileExists) {
                const updated = [...files];

                updated.push({
                  fileId: event.response?.response?.id,
                  name: newFileName,
                });

                onChange([{ dataItem, field: "evaluationFiles", value: updated }]);
                setKendoFiles(event.newState);
              } else {
                showError(t("newRatingSystem.esg.sideMenu.actualBlock.fileAlreadyExists"));
                if (event?.newState?.[0]) event.newState[0].status = UploadFileStatus.UploadFailed;
                setKendoFiles(event.newState);
              }
            }}
          />
        </span>
      )}
      <ConfirmationDialog
        bodyText={t("files.removeConfirmationText")}
        titleText={t("files.deleteConfirmationTitle")}
        color="secondary"
        onClose={() => setDeleteFileConfirmation(null)}
        open={!!deleteFileConfirmation}
        confirmText={t("main.delete")}
        onConfirm={onDelete}
      />
    </div>
  );
};

FilesSubBlock.propTypes = {
  dataItem: PropTypes.object,
  system: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

export const ActualBlockESG = ({ dataItem, system, tableFn, readOnly }) => {
  const { t } = useTranslation();

  return (
    <BaseBlock title={t("newRatingSystem.esg.sideMenu.actualBlock.title")} id={ACTUAL_BLOCK_ESG_ID}>
      <div className="k-d-flex k-flex-col k-gap-3">
        <KPISubBlock dataItem={dataItem} onChange={tableFn?.onItemChange} readOnly={readOnly} />
        <SystemScoreSubBlock dataItem={dataItem} onChange={tableFn?.onItemChange} readOnly={readOnly} />
        <ValueSubBlock dataItem={dataItem} onChange={tableFn?.onItemChange} readOnly={readOnly} />
        <ReasonSubBlock dataItem={dataItem} system={system} onChange={tableFn?.onItemChange} readOnly={readOnly} />
        <LinksSubBlock dataItem={dataItem} onChange={tableFn?.onItemChange} readOnly={readOnly} />
        <FilesSubBlock dataItem={dataItem} system={system} onChange={tableFn?.onItemChange} readOnly={readOnly} />
      </div>
    </BaseBlock>
  );
};

ActualBlockESG.propTypes = {
  dataItem: PropTypes.object,
  system: PropTypes.object,
  tableFn: PropTypes.object,
  readOnly: PropTypes.bool,
};
