import AddIcon from "@mui/icons-material/Add";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AWARD_CALCULATION_TYPE_PERCENT,
  AWARD_CALCULATION_TYPE_POINT,
  SYSTEM_LOGIC_DGNB,
  SYSTEM_LOGIC_STANDARD,
} from "../../../constants/sustainabilitySystem";
import { AwardElement } from "../../../models/AwardElement";
import { processMessage } from "../../../utils";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { ImageViewer } from "../../ImageViewer/ImageViewer";
import { TwoOptionsToggle } from "../../TwoOptionsToggle/TwoOptionsToggle";
import { AwardDialog } from "./AwardDialog/AwardDialog";
import "./SustainabilitySystemAwards.scss";

export const SustainabilitySystemAwards = ({
  values,
  onChange,
  readOnly,
  setAwardCalculationType,
  awardCalculationType,
  systemLogic,
  setSystemLogic,
  submitted,
  errors,
}) => {
  const { t } = useTranslation();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [preview, setPreview] = useState(null);
  const [showDialog, setShowDialog] = useState(null);

  const addAward = (data) => {
    const updated = [...values];
    updated.push(data);
    onChange(updated);
  };

  const setFile = (fileId) => setShowDialog({ ...showDialog, fileId });

  const onDelete = () => {
    const row = deleteConfirmation;
    onChange(values.filter((item) => item.UID !== row.UID));
    setDeleteConfirmation(null);
  };

  const onAwardChange = (data) => {
    if (!values.find((item) => item.UID === data.UID)) {
      addAward(data);
    } else {
      onChange(values.map((item) => (item.UID === data.UID ? { ...data } : item)));
    }
  };

  return (
    <div className="sustainability-system-awards">
      <div className="system-logic">
        <h2>
          System logic
          <Tooltip className="systemLogicDescriptionTooltip" title={t("award.systemLogicDescription")}>
            <InfoOutlinedIcon className="systemLogicDescriptionIcon" />
          </Tooltip>
          <TwoOptionsToggle
            className="systemLogicToggle"
            value={systemLogic}
            onChange={setSystemLogic}
            disabled={readOnly}
            option1={{ value: SYSTEM_LOGIC_STANDARD, label: t("award.systemLogicStandard") }}
            option2={{ value: SYSTEM_LOGIC_DGNB, label: t("award.systemLogicDGNB") }}
          />
        </h2>
      </div>
      <div className="sustainability-system-awards-box">
        <h2>
          {processMessage(t(values.length === 1 ? "award.count" : "award.count2"), [values.length])}{" "}
          <Tooltip className="calculationTypePercentDescriptionTooltip" title={t("award.percentDescription")}>
            <InfoOutlinedIcon className="systemLogicDescriptionIcon" />
          </Tooltip>
          <TwoOptionsToggle
            value={awardCalculationType}
            onChange={setAwardCalculationType}
            disabled={readOnly}
            option1={{ value: AWARD_CALCULATION_TYPE_PERCENT, label: t("award.calculationType.PERCENT") }}
            option2={{ value: AWARD_CALCULATION_TYPE_POINT, label: t("award.calculationType.POINT") }}
          />
          <Tooltip className="calculationTypePointDescriptionTooltip" title={t("award.pointDescription")}>
            <InfoOutlinedIcon className="systemLogicDescriptionIcon" />
          </Tooltip>
        </h2>
        <div className="award-cards">
          {values.map((item, index) => {
            return (
              <div key={index} className="award-card">
                <h3>{item.title || ""}</h3>
                <h4>
                  {t(
                    awardCalculationType === AWARD_CALCULATION_TYPE_POINT ? "award.rangePoints" : "award.rangePercents"
                  )}
                  :
                  <br />
                  {item.scoreFrom || 0} {t("main.to")} {item.scoreTo || 0}
                </h4>
                <div className="award-image-wrapper">
                  <img
                    className="award-image"
                    alt=""
                    src={item.fileId ? `/api/sustainability/award/file/${item.fileId}/preview` : ""}
                    onClick={() => item.fileId && setPreview(`/api/sustainability/award/file/${item.fileId}/preview`)}
                  />
                </div>
                {!readOnly && (
                  <>
                    <Button className="edit-btn" onClick={() => setShowDialog(item)}>
                      <CreateOutlinedIcon /> {t("award.edit")}
                    </Button>
                    <Button className="delete-btn" onClick={() => setDeleteConfirmation(item)}>
                      <DeleteOutlinedIcon /> {t("award.delete")}
                    </Button>
                  </>
                )}
              </div>
            );
          })}
          {!readOnly && (
            <div key={-1} className="add-award-card">
              <span onClick={() => setShowDialog(new AwardElement())}>
                <AddIcon />
                {t("award.addAward")}
              </span>
            </div>
          )}
        </div>

        <ConfirmationDialog
          open={!!deleteConfirmation}
          onClose={() => setDeleteConfirmation(null)}
          onConfirm={onDelete}
          titleText={t("award.deleteConfirmationTitle")}
          bodyText={t("award.deleteConfirmation")}
          confirmText={t("main.delete")}
          color="secondary"
        />

        <AwardDialog
          open={!!showDialog}
          awardCalculationType={awardCalculationType}
          onClose={() => setShowDialog(null)}
          data={showDialog || {}}
          onSave={onAwardChange}
          setFile={setFile}
          readOnly={readOnly}
        />

        <ImageViewer image={preview} open={!!preview} onClose={() => setPreview(null)} />
      </div>
    </div>
  );
};
