import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProjectRatingStatusCountResponse } from "../../../../hooks/project";
import { STATUS_REPORTING, STATUS_SCREENING, STATUS_VERIFICATION } from "../../../../constants/ratingSystem";
import { FilterBlocks } from "../../../FilterBlocks/FilterBlocks";
import "./StatusFilter.scss";

const getCountsByStatuses = (data) => {
  const res = {};
  (data || []).forEach(item => res[item.ratingStatus] = item.count);
  return res;
};

export const StatusFilter = ({ value, onChange, disabled }) => {
  const { t } = useTranslation();

  const countsResponse = useProjectRatingStatusCountResponse();

  const [status, setStatus] = useState(getCountsByStatuses(countsResponse.data));

  useEffect(() => {
    if (!countsResponse.loading) {
      setStatus(getCountsByStatuses(countsResponse.data));
    }
  }, [countsResponse.loading, countsResponse.data]);

  const filterOptions = [
    {
      name: STATUS_SCREENING,
      title: t('status.filter.' + STATUS_SCREENING),
      amount: status[STATUS_SCREENING] || 0
    },
    {
      name: STATUS_VERIFICATION,
      title: t('status.filter.' + STATUS_VERIFICATION),
      amount: status[STATUS_VERIFICATION] || 0
    },
    {
      name: STATUS_REPORTING,
      title: t('status.filter.' + STATUS_REPORTING),
      amount: status[STATUS_REPORTING] || 0
    }
  ];

  return (
    <FilterBlocks filterOptions={filterOptions} value={value} onChange={onChange} allowDeselect showReset={!!value}
                  disabled={disabled}/>
  );
};
