import React, { useEffect, useRef } from "react";
import "./GroupingElementChart.scss";
import { generateGroupingElementSvgPAST } from "./groupingElementSvgPAST";
import { GROUPING_TYPE_CATEGORY, GROUPING_TYPE_CRITERION } from "../../../constants/sustainabilitySystem";
import { useTranslation } from "react-i18next";
import { onlyDecimals } from "../../../utils"

export const GroupingElementChartPAST = ({ data }) => {
  const { i18n } = useTranslation();
  const chartRef = useRef(null);
  const middleRef = useRef(null);
  const { evaluationSystemScore, universalScore, title, type } = data;
  const score = type === GROUPING_TYPE_CRITERION || type === GROUPING_TYPE_CATEGORY ? universalScore : evaluationSystemScore;

  useEffect(() => {
      if (chartRef && chartRef.current) {
        chartRef.current.innerHTML = '';
        if (data) {
          chartRef.current.appendChild(generateGroupingElementSvgPAST(score));
        }
      }
    },
    [data, chartRef]
  );

  return (
    <div className="grouping-element-chart" data-cy={data.systemReference}>
      <div ref={chartRef} className="chart"/>
      <div ref={middleRef} className={'middle-content' + (!score ? ' text-gray' : '')}>
        <div className="header-text text-hyphens" lang={i18n.language}>{title}</div>
        <div className="value-text">{onlyDecimals(score, 2)}</div>
      </div>
    </div>
  );
};
