import { TreeList, filterBy, orderBy } from "@progress/kendo-react-treelist";
import afterFrame from "afterframe";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { useEvent } from "../../hooks/utils/useEvent";
import {
  editField,
  expandField,
  idField,
  parentField,
  pauseEvent,
  selectedField,
  subItemsField,
  validateStatusChange,
} from "./NewRatingSystem.util";
import { NewRatingSystemContextMenu } from "./NewRatingSystemContextMenu";
import { setSystemPageState } from "./NewRatingSystemESG";
import { calculateTrafficLight, getColumnByPath, getOrderIndex, mapColumns } from "./NewRatingSystemESG.util";
import { conditionIsIndicator } from "./NewRatingSystemESG.validation";
import { HideColumnMenu } from "./NewRatingSystemESGHideColumnMenu";
import { NewRatingSystemSideMenu } from "./NewRatingSystemSideMenu";
import { NewRatingSystemTableOptions } from "./NewRatingSystemTableOptions";
import { Renderers } from "./Renderers";

const DEFAULT_HEIGHT = 800;
const MINIMUM_HEIGHT = 600;

export const defaultTableState = {
  data: [],
  map: {},
  levelArray: [],
  columns: [],
  hiddenColumnMap: {},
  editItem: null,
  editItemfield: null,
  changes: false,
  sort: [],
  filter: [],
  selected: null,
  context: null,
  showFilter: true,
  currentLevel: 0,
  expanded: {},
  originalColumns: [],
};

const tableStateSubject = new BehaviorSubject(defaultTableState);
export const tableFnSubject = new BehaviorSubject({});

export const getTableState = () => tableStateSubject.getValue();
export const setTableState = (v) =>
  tableStateSubject.next(typeof v === "function" ? v(tableStateSubject.getValue()) : v);
const useNewRatingSystemState = hookFromSubject(tableStateSubject);

export const NewRatingSystemTable = React.memo(
  ({
    system,
    editCellMap,
    filterCellMap,
    readOnly,
    editable,
    calculationFields,
    unsaved,
    sizingConfig = {},
    updateGrouping = () => null,
    showError = () => null,
  }) => {
    console.time("Table render");
    afterFrame(() => console.timeEnd("Table render"));

    const [height, setHeight] = useState(DEFAULT_HEIGHT);
    const state = useNewRatingSystemState();

    const onResize = useEvent(() => {
      const result =
        window.innerHeight -
        sizingConfig.headerHeight -
        sizingConfig.paddingTop -
        sizingConfig.paddingBottom -
        sizingConfig.gap -
        42;
      setHeight(Math.max(result, MINIMUM_HEIGHT));
    });

    useEffect(() => {
      window.addEventListener("resize", onResize);
      return () => window.removeEventListener("resize", onResize);
    }, []);

    useEffect(onResize, [sizingConfig]);

    useEffect(() => {
      if (readOnly)
        setTableState((prev) => {
          if (prev?.editItem?.[editField]) prev.editItem[editField] = null;
          return {
            ...prev,
            editItem: null,
            editItemField: null,
          };
        });
    }, [readOnly]);

    useEffect(() => {
      if (state.map[state.selected?.[idField]]) state.map[state.selected?.[idField]][selectedField] = true;
      setTableState((prev) => ({ ...prev, selected: state.map[state.selected?.[idField]] }));
    }, [state.data, state.map]);

    const { data, sort, filter, hiddenColumnMap, editItemField, editItem, showFilter } = state;

    const enterEdit = useEvent((dataItem, field) => {
      if (!editCellMap[field] || readOnly) return;
      setTableState((prev) => {
        const editItem = prev.map[dataItem[idField]];
        editItem[editField] = field;
        return {
          ...prev,
          editItem,
          editItemField: field,
        };
      });
    });

    const exitEdit = useEvent(() => {
      if (!editItem && !editItemField) return;
      setTableState((prev) => {
        prev.editItem[editField] = null;
        return {
          ...prev,
          editItem: null,
          editItemField: null,
        };
      });
    });

    const onItemChange = useEvent((changes = []) =>
      setTableState((prev) => {
        let errorShown = false;
        changes.forEach(({ dataItem, field, value }) => {
          if (!dataItem) return;

          if (field === "indicatorStatus" && validateStatusChange(dataItem, value)) {
            if (!errorShown) showError("ratingSystem.errorStatusChange");
            errorShown = true;
            return;
          }

          prev.map[dataItem[idField]][field] = value;
          if (calculationFields.includes(field))
            updateGrouping(prev.map[dataItem[idField]], prev.map, prev.data, parentField, system.systemLogic);

          if (conditionIsIndicator(dataItem)) dataItem.trafficLight = calculateTrafficLight(dataItem);
        });

        if (changes?.length && !unsaved) setSystemPageState((prev) => ({ ...prev, unsaved: true }));

        return { ...prev };
      })
    );

    const onExpandChange = useEvent(({ dataItem, value }) =>
      setTableState((prev) => {
        prev.expanded[dataItem[idField]] = !value;
        prev.map[dataItem[idField]][expandField] = !value;
        return { ...prev };
      })
    );

    const onSelectionChange = useEvent(({ dataItem }) =>
      setTableState((prev) => {
        const selected = prev.map[dataItem[idField]];
        if (prev.selected === selected) return prev;

        if (prev.selected) prev.selected[selectedField] = false;
        selected[selectedField] = !selected[selectedField];
        return { ...prev, selected };
      })
    );

    const onColumnReorder = useEvent(({ columns }) => {
      setTableState({
        ...state,
        columns,
      });
    });

    const table = useRef();
    const onColumnResize = useEvent((event) => {
      if (table.current) table.current.element.style.width = `${event.totalWidth}px`;
      if (event.end)
        setTableState((prev) => ({
          ...prev,
          columns: event.columns,
        }));
    });

    const onSortChange = useEvent(({ sort }) => setTableState((prev) => ({ ...prev, sort })));
    const onFilterChange = useEvent(({ filter }) => setTableState((prev) => ({ ...prev, filter })));

    const onColumnShowHide = useEvent((hiddenColumnMap) => {
      console.time("onColumnShowHide");
      const columns = [...state.columns];

      for (const key in hiddenColumnMap) {
        if (hiddenColumnMap[key]?.hidden || !state.hiddenColumnMap[key]?.hidden) continue;

        const { column } = hiddenColumnMap[key];
        const parent = getColumnByPath(columns, column.path.slice(0, -1));
        const index = column.path.slice(-1)[0];
        if (
          !hiddenColumnMap[key].hidden &&
          (parent[index]?.field ?? parent[index]?.title) !== (column?.field ?? column?.title)
        )
          parent.splice(index, 0, { ...column, orderIndex: getOrderIndex(column, index, parent) });
      }

      setTableState({ ...state, hiddenColumnMap, columns });
      console.timeEnd("onColumnShowHide");
    });

    const enterContextMenu = useEvent((context) => {
      context?.event?.preventDefault();
      setTableState((prev) => ({ ...prev, context }));
    });

    const itemChangeOverride = useEvent((e) => {
      if (e.field === expandField) {
        return onExpandChange({
          dataItem: e.dataItem,
          level: e.level,
          value: e.value,
        });
      }

      onItemChange(e);
    });

    const clearAllFilters = useEvent(() => setTableState((prev) => ({ ...prev, filter: [] })));

    const clearLayout = useEvent(() =>
      setTableState((prev) => {
        const expanded = {};
        for (const key in prev.map) {
          expanded[key] = true;
          prev.map[key][expandField] = true;
        }

        const filter = [];
        const sort = [];
        const { originalColumns } = state;

        for (const key in prev.hiddenColumnMap) {
          const column = originalColumns.find((c) => c.field === key);
          prev.hiddenColumnMap[key].hidden = false;
          prev.hiddenColumnMap[key].column = column;
        }

        return { ...prev, filter, sort, expanded, columns: originalColumns, hiddenColumnMap };
      })
    );

    useEffect(() => {
      tableFnSubject.next({ onItemChange });
    }, [onItemChange]);

    const renderers = new Renderers(enterEdit, exitEdit, enterContextMenu, editField);

    const processedData = orderBy(filterBy(data, filter, subItemsField), sort, subItemsField).concat([
      {
        isFooter: true,
        evaluationSystemScore: system?.score,
        universalScore: system?.superScore,
        potentialEvaluationSystemScore: system?.potentialScore,
        potentialUniversalScore: system?.potentialSuperScore,
        weightedMaxSystemScore: system?.overallMaxSystemScore,
      },
    ]);

    const processedColumns = state.columns
      .filter((c) => !hiddenColumnMap[c.field ?? c.title]?.hidden)
      .map((column) =>
        mapColumns(column, editItemField, editCellMap, filterCellMap, readOnly, showFilter, hiddenColumnMap)
      );

    const tableProps = useMemo(
      () => ({
        style: {
          tableLayout: "fixed",
        },
      }),
      []
    );

    useEffect(() => {
      document.querySelectorAll("thead tr:not(.k-filter-row) th").forEach((th) => {
        th.removeEventListener("mousedown", pauseEvent);
        th.addEventListener("mousedown", pauseEvent);
      });
    }, [table.current, state.columns]);

    return (
      <>
        <div id="new-esg-rating-system-table-options" className="k-d-flex k-align-items-center k-pl-4 table-options">
          <HideColumnMenu
            columns={state.originalColumns}
            hiddenColumnMap={hiddenColumnMap}
            onChange={onColumnShowHide}
          />
          <NewRatingSystemTableOptions state={state} onClearAllFilters={clearAllFilters} onClearLayout={clearLayout} />
        </div>
        <div className="k-d-flex">
          <TreeList
            id="new-esg-rating-system-table"
            className="k-flex-1"
            ref={(element) => {
              table.current = element;
              if (element?.itemChange && element?.itemChange !== itemChangeOverride)
                element.itemChange = itemChangeOverride;
            }}
            data={processedData}
            sort={sort}
            filter={filter}
            selectedField={selectedField}
            editField={editField}
            expandField={expandField}
            subItemsField={subItemsField}
            cellRender={renderers.cellRender}
            rowRender={renderers.rowRender}
            onItemChange={onItemChange}
            onExpandChange={onExpandChange}
            onColumnReorder={onColumnReorder}
            onColumnResize={onColumnResize}
            onSortChange={onSortChange}
            onFilterChange={onFilterChange}
            onSelectionChange={onSelectionChange}
            columns={processedColumns}
            style={{ overflow: "auto", height }}
            tableProps={tableProps}
            rowHeight={59}
            scrollable="virtual"
            resizable
            sortable
            reorderable
            selectable={{
              enabled: true,
              mode: "single",
            }}
          />
          <NewRatingSystemSideMenu
            selected={state.selected}
            system={system}
            height={height}
            tableFn={{ onItemChange }}
            readOnly={readOnly}
          />
          <NewRatingSystemContextMenu
            context={state.context}
            readOnly={readOnly}
            editable={editable}
            tableFn={{ onSelectionChange, onItemChange }}
          />
        </div>
      </>
    );
  }
);

NewRatingSystemTable.propTypes = {
  system: PropTypes.object,
  editCellMap: PropTypes.object,
  filterCellMap: PropTypes.object,
  readOnly: PropTypes.bool,
  editable: PropTypes.bool,
  calculationFields: PropTypes.array,
  unsaved: PropTypes.bool,
  sizingConfig: PropTypes.object,
  updateGrouping: PropTypes.func,
  showError: PropTypes.func,
};
