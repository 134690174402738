import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { v4 as getUUID } from "uuid";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { ContentBlock } from "../../components/ContentBlock/ContentBlock";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { ProjectDetailsCard } from "../../components/ProjectDetailsCard/ProjectDetailsCard";
import { ProjectGroupAnalytics } from "../../components/ProjectGroup/ProjectGroupAnalytics/ProjectGroupAnalytics";
import { ProjectGroupAnalyticsActions } from "../../components/ProjectGroup/ProjectGroupAnalyticsActions/ProjectGroupAnalyticsActions";
import { ProjectGroupAnalyticsChart } from "../../components/ProjectGroup/ProjectGroupAnalyticsChart/ProjectGroupAnalyticsChart";
import { ProjectGroupAnalyticsKPI } from "../../components/ProjectGroup/ProjectGroupAnalyticsKPI/ProjectGroupAnalyticsKPI";
import { ProjectGroupAnalyticsKPIDetailsChart } from "../../components/ProjectGroup/ProjectGroupAnalyticsKPIDetailsChart/ProjectGroupAnalyticsKPIDetailsChart";
import { ProjectGroupAnalyzeMainBlock } from "../../components/ProjectGroup/ProjectGroupAnalyzeMainBlock/ProjectGroupAnalyzeMainBlock";
import { ProjectGroupHierarchy } from "../../components/ProjectGroup/ProjectGroupHierarchy/ProjectGroupHierarchy";
import { ProjectGroupProjects } from "../../components/ProjectGroup/ProjectGroupProjects/ProjectGroupProjects";
import { ProjectGroupSpiderChart } from "../../components/ProjectGroup/ProjectGroupSpiderChart/ProjectGroupSpiderChart";
import { ProjectsMap } from "../../components/ProjectsMap/ProjectsMap";
import {
  AZURE_MAPS_INITIAL_POSITION,
  AZURE_MAPS_INITIAL_ZOOM,
  AZURE_MAPS_LANG_DE_DE,
  AZURE_MAPS_LANG_EN_GB,
} from "../../constants/azure";
import { LANGUAGE_EN } from "../../constants/language";
import { PERMISSION_PROJECT_GROUP_EDIT } from "../../constants/permissions";
import { hasPermission } from "../../helpers/permission";
import { canUserEditProjectGroup } from "../../helpers/projectGroups";
import { useUser } from "../../hooks/auth";
import { getProject, useProjectGroupProjectsPageResponse } from "../../hooks/project";
import {
  getProjectGroup,
  resetProjectGroupStatisticKpiTooltip,
  useProjectGroupChartProjectsResponse,
  useProjectGroupResponse,
  useProjectGroupStatisticKpiResponse,
} from "../../hooks/projectGroup";
import { setBenchmarksLoadedForKpi } from "../../hooks/ratingSystem";
import { showError } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { getMapCoordinatesFromAPIResponse } from "../../utils";
import "./ProjectGroupAnalyze.scss";

export const ProjectGroupAnalyzePage = () => {
  const { projectGroupId } = useParams();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [selectedGroup, setSelectedGroup] = useState(0);
  const [selectedGroupName, setSelectedGroupName] = useState(0);
  const [resetProjects, setResetProjects] = useState(0);
  const [collapsed, setCollapsed] = useState(false);

  const projectGroupResponse = useProjectGroupResponse();
  const projectGroup = projectGroupResponse.data || {};
  const projectGroupStatisticKpiResponse = useProjectGroupStatisticKpiResponse();

  const projectGroupProjectsPageResponse = useProjectGroupProjectsPageResponse();
  const total = projectGroupProjectsPageResponse?.data?.meta?.total || 0;
  const projectGroupChartProjectsResponse = useProjectGroupChartProjectsResponse();

  const [coordinates, setCoordinates] = useState(null);
  const [mapLanguage, setMapLanguage] = useState(
    i18n.language === LANGUAGE_EN ? AZURE_MAPS_LANG_EN_GB : AZURE_MAPS_LANG_DE_DE
  );

  const [projectDetails, setProjectDetails] = useState();
  const [isLoadingProjectDetails, setIsLoadingProjectDetails] = useState(false);
  const [projsOnSameLatLong, setProjsOnSameLatLong] = useState(null);
  const [projsOnSameLatLongIndex, setProjsOnSameLatLongIndex] = useState(0); // used to render projs pagination when multiple projs exists on same lat-long

  const { user } = useUser();
  const canEditProjectGroup = canUserEditProjectGroup(user, projectGroup);

  useEffect(() => {
    if (projectGroupId) {
      load();
      setBenchmarksLoadedForKpi(null);
    }
    resetProjectGroupStatisticKpiTooltip();
  }, [projectGroupId]);

  const load = useEvent(() => {
    getProjectGroup(projectGroupId)
      .then()
      .catch(() => {
        showError(t("error.500"));
        navigate("/projectGroup");
      });
  });

  const reloadProjects = () => setResetProjects(resetProjects + 1);

  const breadCrumb = useMemo(
    () => [{ url: `/projectGroup`, text: t("menu.projectGroups.title") }, { text: projectGroup.name || "" }],
    [projectGroup.name, i18n.language]
  );

  const loading = projectGroupResponse.loading;

  useEffect(() => {
    if (!projectGroupProjectsPageResponse.loading) {
      const responseData = projectGroupProjectsPageResponse.data || {};
      const projects = responseData.content || [];
      setCoordinates(getMapCoordinatesFromAPIResponse(projects));
    }
  }, [projectGroupProjectsPageResponse.loading]);

  const getCoordinates = () => null;

  const closeProjectDetailsCard = () => {
    setProjectDetails(null);
  };

  useEffect(() => {
    if (!projsOnSameLatLong) {
      return;
    }
    setIsLoadingProjectDetails(true);
    getProject(projsOnSameLatLong[projsOnSameLatLongIndex])
      .then(setProjectData)
      .catch((error) => {
        console.error(error);
        setIsLoadingProjectDetails(false);
        showError(t("error.500"));
      });
  }, [projsOnSameLatLongIndex]); // fetch the next proj under the same lat/long

  const setupProjsOnSameLatLong = (projectInfo) => {
    setProjsOnSameLatLongIndex(0);
    if (projectInfo.projsOnSameLatLong) {
      setProjsOnSameLatLong([projectInfo.projectId].concat(projectInfo.projsOnSameLatLong));
    } else {
      setProjsOnSameLatLong(null);
    }
  };

  const setProjectData = (projectDetailsInner) => {
    setIsLoadingProjectDetails(false);
    setProjectDetails(projectDetailsInner);
  };

  const openProjectDetailsCard = (projectInfo) => {
    setIsLoadingProjectDetails(true);
    setupProjsOnSameLatLong(projectInfo);
    getProject(projectInfo.projectId)
      .then(setProjectData)
      .catch((error) => {
        console.error(error);
        setIsLoadingProjectDetails(false);
        showError(t("error.500"));
      });
  };

  i18n.on("languageChanged", () => {
    setMapLanguage(i18n.language);
  });

  return (
    <LoadingOverlay spinner active={loading} className="project-group-analyze-page">
      <div className="page-header">
        <div className="flex-row">
          <Breadcrumb pathParts={breadCrumb} />
          {hasPermission(PERMISSION_PROJECT_GROUP_EDIT) && canEditProjectGroup && (
            <div className="flex-auto text-right">
              <NavLink to={"/projectGroup/" + projectGroupId + "?mode=edit"}>
                <Button color="primary" className="right-header-button">
                  <EditIcon fontSize="inherit" />
                  {t("main.edit")}
                </Button>
              </NavLink>
            </div>
          )}
        </div>
        <div className="top-block">
          <ContentBlock className="main-block">
            <ProjectGroupAnalyzeMainBlock data={projectGroup} collapsed={collapsed} />
          </ContentBlock>
          <ContentBlock className="flex-auto right-block">
            <div className="white-gradient" />
            <IconButton onClick={() => setCollapsed(!collapsed)} size="small" className="collapse-button">
              {!!collapsed && <KeyboardArrowDownIcon />}
              {!collapsed && <KeyboardArrowUpIcon />}
            </IconButton>

            <ProjectGroupHierarchy
              projectGroupId={projectGroupId}
              readOnly
              simplified
              collapsed={collapsed}
              onSelectGroup={(id, name) => {
                setSelectedGroup(id);
                setSelectedGroupName(name);
              }}
              onAddProject={reloadProjects}
            />
          </ContentBlock>
        </div>
        <div className="projects-title flex-row">
          <h2>{selectedGroup ? selectedGroupName : t("projectGroup.hierarchyBlock.allProjects")}</h2>
          {!!total && <div className="flex-auto items-amount">{total}</div>}
        </div>
        <ContentBlock>
          <ProjectGroupProjects
            key={resetProjects}
            projectGroupId={projectGroupId}
            selectedProjectGroupId={selectedGroup || projectGroupId}
            readOnly
            withSearch
            withTooltip
            analyze
          />
        </ContentBlock>
      </div>
      <div className="page-layout projectGroupsMapWrapper">
        <ContentBlock>
          <div className={`projectGroupsMap ${projectDetails ? "card-visible" : ""}`}>
            <ProjectsMap
              initialPosition={AZURE_MAPS_INITIAL_POSITION}
              initialZoom={AZURE_MAPS_INITIAL_ZOOM}
              coordinates={coordinates}
              getCoordinates={getCoordinates}
              mapLanguage={mapLanguage}
              projectDetailsCallback={openProjectDetailsCard}
            />
            {!!projectDetails && (
              <ProjectDetailsCard
                projectDetails={projectDetails}
                closeProjectDetailsCard={closeProjectDetailsCard}
                isLoadingProjectDetails={isLoadingProjectDetails}
                projsOnSameLatLong={projsOnSameLatLong}
                projsOnSameLatLongIndex={projsOnSameLatLongIndex}
                setProjsOnSameLatLongIndex={setProjsOnSameLatLongIndex}
              />
            )}
          </div>
        </ContentBlock>
      </div>
      <div className="page-layout">
        <h2>{t("projectGroup.analytics.header")}</h2>
        <ContentBlock hidden={!projectGroupChartProjectsResponse.data?.length}>
          <ProjectGroupSpiderChart projectGroupId={selectedGroup || projectGroupId} />
        </ContentBlock>
        <div className="flex-row">
          <ProjectGroupAnalytics projectGroupId={selectedGroup || projectGroupId} />
          <ProjectGroupAnalyticsChart projectGroupId={selectedGroup || projectGroupId} className="flex-auto" />
        </div>
        <div className="flex-row">
          <ProjectGroupAnalyticsKPI projectGroupId={selectedGroup || projectGroupId} />
          <ProjectGroupAnalyticsActions projectGroupId={selectedGroup || projectGroupId} />
        </div>
        <ContentBlock>
          {(projectGroupStatisticKpiResponse?.data || []).map(({ kpiUnit, kpiName }, index) => (
            <ProjectGroupAnalyticsKPIDetailsChart
              key={getUUID()}
              projectGroupId={selectedGroup || projectGroupId}
              kpiUnit={kpiUnit}
              kpiName={kpiName}
            />
          ))}
        </ContentBlock>
      </div>
    </LoadingOverlay>
  );
};
