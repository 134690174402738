import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { forEachRecursive } from "../../utils";
import "./CheckTree.scss";

const SELECT_ALL_VALUE = "SELECT_ALL";

const isChecked = (item, values) => {
  const items = item.items || [];
  if ((values || []).indexOf(item.name) !== -1) {
    return true;
  }
  let res = !!items.length;
  if (res) {
    for (let i = 0; i < items.length; i++) {
      res = isChecked(items[i], values);
      if (!res) {
        break;
      }
    }
  }
  return res;
};

const isSomeChecked = (item, values) => {
  const items = item.items || [];
  if ((values || []).indexOf(item.name) !== -1) {
    return true;
  }
  let res = false;
  if (items.length) {
    for (let i = 0; i < items.length; i++) {
      res = isSomeChecked(items[i], values);
      if (res) {
        break;
      }
    }
  }
  return res;
};

const processValuesOnCheck = (items, values, name, checked) => {
  let changedItem;
  let children = [];
  // find item
  forEachRecursive(
    items || [],
    (item) => {
      if (item.name === name) {
        changedItem = item;
        return true;
      }
    },
    "items"
  );
  // clear item and children from values
  const childItems = changedItem.items || [];
  forEachRecursive(
    childItems,
    (item) => {
      children.push(item.name);
    },
    "items"
  );
  let updated = (values || []).filter((value) => value !== name && children.indexOf(value) === -1);
  // add item or children to values
  if (checked) {
    if (childItems.length) {
      childItems.forEach((item) => {
        updated = processValuesOnCheck(items, updated, item.name, checked);
      });
    } else {
      updated.push(name);
    }
  }
  // keep order as in items
  const res = [];
  forEachRecursive(
    items,
    (item) => {
      if (updated.indexOf(item.name) !== -1) {
        res.push(item.name);
      }
    },
    "items"
  );
  return res;
};

const CheckTreeNode = React.forwardRef(
  (
    {
      index,
      item,
      values,
      onCheckedChange,
      collapsible,
      lastLevelOnly,
      disabledValues,
      getRowClassName,
      collapsedByDefault,
      level,
      disabled,
    },
    ref
  ) => {
    const items = item.items || [];
    const checked = isChecked(item, values);
    const someChecked = isSomeChecked(item, values);
    const [collapsed, setCollapsed] = useState(!!collapsedByDefault);
    const isLastLevel = !items.length;

    const onClick = () => {
      if (!isDisabled && (isLastLevel || !lastLevelOnly)) {
        onCheckedChange(item.name, !checked);
      }
    };

    const rowClassName = getRowClassName ? getRowClassName(item, index, level) : "";
    const isDisabled = disabled || (!!disabledValues && disabledValues.indexOf(item.name) !== -1);

    return (
      <li ref={ref} className={"check-tree-node" + (checked ? " checked" : "") + (isDisabled ? " disabled" : "")}>
        <div className={"check-tree-node-title " + rowClassName}>
          {(isLastLevel || !lastLevelOnly) && (
            <Checkbox
              color="primary"
              indeterminate={!checked && someChecked}
              checked={checked || someChecked}
              disabled={isDisabled}
              onChange={() => onCheckedChange(item.name, !checked)}
            />
          )}
          <span className="node-name" onClick={onClick}>
            {item.text}
          </span>
          {!!collapsible && !!items.length && (
            <IconButton className="collapse-trigger" onClick={() => setCollapsed(!collapsed)}>
              {!!collapsed && <ArrowDropDownIcon />}
              {!collapsed && <ArrowDropUpIcon />}
            </IconButton>
          )}
        </div>
        {!!items.length && (
          <ul className={"check-tree-node-items collapsible " + (collapsed ? "collapsed" : "")}>
            {(items || []).map((itemInner, indexInner) => (
              <CheckTreeNode
                key={indexInner}
                index={indexInner}
                item={itemInner}
                values={values}
                collapsible={collapsible}
                disabledValues={disabledValues}
                onCheckedChange={onCheckedChange}
                disabled={disabled}
                lastLevelOnly={lastLevelOnly}
                collapsedByDefault={collapsedByDefault}
                level={level + 1}
              />
            ))}
          </ul>
        )}
      </li>
    );
  }
);

export const CheckTree = React.forwardRef(
  (
    {
      items,
      values,
      onChange,
      collapsible,
      multiple,
      lastLevelOnly,
      disabledValues,
      disabled,
      getRowClassName,
      collapsedByDefault,
      selectAllOption,
    },
    ref
  ) => {
    const onCheckedChange = (name, checked) => {
      if (name === SELECT_ALL_VALUE) {
        if (values.length === items.length) {
          onChange([]);
        } else {
          onChange(items.map((x) => x.name));
        }

        return;
      }

      if (multiple) {
        onChange(processValuesOnCheck(items, values, name, checked));
      } else {
        onChange(checked ? name : null);
      }
    };

    const { t } = useTranslation();

    const allHidden =
      !!items && !!values && values.length === items.length ? [SELECT_ALL_VALUE].concat(values) : values;
    const startingIndex = multiple && selectAllOption ? 1 : 0;

    return (
      <ul ref={ref} className="check-tree">
        {multiple && selectAllOption && (
          <CheckTreeNode
            key={0}
            index={0}
            item={{ name: SELECT_ALL_VALUE, text: t("main.selectOrDeselectAll") }}
            values={multiple ? allHidden : allHidden ? [allHidden] : []}
            collapsible={collapsible}
            disabledValues={disabledValues}
            getRowClassName={getRowClassName}
            onCheckedChange={onCheckedChange}
            lastLevelOnly={lastLevelOnly}
            level={0}
            collapsedByDefault={collapsedByDefault}
            disabled={!!disabled}
          />
        )}
        {(items || []).map((item, index) => (
          <CheckTreeNode
            key={index + startingIndex}
            index={index + startingIndex}
            item={item}
            values={multiple ? values : values ? [values] : []}
            collapsible={collapsible}
            disabledValues={disabledValues}
            getRowClassName={getRowClassName}
            onCheckedChange={onCheckedChange}
            lastLevelOnly={lastLevelOnly}
            level={0}
            collapsedByDefault={collapsedByDefault}
            disabled={!!disabled}
          />
        ))}
      </ul>
    );
  }
);
