import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import React, { useState } from "react";
import { CheckTree } from "../CheckTree/CheckTree";
import "./FilterPopover.scss";

export const FilterPopover = ({ items, values, onChange, title, multiple, collapsible, defaultExpanded }) => {
  const [popoverTarget, setPopoverTarget] = useState(null);

  return (
    <div className="filter-popover">
      <div className="flex-row filter-header" onClick={(event) => setPopoverTarget(event.currentTarget)}>
        <div>
          <h3>{title}</h3>
        </div>
        <div>
          <IconButton size="small" className="collapse-trigger">
            {!popoverTarget && <KeyboardArrowDownIcon />}
            {!!popoverTarget && <KeyboardArrowUpIcon />}
          </IconButton>
        </div>
      </div>

      <Popover
        className="filter-popover-popover"
        open={!!popoverTarget}
        anchorEl={popoverTarget}
        onClose={() => setPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <CheckTree
          multiple={!!multiple}
          items={items || []}
          values={values || []}
          onChange={(valuesInner) => onChange(valuesInner)}
          collapsible={collapsible}
          collapsedByDefault={!defaultExpanded}
        />
      </Popover>
    </div>
  );
};
