import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box } from "@mui/material";
import { FilterOperator } from "@progress/kendo-data-query";
import { SvgIcon } from "@progress/kendo-react-common";
import { Pager } from "@progress/kendo-react-data-tools";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { NumericTextBox, TextBox } from "@progress/kendo-react-inputs";
import * as svgIcons from "@progress/kendo-svg-icons";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEvent } from "../../hooks/utils/useEvent";
import { roundDecimal } from "../../pages/RatingSystem/NewRatingSystem.utils";

export const textOperatorsValueRequired = [
  FilterOperator.Contains,
  FilterOperator.StartsWith,
  FilterOperator.EndsWith,
  FilterOperator.EqualTo,
  FilterOperator.NotEqualTo,
];

export const textOperatorsValueBackend = [FilterOperator.Contains, FilterOperator.EqualTo];

export const textOperatorsValueNotRequired = [
  FilterOperator.IsEmpty,
  FilterOperator.IsNotEmpty,
  FilterOperator.IsNotNull,
  FilterOperator.IsNull,
];

export const textOperatorValues = textOperatorsValueRequired
  .concat(textOperatorsValueNotRequired)
  .map((value) => ({ text: `common.filterOperator.${value}`, value }));

export const textOperatorValuesBackend = textOperatorValues.filter(({ value }) =>
  textOperatorsValueBackend.includes(value)
);

export const numericOperatorsValueRequired = [
  FilterOperator.EqualTo,
  FilterOperator.NotEqualTo,
  FilterOperator.GreaterThanOrEqual,
  FilterOperator.GreaterThan,
  FilterOperator.LessThanOrEqual,
  FilterOperator.LessThan,
];

export const numericOperatorsValueNotRequired = [FilterOperator.IsNotNull, FilterOperator.IsNull];

export const numericOperatorValues = numericOperatorsValueRequired
  .concat(numericOperatorsValueNotRequired)
  .map((value) => ({ text: `common.filterOperator.${value}`, value }));

export const numericOperatorValuesBackend = numericOperatorValues.filter(({ value }) =>
  numericOperatorsValueRequired.includes(value)
);

export const kendoOperatorConversionMap = {
  [FilterOperator.Contains]: "like",
  [FilterOperator.StartsWith]: "sw",
  [FilterOperator.EndsWith]: "ew",
  [FilterOperator.EqualTo]: "eq",
  [FilterOperator.NotEqualTo]: "neq",
  [FilterOperator.GreaterThanOrEqual]: "gte",
  [FilterOperator.GreaterThan]: "gt",
  [FilterOperator.LessThanOrEqual]: "lte",
  [FilterOperator.LessThan]: "lt",
};

export const ExpandButton = ({ expanded, hidden, onClick = () => null }) => {
  let icon = null;
  if (!hidden) icon = expanded ? svgIcons.caretAltDownIcon : svgIcons.caretAltRightIcon;
  return <SvgIcon onClick={onClick} icon={icon} />;
};

ExpandButton.propTypes = {
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  hidden: PropTypes.bool,
};

export const TranslateTh = (props) => {
  const { t } = useTranslation();
  const { title, field, render } = props;
  const sortable = props?.sortable && props?.columns?.find((column) => column.field === field)?.sortable;
  const dir = props?.sort?.[0]?.field === field && props?.sort?.[0]?.dir;
  const thElement = (
    <span className="k-cell-inner">
      <Box
        className="k-link"
        onClick={(e) => {
          if (!sortable) return;

          const sort = [];
          if (dir === "asc") sort.push({ field, dir: "desc" });
          else if (props?.sort[0]?.field !== field) sort.push({ field, dir: "asc" });

          sortable && props?.sortChange(e, sort, field);
        }}
      >
        <span className="k-column-title k-text-uppercase">{t(title)}</span>
        {sortable && dir === "asc" && <ArrowUpwardIcon />}
        {sortable && dir === "desc" && <ArrowDownwardIcon />}
      </Box>
    </span>
  );

  return render ? render(thElement, props) : thElement;
};

TranslateTh.propTypes = {
  columns: PropTypes.array,
  render: PropTypes.func,
  title: PropTypes.string,
  field: PropTypes.string,
  sort: PropTypes.array,
  sortable: PropTypes.bool,
  sortChange: PropTypes.func,
};

export const TextTd = (props) => {
  if (props?.dataItem?.isFooter && !props.dataItem[props.field]) return <td className="k-table-td" />;

  const tdElement = (
    <td
      className={"k-table-td " + (props?.className ? ` ${props.className}` : "")}
      style={props?.style}
      onClick={!props?.dataItem?.isFooter ? props.selectionChange : null}
    >
      {props?.expandable &&
        props?.level?.map((_, index) => (
          <ExpandButton
            key={`button-${props?.level?.slice(0, index)?.join("-")}`}
            expanded={props?.expanded}
            hidden={index !== props?.level?.length - 1 || !props?.hasChildren || props?.dataItem?.excluded}
            onClick={(event) => !props?.dataItem?.excluded && props.onExpandChange(event, props.dataItem, props.level)}
          />
        ))}
      {props.dataItem[props.field]}
    </td>
  );
  return props.render ? props.render(tdElement, props) : tdElement;
};

TextTd.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
  render: PropTypes.func,
  className: PropTypes.string,
  selectionChange: PropTypes.func,
  onExpandChange: PropTypes.func,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  level: PropTypes.number,
  hasChildren: PropTypes.bool,
  style: PropTypes.object,
};

export const NumericTd = (props) => {
  if (props?.dataItem?.isFooter && !props.dataItem[props.field]) return <td className="k-table-td" />;

  const tdElement = (
    <td
      className={"k-table-td numeric-td" + (props?.className ? ` ${props.className}` : "")}
      onClick={!props?.dataItem?.isFooter ? props?.selectionChange : null}
    >
      {roundDecimal(props?.dataItem?.[props?.field], 100)}
    </td>
  );
  return props?.render ? props.render(tdElement, props) : tdElement;
};

NumericTd.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
  render: PropTypes.func,
  className: PropTypes.string,
  selectionChange: PropTypes.func,
};

export const PercentTd = (props) => {
  if (props?.dataItem?.isFooter && !props.dataItem[props.field]) return <td className="k-table-td" />;

  const tdElement = (
    <td
      className={"k-table-td numeric-td" + (props?.className ? ` ${props.className}` : "")}
      onClick={!props?.dataItem?.isFooter ? props?.selectionChange : null}
    >
      {roundDecimal(props?.dataItem?.[props?.field], 100)} %
    </td>
  );
  return props?.render ? props.render(tdElement, props) : tdElement;
};

PercentTd.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
  render: PropTypes.func,
  className: PropTypes.string,
  selectionChange: PropTypes.func,
};

const getOperatorValues = (isBackend, backendValues, frontendValues) => (isBackend ? backendValues : frontendValues);

export const OperatorSelector = (props) => {
  const { t, i18n } = useTranslation();
  const data = useMemo(
    () =>
      (props?.type === "numeric"
        ? getOperatorValues(props?.isBackend, numericOperatorValuesBackend, numericOperatorValues)
        : getOperatorValues(props?.isBackend, textOperatorValuesBackend, textOperatorValues)
      ).map((operator) => ({
        ...operator,
        text: t(operator.text),
      })),
    [i18n.language]
  );

  return (
    <div className="k-filtercell-operator">
      <DropDownList
        ref={props?.ref}
        defaultValue={data[0]}
        data={data}
        value={props?.value}
        textField="text"
        dataItemKey="value"
        className="k-dropdown-operator"
        svgIcon={svgIcons.filterIcon}
        popupSettings={{ width: "auto" }}
        onChange={props.onChange}
      />
    </div>
  );
};

OperatorSelector.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  ref: PropTypes.object,
  value: PropTypes.object,
  isBackend: PropTypes.bool,
};

export const FilterTh = ({ className, children }) => (
  <div className={"k-filtercell k-cell-inner " + className}>
    <div className="filter-padding k-filtercell k-flex-1 k-gap-1">{children}</div>
  </div>
);

FilterTh.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
};

export const MultiSelectFilter = (props) => {
  const { field, onFilterChange, logic = "or", data = [], className = "" } = props;
  const [localFilter, setLocalFilter] = useState({
    field,
    filters: [],
    logic,
    multiSelectValue: [],
  });

  useEffect(() => {
    const filter = [...props.filter];
    const currentFilterIndex = filter.findIndex((f) => f.field === field);
    if (currentFilterIndex !== -1) filter.splice(currentFilterIndex, 1);
    if (localFilter.filters.length) filter.push({ ...localFilter });

    onFilterChange({
      filter,
      field,
    });
  }, [localFilter.filters]);

  useEffect(() => {
    if (!props?.value || localFilter.multiSelectValue?.length === props?.value?.length) return;
    setLocalFilter((prev) => ({
      ...prev,
      multiSelectValue: props?.value,
    }));
  }, [props?.value]);

  const onValueChange = (event) =>
    setLocalFilter((prev) => ({
      ...prev,
      filters: event.value.map((v) => v.filterValue).reduce((acc, value) => [...acc, ...value], []),
      multiSelectValue: event.value,
    }));

  const selected = localFilter.multiSelectValue.length;

  return (
    <FilterTh className={className}>
      <MultiSelect
        fillMode="flat"
        data={data}
        textField="text"
        dataItemKey="filterValue"
        popupSettings={{ width: "auto", className: "multi-select-filter-popup" }}
        onChange={onValueChange}
        tags={selected ? [{ text: `${selected}`, data: localFilter.multiSelectValue }] : []}
        itemRender={props.itemRender}
        value={localFilter?.multiSelectValue}
      />
    </FilterTh>
  );
};

MultiSelectFilter.propTypes = {
  field: PropTypes.string,
  onFilterChange: PropTypes.func,
  className: PropTypes.string,
  logic: PropTypes.string,
  filter: PropTypes.array,
  data: PropTypes.array,
  itemRender: PropTypes.func,
  value: PropTypes.array,
};

export const SPTBackendTextFilter = (props) => <SPTTextFilter {...props} isBackend />;

export const SPTTextFilter = (props) => {
  const { field, onFilterChange, filter, className = "" } = props;
  const operatorRef = useRef(textOperatorValues[0]);

  const filterValue = filter.find((f) => f.field === field);
  const value = filterValue?.value ?? "";
  if (filterValue?.operator) operatorRef.current = textOperatorValues.find((o) => o.value === filterValue?.operator);

  const onChange = (filterField, filterValue) => {
    const filter = [...props.filter];
    const currentFilterIndex = filter.findIndex((f) => f.field === field);
    const newFilter = { field, operator: operatorRef.current.value, value, [filterField]: filterValue };

    if (currentFilterIndex !== -1) filter.splice(currentFilterIndex, 1);
    if (newFilter.value || textOperatorsValueNotRequired.includes(newFilter.operator)) filter.push({ ...newFilter });

    onFilterChange({
      filter,
      field,
      syntheticEvent: filter.syntheticEvent,
    });
  };

  const onOperatorChange = useEvent(({ value }) => {
    operatorRef.current = value;
    onChange("operator", operatorRef.current.value);
  });

  return (
    <FilterTh className={className}>
      <TextBox fillMode="flat" value={value} onChange={({ value }) => onChange("value", value)} />
      <OperatorSelector value={operatorRef.current} onChange={onOperatorChange} isBackend={props?.isBackend} />
    </FilterTh>
  );
};

SPTTextFilter.propTypes = {
  field: PropTypes.string,
  onFilterChange: PropTypes.func,
  className: PropTypes.string,
  filter: PropTypes.array,
  isBackend: PropTypes.bool,
};

export const SPTBackendNumericFilter = (props) => <SPTNumericFilter {...props} isBackend />;

export const SPTNumericFilter = (props) => {
  const { field, onFilterChange, filter, className = "" } = props;
  const operatorRef = useRef(numericOperatorValues[0]);

  const filterValue = filter.find((f) => f.field === field);
  const value = filterValue?.value ?? "";

  const onChange = (filterField, filterValue) => {
    const filter = [...props.filter];
    const currentFilterIndex = filter.findIndex((f) => f.field === field);
    const newFilter = { field, operator: operatorRef.current.value, value, [filterField]: filterValue };

    if (currentFilterIndex !== -1) filter.splice(currentFilterIndex, 1);
    if (newFilter.value || numericOperatorsValueNotRequired.includes(newFilter.operator)) filter.push({ ...newFilter });

    onFilterChange({
      filter,
      field,
      syntheticEvent: filter.syntheticEvent,
    });
  };

  const onOperatorChange = useEvent(({ value }) => {
    operatorRef.current = value;
    onChange("operator", operatorRef.current.value);
  });

  return (
    <FilterTh className={className}>
      <NumericTextBox
        spinners={false}
        step={0}
        value={value}
        fillMode="flat"
        onChange={({ value }) => onChange("value", value)}
        inputStyle={{ textAlign: "right" }}
      />
      <OperatorSelector
        type="numeric"
        value={operatorRef.current}
        onChange={onOperatorChange}
        isBackend={props?.isBackend}
      />
    </FilterTh>
  );
};

SPTNumericFilter.propTypes = {
  field: PropTypes.string,
  onFilterChange: PropTypes.func,
  className: PropTypes.string,
  filter: PropTypes.array,
  isBackend: PropTypes.bool,
};

export const TreeListPager = (props) => (
  <Pager {...props} previousNext={true} buttonCount={8} pageSizes={[10, 25, 50]} />
);
