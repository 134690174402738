import { Configuration, KpiSystemEndpointApi } from "../generated-api";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { createTablePageLogic } from "./tablePageLogic";
import { uploadFile } from "./fileUpload";

const kpiSystemEndpointApi = new KpiSystemEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const kpiSystemsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiSystemsResponse = hookFromSubject(kpiSystemsSubject);
export const getKpiSystems = (status) =>
  promiseToSubject(kpiSystemEndpointApi.apiKpiSystemListGet({status}), kpiSystemsSubject);

export const kpiSystemCreateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiSystemCreateResponse = hookFromSubject(kpiSystemCreateSubject);
export const createKpiSystem = (request) =>
  promiseToSubject(kpiSystemEndpointApi.apiKpiSystemPost({ request }), kpiSystemCreateSubject);

export const kpiSystemsDeleteSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiSystemsDeleteResponse = hookFromSubject(kpiSystemsDeleteSubject);
export const deleteKpiSystem = (id) =>
  promiseToSubject(kpiSystemEndpointApi.apiKpiSystemIdDelete({ id }), kpiSystemsDeleteSubject);



export const kpiSystemCloneSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiSystemCloneResponse = hookFromSubject(kpiSystemCloneSubject);
export const cloneKpiSystem = (id) =>
  promiseToSubject(kpiSystemEndpointApi.apiKpiSystemIdClonePost({ id }), kpiSystemCloneSubject);

export const kpiSystemSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiSystemResponse = hookFromSubject(kpiSystemSubject);
export const getKpiSystem = (id) =>
  promiseToSubject(kpiSystemEndpointApi.apiKpiSystemIdGet({ id }), kpiSystemSubject);

export const kpiSystemUpdateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiSystemUpdateResponse = hookFromSubject(kpiSystemUpdateSubject);
export const updateKpiSystem = (id, request) =>
  promiseToSubject(kpiSystemEndpointApi.apiKpiSystemPut({ request: { ...request, id } }), kpiSystemUpdateSubject);

export const kpiSystemRevisionsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiSystemRevisionsResponse = hookFromSubject(kpiSystemRevisionsSubject);
export const getKpiSystemRevisions = (id) =>
  promiseToSubject(kpiSystemEndpointApi.apiKpiSystemIdRevisionsGet({ id }), kpiSystemRevisionsSubject);

export const kpiSystemRevisionByIdSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiSystemRevisionByIdResponse = hookFromSubject(kpiSystemRevisionByIdSubject);
export const getKpiSystemRevisionById = (id, revisionId) =>
  promiseToSubject(kpiSystemEndpointApi.apiKpiSystemIdRevisionRevisionIdGet({
    id,
    revisionId
  }), kpiSystemRevisionByIdSubject);

export const kpiSystemMigrationSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiSystemMigrationResponse = hookFromSubject(kpiSystemMigrationSubject);
export const migrateKpiSystem = (id, tenantId) =>
  promiseToSubject(kpiSystemEndpointApi.apiKpiSystemIdMigrationExecuteTenantIdPost({id, tenantId}), kpiSystemMigrationSubject);

kpiSystemUpdateSubject.subscribe(event => {
  if (!event.loading && !event.error) {
    kpiSystemSubject.next(event);
  }
});

export const uploadKPIFile = (file) => uploadFile(file, '/api/kpi/system/file/temporary');

export const kpiFilesSubject = new BehaviorSubject([]);
export const useKPIFiles = hookFromSubject(kpiFilesSubject);
export const setKPIFiles = (files = []) => kpiFilesSubject.next(files);
export const addKPIFile = file => kpiFilesSubject.next([...kpiFilesSubject.getValue(), file]);
export const removeKPIFile = fileIndex => kpiFilesSubject.next(
  kpiFilesSubject.getValue().filter((item, index) => index !== fileIndex)
);

export const [useKpiSystemsPageLogic, resetKpiSystemsPageState] = createTablePageLogic();
