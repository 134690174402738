import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import { Button } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PERMISSION_TEXTBLOCK_READ } from "../../../constants/permissions";
import { hasPermission } from "../../../helpers/permission";
import { showMuiDialog } from "../../../hooks/dialog";
import { useEvent } from "../../../hooks/utils/useEvent";
import { TextBlockSelectDialog } from "../../TextBlock/TextBlockSelectDialog";
import "./EvaluationReason.scss";

import { v4 as getUUID } from "uuid";
import { RichText } from "../../RichText/RichText";

import PropTypes from "prop-types";

export const EvaluationReason = ({ value, onChange, onBlur, readOnly, ratingSystemId, kpiRatingSystemId, itemUid }) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState(value ?? "");
  const richTextRef = useRef();

  useEffect(() => {
    setReason(value ?? "");
    onBlur();
  }, [value]);

  const onSelect = useEvent((item) => {
    const content = richTextRef.current?.updateContent(reason + item.text);
    onBlur?.(content);
  });

  const editorUid = useMemo(() => getUUID(), [itemUid]);

  const showTextBlockSelectDialog = () =>
    showMuiDialog((props) => (
      <TextBlockSelectDialog
        onSelect={onSelect}
        ratingSystemId={ratingSystemId}
        kpiRatingSystemId={kpiRatingSystemId}
        {...props}
      />
    ));

  return (
    <div className="evaluation-reason">
      {!readOnly && (
        <RichText
          onChange={onChange}
          content={reason}
          onLoading={() => null}
          disableImageUpload={true}
          uid={editorUid}
          editorHeight={200}
          onBlur={onBlur}
          ref={richTextRef}
        />
      )}

      {!readOnly && hasPermission(PERMISSION_TEXTBLOCK_READ) && (
        <Button size="small" className="k-mt-2" onClick={showTextBlockSelectDialog}>
          <SubjectOutlinedIcon />
          {t("ratingSystem.reason.fromBlocks")}
        </Button>
      )}
    </div>
  );
};

EvaluationReason.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  ratingSystemId: PropTypes.string,
  kpiRatingSystemId: PropTypes.string,
  itemUid: PropTypes.string,
};
