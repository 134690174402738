import React from "react";
import { hideMuiDialog, useMuiDialogs } from "../../hooks/dialog";

export const MuiDialogs = React.memo(() => {

  const dialogs = useMuiDialogs();

  return (
    <div className="mui-dialogs">
      {dialogs.map((item, index) => {
        if (!item) {
          return null;
        }
        return (
          <div key={index}>
            {item?.component({ open: item.open, onClose: () => hideMuiDialog(index) })}
          </div>
        );
      })}
    </div>
  );
});
