import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getBreadcrumbPathParts } from "../../../helpers/dashboard";
import { useWindowSize } from "../../../hooks";
import { setMaxPercentsVisible, sunburstDataSubject } from "../../../hooks/dashboard";
import { setDashboardSelectedGrouping, useDashboardSelectedGrouping } from "../../../hooks/project";
import { findRecursive, roundDecimal } from "../../../utils";
import { Breadcrumb } from "../../Breadcrumb/Breadcrumb";
import { GroupingElementBarChartPAST } from "../GroupingElementBarChart/GroupingElementBarChartPAST";
import { GroupingElementChartPAST } from "../GroupingElementChart/GroupingElementChartPAST";
import "./GroupingElementsTab.scss";

const BAR_CHART_WIDTH = 142;
const BAR_CHART_BETWEEN = 78;

const mapItemPAST = (item) => {
  const universalScore = roundDecimal(item.universalScore);
  const evaluationSystemScore = roundDecimal(item.evaluationSystemScore);
  return {
    systemReference: item.systemReference,
    universalScore: universalScore,
    evaluationSystemScore: evaluationSystemScore,
    type: item.type,
    title: item.name,
    uuid: item.uuid,
    proportion: item.maxSystemScoreProportion,
  };
};

export const GroupingElementsTabPAST = ({ groupingElements }) => {
  const { t } = useTranslation();
  const selectedGrouping = useDashboardSelectedGrouping();

  const groupRef = useRef(null);
  const windowSize = useWindowSize();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (groupRef?.current?.offsetWidth && width !== groupRef.current.offsetWidth) {
      setWidth(groupRef.current.offsetWidth);
    }
  }, [groupRef?.current?.offsetWidth, windowSize?.width]);

  useEffect(() => {
    setMaxPercentsVisible(false);
  }, []);

  const onClick = useCallback((uuid) => {
    if (uuid) {
      const found = findRecursive(sunburstDataSubject.getValue(), (node) => node.data.uuid === uuid);
      setDashboardSelectedGrouping(found);
    } else {
      setDashboardSelectedGrouping(null);
    }
  }, []);

  const processed = useMemo(() => {
    const items = selectedGrouping ? [] : groupingElements.map(mapItemPAST);
    if (selectedGrouping) {
      const node = findRecursive(
        groupingElements,
        (nodeProcessed) => {
          return nodeProcessed.uuid === selectedGrouping.data.uuid;
        },
        "groupingElements"
      );
      if (node && node.groupingElements) {
        node.groupingElements.forEach((item) => {
          items.push(mapItemPAST(item));
        });
      }
      if (node && node.indicatorElements) {
        node.indicatorElements.forEach((item) => {
          items.push(mapItemPAST(item));
        });
      }
    }
    return items;
  }, [groupingElements, selectedGrouping]);

  const groupsNumber = useMemo(() => {
    const items = selectedGrouping ? [] : groupingElements.map(mapItemPAST);
    if (selectedGrouping) {
      const node = findRecursive(
        groupingElements,
        (nodeGroupsNumber) => {
          return nodeGroupsNumber.uuid === selectedGrouping.data.uuid;
        },
        "groupingElements"
      );
      if (node && node.groupingElements) {
        node.groupingElements.forEach((item) => {
          items.push(mapItemPAST(item));
        });
      }
    }
    return items;
  }, [groupingElements, selectedGrouping]);

  let maxProportion = 0;
  processed.forEach((item) => {
    if (maxProportion < item.proportion) {
      maxProportion = item.proportion;
    }
  });

  const pathParts = useMemo(
    () => getBreadcrumbPathParts(selectedGrouping, t("dashboard.pastUniversalScore")),
    [selectedGrouping]
  );
  const availableWidth = width - BAR_CHART_WIDTH;
  const amountPerRow = Math.floor(availableWidth / (BAR_CHART_WIDTH + BAR_CHART_BETWEEN)) + 1;

  return (
    <div className="grouping-elements-tab" ref={groupRef}>
      <Breadcrumb pathParts={pathParts} />
      {!!selectedGrouping && (
        <div className="grouping-charts">
          {processed.map((item) => (
            <div
              key={item.uuid}
              className={groupsNumber.length > 0 ? "cursor-pointer" : ""}
              onClick={() => groupsNumber.length > 0 && onClick(item.uuid)}
            >
              <GroupingElementChartPAST key={item.uuid} data={item} />
            </div>
          ))}
        </div>
      )}
      {!selectedGrouping && (
        <div className="grouping-bar-charts">
          {amountPerRow > 0 &&
            processed.map((item, index) => {
              const nth = index + 1;
              const isFirst = nth === 1 || amountPerRow === 1 || nth % amountPerRow === 1;
              const isBreak = nth % amountPerRow === 0 || nth === processed.length;
              return (
                <React.Fragment key={item.uuid}>
                  <GroupingElementBarChartPAST
                    data={item}
                    isFirst={isFirst}
                    isBreak={isBreak}
                    maxProportion={maxProportion}
                    onClick={onClick}
                  />
                  {!!isBreak && <div className="row-break" />}
                </React.Fragment>
              );
            })}
        </div>
      )}
    </div>
  );
};
