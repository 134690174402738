import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { forEachRecursive, roundDecimal } from "../../../utils";
import { CollapsiblePanel } from "../../CollapsiblePanel/CollapsiblePanel";
import { FormController } from "../../FormController/FormController";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Button from "@mui/material/Button";
import { loadMessages } from "@progress/kendo-react-intl";
import i18next from "i18next";
import { PromiseSubjectState } from "react-rxjs-easy";
import { IMAGE_UPLOAD_RESTRICTIONS } from "../../../constants/fileUpload";
import { HUNDRED } from "../../../constants/main";
import { ACCEPT_IMAGE_FILE_TYPES } from "../../../constants/project";
import { GROUPING_TYPE_CRITERION } from "../../../constants/sustainabilitySystem";
import {
  getRestrictedFileExtension,
  getRestrictedFileSize,
  validateFileRestrictions,
} from "../../../helpers/fileUpload";
import { indicatorActionsListSubject } from "../../../hooks/action";
import { showDialog } from "../../../hooks/dialog";
import { useUploadingFiles } from "../../../hooks/fileUpload";
import { uploadProjectPicture, uploadProjectPictureKey } from "../../../hooks/project";
import { showError } from "../../../hooks/toast";
import { AddActionsDialog } from "../../AddActionsDialog/AddActionsDialog";
import { ImageBlocks } from "../../ImageBlocks/ImageBlocks";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { ActionsBlock } from "../IndicatorElementDockBlocks/ActionsBlock";
import { TopTopicsActions } from "../TopTopicsActions/TopTopicsActions";
import { TopTopicsActionsDialog } from "../TopTopicsActions/TopTopicsActionsDialog";
import kendoDeMessages from "./../../../locales/kendo-de.json";
import kendoEnMessages from "./../../../locales/kendo-en.json";

loadMessages(kendoEnMessages, "en");
loadMessages(kendoDeMessages, "de");

export const GroupingElementDockPAST = ({ rows, data, readOnly, onChange, ratingSystemId, projectId }) => {
  const { t } = useTranslation();
  const uploadInputRef = useRef(null);
  const uploadingFiles = useUploadingFiles().filter((item) => item.key === uploadProjectPictureKey);
  const [images, setImages] = useState([]);
  const [fileValidationError, setFileValidationError] = useState(false);
  const messages = i18next.language === "de" ? kendoDeMessages : kendoEnMessages;
  const [editingName, setEditingName] = useState(false);
  const [customNameValue, setCustomNameValue] = useState("");

  const { name, customName, maxSystemScoreProportion, koValue, universalScore, weightingFactor, UID, summary } =
    data || {};

  const updateImages = useCallback(
    (imagesUpdate) => {
      setImages(imagesUpdate);
      const updated = [...rows];
      forEachRecursive(updated, (node) => {
        if (node.UID === UID) {
          node.fileIds = imagesUpdate;
          return true;
        }
      });
      onChange(updated);
    },
    [images, UID, rows]
  );

  useEffect(() => {
    setImages(data.fileIds || []);
  }, [data]);

  const uploadImage = (file) =>
    uploadProjectPicture(projectId, file)
      .then((response) => {
        const validationError = validateFileRestrictions(file, IMAGE_UPLOAD_RESTRICTIONS);
        setFileValidationError(!!validationError);
        if (!validationError) {
          updateImages([...images, response.id]);
        } else {
          showError(messages[`upload.${validationError}`]);
        }
      })
      .catch((error) => {
        console.error(error);
        showError(t("error.500"));
      });

  const onImageSelect = (event) => {
    [...event.target.files].forEach((file) => uploadImage(file));
    uploadInputRef.current.value = "";
  };

  const showAddActionsDialog = (row, passThrough) => {
    const { readOnly: readOnlyShowAddAction } = passThrough || {};
    showDialog({
      className: "xlarge",
      closeOnClickOutside: !!readOnlyShowAddAction,
      getContent: (onClose) => (
        <AddActionsDialog
          row={row}
          onClose={onClose}
          readOnly={readOnlyShowAddAction}
          onChange={onActionsChange}
          grouping={rows}
          changeGrouping={onChange}
        />
      ),
    });
  };

  const showAddTopTopicsAndAction = (row, passThrough) => {
    const { readOnly: readOnlyShowAddTopTopics } = passThrough || {};

    showDialog({
      className: "top-topics-actions-dialog",
      closeOnClickOutside: !!readOnlyShowAddTopTopics,
      getContent: (onClose) => (
        <TopTopicsActionsDialog
          row={row}
          onClose={onClose}
          readOnly={readOnlyShowAddTopTopics}
          onChange={onTopicsActionsChange}
        />
      ),
    });
  };

  const onTopicsActionsChange = ({ UID: UIDOnTopics, topTopicsAndActions }) => {
    const updated = [...rows];
    forEachRecursive(updated, (node) => {
      if (node.UID === UIDOnTopics) {
        node.topTopicsAndActions = topTopicsAndActions;
        return true;
      }
    });
    onChange(updated);
  };

  const onActionsChange = ({ UID: UIDonActionsChange, actions = [] }) => {
    const updated = [...rows];
    forEachRecursive(updated, (node) => {
      if (node.UID === UIDonActionsChange) {
        node.actions = actions;
        return true;
      }
    });
    onChange(updated);
    indicatorActionsListSubject.next(new PromiseSubjectState(actions));
  };

  useEffect(() => {
    reset({
      weightingFactor: weightingFactor ?? "",
      summary: summary ?? "",
    });

    setCustomNameValue(customName ?? name);
  }, [UID]);

  const { control, getValues, reset } = useForm({
    defaultValues: { weightingFactor: "", summary: "" },
  });

  const onCustomNameChange = ({ customName: customNameChange }) => {
    const updated = [...rows];

    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        node.customName = customNameChange ?? customNameValue;
        return true;
      }
    });

    onChange(updated);
    setEditingName(false);
  };

  const onWeightingChange = () => {
    const updated = [...rows];
    const { weightingFactorChange } = getValues();

    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        node.weightingFactor = +weightingFactorChange;
        return true;
      }
    });
    onChange(updated);
  };

  const getImagePath = useCallback(
    (fileId) => `/api/rating/system/${ratingSystemId}/file/${fileId}/preview`,
    [ratingSystemId]
  );

  const onCustomNameKeyUp = ({ key, target }) => {
    if (key.toLowerCase() === "enter") {
      setCustomNameValue(target.value);
      onCustomNameChange({ customName: target.value });
    }
  };

  const onCustomNameEditingToggle = () => {
    setEditingName(!editingName);
    customName && setCustomNameValue(customName);
  };

  const onCustomNameDefaultClick = () => {
    setCustomNameValue(name);

    if (!editingName) {
      onCustomNameChange({ customName: name });
    }
  };

  return (
    <div className="grouping-element-dock">
      <div className="data-block white-block">
        <h3 className="grouping-element-name">
          {!editingName && (customName ?? name)}
          {!readOnly && editingName && (
            <TextField
              className="edit-name-input"
              value={customNameValue}
              onChange={({ target }) => setCustomNameValue(target.value)}
              onKeyUp={onCustomNameKeyUp}
            />
          )}
          {!readOnly && (
            <IconButton size="small" className="edit-name-button" onClick={onCustomNameEditingToggle}>
              {!editingName && <EditIcon />}
              {editingName && <CloseIcon />}
            </IconButton>
          )}
          {!readOnly && customName && customName !== name && (
            <IconButton size="small" onClick={onCustomNameDefaultClick}>
              <RefreshIcon className="horizontal-flip" />
            </IconButton>
          )}
          {!readOnly && editingName && (
            <IconButton size="small" color="primary" onClick={onCustomNameChange}>
              <CheckIcon />
            </IconButton>
          )}
        </h3>
      </div>
      <div className="data-block gray-block">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.metrics")}</h3>}>
          <table>
            <tbody>
              <tr className="title-row">
                <td>
                  <label>{t("ratingSystem.indicator.pastMaxSystemScoreProportion")}</label>
                </td>
                <td>
                  <label>{t("grouping.koValue")}</label>
                </td>
              </tr>
              <tr className="data-row">
                <td>
                  <span>{roundDecimal(maxSystemScoreProportion, HUNDRED)}%</span>
                </td>
                <td>
                  <span>{roundDecimal(koValue, HUNDRED) || ""}</span>
                </td>
              </tr>
              <tr className="title-row">
                <td>
                  <label>{t("ratingSystem.indicator.pastUniversalScore")}</label>
                </td>
              </tr>
              <tr className="data-row">
                <td>
                  <span>{roundDecimal(universalScore, HUNDRED)}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsiblePanel>
      </div>

      <div className="data-block current">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.currentPAST")}</h3>}>
          <div className="small-padding-bottom">
            <FormController
              control={control}
              name="weightingFactor"
              label={t("grouping.weight")}
              render={({ field, fieldState, label }) => (
                <TextField
                  error={fieldState.invalid}
                  type="number"
                  fullWidth
                  disabled={readOnly}
                  inputProps={{ step: 0.0001 }}
                  label={label}
                  {...field}
                  onBlur={() => onWeightingChange()}
                />
              )}
            />
          </div>
        </CollapsiblePanel>
      </div>
      {data.type === GROUPING_TYPE_CRITERION && (
        <div className="data-block">
          <CollapsiblePanel
            header={
              <h3>
                {t("ratingSystem.columnGroup.topThemenActionen")}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    showAddTopTopicsAndAction(data, { readOnly });
                  }}
                  size="small"
                >
                  <DescriptionOutlinedIcon />
                </IconButton>
              </h3>
            }
          >
            <TopTopicsActions topTopicsAndActions={data?.topTopicsAndActions} />
          </CollapsiblePanel>
        </div>
      )}

      {data.type === GROUPING_TYPE_CRITERION && (
        <div className="data-block">
          <CollapsiblePanel
            header={
              <h3>
                {t("ratingSystem.columnGroup.actions")}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    showAddActionsDialog(data, { readOnly });
                  }}
                  size="small"
                >
                  <DescriptionOutlinedIcon />
                </IconButton>
              </h3>
            }
          >
            <ActionsBlock row={data} readOnly={readOnly} onChange={onActionsChange} />
          </CollapsiblePanel>
        </div>
      )}

      {data.type === GROUPING_TYPE_CRITERION && (
        <LoadingOverlay active={!!uploadingFiles.length} spinner className="data-block auto-height">
          <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.images")}</h3>}>
            <ImageBlocks images={images} onChange={updateImages} getImagePath={getImagePath} readOnly={readOnly} />

            {!readOnly && (
              <div className="images-footer">
                <input
                  ref={uploadInputRef}
                  type="file"
                  color="primary"
                  accept={ACCEPT_IMAGE_FILE_TYPES.join(",")}
                  onChange={onImageSelect}
                  hidden
                  multiple
                />
                <label>
                  <Button
                    type="button"
                    size="small"
                    variant="contained"
                    color="primary"
                    className="gray"
                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                  >
                    {t("main.browse")}
                  </Button>
                </label>
                {fileValidationError && (
                  <div className="file-upload-restrictions">
                    <div>
                      <small>
                        {t("fileUpload.allowedExtensions")} {getRestrictedFileExtension(IMAGE_UPLOAD_RESTRICTIONS)}
                      </small>
                    </div>
                    <div>
                      <small>
                        {t("fileUpload.maximumFileSize")} {getRestrictedFileSize(IMAGE_UPLOAD_RESTRICTIONS)}
                      </small>
                    </div>
                  </div>
                )}
              </div>
            )}
          </CollapsiblePanel>
        </LoadingOverlay>
      )}
    </div>
  );
};
