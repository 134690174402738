import { Configuration, RoleEndpointApi } from "../generated-api";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { createTablePageLogic } from "./tablePageLogic";

const roleApi = new RoleEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const roleListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRoleListResponse = hookFromSubject(roleListSubject);
export const roleList = (tenantId) =>
  promiseToSubject(roleApi.apiSecurityRoleTenantTenantIdGet({ tenantId }), roleListSubject);

export const roleDeleteByIdSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRoleDeleteByIdResponse = hookFromSubject(roleDeleteByIdSubject);
export const roleDeleteById = (id) =>
  promiseToSubject(roleApi.apiSecurityRoleIdDelete({ id }), roleDeleteByIdSubject);

export const roleUpdateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRoleUpdateResponse = hookFromSubject(roleUpdateSubject);
export const roleUpdate = (id, roleDTO) =>
  promiseToSubject(roleApi.apiSecurityRolePut({ roleDTO: {...roleDTO, id} }), roleUpdateSubject);

export const roleCreateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRoleCreateResponse = hookFromSubject(roleCreateSubject);
export const roleCreate = (tenantId, roleDTO) =>
  promiseToSubject(roleApi.apiSecurityRoleTenantTenantIdPost({ roleDTO, tenantId }), roleCreateSubject);

export const [useRolesPageLogic, resetRolesPageState] = createTablePageLogic();
