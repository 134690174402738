import { useMsal } from "@azure/msal-react";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { v4 as getUUID } from "uuid";
import { TENANT_STATUS_ENABLED } from "../../constants/tenant";
import { handleLogout, useUser } from "../../hooks/auth";
import { setUserCurrentTenant } from "../../hooks/users";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import "./TenantSelect.scss";

export const TenantSelect = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUser();
  const availableTenants = user?.availableTenants?.filter((item) => item.status === TENANT_STATUS_ENABLED) || [];
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);

  const setUserTenant = (id) => {
    setUserCurrentTenant(id);
    setLoading(true);
    navigate("/");
    window.location.reload();
  };

  return (
    <LoadingOverlay spinner active={loading} className="tenant-select">
      <div className="middle-area">
        <h1>{!availableTenants.length ? t("tenantSelect.noTenantsTitle") : t("tenantSelect.title")}</h1>
        {availableTenants.map(({ companyName, id, name }) => {
          return (
            <Box key={getUUID()} className="tenant" onClick={() => setUserTenant(id)}>
              <div className="logo">
                <img src={`/api/security/whitelabel/logo/tenant/${id}/available`} alt="logo" />
              </div>
              <div>
                <h2>{companyName}</h2>
                <p>{name}</p>
              </div>
            </Box>
          );
        })}
        {!availableTenants.length && <p>{t("tenantSelect.noTenants")}</p>}
        <Button onClick={() => handleLogout(instance)}>{t("main.signOut")}</Button>
        {user?.tenant && <Button onClick={() => navigate(-1)}>{t("main.backToTenant")}</Button>}
      </div>
    </LoadingOverlay>
  );
};
