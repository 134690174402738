import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../../constants/main";
import {
  getProjectGroupStatisticKpi,
  resetProjectGroupStatisticKpiTooltip,
  setProjectGroupSelectedKpi,
  setProjectGroupStatisticKpiTooltip,
  useProjectGroupSelectedKpi,
  useProjectGroupStatisticKpiResponse,
  useProjectGroupStatisticKpiTooltip,
} from "../../../hooks/projectGroup";
import { usePagination } from "../../../hooks/utils/usePagination";
import { useTimeout } from "../../../hooks/utils/useTimeout";
import { processMessage, roundDecimal } from "../../../utils";
import { ContentBlock } from "../../ContentBlock/ContentBlock";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import { TooltipPopover } from "../../TooltipPopover/TooltipPopover";
import "./ProjectGroupAnalyticsKPI.scss";

const PopoverWrapper = () => {
  const { t } = useTranslation();
  const tooltipTarget = useProjectGroupStatisticKpiTooltip();

  return (
    <TooltipPopover
      content={<pre>{t("projectGroup.analytics.kpi.tooltip")}</pre>}
      target={tooltipTarget}
      open={!!tooltipTarget}
      onClose={resetProjectGroupStatisticKpiTooltip}
      className="text-tooltip"
    />
  );
};

export const ProjectGroupAnalyticsKPI = React.memo(({ projectGroupId }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [processed, setProcessed] = useState([]);
  const [paged, setPaged] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState({ field: "kpiName" });
  const [, setSearchTimeout] = useTimeout();
  const { data, loading } = useProjectGroupStatisticKpiResponse();
  const selectedKpi = useProjectGroupSelectedKpi();
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination(0, MIN_PAGE_SIZE);
  const total = processed.length;

  useEffect(() => {
    getProjectGroupStatisticKpi(projectGroupId).then().catch(console.error);
  }, [projectGroupId]);

  useEffect(() => {
    let updated = [...(data || [])];
    if (searchValue) {
      const lowCaseSearchValue = searchValue.toLowerCase();
      updated = updated.filter(
        ({ kpiName, kpiUnit, minValue, maxValue, avgValue }) =>
          String(kpiName).toLowerCase().indexOf(lowCaseSearchValue) !== -1 ||
          String(kpiUnit).toLowerCase().indexOf(lowCaseSearchValue) !== -1 ||
          +lowCaseSearchValue === minValue ||
          +lowCaseSearchValue === maxValue ||
          +lowCaseSearchValue === avgValue
      );
    }
    if (sortValue && sortValue.field) {
      updated.sort((a, b) => {
        if (a[sortValue.field] > b[sortValue.field]) {
          return sortValue.desc ? -1 : 1;
        }
        if (a[sortValue.field] < b[sortValue.field]) {
          return sortValue.desc ? 1 : -1;
        }
        return 0;
      });
    }
    setProcessed(updated);
    setPaged(rowsPerPage < updated.length ? [...updated].splice(page * rowsPerPage, rowsPerPage) : updated);
  }, [searchValue, sortValue, data, page, rowsPerPage]);

  const search = useCallback(
    (event) =>
      setSearchTimeout(
        setTimeout(() => {
          setSearchValue(String(event.target.value || "").trim());
        }, 500)
      ),
    []
  );

  const columns = useMemo(
    () => [
      {
        field: "kpiName",
        headerText: t("projectGroup.analytics.kpi.kpiName"),
        className: "name-column",
        template: ({ kpiName, kpiUnit }) => (
          <div className="cursor-pointer" onClick={() => setProjectGroupSelectedKpi({ kpiName, kpiUnit })}>
            {[kpiName, kpiUnit].join(" ")}
          </div>
        ),
        sortable: true,
      },
      {
        field: "ratingSystemCount",
        headerText: t("projectGroup.analytics.kpi.ratingSystemCount"),
        className: "number-column",
        sortable: true,
      },
      {
        field: "minValue",
        headerText: t("projectGroup.analytics.kpi.minValue"),
        className: "number-column",
        template: ({ minValue }) => roundDecimal(minValue, 100),
        sortable: true,
      },
      {
        field: "maxValue",
        headerText: t("projectGroup.analytics.kpi.maxValue"),
        className: "number-column",
        template: ({ maxValue }) => roundDecimal(maxValue, 100),
        sortable: true,
      },
      {
        field: "avgValue",
        headerText: t("projectGroup.analytics.kpi.avgValue"),
        className: "number-column",
        template: ({ avgValue, emptyValue }) =>
          !!avgValue && (
            <div className="flex-row">
              <div className="flex-auto">{roundDecimal(avgValue, 100)}</div>
              <div className="icon-button-wrapper">
                {!!emptyValue && (
                  <IconButton
                    size="small"
                    color="primary"
                    onMouseOver={(event) => setProjectGroupStatisticKpiTooltip(event.currentTarget)}
                    onMouseOut={resetProjectGroupStatisticKpiTooltip}
                  >
                    <InfoIcon />
                  </IconButton>
                )}
              </div>
            </div>
          ),
        sortable: true,
      },
    ],
    []
  );

  const onSort = (field) =>
    setSortValue({
      field: field !== sortValue.field || !sortValue.desc ? field : null,
      desc: sortValue.field === field ? !sortValue.desc : false,
    });

  const pagination = {
    labelRowsPerPage: t("pagination.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: total,
    page: page,
    show: total > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pageInner) => setPage(pageInner),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  return (
    <ContentBlock className="project-group-analytics-kpi">
      <LoadingOverlay spinner active={loading} className="auto-height">
        <div
          className="search-box"
          onClick={() => {
            inputRef.current.focus();
          }}
        >
          <SearchIcon />
          <input
            type="text"
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            ref={inputRef}
            placeholder={t("main.search")}
            onChange={search}
          />
        </div>
        <SimpleTableWithSort
          rowClassName={(row) =>
            row.kpiName === selectedKpi?.kpiName && row.kpiUnit === selectedKpi?.kpiUnit ? "selected-row" : ""
          }
          data={paged}
          columns={columns}
          onSort={onSort}
          sortValue={sortValue}
          pagination={pagination}
        />
      </LoadingOverlay>
      <PopoverWrapper />
    </ContentBlock>
  );
});
