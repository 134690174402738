import React from "react";
import {
  useColumnWidths,
  useDragColumn,
  useDragRow,
  useResizeColumn,
  useTableOffsetX,
  useTableScrollX,
  useTableScrollY
} from "../../hooks/advancedTable";

export const AdvancedTableDragOverlay = ({ onPointerMove, onPointerEnd, getColumnX, className }) => {
  const tableScrollX = useTableScrollX();
  const tableScrollY = useTableScrollY();
  const tableOffsetX = useTableOffsetX();
  const dragColumn = useDragColumn();
  const dragRow = useDragRow();
  const resizeColumn = useResizeColumn();
  const columnWidths = useColumnWidths();

  return (
    <div className={'drag-overlay' + (className ? ' ' + className : '')}
         onMouseMove={onPointerMove} onMouseUp={onPointerEnd} hidden={!dragColumn && !dragRow && !resizeColumn}>
      {!!dragColumn && <>
        <div className={'header-ghost'}
             style={{
               left: (dragColumn.currentX - dragColumn.offsetX - tableScrollX) + 'px',
               top: dragColumn.currentY + 'px',
               width: columnWidths[dragColumn.startOrder] + 'px'
             }}>
          {dragColumn.column.headerText || ''}
        </div>
        <div className='header-landing'
             style={{
               left: getColumnX(dragColumn.order, dragColumn.column.locked) + 'px',
               top: dragColumn.currentY + 'px',
               width: columnWidths[dragColumn.order] + 'px'
             }}
        />
      </>}
      {!!dragRow &&
      <div className={'row-ghost'}
           style={{
             top: dragRow.currentY - tableScrollY + 'px',
             left: tableOffsetX + tableScrollX + 'px',
             width: dragRow.width + 'px',
             height: dragRow.height + 'px'
           }}>
      </div>
      }
    </div>
  );
};
