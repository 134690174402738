import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React from "react";

export const ActionTextTemplate = ({ row, field, passThrough, maxLength, required, readOnly }) => {
  const { onValueChange, submitted } = passThrough || {};

  if (!row) {
    return null;
  }

  if (readOnly || (passThrough || {}).readOnly) {
    return row[field] || null;
  }

  return (
    <FormControl fullWidth>
      <TextField
        error={submitted && required && !row[field]}
        type={"text"}
        key={row.UID}
        inputProps={{ maxLength }}
        defaultValue={row[field] || ""}
        onBlur={(event) => onValueChange(row, field, event.target.value)}
      />
    </FormControl>
  );
};
