import { Configuration, ProjectEndpointApi, ProjectGroupEndpointApi } from "../generated-api";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { MIN_PAGE_SIZE } from "../constants/main";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { createTablePageLogic } from "./tablePageLogic";

const projectGroupSelectedKpiSubject = new BehaviorSubject(null);
export const useProjectGroupSelectedKpi = hookFromSubject(projectGroupSelectedKpiSubject);
export const setProjectGroupSelectedKpi = value => projectGroupSelectedKpiSubject.next(value);

const projectGroupApi = new ProjectGroupEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const projectGroupsPageSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupsPageResponse = hookFromSubject(projectGroupsPageSubject);
export const searchProjectGroups = ({ page, itemsPerPage, sort, name, projectGroupId }) => {
  const params = { page: page || 0, itemsPerPage: itemsPerPage || MIN_PAGE_SIZE };
  if (sort) {
    params.sort = sort;
  }
  if (name) {
    params.search = name;
  }
  if (projectGroupId) {
    params['excludeGroupId'] = 'in:' + projectGroupId;
  }
  return promiseToSubject(projectGroupApi.apiProjectGroupPageGet({ params }), projectGroupsPageSubject);
};

export const projectGroupSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupResponse = hookFromSubject(projectGroupSubject);
export const getProjectGroup = (id) =>
  promiseToSubject(projectGroupApi.apiProjectGroupIdGet({ id }), projectGroupSubject);

export const projectGroupStatisticKpiSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupStatisticKpiResponse = hookFromSubject(projectGroupStatisticKpiSubject);
export const getProjectGroupStatisticKpi = (parentGroupId) =>
  promiseToSubject(projectGroupApi.apiProjectGroupParentGroupIdStatisticKpiGet({ parentGroupId }), projectGroupStatisticKpiSubject);

export const projectGroupStatisticActionsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupStatisticActionsResponse = hookFromSubject(projectGroupStatisticActionsSubject);
export const getProjectGroupStatisticActions = (parentGroupId) =>
  promiseToSubject(projectGroupApi.apiProjectGroupParentGroupIdStatisticActionGet({ parentGroupId }), projectGroupStatisticActionsSubject);

export const projectGroupStatisticKpiTooltipSubject = new BehaviorSubject(null);
export const useProjectGroupStatisticKpiTooltip = hookFromSubject(projectGroupStatisticKpiTooltipSubject);
export const setProjectGroupStatisticKpiTooltip = value => projectGroupStatisticKpiTooltipSubject.next(value);
export const resetProjectGroupStatisticKpiTooltip = () => projectGroupStatisticKpiTooltipSubject.next(null);

export const projectSubGroupSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectSubGroupResponse = hookFromSubject(projectSubGroupSubject);
export const getProjectSubGroup = (id) =>
  promiseToSubject(projectGroupApi.apiProjectGroupIdGet({ id }), projectSubGroupSubject);

export const projectGroupCreateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupCreateResponse = hookFromSubject(projectGroupCreateSubject);
export const createProjectGroup = (item, parentId = null) => {
  if (parentId) {
    return promiseToSubject(projectGroupApi.apiProjectGroupParentIdAddPost({
      item,
      parentId
    }), projectGroupCreateSubject);
  } else {
    return promiseToSubject(projectGroupApi.apiProjectGroupPost({ item }), projectGroupCreateSubject);
  }
};

export const projectGroupUpdateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupUpdateResponse = hookFromSubject(projectGroupUpdateSubject);
export const updateProjectGroup = (id, item) =>
  promiseToSubject(projectGroupApi.apiProjectGroupPut({ item: { ...item, id } }), projectGroupUpdateSubject);

export const projectSubGroupUpdateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectSubGroupUpdateResponse = hookFromSubject(projectSubGroupUpdateSubject);
export const updateProjectSubGroup = (id, item) =>
  promiseToSubject(projectGroupApi.apiProjectGroupPut({ item: { ...item, id } }), projectSubGroupUpdateSubject);

export const projectGroupDeleteSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupDeleteResponse = hookFromSubject(projectGroupDeleteSubject);
export const deleteProjectGroup = (id) =>
  promiseToSubject(projectGroupApi.apiProjectGroupIdDelete({ id }), projectGroupDeleteSubject);

export const projectGroupChildrenSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupChildrenResponse = hookFromSubject(projectGroupChildrenSubject);
export const getProjectGroupChildren = (parentId) => {
  const promise = projectGroupApi.apiProjectGroupParentIdChildrenGet({ parentId });
  promise.abort = () => null;
  return promiseToSubject(promise, projectGroupChildrenSubject);
}


export const projectGroupParentSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupParentResponse = hookFromSubject(projectGroupParentSubject);
export const addProjectGroupToProjectGroup = (childId, parentId) =>
  promiseToSubject(projectGroupApi.apiProjectGroupParentIdAttachChildIdPut({
    childId,
    parentId
  }), projectGroupParentSubject);

export const projectGroupParentDeleteSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupParentDeleteResponse = hookFromSubject(projectGroupParentDeleteSubject);
export const removeProjectGroupFromProjectGroup = (childId, parentId) =>
  promiseToSubject(projectGroupApi.apiProjectGroupParentIdDetachChildIdPut({
    childId,
    parentId
  }), projectGroupParentDeleteSubject);

export const selectedProjectGroupChildrenSubject = new BehaviorSubject([]);
export const useSelectedProjectGroupChildren = hookFromSubject(selectedProjectGroupChildrenSubject);
export const setSelectedProjectGroupChildren = values => selectedProjectGroupChildrenSubject.next(values);

export const projectGroupHierarchySelectionSubject = new BehaviorSubject([]);
export const useProjectGroupHierarchySelection = hookFromSubject(projectGroupHierarchySelectionSubject);
export const setProjectGroupHierarchySelection = selection => projectGroupHierarchySelectionSubject.next(selection);
export const resetProjectGroupHierarchySelection = () => projectGroupHierarchySelectionSubject.next([]);

projectGroupUpdateSubject.subscribe(event => {
  if (!event.loading && !event.error) {
    projectGroupSubject.next(event);
  }
});

export const benchmarksLoadedForKpiSubject = new BehaviorSubject(null);
export const setBenchmarksLoadedForKpi = value => benchmarksLoadedForKpiSubject.next(value);
export const useBenchmarksLoadedForKpi = hookFromSubject(benchmarksLoadedForKpiSubject);

export const projectGroupBenchmarkChartSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupBenchmarkChartResponse = hookFromSubject(projectGroupBenchmarkChartSubject);
export const getProjectGroupBenchmarkChart = (groupId, benchmarkId) =>
  promiseToSubject(projectGroupApi.apiProjectGroupGroupIdChartBenchmarkBenchmarkIdGet({
    groupId,
    benchmarkId
  }), projectGroupBenchmarkChartSubject);

export const projectGroupProjectPutSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupProjectPutResponse = hookFromSubject(projectGroupProjectPutSubject);
export const putProjectGroupProject = (groupId, projectId) =>
  promiseToSubject(projectGroupApi.apiProjectGroupGroupIdAddProjectIdPut({
    groupId, projectId
  }), projectGroupProjectPutSubject);

export const projectGroupProjectRemoveSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupProjectRemoveResponse = hookFromSubject(projectGroupProjectRemoveSubject);
export const removeProjectGroupProject = (groupId, projectId) =>
  promiseToSubject(projectGroupApi.apiProjectGroupGroupIdRemoveProjectIdPut({
    groupId, projectId
  }), projectGroupProjectRemoveSubject);

export const projectGroupPathsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupPathsResponse = hookFromSubject(projectGroupPathsSubject);
export const getProjectGroupPaths = (selectedGroupId, projectId) =>
  promiseToSubject(projectGroupApi.apiProjectGroupPathsProjectIdGet({
    selectedGroupId, projectId
  }), projectGroupPathsSubject);

export const projectGroupSystemsCountSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupSystemsCountResponse = hookFromSubject(projectGroupSystemsCountSubject);
export const getProjectGroupSystemsCount = (parentGroupId) =>
  promiseToSubject(projectGroupApi.apiProjectGroupParentGroupIdSystemsCountGet({
    parentGroupId
  }), projectGroupSystemsCountSubject);

export const projectGroupStatisticChartKpiProjectsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupStatisticChartKpiProjectsResponse = hookFromSubject(projectGroupStatisticChartKpiProjectsSubject);
export const getProjectGroupStatisticChartKpiProjects = (parentGroupId, kpiName, kpiUnit, scoreType) =>
  promiseToSubject(projectGroupApi.apiProjectGroupParentGroupIdStatisticChartKpiProjectsGet({
    kpiName, kpiUnit, scoreType, parentGroupId
  }), projectGroupStatisticChartKpiProjectsSubject);

export const getProjectGroupStatisticChartKpiDetails = (groupId, kpiName, kpiUnit) =>
  projectGroupApi.apiProjectGroupGroupIdStatisticChartKpiDetailsGet({
    kpiName, kpiUnit, groupId
  });

export const projectGroupStatisticChartKpiVsKpiSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupStatisticChartKpiVsKpiResponse = hookFromSubject(projectGroupStatisticChartKpiVsKpiSubject);
export const getProjectGroupStatisticChartKpiVsKpi = (parentGroupId, kpiName, kpiUnit, counterKpiName, counterKpiUnit) =>
  promiseToSubject(projectGroupApi.apiProjectGroupParentGroupIdStatisticChartKpiPairGet({
    kpiName, kpiUnit, counterKpiName, counterKpiUnit, parentGroupId
  }), projectGroupStatisticChartKpiVsKpiSubject);

export const projectGroupProjectLocationsPopoverSubject = new BehaviorSubject(null);
export const useProjectGroupProjectLocationsPopover = hookFromSubject(projectGroupProjectLocationsPopoverSubject);
export const setProjectGroupProjectLocationsPopover = popover => projectGroupProjectLocationsPopoverSubject.next(popover);

export const projectGroupChartStructureSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupChartStructureResponse = hookFromSubject(projectGroupChartStructureSubject);
export const getProjectGroupChartStructure = (projectId, systemHash) =>
  promiseToSubject(projectGroupApi.apiProjectGroupChartStructureGet({
    projectId,
    systemHash
  }), projectGroupChartStructureSubject);

export const projectGroupChartProjectsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupChartProjectsResponse = hookFromSubject(projectGroupChartProjectsSubject);
export const getProjectGroupChartProjects = (groupId) =>
  promiseToSubject(projectGroupApi.apiProjectGroupGroupIdChartStructureProjectsGet({ groupId }), projectGroupChartProjectsSubject);

export const [useProjectsToAddLogic, resetProjectsToAddState] = createTablePageLogic();
export const [useProjectGroupsPageLogic, resetProjectGroupsPageState] = createTablePageLogic();

const projectApi = new ProjectEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const projectsToAddPageSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectsToAddPageResponse = hookFromSubject(projectsToAddPageSubject);
export const searchProjectsToAdd = ({
                                      page,
                                      itemsPerPage,
                                      name,
                                      projectTypes,
                                      ratingStatus,
                                      sustainabilitySystemId,
                                      id
                                    }) => {
  const params = { page: page || 0, itemsPerPage: itemsPerPage || MIN_PAGE_SIZE };
  if (name) {
    params.search = 'like:' + name;
  }
  if (projectTypes && projectTypes.length) {
    params.projectTypes = 'in:' + projectTypes.join(',');
  }
  if (ratingStatus && ratingStatus.length) {
    params['ratingSystems.ratingStatus'] = 'in:' + ratingStatus;
  }
  if (sustainabilitySystemId && sustainabilitySystemId.length) {
    params['ratingSystems.sustainabilitySystemId'] = 'in:' + sustainabilitySystemId.join(',');
  }
  params['excludeGroupId'] = 'in:' + id;
  return promiseToSubject(projectApi.apiProjectPageGet({ params }), projectsToAddPageSubject);
};


export const projectGroupPermissionsPutSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectGroupPermissionsPutResponse = hookFromSubject(projectGroupPermissionsPutSubject);
export const updateGroupProjectPermissions = (projectGroupId, projectGroupPermissions) =>
  promiseToSubject(projectGroupApi.apiProjectGroupProjectGroupIdPermissionsPut({ projectGroupId, projectGroupPermissions }), projectGroupPermissionsPutSubject);
