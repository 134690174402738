import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { SUPER_SCORE_TYPE, SYSTEM_SCORE_TYPE } from "../../../constants/ratingSystem";
import {
  setProjectGroupSelectedKpi,
  useProjectGroupSelectedKpi,
  useProjectGroupStatisticChartKpiProjectsResponse,
  useProjectGroupStatisticChartKpiVsKpiResponse,
  useProjectGroupStatisticKpiResponse,
} from "../../../hooks/projectGroup";
import { ContentBlock } from "../../ContentBlock/ContentBlock";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { KPIVsKPIChart } from "./KPIVsKPIChart";
import "./ProjectGroupAnalyticsChart.scss";
import { ProjectsVsKPIChart } from "./ProjectsVsKPIChart";

export const ProjectGroupAnalyticsChart = React.memo(({ projectGroupId, className }) => {
  const { t } = useTranslation();

  const [xValue, setXValue] = useState("");
  const [yValue, setYValue] = useState(null);
  const projectGroupStatisticKpiResponse = useProjectGroupStatisticKpiResponse();
  const data = projectGroupStatisticKpiResponse.data || [];
  const projectGroupStatisticChartKpiProjectsResponse = useProjectGroupStatisticChartKpiProjectsResponse();
  const projectGroupStatisticChartKpiVsKpiResponse = useProjectGroupStatisticChartKpiVsKpiResponse();
  const [kpis, setKpis] = useState([]);
  const selectedKpi = useProjectGroupSelectedKpi();
  const [nonKPIYAxisLabel, setNonKPIYAxisLabel] = useState(null);

  const preselectKpi = (kpisPresSelected) => {
    if (kpisPresSelected && kpisPresSelected.length) {
      selectYValue(kpisPresSelected[0].uuid, kpisPresSelected);
    }
  };

  useEffect(() => {
    if (!projectGroupStatisticKpiResponse.loading) {
      setXValue("");
      const kpisInner = data.map((item) => ({ ...item, uuid: getUUID() }));
      setKpis(kpisInner);
      selectYValue(superScoreYOption.value);
    }
  }, [projectGroupStatisticKpiResponse.loading, projectGroupId]);

  useEffect(() => {
    if (selectedKpi) {
      const kpi = kpis.find((item) => item.kpiName === selectedKpi.kpiName && item.kpiUnit === selectedKpi.kpiUnit);
      if (kpi && (!yValue || yValue !== kpi.uuid) && kpi.uuid !== xValue) {
        setYValue(kpi.uuid);
      }
    }
  }, [selectedKpi]);

  const selectYValue = (value, kpisSelectYValue) => {
    if (value < 0) {
      setYValue(value);
      setProjectGroupSelectedKpi(null);
      setNonKPIYAxisLabel(value === superScoreYOption.value ? superScoreYOption.label : systemScoreYOption.label);
    } else {
      const kpi = kpisSelectYValue?.find((item) => item.uuid === value);
      if (kpi) {
        setYValue(kpi.uuid);
        const { kpiName, kpiUnit } = kpi;
        setProjectGroupSelectedKpi({ kpiName, kpiUnit });
        setNonKPIYAxisLabel(null);
      }
    }
  };

  const selectXValue = (value, kpisSelectXValue) => {
    setXValue(value);
    if (value && yValue < 0) {
      preselectKpi(kpisSelectXValue.filter((item) => item.uuid !== value));
    }
  };

  const loading =
    projectGroupStatisticKpiResponse.loading ||
    projectGroupStatisticChartKpiProjectsResponse.loading ||
    projectGroupStatisticChartKpiVsKpiResponse.loading;

  const kpiX = useMemo(() => kpis.find((item) => item.uuid === xValue), [xValue]);
  const kpiY = useMemo(() => kpis.find((item) => item.uuid === yValue), [yValue]);

  const superScoreYOption = {
    value: -1,
    label: t("projectGroup.analytics.superScore"),
  };

  const systemScoreYOption = {
    value: -2,
    label: t("projectGroup.analytics.systemScore"),
  };

  const [yOptions, xOptions] = useMemo(() => {
    const kpiOptions = kpis.map((item) => ({ value: item.uuid, label: item.kpiName + " " + item.kpiUnit }));
    const xOptionsInner = [{ value: "", label: t("projectGroup.analytics.projects") }, ...kpiOptions];
    const yOptionsInner = [...(xValue === "" ? [superScoreYOption, systemScoreYOption] : []), ...kpiOptions];
    return [
      yOptionsInner.filter((item) => !xValue || item.value !== xValue),
      xOptionsInner.filter((item) => item.value !== yValue),
    ];
  }, [kpis, xValue, yValue]);

  return (
    <ContentBlock className={"project-group-analytics-chart" + (className ? " " + className : "")}>
      <LoadingOverlay spinner active={loading} className="auto-height">
        <div className="flex-row">
          <div className="flex-row flex-2">
            <div className="k-flex-1">
              <Autocomplete
                size="small"
                key={xOptions}
                options={xOptions}
                value={xOptions.length && xValue ? xValue : ""}
                isOptionEqualToValue={(option, value) => !!option && option.value === value}
                getOptionLabel={(option) =>
                  (typeof option === "string"
                    ? xOptions.find((item) => item.value === option)?.label
                    : option?.label) || ""
                }
                renderInput={(params) => <TextField {...params} />}
                onChange={(event, selected) => {
                  selectXValue(selected.value, kpis);
                }}
                disableClearable
              />
            </div>
            <div className="versus">{t("main.vs")}</div>
            <div className="k-flex-1">
              <Autocomplete
                size="small"
                key={yOptions}
                options={yOptions}
                defaultValue={superScoreYOption}
                value={yOptions.length && yValue ? yValue : ""}
                isOptionEqualToValue={(option, value) => !!option && option.value === value}
                getOptionLabel={(option) =>
                  (typeof option === "string" || option < 0
                    ? yOptions.find((item) => item.value === option)?.label
                    : option?.label) || ""
                }
                renderInput={(params) => <TextField {...params} />}
                onChange={(event, selected) => {
                  selectYValue(selected.value, kpis);
                }}
                disableClearable
              />
            </div>
          </div>
          <div className="k-flex-1" />
        </div>
        {!xValue && (
          <ProjectsVsKPIChart
            projectGroupId={projectGroupId}
            kpi={kpiY}
            scoreType={yValue === -1 ? SUPER_SCORE_TYPE : yValue === -2 ? SYSTEM_SCORE_TYPE : undefined}
            alternateYLabel={nonKPIYAxisLabel}
          />
        )}
        {!!xValue && <KPIVsKPIChart projectGroupId={projectGroupId} kpiY={kpiY} kpiX={kpiX} />}
      </LoadingOverlay>
    </ContentBlock>
  );
});
