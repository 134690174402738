import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { INDICATOR_STATUSES } from "../../../constants/ratingSystem";
import { StatusBadge } from "../../StatusBadge/StatusBadge";

export const headerStatusTemplate = ({ selectStatus, t, readOnly }) => {
  if (readOnly) {
    return null;
  }
  return (
    <div className="column-header-addon">
      <Select fullWidth value={""} displayEmpty className="indicator-status-select" onChange={selectStatus}>
        <MenuItem key={-1} value="" disabled>
          {t("grouping.status.changeTo")}
        </MenuItem>
        {INDICATOR_STATUSES.map((item) => (
          <MenuItem key={item} value={item} onClick={(event) => event.stopPropagation()}>
            <StatusBadge className={"status-" + item}>{t("grouping.status." + item)}</StatusBadge>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
