import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { Configuration, WhitelabelEndpointApi } from "../generated-api";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";

const whitelabelApi = new WhitelabelEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const putWhitelabelSubject = new BehaviorSubject(new PromiseSubjectState());
export const useUpdateWhitelabelResponse = hookFromSubject(putWhitelabelSubject);
export const updateWhitelabel = (whitelabelDTO) =>
  promiseToSubject(whitelabelApi.apiSecurityWhitelabelPut({ whitelabelDTO }), putWhitelabelSubject);

export const postWhitelabelLogoTemporarySubject = new BehaviorSubject(new PromiseSubjectState());
export const useWhitelabelLogoTemporaryResponse = hookFromSubject(postWhitelabelLogoTemporarySubject);
export const creteWhitelabelLogoTemporary = (logo) =>
  promiseToSubject(whitelabelApi.apiSecurityWhitelabelLogoTemporaryPost({ logo }), postWhitelabelLogoTemporarySubject);

export const postWhitelabelLogoCollapsedTemporarySubject = new BehaviorSubject(new PromiseSubjectState());
export const useWhitelabelLogoCollapsedTemporaryResponse = hookFromSubject(postWhitelabelLogoCollapsedTemporarySubject);
export const creteWhitelabelLogoCollapsedTemporary = (logo) =>
  promiseToSubject(whitelabelApi.apiSecurityWhitelabelLogoTemporaryPost({ logo }), postWhitelabelLogoCollapsedTemporarySubject);

export const postWhitelabelLogoExpandedTemporarySubject = new BehaviorSubject(new PromiseSubjectState());
export const useWhitelabelLogoExpandedTemporaryResponse = hookFromSubject(postWhitelabelLogoExpandedTemporarySubject);
export const creteWhitelabelLogoExpandedTemporary = (logo) =>
  promiseToSubject(whitelabelApi.apiSecurityWhitelabelLogoTemporaryPost({ logo }), postWhitelabelLogoExpandedTemporarySubject);

export const postWhitelabelLeftInfoboxImageTemporarySubject = new BehaviorSubject(new PromiseSubjectState());
export const useWhitelabelLeftInfoboxImageTemporaryResponse = hookFromSubject(postWhitelabelLeftInfoboxImageTemporarySubject);
export const creteWhitelabelLeftInfoboxImageTemporary = (logo) =>
  promiseToSubject(whitelabelApi.apiSecurityWhitelabelLogoTemporaryPost({ logo }), postWhitelabelLeftInfoboxImageTemporarySubject);

export const postWhitelabelRightInfoboxImageTemporarySubject = new BehaviorSubject(new PromiseSubjectState());
export const useWhitelabelRightInfoboxImageTemporaryResponse = hookFromSubject(postWhitelabelRightInfoboxImageTemporarySubject);
export const creteWhitelabelRightInfoboxImageTemporary = (logo) =>
  promiseToSubject(whitelabelApi.apiSecurityWhitelabelLogoTemporaryPost({ logo }), postWhitelabelRightInfoboxImageTemporarySubject);

export const getWhitelabelTenantSubject = new BehaviorSubject(new PromiseSubjectState());
export const useWhitelabelTenantResponse = hookFromSubject(getWhitelabelTenantSubject);
export const getWhitelabelTenant = (tenantId) =>
  promiseToSubject(whitelabelApi.apiSecurityWhitelabelTenantTenantIdGet({ tenantId }), getWhitelabelTenantSubject);

export const getWhitelabelLogoTenantSubject = new BehaviorSubject(new PromiseSubjectState());
export const useWhitelabelLogoTenantResponse = hookFromSubject(getWhitelabelLogoTenantSubject);
export const getWhitelabelLogoTenant = (tenantId) =>
  promiseToSubject(whitelabelApi.apiSecurityWhitelabelLogoTenantTenantIdGet({ tenantId }), getWhitelabelLogoTenantSubject);

export const getWhitelabelLogoSubject = new BehaviorSubject(new PromiseSubjectState());
export const useWhitelabelLogoResponse = hookFromSubject(getWhitelabelLogoSubject);
export const getWhitelabelLogo = () =>
  promiseToSubject(whitelabelApi.apiSecurityWhitelabelLogoGet(), getWhitelabelLogoSubject);
