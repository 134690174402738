import { forEachRecursive } from "../../utils";

export const mergeValue = (initial, current, updated) => {
  let value = initial;
  const isChanged = initial !== current;
  if (isChanged) {
    value = current;
  }
  const isUpdated = initial !== updated;
  if (isUpdated) {
    value = updated;
  }
  const withConflict = isChanged && isUpdated && current !== updated;
  return [value, withConflict];
};

export const mergeArray = (initial, current, updated) => {
  let withConflicts = false;
  const values = [];
  const valuesSet = new Set();
  [...initial, ...current, ...updated].forEach(item => valuesSet.add(item));
  valuesSet.forEach(item => {
    const [value, conflict] = mergeValue(
      initial.indexOf(item) !== -1,
      current.indexOf(item) !== -1,
      updated.indexOf(item) !== -1
    );
    if (value) {
      values.push(item);
    }
    if (conflict) {
      withConflicts = true;
    }
  });
  return [values, withConflicts];
};

export const mergeObject = (initial = {}, current = {}, updated = {}, fieldsToMerge = []) => {
  const merged = {};
  let withConflicts = false;
  if (!fieldsToMerge?.length) {
    const fieldSet = new Set();
    Object.keys({ ...initial, ...current, ...updated }).forEach(field => fieldSet.add(field));
    fieldsToMerge = [...fieldSet];
  }
  fieldsToMerge.forEach(field => {
    if ([initial[field] ? typeof initial[field] : '', current[field] ? typeof current[field] : '', updated[field] ? typeof updated[field] : ''].indexOf('object') !== -1) {
      try {
        const [value, conflict] = mergeValue(
          JSON.stringify(initial[field]),
          JSON.stringify(current[field]),
          JSON.stringify(updated[field])
        );
        merged[field] = JSON.parse(value);
        if (conflict) {
          withConflicts = true;
        }
      } catch (e) {
        merged[field] = updated[field];
        withConflicts = true;
      }
    } else {
      const [value, conflict] = mergeValue(initial[field], current[field], updated[field]);
      merged[field] = value;
      if (conflict) {
        withConflicts = true;
      }
    }
  });
  return [{ ...initial, ...merged }, withConflicts];
};

export const isObjectChanged = (initial = {}, current = {}, fieldsToCompare = []) => {
  if (!fieldsToCompare?.length) {
    const fieldSet = new Set();
    Object.keys({ ...initial, ...current }).forEach(field => fieldSet.add(field));
    fieldsToCompare = [...fieldSet];
  }
  return fieldsToCompare.some(field => initial[field] !== current[field]);
};

export const isObjectDeepChanged = (initial, current) => JSON.stringify(initial) !== JSON.stringify(current);

export const mergeArrayOfObjects = (initial = [], current = [], updated = [], fieldsToMerge = [], key = 'id') => {
  let withConflicts = false;
  const objects = [];
  const keysSet = new Set();
  let keyIndex = 0;
  const keyPrefix = 'unique_';
  [...initial, ...current, ...updated].forEach(item => {
    if (!item[key]) {
      item[key] = keyPrefix + keyIndex++;
    }
    keysSet.add(item[key]);
  });

  const initialByKey = {};
  initial.forEach(item => initialByKey[item[key]] = item);
  const currentByKey = {};
  current.forEach(item => currentByKey[item[key]] = item);
  const updatedByKey = {};
  updated.forEach(item => updatedByKey[item[key]] = item);

  keysSet.forEach(keyValue => {
    const initialObj = initialByKey[keyValue];
    const currentObj = currentByKey[keyValue];
    const updatedObj = updatedByKey[keyValue];
    if (initialObj && currentObj && updatedObj) {
      const [object, objectConflict] = mergeObject(initialObj, currentObj, updatedObj, fieldsToMerge);
      if (objectConflict) {
        withConflicts = true;
      }
      objects.push(object);
    } else {
      if (!initialObj) {
        if (currentObj) {
          objects.push(currentObj);
        } else {
          objects.push(updatedObj);
        }
      } else {
        if (!updatedObj) {
          if (isObjectChanged(initialObj, currentObj, fieldsToMerge)) {
            withConflicts = true;
          }
        } else {
          if (isObjectChanged(initialObj, updatedObj, fieldsToMerge)) {
            withConflicts = true;
            objects.push(updatedObj);
          }
        }
      }
    }
  });

  objects.forEach(item => {
    if (String(item[key]).indexOf(keyPrefix) === 0) {
      item[key] = undefined;
    }
  });

  return [objects, withConflicts];
};

export const isChangedRecursive = (initial = [], current = [], isChangedFn = (a, b) => false,
                                   childrenPropName = 'children', key = 'UID') => {
  const initialByKey = {};
  const currentByKey = {};

  const keySet = new Set();
  initial.forEach(item => {
    initialByKey[item[key]] = { ...item };
    keySet.add(item[key]);
  });
  current.forEach(item => {
    currentByKey[item[key]] = { ...item };
    keySet.add(item[key]);
  });

  return [...keySet].some(nodeKey => {
    const initialNode = initialByKey[nodeKey];
    const currentNode = currentByKey[nodeKey];
    const initialChildren = initialNode ? initialNode[childrenPropName] : undefined;
    const currentChildren = currentNode ? currentNode[childrenPropName] : undefined;
    if (initialNode) {
      delete initialNode[childrenPropName];
    }
    if (currentNode) {
      delete currentNode[childrenPropName];
    }
    return isChangedFn(initialNode, currentNode)
      || isChangedRecursive(initialChildren, currentChildren, isChangedFn, childrenPropName, key);
  });
};

export const mergeRecursive = (
  initial = [], current = [], updated = [], mergeFn = (a, b, c) => ([c, false]),
  isChangedFn = (a, b) => false, childrenPropName = 'children', key = 'UID'
) => {
  let withConflicts = false;
  const tree = [];

  const initialByKey = {};
  const currentByKey = {};
  const updatedByKey = {};

  const keySet = new Set();
  initial.forEach(item => {
    initialByKey[item[key]] = { ...item };
    keySet.add(item[key]);
  });
  current.forEach(item => {
    currentByKey[item[key]] = { ...item };
    keySet.add(item[key]);
  });
  updated.forEach(item => {
    updatedByKey[item[key]] = { ...item };
    keySet.add(item[key]);
  });

  keySet.forEach(nodeKey => {
    const initialNode = initialByKey[nodeKey];
    const currentNode = currentByKey[nodeKey];
    const updatedNode = updatedByKey[nodeKey];
    const [shouldInclude] = mergeValue(!!initialNode, !!currentNode, !!updatedNode);
    const initialChildren = initialNode ? initialNode[childrenPropName] : undefined;
    const currentChildren = currentNode ? currentNode[childrenPropName] : undefined;
    const updatedChildren = updatedNode ? updatedNode[childrenPropName] : undefined;
    if (initialNode) {
      delete initialNode[childrenPropName];
    }
    if (currentNode) {
      delete currentNode[childrenPropName];
    }
    if (updatedNode) {
      delete updatedNode[childrenPropName];
    }
    const isCurrentChanged = !!currentNode && isChangedFn(initialNode, currentNode);
    const isUpdatedChanged = !!updatedNode
      && (isChangedFn(initialNode, updatedNode) || isChangedRecursive(initialChildren, updatedChildren, isChangedFn));

    if (shouldInclude || isUpdatedChanged) {
      if (initialNode && !currentNode) {
        withConflicts = true;
      }
      const [mergedNode, nodeMergeConflict] = mergeFn(initialNode, currentNode, updatedNode);
      if (nodeMergeConflict) {
        withConflicts = true;
      }
      const [withChildren] = mergeValue(!!initialChildren, !!currentChildren || (!!updatedChildren && isUpdatedChanged), !!updatedChildren);
      let children;
      if (withChildren) {
        const [mergedChildren, childrenMergeConflict] =
          mergeRecursive(initialChildren, currentChildren, updatedChildren, mergeFn, isChangedFn, childrenPropName, key);
        if (childrenMergeConflict) {
          withConflicts = true;
        }
        children = mergedChildren;
      }
      tree.push({ ...mergedNode, [childrenPropName]: children });
    } else if (isCurrentChanged) {
      withConflicts = true;
    }
  });

  return [tree, withConflicts];
};

export const areParentsChangedInRecursiveStructure = (current = [], updated = [], childrenPropName = 'children', key = 'UID') => {
  const currentParentKeyByKey = {};
  const updatedParentKeyByKey = {};
  const nodeKeysSet = new Set();

  forEachRecursive(current, (node, tree, i, level, parent) => {
    currentParentKeyByKey[node[key]] = parent ? parent[key] : -1;
    nodeKeysSet.add(node[key]);
  }, childrenPropName);
  forEachRecursive(updated, (node, tree, i, level, parent) => {
    updatedParentKeyByKey[node[key]] = parent ? parent[key] : -1;
    nodeKeysSet.add(node[key]);
  }, childrenPropName);

  const nodeKeys = [...nodeKeysSet];
  for (let i = 0; i < nodeKeys.length; i++) {
    const nodeKey = nodeKeys[i];
    const currentParent = currentParentKeyByKey[nodeKey];
    const updatedParent = updatedParentKeyByKey[nodeKey];

    if (currentParent && updatedParent && currentParent !== updatedParent) {
      return true;
    }
  }

  return false;
};
