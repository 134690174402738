import { Chip } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { getProjectFileUrl } from "../../../hooks/project";
import { isJSONEmpty } from "../NewRatingSystemESG.util";
import { BaseBlock } from "./BaseBlock";

export const METRICS_BLOCK_ESG_ID = "metricsBlock";

export const MetricsBlockESG = ({ dataItem, system }) => {
  const { t } = useTranslation();
  return (
    <BaseBlock title="Metrics" id={METRICS_BLOCK_ESG_ID}>
      <div className="k-d-flex k-flex-wrap k-gap-2 k-px-5">
        <div className="k-flex-basis-1/3 k-flex-grow k-text-uppercase k-font-bold">
          {t("newRatingSystem.esg.sideMenu.metricsBlock.maxSystemScore")}
        </div>
        <div className="k-flex-basis-1/3 k-flex-grow k-text-uppercase k-font-bold">
          {t("newRatingSystem.esg.sideMenu.metricsBlock.proportionOfMaxSystemScore")}
        </div>
        <div className="k-flex-basis-1/3 k-flex-grow k-text-uppercase">{dataItem?.maxSystemScore}</div>
        <div className="k-flex-basis-1/3 k-flex-grow k-text-uppercase">{dataItem?.maxSystemScoreProportion}%</div>
        <div className="k-flex-basis-1/3 k-flex-grow k-text-uppercase k-font-bold">
          {t("newRatingSystem.esg.sideMenu.metricsBlock.weight")}
        </div>
        <div className="k-flex-basis-1/3 k-flex-grow k-text-uppercase k-font-bold">
          {t("newRatingSystem.esg.sideMenu.metricsBlock.koValue")}
        </div>
        <div className="k-flex-basis-1/3 k-flex-grow k-text-uppercase">{dataItem?.weightingFactor}</div>
        <div className="k-flex-basis-1/3 k-flex-grow k-text-uppercase">{dataItem?.koValue}</div>
        {!isJSONEmpty(dataItem?.elementAwardThresholds) &&
          system?.awards?.map((award) => (
            <div key={`award-element-${award?.internalId}`} className="k-d-flex k-flex-basis-full k-gap-2">
              <div className="k-flex-basis-1/3 k-flex-grow k-text-uppercase k-font-bold">{award?.title}</div>
              <div className="k-flex-basis-1/3 k-flex-grow k-text-uppercase">
                {dataItem?.elementAwardThresholds?.[award.internalId]
                  ? `${dataItem?.elementAwardThresholds?.[award.internalId]}%`
                  : "-"}
              </div>
            </div>
          ))}
        <div className="k-flex-basis-full k-flex-grow k-d-flex k-flex-col k-gap-2">
          <div className="k-font-bold k-text-uppercase">
            {t("newRatingSystem.esg.sideMenu.metricsBlock.evaluationStandardTitle")}
          </div>
          <div>{dataItem?.evaluationStandard}</div>
          <div className="k-font-bold k-text-uppercase">
            {t("newRatingSystem.esg.sideMenu.metricsBlock.evaluationStandardLinks")}
          </div>
          <div className="k-d-flex k-gap-3 k-flex-wrap k-px-5">
            {dataItem?.evaluationStandardLinks?.map((item, index) => (
              <Chip
                className="evaluation-standard-file"
                key={v4()}
                clickable={true}
                onClick={() => {
                  window.open(item.value);
                }}
                label={item.title ?? item.value}
              />
            ))}
          </div>
          <div className="k-font-bold k-text-uppercase">
            {t("newRatingSystem.esg.sideMenu.metricsBlock.evaluationStandardFiles")}
          </div>
          <div className="k-d-flex k-flex-col k-gap-2">
            {dataItem?.evaluationStandardFiles?.map((item, index) => (
              <div key={v4()} className="k-px-7 k-d-flex k-gap-2 k-align-items-center">
                <a
                  href={getProjectFileUrl(system?.projectId, item.fileId)}
                  target="_blank"
                  className="k-flex-1"
                  rel="noreferrer"
                >
                  {item.name}
                </a>
              </div>
            ))}
          </div>{" "}
        </div>
      </div>
    </BaseBlock>
  );
};

MetricsBlockESG.propTypes = {
  dataItem: PropTypes.object,
  system: PropTypes.object,
};
