import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import React from "react";
import { AdvancedTableCell } from "./AdvancedTableCell";

export const AdvancedTableBodyCell = React.memo(
  ({
    row,
    column,
    index,
    passThrough,
    getColumnPosition,
    readOnly,
    disableRowDrag,
    errorMark,
    stickyAmount,
    rowGrabStart,
    rowCollapsed,
    toggleRowCollapsed,
    submitted,
    updateValue,
    className,
    columnStyle,
    collapsible,
  }) => {
    return (
      <TableCell
        className={className}
        style={{
          left: index < stickyAmount ? getColumnPosition(index) + "px" : "0",
          width: ("number" === typeof column.width ? column.width + "px" : column.width) || "auto",
        }}
      >
        {index === 0 && !!errorMark && <div className="error-mark" />}
        {index === 0 && !readOnly && !disableRowDrag && (
          <div className="dnd-trigger" onMouseDown={(event) => rowGrabStart(event, row.UID)}>
            <DragIndicatorIcon />
          </div>
        )}
        <div className="cell-inner" style={columnStyle}>
          {!!collapsible && (
            <IconButton
              className={"collapse-trigger " + (rowCollapsed ? "active" : "")}
              onClick={(event) => {
                event.stopPropagation();
                toggleRowCollapsed(row.UID);
              }}
            >
              {!!rowCollapsed && <ArrowDropDownIcon />}
              {!rowCollapsed && <ArrowDropUpIcon />}
            </IconButton>
          )}
          <AdvancedTableCell
            readOnly={readOnly}
            column={column}
            row={row}
            submitted={submitted}
            updateValue={updateValue}
            passThrough={passThrough}
          />
        </div>
      </TableCell>
    );
  }
);
