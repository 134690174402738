import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, Card, Fade, IconButton } from "@mui/material";
import afterFrame from "afterframe";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBeforeUnload, useNavigate } from "react-router-dom";
import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { v4 as getUUID } from "uuid";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { KPIProjectFieldsDecisions } from "../../components/KPIProjectFieldsDecisions/KPIProjectFieldsDecisions";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { AdditionalFieldsDialog } from "../../components/RatingSystem/AdditionalFieldsDialog/AdditionalFieldsDialog";
import { RatingSystemGoals } from "../../components/RatingSystem/RatingSystemGoals/RatingSystemGoals";
import { RatingSystemStatusIndicatorPopover } from "../../components/RatingSystem/RatingSystemGrouping/RatingSystemStatusIndicator";
import { VersionsDialog } from "../../components/Versions/VersionsDialog/VersionsDialog";
import { STATUS_IN_PROGRESS, STATUS_LOCKED } from "../../constants/sustainabilitySystem";
import { loadLayout, saveLayout } from "../../helpers/tableLayout";
import { getActionSuggestion } from "../../hooks/action";
import { showMuiDialog } from "../../hooks/dialog";
import { useEditSafeExit } from "../../hooks/editing";
import { getProjectGroup, useProjectGroupResponse } from "../../hooks/projectGroup";
import {
  acceptRatingSystemChanges,
  declineRatingSystemChanges,
  evaluateRatingSystem,
  getRatingSystem,
  getRatingSystemRevisionById,
  getRatingSystemRevisions,
  useRatingSystemAcceptChangesResponse,
  useRatingSystemResponse,
  useRatingSystemRevisionByIdResponse,
  useRatingSystemRevisionsResponse,
} from "../../hooks/ratingSystem";
import { showError, showSuccess, showWarning } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { createChapterFileArray, deepCopy } from "../../utils";
import "./NewRatingSystem.scss";
import { expandField, parentField, prepareSystemAndCreateData, sortedVersions } from "./NewRatingSystem.util";
import { processMessage } from "./NewRatingSystem.utils";
import { columns, editCellMap, filterCellMap } from "./NewRatingSystemESG.columns";
import {
  calculateOverallSuperScore,
  calculateOverallSystemScore,
  calculatePotentialOverallSuperScore,
  calculatePotentialOverallSystemScore,
  canApplyChanges,
  checkKOFailed,
  getWaitingLinkedKPIs,
} from "./NewRatingSystemESG.ratingSystem";
import {
  actionCondition,
  createColumnMap,
  filterHidableColumns,
  isJSONEmpty,
  updateGrouping,
} from "./NewRatingSystemESG.util";
import { NewRatingSystemESGHeader, getShowMasterData, setShowMasterData } from "./NewRatingSystemESGHeader";
import { defaultSideMenuState, getSideMenuState, setSideMenuState } from "./NewRatingSystemSideMenu";
import { NewRatingSystemTable, defaultTableState, getTableState, setTableState } from "./NewRatingSystemTable";
import { setPopoverTargetSubject } from "./NewRatingsystemESG.subject";

const paddingTop = 37;
const paddingBottom = 16;
const gap = 20;
const calculationFields = ["evaluationSystemScore", "potentialEvaluationSystemScore", "excluded"];

const defaultSystemPageState = {
  system: null,
  grouping: null,
  map: null,
  readOnly: true,
  editable: true,
  loading: false,
  autoEvaluate: false,
  unsaved: false,
};

const systemPageStateSubject = new BehaviorSubject(defaultSystemPageState);

export const getSystemPageState = () => systemPageStateSubject.getValue();
export const setSystemPageState = (v) =>
  systemPageStateSubject.next(typeof v === "function" ? v(systemPageStateSubject.getValue()) : v);

const useSystemPageState = hookFromSubject(systemPageStateSubject);

const mapExpandedFields = (map, expanded, layout) => {
  if (isJSONEmpty(expanded))
    for (const key in map) {
      expanded[key] = layout?.table?.expanded?.[key] ?? true;
      map[key][expandField] = expanded[key];
    }
  else
    for (const key in expanded) {
      if (map[key]) map[key][expandField] = expanded[key];
    }
};

export const NewRatingSystemESG = ({ ratingSystemId, groupId, analyze }) => {
  console.time("Page render");
  afterFrame(() => console.timeEnd("Page render"));

  const { t } = useTranslation();
  const navigate = useNavigate();
  const systemResponse = useRatingSystemResponse();
  const groupResponse = useProjectGroupResponse();
  const accepChangesResponse = useRatingSystemAcceptChangesResponse();
  const group = groupResponse?.data;
  const { system, grouping, map, readOnly, editable, loading, autoEvaluate, unsaved, waitingKPI } =
    useSystemPageState();
  const permissions = system?.projectPermission ?? {};
  const [headerHeight, setHeaderHeight] = useState(0);
  const [popoverTarget, setPopoverTarget] = useState(null);
  const [showGoals, setShowGoals] = useState(false);
  const layout = loadLayout(ratingSystemId);
  const [versionDialogState, setVersionDialogState] = useState({
    open: false,
    selectedVersion: null,
    versions: [],
  });

  const saveESGLayout = useEvent(() => {
    const { columns, hiddenColumnMap, filter, sort, expanded, showFilter, currentLevel } = getTableState();
    const showMasterData = getShowMasterData();
    const { autoOpen } = getSideMenuState();

    const { loading } = getSystemPageState();
    if (!loading)
      saveLayout(ratingSystemId, {
        table: { columns, hiddenColumnMap, filter, sort, expanded, showFilter, currentLevel },
        header: { showMasterData },
        sideMenu: { autoOpen },
      });
  });

  useEditSafeExit(unsaved);

  useBeforeUnload(saveESGLayout);

  useEffect(
    () => () => {
      saveESGLayout();
      setSystemPageState(defaultSystemPageState);
      setTableState(defaultTableState);
      setSideMenuState(defaultSideMenuState);
    },
    []
  );

  const [confirmDialogState, setConfirmDialogState] = useState({
    askLater: false,
    reEvaluate: false,
  });

  const revisions = useRatingSystemRevisionsResponse();
  const versionById = useRatingSystemRevisionByIdResponse();

  const versionHandler = useEvent((selectedVersion, isCurrent) => {
    getRatingSystemRevisionById(ratingSystemId, selectedVersion)
      .then((data) => {
        const system = deepCopy(data);
        onSystemChange(system);
        setSystemPageState((prev) => ({
          ...prev,
          editable: isCurrent,
          readOnly: !isCurrent || prev?.readOnly,
          unsaved: false,
        }));
        setVersionDialogState((prev) => ({ ...prev, selectedVersion, open: false }));
      })
      .catch(() => {
        showError(t("error.500"));
      });
  });

  useEffect(() => {
    if (setPopoverTarget) setPopoverTargetSubject.next(() => setPopoverTarget);
  }, [setPopoverTarget]);

  const loadAll = useEvent((autoEvaluate = false) => {
    setSystemPageState((prev) => ({ ...prev, autoEvaluate, loading: true }));
    getRatingSystem(ratingSystemId);
    getActionSuggestion(ratingSystemId);
    getRatingSystemRevisions(ratingSystemId)
      .then((data) => {
        setVersionDialogState((prev) => ({
          ...prev,
          versions: sortedVersions(data),
          selectedVersion: data[data.length - 1]?.revisionNumber || null,
        }));
      })
      .catch(() => {
        showError(t("error.500"));
        setSystemPageState((prev) => ({ ...prev, loading: false }));
      });
  });

  useEffect(() => {
    if (ratingSystemId) loadAll();
  }, [ratingSystemId]);

  useEffect(() => {
    if (groupId) getProjectGroup(groupId);
  }, [groupId]);

  useEffect(() => {
    if (system?.status === STATUS_LOCKED || system?.status === STATUS_IN_PROGRESS) {
      showWarning(
        processMessage(t("ratingSystem.warningSustainabilitySystemStatus"), [
          [system.systemSource, system.name, system.systemVersion].join(" "),
        ])
      );
      navigate(system?.projectId ? `/project/${system?.projectId}` : "/project");
    }
  }, [system?.status]);

  const onSystemChange = useEvent((system) => {
    console.time("prepare system");
    const { data, map, levelArray } = prepareSystemAndCreateData(system);
    console.timeEnd("prepare system");

    system = {
      ...system,
      projectDescriptionChapter: system.projectDescriptionTextImageDTO,
      methodologyChapter: system.methodologyTextImageDTO,
    };

    setSystemPageState((prev) => ({
      ...prev,
      system,
      map,
      grouping: data,
      loading: autoEvaluate,
      waitingKPI: getWaitingLinkedKPIs(system?.indicatorElementMap),
    }));

    console.time("calculate system");
    onUpdateGrouping(null, map, data, parentField, system?.systemLogic, levelArray);
    console.timeEnd("calculate system");

    const expanded = getTableState()?.expanded ?? {};
    let tableLayoutState = {};
    mapExpandedFields(map, expanded, layout);

    if (layout?.table) {
      const { columns: layoutColumns, hiddenColumnMap, filter, sort, showFilter, currentLevel } = layout.table;
      const mappedColumns = columns.map((column) => {
        const { cell, headerCell, filterCell, ...layoutColumn } =
          layoutColumns.find((c) => c.field === column.field) ?? {};
        return { ...column, ...layoutColumn };
      });

      tableLayoutState = {
        columns: mappedColumns,
        hiddenColumnMap,
        filter,
        sort,
        showFilter,
        originalColumns: columns,
        currentLevel,
      };
    } else {
      tableLayoutState = {
        columns,
        originalColumns: columns,
        hiddenColumnMap: createColumnMap(columns?.filter(filterHidableColumns), (column) => ({
          hidden: false,
          column,
        })),
      };
    }

    setTableState((prev) => ({
      ...prev,
      data,
      map,
      expanded,
      levelArray,
      currentLevel: levelArray?.length - 1,
      ...tableLayoutState,
    }));

    if (layout?.sideMenu) {
      const { autoOpen } = layout.sideMenu;
      setSideMenuState((prev) => ({ ...prev, autoOpen }));
    }

    if (layout?.header) setShowMasterData(layout?.header?.showMasterData ?? true);
  });

  useEffect(() => {
    if (systemResponse?.data && systemResponse?.data?.id === Number(ratingSystemId) && !systemResponse?.loading) {
      const system = deepCopy(systemResponse.data);
      onSystemChange(system);
      if (autoEvaluate) onSave();
    }
  }, [systemResponse?.data]);

  const onSave = useEvent(() => {
    const { system } = getSystemPageState();
    saveESGLayout();

    setSystemPageState((prev) => ({ ...prev, autoEvaluate: false, loading: true }));
    evaluateRatingSystem({ ...system, ratingSystemId })
      .then(() => {
        loadAll();
        setSystemPageState((prev) => ({ ...prev, readOnly: true, loading: false, unsaved: false }));
        showSuccess(t("ratingSystem.updatedMessage"));
      })
      .catch(() => setSystemPageState((prev) => ({ ...prev, loading: false })));
  });

  const onCancel = useEvent(() => {
    const system = deepCopy(systemResponse.data);
    onSystemChange(system);
    setSystemPageState((prev) => ({ ...prev, readOnly: true, unsaved: false }));
  });

  const onEdit = useCallback(() => setSystemPageState((prev) => ({ ...prev, readOnly: !prev.editable })), []);

  const onCustomChapterUpdate = useEvent(({ methodologyChapter, projectDescriptionChapter }) => {
    projectDescriptionChapter = createChapterFileArray(projectDescriptionChapter);
    setSystemPageState((prev) => ({
      ...prev,
      system: { ...prev.system, methodologyChapter, projectDescriptionChapter },
    }));
  });

  const showAdditionalFieldsModal = useEvent(() => {
    const { methodologyChapter, projectDescriptionChapter } = system;
    showMuiDialog((props) => (
      <AdditionalFieldsDialog
        systemId={ratingSystemId}
        projectId={system?.projectId}
        readOnly={readOnly || !editable}
        data={{ methodologyChapter, projectDescriptionChapter }}
        onChange={onCustomChapterUpdate}
        {...props}
      />
    ));
  });

  const onUpdateGrouping = useEvent((dataItem, map, grouping, parentField, systemLogic, levelArray = null) => {
    updateGrouping(dataItem, map, grouping, parentField, systemLogic, levelArray);
    const score = calculateOverallSystemScore(grouping, systemLogic);
    const potentialScore = calculatePotentialOverallSystemScore(grouping, systemLogic);
    setSystemPageState((prev) => ({
      ...prev,
      system: {
        ...prev.system,
        score,
        superScore: calculateOverallSuperScore(prev.system, grouping, score, systemLogic),
        potentialScore,
        potentialSuperScore: calculatePotentialOverallSuperScore(prev.system, grouping, potentialScore, systemLogic),
        koFailed: checkKOFailed(map, "evaluationSystemScore"),
        koFailedPotential: checkKOFailed(map, "potentialEvaluationSystemScore"),
      },
    }));
  });

  const onTableShowError = useEvent((text) => showError(t(text)));

  const onHeaderSizeChange = useEvent(({ height }) => setHeaderHeight(height));
  const onOpenVersionDialog = useEvent(() => setVersionDialogState((prev) => ({ ...prev, open: true })));
  const onHeaderShowChange = useEvent(() => setHeaderHeight((prev) => prev + 1));
  const onStatusChange = useEvent(({ value }) =>
    setSystemPageState((prev) => ({ ...prev, system: { ...prev?.system, ratingStatus: value.value }, unsaved: true }))
  );

  const acceptChanges = useEvent(() => {
    setConfirmDialogState((prev) => ({ ...prev, askLater: true }));
    acceptRatingSystemChanges(ratingSystemId)
      .then(() => loadAll(true))
      .catch((error) => {
        console.error(error);
        showError(t("ratingSystem.acceptChangesError"));
      });
  });

  const declineChanges = useEvent(() => {
    setConfirmDialogState((prev) => ({ ...prev, open: false }));
    declineRatingSystemChanges(ratingSystemId).then(loadAll).catch(console.error).finally(loadAll);
  });

  const askDecisionLater = useEvent(() => setConfirmDialogState((prev) => ({ ...prev, open: false, askLater: true })));
  const setGoals = useEvent((ratingSystemGoals) =>
    setSystemPageState((prev) => ({ ...prev, system: { ...prev.system, ratingSystemGoals } }))
  );

  const isAcceptChangesRequired = (data) =>
    !confirmDialogState.askLater && data?.updated && canApplyChanges(data?.ratingStatus, permissions);

  const sizingCofing = useMemo(() => ({ paddingTop, paddingBottom, gap, headerHeight }), [headerHeight]);
  const ratingSystemGoals = (system?.ratingSystemGoals ?? []).map((node) => ({
    UID: String(node.id || getUUID()),
    ...node,
  }));

  const showLoading =
    loading ||
    revisions?.loading ||
    versionById?.loading ||
    systemResponse?.loading ||
    groupResponse?.loading ||
    accepChangesResponse?.loading;

  const decisionsDisabled =
    !system?.id || isAcceptChangesRequired(system) || !canApplyChanges(system.ratingStatus, permissions) || showLoading;

  const afterDecisions = useEvent(() => {
    setSystemPageState((prev) => ({ ...prev, waitingKPI: [] }));
    loadAll();
  });

  return (
    <>
      <LoadingOverlay spinner active={showLoading}>
        <div className="k-d-flex k-flex-col k-px-12 k-min-h-screen">
          <Box
            id="new-rating-system-esg-body"
            className="k-flex-1 k-d-flex k-flex-col k-overflow-hidden"
            sx={{ paddingTop: `${paddingTop}px`, paddingBottom: `${paddingBottom}px`, gap: `${gap}px` }}
          >
            <NewRatingSystemESGHeader
              groupId={groupId}
              analyze={analyze}
              system={system}
              elementMap={map}
              group={group}
              readOnly={readOnly}
              showGoals={showGoals}
              onHeaderSizeChange={onHeaderSizeChange}
              onOpenVersionDialog={onOpenVersionDialog}
              onOpenCustomChapter={showAdditionalFieldsModal}
              onHeaderShowChange={onHeaderShowChange}
              onStatusChange={onStatusChange}
              onShowGoalsChange={setShowGoals}
            />
            <Fade in={!showGoals} unmountOnExit>
              <Card>
                <NewRatingSystemTable
                  system={system}
                  columns={columns}
                  editCellMap={editCellMap}
                  filterCellMap={filterCellMap}
                  layout={layout}
                  readOnly={readOnly}
                  editable={editable}
                  calculationFields={calculationFields}
                  unsaved={unsaved}
                  sizingConfig={sizingCofing}
                  updateGrouping={onUpdateGrouping}
                  showError={onTableShowError}
                />
              </Card>
            </Fade>
            {showGoals && (
              <RatingSystemGoals
                values={ratingSystemGoals}
                onChange={setGoals}
                readOnly={readOnly || !editable}
                grouping={grouping}
                score={system?.score}
                superScore={system?.superScore}
              />
            )}
          </Box>
        </div>
        <div
          id="new-rating-system-fab-buttons"
          className="k-pos-fixed k-right-5 k-bottom-24 k-d-flex k-flex-col k-gap-3"
        >
          {!readOnly && (
            <>
              <IconButton className="close-button" size="large" onClick={onCancel}>
                <CloseOutlinedIcon />
              </IconButton>
              <IconButton className="save-button" size="large" onClick={onSave} disabled={!editable}>
                <SaveOutlinedIcon />
              </IconButton>
            </>
          )}
          {readOnly && (
            <IconButton className="edit-button" size="large" onClick={onEdit} disabled={!editable}>
              <EditIcon />
            </IconButton>
          )}
        </div>
      </LoadingOverlay>
      <RatingSystemStatusIndicatorPopover
        setPopoverTarget={setPopoverTarget}
        popoverTarget={popoverTarget}
        actionCondition={actionCondition}
      />
      <ConfirmationDialog
        open={isAcceptChangesRequired(system)}
        onConfirm={acceptChanges}
        onCancel={declineChanges}
        thirdAction={askDecisionLater}
        titleText={t("ratingSystem.acceptChangesTitle")}
        bodyText={t("ratingSystem.acceptChanges")}
        thirdActionText={t("ratingSystem.askLater")}
        confirmText={t("main.accept")}
        color="secondary"
      />
      <VersionsDialog
        open={versionDialogState.open}
        onClose={() => setVersionDialogState((prev) => ({ ...prev, open: false }))}
        onAction={versionHandler}
        titleText={t("versions.titleRating")}
        data={sortedVersions(versionDialogState.versions)}
        selectedVersion={versionDialogState.selectedVersion}
        loading={versionById.loading}
      />
      <KPIProjectFieldsDecisions
        projectId={system?.projectId}
        linkedKpis={waitingKPI}
        disabled={decisionsDisabled}
        onFinish={afterDecisions}
      />
    </>
  );
};

NewRatingSystemESG.propTypes = {
  ratingSystemId: PropTypes.string,
  groupId: PropTypes.string,
  analyze: PropTypes.bool,
};
