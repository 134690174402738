import BarChartIcon from "@mui/icons-material/BarChart";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import HistoryIcon from "@mui/icons-material/History";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { Box, Button, Card, Fade, IconButton, Slide } from "@mui/material";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import afterFrame from "afterframe";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { SystemScoreProgressESG } from "../../components/Dashboard/SystemScore/SystemScoreProgress/SystemScoreProgressESG";
import { StatusBadge } from "../../components/StatusBadge/StatusBadge";
import { TwoOptionsToggle } from "../../components/TwoOptionsToggle/TwoOptionsToggle";
import { AWARD_CALCULATION_TYPE_PERCENT } from "../../constants/sustainabilitySystem";
import { FloatingLabelTextBox } from "./FloatingInputs";
import { ratingSystemStatusValues, statusItemRender, statusValueRender } from "./NewRatingSystem.util";
import { processMessage, roundDecimal } from "./NewRatingSystem.utils";
import { checkThresholdFailed, getAward } from "./NewRatingSystemESG.ratingSystem";

const SystemData = ({ system, show, t }) => {
  return (
    <Fade in={show} unmountOnExit>
      <div className="k-d-flex k-gap-3">
        <FloatingLabelTextBox
          label={t("newRatingSystem.esg.header.masterData.name")}
          value={system?.name ?? ""}
          readOnly
        />
        <FloatingLabelTextBox
          label={t("newRatingSystem.esg.header.masterData.version")}
          value={system?.systemVersion ?? ""}
          readOnly
        />
        <FloatingLabelTextBox
          label={t("newRatingSystem.esg.header.masterData.projectType")}
          value={system?.projectTypes[0] ? t(`sustainabilitySystem.type.${system?.projectTypes[0]}`) : ""}
          readOnly
        />
        <FloatingLabelTextBox
          className="k-flex-1"
          label={t("newRatingSystem.esg.header.masterData.description")}
          value={system?.description ?? ""}
          readOnly
        />
      </div>
    </Fade>
  );
};

SystemData.propTypes = {
  system: PropTypes.object,
  show: PropTypes.bool,
  t: PropTypes.func,
};

const ErrorWarning = ({ system, actualSideThresholdFailed }) => {
  const { t } = useTranslation();
  return (
    <Slide
      in={system?.koFailed || system?.koFailedPotential || actualSideThresholdFailed}
      easing="ease-out"
      timeout={150}
    >
      <div
        className={
          "ko-message k-flex-basis-full k-p-1 k-d-flex k-flex-col" +
          (!system?.koFailed && !actualSideThresholdFailed ? " k-height-0" : "")
        }
      >
        <Fade in={system?.koFailed} easing="ease-out" timeout={350} unmountOnExit>
          <div className="k-flex-1 k-d-flex k-gap-2 k-align-items-center k-justify-content-center">
            <ErrorOutlineOutlinedIcon />
            {t("award.actualKO")}
          </div>
        </Fade>
        <Fade in={system?.koFailed && system?.koFailedPotential} easing="ease-out" timeout={350} unmountOnExit>
          <div className="k-flex-1">
            <hr />
          </div>
        </Fade>
        <Fade in={system?.koFailedPotential} easing="ease-out" timeout={350} unmountOnExit>
          <div className="k-flex-1 k-d-flex k-gap-2 k-align-items-center k-justify-content-center">
            <ErrorOutlineOutlinedIcon />
            {t("award.potentialKO")}
          </div>
        </Fade>
        <Fade
          in={(system?.koFailed || system?.koFailedPotential) && actualSideThresholdFailed}
          easing="ease-out"
          timeout={350}
          unmountOnExit
        >
          <div className="k-flex-1">
            <hr />
          </div>
        </Fade>
        <Fade in={actualSideThresholdFailed} easing="ease-out" timeout={350} unmountOnExit>
          <div className="k-flex-1 k-d-flex k-gap-2 k-align-items-center k-justify-content-center">
            <ErrorOutlineOutlinedIcon />
            {typeof actualSideThresholdFailed === "object"
              ? processMessage(t("newRatingSystem.esg.header.sideThresholdFailed"), [
                  actualSideThresholdFailed[0]?.name,
                ])
              : t("award.sideThresholdFailed")}
          </div>
        </Fade>
      </div>
    </Slide>
  );
};

ErrorWarning.propTypes = {
  system: PropTypes.object,
  actualSideThresholdFailed: PropTypes.array,
};

const ScoreHeader = ({ system, elementMap, show, t }) => {
  const navigate = useNavigate();

  const awardCalculationType = system?.awardCalculationType ?? AWARD_CALCULATION_TYPE_PERCENT;
  const isPercent = awardCalculationType === AWARD_CALCULATION_TYPE_PERCENT;

  const actualSideThresholdFailed = checkThresholdFailed(
    system?.awards || [],
    elementMap,
    isPercent ? system?.superScore : system?.score,
    isPercent ? "universalScore" : "evaluationSystemScore"
  );

  const actualAward = getAward(
    system?.awards ?? [],
    elementMap,
    isPercent ? system?.superScore : system?.score,
    isPercent ? "universalScore" : "evaluationSystemScore"
  );

  const potentialAward = getAward(
    system?.awards ?? [],
    elementMap,
    isPercent ? system?.potentialSuperScore : system?.potentialScore,
    isPercent ? "potentialUniversalScore" : "potentialEvaluationSystemScore"
  );

  return (
    <Fade in={show} unmountOnExit>
      <Card className="k-d-flex k-flex-wrap k-gap-7">
        <ErrorWarning system={system} actualSideThresholdFailed={actualSideThresholdFailed} isPercent={isPercent} />
        <div className="k-d-flex k-flex-col k-px-3 k-flex-1">
          <div className="k-flex-1 k-d-flex k-flex-wrap k-gap-3 k-p-3 k-align-items-center k-flex-1">
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase k-text-right"></div>
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase k-text-right">
              {t("newRatingSystem.esg.header.progress.systemScore")}
            </div>
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase k-text-right">
              {t("newRatingSystem.esg.header.progress.superScore")}
            </div>
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase k-text-right">
              {t("newRatingSystem.esg.header.progress.award")}
            </div>
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase header-primary-background-text">
              {t("newRatingSystem.esg.header.progress.actual")}
            </div>
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase k-text-right k-font-bold header-primary-background-text">
              {roundDecimal(system?.score, 100)}
            </div>
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase k-text-right k-font-bold header-primary-background-text">
              {roundDecimal(system?.superScore, 100)}%
            </div>
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase k-text-right k-h-10">
              {!system?.koFailed && actualAward?.fileId && (
                <img
                  className="k-h-full"
                  src={`/api/sustainability/award/file/${actualAward.fileId}/preview`}
                  alt="award"
                />
              )}
            </div>
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase header-secondary-background-text">
              {t("newRatingSystem.esg.header.progress.potential")}
            </div>
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase k-text-right k-font-bold header-primary-background-text">
              {system?.score !== system?.potentialScore ? roundDecimal(system?.potentialScore, 100) : "-"}
            </div>
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase k-text-right k-font-bold header-primary-background-text">
              {system?.score !== system?.potentialScore ? roundDecimal(system?.potentialSuperScore, 100) + "%" : "-"}
            </div>
            <div className="k-flex-basis-1/5 k-flex-grow k-text-uppercase k-text-right k-h-10">
              {!system?.koFailedPotential && potentialAward?.fileId && system?.score !== system?.potentialScore && (
                <img
                  className="k-h-full"
                  src={`/api/sustainability/award/file/${potentialAward.fileId}/preview`}
                  alt="award"
                />
              )}
            </div>
          </div>
        </div>
        <Box
          className="k-px-3 k-pb-3 k-cursor-pointer progress-bar-flex"
          onClick={() => navigate(`/project/${system?.projectId}/dashboard/${system?.id}`)}
        >
          <SystemScoreProgressESG data={{ ...system, awardCalculationType }} />
        </Box>
      </Card>
    </Fade>
  );
};

ScoreHeader.propTypes = {
  system: PropTypes.object,
  elementMap: PropTypes.object,
  show: PropTypes.bool,
  t: PropTypes.func,
};

const showMasterDataSubject = new BehaviorSubject(true);
const useShowMasterData = hookFromSubject(showMasterDataSubject);

export const setShowMasterData = (v) =>
  showMasterDataSubject.next(typeof v === "function" ? v(showMasterDataSubject.getValue()) : v);
export const getShowMasterData = () => showMasterDataSubject.getValue();

export const NewRatingSystemESGHeader = React.memo(
  ({
    groupId,
    analyze,
    system,
    elementMap,
    group,
    onHeaderSizeChange,
    readOnly,
    showGoals,
    onOpenVersionDialog,
    onOpenCustomChapter,
    onHeaderShowChange,
    onStatusChange,
    onShowGoalsChange,
  }) => {
    console.time("Header render");
    afterFrame(() => console.timeEnd("Header render"));

    const { t } = useTranslation();
    const navigate = useNavigate();
    const showMasterData = useShowMasterData();

    useEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { width, height } = entry.contentRect;
          onHeaderSizeChange({ width, height: Math.round(height) });
        }
      });

      resizeObserver.observe(document.getElementById("new-rating-system-esg-header"));
    }, []);

    const value =
      ratingSystemStatusValues?.find((status) => status.value === system?.ratingStatus) ?? ratingSystemStatusValues[0];

    const analyzePart = analyze ? "/analyze" : "";
    const pathParts = [];

    if (groupId) {
      pathParts.push({ url: "/projectGroup", text: t("menu.projectGroups.title") });
      pathParts.push({ url: "/projectGroup" + analyzePart + "/" + groupId, text: group.name });
      pathParts.push({
        url: "/projectGroup" + analyzePart + "/" + groupId + "/project/" + system?.projectId,
        text: system?.projectName,
      });
    } else {
      pathParts.push({ url: `/project`, text: t("menu.project.title") });
      pathParts.push({ url: `/project/${system?.projectId}`, text: system?.projectName });
    }
    pathParts.push({ text: system?.systemSource || "" });

    return (
      <div id="new-rating-system-esg-header" className="k-d-flex k-flex-col k-gap-5">
        <div className="k-d-flex k-align-items-center">
          <Breadcrumb pathParts={pathParts} className="k-flex-1 k-mb-0" />
          <Button
            className="header-button"
            onClick={() => navigate(`/project/${system?.projectId}/dashboard/${system?.id}`)}
          >
            <BarChartIcon fontSize="inherit" />
            {t("newRatingSystem.esg.header.buttons.analysis")}
          </Button>
          <Button className="header-button" onClick={onOpenVersionDialog}>
            <HistoryIcon />
            {t("newRatingSystem.esg.header.buttons.history")}
          </Button>
          <Button
            className="header-button"
            onClick={() => window.open(`/api/rating/system/${system?.id}/files/download`, "_blank")}
          >
            <FileDownloadOutlinedIcon />
            {t("newRatingSystem.esg.header.buttons.downloadAllFiles")}
          </Button>
        </div>
        <div className="k-d-flex k-align-items-center">
          <div className="k-d-flex k-align-items-center k-gap-3">
            <span className="k-font-bold system-source">{system?.systemSource}</span>
            {!readOnly && (
              <DropDownList
                className="status-dropdown"
                fillMode="flat"
                data={ratingSystemStatusValues}
                textField="text"
                dataItemKey="value"
                value={value}
                popupSettings={{ width: "auto" }}
                valueRender={statusValueRender}
                itemRender={statusItemRender}
                onChange={onStatusChange}
              />
            )}
            {readOnly && (
              <StatusBadge className={"status-" + system?.ratingStatus} translate>
                {system?.ratingStatus}
              </StatusBadge>
            )}
          </div>
          <div className="k-flex-1 k-d-flex k-align-items-center k-justify-content-center">
            <TwoOptionsToggle
              value={showGoals}
              onChange={onShowGoalsChange}
              option1={{ value: false, label: t("sustainabilitySystem.step.table") }}
              option2={{ value: true, label: t("ratingSystem.label.goals") }}
            />
          </div>
          <div>
            <Button className="header-button" onClick={onOpenCustomChapter}>
              <EditNoteOutlinedIcon />
              {t("newRatingSystem.esg.header.buttons.customChapter")}
            </Button>
          </div>
        </div>
        <div className="k-d-flex k-flex-col k-gap-1">
          <div className="k-d-flex k-align-items-center">
            <span className="k-text-uppercase k-font-bold k-flex-1">
              {t("newRatingSystem.esg.header.masterData.title")}
            </span>
            <IconButton
              onClick={() =>
                setShowMasterData((prev) => {
                  onHeaderShowChange();
                  return !prev;
                })
              }
            >
              <KeyboardArrowUpOutlinedIcon
                fontSize="large"
                className={"show-master-data-arrow" + (showMasterData ? " k-rotate-180" : "")}
              />
            </IconButton>
          </div>
          <SystemData system={system} show={showMasterData} t={t} />
        </div>
        <ScoreHeader system={system} elementMap={elementMap} show={showMasterData && !showGoals} t={t} />
      </div>
    );
  }
);

NewRatingSystemESGHeader.propTypes = {
  groupId: PropTypes.string,
  analyze: PropTypes.bool,
  system: PropTypes.object,
  elementMap: PropTypes.object,
  group: PropTypes.object,
  onHeaderSizeChange: PropTypes.func,
  readOnly: PropTypes.bool,
  showGoals: PropTypes.bool,
  onOpenVersionDialog: PropTypes.func,
  onOpenCustomChapter: PropTypes.func,
  onHeaderShowChange: PropTypes.func,
  onStatusChange: PropTypes.func,
  onShowGoalsChange: PropTypes.func,
};
