import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import React from "react";

export const AdvancedTableHeaderExpandCollapse = ({ expandCollapse, expandLevel, collapseLevel }) => {
  return (
    <div className="advanced-table-expand-collapse">
      <Button color="primary" size="small" onClick={() => expandCollapse(expandLevel, false)}>
        <ExpandMoreIcon />
      </Button>
      <Button color="primary" size="small" onClick={() => expandCollapse(collapseLevel, true)}>
        <ExpandLessIcon />
      </Button>
    </div>
  );
};
