import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Fab from "@mui/material/Fab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { GeneralAttributesTable } from "../../components/GeneralAttributes/GeneralAttributesTable";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { ProjectAttributeDialog } from "../../components/ProjectAttributes/ProjectAttributeDialog/ProjectAttributeDialog";
import { ATTRIBUTE_STATUS_ACTIVE } from "../../constants/projectAttribute";
import { checkPopState } from "../../hooks";
import {
  clearAdminGeneralProjectAttribute,
  createAdminGeneralProjectAttribute,
  getAdminGeneralProjectAttributeList,
  resetGeneralProjectAttributePageState,
  updateAdminGeneralProjectAttribute,
  useAdminGeneralProjectAttributeClearResponse,
  useAdminGeneralProjectAttributeListResponse,
  useAdminGeneralProjectAttributeUpdateResponse,
  useGeneralProjectAttributePageLogic,
} from "../../hooks/projectAttribute";
import { showError, showSuccess } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import "./GeneralAttributes.scss";

export const GeneralAttributesPage = () => {
  const { t } = useTranslation();

  const response = useAdminGeneralProjectAttributeListResponse();
  const updateResponse = useAdminGeneralProjectAttributeUpdateResponse();
  const clearResponse = useAdminGeneralProjectAttributeClearResponse();
  const { searchValue, setSearchValue } = useGeneralProjectAttributePageLogic();

  const [data, setData] = useState([]);
  const [init, setInit] = useState(false);
  const [actionConfirmation, setActionConfirmation] = useState(null);
  const [clearConfirmation, setClearConfirmation] = useState(null);
  const [showDataAttribute, setShowDataAttribute] = useState({ open: false });

  const load = () =>
    getAdminGeneralProjectAttributeList()
      .then()
      .catch((error) => {
        console.error(error);
        showError(t("error.500"));
      });

  useEffect(() => {
    if (!checkPopState()) {
      resetGeneralProjectAttributePageState();
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      load();
    }
  }, [init]);

  useEffect(() => {
    if (response.data) {
      setData(response.data.reverse());
    }
  }, [response.data]);

  useEffect(() => {
    if (updateResponse.data !== null) {
      load();
    }
  }, [updateResponse.data]);

  const handleError = (error) => {
    console.error(error);
    showError(t("error.500"));
  };

  const actionHandler = useEvent(() => {
    if (actionConfirmation) {
      updateAdminGeneralProjectAttribute({ ...actionConfirmation, uuid: undefined })
        .then(load)
        .catch(handleError);
    }
    setActionConfirmation(null);
  });

  const clearHandler = useEvent(() => {
    if (clearConfirmation) {
      clearAdminGeneralProjectAttribute(clearConfirmation.id)
        .then(() => {
          showSuccess(t("generalAttributes.cleared"));
          load();
        })
        .catch(handleError);
    }
    setClearConfirmation(null);
  });

  const isUniqueName = useEvent((id, name) => !data?.some((item) => item.name === name && item.id !== id));

  const showAttributeModal = useEvent((data) => setShowDataAttribute({ open: true, data }));

  const showAddModal = useEvent(() => setShowDataAttribute({ open: true }));
  const hideActionConfirmation = useEvent(() => setActionConfirmation(null));
  const hideClearConfirmation = useEvent(() => setClearConfirmation(null));

  const loading = response.loading || updateResponse.loading || clearResponse.loading;

  return (
    <LoadingOverlay spinner active={loading} className="general-attributes-page page-with-table">
      <div className="page-header large flex-row">
        <div className="flex-col title-box no-margin">
          <h1>{t("generalAttributes.title")}</h1>
        </div>
        <div className="flex-col search-box no-margin">
          <SearchIcon />
          <input
            type="text"
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            placeholder={t("main.search")}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </div>
      </div>

      <div className="page-layout">
        <GeneralAttributesTable
          data={data}
          onEdit={showAttributeModal}
          onDeactivate={setActionConfirmation}
          onActivate={setActionConfirmation}
          onClear={setClearConfirmation}
        />
      </div>

      <ConfirmationDialog
        open={!!actionConfirmation}
        onClose={hideActionConfirmation}
        onConfirm={actionHandler}
        titleText={
          actionConfirmation?.status === ATTRIBUTE_STATUS_ACTIVE
            ? t("generalAttributes.activateConfirmationTitle")
            : t("generalAttributes.deactivateConfirmationTitle")
        }
        bodyText={
          actionConfirmation?.status === ATTRIBUTE_STATUS_ACTIVE
            ? t("generalAttributes.activateConfirmation")
            : t("generalAttributes.deactivateConfirmation")
        }
        confirmText={
          actionConfirmation?.status === ATTRIBUTE_STATUS_ACTIVE
            ? t("generalAttributes.activate")
            : t("generalAttributes.deactivate")
        }
        color="secondary"
      />

      <ConfirmationDialog
        open={!!clearConfirmation}
        onClose={hideClearConfirmation}
        onConfirm={clearHandler}
        titleText={t("generalAttributes.clearConfirmationTitle")}
        bodyText={t("generalAttributes.clearConfirmation")}
        confirmText={t("generalAttributes.clear")}
        color="secondary"
      />

      <div className="fabs">
        <Fab type="button" color="primary" onClick={showAddModal}>
          <AddIcon className="fab-svg" />
        </Fab>
      </div>
      <ProjectAttributeDialog
        data={showDataAttribute?.data}
        isUniqueName={isUniqueName}
        readOnly={false}
        onChange={async (attribute) => {
          try {
            if (!showDataAttribute?.data) {
              await createAdminGeneralProjectAttribute({
                ...attribute,
                uuid: undefined,
                status: ATTRIBUTE_STATUS_ACTIVE,
              });
            } else {
              await updateAdminGeneralProjectAttribute({ ...attribute, uuid: undefined });
            }
            load();
            setShowDataAttribute({ open: false });
          } catch (error) {
            console.error(error);
            if (error && error.type !== "abort") {
              if (error.status === 409) {
                showError(t("generalAttributes.error.409"));
              } else {
                showError(t("error.500"));
              }
            }
          }
        }}
        open={showDataAttribute?.open}
        onClose={() => setShowDataAttribute({ open: false })}
      />
    </LoadingOverlay>
  );
};
