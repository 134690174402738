import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleError } from "../../../helpers/error";
import { roleList, useRoleListResponse } from "../../../hooks/role";
import { setUserTenantRoles, useSetUserTenantRolesResponse } from "../../../hooks/tenant";
import { useTimeout } from "../../../hooks/utils/useTimeout";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";

import "./UserTenantRolesDialog.scss";

export const UserTenantRolesDialog = ({ user, tenant, open, onClose, onChange }) => {
  const { t } = useTranslation();

  const roleListResponse = useRoleListResponse();
  const tenantRoles = roleListResponse.data || [];
  const setUserTenantRolesResponse = useSetUserTenantRolesResponse();
  const [roleIds, setRoleIds] = useState([]);

  const [, setResponseTimeout] = useTimeout();

  useEffect(() => {
    roleList(tenant.id).then().catch(handleError);
    setRoleIds(tenant.roles.map((item) => item.id));
  }, []);

  const afterChange = () =>
    setResponseTimeout(
      setTimeout(() => {
        onChange();
        onClose();
      }, 0)
    );

  const toggleRoleChecked = (id) => {
    const updated = roleIds.filter((item) => item !== id);
    if (roleIds.indexOf(id) === -1) {
      updated.push(id);
    }
    setRoleIds(updated);
  };

  const onSubmit = () => setUserTenantRoles(user.id, tenant.id, roleIds).then(afterChange).catch(handleError);

  const checkedById = {};
  roleIds.forEach((item) => {
    checkedById[item] = true;
  });

  const loading = setUserTenantRolesResponse.loading || roleListResponse.loading;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {t("users.userTenantRolesHeader")}
        <IconButton className="close-button" size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingOverlay spinner active={loading} className="add-user-tenant-dialog auto-height">
          {tenantRoles.map((item, index) => (
            <FormControl key={index} fullWidth>
              <div>
                <Checkbox
                  checked={!!checkedById[item.id]}
                  id={"tenantRoleCheckbox_" + index}
                  label={item.name}
                  onChange={() => toggleRoleChecked(item.id)}
                  className="checkLine"
                />
                <label htmlFor={"tenantRoleCheckbox_" + index}>{item.name}</label>
              </div>
            </FormControl>
          ))}
          {!roleListResponse.loading && !tenantRoles.length && <p className="text-center">{t("main.empty")}</p>}
        </LoadingOverlay>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("main.cancel")}</Button>
        <Button onClick={onSubmit} color="primary">
          {t("main.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
