import React from "react";
import { StatusBadge } from "../../components/StatusBadge/StatusBadge";
import {
  STATUS_CLOSED,
  STATUS_NOT_RATED,
  STATUS_RATED,
  STATUS_REPORTING,
  STATUS_SCREENING,
  STATUS_VERIFICATION,
} from "../../constants/ratingSystem";
import { GROUPING_TYPE_INDICATOR } from "../../constants/sustainabilitySystem";
import { getSystemPageState } from "./NewRatingSystemESG";
import {
  calculateTrafficLight,
  createNestedArray,
  isExcluded,
  sortElementsByPosition,
} from "./NewRatingSystemESG.util";
import { conditionIsCategory, conditionIsCriterion, conditionIsIndicator } from "./NewRatingSystemESG.validation";

export const ratingSystemStatusValues = [
  { value: STATUS_SCREENING, text: `ratingSystem.status.${STATUS_SCREENING}` },
  { value: STATUS_VERIFICATION, text: `ratingSystem.status.${STATUS_VERIFICATION}` },
  { value: STATUS_REPORTING, text: `ratingSystem.status.${STATUS_REPORTING}` },
];

export const idField = "uuid";
export const parentField = "parentUuid";
export const expandField = "expanded";
export const selectedField = "selected";
export const subItemsField = "children";
export const editField = "inEdit";

export const statusValueRender = (element, value) => {
  if (!value) return element;
  return React.cloneElement(
    element,
    {
      ...element.props,
    },
    <StatusBadge className={"status-" + value.value} translate>
      {value.text}
    </StatusBadge>
  );
};

export const statusItemRender = (li, itemProps) => {
  const itemChildren = (
    <StatusBadge className={"status-" + itemProps.dataItem.value} translate>
      {itemProps.dataItem.text}
    </StatusBadge>
  );
  return React.cloneElement(li, li.props, itemChildren);
};

export const sortedVersions = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }
  return [...data].sort((a, b) => b.revisionNumber - a.revisionNumber);
};

export const validateStatusChange = (node, status) =>
  (status === STATUS_RATED || status === STATUS_CLOSED) &&
  ((!node.evaluationSystemScore && node.evaluationSystemScore !== 0) || !node.reason);

export const prepareSystemAndCreateData = (system) => {
  for (const key in system?.indicatorElementMap ?? {}) {
    const indicator = system.indicatorElementMap[key];
    if (!indicator?.type) indicator.type = GROUPING_TYPE_INDICATOR;
    indicator.indicatorStatus = indicator.indicatorStatus ?? STATUS_NOT_RATED;
    indicator.trafficLight = calculateTrafficLight(indicator);
  }

  const elementsMap = {
    ...(system?.groupingElementMap ?? {}),
    ...(system?.indicatorElementMap ?? {}),
  };

  for (const key in elementsMap) {
    if (elementsMap[key].excludedFromCalculation == null)
      elementsMap[key].excludedFromCalculation = isExcluded(elementsMap[key], parentField, elementsMap);
  }

  const { nestedArray, levelArray } = createNestedArray(
    elementsMap,
    parentField,
    subItemsField,
    expandField,
    editField,
    selectedField
  );

  return {
    map: elementsMap,
    data: sortElementsByPosition(nestedArray),
    levelArray,
  };
};

export const pauseEvent = (e) => {
  if (e.stopPropagation) e.stopPropagation();
  if (e.preventDefault) e.preventDefault();
  return false;
};

export const createRecursiveChange = (dataItem, field, value) => {
  const result = [];
  if (conditionIsIndicator(dataItem)) {
    result.push({ dataItem, field, value });
  } else if (conditionIsCriterion(dataItem) || conditionIsCategory(dataItem)) {
    dataItem.children.forEach((child) => {
      result.push(...createRecursiveChange(child, field, value));
    });
  } else if (!dataItem) {
    const { system } = getSystemPageState();
    for (const key in system?.indicatorElementMap)
      result.push(...createRecursiveChange(system?.indicatorElementMap[key], field, value));
  }

  return result;
};
