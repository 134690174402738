import React from "react";
import { useTranslation } from "react-i18next";
import { USERS_STATUS_DISABLED, USERS_STATUS_ENABLED } from "../../../constants/users";
import { FilterBlocks } from "../../FilterBlocks/FilterBlocks";
import "./UsersFilter.scss";

export const UsersFilter = ({ value, onChange, records, disabled }) => {
  const { t } = useTranslation();

  const amounts = {
    [USERS_STATUS_ENABLED]: 0,
    [USERS_STATUS_DISABLED]: 0,
  };

  (records || []).forEach(item => {
    if (item.status === USERS_STATUS_ENABLED) {
      amounts[USERS_STATUS_ENABLED]++;
    } else if (item.status === USERS_STATUS_DISABLED) {
      amounts[USERS_STATUS_DISABLED]++;
    } else {
      amounts[item.type]++;
    }
  });

  const filterOptions = [
    {
      name: USERS_STATUS_ENABLED,
      title: t('users.filter.' + USERS_STATUS_ENABLED),
      amount: amounts[USERS_STATUS_ENABLED]
    },
    {
      name: USERS_STATUS_DISABLED,
      title: t('users.filter.' + USERS_STATUS_DISABLED),
      amount: amounts[USERS_STATUS_DISABLED]
    },
  ];

  return (
    <FilterBlocks filterOptions={filterOptions} value={value} onChange={onChange} allowDeselect showReset={!!value}
                  disabled={disabled}/>
  );
};
