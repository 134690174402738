import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import { TextArea } from "@progress/kendo-react-inputs";
import i18next from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FILE_UPLOAD_RESTRICTIONS, MAX_FILE_SIZE_MB } from "../../../constants/fileUpload";
import {
  getFileSizeErrors,
  getRestrictedFileExtension,
  getRestrictedFileSize,
  validateFileRestrictions,
} from "../../../helpers/fileUpload";
import { addBreakLines, adjustRGBFormat, removeExtraBreakLines } from "../../../helpers/richtext";
import { resetUploadingFiles, useUploadingFiles } from "../../../hooks/fileUpload";
import { getProjectFileUrl, getProjectFiles, useProjectFilesResponse } from "../../../hooks/project";
import {
  addRatingReasonFile,
  ratingReasonFilesSubject,
  removeRatingReasonFile,
  setRatingReasonFiles,
  uploadRatingReasonFile,
  uploadRatingReasonFileKey,
  useRatingReasonFiles,
} from "../../../hooks/ratingIndicatorFiles";
import { ratingSystemSubject } from "../../../hooks/ratingSystem";
import { showError } from "../../../hooks/toast";
import kendoDeMessages from "../../../locales/kendo-de.json";
import kendoEnMessages from "../../../locales/kendo-en.json";
import { FilesTable } from "../../FilesTable/FilesTable";
import { FormController } from "../../FormController/FormController";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { isNotEmpty } from "../../RichText/utils";
import { SelectFileDialog } from "../../SelectFileDialog/SelectFileDialog";
import { EvaluationReason } from "../EvaluationReason/EvaluationReason";
import "./CurrentBlock.scss";

export const CurrentBlock = ({
  onChange,
  row,
  readOnly,
  projectId,
  ratingSystemId,
  permissions,
  restrictions = FILE_UPLOAD_RESTRICTIONS,
}) => {
  const { t } = useTranslation();
  const data = row || {};
  const { UID } = data;
  const evaluationStandard = data.evaluationStandard || "";
  const customEvaluationStandard = data.customEvaluationStandard || "";
  const [editingEvaluation, setEditingEvaluation] = useState(false);
  const [evaluationStandardDisplay, setEvaluationStandardDisplay] = useState("");
  const [evaluationStandardUpdated, setEvaluationStandardUpdated] = useState("");
  const scoreInputRef = useRef(null);
  const [reasonState, setReasonState] = useState("");
  const [popoverTarget, setPopoverTarget] = useState(null);
  const [selectFileOpen, setSelectFileOpen] = useState(null);
  const accessRestricted = ratingSystemSubject.getValue()?.data?.accessRestricted;
  const messages = i18next.language === "de" ? kendoDeMessages : kendoEnMessages;
  const isViewer = !accessRestricted || !!permissions.permissionReader;
  const [fileValidationError, setFileValidationError] = useState(false);
  const uploadInputRef = useRef(null);
  const files = useRatingReasonFiles();
  const uploadingFiles = useUploadingFiles().filter((item) => item.key === uploadRatingReasonFileKey);
  const projectFilesResponse = useProjectFilesResponse();

  useEffect(() => {
    const { UID, weightingFactor, evaluationSystemScore } = data || {};
    reset({
      UID: UID,
      weightingFactor: weightingFactor || "",
      evaluationSystemScore: evaluationSystemScore || (evaluationSystemScore === 0 ? 0 : ""),
    });
    setRatingReasonFiles(data.evaluationFiles || []);
    resetUploadingFiles();
    setReasonState(data.reason ?? "");
    setEvaluationStandardDisplay(customEvaluationStandard ? customEvaluationStandard : evaluationStandard || "");
  }, [UID]);

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: { UID: "", weightingFactor: "", evaluationSystemScore: "" },
  });

  useEffect(() => {
    if (!readOnly && row && scoreInputRef?.current) {
      scoreInputRef.current.focus();
    }
  }, [readOnly, row, scoreInputRef]);

  const saveChanges = (reasonEditorValue) => {
    const { weightingFactor, evaluationSystemScore } = getValues();
    const { maxSystemScore } = row;
    let value = +evaluationSystemScore;
    let weight = +weightingFactor;

    if (evaluationSystemScore !== "" && +evaluationSystemScore < 1) {
      value = 0;
      setValue("evaluationSystemScore", value);
    }

    if (+evaluationSystemScore > +maxSystemScore) {
      value = +maxSystemScore;
      setValue("evaluationSystemScore", value);
    }

    if (+weightingFactor > 10 || +weightingFactor < 1) {
      weight = Math.min(10, Math.max(1, +weightingFactor));
      setValue("weightingFactor", weight);
    }

    let reason = reasonState;
    if (reasonEditorValue) {
      const { event } = reasonEditorValue;
      let html = event?.html ?? "";

      html = adjustRGBFormat(html);
      html = addBreakLines(html);
      html = removeExtraBreakLines(html);

      reason = isNotEmpty(html) ? html : "";
      setReasonState(reason);
    }

    if (UID === getValues().UID)
      onChange({
        UID,
        fields: {
          reason,
          evaluationStandard,
          weightingFactor: weightingFactor === "" ? null : weight,
          evaluationSystemScore: evaluationSystemScore === "" ? null : value,
        },
        files: ratingReasonFilesSubject.getValue(),
      });
  };

  const postFilesLoading = !!uploadingFiles.length;
  const filesProcessed = (files || []).map((item) => ({
    ...item,
    editable: true,
    userTags: item.userTags || [],
  }));

  const availableFiles = (projectFilesResponse.data || []).filter((file) => {
    return !filesProcessed.find((item) => item.fileId === file.id);
  });

  const onFileSelect = (event) => {
    const errors = getFileSizeErrors(event, MAX_FILE_SIZE_MB);
    if (!errors) {
      [...event.target.files].forEach((file) => {
        const validationError = validateFileRestrictions(file, restrictions);
        setFileValidationError(!!validationError);
        if (!validationError) {
          uploadRatingReasonFile(projectId, file)
            .then((response) => {
              addRatingReasonFile({ fileId: response.id, name: response.originalName });
              saveChanges();
            })
            .catch((error) => {
              if (error && error.type !== "abort") {
                if (error.status === 409) {
                  showError(t("file.error.409"));
                } else {
                  showError(t("error.500"));
                }
              }
            });
        } else {
          showError(messages[`upload.${validationError}`]);
        }
      });
      uploadInputRef.current.value = "";
    } else {
      let errorMessage = "";
      errors.forEach((error) => {
        errorMessage +=
          t("file.tooLarge", {
            fileName: error.fileName,
            fileSize: error.fileSize,
          }) + "\n";
      });
      errorMessage += t("file.maxSize", { maxFileSize: MAX_FILE_SIZE_MB });
      showError(errorMessage);
    }
  };

  const removeFile = (file) => {
    removeRatingReasonFile(filesProcessed.findIndex((item) => item?.fileId === file?.fileId));
    saveChanges();
  };

  const oneProjectFileSelect = (value) => {
    addRatingReasonFile({ fileId: value.id, name: value.name, userTags: value.userTags || [] });
    saveChanges();
  };

  const onEvaluationStandardUpdatedToggle = () => {
    setEditingEvaluation(!editingEvaluation);
    setEvaluationStandardUpdated(evaluationStandardDisplay);
  };

  const onEvaluationStandardUpdatedChanged = () => {
    setEditingEvaluation(false);
    row.customEvaluationStandard = evaluationStandardUpdated;
    saveChanges();
  };

  const onEvaluationStandardDefaultClick = () => {
    setEvaluationStandardDisplay(evaluationStandard);
    setEvaluationStandardUpdated(null);
  };

  return (
    <LoadingOverlay spinner className="rating-reason-dialog auto-height">
      <div className="small-padding-bottom">
        <FormController
          control={control}
          name="weightingFactor"
          label={t("grouping.weight")}
          render={({ field, fieldState, label }) => (
            <>
              <TextField
                onWheel={(event) => event.preventDefault()}
                error={fieldState.invalid}
                type="number"
                fullWidth
                disabled={readOnly}
                inputProps={{ step: 0.0001, min: 1, max: 10 }}
                label={label}
                {...field}
                onBlur={() => saveChanges()}
              />
              <div className="chars-left-up">(1 - 10)</div>
            </>
          )}
        />
      </div>
      <div className="small-padding-bottom">
        <FormController
          control={control}
          name="evaluationSystemScore"
          label={t("ratingSystem.indicator.pastEvaluationSystemScore")}
          render={({ field, fieldState, label }) => (
            <>
              <TextField
                onWheel={(event) => event.preventDefault()}
                inputRef={scoreInputRef}
                error={fieldState.invalid}
                type="number"
                fullWidth
                disabled={readOnly || !data.maxSystemScore}
                inputProps={{ step: 0.0001, min: 1, max: data.maxSystemScore }}
                label={label}
                {...field}
                onBlur={() => saveChanges()}
              />
              <div className="chars-left-up">(1 - {data.maxSystemScore})</div>
            </>
          )}
        />
      </div>
      <div className="header-evaluation">
        <h4 className="evaluation-element">
          {t("grouping.evaluationStandard")}
          {!readOnly && (
            <IconButton
              size="small"
              className="edit-name-button"
              color={editingEvaluation ? "secondary" : "primary"}
              onClick={onEvaluationStandardUpdatedToggle}
            >
              {!editingEvaluation && <EditIcon />}
              {editingEvaluation && <CloseIcon />}
            </IconButton>
          )}

          {!readOnly && editingEvaluation && (
            <IconButton size="small" color="primary" onClick={onEvaluationStandardUpdatedChanged}>
              <CheckIcon />
            </IconButton>
          )}
          {!readOnly && editingEvaluation && evaluationStandardDisplay !== evaluationStandard && (
            <IconButton size="small" onClick={onEvaluationStandardDefaultClick}>
              <RefreshIcon className="horizontal-flip" color="secondary" />
            </IconButton>
          )}
        </h4>

        {!!readOnly && <pre>{evaluationStandardDisplay}</pre>}
        {!readOnly && editingEvaluation && (
          <TextArea
            className="edit-name-input"
            value={evaluationStandardDisplay}
            onChange={({ target }) => {
              setEvaluationStandardDisplay(target.value);
              setEvaluationStandardUpdated(target.value);
            }}
          />
        )}
        {!readOnly && !editingEvaluation && <pre>{evaluationStandardDisplay}</pre>}
      </div>
      <div className="header-evaluation">
        {!!readOnly && (
          <>
            {!!data.reason && (
              <>
                <h4 className="header">{t("ratingSystem.indicator.pastTitle")}</h4>
                <div className="rich-text-content" dangerouslySetInnerHTML={{ __html: data.reason }} />
              </>
            )}
            {!!isViewer && !!files.length && (
              <>
                <h4 className="links-header">{t("grouping.files")}</h4>
                <div className="links">
                  <FilesTable
                    noSearch
                    readOnly
                    files={files || []}
                    getFilePath={(item) => getProjectFileUrl(projectId, item.fileId)}
                  />
                </div>
              </>
            )}
          </>
        )}

        {!readOnly && (
          <>
            <h4 className="header">{t("ratingSystem.indicator.pastTitle")}</h4>
            <EvaluationReason value={data.reason} onBlur={saveChanges} ratingSystemId={ratingSystemId} />
            {!!isViewer && (
              <>
                <h4 className="links-header">
                  {t("grouping.files")}
                  {permissions.permissionDocumentUpload && (
                    <IconButton
                      color="primary"
                      size="small"
                      className="add-button"
                      onClick={(event) => setPopoverTarget(event.target)}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  )}
                </h4>
                {fileValidationError && (
                  <div className="file-upload-restrictions">
                    <div>
                      <small>
                        {t("fileUpload.allowedExtensions")} {getRestrictedFileExtension(FILE_UPLOAD_RESTRICTIONS)}
                      </small>
                    </div>
                    <div>
                      <small>
                        {t("fileUpload.maximumFileSize")} {getRestrictedFileSize(FILE_UPLOAD_RESTRICTIONS)}
                      </small>
                    </div>
                  </div>
                )}
                <input ref={uploadInputRef} type="file" color="primary" onChange={onFileSelect} hidden multiple />
                {!filesProcessed.length && <div className="empty text-center">{t("main.empty")}</div>}
                {(!!filesProcessed.length || postFilesLoading) && (
                  <FilesTable
                    noSearch
                    tagsReadOnly
                    files={filesProcessed}
                    uploadingFiles={uploadingFiles}
                    deleteFile={(item) => removeFile(item)}
                    updateFiles={onChange}
                    getFilePath={(item) => getProjectFileUrl(projectId, item.fileId)}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>

      <Popover
        open={!!popoverTarget}
        anchorEl={popoverTarget}
        onClose={() => setPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setPopoverTarget(null);
            uploadInputRef.current && uploadInputRef.current.click();
          }}
        >
          {t("ratingSystem.reason.addLocalFile")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPopoverTarget(null);
            setSelectFileOpen(true);
            getProjectFiles(projectId);
          }}
        >
          {t("ratingSystem.reason.addProjectFile")}
        </MenuItem>
      </Popover>

      <SelectFileDialog
        open={selectFileOpen}
        files={availableFiles}
        onClose={() => setSelectFileOpen(false)}
        isLoading={projectFilesResponse.loading}
        getFilePath={(item) => `/api/project/${projectId}/file/${item.fileId}`}
        onSelect={oneProjectFileSelect}
      />
    </LoadingOverlay>
  );
};
