import Checkbox from "@mui/material/Checkbox";
import { FormController } from "../FormController/FormController";

export const CheckboxFieldController = ({ control, name, label, readOnly }) => {
  return (
    <FormController
      control={control}
      name={name}
      label={label}
      render={({ field, label: labelnner }) => (
        <label className="checkbox-field-label">
          <Checkbox {...field} disabled={readOnly} checked={!!field.value} />
          <span className="label">{labelnner}</span>
        </label>
      )}
    />
  );
};
