import { Configuration, KpiRatingSystemEndpointApi } from "../generated-api";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";

const kpiRatingSystemApi = new KpiRatingSystemEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const kpiRatingSystemSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiRatingSystemResponse = hookFromSubject(kpiRatingSystemSubject);
export const getKpiRatingSystem = (id) =>
  promiseToSubject(kpiRatingSystemApi.apiRatingKpiIdGet({ id }), kpiRatingSystemSubject);

export const kpiRatingSystemEvaluateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiRatingSystemEvaluateResponse = hookFromSubject(kpiRatingSystemEvaluateSubject);
export const evaluateKpiRatingSystem = (request) =>
  promiseToSubject(kpiRatingSystemApi.apiRatingKpiEvaluatePut({ request }), kpiRatingSystemEvaluateSubject);

export const kpiRatingSystemAcceptChangesSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiRatingSystemAcceptChangesResponse = hookFromSubject(kpiRatingSystemAcceptChangesSubject);
export const acceptKpiRatingSystemChanges = (id) =>
  promiseToSubject(kpiRatingSystemApi.apiRatingKpiIdAcceptChangesPost({ id }), kpiRatingSystemAcceptChangesSubject);

kpiRatingSystemAcceptChangesSubject.subscribe(response => {
  if (!response.loading && response.data) {
    kpiRatingSystemSubject.next(response);
  }
});

export const kpiRatingSystemDeclineChangesSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiRatingSystemDeclineChangesResponse = hookFromSubject(kpiRatingSystemDeclineChangesSubject);
export const declineKpiRatingSystemChanges = (id) =>
  promiseToSubject(kpiRatingSystemApi.apiRatingKpiIdDeclineChangesPost({ id }), kpiRatingSystemDeclineChangesSubject);

export const kpiSelectedRowSubject = new BehaviorSubject(null);
export const useKpiSelectedRow = hookFromSubject(kpiSelectedRowSubject);
export const setKpiSelectedRow = value => kpiSelectedRowSubject.next(value);

export const kpiDashboardTimelineSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiDashboardTimelineResponse = hookFromSubject(kpiDashboardTimelineSubject);
export const getKpiDashboardTimeline = (id) =>
  promiseToSubject(kpiRatingSystemApi.apiRatingKpiIdTimelineGet({ id }), kpiDashboardTimelineSubject);

export const kpiDashboardRevisionSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiDashboardRevisionResponse = hookFromSubject(kpiDashboardRevisionSubject);
export const getKpiDashboardRevision = (id, revisionId, lastRevision) => {
  if (lastRevision) {
    promiseToSubject(kpiRatingSystemApi.apiRatingKpiIdGet({
      id
    }), kpiDashboardRevisionSubject);
  } else {
    promiseToSubject(kpiRatingSystemApi.apiRatingKpiIdRevisionRevisionIdGet({
      id,
      revisionId
    }), kpiDashboardRevisionSubject);
  }
};

export const kpiRatingSystemGroupingSubject = new BehaviorSubject([]);
export const useKpiRatingSystemGrouping = hookFromSubject(kpiRatingSystemGroupingSubject);
export const setKpiRatingSystemGrouping = grouping => kpiRatingSystemGroupingSubject.next(grouping);

export const kpiRatingSystemKPIChartSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiRatingSystemKPIChartResponse = hookFromSubject(kpiRatingSystemKPIChartSubject);
export const getKpiRatingSystemKPIChart = (id, revision, lastRevision) => {
  if (revision === lastRevision) {
    return promiseToSubject(kpiRatingSystemApi.apiRatingKpiIdChartKpiGet({ id }), kpiRatingSystemKPIChartSubject);
  } else {
    return promiseToSubject(kpiRatingSystemApi.apiRatingKpiIdChartKpiGet({ id }, { params: { revision } }), kpiRatingSystemKPIChartSubject);
  }
};

export const kpiRatingSystemBenchmarkChartSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiRatingSystemBenchmarkChartResponse = hookFromSubject(kpiRatingSystemBenchmarkChartSubject);
export const getKpiRatingSystemBenchmarkChart = (ratingSystemId, benchmarkId) =>
  promiseToSubject(kpiRatingSystemApi.apiRatingKpiRatingSystemIdChartBenchmarkBenchmarkIdGet({
    ratingSystemId,
    benchmarkId
  }), kpiRatingSystemBenchmarkChartSubject);

export const kpiRatingSystemKPIListSubject = new BehaviorSubject([]);
export const useKpiRatingSystemKPIList = hookFromSubject(kpiRatingSystemKPIListSubject);
export const setKpiRatingSystemKPIList = value => kpiRatingSystemKPIListSubject.next(value);

export const kpiRatingSystemRevisionsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiRatingSystemRevisionsResponse = hookFromSubject(kpiRatingSystemRevisionsSubject);
export const getKpiRatingSystemRevisions = (id) =>
  promiseToSubject(kpiRatingSystemApi.apiRatingKpiIdRevisionsGet({ id }), kpiRatingSystemRevisionsSubject);

export const kpiRatingSystemRevisionByIdSubject = new BehaviorSubject(new PromiseSubjectState());
export const useKpiRatingSystemRevisionByIdResponse = hookFromSubject(kpiRatingSystemRevisionByIdSubject);
export const getKpiRatingSystemRevisionById = (id, revisionId) =>
  promiseToSubject(kpiRatingSystemApi.apiRatingKpiIdRevisionRevisionIdGet({
    id,
    revisionId
  }), kpiRatingSystemRevisionByIdSubject);
