import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import jp from "jsonpath";
import React from "react";
import { roundDecimal } from "../../utils";

export const COLUMN_TYPE_TEXT = "TEXT";
export const COLUMN_TYPE_NUMBER = "NUMBER";
export const COLUMN_TYPE_PERCENT = "PERCENT";
export const COLUMN_TYPE_CHECKBOX = "CHECKBOX";

export const AdvancedTableCell = React.memo(({ readOnly, column, updateValue, row, submitted, passThrough }) => {
  const {
    type,
    showCondition,
    editableCondition,
    min,
    max,
    headerText,
    getHeaderText,
    required,
    template,
    editTemplate,
    field,
    getField,
    step,
    icon,
    getIcon,
    startAdornment,
    endAdornment,
    getStartAdornment,
    getEndAdornment,
    allowEmpty,
    maxLength,
    decimalPrecisionRatio,
    jsonPath,
  } = column;

  if (showCondition && !showCondition(row)) {
    return null;
  }

  const isEdit =
    !readOnly &&
    !column.readOnly &&
    (!editableCondition || editableCondition(row)) &&
    (!column.isDisabled || !column.isDisabled(row));

  const iconValue = getIcon ? getIcon(row) : icon;
  const startAdornmentValue = getStartAdornment ? getStartAdornment(row, passThrough) : startAdornment;
  const endAdornmentValue = getEndAdornment ? getEndAdornment(row, passThrough) : endAdornment;

  const isText = type === COLUMN_TYPE_TEXT;
  const isCheckbox = type === COLUMN_TYPE_CHECKBOX;
  const isPercent = type === COLUMN_TYPE_PERCENT;
  const isNumber = type === COLUMN_TYPE_NUMBER || isPercent;

  const fieldName = getField ? getField(row) : field;
  const headerTextValue = getHeaderText ? getHeaderText(row) : headerText;

  const onChange = (value) => {
    updateValue(row.UID, fieldName, value, jsonPath);
    column.onChange && column.onChange(row.UID, fieldName, value, passThrough);
  };

  const onBlur = (event) => {
    const value = event.target.value;
    if (isNumber) {
      let minValue = min;
      let maxValue = max;
      if (isPercent) {
        minValue = 0;
        maxValue = 100;
      }
      const numberValue = value === "" ? null : +value;
      let processedValue;
      if (value === "" || isNaN(numberValue)) {
        processedValue = null;
      } else if (!!maxValue && numberValue > maxValue) {
        processedValue = maxValue;
      } else if (undefined !== minValue && numberValue < minValue) {
        processedValue = minValue;
      } else {
        processedValue = numberValue;
      }
      onChange(processedValue);
      event.target.value = processedValue;
    } else {
      onChange(value);
    }
  };

  const inputProps = {
    placeholder: headerTextValue + (required ? " *" : ""),
    startAdornment: iconValue ? <InputAdornment position="start">{iconValue}</InputAdornment> : null,
    endAdornment: type === COLUMN_TYPE_PERCENT ? <InputAdornment position="end">%</InputAdornment> : null,
  };

  if (startAdornmentValue) {
    inputProps.startAdornment = <InputAdornment position="start">{startAdornmentValue}</InputAdornment>;
  }

  if (endAdornmentValue) {
    inputProps.endAdornment = <InputAdornment position="end">{endAdornmentValue}</InputAdornment>;
  }

  let fieldValue = allowEmpty && !row[fieldName] ? "" : row[fieldName];
  if (jsonPath) {
    fieldValue = jp.value(row, jsonPath);
  }

  if (type === COLUMN_TYPE_PERCENT || type === COLUMN_TYPE_NUMBER) {
    fieldValue = fieldValue != null ? roundDecimal(fieldValue, decimalPrecisionRatio) : "";
  }

  return (
    <>
      {!isEdit && (
        <>
          {!!iconValue && <span className="type-icon">{iconValue}</span>}
          {!!startAdornmentValue && <span className="adornment start-adornment">{startAdornmentValue}</span>}
          {(template ? (
            template(row, fieldName, passThrough)
          ) : isNumber ? (
            <div className="text-right">
              {fieldValue}
              {(fieldValue || !allowEmpty) && isPercent && fieldValue !== "" ? "%" : ""}
            </div>
          ) : (
            fieldValue
          )) || ""}
          {!!endAdornmentValue && <span className="adornment end-adornment">{endAdornmentValue}</span>}
        </>
      )}
      {!!isEdit && editTemplate ? editTemplate(row, column, updateValue, submitted, passThrough) : ""}
      {!!isEdit && !editTemplate && (isText || isNumber) && (!!fieldName || !!jsonPath) && (
        <FormControl fullWidth>
          <TextField
            error={submitted && row.error && row.error.field === fieldName}
            type={isNumber ? "number" : undefined}
            InputProps={inputProps}
            inputProps={isNumber ? { step: step || "any" } : maxLength ? { maxLength } : undefined}
            defaultValue={fieldValue}
            onBlur={onBlur}
          />
        </FormControl>
      )}
      {isCheckbox && !!fieldName && (
        <FormControl>
          <Checkbox
            key={row[fieldName]}
            disabled={!isEdit}
            color="primary"
            onClick={(event) => event.stopPropagation()}
            defaultChecked={!!row[fieldName]}
            onChange={() => onChange(!row[fieldName])}
          />
        </FormControl>
      )}
    </>
  );
});
