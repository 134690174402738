export const UserIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_170"
          data-name="Path 170"
          d="M23,23,3,23A20,20,0,1,0,23,3,20,20,0,0,0,3,23"
          transform="translate(-3 -3)"
          fill="#dfe2e8"
        />
      </clipPath>
    </defs>
    <g id="icon-user" transform="translate(-3 -3)">
      <g id="Mask_Group_10" data-name="Mask Group 10" transform="translate(3 3)" clip-path="url(#clip-path)">
        <g id="Group_120" data-name="Group 120">
          <path
            id="Path_162"
            data-name="Path 162"
            d="M23,23,3,23A20,20,0,1,0,23,3,20,20,0,0,0,3,23"
            transform="translate(-3 -3)"
            fill="#dfe2e8"
          />
          <path
            id="Path_163"
            data-name="Path 163"
            d="M15.667,13.667,9,13.667A6.667,6.667,0,1,0,15.667,7,6.667,6.667,0,0,0,9,13.667"
            transform="translate(4.333 1.889)"
            fill="#8792a2"
            stroke="#8792a2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_164"
            data-name="Path 164"
            d="M6.168,22.331A8.889,8.889,0,0,1,14.684,16h8.889c2.755,0,7.895,4.272,8,6.331.105.153-12.368,10.859-25.4,0"
            transform="translate(1.129 11.555)"
            fill="#8792a2"
            stroke="#8792a2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </g>
    </g>
  </svg>
);
