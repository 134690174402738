import { Configuration, TenantEndpointApi } from "../generated-api";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { createTablePageLogic } from "./tablePageLogic";

const tenantApi = new TenantEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const getTenantListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useTenantListResponse = hookFromSubject(getTenantListSubject);
export const getTenantList = () => promiseToSubject(tenantApi.apiSecurityTenantListGet(), getTenantListSubject);

export const setUserTenantRolesSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSetUserTenantRolesResponse = hookFromSubject(setUserTenantRolesSubject);
export const setUserTenantRoles = (userId, tenantId, roleIds) => promiseToSubject(tenantApi.apiSecurityTenantTenantIdRolesUserUserIdPut({
  roleIds, tenantId, userId
}, { returnResponse: true }), setUserTenantRolesSubject);

export const getTenantRevisionsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useGetTenantRevisionsResponse = hookFromSubject(getTenantRevisionsSubject);
export const getTenantRevisions = (id) =>
  promiseToSubject(tenantApi.apiSecurityTenantIdRevisionsGet({ id }), getTenantRevisionsSubject);

export const getTenantByIdSubject = new BehaviorSubject(new PromiseSubjectState());
export const useGetTenantByIdResponse = hookFromSubject(getTenantByIdSubject);
export const getTenantById = (id) =>
  promiseToSubject(tenantApi.apiSecurityTenantIdGet({ id }), getTenantByIdSubject);

export const postTenantSubject = new BehaviorSubject(new PromiseSubjectState());
export const useCreateTenantResponse = hookFromSubject(postTenantSubject);
export const createTenant = (tenantDTO) =>
  promiseToSubject(tenantApi.apiSecurityTenantPost({ tenantDTO }), postTenantSubject);

export const updateTenantSubject = new BehaviorSubject(new PromiseSubjectState());
export const useUpdateTenantResponse = hookFromSubject(updateTenantSubject);
export const updateTenant = (tenantDTO) =>
  promiseToSubject(tenantApi.apiSecurityTenantPut({ tenantDTO }), updateTenantSubject);

export const deleteTenantSubject = new BehaviorSubject(new PromiseSubjectState());
export const useDeleteTenantResponse = hookFromSubject(deleteTenantSubject);
export const deleteTenant = (id) =>
  promiseToSubject(tenantApi.apiSecurityTenantIdDelete({ id }), deleteTenantSubject);

export const [useTenantsPageLogic, resetTenantsPageState] = createTablePageLogic();
