import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Fab from "@mui/material/Fab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { TenantsDialog } from "../../components/Tenants/TenantsDialog/TenantsDialog";
import { TenantsFilter } from "../../components/Tenants/TenantsFilter/TenantsFilter";
import { TenantsTable } from "../../components/Tenants/TenantsTable/TenantsTable";
import { WhitelabelDialog } from "../../components/WhitelabelDialog/WhitelabelDialog";
import { checkPopState } from "../../hooks";
import {
  deleteTenant,
  getTenantList,
  resetTenantsPageState,
  useDeleteTenantResponse,
  useTenantListResponse,
  useTenantsPageLogic,
} from "../../hooks/tenant";
import { showError } from "../../hooks/toast";
import { useWhitelabelTenantResponse } from "../../hooks/whitelabel";
import "./Tenants.scss";

export const TenantsPage = () => {
  const { t } = useTranslation();

  const response = useTenantListResponse();
  const deleteResponse = useDeleteTenantResponse();
  const whitelabelForEditResponse = useWhitelabelTenantResponse();
  const { searchValue, setSearchValue, filterValue, setFilterValue } = useTenantsPageLogic();

  const [data, setData] = useState([]);
  const [init, setInit] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [rowDataWL, setRowDataWL] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [filtered, setFiltered] = useState([]);

  const load = () =>
    getTenantList()
      .then()
      .catch((error) => {
        console.error(error);
        showError(t("error.500"));
      });

  useEffect(() => {
    if (!checkPopState()) {
      resetTenantsPageState();
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      load();
    }
  }, [init]);

  useEffect(() => {
    if (response.data) {
      setData(response.data.reverse());
    }
  }, [response.data]);

  useEffect(() => {
    if (deleteResponse.data !== null) {
      load();
    }
  }, [deleteResponse.data]);

  const handleError = (error) => {
    console.error(error);
    showError(t("error.500"));
  };

  const deleteHandler = () => {
    deleteTenant(deleteConfirmation.id).then().catch(handleError);
    setDeleteConfirmation(null);
  };

  const loading = response.loading || deleteResponse.loading || whitelabelForEditResponse.loading;

  return (
    <LoadingOverlay spinner active={loading} className="tenants-page page-with-table">
      <div className="page-header large flex-row">
        <div className="flex-col title-box no-margin">
          <h1>{t("tenants.title")}</h1>
        </div>
        <div className="flex-col search-box no-margin">
          <SearchIcon />
          <input
            type="text"
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            placeholder={t("main.search")}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </div>
      </div>

      <div className="page-layout">
        <TenantsFilter onChange={setFilterValue} value={filterValue} records={filtered} />
        <TenantsTable
          data={data}
          setFiltered={setFiltered}
          onEdit={(row) => setRowData(row)}
          onEditWhitelabel={(row) => setRowDataWL(row)}
          onDelete={(row) => setDeleteConfirmation(row)}
        />
      </div>

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={t("tenants.tenantDeleteConfirmationTitle")}
        bodyText={t("tenants.tenantDeleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />

      <TenantsDialog rowData={rowData} onSaved={load} onClose={() => setRowData(null)} />

      <WhitelabelDialog rowData={rowDataWL} onClose={() => setRowDataWL(null)} />

      <div className="fabs">
        <Fab type="button" color="primary" onClick={() => setRowData({})}>
          <AddIcon className="fab-svg" />
        </Fab>
      </div>
    </LoadingOverlay>
  );
};
