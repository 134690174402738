let tenant = null;
let policy = null;
let clientId = null;

export const getAuthVariables = () => {
  return fetch(`${window.location.origin}/config.json`)
    .then(response => response.json())
    .then(config => {
      tenant = config.tenant;
      policy = config.policy;
      clientId = config.clientId;
    });
};

export const msalConfig = () => ({
  auth: {
    clientId,
    authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${policy}`,
    knownAuthorities: [tenant + '.b2clogin.com'],
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
});

export const loginRequest = () => ({
  scopes: ["openid", "profile", clientId]
});

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
