import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import ListSubheader from "@mui/material/ListSubheader";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useActionSuggestionResponse } from "../../../hooks/action";
import { findInValue } from "../../../utils";

const filterOption = (option, search) =>
  !search || findInValue(option.name, search) || !!(option.listTags || []).find((item) => findInValue(item, search));

export const AddActionsTableFooter = ({
  addCustomAction,
  addAction,
  sustainabilityIndicatorElementUuid,
  originIndicatorElementUuid,
  sustainabilityGroupingElementUuid,
  originGroupingElementUuid,
  kpiSystemElementUuid,
}) => {
  const { t } = useTranslation();
  const [nameValue, setNameValue] = useState("");
  const [options, setOptions] = useState([]);
  const [reset, setReset] = useState(false);
  const actionSuggestionResponse = useActionSuggestionResponse();

  const elementUuidMatches = (item) => {
    const uuid = item.sustainabilityElementUuid;
    return (
      !!uuid &&
      (uuid === sustainabilityIndicatorElementUuid ||
        uuid === originIndicatorElementUuid ||
        uuid === sustainabilityGroupingElementUuid ||
        uuid === originGroupingElementUuid ||
        uuid === kpiSystemElementUuid)
    );
  };

  useEffect(() => {
    if (!actionSuggestionResponse.loading) {
      const suggestion = actionSuggestionResponse.data || {};
      const usedNames = {};
      const favourite = (suggestion.favourite || []).filter((item) => {
        if (!elementUuidMatches(item)) return false;

        if (!usedNames[item.name]) {
          usedNames[item.name] = true;
          return true;
        }

        return false;
      });

      const global = suggestion.global || [];
      const suggested = [];

      if (favourite.length) {
        suggested.push({ ...favourite[0], favorite: -1 });
      }

      setOptions([...suggested, ...favourite.map((item) => ({ ...item, favorite: 1 })), ...global]);
    }
  }, [actionSuggestionResponse.loading]);

  const createAction = () => {
    addCustomAction(nameValue);
    setNameValue("");
    setReset(!reset);
  };

  const onKeyUp = (event) => {
    if (event.key === "Enter" && nameValue) {
      createAction();
    }
  };

  const onChange = (event, option) => {
    if (!!option && "object" === typeof option) {
      addAction(option);
      setNameValue("");
      setReset(!reset);
    }
  };

  const renderGroup = (params) => [
    params.group !== -1 && (
      <ListSubheader key={params.key} component="div">
        {params.group === 1 ? t("ratingSystem.actions.favorites") : t("ratingSystem.actions.other")}
      </ListSubheader>
    ),
    params.children,
  ];

  return (
    <div className="indicator-actions-table-footer">
      <Autocomplete
        key={reset}
        options={options}
        freeSolo
        renderOption={(props, option, { selected }) => {
          if (option.favorite === -1) {
            return (
              <li {...props}>
                <Box className="favorite-option">
                  <span className="favorite-option-title">{t("ratingSystem.actions.suggestion")}:</span> {option.name}
                </Box>
              </li>
            );
          } else {
            return (
              <li {...props}>
                <Box>{option.name}</Box>
              </li>
            );
          }
        }}
        onInputChange={(event) => setNameValue(event.target.value)}
        getOptionLabel={(option) => option?.name || ""}
        renderInput={(params) => (
          <>
            <TextField {...params} label="" placeholder={t("ratingSystem.actions.addPlaceholder")} />
            {!!nameValue && (
              <IconButton
                size="small"
                color="primary"
                className="add-button"
                onClick={createAction}
                title={t("main.add")}
              >
                <CheckIcon />
              </IconButton>
            )}
          </>
        )}
        filterOptions={(optionsFilter, { inputValue }) =>
          optionsFilter.filter((item) => filterOption(item, inputValue))
        }
        onKeyUp={onKeyUp}
        onChange={onChange}
        groupBy={(option) => option.favorite}
        renderGroup={renderGroup}
      />
    </div>
  );
};

AddActionsTableFooter.propTypes = {
  addCustomAction: PropTypes.func.isRequired,
  addAction: PropTypes.func.isRequired,
  sustainabilityIndicatorElementUuid: PropTypes.string,
  originIndicatorElementUuid: PropTypes.string,
  sustainabilityGroupingElementUuid: PropTypes.string,
  originGroupingElementUuid: PropTypes.string,
  kpiSystemElementUuid: PropTypes.string,
};
