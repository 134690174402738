import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { TwoOptionsToggle } from "../../components/TwoOptionsToggle/TwoOptionsToggle";
import { INDICATOR_VALUE_TYPE_RANGE, INDICATOR_VALUE_TYPE_TEXT } from "../../constants/sustainabilitySystem";
import { DEFAULT_UNIT, UNIT_OPTIONS } from "../../constants/unit";
import { resetUploadingFiles, useUploadingFiles } from "../../hooks/fileUpload";
import {
  addIndicatorFile,
  removeIndicatorFile,
  setIndicatorFiles,
  uploadIndicatorFile,
  useIndicatorFiles,
} from "../../hooks/indicatorFiles";
import { FilesList } from "../FilesList/FilesList";
import { IndicatorValueInput } from "../IndicatorValueInput/IndicatorValueInput";
import { LinksList } from "../LinksList/LinksList";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import "./IndicatorDataDialog.scss";
import { IndicatorValueRangeDialog } from "./IndicatorValueRangeDialog/IndicatorValueRangeDialog";

export const IndicatorDataDialog = ({ onClose, onChange, row, readOnly, submitted }) => {
  const { t } = useTranslation();
  const data = row || {};
  const { UID } = data;
  const [standard, setStandard] = useState("");
  const [valueRangeDialogOpen, setValueRangeDialogOpen] = useState(false);
  const [unitValue, setUnitValue] = useState("-");
  const [valueType, setValueType] = useState("");
  const [allowCustomValue, setAllowCustomValue] = useState(false);
  const [valueRangeList, setValueRange] = useState([]);
  const [links, setLinks] = useState([]);
  const files = useIndicatorFiles();
  const uploadingFiles = useUploadingFiles();
  const [visibleOnTable, setVisibleOnTable] = useState(false);

  const { control, setValue, getValues } = useForm({
    defaultValues: { preview: "" },
  });

  useEffect(() => {
    setIndicatorFiles(data.evaluationStandardFiles || []);
    setLinks(data.evaluationStandardLinks || []);
    setStandard(data.evaluationStandard || "");
    setUnitValue(data.unit || "");
    setValueType(data.valueType || INDICATOR_VALUE_TYPE_TEXT);
    setAllowCustomValue(!!data.allowCustomValue);
    setValueRange(data.valueRangeList || []);
    setVisibleOnTable(data.visibleOnTable);
    resetUploadingFiles();
  }, [UID]);

  const onChangeValueType = (valueRangeListInner, allowCustomValueInner) => {
    setValue("preview", "");
    setAllowCustomValue(allowCustomValueInner);
    setValueRange(valueRangeListInner);
  };

  const onSave = () => {
    onChange({ UID, standard, links, files, unitValue, valueType, valueRangeList, allowCustomValue, visibleOnTable });
    onClose();
  };

  const getFilePath = useCallback((fileId) => {
    return `/api/sustainability/indicator/file/${fileId}/preview`;
  }, []);

  const postFilesLoading = !!uploadingFiles.length;

  return (
    <LoadingOverlay spinner className="indicator-data-dialog auto-height">
      <h2>{t("grouping.unitHeader")}</h2>
      <pre className="unit">
        {t("grouping.unitLabel")}
        &nbsp;
        {
          <Select
            className="selectMargin"
            cacheOptions
            value={{ label: unitValue }}
            defaultValue={{ value: DEFAULT_UNIT }}
            onChange={(event) => setUnitValue(event?.value)}
            options={UNIT_OPTIONS}
            isClearable={true}
            isDisabled={!!readOnly}
          />
        }
        &nbsp;
        {t("grouping.unitPostLabel")}
      </pre>

      <div className="dialog-body">
        <div className="flex-row">
          <h2>{t("grouping.valueType.title")}</h2>
          <div className="flex-auto" />
          <TwoOptionsToggle
            value={visibleOnTable}
            onChange={setVisibleOnTable}
            disabled={readOnly}
            option1={{ value: false, label: t("grouping.inactive") }}
            option2={{ value: true, label: t("grouping.active") }}
          />
        </div>
        <div className="value-type">
          <div>
            <label>
              <Radio
                color="primary"
                name="valueType"
                checked={valueType === INDICATOR_VALUE_TYPE_TEXT}
                onChange={() => {
                  setValue("preview", "");
                  setValueType(INDICATOR_VALUE_TYPE_TEXT);
                }}
                disabled={readOnly || !visibleOnTable}
              />
              <span className="label">{t("grouping.valueType.TEXT")}</span>
            </label>
          </div>
          <div>
            <label>
              <Radio
                color="primary"
                name="valueType"
                checked={valueType === INDICATOR_VALUE_TYPE_RANGE}
                onChange={() => {
                  setValue("preview", "");
                  setValueType(INDICATOR_VALUE_TYPE_RANGE);
                }}
                disabled={readOnly || !visibleOnTable}
              />
              <span className="label">{t("grouping.valueType.RANGE")}</span>
            </label>
            {!readOnly && (
              <IconButton
                color="primary"
                size="small"
                onClick={() => setValueRangeDialogOpen(true)}
                disabled={valueType !== INDICATOR_VALUE_TYPE_RANGE || !visibleOnTable}
              >
                <EditIcon />
              </IconButton>
            )}
          </div>
          {valueType === INDICATOR_VALUE_TYPE_RANGE && (
            <IndicatorValueInput
              value={getValues("preview")}
              valueType={valueType}
              allowCustomValue={allowCustomValue}
              valueRangeList={valueRangeList}
              title={t("main.preview")}
              control={control}
              name="preview"
              unit={unitValue}
            />
          )}
        </div>
        {!!readOnly && (
          <div>
            <h2>{t("grouping.evaluationStandard")}</h2>
            <pre>{standard}</pre>
          </div>
        )}
        {!readOnly && (
          <>
            <h2>{t("grouping.evaluationStandard")}</h2>
            <TextField
              error={!!submitted && !standard}
              fullWidth
              className="evaluation-standard"
              multiline
              value={standard}
              onChange={(event) => setStandard(event.target.value)}
            />
          </>
        )}
        <LinksList links={links} onChange={setLinks} readOnly={readOnly} />
        <FilesList
          files={files}
          readOnly={readOnly}
          addFile={addIndicatorFile}
          removeFile={removeIndicatorFile}
          uploadFile={uploadIndicatorFile}
          getFilePath={getFilePath}
        />
      </div>

      <div className="buttons text-right">
        {!!readOnly && <Button onClick={() => onClose()}>{t("main.close")}</Button>}
        {!readOnly && (
          <>
            <Button
              onClick={() => {
                resetUploadingFiles();
                onClose();
              }}
            >
              {t("main.cancel")}
            </Button>
            <Button color="primary" onClick={onSave} disabled={postFilesLoading}>
              {t("main.save")}
            </Button>
          </>
        )}
      </div>

      <IndicatorValueRangeDialog
        open={valueRangeDialogOpen}
        valueRangeList={valueRangeList}
        allowCustomValue={allowCustomValue}
        onClose={() => setValueRangeDialogOpen(false)}
        onChange={onChangeValueType}
      />
    </LoadingOverlay>
  );
};
