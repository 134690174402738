import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../../constants/main";
import { ROLE_STATUS_DISABLED, ROLE_STATUS_ENABLED } from "../../../constants/role";
import { useRolesPageLogic } from "../../../hooks/role";
import { processMessage } from "../../../utils";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import "./RolesTable.scss";

export const RolesTable = ({ data, loading, onDelete, onEdit, setFiltered, readOnly }) => {
  const { t } = useTranslation();

  const { searchValue, filterValue, sortValue, onSort, page, rowsPerPage, setPage, setRowsPerPage } =
    useRolesPageLogic();
  const [processed, setProcessed] = useState([]);

  useEffect(() => {
    if (!loading) {
      let updated = [...data];
      // search
      const searchQuery = String(searchValue).trim().toLowerCase();
      if (searchQuery) {
        updated = updated.filter((item) => {
          return (
            String(item.name).toLowerCase().indexOf(searchQuery) !== -1 ||
            String(item.description).toLowerCase().indexOf(searchQuery) !== -1 ||
            !!(item.listTags || []).find((tag) => String(tag).toLowerCase().indexOf(searchQuery) !== -1)
          );
        });
      }
      setFiltered(updated);
      updated = updated.filter((item) => {
        if (filterValue === ROLE_STATUS_ENABLED) {
          return item.status === ROLE_STATUS_ENABLED;
        }
        if (filterValue === ROLE_STATUS_DISABLED) {
          return item.status === ROLE_STATUS_DISABLED;
        }
        return true;
      });
      // sort
      updated.sort((a, b) => {
        if (a.id && !b.id) {
          return 1;
        }
        if (b.id && !a.id) {
          return -1;
        }
        if (sortValue.field) {
          if (a[sortValue.field] > b[sortValue.field]) {
            return sortValue.desc ? -1 : 1;
          }
          if (a[sortValue.field] < b[sortValue.field]) {
            return sortValue.desc ? 1 : -1;
          }
        }
        return 0;
      });
      setProcessed(updated);
      if (page * rowsPerPage > updated.length) {
        setPage(Math.floor(updated.length / rowsPerPage));
      }
    }
  }, [loading, searchValue, sortValue, filterValue, page, rowsPerPage, data]);

  const pagination = {
    labelRowsPerPage: t("roles.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: processed.length,
    page: page,
    show: processed.length > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pagePagination) => setPage(pagePagination),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const paged = processed.length > rowsPerPage ? [...processed].splice(page * rowsPerPage, rowsPerPage) : processed;

  const columns = [
    {
      field: "name",
      headerText: t("roles.name"),
      headerClassName: "name-column",
      sortable: true,
    },
    {
      field: "description",
      headerText: t("roles.description"),
      headerClassName: "description-column",
      sortable: true,
    },
    {
      field: "status",
      headerText: t("roles.status"),
      headerClassName: "status-column",
      sortable: true,
      className: (row) => (row.status === ROLE_STATUS_ENABLED ? "active" : "inactive"),
      template: (row) => <span>{t("role.status." + row.status)}</span>,
    },
    {
      field: "roleIsDefault",
      headerClassName: "default-column-header",
      headerText: "",
      sortable: true,
      className: "default-column",
      template: (row) => !!row.roleIsDefault && <span>{t("roles.default")}</span>,
    },
  ];

  if (!readOnly) {
    columns.push({
      headerText: "",
      headerClassName: "actions-column-header",
      className: "actions-column",
      template: (row) => {
        return (
          <div className="column-actions">
            <IconButton color="primary" size="small" onClick={() => onEdit(row)}>
              <EditIcon />
            </IconButton>
            <IconButton size="small" onClick={() => onDelete(row)}>
              <DeleteOutlineIcon />
            </IconButton>
          </div>
        );
      },
    });
  }

  return (
    <SimpleTableWithSort
      className="roles-table"
      data={paged}
      sortValue={sortValue}
      onSort={onSort}
      pagination={pagination}
      columns={columns}
    />
  );
};
