import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React from "react";
import { minMaxDecimals } from "../../../utils";

const maxAndDecimals = (value, max, step) => {
  if (!value) {
    return value;
  }
  return minMaxDecimals(+value, 0, max, String(step).split(".")[1].length);
};

export const ActionNumberTemplate = ({ row, field, passThrough, max, step, required, readOnly }) => {
  const { onValueChange, submitted } = passThrough || {};

  if (!row) {
    return null;
  }

  if (readOnly || (passThrough || {}).readOnly) {
    return row[field] || null;
  }

  return (
    <FormControl fullWidth key={row[field]}>
      <TextField
        error={submitted && required && !row[field]}
        type={"number"}
        key={row.UID}
        inputProps={{ max, step }}
        defaultValue={row[field] || ""}
        onBlur={(event) => onValueChange(row, field, maxAndDecimals(event.target.value, max, step))}
      />
    </FormControl>
  );
};
