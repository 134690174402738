import React from 'react';
import "./SystemScoreTable.scss";
import { useTranslation } from "react-i18next";
import { SystemScoreTableRow } from "./SystemScoreTableRow";

export const SystemScoreTableESG = ({ data, koFailed }) => {
  const { t } = useTranslation();

  const { score, superScore, potentialScore, potentialSuperScore, actualAward, potentialAward } = data || {};

  return (
    <div className="system-score-table">
      <span className="title left"/>
      <span className="title">{t('dashboard.awardTable.systemScore')}</span>
      <span className="title">{t('dashboard.awardTable.superScore')}</span>
      <span className="title">{t('dashboard.awardTable.award')}</span>

      <SystemScoreTableRow
        position={{ name: t('dashboard.awardTable.actual') }}
        systemScore={score}
        superScore={superScore}
        award={!koFailed ? actualAward : []}
      />

      {potentialScore !== score &&
      <SystemScoreTableRow
        position={{ name: t('dashboard.awardTable.potential'), potential: true }}
        systemScore={potentialScore}
        superScore={potentialSuperScore}
        award={potentialAward}
      />
      }

      {potentialScore === score &&
      <SystemScoreTableRow position={{ name: t('dashboard.awardTable.potential'), potential: true }} systemScore={''}
                           superScore={''}
                           award={''}
      />
      }
    </div>
  );
};
