import { BehaviorSubject } from "rxjs";
import { hookFromSubject } from "react-rxjs-easy";
import { uploadFile } from "./fileUpload";

export const uploadRatingReasonFileKey = 'ratingReason';
export const uploadRatingReasonFile = (id, file) => uploadFile(file, `/api/project/${id}/file/temporary`, uploadRatingReasonFileKey);

export const ratingReasonFilesSubject = new BehaviorSubject([]);
export const useRatingReasonFiles = hookFromSubject(ratingReasonFilesSubject);
export const setRatingReasonFiles = (files = []) => ratingReasonFilesSubject.next(files);
export const addRatingReasonFile = file => ratingReasonFilesSubject.next([...ratingReasonFilesSubject.getValue(), file]);
export const removeRatingReasonFile = fileIndex => ratingReasonFilesSubject.next(ratingReasonFilesSubject.getValue().filter((item, index) => index !== fileIndex));
