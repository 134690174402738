import {
  areParentsChangedInRecursiveStructure,
  isObjectChanged,
  isObjectDeepChanged,
  mergeArray,
  mergeArrayOfObjects,
  mergeObject,
  mergeRecursive
} from "./merge";
import { GROUPING_TYPE_KPI } from "../../constants/kpiSystem";
import { mapKPISystemGroupings } from "../kpiSystem";

export const mergeKPISystem = (initial = {}, current = {}, updated = {}) => {
  let withConflicts = false;

  const fieldsToMerge = [
    'name',
    'systemSource',
    'systemVersion',
    'description',
    'status'
  ];

  const [merged, objectConflicts] = mergeObject(initial, current, updated, fieldsToMerge);
  if (objectConflicts) {
    withConflicts = true;
  }
  merged.modifiedDateTime = current.modifiedDateTime;

  const [projectTypes, projectTypesConflicts] = mergeArray(initial.projectTypes, current.projectTypes, updated.projectTypes);
  if (projectTypesConflicts) {
    withConflicts = true;
  }

  const groupingFieldsToMerge = [
    'UID',
    'id',
    'type',

    'name',
    'kpiName',
    'kpiUnit'
  ];

  const mergeFn = (a, b, c) => {
    let withConflict = false;
    let result;
    if (b) {
      const [mergedInner, mergeConflict] = mergeObject(a, b, c, groupingFieldsToMerge);
      result = mergedInner;
      if (mergeConflict) {
        withConflict = true;
      }
      if (result.type === GROUPING_TYPE_KPI) {
        const [files, filesConflict] = mergeArrayOfObjects(a?.files, b?.files, c?.files, null, "id");
        result.files = files;
        if (filesConflict) {
          withConflict = true;
        }
        const [links, linksConflict] = mergeArrayOfObjects(a?.links, b?.links, c?.links, null, "id");
        result.links = links;
        if (linksConflict) {
          withConflict = true;
        }
      }
    } else {
      result = c;
      withConflict = true;
    }

    return [result, withConflict];
  };

  const isChangedFn = (a, b) => {
    let isChanged = isObjectChanged(a, b, groupingFieldsToMerge);
    if (isObjectDeepChanged(a?.files, b?.files)
      || isObjectDeepChanged(a?.links, b?.links)) {
      isChanged = true;
    }
    return isChanged;
  };

  let children;
  if (areParentsChangedInRecursiveStructure(current.children, updated.children, 'groupingElements')) {
    children = updated.groupingElements;
  } else {
    const [mergedChildren, childrenConflicts] = mergeRecursive(
      mapKPISystemGroupings(initial.groupingElements),
      mapKPISystemGroupings(current.groupingElements),
      mapKPISystemGroupings(updated.groupingElements),
      mergeFn, isChangedFn, 'children', 'UID'
    );
    if (childrenConflicts) {
      withConflicts = true;
    }
    children = mergedChildren;
  }

  return [{ ...merged, projectTypes, children }, withConflicts];
};
