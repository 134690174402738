import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";
import * as en from "./locales/en.json";
import * as de from "./locales/de.json";
import {DEFAULT_LANGUAGE} from "./constants/language";

const allowedLanguages = ['en', 'de'];

i18n.on('languageChanged', () => {
  if (allowedLanguages.indexOf(i18n.language) === -1) {
    i18n.changeLanguage(DEFAULT_LANGUAGE);
  } else {
    setTimeout(() => {
      window.document.title = i18n.t('main.title');
    }, 0);
  }
});

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: en.default
      },
      de: {
        translation: de.default
      }
    },
    fallbackLng: DEFAULT_LANGUAGE,
    debug: true,
    interpolation: {
      escapeValue: false
    }
  });

window.document.title = i18n.t('main.title');

export default i18n;
