import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import React from "react";
import { setUserRolesPopover, useUserRolesPopover } from "../../../hooks/users";

export const UserRolesTooltipPopover = () => {
  const popover = useUserRolesPopover();
  const data = popover?.data;
  const roles = data?.roles || [];

  return (
    <Popover
      className={"tooltip-popover"}
      open={!!popover && roles.length}
      anchorEl={(popover || {}).target}
      onClose={() => setUserRolesPopover(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      disableRestoreFocus
    >
      {!!roles.length && roles.map((item, index) => <MenuItem key={index}>{item.name}</MenuItem>)}
    </Popover>
  );
};
