import { Configuration, TextBlockEndpointApi } from "../generated-api";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { createTablePageLogic } from "./tablePageLogic";
import { MIN_PAGE_SIZE } from "../constants/main";

const textBlockEndpointApi = new TextBlockEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const getSettingsTextblockListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSettingsTextblockListResponse = hookFromSubject(getSettingsTextblockListSubject);
export const getAdminProjectAttributeList = ({ page, itemsPerPage, sort, name }) => {
  const params = { page: page || 0, itemsPerPage: itemsPerPage || MIN_PAGE_SIZE };
  if (sort) {
    params.sort = sort;
  }
  if (name) {
    params.search = name;
  }
  
  return promiseToSubject(textBlockEndpointApi.apiSettingsTextblockPageGet({ params }), getSettingsTextblockListSubject);
};

export const getSuggestedTextblockSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSuggestedTextblockResponse = hookFromSubject(getSuggestedTextblockSubject);
export const getSuggestedTextblockList = (args, { page, itemsPerPage, sort, name }) => {
  const params = { page: page || 0, itemsPerPage: itemsPerPage || MIN_PAGE_SIZE };
  if (sort) {
    params.sort = sort;
  }
  if (name) {
    params.search = name;
  }

  return promiseToSubject(textBlockEndpointApi.apiSettingsTextblockSuggestedRatingSystemIdIndicatorIdGet(args , { params }), getSuggestedTextblockSubject);
};

export const getSuggestedKpiTextblockSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSuggestedKpiTextblockResponse = hookFromSubject(getSuggestedKpiTextblockSubject);
export const getSuggestedKpiTextblockList = (args, { page, itemsPerPage, sort, name }) => {
  const params = { page: page || 0, itemsPerPage: itemsPerPage || MIN_PAGE_SIZE };
  if (sort) {
    params.sort = sort;
  }
  if (name) {
    params.search = name;
  }

  return promiseToSubject(textBlockEndpointApi.apiSettingsTextblockKpiSuggestedKpiRatingSystemIdKpiIdGet(args , { params }), getSuggestedTextblockSubject);
};


export const updateSettingsTextblockSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSettingsTextblockUpdateResponse = hookFromSubject(updateSettingsTextblockSubject);
export const updateSettingsTextblock = (request) =>
  promiseToSubject(textBlockEndpointApi.apiSettingsTextblockPut({ request }), updateSettingsTextblockSubject);

export const createSettingsTextblockSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSettingsTextblockCreateResponse = hookFromSubject(createSettingsTextblockSubject);
export const createSettingsTextblock = (request) =>
  promiseToSubject(textBlockEndpointApi.apiSettingsTextblockPost({ request }), createSettingsTextblockSubject);

export const deleteSettingsTextblockSubject = new BehaviorSubject(new PromiseSubjectState());
export const useSettingsTextblockDeleteResponse = hookFromSubject(deleteSettingsTextblockSubject);
export const deleteSettingsTextblock = (id) =>
  promiseToSubject(textBlockEndpointApi.apiSettingsTextblockIdDelete({ id }), deleteSettingsTextblockSubject);

export const [useTextblockPageLogic, resetTextblockPageState] = createTablePageLogic();

export const updatedTextblockAutoTaggedSubject = new BehaviorSubject(new PromiseSubjectState());
export const updatedTextblockAutoTagged = (ratingSystemId, indicatorId, textBlockId) =>
  promiseToSubject(textBlockEndpointApi.apiSettingsTextblockAutoTaggedTextBlockIdRatingSystemIdIndicatorIdPut({ratingSystemId, indicatorId, textBlockId}), updatedTextblockAutoTaggedSubject);

export const updatedKpiTextblockAutoTaggedSubject = new BehaviorSubject(new PromiseSubjectState());
export const updatedKpiTextblockAutoTagged = (kpiRatingSystemId, kpiId, textBlockId) =>
  promiseToSubject(textBlockEndpointApi.apiSettingsTextblockKpiAutoTaggedTextBlockIdKpiRatingSystemIdKpiIdPut({kpiRatingSystemId, kpiId, textBlockId}), updatedTextblockAutoTaggedSubject);


