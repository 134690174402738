import { useEffect } from "react";
import { useNavigate } from "react-router";

export const Redirect = ({ to }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, []);

  return null;
};
