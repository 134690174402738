import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import PropTypes from "prop-types";

export const BaseBlock = ({ title, className, id, children }) => {
  return (
    <Accordion defaultExpanded className={"k-d-flex k-flex-col k-gap-2" + (className ? ` ${className}` : "")} id={id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className="element-data-header k-text-uppercase k-font-bold">
        {title}
      </AccordionSummary>
      <AccordionDetails className="k-px-0 k-py-5">{children}</AccordionDetails>
    </Accordion>
  );
};

BaseBlock.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
};
