import EditIcon from "@mui/icons-material/Edit";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { InfoDialog } from "../../components/InfoDialog/InfoDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { SimpleTableWithSort } from "../../components/SimpleTableWithSort/SimpleTableWithSort";
import { StatusBadge } from "../../components/StatusBadge/StatusBadge";
import { SustainabilitySystemCopyToTenantDialog } from "../../components/SustainabilitySystem/SustainabilitySystemCopyToTenantDialog/SustainabilitySystemCopyToTenantDialog";
import { SustainabilitySystemDetailDialog } from "../../components/SustainabilitySystem/SustainabilitySystemDetailDialog/SustainabilitySystemDetailDialog";
import { StatusFilter } from "../../components/SustainabilitySystem/SustainabilitySystemFilter/StatusFilter/StatusFilter";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../constants/main";
import {
  PERMISSION_SUSTAINABILITY_SYSTEM_CREATE,
  PERMISSION_SUSTAINABILITY_SYSTEM_EDIT,
  PERMISSION_SUSTAINABILITY_SYSTEM_MIGRATION,
} from "../../constants/permissions";
import { hasPermission } from "../../helpers/permission";
import { checkPopState } from "../../hooks";
import { showDialog, showMuiDialog } from "../../hooks/dialog";
import {
  cloneSustainabilitySystem,
  deleteSustainabilitySystem,
  getSustainabilitySystems,
  resetSystemsPageState,
  useSustainabilitySystemCloneResponse,
  useSustainabilitySystemDeleteResponse,
  useSustainabilitySystemsResponse,
  useSystemsPageLogic,
} from "../../hooks/system";
import { showError, showInfo, showSuccess } from "../../hooks/toast";
import { useTimeout } from "../../hooks/utils/useTimeout";
import { PlusSVG } from "../../svg";
import { isUserSuperAdmin, processMessage } from "../../utils";

const AuthorColumnTemplate = (props, field) => (
  <NavLink to={"/sustainabilitySystem/" + props.id} className="column-link">
    {!!props.readOnly && <LockOutlinedIcon />}
    <div className={props.readOnly ? "column-link-with-icon" : ""}>{props[field]}</div>
  </NavLink>
);

const NameColumnTemplate = (props, field) => (
  <NavLink to={"/sustainabilitySystem/" + props.id} className="column-link">
    {props[field]}
  </NavLink>
);

const StatusColumnTemplate = (props, t) =>
  !!props.status && (
    <StatusBadge className={"status-" + props.status}>{t("sustainabilitySystem.status." + props.status)}</StatusBadge>
  );

const ActionsColumnTemplate = (props, setDetailsDialog, setDeleteConfirmation, rootProps, setKebabPopoverTarget) => (
  <>
    {hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_EDIT) && (
      <NavLink to={"/sustainabilitySystem/" + props.id + "?mode=edit"}>
        <IconButton color="primary" size="small" disabled={props.readOnly}>
          <EditIcon />
        </IconButton>
      </NavLink>
    )}

    {!hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_EDIT) && (
      <NavLink to={"/sustainabilitySystem/" + props.id}>
        <IconButton color="primary" size="small">
          <VisibilityIcon />
        </IconButton>
      </NavLink>
    )}

    {hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_EDIT) && (
      <IconButton size="small" onClick={(event) => setKebabPopoverTarget({ target: event.target, props, rootProps })}>
        <MoreVertIcon />
      </IconButton>
    )}
  </>
);

export const SustainabilitySystemsPage = () => {
  const { t } = useTranslation();
  const response = useSustainabilitySystemsResponse();
  const systems = response.data || [];

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [detailsDialog, setDetailsDialog] = useState({ open: false });
  const deleteResponse = useSustainabilitySystemDeleteResponse();
  const cloneResponse = useSustainabilitySystemCloneResponse();

  const {
    searchValue,
    setSearchValue,
    filterValue,
    setFilterValue,
    sortValue,
    setSortValue,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    resetState,
  } = useSystemsPageLogic();
  const [, setSearchTimeout] = useTimeout();

  const [processed, setProcessed] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [kebabPopoverTarget, setKebabPopoverTarget] = useState(null);

  const load = () => getSustainabilitySystems().catch(console.error);

  const isSuperAdmin = isUserSuperAdmin();

  useEffect(() => {
    if (!checkPopState()) {
      resetSystemsPageState();
    }
    load();
  }, []);

  useEffect(() => {
    if (!response.loading) {
      let updated = [...systems];
      // search
      if (searchValue) {
        updated = updated.filter(
          (item) =>
            (item.systemSource || "").toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
            (item.name || "").toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
            (item.systemVersion || "").toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
            (item.description || "").toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        );
      }
      setFiltered(updated);
      if (filterValue) {
        updated = updated.filter((item) => item.status === filterValue);
      }
      // sort
      if (sortValue.field) {
        updated = updated.sort((a, b) => {
          if (a[sortValue.field] > b[sortValue.field]) {
            return sortValue.desc ? -1 : 1;
          } else if (a[sortValue.field] < b[sortValue.field]) {
            return sortValue.desc ? 1 : -1;
          } else {
            return 0;
          }
        });
      }
      setProcessed(updated);
      if (page * rowsPerPage > updated.length) {
        setPage(Math.floor(updated.length / rowsPerPage));
      }
    }
  }, [response.loading, searchValue, sortValue, page, rowsPerPage, filterValue]);

  const onSort = (field) =>
    setSortValue({
      field: field !== sortValue.field || !sortValue.desc ? field : null,
      desc: sortValue.field === field ? !sortValue.desc : false,
    });

  const search = (value) => {
    setSearchTimeout(setTimeout(() => setSearchValue(String(value || "").trim()), 500));
  };

  const afterChange = () => setPage(0) & load();

  const deleteHandler = () => {
    const sustainabilitySystemId = deleteConfirmation;
    setDeleteConfirmation(false);
    deleteSustainabilitySystem(sustainabilitySystemId).then(
      () => {
        showInfo(t("sustainabilitySystem.deletedMessage"));
        afterChange();
      },
      () => showError(t("sustainabilitySystem.deleteError"))
    );
  };

  const cloneHandler = (id) => {
    cloneSustainabilitySystem(id).then(
      () => {
        showSuccess(t("sustainabilitySystem.cloneCreatedSuccessMessage"));
        afterChange();
      },
      () => showError(t("sustainabilitySystem.cloneCreatedErrorMessage"))
    );
  };

  const openMigrateSystemDialog = (systemId) =>
    showMuiDialog((props) => <SustainabilitySystemCopyToTenantDialog systemId={systemId} {...props} />);

  const openDetailsDialog = (item) => {
    const sourceSystemItem = paged.find((value) => {
      return value.id === item.sourceSystemId;
    });
    showDialog({
      className: "medium",
      closeOnClickOutside: false,
      getContent: (onClose) => (
        <SustainabilitySystemDetailDialog item={item} sourceSystemItem={sourceSystemItem} onClose={onClose} />
      ),
    });
  };

  const pagination = {
    labelRowsPerPage: t("projects.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: processed.length,
    page: page,
    show: processed.length > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pagePagination) => setPage(pagePagination),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const paged = [...processed].splice(page * rowsPerPage, rowsPerPage);

  return (
    <div className="sustainability-systems page-with-table">
      <div className="page-header large flex-row">
        <div className="flex-col title-box no-margin">
          <h1>{t("sustainabilitySystems.title")}</h1>
        </div>
        <div className="flex-col search-box no-margin">
          <SearchIcon />
          <input
            type="text"
            key={resetState}
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            placeholder={t("main.search")}
            onChange={(event) => search(event.target.value)}
          />
        </div>
      </div>

      <div className="page-layout">
        <div className="flex-row-filter">
          <StatusFilter onChange={setFilterValue} value={filterValue} records={filtered} />
        </div>

        <LoadingOverlay
          spinner
          className="table-loading"
          active={response.loading || deleteResponse.loading || cloneResponse.loading}
        >
          <SimpleTableWithSort
            className="repaint"
            data={paged}
            pagination={pagination}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            sortValue={sortValue}
            onSort={onSort}
            columns={[
              {
                field: "systemSource",
                sortable: true,
                headerText: t("sustainabilitySystems.systemSource"),
                template: AuthorColumnTemplate,
              },
              {
                field: "name",
                sortable: true,
                headerText: t("sustainabilitySystems.name"),
                template: NameColumnTemplate,
              },
              {
                field: "systemVersion",
                sortable: true,
                headerText: t("sustainabilitySystems.systemVersion"),
              },
              {
                field: "status",
                sortable: true,
                className: "status-cell",
                headerText: t("sustainabilitySystems.status"),
                template: (props) => StatusColumnTemplate(props, t),
              },
              {
                className: "action-cell",
                headerClassName: "action-header-cell",
                headerText: t("main.actions"),
                template: (props, rootProps) =>
                  ActionsColumnTemplate(
                    props,
                    setDetailsDialog,
                    setDeleteConfirmation,
                    rootProps,
                    setKebabPopoverTarget
                  ),
              },
            ]}
          />
        </LoadingOverlay>
      </div>

      {hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_CREATE) && (
        <div className="fabs">
          <Fab type="button" color="primary">
            <NavLink to="/sustainabilitySystem/create" className="link">
              <PlusSVG />
            </NavLink>
          </Fab>
        </div>
      )}

      <Popover
        open={!!kebabPopoverTarget}
        anchorEl={(kebabPopoverTarget || {}).target}
        onClose={() => setKebabPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          disabled={!((kebabPopoverTarget || {}).props || {}).description}
          onClick={() => {
            setKebabPopoverTarget(null);
            setDetailsDialog({ ...kebabPopoverTarget.props, open: true });
          }}
        >
          {t("sustainabilitySystems.description")}
        </MenuItem>
        {hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_CREATE) && (
          <MenuItem
            onClick={() => {
              setKebabPopoverTarget(null);
              cloneHandler((kebabPopoverTarget || {}).props?.id);
            }}
          >
            {t("main.clone")}
          </MenuItem>
        )}
        {hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_MIGRATION) && (
          <MenuItem
            onClick={() => {
              setKebabPopoverTarget(null);
              openMigrateSystemDialog((kebabPopoverTarget || {}).props?.id);
            }}
          >
            {t("sustainabilitySystems.migrate")}
          </MenuItem>
        )}
        <MenuItem
          disabled={isSuperAdmin ? false : (kebabPopoverTarget || {}).props?.readOnly}
          onClick={() => {
            setKebabPopoverTarget(null);
            setDeleteConfirmation((kebabPopoverTarget || {}).props?.id);
          }}
        >
          {t("main.delete")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setKebabPopoverTarget(null);
            openDetailsDialog((kebabPopoverTarget || {}).props);
          }}
        >
          {t("sustainabilitySystems.details")}
        </MenuItem>
      </Popover>

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        showLineBreaks
        titleText={t("sustainabilitySystem.deleteConfirmationTitle")}
        bodyText={t("sustainabilitySystem.deleteConfirmationUsedInProject")}
        confirmText={t("main.delete")}
        color="secondary"
      />

      <InfoDialog
        open={detailsDialog.open}
        onClose={() => setDetailsDialog({ ...detailsDialog, open: false })}
        titleText={t("sustainabilitySystems.descriptionTitle") + " " + detailsDialog.name}
        bodyText={detailsDialog.description}
      />
    </div>
  );
};
