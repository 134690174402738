import React from "react";
import { useTranslation } from "react-i18next";
import { TENANT_STATUS_ENABLED } from "../../../constants/tenant";
import { useUser } from "../../../hooks/auth";
import { useProjectCopyGenerateTImelineSubjectResponse, useProjectCopySubjectResponse } from "../../../hooks/project";
import { useUserCurrentTenantChangeResponse } from "../../../hooks/users";
import { SelectMigrationTargetDialog } from "../../SelectMigrationTargetDialog/SelectMigrationTargetDialog";
import { ProjectCopyConfirmationDialog } from "./ProjectCopyConfirmationDialog";

export const ProjectCopyToTenantDialog = React.memo(
  ({ projectId, projectName, refreshProjectList, onClose, ...props }) => {
    const { t } = useTranslation();
    const user = useUser();
    const [tenantId, setTenantId] = React.useState(null);
    const userCurrentTenantChangeResponse = useUserCurrentTenantChangeResponse();
    const projectCopySubjectResponse = useProjectCopySubjectResponse();
    const projectCopyGenerateTimelineSubjectResponse = useProjectCopyGenerateTImelineSubjectResponse();
    const availableTenants =
      user?.availableTenants?.filter(
        (item) => item.status === TENANT_STATUS_ENABLED && item.type === user?.tenant.type
      ) || [];

    const loading =
      projectCopySubjectResponse.loading ||
      projectCopyGenerateTimelineSubjectResponse.loading ||
      userCurrentTenantChangeResponse.loading;

    return (
      <>
        <SelectMigrationTargetDialog
          title={t("projects.copy.copyTitle") + " " + projectName}
          onSelect={setTenantId}
          migrationTargets={availableTenants}
          loading={loading}
          onClose={onClose}
          {...props}
        />
        <ProjectCopyConfirmationDialog
          projectId={projectId}
          projectName={projectName}
          tenant={availableTenants?.find((item) => item.id === tenantId)}
          closeParent={onClose}
          refreshProjectList={refreshProjectList}
          open={!!tenantId}
          onClose={() => setTenantId(null)}
        />
      </>
    );
  }
);
