import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEvent } from "../../../hooks/utils/useEvent";

export const KPIBenchmarkAutocomplete = React.memo(
  ({ options, onOpen, onClose, onValueChange, onTextChange, loading, value }) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);

    const onChange = useEvent((event, valueOnChange) => onValueChange(valueOnChange));

    return (
      <Autocomplete
        open={open}
        value={options.find((item) => item.id === value) || ""}
        onOpen={() => {
          setOpen(true);
          onOpen && onOpen();
        }}
        onClose={() => {
          setOpen(false);
          onClose && onClose();
        }}
        onInputChange={(event, newInputValue) => {
          if (!!event && event?.type !== "click") {
            onTextChange(newInputValue);
          }
        }}
        onChange={onChange}
        filterOptions={(x) => x}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        isOptionEqualToValue={(option, valueOptionSelected) => {
          return option?.id === valueOptionSelected || option?.id === valueOptionSelected?.id;
        }}
        options={options}
        loading={loading}
        lang={i18n.language}
        renderTags={(item) => (
          <div className="flex-row w-100">
            <div className="flex-auto">{item.name}</div>
            {!!item.description && (
              <Tooltip title={item.description} placement="top">
                <InfoOutlinedIcon color="primary" />
              </Tooltip>
            )}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("dashboard.kpi.legend.benchmark")}
            InputProps={{
              ...params.InputProps,
              maxLength: 255,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          ></TextField>
        )}
      />
    );
  }
);
