import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import jp from "jsonpath";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PERMISSION_KPI_READ } from "../../../constants/permissions";
import {
  AWARD_CALCULATION_TYPE_POINT,
  GROUPING_TYPE_INDICATOR,
  SYSTEM_LOGIC_DGNB,
} from "../../../constants/sustainabilitySystem";
import { expandRowCondition } from "../../../helpers/advancedTable";
import { hasPermission } from "../../../helpers/permission";
import {
  copyElement,
  matchDropLogic,
  processAllIndicatorsWeighting,
  processMaxSystemScore,
  processMaxSystemScoreProportions,
  sortGroupings,
} from "../../../helpers/sustainabilitySystem";
import { showDialog } from "../../../hooks/dialog";
import { useEvent } from "../../../hooks/utils/useEvent";
import { GroupingElement } from "../../../models/GroupingElement";
import { CategorySVG, IndicatorSVG } from "../../../svg";
import { findRecursive, forEachRecursive } from "../../../utils";
import {
  calculateGroupingMaxScore,
  conditionIsCategory,
  conditionIsIndicator,
  conditionNotCategory,
  conditionNotIndicator,
  sumGroupingWeightedMaxSystemScore,
} from "../../../validation/sustainabilitySystem";
import { AdvancedTable } from "../../AdvancedTable/AdvancedTable";
import {
  COLUMN_TYPE_CHECKBOX,
  COLUMN_TYPE_NUMBER,
  COLUMN_TYPE_PERCENT,
  COLUMN_TYPE_TEXT,
} from "../../AdvancedTable/AdvancedTableCell";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { IndicatorDataDialog } from "../../IndicatorDataDialog/IndicatorDataDialog";
import { SearchBadge } from "../../SearchBadge/SearchBadge";
import { AddMenuPopover } from "./AddMenuPopover";
import { KPIEditTemplate, KPITemplate } from "./KPITemplate";
import { MaxSystemScoreTemplate } from "./MaxSystemScoreTemplate";
import "./SustainabilitySystemGrouping.scss";
import { WeightingColumnEditTemplate, WeightingColumnTemplate } from "./WeightingColumnTemplate";

const createIndicatorDataDialog = (row, changeEvaluationStandard, readOnly, submitted) => (onClose) =>
  (
    <IndicatorDataDialog
      row={row}
      onClose={onClose}
      onChange={changeEvaluationStandard}
      readOnly={readOnly}
      submitted={submitted}
    />
  );

const CategoryIcon = (row) => (conditionIsCategory(row) ? <CategorySVG /> : null);

const createIndicatorNameTemplate = (showIndicatorDialog) => (row, passThrough) =>
  (
    <IconButton
      color={
        (passThrough || {})?.submitted && (row.error || {})?.field === "evaluationStandard" ? "secondary" : undefined
      }
      onClick={() => showIndicatorDialog(row, passThrough)}
      className={"no-padding"}
    >
      <DescriptionOutlinedIcon />
    </IconButton>
  );

const createWeightedMaxSystemScoreFooterTemplate = (systemLogic, mainTotal) => (field, data) =>
  (
    <div className="text-right">
      {mainTotal +
        ": " +
        (systemLogic === SYSTEM_LOGIC_DGNB
          ? sumGroupingWeightedMaxSystemScore(data)
          : calculateGroupingMaxScore(data)
        ).toFixed(2)}
    </div>
  );

export const SustainabilitySystemGroupingESG = React.memo((props) => {
  const {
    values,
    onChange,
    readOnly,
    submitted,
    errors,
    setOverallMaxSystemScore,
    errorMessage,
    systemLogic,
    awards,
    awardCalculationType,
  } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState({});
  const [addPopoverTarget, setAddPopoverTarget] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  useEffect(() => {
    if (systemLogic) {
      onChange(recalculateFields([...values]));
    }
  }, [systemLogic, awards]);

  const showIndicatorDialog = (row, passThroughShowIndicator) => {
    const {
      readOnly: readOnlyShowIndicator,
      changeEvaluationStandard: changeEvaluationStandardShowIndicator,
      submitted: submittedShowIndicator,
    } = passThroughShowIndicator || {};
    showDialog({
      title: t("grouping.evaluationStandard"),
      className: "large",
      closeOnClickOutside: !!readOnlyShowIndicator,
      getContent: createIndicatorDataDialog(
        row,
        changeEvaluationStandardShowIndicator,
        readOnlyShowIndicator,
        submittedShowIndicator
      ),
    });
  };

  const columns = useMemo(() => {
    const columnsInner = [
      {
        field: "systemReference",
        headerText: t("grouping.systemReference"),
        type: COLUMN_TYPE_TEXT,
        width: 120,
        minWidth: 120,
        maxLength: 255,
        search: true,
      },
      {
        field: "name",
        headerText: t("grouping.groupingTitle"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionNotIndicator,
        getIcon: CategoryIcon,
        groupingColumn: true,
        minWidth: 200,
        width: 335,
        maxLength: 255,
        search: true,
      },
      {
        field: "indicatorName",
        headerText: t("grouping.indicatorName"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionIsIndicator,
        startAdornment: <IndicatorSVG />,
        getEndAdornment: createIndicatorNameTemplate(showIndicatorDialog),
        minWidth: 200,
        width: 335,
        maxLength: 255,
        search: true,
      },
      {
        field: "weightingFactor",
        headerText: t("grouping.weight"),
        required: true,
        type: COLUMN_TYPE_PERCENT,
        step: 0.0001,
        editableCondition: conditionNotIndicator,
        template: WeightingColumnTemplate,
        editTemplate: WeightingColumnEditTemplate,
        width: 150,
        minWidth: 150,
      },
      {
        getField: (row) => (row.type === GROUPING_TYPE_INDICATOR ? "maxSystemScore" : "maxSystemScoreLimit"),
        headerText: t("grouping.maxSystemScore"),
        getHeaderText: (row) =>
          t(row.type === GROUPING_TYPE_INDICATOR ? "grouping.maxSystemScore" : "grouping.maxSystemScoreLimit"),
        required: false,
        type: COLUMN_TYPE_NUMBER,
        template: MaxSystemScoreTemplate,
        step: 0.0001,
        min: 0,
        max: 999,
        width: 110,
        minWidth: 110,
      },
      {
        field: "weightedMaxSystemScore",
        headerText: t("grouping.intermediateMaxSystemScore"),
        type: COLUMN_TYPE_NUMBER,
        allowEmpty: true,
        readOnly: true,
        width: 140,
        minWidth: 140,
        showCondition: conditionNotIndicator,
        footerTemplate: createWeightedMaxSystemScoreFooterTemplate(systemLogic, t("main.total")),
      },
      systemLogic === SYSTEM_LOGIC_DGNB && {
        field: "relativeProportionMaxSystemScore",
        headerText: t("grouping.relativeProportionMaxSystemScore"),
        type: COLUMN_TYPE_PERCENT,
        allowEmpty: true,
        readOnly: true,
        width: 200,
        minWidth: 200,
      },
      {
        field: "maxSystemScoreProportion",
        headerText: t("grouping.absoluteProportionMaxSystemScore"),
        type: COLUMN_TYPE_PERCENT,
        allowEmpty: true,
        readOnly: true,
        width: 200,
        minWidth: 200,
      },
      {
        field: "koValue",
        headerText: t("grouping.koValue"),
        required: false,
        type: COLUMN_TYPE_NUMBER,
        allowEmpty: true,
        step: 1,
        min: 0,
        max: 999,
        width: 110,
        minWidth: 110,
      },
      hasPermission(PERMISSION_KPI_READ) && {
        field: "kpis",
        headerText: t("grouping.kpi"),
        showCondition: conditionIsIndicator,
        editableCondition: conditionIsIndicator,
        template: KPITemplate,
        editTemplate: KPIEditTemplate,
        width: 225,
        minWidth: 225,
      },
      {
        field: "excludable",
        type: COLUMN_TYPE_CHECKBOX,
        headerText: t("grouping.excludable"),
        showCondition: conditionNotCategory,
        editableCondition: conditionNotCategory,
        width: 185,
        minWidth: 175,
        onChange: (UID, fieldName, value, { getValues: getValuesExcludable }) => {
          const valuesExcludable = getValuesExcludable();
          if (value) {
            const updated = [...valuesExcludable];
            jp.apply(updated, `$..[?(@.UID=="${UID}")].excludable`, () => true);
            jp.apply(updated, `$..[?(@.UID=="${UID}")]..children..excludable`, () => true);
            onChange(updated);
          }
        },
      },
    ];

    while (columnsInner.some((item) => !item)) {
      const index = columnsInner.findIndex((item) => !item);
      columnsInner.splice(index, 1);
    }

    let awardColumns = [];
    if (awards != null) {
      awardColumns = awards.map((award) => ({
        jsonPath: `$.elementAwardThresholds["${award?.internalId?.toLowerCase()}"]`,
        type: awardCalculationType === AWARD_CALCULATION_TYPE_POINT ? COLUMN_TYPE_NUMBER : COLUMN_TYPE_PERCENT,
        headerText: award.title,
        width: 175,
        minWidth: 175,
      }));
    }

    return columnsInner.concat(awardColumns);
  }, [systemLogic, awards]);

  const searched = columns.filter((item) => !!item.search && search[item.field]);
  const errorsByPath = useMemo(() => {
    const errorsByPathInner = {};
    errors?.forEach((item) => {
      const path = item.path.join("_");
      if (!errorsByPathInner[path]) {
        errorsByPathInner[path] = item;
      }
    });
    return errorsByPathInner;
  }, [errors]);

  const addRootGroupingElement = () => {
    const rootGroupingElement = new GroupingElement();
    const elementAwardThresholds = awards
      .map((award) => ({ [award?.internalId?.toLowerCase()]: null }))
      .reduce((previous, currrent) => ({ ...previous, ...currrent }), {});
    rootGroupingElement.setElementAwardThresholds(elementAwardThresholds);
    rootGroupingElement.weightingFactor = 1;
    const updated = [...values, rootGroupingElement];
    onChange(recalculateFields(updated));
  };

  const addCategory = (row, hideControls, type) => {
    setAddPopoverTarget(null);
    hideControls();
    const groupingElement = new GroupingElement({ type });
    groupingElement.weightingFactor = 1;
    const updated = [...values];
    const { parent } = row;
    if (parent) {
      const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
      if (foundParent) {
        groupingElement.setElementAwardThresholds(foundParent.elementAwardThresholds);
        foundParent.children.push(groupingElement);
      }
    } else {
      updated.push(groupingElement);
    }
    onChange(recalculateFields(updated));
  };

  const addChildCategory = (row, hideControls, type) => {
    setAddPopoverTarget(null);
    hideControls();
    const childCategoryGroupingElement = new GroupingElement({ type });
    childCategoryGroupingElement.weightingFactor = 1;
    const updated = [...values];
    const found = findRecursive(updated, (node) => node.UID === row.UID);
    if (found) {
      childCategoryGroupingElement.setElementAwardThresholds(found.elementAwardThresholds);
      found.children.push(childCategoryGroupingElement);
    }
    onChange(recalculateFields(updated));
  };

  const addIndicator = (row, hideControls) => {
    setAddPopoverTarget(null);
    hideControls();
    const updated = [...values];
    const { parent } = row;
    const indicator = new GroupingElement({ type: GROUPING_TYPE_INDICATOR });
    if (row.type === GROUPING_TYPE_INDICATOR) {
      if (parent) {
        const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
        if (foundParent) {
          indicator.setElementAwardThresholds(foundParent.elementAwardThresholds);
          foundParent.children.push(indicator);
        }
      } else {
        updated.push(indicator);
      }
    } else {
      const found = findRecursive(updated, (node) => node.UID === row.UID);
      if (found) {
        indicator.setElementAwardThresholds(found.elementAwardThresholds);
        found.children.push(indicator);
      }
    }
    onChange(recalculateFields(updated));
  };

  const copyIndicator = (row, hideControls) => {
    hideControls();
    const updated = [...values];
    const { parent } = row;
    const indicator = copyElement(row);
    if (parent) {
      const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
      if (foundParent) {
        foundParent.children.push(indicator);
      }
    }
    onChange(recalculateFields(updated));
  };

  const copyGrouping = (row, hideControls) => {
    hideControls();
    const updated = [...values];
    const { parent } = row;
    const element = copyElement(row);
    if (parent) {
      const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
      if (foundParent) {
        foundParent.children.push(element);
      }
    } else {
      updated.push(element);
    }
    onChange(recalculateFields(updated));
  };

  const renderAddPopover = (row, hideControls) => {
    return (
      <AddMenuPopover
        row={row}
        addCategory={addCategory}
        addChildCategory={addChildCategory}
        addIndicator={addIndicator}
        addPopoverTarget={addPopoverTarget}
        hideControls={hideControls}
        setAddPopoverTarget={setAddPopoverTarget}
      />
    );
  };

  const renderHoverControlButtons = (row, hideControls) => {
    return (
      <>
        {row?.type === GROUPING_TYPE_INDICATOR && (
          <Fab color="primary" size="small" onClick={() => copyIndicator(row, hideControls)}>
            <FileCopyOutlinedIcon />
          </Fab>
        )}
        {row?.type !== GROUPING_TYPE_INDICATOR && (
          <Fab color="primary" size="small" onClick={() => copyGrouping(row, hideControls)}>
            <FileCopyOutlinedIcon />
          </Fab>
        )}
        <Fab color="primary" size="small" onClick={(event) => setAddPopoverTarget(event.target)}>
          <AddIcon />
        </Fab>
        <Fab color="secondary" size="small" onClick={() => setDeleteConfirmation({ row, hideControls })}>
          <DeleteOutlineIcon />
        </Fab>
      </>
    );
  };

  const deleteHandler = () => {
    setDeleteConfirmation(false);
    const { row, hideControls } = deleteConfirmation;
    hideControls();
    const updated = [...values];
    const { parent, index } = row;
    if (parent) {
      const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
      if (foundParent) {
        foundParent.children.splice(index, 1);
      }
    } else {
      updated.splice(index, 1);
    }
    onChange(recalculateFields(updated));
  };

  const recalculateFields = (data) => {
    let updated = [...data];
    updated = processMaxSystemScore(updated);
    processMaxSystemScoreProportions(updated, systemLogic);
    setOverallMaxSystemScore(
      systemLogic === SYSTEM_LOGIC_DGNB
        ? sumGroupingWeightedMaxSystemScore(updated)
        : calculateGroupingMaxScore(updated)
    );
    processAllIndicatorsWeighting(updated);
    return updated;
  };

  const additionalUpdateLogic = (data, row, field, value, prevValue) => {
    if (field === "maxSystemScore" || field === "maxSystemScoreLimit" || field === "weightingFactor") {
      const notChanged = value === prevValue;
      if (!notChanged) {
        return recalculateFields(data);
      }
    }
    return data;
  };

  const getRowClassName = (row) => {
    return "sustainability-row-" + row.type;
  };

  const onChangePosition = (data) => {
    onChange(recalculateFields(data));
  };

  const changeEvaluationStandard = useEvent(
    ({ UID, standard, links, files, unitValue, valueType, valueRangeList, allowCustomValue, visibleOnTable }) => {
      const updated = [...values];
      forEachRecursive(updated, (node) => {
        if (node.UID === UID) {
          node.evaluationStandard = standard;
          node.evaluationStandardLinks = links || [];
          node.evaluationStandardFiles = files || [];
          node.unit = unitValue;
          node.valueType = valueType;
          node.valueRangeList = valueRangeList;
          node.allowCustomValue = allowCustomValue;
          node.visibleOnTable = visibleOnTable;
          return true;
        }
      });
      onChange(updated);
    }
  );

  const getValues = useEvent(() => values);

  const deleteRow = (deleteConfirmation || {})?.row;
  const isDeleteIndicator = !!deleteRow && deleteRow.type === GROUPING_TYPE_INDICATOR;

  const expandCondition = useCallback((node, path) => expandRowCondition(node, path, errors), [errors]);

  const passThrough = useMemo(
    () => ({
      readOnly,
      submitted,
      changeEvaluationStandard,
      getValues,
    }),
    [readOnly, submitted]
  );

  return (
    <div className="sustainability-system-grouping">
      {!!searched.length && (
        <div className="filters">
          {searched.map((item, index) => (
            <SearchBadge
              key={index}
              field={item.headerText}
              value={search[item.field].data}
              onClose={() => setSearch({ ...search, [item.field]: undefined })}
            />
          ))}
          <Button className="clear-filters" onClick={() => setSearch({})}>
            {t("main.clear")}
          </Button>
        </div>
      )}
      <AdvancedTable
        data={values}
        errorMessage={errorMessage}
        expandRowsByCondition={!!errorMessage}
        expandRowCondition={expandCondition}
        errorMarkRowsByCondition={!!errorMessage}
        errorMarkRowCondition={expandCondition}
        onChange={onChange}
        getRowClassName={getRowClassName}
        additionalUpdateLogic={additionalUpdateLogic}
        onChangePosition={onChangePosition}
        readOnly={readOnly}
        submitted={submitted}
        errorsByPath={errorsByPath}
        groupingField={"children"}
        onSearch={setSearch}
        search={search}
        columns={columns}
        footer={
          <div>
            {!readOnly && !(values || []).length && (
              <Button variant="contained" color="primary" onClick={addRootGroupingElement}>
                <AddIcon /> {t("grouping.addCategory")}
              </Button>
            )}
          </div>
        }
        sortRows={sortGroupings}
        renderAddPopover={renderAddPopover}
        renderHoverControlButtons={renderHoverControlButtons}
        matchDropLogic={matchDropLogic}
        passThrough={passThrough}
        disableColumnLock
      />

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={t(
          isDeleteIndicator ? "grouping.deleteIndicatorConfirmationTitle" : "grouping.deleteConfirmationTitle"
        )}
        bodyText={t(isDeleteIndicator ? "grouping.deleteIndicatorConfirmation" : "grouping.deleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />
    </div>
  );
});
