import React from "react";
import { useTranslation } from "react-i18next";
import { FilterBlocks } from "../../FilterBlocks/FilterBlocks";
import "./RolesFilter.scss";
import { ROLE_STATUS_DISABLED, ROLE_STATUS_ENABLED } from "../../../constants/role";

export const RoleFilter = ({ value, onChange, records, disabled }) => {
  const { t } = useTranslation();

  const amounts = {
    [ROLE_STATUS_ENABLED]: 0,
    [ROLE_STATUS_DISABLED]: 0
  };

  (records || []).forEach(item => {
    amounts[item.status]++
  });

  const filterOptions = [
    {
      name: ROLE_STATUS_ENABLED,
      title: t('roles.filter.' + ROLE_STATUS_ENABLED),
      amount: amounts[ROLE_STATUS_ENABLED]
    },
    {
      name: ROLE_STATUS_DISABLED,
      title: t('roles.filter.' + ROLE_STATUS_DISABLED),
      amount: amounts[ROLE_STATUS_DISABLED]
    }
  ];

  return (
    <FilterBlocks filterOptions={filterOptions} value={value} onChange={onChange} allowDeselect showReset={!!value}
                  disabled={disabled}/>
  );
};
