export const getDuplicatedAction = (data) => {
  const names = {};
  return data.find(item => {
    if (!names[item.name]) {
      names[item.name] = true;
      return false;
    }
    return true;
  });
};

export const getInvalidRequiredAction = data => data.find(item => !item.name || !item.description);

export const validateIndicatorActionsRequired = (actions = []) => {
  return !actions.find(item => {
    return !item.name || !item.description;
  })
};

export const validateUniqueName = (name, data = []) => {
  return !data.find(item => item.name === name);
};
