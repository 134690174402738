import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { AddUserTenantDialog } from "../../components/Users/AddUserTenantDialog/AddUserTenantDialog";
import { UserDetailsDialog } from "../../components/Users/UserDetailsDialog/UserDetailsDialog";
import { UserRolesTooltipPopover } from "../../components/Users/UserRolesTooltipPopover/UserRolesTooltipPopover";
import { UserTenantRolesDialog } from "../../components/Users/UserTenantRolesDialog/UserTenantRolesDialog";
import { UsersFilter } from "../../components/Users/UsersFilter/UsersFilter";
import { UsersTable } from "../../components/Users/UsersTable/UsersTable";
import { handleError } from "../../helpers/error";
import { checkPopState } from "../../hooks";
import { getTenantList } from "../../hooks/tenant";
import {
  deleteUser,
  deleteUserTenant,
  getUsersList,
  resetUsersPageState,
  useDeleteUserResponse,
  useRemoveUserTenantResponse,
  useUsersListResponse,
  useUsersPageLogic,
} from "../../hooks/users";
import { useTimeout } from "../../hooks/utils/useTimeout";
import "./Users.scss";

export const UsersPage = () => {
  const { t } = useTranslation();

  const response = useUsersListResponse();
  const deleteResponse = useDeleteUserResponse();
  const removeTenantResponse = useRemoveUserTenantResponse();
  const usersList = response.data || [];
  const readOnly = false;

  const { searchValue, setSearchValue, filterValue, setFilterValue } = useUsersPageLogic();
  const [, setSearchTimeout] = useTimeout();

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [removeConfirmation, setRemoveConfirmation] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [editUserDialog, setEditUserDialog] = useState(null);
  const [addTenantDialog, setAddTenantDialog] = useState(null);
  const [manageRolesDialog, setManageRolesDialog] = useState(null);

  useEffect(() => {
    getTenantList().then().catch(console.error);
  }, []);

  useEffect(() => {
    if (!checkPopState()) {
      resetUsersPageState();
    }
    load();
  }, []);

  const load = () => getUsersList().then().catch(handleError);
  const search = (value) => {
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(value || "").trim());
      }, 500)
    );
  };

  const deleteHandler = () => {
    setDeleteConfirmation(null);
    deleteUser(deleteConfirmation.id).then(load).catch(handleError);
  };

  const removeTenantHandler = () => {
    setRemoveConfirmation(null);
    const { user, tenant } = removeConfirmation;
    deleteUserTenant(user.id, tenant.id).then(load).catch(handleError);
  };

  const loading = response.loading || deleteResponse.loading || removeTenantResponse.loading;

  return (
    <div className="users-page page-with-table">
      <LoadingOverlay spinner active={loading}>
        <div className="page-header large flex-row">
          <div className="flex-col title-box no-margin">
            <h1>{t("users.title")}</h1>
          </div>
          <div className="flex-col search-box no-margin">
            <SearchIcon />
            <input
              type="text"
              defaultValue={searchValue || ""}
              className="search-input input-shadow"
              placeholder={t("main.search")}
              onChange={(event) => search(event.target.value)}
            />
          </div>
        </div>

        <div className="page-layout">
          <UsersFilter onChange={setFilterValue} value={filterValue} records={filtered} />
          <UsersTable
            data={usersList}
            setFiltered={setFiltered}
            loading={response.loading}
            readOnly={readOnly}
            onEdit={setEditUserDialog}
            onAddTenant={setAddTenantDialog}
            onManageRoles={setManageRolesDialog}
            onRemoveTenant={(user, tenant) => setRemoveConfirmation({ user, tenant })}
            onDelete={(user) => setDeleteConfirmation(user)}
          />
        </div>
      </LoadingOverlay>

      <UserRolesTooltipPopover />

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={t("users.userDeleteConfirmationTitle")}
        bodyText={t("users.userDeleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />

      <ConfirmationDialog
        open={!!removeConfirmation}
        onClose={() => setRemoveConfirmation(null)}
        onConfirm={removeTenantHandler}
        titleText={t("users.tenantRemoveConfirmationTitle")}
        bodyText={t("users.tenantRemoveConfirmation")}
        confirmText={t("main.remove")}
        color="secondary"
      />
      {addTenantDialog && (
        <AddUserTenantDialog
          {...addTenantDialog}
          onChange={load}
          open={addTenantDialog}
          onClose={() => setAddTenantDialog(null)}
        />
      )}
      {manageRolesDialog && (
        <UserTenantRolesDialog
          {...manageRolesDialog}
          onChange={load}
          open={manageRolesDialog}
          onClose={() => setManageRolesDialog(null)}
        />
      )}
      {editUserDialog && (
        <UserDetailsDialog
          {...editUserDialog}
          onChange={load}
          open={editUserDialog}
          onClose={() => setEditUserDialog(null)}
        />
      )}
    </div>
  );
};
