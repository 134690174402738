import { drawBackgroundLine, drawBar } from "../../../helpers/canvas";

export const renderKPIDetailsChart = (
  canvas, width, height,
  { linesAmount, limitedXRange, heightInPoints, valuesByYear, goalsByYear, palette, hasPotential, benchmark }
) => {
  const offset = 20;
  const separatorWidth = 6;
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.reset();
  const amount = linesAmount || 20;
  const lineStepHeight = height / amount;
  for (let i = 0; i < amount; i++) {
    const y = i * lineStepHeight;
    drawBackgroundLine(ctx, 0, y, width, y, '#ECECEC', 2);
  }

  const drawBars = (stepWidth, firstYear) => {
    ctx.shadowColor = '#00000066';
    ctx.shadowBlur = 2;
    const barWidth = stepWidth > 90 ? 30 : stepWidth / 3;

    for (let year = firstYear, step = 0; step <= limitedXRange.length; year++, step++) {
      const values = valuesByYear[year] || [];
      let valueOffset = 0;
      if (hasPotential) {
        valueOffset = values ? -barWidth / 2 - separatorWidth / 2 : 0;
      }

      let y = height;
      values.forEach(({ projectId, value, estimation }, index) => {
        const x = stepWidth * step + stepWidth / 2 + offset + valueOffset;
        const color = palette[projectId];
        const estimatedValue = value * estimation / 100;
        const measuredValue = value * (100 - estimation) / 100;
        if (measuredValue) {
          ctx.globalAlpha = 0.5;
          const barHeight = measuredValue / heightInPoints * height;
          drawBar(ctx, x, y, 30, barHeight, 1, color,
            (values.length - 1) === index && !estimatedValue ? { upperLeft: 5, upperRight: 5 } : {}
          );
          y -= barHeight;
        }
        if (estimatedValue) {
          ctx.globalAlpha = 1;
          const barHeight = estimatedValue / heightInPoints * height;
          drawBar(ctx, x, y, 30, barHeight, 1, color,
            (values.length - 1) === index ? { upperLeft: 5, upperRight: 5 } : {}
          );
          y -= barHeight;
        }
      });
      if (hasPotential) {
        y = height;
        ctx.globalAlpha = 1;
        values.forEach(({ projectId, potential }, index) => {
          const x = stepWidth * step + stepWidth / 2 + offset - valueOffset;
          const color = '#548233';
          const barHeight = potential / heightInPoints * height;
          drawBar(ctx, x, y, 30, barHeight, 1, color,
            (values.length - 1) === index ? { upperLeft: 5, upperRight: 5 } : {}
          );
          y -= barHeight;
        });
      }
    }
  };

  if (limitedXRange.length) {
    const firstYear = limitedXRange[0];
    const lastYear = limitedXRange[limitedXRange.length - 1];
    const stepWidth = (width - (offset * 2)) / limitedXRange.length;

    drawBars(stepWidth, firstYear);

    ctx.shadowColor = null;
    ctx.shadowBlur = 0;
    ctx.globalAlpha = 1;

    let prevGoal = goalsByYear[firstYear];
    let lastGoal = goalsByYear[lastYear];

    const lineWidth = 2;
    const lineOffset = lineWidth / 2;
    ctx.beginPath();
    ctx.moveTo(-lineWidth, Math.round(height - prevGoal / heightInPoints * height) - lineOffset);
    for (let year = firstYear, index = 0; index <= limitedXRange.length; year++, index++) {
      const goal = goalsByYear[year];
      if (goal) {
        ctx.lineTo(Math.round(index * stepWidth + stepWidth / 2) + offset, Math.round(height - goal / heightInPoints * height) - lineOffset);
      }
    }
    ctx.lineTo(width + lineWidth, Math.round(height - lastGoal / heightInPoints * height) - lineOffset);
    ctx.lineTo(width + lineWidth, height + lineWidth);
    ctx.lineTo(-lineWidth, height + lineWidth);
    ctx.closePath();

    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = '#62B0FF';
    ctx.fillStyle = '#62B0FF33';
    ctx.fill();
    ctx.stroke();

    if (benchmark?.some(item => item.value)) {
      const prevBenchmark = benchmark.find(item => item.value)?.value || 0;
      const nextBenchmark = benchmark.findLast(item => item.value)?.value || 0;
      const lineWidthInner = 2;
      ctx.beginPath();
      ctx.moveTo(-lineWidthInner, Math.round(height - prevBenchmark / heightInPoints * height) - lineOffset);
      for (let index = 0; index <= benchmark.length; index++) {
        const { value, year } = benchmark[index] || {};
        if (value) {
          ctx.lineTo(Math.round((year - firstYear) * stepWidth + stepWidth / 2) + offset, Math.round(height - value / heightInPoints * height) - lineOffset);
        }
      }
      ctx.lineTo(width + lineWidthInner, Math.round(height - nextBenchmark / heightInPoints * height) - lineOffset);

      ctx.lineWidth = lineWidthInner;
      ctx.strokeStyle = '#F72B2F';
      ctx.stroke();
    }
  }

  drawBackgroundLine(ctx, 0, height, width, height, '#B9BCBE', 2);
};
