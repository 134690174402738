import React from "react";
import { useParams } from "react-router";
import { TENANT_TYPE_ESG, TENANT_TYPE_PAST } from "../../constants/tenant";
import { getUserTenantType } from "../../hooks/auth";
import { SustainabilitySystemESG } from "./SustainabilitySystemESG";
import { SustainabilitySystemPAST } from "./SustainabilitySystemPAST";

export const SustainabilitySystemPage = (props) => {
  const tenantType = getUserTenantType();
  const { sustainabilitySystemId } = useParams();

  switch (tenantType) {
    case TENANT_TYPE_ESG:
      return <SustainabilitySystemESG sustainabilitySystemId={sustainabilitySystemId} />;
    case TENANT_TYPE_PAST:
      return <SustainabilitySystemPAST sustainabilitySystemId={sustainabilitySystemId} />;
    default:
      return null;
  }
};
