import React, { useEffect, useState } from "react";
import { useEvent } from "../../hooks/utils/useEvent";
import { ResponsibleIndicator } from "./ResponsibleIndicator";

export const ResponsibleBlock = ({ onChange, row, readOnly }) => {
  const data = row || {};
  const { UID } = data;
  const [responsible, setResponsible] = useState(data.responsible);

  useEffect(() => {
    setResponsible(data.responsible);
  }, [UID, data?.responsible]);

  const saveChanges = useEvent(() => {
    onChange({
      UID,
      fields: {
        responsible: responsible
      }
    });
  });

  return (
      <>
        <ResponsibleIndicator value={responsible} onChange={setResponsible} onBlur={() => saveChanges()} readOnly={readOnly} />
      </>
  )
};
