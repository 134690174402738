import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../../constants/main";
import { getUserTenantType } from "../../../hooks/auth";
import {
  putProjectGroupProject,
  resetProjectsToAddState,
  searchProjectsToAdd,
  useProjectGroupProjectPutResponse,
  useProjectsToAddLogic,
  useProjectsToAddPageResponse,
} from "../../../hooks/projectGroup";
import { showError } from "../../../hooks/toast";
import { useTimeout } from "../../../hooks/utils/useTimeout";
import { processMessage } from "../../../utils";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { ProjectFilter } from "../../Project/ProjectFilter/ProjectFilter";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import "./AddProjectGroupProjectDialog.scss";

export const NameColumnTemplate = ({ name, accessRestricted }, t) => {
  return (
    <div className="flex-row">
      {!!accessRestricted && (
        <Tooltip title={t("projects.accessRestricted")}>
          <LockOutlinedIcon />
        </Tooltip>
      )}
      <div className="flex-auto">{name}</div>
    </div>
  );
};

export const AddProjectGroupProjectDialog = ({ onClose, onChange, projectGroupId }) => {
  const { t } = useTranslation();
  const tenantType = getUserTenantType();

  const projectsToAddPageResponse = useProjectsToAddPageResponse();
  const projectGroupProjectPutResponse = useProjectGroupProjectPutResponse();

  const [processed, setProcessed] = useState([]);
  const [total, setTotal] = useState(0);
  const [init, setInit] = useState(false);
  const inputRef = useRef(null);

  const {
    searchValue,
    setSearchValue,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    resetState,
    setFilterValue,
    filterValue,
    filterValues,
    setFilterValues,
  } = useProjectsToAddLogic();
  const [, setSearchTimeout] = useTimeout();

  useEffect(() => {
    if (!projectsToAddPageResponse.loading && projectsToAddPageResponse.data) {
      const responseData = projectsToAddPageResponse.data || {};
      const items = responseData.content || [];
      const totalValue = (responseData.meta || {}).total || 0;
      setProcessed(items);
      setTotal(totalValue);
    }
  }, [projectsToAddPageResponse.loading, projectsToAddPageResponse.data]);

  const load = () =>
    searchProjectsToAdd({
      page: page + 1,
      itemsPerPage: rowsPerPage,
      name: searchValue,
      id: projectGroupId,
      ratingStatus: filterValue,
      projectTypes: filterValues.projectTypes,
      sustainabilitySystemId: filterValues.sustainabilitySystems,
    }).catch(console.error);

  useEffect(() => {
    resetProjectsToAddState();
    setProcessed([]);
    setTotal(0);
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      load();
    }
  }, [
    init,
    searchValue,
    page,
    rowsPerPage,
    filterValue,
    filterValues.projectTypes,
    filterValues.sustainabilitySystems,
  ]);

  const search = (value) => {
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(value || "").trim());
      }, 500)
    );
  };

  const addProjectGroupProject = (projectId, reload) => {
    putProjectGroupProject(projectGroupId, projectId)
      .then(() => {
        !!reload && reload();
        onChange();
      })
      .catch((error) => {
        console.error(error);
        showError(t("error.500"));
      });
  };

  const pagination = {
    labelRowsPerPage: t("projects.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: total,
    page: page,
    show: total > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pageOnChange) => setPage(pageOnChange),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const columns = useMemo(() => {
    const commonColumn = {
      field: "name",
      headerText: t("projects.name"),
      headerClassName: "name-column",
      template: (props) => NameColumnTemplate(props, t),
    };

    return [
      commonColumn,
      {
        className: "action-cell text-right",
        headerClassName: "action-header-cell",
        headerText: " ",
        template: (row, field, rootRow, passThrough) => (
          <div>
            <Button onClick={() => addProjectGroupProject(row.id, passThrough.load)}>{t("main.add")}</Button>
          </div>
        ),
      },
    ];
  }, [tenantType]);

  const loading = projectsToAddPageResponse.loading || projectGroupProjectPutResponse.loading;

  return (
    <div className="add-project-group-project-dialog">
      <h2>
        {t("projectGroup.modalAddProjectGroupProjectTitle")}
        <IconButton onClick={onClose} size="small" className={"pull-right"}>
          <CloseOutlinedIcon />
        </IconButton>
      </h2>

      <div className="flex-row-filter search-filter-box">
        <div
          className="search-box flex-auto"
          onClick={() => {
            inputRef.current.focus();
          }}
        >
          <SearchIcon />
          <input
            type="text"
            key={resetState}
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            ref={inputRef}
            placeholder={t("main.search")}
            onChange={(event) => search(event.target.value)}
          />
        </div>
        <ProjectFilter onChangeFilters={setFilterValues} filters={filterValues} hideSystem />
      </div>

      <LoadingOverlay spinner className="auto-height" active={loading}>
        <SimpleTableWithSort
          key={loading}
          className="overflow-visible"
          tableClassName="modal-table"
          data={processed}
          pagination={pagination}
          setFilterValue={setFilterValue}
          filterValue={filterValue}
          columns={columns}
          passThrough={{ load }}
        />
      </LoadingOverlay>
    </div>
  );
};
