export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
});

export const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(toBase64)
    .then(base64 => ({ url, data: base64 }));

export const toDataURLParallel = async (urls) => {
    const promises = [];
    urls.forEach(url => promises.push(toDataURL(url)));
    return await Promise.all(promises);
}