import BarChartIcon from "@mui/icons-material/BarChart";
import Button from "@mui/material/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export const DashboardLink = ({ system }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projectId, id, kpiSystemId, visibleOnDashboard } = system || {};

  return (
    <Button
      color="primary"
      className="right-header-button"
      disabled={!visibleOnDashboard}
      onClick={() =>
        navigate(kpiSystemId ? `/project/${projectId}/kpiDashboard/${id}` : `/project/${projectId}/dashboard/${id}`)
      }
    >
      <BarChartIcon fontSize="inherit" />
      {t("ratingSystem.dashboardLink")}
    </Button>
  );
};
