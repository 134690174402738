import { ProseMirror } from '@progress/kendo-react-editor';
import { showError } from '../../../hooks/toast';
import { MB, MEGA } from '../../../constants/fileUpload';
import i18n from 'i18next';
import { insertText } from '../utils';
import { getImageSourceFromMap, imageLockSubject, setImageLock } from '../../../hooks/richtext';
import { toBase64 } from '../../../helpers/image';

const EVENT_TEXT_KEY = 'Text';

const findFirstFile = (items) => {
    let firstFile = null;

    for (const element of items) {
        if (element.type.indexOf('image') !== -1) { 
            const file = 'getAsFile' in element ? element.getAsFile() : element;
            if (file) {
                firstFile = file;
                break;
            }
        }
    }

    return firstFile;
};

const insertImage = async (view, event, firstFile, props) => {
    const { onImageInsert, uploadFunction, getResultUrl, richTextConfiguration, uid } = props;
    if (richTextConfiguration?.imageSizeLimit < 0 || firstFile.size <= richTextConfiguration?.imageSizeLimit * MEGA) {
        const base64 = await toBase64(firstFile);

        const imageSource = getImageSourceFromMap(uid);
        let fileUrl = null;
        if (!imageSource[base64]) { 
            const result = await uploadFunction(firstFile);
            fileUrl = getResultUrl(result.id);
        } else { 
            fileUrl = imageSource[base64];
        }

        onImageInsert({
            view,
            fileUrl,
            event,
            richTextConfiguration,
            uid
        });
    } else { 
        showError(`${i18n.t('richtext.fileSizeError')} ${richTextConfiguration?.imageSizeLimit}${MB}`);
        setImageLock(false); 
    }
};

export const insertPastedContentPlugin = (props) => new ProseMirror.Plugin({
    props: {
        handleDOMEvents: {
            paste: (view, event) => insertPastedContent(view, event, props),
            drop: (view, event) => insertPastedContent(view, event, props)
        }
    },
    key: new ProseMirror.PluginKey('insert-image-plugin')
});

const insertPastedContent = async (view, event, props) => {
    const { disableImageUpload, richTextConfiguration } = props;
    const imageLock = imageLockSubject.getValue();
    if (imageLock) { 
        event.preventDefault();
        return;
    }

    setImageLock(true);
    const items = event?.clipboardData?.items || event?.dataTransfer?.files;

    if (items) {
        const firstFile = findFirstFile(items);
        event.preventDefault();

        if (!disableImageUpload && firstFile && !!richTextConfiguration?.imageQuantityLimit && richTextConfiguration?.imageQuantityLimit >= 0) {
            await insertImage(view, event, firstFile, props);
        } else if (event.type !== 'drop') {
            const text = event.clipboardData ? event.clipboardData.getData(EVENT_TEXT_KEY) : event.dataTransfer.getData(EVENT_TEXT_KEY);
            setImageLock(false);

            insertText({ text, view });
        } else {
            setImageLock(false);
        }
    }
};