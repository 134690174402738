import { PromiseSubjectState, hookFromSubject, promiseToSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { Configuration, UserEndpointApi } from "../generated-api";
import { getAuthorizationHeader, handleUnauthorized, setUser } from "./auth";
import { createTablePageLogic } from "./tablePageLogic";

const usersApi = new UserEndpointApi(
  new Configuration({
    basePath: window.location.origin,
    getHeaders: () => ({ Authorization: getAuthorizationHeader() }),
    responseHandler: handleUnauthorized,
  })
);

export const getUsersListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useUsersListResponse = hookFromSubject(getUsersListSubject);
export const getUsersList = () => promiseToSubject(usersApi.apiSecurityUserItemsGet(), getUsersListSubject);

export const getTenantUsersListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useTenantUsersListResponse = hookFromSubject(getTenantUsersListSubject);
export const getTenantUsersList = () =>
  promiseToSubject(usersApi.apiSecurityUserTenantItemsGet(), getTenantUsersListSubject);

export const userUpdateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useUpdateResponse = hookFromSubject(userUpdateSubject);
export const updateUser = (id, user) =>
  promiseToSubject(
    usersApi.apiSecurityUserPut({
      item: { ...user, id },
    }),
    userUpdateSubject
  );

export const deleteUserSubject = new BehaviorSubject(new PromiseSubjectState());
export const useDeleteUserResponse = hookFromSubject(deleteUserSubject);
export const deleteUser = (id) =>
  promiseToSubject(usersApi.apiSecurityUserIdDelete({ id }, { returnResponse: true }), deleteUserSubject);

export const userAddUserTenantSubject = new BehaviorSubject(new PromiseSubjectState());
export const useAddUserTenantResponse = hookFromSubject(userAddUserTenantSubject);
export const addUserTenant = (userId, tenantId) =>
  promiseToSubject(
    usersApi.apiSecurityUserUserIdTenantTenantIdAddPut({
      tenantId,
      userId,
    }),
    userAddUserTenantSubject
  );

export const removeUserTenantSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRemoveUserTenantResponse = hookFromSubject(removeUserTenantSubject);
export const deleteUserTenant = (userId, tenantId) =>
  promiseToSubject(
    usersApi.apiSecurityUserUserIdTenantTenantIdRemovePut({
      tenantId,
      userId,
    }),
    removeUserTenantSubject
  );

export const setUserCurrentTenantSubject = new BehaviorSubject(new PromiseSubjectState());
export const useUserCurrentTenantChangeResponse = hookFromSubject(setUserCurrentTenantSubject);
export const setUserCurrentTenant = (tenantIdValue) =>
  promiseToSubject(
    usersApi.apiSecurityUserAuthenticatedTenantTenantIdValueGet({ tenantIdValue }),
    setUserCurrentTenantSubject
  );

export const userUpdateLanguageSubject = new BehaviorSubject(new PromiseSubjectState());
export const useUpdateLanguageResponse = hookFromSubject(userUpdateLanguageSubject);
export const updateUserLanguage = (language) =>
  promiseToSubject(usersApi.apiSecurityUserLanguagePut({ languageDTO: { language } }), userUpdateLanguageSubject);

export const getUserAuthenticated = () =>
  usersApi.apiSecurityUserAuthenticatedGet().then((response) => setUser(response));

export const [useUsersPageLogic, resetUsersPageState] = createTablePageLogic();

export const userRolesPopoverSubject = new BehaviorSubject(null);
export const useUserRolesPopover = hookFromSubject(userRolesPopoverSubject);
export const setUserRolesPopover = (popover) => userRolesPopoverSubject.next(popover);

export const getUserProfileSubject = new BehaviorSubject(new PromiseSubjectState());
export const useGetUserProfileResponse = hookFromSubject(getUserProfileSubject);
export const getUserProfile = (id) =>
  promiseToSubject(usersApi.apiSecurityUserProfileIdGet({ id }), getUserProfileSubject);

export const updateUserProfileSubject = new BehaviorSubject(new PromiseSubjectState());
export const useUpdateUserProfileResponse = hookFromSubject(updateUserProfileSubject);
export const updateUserProfile = (user) => {
  return promiseToSubject(usersApi.apiSecurityUserProfileUpdatePut({ request: { ...user } }), updateUserProfileSubject);
};
