import React from "react";
import { useTableScrollX, useTableHoverControls, setTableHoverControls, useDragRow } from "../../hooks/advancedTable";

export const HoverControls = ({renderHoverControlButtons, flatData, renderAddPopover}) => {
  const tableScrollX = useTableScrollX();
  const hoverControls = useTableHoverControls();
  const dragRow = useDragRow();

  if(!hoverControls || !!dragRow) {
    return null;
  }

  const hoverRow = flatData ? flatData.find(item => item.UID === hoverControls.UID) : null;

  return (
    <div className={'hover-controls' + (hoverControls.hidden ? ' hidden' : '')} style={{
      left: tableScrollX + 'px',
      top: hoverControls.top
    }} onMouseOver={event => event.preventDefault() & event.stopPropagation()}>
      <div className="controls-fabs">
        {!!renderHoverControlButtons && renderHoverControlButtons(hoverRow, () => setTableHoverControls(null))}
      </div>
      {!!renderAddPopover && renderAddPopover(hoverRow, () => setTableHoverControls(null))}
    </div>
  );
};
