import { v4 as getUUID } from "uuid";
import { MENU_TYPE } from "../components/AdvancedTable/AdvancedTableHeaderSearch";
import { PAST_MINIMUM_VALUE } from "../constants/main";
import {
  INDICATOR_GROUPING_TYPE,
  KPI_STATUS_CHANGED,
  KPI_STATUS_WAITING,
  MISSING_ACTION_FILTER,
  MISSING_REASON_FILTER,
  MISSING_SCORE_FILTER,
  SCORE_TYPES,
  STATUS_REPORTING,
} from "../constants/ratingSystem";
import {
  GROUPING_TYPE_CATEGORY,
  GROUPING_TYPE_CRITERION,
  GROUPING_TYPE_INDICATOR,
  SYSTEM_LOGIC_DGNB,
  SYSTEM_LOGIC_STANDARD,
} from "../constants/sustainabilitySystem";
import {
  deepCopy,
  forEachRecursive,
  forEachRecursiveReverseStrict,
  getElementByMatchingField,
  getNodeAbsoluteDegreeOfFulfillment,
  getNodeAbsoluteDegreeOfFulfillmentPotential,
  getNodeDifferenceAbsoluteDegreeOfFulfillment,
  getNodeDifferenceRelativeDegreeOfFulfillment,
  getNodeRelativeDegreeOfFulfillment,
  getNodeRelativeDegreeOfFulfillmentPotential,
  isExcludedElement,
  isIncludedElement,
  mapRecursive,
  roundDecimal,
} from "../utils";
import {
  conditionIsCategory,
  conditionIsCategoryIncluded,
  conditionIsCriterion,
  conditionIsCriterionIncluded,
  conditionIsGrouping,
  conditionIsIndicator,
  conditionIsIndicatorIncluded,
  conditionNotIndicator,
} from "../validation/sustainabilitySystem";

export const getNodeChangedFields = (values, previousValues) => {
  const fieldMap = {
    systemReference: "systemReference",
    indicatorName: "indicatorName",
    name: "name",
    type: "type",
    weightingFactor: "weightingFactor",
    maxSystemScore: ["maxSystemScore", "maxSystemScoreLimit"],
    maxSystemScoreLimit: ["maxSystemScore", "maxSystemScoreLimit"],
    maxSystemScoreProportion: "maxSystemScoreProportion",
    koValue: "koValue",
    kpi: "kpi",
  };

  if (values && previousValues) {
    const changedFields = {};
    Object.keys(fieldMap).forEach((column) => {
      const field = fieldMap[column];
      if (values[column] !== previousValues[column]) {
        if (Array.isArray(field)) {
          field.forEach((item) => {
            changedFields[item] = true;
          });
        } else {
          changedFields[field] = true;
        }
      }
    });
    return Object.keys(changedFields);
  }
  return null;
};

export const isKOFailed = (row) =>
  row.evaluationSystemScore !== null && !!row.koValue && row.koValue > row.evaluationSystemScore;

const calculateGeneralOverallSystemScore = (data) => {
  let scores = 0;
  data.forEach((item) => {
    if (isExcludedElement(item)) {
      return null;
    }
    scores += +item.evaluationSystemScore * (+item.weightingFactor || 1) || 0;
  });
  return scores;
};

const calculateDGNBOverallSystemScore = (data) => {
  let scores = 0;
  data.forEach((item) => {
    if (isExcludedElement(item)) {
      return null;
    }
    scores += +item.weightedSystemScore;
  });
  return scores;
};

export const calculateOverallSystemScore = (data, systemLogic) => {
  if (systemLogic === SYSTEM_LOGIC_DGNB) {
    return calculateDGNBOverallSystemScore(data);
  } else {
    return calculateGeneralOverallSystemScore(data);
  }
};

export const calculatePASTOverallSystemScore = (data) => calculatePASTScore(data.filter(conditionIsCategory));

const calculatePASTWeightInPercents = (node, nodes) => {
  const weightingFactor = node.weightingFactor || 0;
  const allWeightsDefined = !nodes.some((item) => !item.weightingFactor);
  let weightingSum = 0;
  nodes.forEach((item) => {
    if (isExcludedElement(item)) {
      return null;
    }
    weightingSum += item.weightingFactor;
  });
  return allWeightsDefined && weightingSum ? weightingFactor / weightingSum : 0;
};

const calculatePASTScore = (nodes) => {
  const items = nodes.filter((item) => !!item.universalScore && !!item.weightingFactor);
  if (!items.length) {
    return 0;
  } else {
    let scoringSum = 0;
    items.forEach((item) => {
      if (isExcludedElement(item)) {
        return null;
      }
      scoringSum += item.universalScore * item.weightingFactor;
    });
    let weightingSum = 0;
    items.forEach((item) => {
      if (isExcludedElement(item)) {
        return null;
      }
      weightingSum += item.weightingFactor;
    });
    return weightingSum ? scoringSum / weightingSum : 0;
  }
};

const conditionHasUniversalScore = (item) => !!item.universalScore;
const conditionHasWeightingAndScore = (item) => !!item.weightingFactor && !!item.evaluationSystemScore;
const conditionHasProportion = (item) => !!item.maxSystemScoreProportion;

export const calculatePASTPercentsAndScore = (data) => {
  forEachRecursiveReverseStrict(data, (node, nodes) => {
    if (isExcludedElement(node)) {
      return null;
    }
    const calculateAsCategory =
      node.type === GROUPING_TYPE_CRITERION && node.children.some(conditionIsCriterionIncluded);
    if (node.type === GROUPING_TYPE_INDICATOR) {
      if (conditionHasWeightingAndScore(node)) {
        node.maxSystemScoreProportion =
          calculatePASTWeightInPercents(
            node,
            nodes.filter(conditionIsIndicatorIncluded).filter(conditionHasWeightingAndScore)
          ) * 100;
      } else {
        node.maxSystemScoreProportion = 0;
      }
    } else if (node.type === GROUPING_TYPE_CRITERION && !calculateAsCategory) {
      const noIndicatorsHaveScore = !node.children
        .filter(conditionIsIndicatorIncluded)
        .some(conditionHasWeightingAndScore);
      if (noIndicatorsHaveScore) {
        node.maxSystemScoreProportion = 0;
      } else {
        const sameLevelNodes = nodes.filter(conditionIsCriterionIncluded).filter((item) => {
          return item.children.filter(conditionIsIndicatorIncluded).some(conditionHasWeightingAndScore);
        });
        node.maxSystemScoreProportion = calculatePASTWeightInPercents(node, sameLevelNodes) * 100;
      }
    } else {
      const allCriterionAreIgnored = !node.children.filter(conditionIsCriterionIncluded).some(conditionHasProportion);
      if (allCriterionAreIgnored) {
        node.maxSystemScoreProportion = 0;
      } else {
        const sameLevel = calculateAsCategory ? nodes : nodes.filter(conditionIsCategoryIncluded);
        const sameLevelNodes = sameLevel.filter((item) => {
          return item.children.filter(conditionIsCriterionIncluded).some(conditionHasProportion);
        });
        node.maxSystemScoreProportion = calculatePASTWeightInPercents(node, sameLevelNodes) * 100;
      }
    }
  });
  forEachRecursiveReverseStrict(data, (node) => {
    if (isExcludedElement(node)) {
      return null;
    }
    const calculateAsCategory =
      node.type === GROUPING_TYPE_CRITERION && node.children.some(conditionIsCriterionIncluded);
    if (node.type === GROUPING_TYPE_INDICATOR) {
      const evaluationSystemScore = node.evaluationSystemScore || 0;
      const maxSystemScoreProportion = node.maxSystemScoreProportion || 0;
      node.universalScore = evaluationSystemScore * (maxSystemScoreProportion / 100);
    } else if (node.type === GROUPING_TYPE_CRITERION && !calculateAsCategory) {
      const indicators = node.children.filter(conditionIsIndicatorIncluded);
      const noIndicatorsHaveScore = !indicators.some(conditionHasUniversalScore);
      const allIndicatorsKOPassed = !indicators.some((item) => isKOFailed(item));
      if (noIndicatorsHaveScore) {
        node.universalScore = 0;
      } else {
        if (allIndicatorsKOPassed) {
          node.universalScore = indicators.length
            ? indicators.map((item) => item.universalScore || 0).reduce((val, sum = 0) => sum + val)
            : 0;
        } else {
          node.universalScore = PAST_MINIMUM_VALUE;
        }
      }
    } else {
      node.universalScore = calculatePASTScore(node.children.filter(conditionIsCriterionIncluded));
    }
  });
};

const chosePotentialOrCurrent = (potential, current) => {
  if (!potential && potential !== 0) {
    return current || 0;
  }
  return potential;
};

const calculateGeneralPotentialOverallSystemScore = (data) => {
  const isPotentialsDefined = checkPotentialChanged(data);
  if (!isPotentialsDefined) {
    return 0;
  }
  let sum = 0;
  data.forEach((item) => {
    if (isExcludedElement(item)) {
      return null;
    }
    sum +=
      chosePotentialOrCurrent(item.potentialEvaluationSystemScore, item.evaluationSystemScore) *
        (+item.weightingFactor || 1) || 0;
  });
  return sum;
};

const calculateDGNBPotentialOverallSystemScore = (data) => {
  let scores = 0;
  data.forEach((item) => {
    if (isExcludedElement(item)) {
      return null;
    }
    scores += +item.weightedPotentialSystemScore || +item.weightedSystemScore;
  });
  return scores;
};

export const calculatePotentialOverallSystemScore = (data, systemLogic) => {
  if (systemLogic === SYSTEM_LOGIC_DGNB) {
    return calculateDGNBPotentialOverallSystemScore(data);
  } else {
    return calculateGeneralPotentialOverallSystemScore(data);
  }
};

const calculateGeneralPotentialOverallSuperScore = (header, potentialScore) => {
  const overallMaxSystemScore = (header || {})?.overallMaxSystemScore || 0;
  return overallMaxSystemScore ? roundDecimal((potentialScore / overallMaxSystemScore) * 100) : 0;
};

const calculateDGNBPotentialOverallSuperScore = (grouping) => {
  let sum = 0;
  (grouping || []).forEach((item) => {
    if (isExcludedElement(item)) {
      return null;
    }
    sum += +item.absoluteDegreeOfFulfillmentPotential;
  });
  return roundDecimal(sum);
};

export const calculatePotentialOverallSuperScore = (header, grouping, potentialScore, systemLogic) => {
  let result;
  if (systemLogic === SYSTEM_LOGIC_DGNB) {
    result = calculateDGNBPotentialOverallSuperScore(grouping);
  } else {
    result = calculateGeneralPotentialOverallSuperScore(header, potentialScore);
  }

  return Math.min(result, 100);
};

const calculateGeneralOverallSuperScore = (header, score) => {
  const overallMaxSystemScore = (header || {})?.overallMaxSystemScore || 0;
  return overallMaxSystemScore ? roundDecimal((score / overallMaxSystemScore) * 100) : 0;
};

const calculateDGNBOverallSuperScore = (grouping) => {
  let sum = 0;
  (grouping || []).forEach((item) => {
    sum += item.absoluteDegreeOfFulfillment || 0;
  });
  return sum;
};

export const calculateOverallSuperScore = (header, grouping, score, systemLogic) => {
  let result;
  if (systemLogic === SYSTEM_LOGIC_DGNB) {
    result = calculateDGNBOverallSuperScore(grouping);
  } else {
    result = calculateGeneralOverallSuperScore(header, score);
  }

  return Math.min(result, 100);
};

const calculateGroupingWeightedSystemScore = (node) =>
  node.weightingFactor && node.evaluationSystemScore ? node.weightingFactor * node.evaluationSystemScore : 0;
const calculatePotentialGroupingWeightedSystemScore = (node) =>
  node.weightingFactor && node.potentialEvaluationSystemScore
    ? node.weightingFactor * node.potentialEvaluationSystemScore
    : 0;
const getMaxSystemScoreOrLimit = (node) =>
  !!node.maxSystemScoreLimit && node.maxSystemScoreLimit < node.maxSystemScore
    ? node.maxSystemScoreLimit
    : node.maxSystemScore;

const recalculateGeneralGroupingScore = (values) => {
  forEachRecursiveReverseStrict(values, (node) => {
    if (isExcludedElement(node)) {
      return null;
    }
    if (node.type === GROUPING_TYPE_INDICATOR) {
      const { maxSystemScore, evaluationSystemScore } = node;
      if (maxSystemScore < evaluationSystemScore) {
        node.evaluationSystemScore = maxSystemScore;
      }
      node.universalScore = maxSystemScore ? (evaluationSystemScore / maxSystemScore) * 100 : 0;
      node.weightedSystemScore = node.evaluationSystemScore * node.weighting;
    } else {
      const maxScore = getMaxSystemScoreOrLimit(node);
      let evaluationSystemScore = null;
      let evaluationSystemScoreDefined = node.children
        .filter(isIncludedElement)
        .some((item) =>
          conditionIsIndicator(item)
            ? item.evaluationSystemScore != null
            : item.evaluationSystemScore != null && item.weightingFactor != null
        );

      if (evaluationSystemScoreDefined) {
        evaluationSystemScore = 0;
        node.children.filter(isIncludedElement).forEach((item) => {
          if (conditionIsIndicator(item)) {
            evaluationSystemScore += +item.evaluationSystemScore;
          } else {
            evaluationSystemScore += +item.evaluationSystemScore * (+item.weightingFactor || 1);
          }
        });
      }

      if (node.type === GROUPING_TYPE_CRITERION) {
        const matchingElementField = "uuid";
        const parentElement = getElementByMatchingField(node.parentUuid, values, matchingElementField);
        node.relativeProportionMaxSystemScore = node.relativeProportionMaxSystemScore * parentElement.weightingFactor;
      }

      node.evaluationSystemScore =
        evaluationSystemScore != null && evaluationSystemScore > maxScore ? maxScore : evaluationSystemScore;
      node.universalScore = maxScore ? (node.evaluationSystemScore / maxScore) * 100 : 0;

      node.weightedSystemScore = calculateGroupingWeightedSystemScore(node);
    }

    node.degreeOfFulfillment = getNodeRelativeDegreeOfFulfillment(node, values, SYSTEM_LOGIC_STANDARD);
    node.absoluteDegreeOfFulfillment = getNodeAbsoluteDegreeOfFulfillment(node, values, SYSTEM_LOGIC_STANDARD);
  });
};

const recalculateDGNBGroupingScore = (values) => {
  forEachRecursiveReverseStrict(values, (node) => {
    if (isExcludedElement(node)) {
      return null;
    }
    if (node.type === GROUPING_TYPE_INDICATOR) {
      const { maxSystemScore, evaluationSystemScore } = node;
      if (maxSystemScore < evaluationSystemScore) {
        node.evaluationSystemScore = maxSystemScore;
      }
      node.universalScore = maxSystemScore ? (evaluationSystemScore / maxSystemScore) * 100 : 0;
      node.weightedSystemScore = node.evaluationSystemScore * node.weighting;
    } else {
      const maxScore = getMaxSystemScoreOrLimit(node);
      let evaluationSystemScore = null;
      let evaluationSystemScoreDefined = node.children
        .filter(isIncludedElement)
        .some((item) => item.evaluationSystemScore != null && item.weightingFactor != null);

      if (evaluationSystemScoreDefined) {
        evaluationSystemScore = 0;
        node.children.filter(isIncludedElement).forEach((item) => {
          evaluationSystemScore += +item.evaluationSystemScore * (+item.weightingFactor || 1);
        });
      }

      node.evaluationSystemScore =
        evaluationSystemScore != null && evaluationSystemScore > maxScore ? maxScore : evaluationSystemScore;

      if (node.type === GROUPING_TYPE_CATEGORY) {
        let weightedSystemScore = 0;
        node.children.filter(conditionIsCriterionIncluded).forEach((item) => {
          weightedSystemScore += +item.weightedSystemScore;
        });
        node.weightedSystemScore = weightedSystemScore;

        if (node.degreeOfFulfillment > 100) {
          node.degreeOfFulfillment = 100;
        }

        node.universalScore = node.weightedMaxSystemScore
          ? (node.weightedSystemScore / node.weightedMaxSystemScore) * 100
          : 0;
        if (node.universalScore > 100) {
          node.universalScore = 100;
        }
      }
      if (node.type === GROUPING_TYPE_CRITERION) {
        node.weightedSystemScore = calculateGroupingWeightedSystemScore(node);
        node.universalScore = node.weightedMaxSystemScore
          ? (node.weightedSystemScore / node.weightedMaxSystemScore) * 100
          : 0;
      }
    }

    node.degreeOfFulfillment = getNodeRelativeDegreeOfFulfillment(node, values, SYSTEM_LOGIC_DGNB);
    node.absoluteDegreeOfFulfillment = getNodeAbsoluteDegreeOfFulfillment(node, values, SYSTEM_LOGIC_DGNB);
  });
};

export const recalculateGroupingScore = (values, systemLogic) => {
  if (systemLogic === SYSTEM_LOGIC_DGNB) {
    recalculateDGNBGroupingScore(values);
  } else {
    recalculateGeneralGroupingScore(values);
  }
};

const recalculateGeneralPotentialGroupingScore = (values) => {
  mapRecursive(values, (node, children) => {
    if (isExcludedElement(node)) {
      return { ...node, children };
    }
    if (conditionNotIndicator(node)) {
      const maxScore = getMaxSystemScoreOrLimit(node);
      let potentialEvaluationSystemScore = null;
      let potentialEvaluationSystemScoreDefined = children
        .filter(isIncludedElement)
        .some((item) =>
          conditionIsIndicator(item)
            ? item.effectivePotentialEvaluationSystemScore != null || item.evaluationSystemScore != null
            : (item.potentialEvaluationSystemScore != null || item.evaluationSystemScore != null) &&
              item.weightingFactor != null
        );

      if (potentialEvaluationSystemScoreDefined) {
        potentialEvaluationSystemScore = 0;
        children.filter(isIncludedElement).forEach((item) => {
          if (item.type === GROUPING_TYPE_INDICATOR) {
            potentialEvaluationSystemScore +=
              chosePotentialOrCurrent(item.effectivePotentialEvaluationSystemScore, item.evaluationSystemScore) || 0;
          } else {
            potentialEvaluationSystemScore +=
              chosePotentialOrCurrent(item.potentialEvaluationSystemScore, item.evaluationSystemScore) *
                (+item.weightingFactor || 1) || 0;
          }
        });
      }

      if (node.type === GROUPING_TYPE_CATEGORY) {
        let weightedPotentialSystemScore = 0;
        node.children.filter(conditionIsCriterionIncluded).forEach((item) => {
          weightedPotentialSystemScore += +item.weightedPotentialSystemScore;
        });
        node.weightedPotentialSystemScore = weightedPotentialSystemScore * node.weightingFactor;
      } else if (node.type === GROUPING_TYPE_CRITERION) {
        node.weightedPotentialSystemScore = calculatePotentialGroupingWeightedSystemScore(node);
      }

      node.potentialEvaluationSystemScore =
        potentialEvaluationSystemScore > maxScore ? maxScore : potentialEvaluationSystemScore;
      node.potentialUniversalScore = maxScore ? (node.potentialEvaluationSystemScore / maxScore) * 100 : 0;
    } else {
      node.weightedPotentialSystemScore = node.effectivePotentialEvaluationSystemScore * node.weighting;

      const { maxSystemScore, potentialEvaluationSystemScore } = node;
      if (maxSystemScore < potentialEvaluationSystemScore) {
        node.potentialEvaluationSystemScore = maxSystemScore;
      }
      const isPotentialDefined = potentialEvaluationSystemScore || potentialEvaluationSystemScore === 0;
      const effectivePotentialEvaluationSystemScore = isPotentialDefined
        ? potentialEvaluationSystemScore
        : node.evaluationSystemScore;
      node.effectivePotentialEvaluationSystemScore =
        maxSystemScore < effectivePotentialEvaluationSystemScore
          ? maxSystemScore
          : effectivePotentialEvaluationSystemScore;
      node.potentialUniversalScore = (effectivePotentialEvaluationSystemScore / maxSystemScore) * 100;
    }

    node.relativeDegreeOfFulfillmentPotential = getNodeRelativeDegreeOfFulfillmentPotential(
      node,
      SYSTEM_LOGIC_STANDARD
    );
    node.absoluteDegreeOfFulfillmentPotential = getNodeAbsoluteDegreeOfFulfillmentPotential(
      node,
      values,
      SYSTEM_LOGIC_STANDARD
    );
    node.differenceRelativeDegreeOfFulfillment = getNodeDifferenceRelativeDegreeOfFulfillment(node, values);
    node.differenceAbsoluteDegreeOfFulfillment = getNodeDifferenceAbsoluteDegreeOfFulfillment(node, values);
    return { ...node, children };
  });
};

const recalculateDGNBPotentialGroupingScore = (values) => {
  mapRecursive(values, (node, children) => {
    if (isExcludedElement(node)) {
      return { ...node, children };
    }
    if (node.type !== GROUPING_TYPE_INDICATOR) {
      const maxScore = getMaxSystemScoreOrLimit(node);
      let potentialEvaluationSystemScore = null;
      let potentialEvaluationSystemScoreDefined = children
        .filter(isIncludedElement)
        .some((item) =>
          conditionIsIndicator(item)
            ? item.effectivePotentialEvaluationSystemScore != null || item.evaluationSystemScore != null
            : (item.potentialEvaluationSystemScore != null || item.evaluationSystemScore != null) &&
              item.weightingFactor != null
        );

      if (potentialEvaluationSystemScoreDefined) {
        potentialEvaluationSystemScore = 0;
        children.filter(isIncludedElement).forEach((item) => {
          if (item.type === GROUPING_TYPE_INDICATOR) {
            potentialEvaluationSystemScore +=
              chosePotentialOrCurrent(item.effectivePotentialEvaluationSystemScore, item.evaluationSystemScore) || 0;
          } else {
            potentialEvaluationSystemScore +=
              chosePotentialOrCurrent(item.potentialEvaluationSystemScore, item.evaluationSystemScore) *
                (+item.weightingFactor || 1) || 0;
          }
        });
      }

      node.potentialEvaluationSystemScore =
        potentialEvaluationSystemScore > maxScore ? maxScore : potentialEvaluationSystemScore;

      if (node.type === GROUPING_TYPE_CATEGORY) {
        let weightedPotentialSystemScore = 0;
        node.children.filter(conditionIsCriterionIncluded).forEach((item) => {
          weightedPotentialSystemScore += +item.weightedPotentialSystemScore;
        });
        node.weightedPotentialSystemScore = weightedPotentialSystemScore;
        node.potentialUniversalScore = node.weightedMaxSystemScore
          ? (node.weightedPotentialSystemScore / node.weightedMaxSystemScore) * 100
          : 0;
        if (node.potentialUniversalScore > 100) {
          node.potentialUniversalScore = 100;
        }
        let potentialDegreeOfFulfillment = node.weightedMaxSystemScore
          ? (node.potentialEvaluationSystemScore / node.weightedMaxSystemScore) * 100
          : 0;
        if (potentialDegreeOfFulfillment > 100) {
          potentialDegreeOfFulfillment = 100;
        }
        node.absoluteDegreeOfFulfillmentPotential = potentialDegreeOfFulfillment * node.weightingFactor;
      }
      if (node.type === GROUPING_TYPE_CRITERION) {
        node.weightedPotentialSystemScore = calculatePotentialGroupingWeightedSystemScore(node);
        node.potentialUniversalScore = node.weightedMaxSystemScore
          ? (node.weightedPotentialSystemScore / node.weightedMaxSystemScore) * 100
          : 0;
      }
    } else {
      const { maxSystemScore, potentialEvaluationSystemScore } = node;
      if (maxSystemScore < potentialEvaluationSystemScore) {
        node.potentialEvaluationSystemScore = maxSystemScore;
      }
      const isPotentialDefined = potentialEvaluationSystemScore || potentialEvaluationSystemScore === 0;
      const effectivePotentialEvaluationSystemScore = isPotentialDefined
        ? potentialEvaluationSystemScore
        : node.evaluationSystemScore;
      node.effectivePotentialEvaluationSystemScore =
        maxSystemScore < effectivePotentialEvaluationSystemScore
          ? maxSystemScore
          : effectivePotentialEvaluationSystemScore;
      node.potentialUniversalScore = (effectivePotentialEvaluationSystemScore / maxSystemScore) * 100;
      node.weightedPotentialSystemScore = node.effectivePotentialEvaluationSystemScore * node.weighting;
    }

    node.relativeDegreeOfFulfillmentPotential = getNodeRelativeDegreeOfFulfillmentPotential(node, SYSTEM_LOGIC_DGNB);
    node.absoluteDegreeOfFulfillmentPotential = getNodeAbsoluteDegreeOfFulfillmentPotential(
      node,
      values,
      SYSTEM_LOGIC_DGNB
    );
    node.differenceRelativeDegreeOfFulfillment = getNodeDifferenceRelativeDegreeOfFulfillment(node, values);
    node.differenceAbsoluteDegreeOfFulfillment = getNodeDifferenceAbsoluteDegreeOfFulfillment(node, values);

    return { ...node, children };
  });
};

export const recalculatePotentialGroupingScore = (values, systemLogic) => {
  if (systemLogic === SYSTEM_LOGIC_DGNB) {
    recalculateDGNBPotentialGroupingScore(values);
  } else {
    recalculateGeneralPotentialGroupingScore(values);
  }
};

export const mapGroupings = (groupingElements) =>
  mapRecursive(
    groupingElements,
    (node, children) => ({
      ...node,
      children: (node?.indicatorElements && node.indicatorElements?.length ? node.indicatorElements : children).map(
        (item) => ({
          ...item,
        })
      ),
    }),
    "groupingElements"
  );

export const getKPIValues = (grouping = [], childrenPropertyName = "children") => {
  const kpiNames = {};
  const kpis = [];
  forEachRecursive(
    grouping,
    (node) => {
      if (isExcludedElement(node)) {
        return null;
      }
      const { kpi } = node;
      if (kpi) {
        const nameUnit = kpi.name + "_" + kpi.unit;
        if (!kpiNames[nameUnit]) {
          const key = SCORE_TYPES.KPI_TYPE + "_" + nameUnit;
          kpis.push({
            key: key,
            name: kpi.name,
            unit: kpi.unit,
            indicatorId: node.id,
            indicatorName: node.indicatorName || node.name,
            currentValue: kpi.currentValue,
          });
          kpiNames[nameUnit] = true;
        }
      }
    },
    childrenPropertyName
  );
  return kpis;
};

export const checkPotentialChanged = (grouping) => {
  let res = false;
  forEachRecursive(grouping, (node) => {
    if (
      node.type === GROUPING_TYPE_INDICATOR &&
      (node.evaluationSystemScore != null || node.potentialEvaluationSystemScore != null)
    ) {
      res = true;
      return true;
    }
  });
  return res;
};

export const checkKOFailed = (grouping) => {
  let res = false;
  forEachRecursive(grouping, (node) => {
    if (isExcludedElement(node)) {
      return null;
    }
    if (node.evaluationSystemScore !== null && !!node.koValue && node.koValue > node.evaluationSystemScore) {
      res = true;
      return true;
    }
  });
  return res;
};

export const checkPASTKOFailed = (grouping) => {
  let res = false;
  forEachRecursive(grouping, (node) => {
    if (isExcludedElement(node)) {
      return null;
    }
    if (!!node.universalScore && !!node.koValue && node.koValue > node.universalScore) {
      res = true;
      return true;
    }
  });
  return res;
};

const validateThresholds = ({ internalId }, elementList, attribute) =>
  elementList.every(
    (element) =>
      !element?.elementAwardThresholds?.[internalId?.toLowerCase()] ||
      element?.elementAwardThresholds?.[internalId?.toLowerCase()] <= element?.[attribute]
  );

export const checkThresholdFailed = (awards, elementList, score, attribute) =>
  (awards || []).some((item) => score >= item.scoreFrom && !validateThresholds(item, elementList, attribute));

export const getAward = (awards, elementList, score, attribute) =>
  (awards || [])
    .sort((a, b) => (b.scoreFrom || 0) - (a.scoreFrom || 0))
    .find(
      (item, index, sortedAwards) =>
        score >= item.scoreFrom &&
        validateThresholds(item, elementList, attribute) &&
        sortedAwards.slice(index + 1).every((award) => validateThresholds(award, elementList, attribute))
    );

export const calculateUniversalScore = (evaluationSystemScore, maxSystemScore) =>
  evaluationSystemScore && maxSystemScore ? roundDecimal((evaluationSystemScore / +maxSystemScore) * 100) : null;

export const mapRatingSystemGroupings = (groupingElements) =>
  mapRecursive(
    groupingElements || [],
    (node, children) => {
      return {
        UID: String(node.id || getUUID()),
        ...node,
        children: [
          ...(node.indicatorElements || []).map((item) => {
            return {
              UID: item.id ? "i_" + item.id : getUUID(),
              ...item,
              indicatorName: item.name,
              kpis: item.kpis,
              name: node.name,
              type: GROUPING_TYPE_INDICATOR,
            };
          }),
          ...children,
        ],
      };
    },
    "groupingElements"
  );

export const processGrouping = (grouping) => {
  return mapRecursive(grouping, (node, children) => {
    if (node?.type !== GROUPING_TYPE_INDICATOR) {
      const indicatorElements = children.filter(conditionIsIndicator);
      const groupingElements = children.filter(conditionNotIndicator);
      return {
        ...node,
        indicatorElements,
        groupingElements,
        UID: undefined,
        children: undefined,
      };
    }
    return {
      ...node,
      UID: undefined,
      children: undefined,
    };
  });
};

export const ratingSystemFromEvaluate = (system, evaluate) => {
  const evaluateData = { ...deepCopy(evaluate) };
  const groupingElements = evaluateData.groupingElements;
  const indicatorElements = evaluateData.indicatorElements;
  delete evaluateData.groupingElements;
  delete evaluateData.indicatorElements;
  delete evaluateData.ratingSystemId;
  const updated = { ...deepCopy(system), ...evaluateData };
  const groupingsByKey = {};
  const indicatorsByKey = {};
  groupingElements?.forEach((item) => (groupingsByKey[item.id] = item));
  indicatorElements?.forEach((item) => (indicatorsByKey[item.id] = item));
  forEachRecursive(
    updated.groupingElements,
    (node) => {
      const groupingElement = groupingsByKey[node.id] || {};
      Object.keys(groupingElement).forEach((key) => {
        node[key] = groupingElement[key];
      });
      const indicators = node?.indicatorElements || [];
      indicators.forEach((nodeIndicator) => {
        const indicatorElement = indicatorsByKey[nodeIndicator.id] || {};
        Object.keys(indicatorElement).forEach((key) => {
          nodeIndicator[key] = indicatorElement[key];
        });
      });
    },
    "groupingElements"
  );
  return updated;
};

export const groupingElementsFromChildren = (children) => {
  const groupingElements = [...children];
  forEachRecursive(children, (node) => {
    if (conditionIsGrouping(node)) {
      node.groupingElements = node.children?.filter(conditionIsGrouping) || [];
      if (conditionIsCriterion(node)) {
        node.indicatorElements = node.children?.filter(conditionIsIndicator) || [];
      }
    }
  });
  return groupingElements;
};

export const getWaitingLinkedKPIs = (groupingElements) => {
  const linkedKpis = [];
  forEachRecursive(
    groupingElements || [],
    (node) => {
      node.indicatorElements?.forEach(({ kpi }) => {
        if (
          [KPI_STATUS_WAITING, KPI_STATUS_CHANGED].includes(kpi?.linkStatus) &&
          !linkedKpis.some((item) => item.nameAndUnit === kpi.nameAndUnit)
        ) {
          linkedKpis.push(kpi);
        }
      });
    },
    "groupingElements"
  );
  return linkedKpis;
};

export const canView = (revision, { permissionReader }) =>
  !!revision && (!revision.accessRestricted || !!permissionReader);

export const canEdit = ({ member }) => member;

export const canViewProject = (accessRestricted = false, { permissionReader } = {}) =>
  !accessRestricted || !!permissionReader;

export const canApplyChanges = (ratingStatus = null, { permissionChangeApplier } = {}) =>
  ratingStatus !== STATUS_REPORTING && !!permissionChangeApplier;

export const getMissingStatuses = (node, actionCondition) => {
  const { evaluationSystemScore, reason, actions } = node || {};

  const mustHaveActions = actionCondition(node);
  const scorePoint = !!evaluationSystemScore || evaluationSystemScore === 0 ? 1 : 0;
  const reasonPoint = reason ? 1 : 0;
  const actionsPoint = mustHaveActions && actions?.length > 0 ? 1 : 0;

  return { scorePoint, reasonPoint, actionsPoint, mustHaveActions };
};

export const getMissingStatusesSearchConfig = (t, actionCondition) => {
  return {
    type: MENU_TYPE,
    multiple: true,
    items: [
      {
        value: MISSING_SCORE_FILTER,
        text: t("ratingSystem.status.missingScore"),
      },
      {
        value: MISSING_REASON_FILTER,
        text: t("ratingSystem.status.missingReason"),
      },
      {
        value: MISSING_ACTION_FILTER,
        text: t("ratingSystem.status.missingAction"),
      },
    ],
    searchFunction: (node, field, value) => {
      if (value.length === 0) {
        return false;
      }

      if (node.type.toLowerCase() !== INDICATOR_GROUPING_TYPE) {
        return true;
      }

      const { reasonPoint, scorePoint, actionsPoint } = getMissingStatuses(node, actionCondition);
      const points = {
        MISSING_REASON: reasonPoint,
        MISSING_SCORE: scorePoint,
        MISSING_ACTION: actionsPoint,
      };

      return value.reduce((result, currentValue) => result && points[currentValue], true);
    },
  };
};
