import { drawBackgroundLine, drawValueBar } from "../../../helpers/canvas";

export const renderKPIChart = (canvas, width, height, { values, benchmark }, {
  heightInPoints,
  limitedYearsRange,
  valuesByYear,
  linesAmount
}) => {
  const offset = 20;
  const separatorWidth = 6;
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.reset();
  const amount = linesAmount;
  const lineStepHeight = height / amount;
  for (let i = 0; i < amount; i++) {
    const y = i * lineStepHeight;
    drawBackgroundLine(ctx, 0, y, width, y, '#ECECEC', 2);
  }

  const yearsAmount = limitedYearsRange.length;
  if (yearsAmount) {
    const stepWidth = (width - (offset * 2)) / yearsAmount;

    for (let year = limitedYearsRange[0], index = 0; index <= limitedYearsRange.length; year++, index++) {
      const value = valuesByYear[year] || {};
      const barWidth = stepWidth > 90 ? 30 : stepWidth / 3;
      const valueOffset = value.potential ? -barWidth / 2 - separatorWidth / 2 : 0;
      if (value.estimated) {
        drawValueBar(ctx, index * stepWidth + stepWidth / 2 + offset + valueOffset,
          height, barWidth, value.estimated / heightInPoints * height, 1, '#007CC0',
          value.measured ? {} : undefined);
      }
      if (value.measured) {
        const heightOffset = value.estimated ? Math.floor(value.estimated / heightInPoints * height) : 0;
        drawValueBar(ctx, index * stepWidth + stepWidth / 2 + offset + valueOffset,
          height - heightOffset, barWidth, value.measured / heightInPoints * height,
          1, '#214F91');
      }
      if (value.potential) {
        drawValueBar(ctx, index * stepWidth + stepWidth / 2 + offset + barWidth / 2 + separatorWidth / 2,
          height, barWidth, value.potential / heightInPoints * height, 1, '#548233');
      }
    }
    const firstYear = limitedYearsRange[0];
    const lineWidth = 2;
    const lineOffset = lineWidth / 2;

    if (values?.some(item => item.goal)) {
      let prevGoal = (values.find(item => item.goal) || {}).goal || 0;
      const nextGoal = (values.findLast(item => item.goal) || {}).goal || 0;
      ctx.beginPath();
      ctx.moveTo(-lineWidth, Math.round(height - prevGoal / heightInPoints * height) - lineOffset);
      for (let index = 0; index <= values.length; index++) {
        const { year } = values[index] || {};
        const goal = (values[index] || {}).goal;
        if (goal) {
          ctx.lineTo(Math.round((year - firstYear) * stepWidth + stepWidth / 2) + offset, Math.round(height - goal / heightInPoints * height) - lineOffset);
        }
      }
      ctx.lineTo(width + lineWidth, Math.round(height - nextGoal / heightInPoints * height) - lineOffset);
      ctx.lineTo(width + lineWidth, height + lineWidth);
      ctx.lineTo(-lineWidth, height + lineWidth);
      ctx.closePath();

      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = '#62B0FF';
      ctx.fillStyle = '#62B0FF33';
      ctx.fill();
      ctx.stroke();
    }

    if (benchmark?.some(item => item.value)) {
      const prevBenchmark = benchmark.find(item => item.value)?.value || 0;
      const nextBenchmark = benchmark.findLast(item => item.value)?.value || 0;
      ctx.beginPath();
      ctx.moveTo(-lineWidth, Math.round(height - prevBenchmark / heightInPoints * height) - lineOffset);
      for (let index = 0; index <= benchmark.length; index++) {
        const { value, year } = benchmark[index] || {};
        if (value) {
          ctx.lineTo(Math.round((year - firstYear) * stepWidth + stepWidth / 2) + offset, Math.round(height - value / heightInPoints * height) - lineOffset);
        }
      }
      ctx.lineTo(width + lineWidth, Math.round(height - nextBenchmark / heightInPoints * height) - lineOffset);

      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = '#F72B2F';
      ctx.stroke();
    }
  }

  drawBackgroundLine(ctx, 0, height, width, height, '#B9BCBE', 2);
};
