import CloseIcon from "@mui/icons-material/Close";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { CheckTree } from "../../components/CheckTree/CheckTree";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { FormController } from "../../components/FormController/FormController";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { GeneralAttributesDialog } from "../../components/ProjectAttributes/GeneralAttributesDialog/GeneralAttributesDialog";
import { ProjectAttributeDialog } from "../../components/ProjectAttributes/ProjectAttributeDialog/ProjectAttributeDialog";
import { ProjectAttributeDragOverlay } from "../../components/ProjectAttributes/ProjectAttributeDragOverlay";
import { ProjectAttributeItem } from "../../components/ProjectAttributes/ProjectAttributeItem/ProjectAttributeItem";
import { PROJECT_TYPES } from "../../constants/project";
import {
  ATTRIBUTE_STATUS_ACTIVE,
  LEFT_COLUMN_PLACEHOLDER_ID,
  RIGHT_COLUMN_PLACEHOLDER_ID,
} from "../../constants/projectAttribute";
import { showDialog } from "../../hooks/dialog";
import {
  createAdminGeneralProjectAttribute,
  getAdminProjectAttributeList,
  setProjectAttributeLandings,
  setProjectTypeAttributes,
  updateAdminProjectAttributes,
  useAdminProjectAttributeListResponse,
  useAdminProjectAttributesUpdateResponse,
  useProjectAttributeDragStart,
  useProjectTypeAttributes,
} from "../../hooks/projectAttribute";
import { showError } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { PlusSVG, SaveSVG } from "../../svg";
import { deepCopy, mapRecursive } from "../../utils";
import "./ProjectAttributes.scss";

export const ProjectAttributesPage = () => {
  const { t } = useTranslation();
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const [selectedProjectType, setSelectedProjectType] = useState(null);
  const projectTypeAttributes = useProjectTypeAttributes();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [popoverTarget, setPopoverTarget] = useState(null);
  const [projectAttributeViewData, setProjectAttributeViewData] = useState(null);
  const [addProjectAttribute, setAddProjectAttribute] = useState(null);
  const updateResponse = useAdminProjectAttributesUpdateResponse();
  const response = useAdminProjectAttributeListResponse();
  const responseDataFields = (response.data?.fields || []).map((item) => ({
    ...item,
    uuid: item.id,
  }));
  const projectAttributeDragStart = useProjectAttributeDragStart();

  const { handleSubmit, control, reset, getValues, formState } = useForm({
    defaultValues: { details: { en: "", de: "" } },
  });

  const leftAttributes = projectTypeAttributes?.filter((item) => item.columnIndex !== 1);
  const rightAttributes = projectTypeAttributes?.filter((item) => item.columnIndex === 1);

  const isChanged =
    formState.isDirty ||
    (!response.loading &&
      !!projectTypeAttributes &&
      JSON.stringify(responseDataFields) !== JSON.stringify(projectTypeAttributes));

  const load = () => {
    if (selectedProjectType) {
      getAdminProjectAttributeList(selectedProjectType);
    }
  };

  useEffect(() => {
    load();
    setProjectTypeAttributes([]);
  }, [selectedProjectType]);

  const getLandings = (nodes) =>
    [...(nodes || [])].map((item) => ({
      left: item.offsetLeft,
      top: item.offsetTop,
      width: item.offsetWidth,
      height: item.offsetHeight,
      uuid: item.id,
    }));

  useEffect(() => {
    if (!response.loading) {
      const details = response.data?.details;
      reset({ details: { en: details?.en || "", de: details?.de || "" } });
      setProjectTypeAttributes(response.data?.fields ? deepCopy(responseDataFields) : []);
    }
  }, [response.loading]);

  useEffect(() => {
    const leftLandings = getLandings(leftRef?.current?.childNodes);
    const rightLandings = getLandings(rightRef?.current?.childNodes);
    setProjectAttributeLandings([...leftLandings, ...rightLandings]);
  }, [leftRef?.current, rightRef?.current, projectTypeAttributes]);

  const showAddAttributeModal = useEvent((data) => {
    hideAddPopover();
    setAddProjectAttribute(data);
  });

  const showDeleteConfirmation = useEvent((data) => setDeleteConfirmation(data));
  const hideAddPopover = useEvent(() => setPopoverTarget(null));
  const showAddPopover = useEvent((event) => setPopoverTarget(event.currentTarget));

  const addAttribute = useEvent((attribute) =>
    setProjectTypeAttributes([
      ...projectTypeAttributes,
      {
        ...attribute,
        uuid: getUUID(),
      },
    ])
  );

  const showAddGeneralModal = useEvent(() => {
    hideAddPopover();
    showDialog({
      className: "large",
      closeOnClickOutside: true,
      getContent: (onClose) => <GeneralAttributesDialog onClose={onClose} addAttribute={addAttribute} />,
    });
  });

  const save = useEvent(() =>
    updateAdminProjectAttributes(selectedProjectType, {
      id: response.data?.id,
      ...getValues(),
      fields: (projectTypeAttributes || []).map((item) => ({
        ...item,
        uuid: undefined,
      })),
    })
      .then(load)
      .catch(console.error)
  );

  const cancel = useEvent(() => {
    setSelectedProjectType(null);
    reset({});
    setProjectTypeAttributes(null);
  });

  const deleteHandler = useEvent(() => {
    setDeleteConfirmation(null);
    const updated = projectTypeAttributes.filter((item) => item.uuid !== deleteConfirmation.uuid);
    setProjectTypeAttributes(updated);
  });

  const isUniqueName = useEvent(
    (uuid, name) => !projectTypeAttributes.some((item) => item.name === name && item.uuid !== uuid)
  );

  const changeFieldPosition = useEvent((data, uuid) => {
    let targetColumnIndex = -1;
    if (uuid === LEFT_COLUMN_PLACEHOLDER_ID || leftAttributes.some((item) => item.uuid === uuid)) {
      targetColumnIndex = 0;
    } else if (uuid === RIGHT_COLUMN_PLACEHOLDER_ID || rightAttributes.some((item) => item.uuid === uuid)) {
      targetColumnIndex = 1;
    }
    if (targetColumnIndex !== -1) {
      const currentColumnIndex = data?.columnIndex || 0;
      const currentIndex = projectTypeAttributes.findIndex((item) => item.uuid === data.uuid);
      if (currentIndex !== -1) {
        let targetIndex = projectTypeAttributes.findIndex((item) => item.uuid === uuid);
        const updated = [...projectTypeAttributes];
        const spliced = updated.splice(currentIndex, 1)[0];
        updated.splice(targetIndex === -1 ? updated.length : targetIndex, 0, spliced);
        if (targetColumnIndex !== currentColumnIndex) {
          spliced.columnIndex = targetColumnIndex;
        }
        setProjectTypeAttributes(updated);
      }
    }
  });

  const loading = response.loading || updateResponse.loading;

  return (
    <LoadingOverlay spinner active={loading} className="project-attributes-page page-with-table">
      <ProjectAttributeDragOverlay onChangePosition={changeFieldPosition} />

      <div className="page-header large">
        <div className="flex-col title-box no-margin">
          <h1>{t("projectAttributes.title")}</h1>
        </div>
      </div>

      <div className="page-layout">
        <div className="flex-row">
          <div className="project-types-column">
            <CheckTree
              disabled={selectedProjectType && isChanged}
              multiple={false}
              lastLevelOnly={true}
              items={mapRecursive(
                PROJECT_TYPES,
                (item, children) => ({
                  ...item,
                  text: t("sustainabilitySystem.type." + item.name),
                  items: children,
                }),
                "items"
              )}
              values={selectedProjectType}
              onChange={setSelectedProjectType}
            />
          </div>
          {!!selectedProjectType && (
            <div className="flex-auto" key={response.loading}>
              <div className="flex-row project-headers">
                <div className="k-flex-1">
                  <FormController
                    control={control}
                    name="details.en"
                    required
                    label={t("projectAttributes.header") + " EN"}
                    render={({ field, fieldState, label }) => (
                      <TextField
                        error={fieldState.invalid}
                        fullWidth
                        label={label + " *"}
                        {...field}
                        value={field?.value || ""}
                      />
                    )}
                  />
                </div>
                <div className="k-flex-1">
                  <FormController
                    control={control}
                    name="details.de"
                    required
                    label={t("projectAttributes.header") + " DE"}
                    render={({ field, fieldState, label }) => (
                      <TextField
                        error={fieldState.invalid}
                        fullWidth
                        label={label + " *"}
                        {...field}
                        value={field?.value || ""}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="fields-container">
                <div className="fields-left" ref={leftRef}>
                  {leftAttributes?.map((item) => (
                    <ProjectAttributeItem
                      data={item}
                      key={item.uuid}
                      onEdit={setProjectAttributeViewData}
                      onDelete={showDeleteConfirmation}
                      className={item.uuid === projectAttributeDragStart?.data?.uuid ? "opacity-0" : ""}
                    />
                  ))}
                  {!leftAttributes?.length && (
                    <div className="field-placeholder" id={LEFT_COLUMN_PLACEHOLDER_ID}>
                      &nbsp;
                    </div>
                  )}
                </div>
                <div className="fields-right" ref={rightRef}>
                  {rightAttributes?.map((item) => (
                    <ProjectAttributeItem
                      data={item}
                      key={item.uuid}
                      onEdit={setProjectAttributeViewData}
                      onDelete={showDeleteConfirmation}
                      className={item.uuid === projectAttributeDragStart?.data?.uuid ? "opacity-0" : ""}
                    />
                  ))}
                  {!rightAttributes?.length && (
                    <div className="field-placeholder" id={RIGHT_COLUMN_PLACEHOLDER_ID}>
                      &nbsp;
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {!!selectedProjectType && (
        <div className="fabs static">
          <Fab type="button" color="primary" onClick={showAddPopover}>
            <PlusSVG className="fab-svg" />
          </Fab>
          <Fab type="button" color="primary" onClick={handleSubmit(save)} disabled={loading || !isChanged}>
            <SaveSVG className="fab-svg" />
          </Fab>
          <Fab type="button" color="secondary" onClick={cancel}>
            <CloseIcon className="fab-svg" />
          </Fab>
        </div>
      )}

      <Popover
        className={"menu-popover"}
        open={!!popoverTarget}
        anchorEl={popoverTarget}
        onClose={hideAddPopover}
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        disableRestoreFocus
      >
        <MenuItem onClick={showAddAttributeModal}>{t("projectAttributes.addNew")}</MenuItem>
        <MenuItem onClick={showAddGeneralModal}>{t("projectAttributes.addGeneral")}</MenuItem>
      </Popover>

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={t("projectAttributes.deleteConfirmationTitle")}
        bodyText={t("projectAttributes.deleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />
      <ProjectAttributeDialog
        data={projectAttributeViewData}
        readOnly
        open={projectAttributeViewData}
        onClose={() => setProjectAttributeViewData(null)}
      />
      <ProjectAttributeDialog
        isUniqueName={isUniqueName}
        readOnly={false}
        onChange={async (attribute) => {
          try {
            const created = await createAdminGeneralProjectAttribute({
              ...attribute,
              uuid: undefined,
              status: ATTRIBUTE_STATUS_ACTIVE,
            });
            setProjectTypeAttributes([...projectTypeAttributes, { ...created, id: undefined, uuid: getUUID() }]);
            setAddProjectAttribute(null);
          } catch (error) {
            console.error(error);
            if (error && error.type !== "abort") {
              if (error.status === 409) {
                showError(t("generalAttributes.error.409"));
              } else {
                showError(t("error.500"));
              }
            }
          }
        }}
        open={addProjectAttribute}
        onClose={() => setAddProjectAttribute(null)}
      />
    </LoadingOverlay>
  );
};
