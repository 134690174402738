import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React from "react";
import { PERMISSION_KPI_EDIT, PERMISSION_KPI_LINK_GENERAL_ATTRIBUTE } from "../../constants/permissions";
import { DEFAULT_UNIT, UNIT_OPTIONS } from "../../constants/unit";
import { hasPermission } from "../../helpers/permission";
import { Chips } from "../Chips/Chips";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";

const isEditableRow = (row, editRow) => !!editRow && editRow.id === row.id;
const isAddRow = (row, isAdd) => isAdd && !row.id;

export const TextColumnTemplate = (props, field, parentRow, passThrough) => {
  const { editRow, isAdd, onValueChange, submitted } = passThrough;
  if (isEditableRow(props, editRow) || isAddRow(props, isAdd)) {
    return (
      <FormControl fullWidth>
        <TextField
          error={submitted && !props[field] && field === "name"}
          type={"text"}
          key={props.id || -1}
          inputProps={{ maxLength: 255 }}
          defaultValue={props[field] || ""}
          onBlur={(event) => onValueChange(props, field, event.target.value)}
        />
      </FormControl>
    );
  }

  return props[field];
};

export const TagsColumnTemplate = (props, field, parentRow, passThrough) => {
  const { editRow, isAdd, onValueChange } = passThrough;
  const readOnly = !isEditableRow(props, editRow) && !isAddRow(props, isAdd);
  return (
    <div className="chips">
      <Chips
        chips={props[field] || []}
        onChange={(tags) => onValueChange(props, field, tags)}
        readOnly={readOnly}
        noWrapper
        changeOnBlur
      />
    </div>
  );
};

export const UnitColumnTemplate = (props, field, parentRow, passThrough) => {
  const { editRow, isAdd, onValueChange } = passThrough;
  const readOnly = !isEditableRow(props, editRow) && !isAddRow(props, isAdd);
  if (readOnly) {
    return props[field];
  } else {
    return (
      <Select
        value={props[field] || DEFAULT_UNIT}
        onChange={(event) => onValueChange(props, field, event.target.value)}
      >
        {UNIT_OPTIONS.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    );
  }
};

export const GeneralAttributeColumnTemplate = (props, field, parentRow, passThrough) => {
  const { editRow, isAdd, onValueChange, t, kpiGeneralAttributesResponse } = passThrough;
  const readOnly = !isEditableRow(props, editRow) && !isAddRow(props, isAdd);
  const value = props[field];

  if (readOnly || !hasPermission(PERMISSION_KPI_LINK_GENERAL_ATTRIBUTE)) {
    return value;
  } else {
    const options = [...(kpiGeneralAttributesResponse?.data || [])];
    if (!options.includes(value)) {
      options.splice(0, 0, value);
    }
    return (
      <LoadingOverlay spinner active={kpiGeneralAttributesResponse.loading} className="auto-height">
        <Select value={value || ""} onChange={(event) => onValueChange(props, field, event.target.value)}>
          <MenuItem key={-1} value={""}>
            {t("main.unselected")}
          </MenuItem>
          {options.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </LoadingOverlay>
    );
  }
};

export const ActionsColumnTemplate = (props, field, parentRow, passThrough) => {
  const { setDeleteConfirmation, editRow, setEditRow, isAdd, cancelEdit, save, create } = passThrough;
  const addRow = isAddRow(props, isAdd);
  if (isEditableRow(props, editRow) || addRow) {
    return (
      <>
        <IconButton color="primary" size="small" onClick={() => (addRow ? create(props) : save(props))}>
          <CheckIcon />
        </IconButton>
        <IconButton size="small" color="secondary" onClick={cancelEdit}>
          <CloseIcon />
        </IconButton>
      </>
    );
  }
  if (hasPermission(PERMISSION_KPI_EDIT)) {
    return (
      <>
        <IconButton color="primary" size="small" onClick={() => setEditRow(props)} disabled={!!editRow}>
          <EditIcon />
        </IconButton>
        <IconButton size="small" onClick={() => setDeleteConfirmation(props.id)} disabled={!!editRow}>
          <DeleteOutlineIcon />
        </IconButton>
      </>
    );
  }
};
