export const PERMISSION_TENANT_ADMIN = "TENANT_ADMIN";

export const PERMISSION_ACTION_CREATE = "ACTION_CREATE";
export const PERMISSION_ACTION_EDIT = "ACTION_EDIT";
export const PERMISSION_ACTION_READ = "ACTION_READ";

export const PERMISSION_PROJECT_CREATE = "PROJECT_CREATE";
export const PERMISSION_PROJECT_READ = "PROJECT_READ";
export const PERMISSION_PROJECT_MIGRATION = "PROJECT_MIGRATION";

export const PERMISSION_PROJECT_GROUP_READ = "PROJECT_GROUP_READ";
export const PERMISSION_PROJECT_GROUP_EDIT = "PROJECT_GROUP_EDIT";
export const PERMISSION_PROJECT_GROUP_DELETE = "PROJECT_GROUP_DELETE";

export const PERMISSION_BENCHMARK_READ = "BENCHMARK_READ";
export const PERMISSION_BENCHMARK_EDIT = "BENCHMARK_EDIT";
export const PERMISSION_BENCHMARK_CREATE = "BENCHMARK_CREATE";
export const PERMISSION_BENCHMARK_STATUS_CHANGE = "BENCHMARK_STATUS_CHANGE";
export const PERMISSION_BENCHMARK_DOCUMENT_UPLOAD = "BENCHMARK_DOCUMENT_UPLOAD";
export const PERMISSION_BENCHMARK_MIGRATION = "BENCHMARK_MIGRATION";

export const PERMISSION_SUSTAINABILITY_SYSTEM_CREATE = "SUSTAINABILITY_SYSTEM_CREATE";
export const PERMISSION_SUSTAINABILITY_SYSTEM_EDIT = "SUSTAINABILITY_SYSTEM_EDIT";
export const PERMISSION_SUSTAINABILITY_SYSTEM_READ = "SUSTAINABILITY_SYSTEM_READ";
export const PERMISSION_SUSTAINABILITY_SYSTEM_STATUS_CHANGE = "SUSTAINABILITY_SYSTEM_STATUS_CHANGE";
export const PERMISSION_SUSTAINABILITY_SYSTEM_MIGRATION = "SUSTAINABILITY_SYSTEM_MIGRATION";

export const PERMISSION_KPI_SYSTEM_CREATE = "KPI_SYSTEM_CREATE";
export const PERMISSION_KPI_SYSTEM_EDIT = "KPI_SYSTEM_EDIT";
export const PERMISSION_KPI_SYSTEM_READ = "KPI_SYSTEM_READ";
export const PERMISSION_KPI_SYSTEM_STATUS_CHANGE = "KPI_SYSTEM_STATUS_CHANGE";
export const PERMISSION_KPI_SYSTEM_MIGRATION = "KPI_SYSTEM_MIGRATION";

export const PERMISSION_KPI_CREATE = "KPI_CREATE";
export const PERMISSION_KPI_EDIT = "KPI_EDIT";
export const PERMISSION_KPI_READ = "KPI_READ";
export const PERMISSION_KPI_LINK_GENERAL_ATTRIBUTE = "KPI_LINK_GENERAL_ATTRIBUTE";

export const PERMISSION_TEXTBLOCK_CREATE = "TEXTBLOCK_CREATE";
export const PERMISSION_TEXTBLOCK_EDIT = "TEXTBLOCK_EDIT";
export const PERMISSION_TEXTBLOCK_READ = "TEXTBLOCK_READ";
export const PERMISSION_TEXTBLOCK_CREATE_IN_RATING_SYSTEM = "TEXTBLOCK_CREATE_IN_RATING_SYSTEM";

export const PERMISSION_USER_PROFILE_EDIT = "USER_EDIT";

export const PERMISSION_SUPER_ADMIN = "SUPER_ADMIN";

export const PERMISSION_GROUPS = {
  'SUSTAINABILITY_SYSTEM': [
    PERMISSION_SUSTAINABILITY_SYSTEM_READ, PERMISSION_SUSTAINABILITY_SYSTEM_CREATE,
    PERMISSION_SUSTAINABILITY_SYSTEM_EDIT, PERMISSION_SUSTAINABILITY_SYSTEM_STATUS_CHANGE,
    PERMISSION_SUSTAINABILITY_SYSTEM_MIGRATION
  ],
  'PROJECT': [
    PERMISSION_PROJECT_READ, PERMISSION_PROJECT_CREATE, PERMISSION_PROJECT_MIGRATION
  ],
  'PROJECT_GROUP': [PERMISSION_PROJECT_GROUP_READ, PERMISSION_PROJECT_GROUP_EDIT, PERMISSION_PROJECT_GROUP_DELETE],
  'BENCHMARK': [
    PERMISSION_BENCHMARK_READ, PERMISSION_BENCHMARK_EDIT, PERMISSION_BENCHMARK_CREATE,
    PERMISSION_BENCHMARK_STATUS_CHANGE, PERMISSION_BENCHMARK_DOCUMENT_UPLOAD, PERMISSION_BENCHMARK_MIGRATION
  ],
  'ACTION': [PERMISSION_ACTION_READ, PERMISSION_ACTION_CREATE, PERMISSION_ACTION_EDIT],
  'KPI': [PERMISSION_KPI_READ, PERMISSION_KPI_CREATE, PERMISSION_KPI_EDIT, PERMISSION_KPI_LINK_GENERAL_ATTRIBUTE],
  'KPI_SYSTEM': [
    PERMISSION_KPI_SYSTEM_READ, PERMISSION_KPI_SYSTEM_CREATE, PERMISSION_KPI_SYSTEM_EDIT,
    PERMISSION_KPI_SYSTEM_STATUS_CHANGE, PERMISSION_KPI_SYSTEM_MIGRATION
  ],
  'TEXTBLOCK': [
    PERMISSION_TEXTBLOCK_CREATE, PERMISSION_TEXTBLOCK_EDIT, PERMISSION_TEXTBLOCK_READ, PERMISSION_TEXTBLOCK_CREATE_IN_RATING_SYSTEM
  ],
  'USER_PROFILE': [
    PERMISSION_USER_PROFILE_EDIT
  ],
};
