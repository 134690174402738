import AnalyticsIcon from "@mui/icons-material/Assessment";
import EditRoadIcon from "@mui/icons-material/EditAttributes";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ExpandButton, TreeListPager } from "../../components/TreeList/treeListComponents";
import { DATETIME_FORMAT_CLIENT } from "../../constants/main";
import { AWARD_CALCULATION_TYPE_POINT } from "../../constants/sustainabilitySystem";
import { useProjectsPageLogic } from "../../hooks/project";
import { onlyDecimals } from "../../utils";
import { useProjectsTableState } from "./ProjectsTable";

const EXPAND_FIELD = "expanded";

const ActionsButton = ({ onClick }) => (
  <IconButton size="small" onClick={onClick}>
    <MoreVertIcon />
  </IconButton>
);

ActionsButton.propTypes = {
  onClick: PropTypes.func,
};

const EditLink = ({ to }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("groupingContext.executeEvaluation")}>
      <NavLink to={to}>
        <IconButton color="primary" size="small">
          <EditRoadIcon />
        </IconButton>
      </NavLink>
    </Tooltip>
  );
};

EditLink.propTypes = {
  to: PropTypes.string,
};

const AnalyticsButton = ({ title, to, disabled }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t(title)}>
      <NavLink
        to={to}
        onClick={disabled ? (e) => e.preventDefault() : undefined}
        className={disabled ? "no-events" : undefined}
      >
        <IconButton color="primary" size="small" disabled={disabled}>
          <AnalyticsIcon />
        </IconButton>
      </NavLink>
    </Tooltip>
  );
};

AnalyticsButton.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  disabled: PropTypes.bool,
};

export const ActionsColumn = (props) => {
  const { dataItem, hasChildren } = props;
  const isRootElement = props.level.length === 1;
  const { setActionsPopoverProps } = useProjectsPageLogic();

  if (!dataItem) return <td className="k-table-td"></td>;

  const items = dataItem?.children;
  const hasSingleItem = items?.length === 1;
  const moreItems = items?.length > 1;

  const { id, accessRestricted, projectPermission, kpiSystemId } = dataItem;
  const access = { accessRestricted, projectId: id };

  if (!projectPermission?.member) return <td className="k-table-td"></td>;

  const dashboardPath = `/project/${id}${kpiSystemId ? "/kpiDashboard/" : "/dashboard/"}${id}`;
  const editPath = `${!kpiSystemId ? "/project/ratingSystem/" : "/project/kpiRatingSystem/"}${id}?mode=edit`;

  let isRatingDashboardAvailable = false;
  if (hasChildren) isRatingDashboardAvailable = items[0]?.visibleOnDashboard;

  return (
    <td className="k-table-td">
      <div className="k-d-flex k-justify-content-end">
        {isRootElement && (
          <>
            {(moreItems || !hasChildren) && (
              <ActionsButton onClick={(event) => setActionsPopoverProps({ target: event.target, props, ...access })} />
            )}

            {hasSingleItem && (
              <>
                <EditLink to={editPath} />
                <AnalyticsButton
                  title="groupingContext.showRatingDashboard"
                  to={dashboardPath}
                  disabled={!isRatingDashboardAvailable}
                />
                <ActionsButton
                  onClick={(event) => setActionsPopoverProps({ target: event.target, props, ...access })}
                />
              </>
            )}
          </>
        )}
        {!isRootElement && (
          <>
            <EditLink to={editPath} />
            <AnalyticsButton
              title="groupingContext.showRatingDashboard"
              to={dashboardPath}
              disabled={!isRatingDashboardAvailable}
            />
            <ActionsButton onClick={(event) => setActionsPopoverProps({ target: event.target, props, ...access })} />
          </>
        )}
      </div>
    </td>
  );
};

ActionsColumn.propTypes = {
  dataItem: PropTypes.object,
  level: PropTypes.array,
  hasChildren: PropTypes.bool,
};

export const NameColumn = (props) => {
  const { t } = useTranslation();
  if (props?.level?.length > 1) return <td className="k-table-td"></td>;

  const onClick = (event) => props?.onExpandChange(event, props?.dataItem, props?.level);

  return (
    <td className="k-table-td">
      <div className="k-d-flex k-align-items-center k-gap-2">
        {props?.dataItem?.children?.length > 1 && <ExpandButton expanded={props?.expanded} onClick={onClick} />}

        {!!props?.dataItem?.accessRestricted && (
          <Tooltip title={t("projects.accessRestricted")}>
            <LockOutlinedIcon />
          </Tooltip>
        )}
        <NavLink to={"/project/" + props?.dataItem?.id} className="column-link flex-auto">
          {props?.dataItem?.name}
        </NavLink>
      </div>
    </td>
  );
};

NameColumn.propTypes = {
  dataItem: PropTypes.object,
  level: PropTypes.array,
  onExpandChange: PropTypes.func,
  expanded: PropTypes.bool,
};

export const RatingSystemPASTScoreColumn = (props) => {
  const { dataItem } = props;

  const row = dataItem?.children?.length === 1 ? dataItem?.children?.[0] : dataItem;
  if (row?.score === undefined) return <td className="k-table-td"></td>;

  return (
    <td className="k-table-td">
      <div className="k-d-flex k-justify-content-center">{row.score ? `${onlyDecimals(row.score, 2)}` : ""}</div>
    </td>
  );
};

RatingSystemPASTScoreColumn.propTypes = {
  kpiSystemId: PropTypes.number,
  score: PropTypes.number,
};

export const RatingSystemScoreColumn = (props) => {
  const { dataItem } = props;

  const row = dataItem?.children?.length === 1 ? dataItem?.children?.[0] : dataItem;
  const isPoint = row?.awardCalculationType === AWARD_CALCULATION_TYPE_POINT;

  if (row?.score && row?.kpiSystemId) return <td className="k-table-td">-</td>;
  else if (row?.score === undefined) return <td className="k-table-td"></td>;

  return (
    <td className="k-table-td">
      <div className="k-d-flex k-justify-content-center k-gap-3">
        <div>
          <span className="text-muted">{!isPoint && `${row?.score}/${row?.overallMaxSystemScore}`}</span>
          <span>{!!isPoint && onlyDecimals(row?.superScore, 2) + "%"}</span>
        </div>
        <div>
          <span>{!!isPoint && `${row?.score}/${row?.overallMaxSystemScore}`}</span>
          <span>{!isPoint && onlyDecimals(row?.superScore, 2) + "%"}</span>
        </div>
      </div>
    </td>
  );
};

RatingSystemScoreColumn.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
};

export const RatingSystemSuperScoreColumn = (props) => {
  const { dataItem } = props;

  const row = dataItem?.children?.length === 1 ? dataItem?.children?.[0] : dataItem;
  const isPoint = row?.awardCalculationType === AWARD_CALCULATION_TYPE_POINT;

  if (row?.superScore && row?.kpiSystemId) return <td className="k-table-td">-</td>;
  else if (row?.superScore === undefined) return <td className="k-table-td"></td>;

  return (
    <td>
      <div className="k-d-flex k-justify-content-center">
        <div>
          <span>{!!isPoint && `${row?.score}/${row?.overallMaxSystemScore}`}</span>
          <span>{!isPoint && `${onlyDecimals(row?.superScore, 2)}%`}</span>
        </div>
      </div>
    </td>
  );
};

RatingSystemSuperScoreColumn.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
};

export const RatingSystemNameColumn = (props) => {
  const { t } = useTranslation();
  const { dataItem, field, setShowTooltip, onExpandChange } = props;
  const childRow = dataItem;
  const cellData = dataItem?.[field];
  const childrens = childRow?.children;

  const handleExpandClick = (event) => {
    onExpandChange(event, dataItem, props.level);
  };

  const renderNavLink = (row) => (
    <td>
      <NavLink
        to={!row?.kpiSystemId ? `/project/ratingSystem/${row?.id}` : `/project/kpiRatingSystem/${row?.id}`}
        className="column-link"
        onMouseEnter={
          !row.kpiSystemId && setShowTooltip
            ? (event) => setShowTooltip({ data: row, target: event.currentTarget })
            : null
        }
        onMouseLeave={setShowTooltip ? () => setShowTooltip(null) : null}
      >
        {row?.systemSource} {row?.name} {row?.systemVersion}
        {!row.kpiSystemId && row?.selectedAward?.fileId && (
          <img src={`/api/sustainability/award/file/${row?.selectedAward.fileId}/preview`} hidden alt="" />
        )}
      </NavLink>
    </td>
  );

  if (!cellData) {
    if (childrens?.length > 1) {
      return (
        <td>
          {!dataItem[EXPAND_FIELD] && (
            <Button onClick={handleExpandClick} size="small">
              {t("project.collapsedMultipleRecords")}
            </Button>
          )}
        </td>
      );
    } else if (childrens?.length === 1) {
      return renderNavLink(childrens[0]);
    }
    return <td></td>;
  }

  return renderNavLink(childRow);
};

RatingSystemNameColumn.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
  setShowTooltip: PropTypes.func,
  onExpandChange: PropTypes.func,
  level: PropTypes.array,
};

export const StatusColumn = (props) => {
  const { t } = useTranslation();
  const { dataItem, field } = props;
  const childRow = dataItem;
  const cellData = dataItem?.[field];
  const childrens = childRow?.children;

  const renderStatus = (row) => (
    <td className="k-table-td">
      <div className="k-d-flex k-justify-content-center">
        {cellData && <div className={`status-badge status-${cellData}`}>{t(`ratingSystem.status.${cellData}`)}</div>}
      </div>
    </td>
  );

  if (!cellData && childrens?.length === 1) {
    return renderStatus(childrens[0]);
  }

  return cellData ? renderStatus(childRow) : <td className="k-table-td"></td>;
};

StatusColumn.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
};

export const DateColumn = (props) => {
  const { dataItem, field } = props;
  const childRow = dataItem;
  const isProject = props?.level?.length === 1;
  const childrens = childRow?.children;

  const render = (row) => (
    <td className="k-table-td">
      {row?.[field] && (
        <div className="nowrap k-text-center">
          {row[field] ? moment.utc(row[field]).local().format(DATETIME_FORMAT_CLIENT) : ""}
        </div>
      )}
    </td>
  );

  if (childrens?.length === 1) {
    return render(childrens[0]);
  }

  return !isProject ? render(childRow) : <td className="k-table-td"></td>;
};

DateColumn.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.object,
  level: PropTypes.array,
};

export const ProjectsPager = (props) => {
  const { total } = useProjectsPageLogic();
  const { take } = useProjectsTableState();
  return <TreeListPager {...props} pageSizeValue={take} total={total} />;
};
