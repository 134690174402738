import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { getBreadcrumbPathParts } from "../../../helpers/dashboard";
import { mapKpiGroupings } from "../../../helpers/kpiRatingSystem";
import { getKPIValues, mapGroupings } from "../../../helpers/ratingSystem";
import { useDashboardSelectedGrouping } from "../../../hooks/project";
import { findRecursive, forEachRecursive } from "../../../utils";
import { Breadcrumb } from "../../Breadcrumb/Breadcrumb";
import { GoalCard } from "../../RatingSystem/GoalCard/GoalCard";
import "./GoalsTab.scss";

export const GoalsTab = ({ ratingSystem, isKpiSystem }) => {
  const { t } = useTranslation();
  const selectedGrouping = useDashboardSelectedGrouping();
  const ratingSystemGoals = ratingSystem?.ratingSystemGoals || [];
  const { score, superScore } = ratingSystem || {};
  const [kpis, setKpis] = useState([]);
  const [filteredGoals, setFilteredGoals] = useState([]);

  const pathParts = getBreadcrumbPathParts(selectedGrouping, t("dashboard.tabs.goals"));
  useEffect(() => {
    const grouping = isKpiSystem
      ? mapKpiGroupings(ratingSystem?.groupingElements || [])
      : mapGroupings(ratingSystem?.groupingElements || []);
    const kpiValues = getKPIValues(grouping);
    setKpis(kpiValues);
    const kpisByGrouping = [];
    const selectedId = selectedGrouping ? selectedGrouping.data.uuid : null;
    const groupingElement = findRecursive(grouping, (node) => node.uuid === selectedId);
    forEachRecursive(groupingElement?.children || [], (node) => {
      if (node.kpi) {
        kpisByGrouping.push(node.kpi.name);
      }
    });
    setFilteredGoals(
      ratingSystemGoals.filter((goal) => {
        if (pathParts.length === 1) {
          return true;
        }
        const kpi = kpiValues.find((item) => item.name === goal.kpiName);
        return kpi ? kpisByGrouping.indexOf(kpi.name) !== -1 : false;
      })
    );
  }, [ratingSystem, selectedGrouping]);

  return (
    <div className="goals-tab">
      <Breadcrumb pathParts={pathParts} />
      <div className="grid-blocks">
        {filteredGoals.map((item, index) => (
          <GoalCard key={getUUID()} card={item} readOnly={true} kpis={kpis} score={score} superScore={superScore} />
        ))}
      </div>
    </div>
  );
};

GoalsTab.propTypes = {
  ratingSystem: PropTypes.object,
  isKpiSystem: PropTypes.bool,
};
