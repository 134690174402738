import { DialogActions, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { VersionsTable } from "../VersionsTable/VersionsTable";
import "./VersionsDialog.scss";

export const VersionsDialog = ({ data, open, onClose, onAction, titleText, selectedVersion, loading }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={() => onClose()} className="versions-dialog">
      <DialogTitle>{titleText}</DialogTitle>
      <LoadingOverlay spinner active={loading} className="versions-dialog-loader">
        <DialogContent>
          <VersionsTable data={data} onAction={onAction} selectedVersion={selectedVersion} />
        </DialogContent>
      </LoadingOverlay>
      <DialogActions>
        <Button onClick={() => onClose()}>{t("main.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};
