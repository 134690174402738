import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { DATETIME_FORMAT_CLIENT } from "../../../constants/main";
import { CREATED, DUPLICATED, MIGRATED } from "../../../constants/sustainabilitySystem";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import "./SustainabilitySystemDetailDialog.scss";

export const SustainabilitySystemDetailDialog = ({ item, sourceSystemItem, onClose }) => {
  const { t } = useTranslation();
  return (
    <LoadingOverlay spinner className="system-detail-dialog auto-height">
      <div className="flex-row custom-dialog-header">
        <h2 className="flex-auto">
          {t("systemDetails.detailSystem")} {item?.systemSource} {item?.name} {item?.systemVersion}{" "}
        </h2>
        <IconButton className="close-button" size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="dialog-body">
        {item?.originType === CREATED && <h4 className="flex-auto"> {t("systemDetails.newlyCreated")} </h4>}
        {item?.originType === CREATED && (
          <h4 className="flex-auto">
            {" "}
            {t("systemDetails.createdBy")} {item?.creator}{" "}
          </h4>
        )}
        {item?.originType === DUPLICATED && (
          <h4 className="flex-auto">
            {" "}
            {t("systemDetails.duplicatedOf")} {sourceSystemItem?.systemSource} {sourceSystemItem?.name}{" "}
            {sourceSystemItem?.systemVersion}{" "}
          </h4>
        )}
        {item?.originType === MIGRATED && (
          <h4 className="flex-auto">
            {" "}
            {t("systemDetails.duplicatedOf")} {item?.systemSource} {item?.name} {item?.systemVersion}{" "}
            {t("systemDetails.fromTenant")} {item?.sourceTenantName}{" "}
          </h4>
        )}
        {(item?.originType === DUPLICATED || item?.originType === MIGRATED) && (
          <h4 className="flex-auto">
            {" "}
            {t("systemDetails.duplicatedBy")} {item?.creator}{" "}
          </h4>
        )}
        <h4 className="flex-auto">
          {" "}
          {t("systemDetails.at")} {moment(item.createdDateTime).format(DATETIME_FORMAT_CLIENT)}{" "}
        </h4>
      </div>
      <div className="buttons text-right">
        <Button onClick={onClose}>{t("main.close")}</Button>
      </div>
    </LoadingOverlay>
  );
};
