export const ProjectGroupsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="icon-project_groups" data-name="icon-project groups" transform="translate(1 1)">
      <path
        id="Path_45"
        data-name="Path 45"
        d="M0,0H30V30H0Z"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        opacity="0"
      />
      <g id="Group_115" data-name="Group 115" transform="translate(0 0.833)">
        <path
          id="Path_46"
          data-name="Path 46"
          d="M10.333,4h5l3.333,3.333H27a3.333,3.333,0,0,1,3.333,3.333V22.333A3.333,3.333,0,0,1,27,25.667H10.333A3.333,3.333,0,0,1,7,22.333v-15A3.333,3.333,0,0,1,10.333,4"
          transform="translate(-0.333 -4)"
          fill="none"
          stroke="#002965"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_47"
          data-name="Path 47"
          d="M26.333,23v3.333A3.333,3.333,0,0,1,23,29.667H6.333A3.333,3.333,0,0,1,3,26.333v-15A3.333,3.333,0,0,1,6.333,8H9.667"
          transform="translate(-3 -1.333)"
          fill="none"
          stroke="#002965"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </g>
  </svg>
);
