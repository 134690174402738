import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import React, { useEffect } from "react";
import { useWindowSize } from "../../hooks";
import { setDockResize, useDockResize } from "../../hooks/advancedTable";
import "./Dock.scss";

export const Dock = ({ show, onClose, options, content, hidden, children }) => {
  const windowSize = useWindowSize();
  const maxWidth = windowSize.width / 2;

  const config = { width: 420, minWidth: 420, ...(options || {}) };

  const resize = useDockResize();
  const resizeInProgress = undefined !== resize.startX;

  useEffect(() => {
    if (resize.width > maxWidth && maxWidth >= config.minWidth) {
      setDockResize({ width: maxWidth });
    }
  }, [maxWidth]);

  useEffect(() => {
    if (show) {
      setDockResize({ width: config.width });
    } else {
      setDockResize({ width: 0 });
    }
  }, [show]);

  const onResizeStart = (event) => {
    event.stopPropagation();
    setDockResize({ ...resize, startWidth: resize.width, startX: event.pageX });
  };

  const onResize = (event) => {
    event.stopPropagation();
    if (resizeInProgress) {
      const deltaX = event.pageX - resize.startX;
      const width = resize.startWidth - deltaX;
      setDockResize({
        ...resize,
        width: width < config.minWidth ? config.minWidth : width > maxWidth ? maxWidth : width,
      });
    }
  };

  const onResizeEnd = (event) => {
    event.stopPropagation();
    setDockResize({ width: resize.width });
  };

  const showOverlay = !!show && resizeInProgress;

  return !hidden ? (
    <div className={"dock" + (show ? " dock-open" : "")}>
      {showOverlay && <div className="pointer-move-area" onPointerMove={onResize} onPointerUp={onResizeEnd} />}
      <div className="wrapper-panel">{children}</div>
      {!!show && (
        <div className="dock-panel" style={{ minWidth: resize.width + "px" }}>
          <div className="resize-pin" onPointerDown={onResizeStart} />
          <IconButton className="close-button" size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <div className="scroll-area">{content}</div>
        </div>
      )}
    </div>
  ) : null;
};
