import "./ImageViewer.scss";
import React, { useEffect, useRef } from "react";

export const ImageViewer = ({ image, open, onClose }) => {
  const ingRef = useRef(null);

  useEffect(() => {

  }, [image, open]);

  return (<div className={'image-viewer' + (open ? ' open' : '')} onClick={onClose}>
    <img ref={ingRef} src={image} alt="" className="modal-content"/>
  </div>);
};
