import { Grid } from "@mui/material";
import React from "react";
import "./TopTopicsActions.scss";

export const TopTopicsActions = (data) => {
  return (
    <div className="topicsActionsList">
      <ul>
        {data?.topTopicsAndActions?.map((item, index) => (
          <li key={index}>
            <Grid container spacing={1}>
              <Grid xs={6} item>
                <span className="topic">&bull; {item.topic}</span>
              </Grid>
              <Grid xs={6} item>
                <span className="action">&bull; {item.action}</span>
              </Grid>
            </Grid>
          </li>
        ))}
      </ul>
    </div>
  );
};
