import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  getProjectGroupPaths,
  projectGroupHierarchySelectionSubject,
  setProjectGroupProjectLocationsPopover,
  useProjectGroupPathsResponse,
  useProjectGroupProjectLocationsPopover,
} from "../../../hooks/projectGroup";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import "./ProjectGroupProjectsLocationsPopover.scss";

export const ProjectGroupProjectsLocationsPopover = ({ projectGroupId }) => {
  const { t } = useTranslation();

  const response = useProjectGroupPathsResponse();
  const popover = useProjectGroupProjectLocationsPopover();
  const responseData = response.data || [];
  const popoverData = popover?.data;
  const { id } = popoverData || {};

  const data = [];
  responseData.forEach((item) => {
    const foundIndex = item.path.findIndex((group) => group.id === projectGroupId);
    if (foundIndex !== -1) {
      const path = [
        { id: 0, name: item.path[foundIndex].name },
        ...item.path
          .filter((group, index) => {
            return index > foundIndex;
          })
          .map((group) => ({ id: group.id, name: group.name })),
      ];
      data.push(path);
    }
  });

  useEffect(() => {
    if (projectGroupId && id) {
      getProjectGroupPaths(projectGroupId, id);
    }
  }, [projectGroupId, id]);

  const onClick = useCallback((path) => {
    const selection = [...path];
    selection.splice(0, 1);
    if (!selection.length) {
      selection.push({ id: 0, name: t("projectGroup.hierarchyBlock.allProjects") });
    }
    projectGroupHierarchySelectionSubject.next(selection.map((item) => ({ id: item.id, name: item.name })));
    setProjectGroupProjectLocationsPopover(null);
  }, []);

  return (
    <Popover
      className={"project-group-project-locations-popover"}
      open={!!popover}
      anchorEl={(popover || {}).target}
      onClose={() => setProjectGroupProjectLocationsPopover(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      disableRestoreFocus
    >
      <LoadingOverlay spinner active={response.loading} className="auto-height">
        {!!data.length &&
          data.map((item, index) => (
            <MenuItem key={index} onClick={() => onClick(item)}>
              {item.map((itemInner) => itemInner.name).join(" / ")}
            </MenuItem>
          ))}
      </LoadingOverlay>
    </Popover>
  );
};
