import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./IndicatorDataDialog.scss";

export const IndicatorDataDialogPAST = ({ onClose, onChange, row, readOnly, submitted }) => {
  const { t } = useTranslation();
  const data = row || {};
  const { UID } = data;
  const [standard, setStandard] = useState("");

  useEffect(() => {
    setStandard(data.evaluationStandard || "");
  }, [UID]);

  const onSave = () => {
    onChange({ UID, standard });
    onClose();
  };

  return (
    <div className="indicator-data-dialog auto-height">
      <div className="dialog-head">
        <h2>{t("grouping.evaluationStandard")}</h2>
      </div>
      <div className="dialog-body">
        {!!readOnly && (
          <div>
            {!!standard && <pre>{standard}</pre>}
            {!standard && <div className="text-center text-muted">{t("main.empty")}</div>}
          </div>
        )}
        {!readOnly && (
          <>
            <TextField
              error={!!submitted && !standard}
              fullWidth
              className="evaluation-standard"
              multiline
              value={standard}
              onChange={(event) => setStandard(event.target.value)}
            />
          </>
        )}
      </div>

      <div className="buttons text-right">
        {!!readOnly && <Button onClick={() => onClose()}>{t("main.close")}</Button>}
        {!readOnly && (
          <>
            <Button onClick={onClose}>{t("main.cancel")}</Button>
            <Button color="primary" onClick={onSave}>
              {t("main.save")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
