import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { v4 as getUUID } from "uuid";
import { RATING_SYSTEM } from "../../../constants/main";
import { addSVGToImages, hideSpinnerImage, show404Image } from "../../../helpers/richtext";
import { useUploadingFiles } from "../../../hooks/fileUpload";
import { uploadProjectPictureKey } from "../../../hooks/project";
import { ratingSystemRichTextImageUpload } from "../../../hooks/ratingSystem";
import { sustainabilitySystemRichTextImageUpload } from "../../../hooks/system";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { RichText } from "../../RichText/RichText";
import { RICH_TEXT_EDITOR_HEIGHT } from "../../RichText/constants";

const checkAttributes = (previousAttributes, nextAttributes) =>
  previousAttributes.systemId === nextAttributes.systemId &&
  previousAttributes.readOnly === nextAttributes.readOnly &&
  previousAttributes.values?.text === nextAttributes.values?.text;

const onImageLoad = (event) => {
  const target = event.detail.event.target;
  hideSpinnerImage(target);
};

const onImageError = (event) => {
  const target = event.detail.event.target;
  show404Image(target);
  hideSpinnerImage(target);
};

export const AdditionalFieldsTabContent = React.memo(
  ({ systemId, values, onChange, onLoading, readOnly, systemType }) => {
    const uploadingFiles = useUploadingFiles().filter((item) => item.key === uploadProjectPictureKey);

    const loading = !!uploadingFiles.length;

    const resolveResultUrl = (systemResultTypeUrl, systemResultIdUrl, id) => {
      if (!systemResultTypeUrl || systemResultTypeUrl === RATING_SYSTEM) {
        // picking rating system as default type
        return `/api/rating/system/${systemResultIdUrl}/richtext/image/${id}`;
      }
      return `/api/sustainability/system/${systemResultIdUrl}/richtext/image/${id}`;
    };

    const resolveUploadUrl = (systemTypeUploadUrl, systemIdUploadUrl) => {
      if (!systemTypeUploadUrl || systemTypeUploadUrl === RATING_SYSTEM) {
        return `/api/rating/system/${systemIdUploadUrl}/richtext/image`;
      }
      return `/api/sustainability/system/richtext/image`;
    };

    const getResultUrl = (id) => window.location.origin + resolveResultUrl(systemType, systemId, id);

    const richTextImageUpload = (systemTypeInner, systemIdInner, file) => {
      if (systemTypeInner === RATING_SYSTEM || !systemTypeInner) {
        return ratingSystemRichTextImageUpload(systemIdInner, file);
      }
      return sustainabilitySystemRichTextImageUpload(file);
    };

    useEffect(() => {
      window.removeEventListener("rich-text-image-onerror", onImageError);
      window.addEventListener("rich-text-image-onerror", onImageError);

      window.removeEventListener("rich-text-image-onload", onImageLoad);
      window.addEventListener("rich-text-image-onload", onImageLoad);
    }, [systemId]);

    const editorUid = useMemo(() => getUUID(), []);

    return (
      <LoadingOverlay spinner active={loading} className="additional-fields-tab-content auto-height">
        {!readOnly && (
          <RichText
            onChange={onChange}
            onLoading={onLoading}
            content={values?.text}
            uploadUrl={resolveUploadUrl(systemType, systemId)}
            uploadFunction={(file) => richTextImageUpload(systemType, systemId, file)}
            getResultUrl={getResultUrl}
            entityId={systemId}
            editorHeight={RICH_TEXT_EDITOR_HEIGHT}
            uid={editorUid}
          />
        )}

        {!!readOnly && !!values?.text && (
          <div className="rich-text-content" dangerouslySetInnerHTML={{ __html: addSVGToImages(values.text) }} />
        )}
      </LoadingOverlay>
    );
  },
  checkAttributes
);

AdditionalFieldsTabContent.propTypes = {
  systemId: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  systemType: PropTypes.string.isRequired,
};
