import React from 'react';
import './LoadingOverlay.scss';

export const Spinner = () => (
  <div className={'loading-overlay-spinner'}>
    <svg viewBox='25 25 50 50'>
      <circle
        cx='50'
        cy='50'
        r='20'
        fill='none'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
    </svg>
  </div>
);

export const LoadingOverlay = (
  {
    children,
    className,
    onClick,
    active,
    spinner,
    text
  }
) => {
  return (
    <div className={'loading-overlay ' + (active ? 'active ' : '') + (className || '')}>
      <div className={'loading-overlay-overlay'} onClick={onClick}>
        <div className={'loading-overlay-content'}>
          {spinner && (typeof spinner === 'boolean' ? <Spinner/> : spinner)}
          {text}
        </div>
      </div>
      {children}
    </div>
  )
};
