import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const LeadSelect = ({ control, disabled, projectPermissions, owner, projectAvailableUsers, isGroup }) => {
  const { t } = useTranslation();
  const usersByUUID = {};
  projectAvailableUsers.forEach((item) => {
    usersByUUID[item.uuid] = [item.firstName, item.lastName].join(" ");
  });
  const usersToSelect = useMemo(() => {
    return (
      projectPermissions
        .filter((item) => (isGroup ? item.admin : item.permissionOwner) && item.userUuid !== owner)
        .map((item) => {
          const user = usersByUUID[item.userUuid];
          return { ...item, user };
        }) || []
    );
  }, [projectPermissions, projectAvailableUsers]);

  return (
    <Controller
      control={control}
      name={isGroup ? "projectGroupLead" : "projectLead"}
      defaultValue={""}
      render={({ field, formState, fieldState }) => {
        return (
          <FormControl fullWidth error={fieldState.invalid} className="project-lead-select">
            <InputLabel htmlFor="projectLeadSelect">
              {isGroup ? t("projectGroups.projectGroupLeaderName") : t("project.projectLead")}
            </InputLabel>
            <Select
              fullWidth
              id="projectLeadSelect"
              displayEmpty
              defaultValue={""}
              label={t("project.projectLead")}
              {...field}
              disabled={disabled || (!usersToSelect.length && !field.value)}
              renderValue={(value) => usersByUUID[value]}
            >
              <MenuItem key={-1} value={""}>
                {t("main.unselected")}
              </MenuItem>
              {usersToSelect.map((item, index) => (
                <MenuItem key={index} value={item.userUuid}>
                  {item.user}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }}
    />
  );
};
