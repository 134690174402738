export const AdministrationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32.078">
    <g id="icon-administration" transform="translate(1 0.04)">
      <path
        id="Path_165"
        data-name="Path 165"
        d="M0,0H30V30H0Z"
        transform="translate(0 1)"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        opacity="0"
      />
      <g id="Group_118" data-name="Group 118" transform="translate(1.5 1)">
        <path
          id="Path_166"
          data-name="Path 166"
          d="M28.313,8.4A3.338,3.338,0,0,1,30,11.327V22.253a3.337,3.337,0,0,1-1.737,2.922L18.138,31.58a3.4,3.4,0,0,1-3.276,0L4.737,25.175A3.338,3.338,0,0,1,3,22.253V11.325A3.335,3.335,0,0,1,4.737,8.4l10.125-5.97a3.5,3.5,0,0,1,3.375,0L28.362,8.4h-.05Z"
          transform="translate(-3 -2)"
          fill="none"
          stroke="#002965"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_167"
          data-name="Path 167"
          d="M13.5,13.5,9,13.5A4.5,4.5,0,1,0,13.5,9,4.5,4.5,0,0,0,9,13.5"
          transform="translate(0 1.5)"
          fill="none"
          stroke="#002965"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </g>
  </svg>
);
