import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { FILE_UPLOAD_RESTRICTIONS, MAX_FILE_SIZE_MB } from "../../../constants/fileUpload";
import { MUI_CLEAR_INPUT_REASON } from "../../../constants/main";
import { INDICATOR_VALUE_TYPE_RANGE, INDICATOR_VALUE_TYPE_TEXT } from "../../../constants/sustainabilitySystem";
import {
  getFileSizeErrors,
  getRestrictedFileExtension,
  getRestrictedFileSize,
  validateFileRestrictions,
} from "../../../helpers/fileUpload";
import { calculateUniversalScore } from "../../../helpers/ratingSystem";
import { resetUploadingFiles, useUploadingFiles } from "../../../hooks/fileUpload";
import { getProjectFileUrl, getProjectFiles, useProjectFilesResponse } from "../../../hooks/project";
import {
  addRatingReasonFile,
  ratingReasonFilesSubject,
  removeRatingReasonFile,
  setRatingReasonFiles,
  uploadRatingReasonFile,
  uploadRatingReasonFileKey,
  useRatingReasonFiles,
} from "../../../hooks/ratingIndicatorFiles";
import { ratingSystemSubject } from "../../../hooks/ratingSystem";
import { showError } from "../../../hooks/toast";
import { useEvent } from "../../../hooks/utils/useEvent";
import { FilesTable } from "../../FilesTable/FilesTable";
import { FormController } from "../../FormController/FormController";
import { IndicatorValueInput } from "../../IndicatorValueInput/IndicatorValueInput";
import { KPIControl } from "../../KPIControl/KPIControl";
import { LinkDialog } from "../../LinkDialog/LinkDialog";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { SelectFileDialog } from "../../SelectFileDialog/SelectFileDialog";
import { EvaluationReason } from "../EvaluationReason/EvaluationReason";

import { FormControl } from "@mui/material";
import { loadMessages } from "@progress/kendo-react-intl";
import i18next from "i18next";
import { addBreakLines, adjustRGBFormat, removeExtraBreakLines } from "../../../helpers/richtext";
import kendoDeMessages from "./../../../locales/kendo-de.json";
import kendoEnMessages from "./../../../locales/kendo-en.json";

loadMessages(kendoEnMessages, "en");
loadMessages(kendoDeMessages, "de");

const CUSTOM_VALUE_TAG = "CURRENT";

export const CurrentBlock = ({
  onChange,
  row,
  readOnly,
  projectId,
  ratingSystemId,
  permissions,
  restrictions = FILE_UPLOAD_RESTRICTIONS,
}) => {
  const { t } = useTranslation();
  const data = row || {};
  const { UID } = data;
  const [reasonState, setReasonState] = useState("");
  const [links, setLinks] = useState([]);
  const [kpi, setKpi] = useState(null);
  const files = useRatingReasonFiles();
  const [popoverTarget, setPopoverTarget] = useState(null);
  const [selectFileOpen, setSelectFileOpen] = useState(null);
  const [linkDialog, setLinkDialog] = useState(null);
  const [fileValidationError, setFileValidationError] = useState(false);
  const uploadInputRef = useRef(null);
  const uploadingFiles = useUploadingFiles().filter((item) => item.key === uploadRatingReasonFileKey);
  const projectFilesResponse = useProjectFilesResponse();
  const unit = data.unit;
  const valueType = data.valueType || INDICATOR_VALUE_TYPE_TEXT;
  const allowCustomValue = !!data.allowCustomValue;
  const [valueRangeList, setValueRangeList] = useState(data.valueRangeList || []);
  const evaluationStandard = data.evaluationStandard || "";
  const evaluationStandardLinks = data.evaluationStandardLinks || [];
  const evaluationStandardFiles = data.evaluationStandardFiles || [];
  const scoreInputRef = useRef(null);
  const accessRestricted = ratingSystemSubject.getValue()?.data?.accessRestricted;
  const messages = i18next.language === "de" ? kendoDeMessages : kendoEnMessages;

  const isViewer = !accessRestricted || !!permissions.permissionReader;

  useEffect(() => {
    setValueRangeList(data?.valueRangeList);
  }, [data?.valueRangeList]);

  useEffect(() => {
    const {
      evaluationValue,
      evaluationSystemScore,
      kpi: kpiInner,
      customCurrentValue,
      evaluationScoringValue,
    } = data || {};
    reset({
      evaluationValue: evaluationValue || "",
      customCurrentValue: customCurrentValue || "",
      evaluationSystemScore: evaluationSystemScore || (evaluationSystemScore === 0 ? 0 : ""),
      evaluationScoringValue: evaluationScoringValue || "",
    });
    setRatingReasonFiles(data.evaluationFiles || []);
    setLinks(data.evaluationLinks || []);
    setKpi(kpiInner);
    resetUploadingFiles();
    setReasonState(data.reason ?? "");
  }, [UID]);

  const { control, getValues, reset, setValue } = useForm({
    defaultValues: {
      evaluationValue: "",
      customCurrentValue: "",
      evaluationSystemScore: "",
      evaluationScoringValue: "",
    },
  });

  useEffect(() => {
    if (!readOnly && row && scoreInputRef?.current) {
      scoreInputRef.current.focus();
    }
  }, [readOnly, row, scoreInputRef]);

  const saveChanges = useEvent((updatedLinks, updatedKpi, updatedValueRangeList) => {
    const { evaluationValue, evaluationSystemScore, customCurrentValue, evaluationScoringValue } = getValues();
    const { maxSystemScore } = row;
    let value = +evaluationSystemScore;

    if (evaluationSystemScore < -maxSystemScore) {
      value = -maxSystemScore;
      setValue("evaluationSystemScore", value);
    }
    if (+evaluationSystemScore > +maxSystemScore) {
      value = +maxSystemScore;
      setValue("evaluationSystemScore", value);
    }

    onChange({
      UID,
      fields: {
        reason: reasonState,
        evaluationValue,
        evaluationScoringValue,
        customCurrentValue,
        evaluationSystemScore: evaluationSystemScore === "" ? null : value,
        universalScore: calculateUniversalScore(value, maxSystemScore),
        kpi: updatedKpi || kpi,
      },
      links: updatedLinks || links,
      files: ratingReasonFilesSubject.getValue(),
      valueRangeList: updatedValueRangeList || valueRangeList,
    });
  });

  const saveChangesReason = (reasonEditorValue) => {
    let reason = reasonState;
    if (reasonEditorValue) {
      const { event } = reasonEditorValue;
      let html = event?.html ?? "";

      html = adjustRGBFormat(html);
      html = addBreakLines(html);
      html = removeExtraBreakLines(html);

      reason = html;
      setReasonState(reason);
    }
    saveChanges();
  };

  const changeLink = (dataChangeLink) => {
    if (linkDialog === true) {
      const updated = [...links, dataChangeLink];
      setLinks(updated);
      saveChanges(updated);
    } else {
      const updated = [...links];
      updated[linkDialog] = dataChangeLink;
      setLinks(updated);
      saveChanges(updated);
    }
    setLinkDialog(null);
  };

  const deleteLink = (index) => {
    const updated = [...links];
    updated.splice(index, 1);
    setLinks(updated);
    saveChanges(updated);
  };

  const onFileSelect = (event) => {
    const errors = getFileSizeErrors(event, MAX_FILE_SIZE_MB);
    if (!errors) {
      [...event.target.files].forEach((file) => {
        const validationError = validateFileRestrictions(file, restrictions);
        setFileValidationError(!!validationError);
        if (!validationError) {
          uploadRatingReasonFile(projectId, file)
            .then((response) => {
              addRatingReasonFile({ fileId: response.id, name: response.originalName });
              saveChanges();
            })
            .catch((error) => {
              if (error && error.type !== "abort") {
                if (error.status === 409) {
                  showError(t("file.error.409"));
                } else {
                  showError(t("error.500"));
                }
              }
            });
        } else {
          showError(messages[`upload.${validationError}`]);
        }
      });
      uploadInputRef.current.value = "";
    } else {
      let errorMessage = "";
      errors.forEach((error) => {
        errorMessage +=
          t("file.tooLarge", {
            fileName: error.fileName,
            fileSize: error.fileSize,
          }) + "\n";
      });
      errorMessage += t("file.maxSize", { maxFileSize: MAX_FILE_SIZE_MB });
      showError(errorMessage);
    }
  };

  const removeFile = (file) => {
    removeRatingReasonFile(filesProcessed.findIndex((item) => item?.fileId === file?.fileId));
    saveChanges();
  };

  const oneProjectFileSelect = (value) => {
    addRatingReasonFile({ fileId: value.id, name: value.name, userTags: value.userTags || [] });
    saveChanges();
  };

  const onKpiChange = ({ value, estimation }) => {
    const updated = { ...kpi, currentValue: value, estimation };
    setKpi(updated);
    saveChanges(null, updated);
  };

  const postFilesLoading = !!uploadingFiles.length;
  const filesProcessed = (files || []).map((item) => ({
    ...item,
    editable: true,
    userTags: item.userTags || [],
  }));

  const availableFiles = (projectFilesResponse.data || []).filter((file) => {
    return !filesProcessed.find((item) => item.fileId === file.id);
  });

  const isRange = valueType === INDICATOR_VALUE_TYPE_RANGE;

  return (
    <LoadingOverlay spinner className="rating-reason-dialog auto-height">
      {!!kpi && (
        <div className="padding-bottom">
          <h4 className="header no-margin">{t("ratingSystem.columnGroup.kpi")}</h4>
          <KPIControl
            kpiUnit={kpi.unit}
            kpiName={kpi.name}
            value={kpi.currentValue}
            estimation={kpi.estimation}
            onChange={onKpiChange}
            readOnly={readOnly}
            linkedGeneralAttributeName={kpi?.linkedGeneralAttributeName}
          />
        </div>
      )}
      {!isRange && (
        <div className="small-padding-bottom">
          <FormController
            control={control}
            name="evaluationSystemScore"
            label={t("ratingSystem.reason.evaluationSystemScore")}
            render={({ field, fieldState, label }) => (
              <>
                <TextField
                  onWheel={(event) => event.preventDefault()}
                  inputRef={scoreInputRef}
                  error={fieldState.invalid}
                  type="number"
                  fullWidth
                  disabled={readOnly || !data.maxSystemScore}
                  inputProps={{ step: 0.0001, min: -data.maxSystemScore, max: data.maxSystemScore }}
                  label={label + " *"}
                  {...field}
                  onBlur={() => saveChanges()}
                />
                <div className="chars-left">0 - {data.maxSystemScore}</div>
              </>
            )}
          />
        </div>
      )}
      <div className="small-padding-bottom">
        {isRange && data.visibleOnTable && (
          <IndicatorValueInput
            value={getValues("evaluationValue")}
            valueType={valueType}
            enableCustomValueOption={allowCustomValue}
            customValueTag={CUSTOM_VALUE_TAG}
            maxCustomValue={data?.maxSystemScore}
            valueRangeList={valueRangeList}
            unit={unit}
            control={control}
            disabled={readOnly}
            name="evaluationValue"
            title={isRange ? t("ratingSystem.reason.evaluationSystemScore") : t("ratingSystem.reason.evaluationValue")}
            onBlur={(event) => {
              const value = event.target.value;
              const option = (valueRangeList || []).find((item) => item.value === value);
              if (value !== "CUSTOM" && option && option.systemScore) {
                setValue("evaluationSystemScore", option.systemScore);
              }
              saveChanges();
            }}
            onSelect={(value, systemScore, isCustom = false) => {
              if (systemScore != null) {
                setValue("evaluationSystemScore", systemScore);
              } else {
                setValue("evaluationSystemScore", "");
              }

              if (isCustom) {
                const updated = [
                  ...valueRangeList.filter((item) => !item?.custom || item?.customValueTag !== CUSTOM_VALUE_TAG),
                  { value, systemScore, custom: true, customValueTag: CUSTOM_VALUE_TAG },
                ];
                setValueRangeList(updated);
                saveChanges(null, null, updated);
              }
            }}
            onInputChange={(event, newInputValue, reasonInputChange) => {
              if (reasonInputChange === MUI_CLEAR_INPUT_REASON) {
                setValue("evaluationSystemScore", "");
              }
            }}
          />
        )}
      </div>
      {data.visibleOnTable && (
        <div className="small-padding-bottom">
          <FormController
            control={control}
            name="evaluationScoringValue"
            label={t("ratingSystem.reason.evaluationValue")}
            render={({ field, fieldState, label }) => (
              <FormControl error={fieldState.invalid} fullWidth>
                <TextField
                  {...field}
                  error={fieldState.invalid}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  label={label}
                  disabled={readOnly}
                  onBlur={() => saveChanges()}
                />
                {!!unit && !!data.visibleOnTable && <div className="value-unit">{unit}</div>}
              </FormControl>
            )}
          />
        </div>
      )}
      <>
        {!!evaluationStandard && (
          <>
            <h4 className="header">{t("grouping.evaluationStandard")}</h4>
            <pre>{evaluationStandard}</pre>
          </>
        )}
        {!!isViewer && !!evaluationStandardLinks.length && (
          <>
            <h4 className="links-header">{t("grouping.links")}</h4>
            <div className="links chips">
              {evaluationStandardLinks.map((item) => (
                <Chip
                  key={getUUID()}
                  clickable={true}
                  onClick={() => {
                    window.open(item.value);
                  }}
                  label={item.title || item.value}
                />
              ))}
            </div>
          </>
        )}
        {!!isViewer && !!evaluationStandardFiles.length && (
          <>
            <h4 className="links-header">{t("grouping.files")}</h4>
            <div className="links chips">
              {evaluationStandardFiles.map((item) => (
                <Chip
                  key={getUUID()}
                  clickable={true}
                  onClick={() => {
                    window.open(`/api/sustainability/indicator/file/${item.fileId}/preview`);
                  }}
                  label={item.name}
                />
              ))}
            </div>
          </>
        )}
      </>
      {!!readOnly && (
        <>
          {!!data.reason && (
            <>
              <h4 className="header">{t("ratingSystem.reason.title")}</h4>
              <div className="rich-text-content" dangerouslySetInnerHTML={{ __html: data.reason }} />
            </>
          )}
          {!!isViewer && !!links.length && (
            <>
              <h4 className="links-header">{t("grouping.links")}</h4>
              <div className="links chips">
                {links.map((item) => (
                  <Chip
                    key={getUUID()}
                    clickable={true}
                    onClick={() => {
                      window.open(item.value);
                    }}
                    label={item.title || item.value}
                  />
                ))}
              </div>
            </>
          )}
          {!!isViewer && !!files.length && (
            <>
              <h4 className="links-header">{t("grouping.files")}</h4>
              <div className="links">
                <FilesTable
                  noSearch
                  readOnly
                  files={files || []}
                  getFilePath={(item) => getProjectFileUrl(projectId, item.fileId)}
                />
              </div>
            </>
          )}
        </>
      )}

      {!readOnly && (
        <>
          <h4 className="header">{t("ratingSystem.reason.title")}</h4>
          <EvaluationReason value={data.reason} onBlur={saveChangesReason} ratingSystemId={ratingSystemId} />
          {!!isViewer && (
            <>
              <h4 className="links-header">
                {t("grouping.links")}{" "}
                <IconButton color="primary" size="small" className="add-button" onClick={() => setLinkDialog(true)}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </h4>
              {!links.length && <div className="empty text-center">{t("main.empty")}</div>}
              {!!links.length && (
                <div className="links chips">
                  {links.map((item, index) => (
                    <Chip
                      key={getUUID()}
                      clickable
                      label={item.title || item.value}
                      onDelete={() => deleteLink(index)}
                      onClick={() => setLinkDialog(index)}
                    />
                  ))}
                </div>
              )}
              <h4 className="links-header">
                {t("grouping.files")}
                {permissions.permissionDocumentUpload && (
                  <IconButton
                    color="primary"
                    size="small"
                    className="add-button"
                    onClick={(event) => setPopoverTarget(event.target)}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
              </h4>
              {fileValidationError && (
                <div className="file-upload-restrictions">
                  <div>
                    <small>
                      {t("fileUpload.allowedExtensions")} {getRestrictedFileExtension(FILE_UPLOAD_RESTRICTIONS)}
                    </small>
                  </div>
                  <div>
                    <small>
                      {t("fileUpload.maximumFileSize")} {getRestrictedFileSize(FILE_UPLOAD_RESTRICTIONS)}
                    </small>
                  </div>
                </div>
              )}
              <input ref={uploadInputRef} type="file" color="primary" onChange={onFileSelect} hidden multiple />
              {!filesProcessed.length && <div className="empty text-center">{t("main.empty")}</div>}
              {(!!filesProcessed.length || postFilesLoading) && (
                <FilesTable
                  noSearch
                  tagsReadOnly
                  files={filesProcessed}
                  uploadingFiles={uploadingFiles}
                  deleteFile={(item) => removeFile(item)}
                  updateFiles={onChange}
                  getFilePath={(item) => getProjectFileUrl(projectId, item.fileId)}
                />
              )}
            </>
          )}
        </>
      )}

      <Popover
        open={!!popoverTarget}
        anchorEl={popoverTarget}
        onClose={() => setPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setPopoverTarget(null);
            uploadInputRef?.current && uploadInputRef?.current.click();
          }}
        >
          {t("ratingSystem.reason.addLocalFile")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPopoverTarget(null);
            setSelectFileOpen(true);
            getProjectFiles(projectId);
          }}
        >
          {t("ratingSystem.reason.addProjectFile")}
        </MenuItem>
      </Popover>

      <SelectFileDialog
        open={selectFileOpen}
        files={availableFiles}
        onClose={() => setSelectFileOpen(false)}
        isLoading={projectFilesResponse.loading}
        getFilePath={(item) => `/api/project/${projectId}/file/${item.fileId}`}
        onSelect={oneProjectFileSelect}
      />

      <LinkDialog
        key={linkDialog}
        open={linkDialog !== null}
        onClose={() => setLinkDialog(null)}
        titleText={linkDialog === true ? t("grouping.addLink") : t("grouping.editLink")}
        data={linkDialog !== null ? links[linkDialog] : null}
        onSave={changeLink}
      />
    </LoadingOverlay>
  );
};
