import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import "./TopTopicsActionsDialog.scss";

export const TopTopicsActionsDialog = ({ onClose, row, readOnly, onChange }) => {
  const { t } = useTranslation();
  const [deleteTopicActionIndex, setDeleteTopicActionIndex] = useState(null);
  const [topTopicsAndActions, setTopicsActions] = useState([]);
  const [updatedIndex, setUpdatedIndex] = useState(0);

  useEffect(() => {
    setTopicsActions(row.topTopicsAndActions || []);
  }, [row]);

  const deleteHandler = () => {
    const updated = [...topTopicsAndActions];
    updated.splice(deleteTopicActionIndex, 1);
    setTopicsActions(updated);
    setUpdatedIndex(updatedIndex + 1);
    setDeleteTopicActionIndex(null);
  };

  const addTopicAction = () => {
    setTopicsActions([...topTopicsAndActions, { topic: "", action: "" }]);
  };

  const onTopicActionChange = (newValue, index) => {
    const updated = [...topTopicsAndActions];
    updated[index] = newValue;
    setTopicsActions(updated);
  };

  const removeEmpties = (topicsActionsArray) => {
    const result = [];
    topicsActionsArray.forEach((m) => {
      if ((!!m.topic && !!m.action) || m.topic !== "" || m.action !== "") result.push(m);
    });
    return result;
  };

  const saveChanges = () => {
    row.topTopicsAndActions = removeEmpties(topTopicsAndActions);
    onChange(row, topTopicsAndActions);
    onClose();
  };

  return (
    <>
      <h3>{t("ratingSystem.columnGroup.topThemenActionen")}</h3>
      <div className="topTopicsActionsDialog" key={updatedIndex}>
        {topTopicsAndActions.map((item, index) => (
          <Grid container spacing={1} key={index}>
            <Grid xs={6} item>
              <TextField
                fullWidth
                defaultValue={item.topic}
                label={t("ratingSystem.columnGroup.topic")}
                disabled={readOnly}
                onBlur={(event) =>
                  onTopicActionChange(
                    {
                      topic: event.target.value,
                      action: item.action,
                    },
                    index
                  )
                }
                multiline
              />
            </Grid>
            <Grid xs={readOnly ? 6 : 5} item>
              <TextField
                fullWidth
                defaultValue={item.action}
                label={t("ratingSystem.columnGroup.action")}
                disabled={readOnly}
                onBlur={(event) => onTopicActionChange({ topic: item.topic, action: event.target.value }, index)}
                multiline
              />
            </Grid>
            {!readOnly && (
              <Grid xs={1} item>
                <IconButton className="deleteTopicAction" size="small" onClick={() => setDeleteTopicActionIndex(index)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
        {!readOnly && (
          <Button onClick={() => addTopicAction()}>
            <span className="value-button">
              <AddIcon /> {t("ratingSystem.addValue")}
            </span>
          </Button>
        )}
        <div className="buttons text-right">
          <Button className="close-goal-dialog" onClick={() => onClose()}>
            {t("main.close")}
          </Button>
          {!readOnly && (
            <Button color="primary" className="save-topics-action" onClick={saveChanges}>
              {t("main.save")}
            </Button>
          )}
        </div>
      </div>
      <ConfirmationDialog
        open={!!deleteTopicActionIndex || deleteTopicActionIndex === 0} // the 1st index (0) wasnt triggering the dialog bcz !!0 was returning false
        onClose={() => setDeleteTopicActionIndex(null)}
        onConfirm={deleteHandler}
        titleText={t("actions.actionDeleteConfirmationTitle")}
        bodyText={t("actions.actionDeleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />
    </>
  );
};
