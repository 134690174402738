export const canUserEditProjectGroup = (user, projectGroup) => {    
    if (!user || !projectGroup) {
        return false;
    }
    let isOwner = user.uuid === projectGroup?.owner;
    let isSuperAdmin = user.superAdmin;
    let isPartOfTheGroup = projectGroup.projectGroupPermissions?.some(f => user.uuid === f.userUuid);
    return isSuperAdmin || isOwner || isPartOfTheGroup;
}

export const canUserEditProjectGroupPermissions = (user, projectGroup) => {
    if (!user || !projectGroup) {
        return false;
    }

    let isOwner = user.uuid === projectGroup?.owner;
    let isSuperAdmin = user.superAdmin;
    let isGroupAdmin = projectGroup?.projectGroupPermissions?.some(f => user.uuid === f.userUuid && f.admin);
    return isSuperAdmin || isOwner || isGroupAdmin;
}