import SearchIcon from "@mui/icons-material/Search";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { DateColumnTemplate } from "../../components/ColumnTemplate/DateColumnTemplate";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { ProjectGroupDialog } from "../../components/ProjectGroup/ProjectGroupDialog/ProjectGroupDialog";
import { ProjectGroupActionsColumnTemplate } from "../../components/ProjectGroup/ProjectGroupTemplates";
import { SimpleTableWithSort } from "../../components/SimpleTableWithSort/SimpleTableWithSort";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../constants/main";
import { PERMISSION_PROJECT_GROUP_EDIT } from "../../constants/permissions";
import { hasPermission } from "../../helpers/permission";
import { checkPopState } from "../../hooks";
import { showDialog } from "../../hooks/dialog";
import {
  deleteProjectGroup,
  resetProjectGroupsPageState,
  searchProjectGroups,
  useProjectGroupDeleteResponse,
  useProjectGroupsPageLogic,
  useProjectGroupsPageResponse,
} from "../../hooks/projectGroup";
import { showInfo } from "../../hooks/toast";
import { useTimeout } from "../../hooks/utils/useTimeout";
import { PlusSVG } from "../../svg";
import { processMessage } from "../../utils";

export const ProjectGroupsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const response = useProjectGroupsPageResponse();
  const [processed, setProcessed] = useState([]);
  const [total, setTotal] = useState(0);

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const deleteResponse = useProjectGroupDeleteResponse();

  const {
    searchValue,
    setSearchValue,
    filterValue,
    setFilterValue,
    sortValue,
    setSortValue,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    resetState,
  } = useProjectGroupsPageLogic();
  const [, setSearchTimeout] = useTimeout();
  const [init, setInit] = useState(false);
  const [popoverTarget, setPopoverTarget] = useState(null);

  useEffect(() => {
    if (!response.loading && response.data) {
      const responseData = response.data || {};
      const items = responseData.content || [];
      const totalValue = (responseData.meta || {}).total || 0;
      setProcessed(items);
      setTotal(totalValue);
    }
  }, [response.loading]);

  const load = () =>
    searchProjectGroups({
      page: page + 1,
      itemsPerPage: rowsPerPage,
      sort: sortValue.field ? (sortValue.desc ? "-" : "") + sortValue.field : undefined,
      name: searchValue,
    }).catch(console.error);

  useEffect(() => {
    if (!checkPopState()) {
      resetProjectGroupsPageState();
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      load();
    }
  }, [init, searchValue, sortValue.field, sortValue.desc, page, rowsPerPage, filterValue]);

  const onSort = (field) =>
    setSortValue({
      field: field !== sortValue.field || !sortValue.desc ? field : null,
      desc: sortValue.field === field ? !sortValue.desc : false,
    });

  const search = (value) => {
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(value || "").trim());
      }, 500)
    );
  };

  const afterChange = () => (page === 0 ? load() : setPage(0));

  const deleteHandler = () => {
    deleteProjectGroup(deleteConfirmation.props.id).then(() => {
      showInfo(t("projectGroups.deletedMessage"));
      afterChange();
    }, console.error);
    setDeleteConfirmation(null);
  };

  const pagination = {
    labelRowsPerPage: t("projectGroups.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: total,
    page: page,
    show: total > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pagePagination) => setPage(pagePagination),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const columns = useMemo(() => {
    return [
      {
        field: "name",
        headerClassName: "name-column",
        headerText: t("projectGroups.name"),
        sortable: true,
        template: (props, field) => {
          return (
            <div className="flex-row name-column-template">
              <NavLink to={"/projectGroup/analyze/" + props.id} className="column-link flex-auto">
                {props[field]}
              </NavLink>
            </div>
          );
        },
      },
      {
        field: "description",
        headerClassName: "name-description",
        headerText: t("projectGroups.description"),
      },
      {
        field: "modifiedDateTime",
        className: "text-right",
        headerClassName: "text-right",
        headerText: t("projectGroups.modifiedDateTime"),
        template: DateColumnTemplate,
      },
      {
        className: "action-cell",
        headerClassName: "action-header-cell",
        headerText: t("main.actions"),
        template: (props) => ProjectGroupActionsColumnTemplate({ props, setPopoverTarget, t }),
      },
    ];
  }, []);

  const showAddGroupModal = () => {
    showDialog({
      className: "medium",
      closeOnClickOutside: false,
      getContent: (onClose) => (
        <ProjectGroupDialog
          data={null}
          onClose={onClose}
          onChange={(data) => {
            onClose();
            navigate("/projectGroup/" + data.id);
          }}
          parentProjectGroupId={null}
        />
      ),
    });
  };

  return (
    <div className="project-groups-page page-with-table">
      <div className="page-header large flex-row">
        <div className="flex-col title-box no-margin">
          <h1>{t("projectGroups.title")}</h1>
        </div>
        <div className="flex-col search-box no-margin">
          <SearchIcon />
          <input
            type="text"
            key={resetState}
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            placeholder={t("main.search")}
            onChange={(event) => search(event.target.value)}
          />
        </div>
      </div>

      <div className="page-layout">
        <LoadingOverlay spinner className="table-loading" active={response.loading || deleteResponse.loading}>
          <SimpleTableWithSort
            className="repaint"
            data={processed}
            childrenRecordsName="ratingSystems"
            pagination={pagination}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            sortValue={sortValue}
            onSort={onSort}
            columns={columns}
          />
        </LoadingOverlay>
      </div>

      {hasPermission(PERMISSION_PROJECT_GROUP_EDIT) && (
        <div className="fabs">
          <Fab type="button" color="primary" onClick={showAddGroupModal}>
            <PlusSVG className="fab-svg" />
          </Fab>
        </div>
      )}

      <Popover
        open={!!popoverTarget}
        anchorEl={(popoverTarget || {}).target}
        onClose={() => setPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            setDeleteConfirmation(popoverTarget);
            setPopoverTarget(null);
          }}
        >
          {t("main.delete")}
        </MenuItem>
      </Popover>

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={t("projectGroups.deleteConfirmationTitle")}
        bodyText={t("projectGroups.deleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />
    </div>
  );
};
