import CancelIcon from "@mui/icons-material/Cancel";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cancelUploadingFile } from "../../hooks/fileUpload";
import { projectFileUploadedSubject, useUploadedProjectFile } from "../../hooks/project";
import { usePagination } from "../../hooks/utils/usePagination";
import { processMessage } from "../../utils";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import "./FilesTable.scss";

const MIN_PAGE_SIZE = 10;

export const FilesTable = ({
  files,
  uploadingFiles,
  readOnly,
  deleteFile,
  onClick,
  filesPath,
  getFilePath,
  noSearch,
  ignoreEditable,
}) => {
  const { t } = useTranslation();
  const items = [...files];
  const { page, rowsPerPage, setPage, setRowsPerPage } = usePagination(0, MIN_PAGE_SIZE);
  const [searchText, setSearchText] = useState("");
  const [deleteFileConfirmation, setDeleteFileConfirmation] = useState(null);
  const uploadedProjectFile = useUploadedProjectFile();
  const processedSearchText = searchText.trim().toLowerCase();
  const processed = searchText
    ? items.filter((item) => {
        const nameMatch =
          String(item.name || "")
            .toLowerCase()
            .indexOf(processedSearchText) !== -1;
        if (nameMatch) {
          return true;
        }
        return (
          !!(item.userTags || []).find((tag) => tag.toLowerCase().indexOf(processedSearchText) !== -1) ||
          !!(item.generatedTags || []).find((tag) => tag.toLowerCase().indexOf(processedSearchText) !== -1)
        );
      })
    : items;
  const limited = rowsPerPage < processed.length ? [...processed].splice(page * rowsPerPage, rowsPerPage) : processed;

  useEffect(() => {
    if (!limited.length && processed.length && page > 0) {
      setPage(page - 1);
    }
  }, [limited.length, processed.length, page]);

  useEffect(() => {
    if (uploadedProjectFile) {
      setPage(Math.floor(processed.length / rowsPerPage));
      projectFileUploadedSubject.next(null);
    }
  }, [uploadedProjectFile]);

  const handleFileDelete = () => {
    deleteFile(deleteFileConfirmation.item);
    setDeleteFileConfirmation(null);
  };

  const pagination = {
    labelRowsPerPage: t("pagination.filesPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: [MIN_PAGE_SIZE, 25, 50],
    count: processed.length,
    page: page,
    onPageChange: (event, pageInner) => setPage(pageInner),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  return (
    <div className="files-table">
      {!noSearch && (
        <div className="files-search">
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            defaultValue={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            inputProps={{ placeholder: t("main.search") }}
          />
        </div>
      )}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("files.columnFile")}</TableCell>
            <TableCell className="actions-cell" />
          </TableRow>
        </TableHead>
        <TableBody>
          {limited.map((item, index) => {
            const filePath = getFilePath ? getFilePath(item) : filesPath ? filesPath + item.id : null;
            return (
              <TableRow key={index + "_" + item.id} className={index % 2 ? "even" : "odd"}>
                <TableCell>
                  {!!filePath && (
                    <a
                      href={filePath}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(event) => {
                        if (onClick) {
                          event.preventDefault();
                          onClick(item);
                        }
                      }}
                    >
                      {item.name}
                    </a>
                  )}
                  {!filePath && item.name}
                </TableCell>
                <TableCell className="actions-cell">
                  {!readOnly && (!!item.editable || ignoreEditable) && (
                    <IconButton size="small" onClick={() => setDeleteFileConfirmation({ item, index })}>
                      <DeleteOutlinedIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
          {(uploadingFiles || []).map((item, index) => (
            <TableRow key={index + "_upload"} className={(limited.length + index) % 2 ? "even" : "odd"}>
              <TableCell>{item.file.name}</TableCell>
              <TableCell>
                {item.progress === 1 ? (
                  <LinearProgress />
                ) : (
                  <LinearProgress variant="buffer" value={0} valueBuffer={item.progress * 100} />
                )}
              </TableCell>
              <TableCell className="actions-cell">
                <IconButton color="secondary" size="small" onClick={() => cancelUploadingFile(item.file)}>
                  <CancelIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {!!pagination && processed.length > MIN_PAGE_SIZE && <TablePagination component="div" {...pagination} />}

      <ConfirmationDialog
        bodyText={t("files.removeConfirmationText")}
        titleText={t("files.deleteConfirmationTitle")}
        color="secondary"
        onClose={() => setDeleteFileConfirmation(null)}
        open={!!deleteFileConfirmation}
        confirmText={t("main.delete")}
        onConfirm={handleFileDelete}
      />
    </div>
  );
};
