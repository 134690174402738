import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { KPI_TYPE } from "../../../constants/ratingSystem";
import { getKPIValues } from "../../../helpers/ratingSystem";
import { getBenchmark, resetBenchmarkResponse, useBenchmarkResponse } from "../../../hooks/benchmark";
import { useEvent } from "../../../hooks/utils/useEvent";
import { GoalElement } from "../../../models/GoalElement";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { GoalCard } from "../GoalCard/GoalCard";
import { BenchmarksDialog } from "./BenchmarksDialog/BenchmarksDialog";
import { GoalsDialog } from "./GoalsDialog/GoalsDialog";
import "./RatingSystemGoals.scss";

export const RatingSystemGoals = ({ values, onChange, readOnly, grouping, score, superScore, kpiOnly }) => {
  const { t } = useTranslation();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [showDialog, setShowDialog] = useState(null);
  const [showBenchmarkDialog, setShowBenchmarkDialog] = useState(null);
  const [kpis, setKpis] = useState([]);
  const benchmarkResponse = useBenchmarkResponse();

  useEffect(() => {
    setKpis(getKPIValues(grouping));
  }, [grouping]);
  useEffect(() => {
    const { loading, data } = benchmarkResponse;
    if (!loading && data) {
      const goal = new GoalElement({
        name: data.name,
        kpiName: data.kpiName,
        kpiUnit: data.kpiUnit,
        goals: data.goals,
        type: KPI_TYPE,
      });
      addGoal(goal);
      resetBenchmarkResponse();
    }
  }, [benchmarkResponse.loading]);

  const addGoal = (data) => {
    const updated = [...values];
    updated.push(data);
    onChange(updated);
  };

  const onDelete = () => {
    const UID = deleteConfirmation;
    onChange(values.filter((item) => item.UID !== UID));
    setDeleteConfirmation(null);
  };

  const onGoalsChange = (data) => {
    if (!values.find((item) => item.UID === data.UID)) {
      addGoal(data);
    } else {
      onChange(
        values.map((item) => {
          if (item.UID === data.UID) {
            return { ...data };
          } else {
            return item;
          }
        })
      );
    }
  };

  const onBenchmarkSelect = useEvent((benchmarkId) => getBenchmark(benchmarkId));

  const createButtons = useMemo(
    () => (
      <div className="flex-row flex-justify-center">
        <div className="add-rating-goals">
          <span onClick={() => setShowDialog(new GoalElement())}>
            <AddIcon />
            {t("ratingSystem.createGoal")}
          </span>
        </div>
        <div className="add-rating-goals">
          <span
            onClick={() => !!kpis.length && setShowBenchmarkDialog(true)}
            className={!kpis.length ? "disabled" : ""}
          >
            <AddIcon />
            {t("ratingSystem.createBenchmarkGoal")}
          </span>
        </div>
      </div>
    ),
    [kpis]
  );

  return (
    <LoadingOverlay
      spinner
      active={benchmarkResponse.loading}
      className={
        "ratingSystemGoals auto-height" + (values.length ? " ratingSystemGoals-hasBlocks" : " ratingSystemGoals-empty")
      }
    >
      {!values.length && !readOnly && (
        <>
          <p className="text-center empty-title">{values.length || t("ratingSystem.youHaveNoGoals")}</p>
          {createButtons}
        </>
      )}
      {!!values.length && (
        <>
          {values.map((item) => (
            <GoalCard
              key={item.UID}
              card={item}
              kpis={kpis}
              onSetDeleteConfirmation={setDeleteConfirmation}
              readOnly={readOnly}
              onSetShowDialog={setShowDialog}
              showDialog={showDialog}
              score={score}
              superScore={superScore}
            />
          ))}
          {!readOnly && createButtons}
        </>
      )}

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={onDelete}
        titleText={t("ratingSystem.deleteGoalsConfirmation")}
        bodyText={t("ratingSystem.deleteGoalsConfirmationText")}
        confirmText={t("main.delete")}
        color="secondary"
      />

      <GoalsDialog
        open={!!showDialog}
        onClose={() => setShowDialog(null)}
        data={showDialog || {}}
        readOnly={readOnly}
        kpiOnly={kpiOnly}
        onSave={onGoalsChange}
        kpis={kpis}
      />

      <BenchmarksDialog
        open={!!showBenchmarkDialog}
        onClose={() => setShowBenchmarkDialog(null)}
        onSelect={onBenchmarkSelect}
        kpis={kpis}
      />
    </LoadingOverlay>
  );
};
