import { forEachRecursive } from "../utils";
import { VALIDATION_ERROR_REQUIRED } from "../constants/validation";

export const validateRatingSystemGrouping = (grouping = []) => {
  const validationErrors = [];

  forEachRecursive(grouping, (node, tree, index, level, parent, path) => {
    if (node.kpi && node.kpi.currentValue && (!node.kpi.estimation && node.kpi.estimation !== 0)) {
      validationErrors.push({ field: 'kpi.estimation', type: VALIDATION_ERROR_REQUIRED, path });
    }
  });

  return validationErrors;
};
