import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import { SIDEBAR_OPEN_LOCAL_STORAGE, getVersion, menuSubject, useMenu } from "../../hooks";
import { useAccessToken, useUser } from "../../hooks/auth";
import { MuiDialogs } from "../MuiDialogs/MuiDialogs";
import { CustomItem } from "./CustomItem";
import "./DefaultLayout.scss";
import { itemList } from "./Items";

const getExpandedIndex = (items) => {
  return items.findIndex((item) => item.dataExpanded);
};

const updateItemSelection = (item, index, params) => {
  const { selected, level, parentId, dataExpanded, id, ...others } = item;
  const {
    currentItem,
    selectedHeader,
    nextExpanded,
    expandedIndex,
    setSelectedChild,
    setSelectedParent,
    setSelectedHeader,
  } = params;
  const isChild = parentId !== undefined;
  const isParent = dataExpanded !== undefined;
  const isCurrentItem = currentItem.id === id;

  if (currentItem.id === 0) setSelectedHeader(!selectedHeader);

  if (isCurrentItem && !isParent && !isChild) {
    setSelectedChild(undefined);
    return { selected: true, id, ...others };
  }

  if (isCurrentItem && isParent) {
    setSelectedChild(undefined);
    return { selected: true, dataExpanded: nextExpanded, id, ...others };
  }

  if (currentItem.pID === id) return { selected: true, dataExpanded: true, id, ...others };

  if (index === expandedIndex) return { selected: false, dataExpanded: false, id, ...others };

  if (isParent) return { selected: false, dataExpanded: false, id, ...others };

  if (isChild) {
    if (isCurrentItem) {
      setSelectedChild(id);
      setSelectedParent(parentId);
    }
    return { level, parentId, id, ...others };
  }

  return { selected: false, id, ...others };
};

export const DefaultLayout = () => {
  const navigate = useNavigate();
  const token = useAccessToken();
  const user = useUser();

  useEffect(() => {
    const afterReloadPath = sessionStorage.getItem("afterReloadPath");
    if (afterReloadPath) {
      navigate(afterReloadPath);
      sessionStorage.removeItem("afterReloadPath");
    }
  }, []);

  useEffect(() => {
    if (token) {
      getVersion();
    }
  }, [token]);

  const menu = useMenu();
  const drawerOpen = menu.open;
  const setDrawerOpen = (open) => {
    menuSubject.next({ open });
    localStorage.setItem(SIDEBAR_OPEN_LOCAL_STORAGE, open);
  };

  const [items, setItems] = React.useState(itemList);
  const [selectedParent, setSelectedParent] = React.useState();
  const [selectedChild, setSelectedChild] = React.useState();
  const [selectedHeader, setSelectedHeader] = React.useState(); // Define setSelectedHeader here

  const onSelect = (ev) => {
    const currentItem = ev.itemTarget.props;
    const nextExpanded = !currentItem.dataExpanded;

    const expandedIndex = getExpandedIndex(items);

    const newData = items.map((item, index) =>
      updateItemSelection(item, index, {
        currentItem,
        selectedHeader,
        nextExpanded,
        expandedIndex,
        setSelectedChild,
        setSelectedParent,
        setSelectedHeader, // Pass setSelectedHeader here
      })
    );

    navigate(ev.itemTarget.props.href);

    if (ev.itemTarget.props.isParent && !drawerOpen) {
      setDrawerOpen(!drawerOpen);
    }

    setItems(newData);
  };

  useEffect(() => {
    if (selectedParent) {
      const updatedItems = items.map((item) => {
        if (item.id === selectedParent) {
          return {
            ...item,
            selected: true,
          };
        }
        return item;
      });
      setItems(updatedItems);
    }
  }, [selectedParent]);

  useEffect(() => {
    if (selectedChild) {
      const updatedItems = items.map((item) => {
        if (item.id === selectedChild) {
          return {
            ...item,
            isSelected: true,
          };
        }

        return { ...item, isSelected: false };
      });
      setItems(updatedItems);
    } else {
      const updatedItems = items.map((item) => {
        return { ...item, isSelected: false };
      });
      setItems(updatedItems);
    }
  }, [selectedChild]);

  useEffect(() => {
    if (selectedHeader) {
      const updatedItems = items.map((item) => {
        if (item.id === 1) {
          return {
            ...item,
            selected: true,
          };
        }
        return { ...item, isSelected: false, selected: false };
      });
      setItems(updatedItems);
    }
  }, [selectedHeader]);

  const data = items.map((item) => {
    const { selected, parentId, ...others } = item;
    if (parentId !== undefined) {
      const parentEl = items.find((parent) => parent.id === parentId);
      return {
        ...others,
        visible: !!parentEl?.dataExpanded,
      };
    }
    return item;
  });

  return (
    <Drawer
      className={drawerOpen ? "drawer separator-expanded" : "drawer separator-collapsed"}
      position={"start"}
      expanded={drawerOpen}
      mode={"push"}
      mini={true}
      items={data}
      onSelect={onSelect}
      item={CustomItem}
      width={380}
      miniWidth={100}
    >
      <DrawerContent className="content">
        <div className={"content-area" + (drawerOpen ? " open" : "")}>{user ? <Outlet /> : null}</div>
        <MuiDialogs />
      </DrawerContent>
    </Drawer>
  );
};
