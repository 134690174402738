import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, load, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import weekData from 'cldr-core/supplemental/weekData.json';
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from "cldr-dates-full/main/de/dateFields.json";
import deNumbers from "cldr-numbers-full/main/de/numbers.json";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_SERVER } from "../../constants/main";
import kendoDeMessages from '../../locales/kendo-de.json';
import "./DatePickerDialog.scss";

load(
  deNumbers,
  deDateFields,
  deCaGregorian,
  weekData
)
loadMessages(kendoDeMessages, 'de');

export const DatePickerDialog = ({ onClose, onChange, defaultValue, title, fieldLabel }) => {
  const { t, i18n } = useTranslation();
  const [date, setDate] = useState(defaultValue);

  return (
    <div className="date-picker-dialog">
      <h2>{title}</h2>
      <div className="flex-row">
        <LocalizationProvider language={i18n.language}>
          <IntlProvider locale={i18n.language}>
            <div className="flex-auto">
              <InputLabel htmlFor={"datePickerDialogInput"}>{fieldLabel}</InputLabel>
              <DatePicker
                className="date-picker"
                id={"datePickerDialogInput"}
                fillMode="flat"
                value={new Date(date)}
                format={"dd.MM.yyyy"}
                onChange={(value) => {
                  setDate(value ? moment(value.value).format(DATE_FORMAT_SERVER) : value.value);
                }}
              />
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <div>
          <Button size="small" onClick={() => setDate(moment(new Date()).format(DATE_FORMAT_SERVER))}>
            {t("main.today")}
          </Button>
        </div>
      </div>
      <div className="buttons text-right">
        <Button size="small" onClick={onClose}>
          {t("main.cancel")}
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => {
            onClose();
            onChange(date);
          }}
        >
          {t("main.save")}
        </Button>
      </div>
    </div>
  );
};
