import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { ACTION_STATE_APPROVED, ACTION_STATE_REJECTED, ACTION_STATE_WAITING } from "../../../constants/action";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../../constants/main";
import { PERMISSION_ACTION_CREATE, PERMISSION_ACTION_EDIT } from "../../../constants/permissions";
import { hasPermission } from "../../../helpers/permission";
import { useActionsPageLogic } from "../../../hooks/actionTemplate";
import { processMessage } from "../../../utils";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import { ActionTagsTemplate } from "./ActionTagsTemplate";
import { ActionTextTemplate } from "./ActionTextTemplate";
import "./ActionsTable.scss";

const isWaiting = (item) => item.state === ACTION_STATE_WAITING;
const isReadOnly = (item, editRow) => isWaiting(item) || !editRow || editRow.UID !== item.UID;

const nameColumnTemplate = (canEdit) => (row, field, parentRow, passThrough) =>
  (
    <ActionTextTemplate
      row={row}
      field={field}
      required
      passThrough={passThrough}
      maxLength={255}
      readOnly={isReadOnly(row, passThrough.editRow) || !canEdit}
    />
  );

const descriptionColumnTemplate = (canEdit, onAccept, onReject, t) => (row, field, parentRow, passThrough) =>
  (
    <>
      <ActionTextTemplate
        row={row}
        field={field}
        required
        passThrough={passThrough}
        maxLength={255}
        readOnly={isReadOnly(row, passThrough.editRow) || !canEdit}
      />
      {canEdit && isWaiting(row) && (
        <>
          <br />
          <Button
            color="primary"
            className="waiting-button"
            disabled={!!passThrough.editRow}
            onClick={() => onAccept(row)}
          >
            <CheckIcon />
            {t("actions.accept")}
          </Button>
          <Button
            color="secondary"
            className="waiting-button"
            disabled={!!passThrough.editRow}
            onClick={() => onReject(row)}
          >
            <CloseIcon />
            {t("actions.reject")}
          </Button>
        </>
      )}
    </>
  );

const listTagsColumnTemplate = (canEdit) => (row, field, parentRow, passThrough) =>
  (
    <ActionTagsTemplate
      readOnly={isReadOnly(row, passThrough.editRow) || !canEdit}
      field={field}
      row={row}
      passThrough={passThrough}
    />
  );

const actionsColumnTemplate = (onSave, onCancel, setEditRow, onDelete) => (row, field, parentRow, passThrough) => {
  if (isWaiting(row)) {
    return null;
  }

  const { editRow: editRowCanEdit } = passThrough;
  const isEdit = !!editRowCanEdit && editRowCanEdit.UID === row.UID;

  return isEdit ? (
    <>
      <IconButton size="small" color="primary" onClick={() => onSave(editRowCanEdit)}>
        <CheckIcon />
      </IconButton>
      <IconButton size="small" color="secondary" onClick={() => onCancel()}>
        <CloseIcon />
      </IconButton>
    </>
  ) : (
    <>
      <IconButton color="primary" size="small" onClick={() => setEditRow(row)} disabled={!!editRowCanEdit}>
        <EditIcon />
      </IconButton>
      <IconButton size="small" onClick={() => onDelete(row)} disabled={!!editRowCanEdit}>
        <DeleteOutlineIcon />
      </IconButton>
    </>
  );
};

export const ActionsTable = ({
  data,
  loading,
  onChange,
  submitted,
  onDelete,
  onSave,
  isAdd,
  onCancel,
  setEditRow,
  editRow,
  onAccept,
  onReject,
}) => {
  const { t } = useTranslation();

  const [processed, setProcessed] = useState([]);
  const [added, setAdded] = useState(null);
  const {
    sortValue,
    setSortValue,
    filterValue,
    setFilterValue,
    searchValue,
    setSearchValue,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
  } = useActionsPageLogic();

  const canEdit = hasPermission(PERMISSION_ACTION_EDIT) || (hasPermission(PERMISSION_ACTION_CREATE) && isAdd);

  useEffect(() => {
    if (!loading && !editRow) {
      let updated = [...data];
      // search
      const searchQuery = String(searchValue).trim().toLowerCase();
      if (searchQuery) {
        updated = updated.filter((item) => {
          return (
            String(item.name).toLowerCase().indexOf(searchQuery) !== -1 ||
            String(item.description).toLowerCase().indexOf(searchQuery) !== -1 ||
            !!(item.listTags || []).find((tag) => String(tag).toLowerCase().indexOf(searchQuery) !== -1)
          );
        });
      }
      // filter
      updated = updated.filter((item) => {
        if (filterValue === ACTION_STATE_WAITING) {
          return item.state === ACTION_STATE_WAITING;
        } else if (filterValue === ACTION_STATE_REJECTED) {
          return item.state === ACTION_STATE_REJECTED;
        }
        return item.state !== ACTION_STATE_REJECTED;
      });
      // sort
      updated.sort((a, b) => {
        if (a.id && !b.id) {
          return 1;
        }
        if (b.id && !a.id) {
          return -1;
        }
        if (sortValue.field) {
          if (a[sortValue.field] > b[sortValue.field]) {
            return sortValue.desc ? -1 : 1;
          }
          if (a[sortValue.field] < b[sortValue.field]) {
            return sortValue.desc ? 1 : -1;
          }
        }
        return 0;
      });
      setProcessed(updated);
      if (page * rowsPerPage > updated.length) {
        setPage(Math.floor(updated.length / rowsPerPage));
      }
    }
  }, [loading, searchValue, sortValue, filterValue, page, rowsPerPage, data]);

  useEffect(() => {
    if (isAdd) {
      const newRecord = { state: ACTION_STATE_APPROVED, UID: getUUID() };
      onChange([newRecord, ...data]);
      setSearchValue("");
      setSortValue({ field: null, desc: false });
      setFilterValue(null);
      setPage(0);
      setAdded(newRecord);
    }
  }, [isAdd]);

  useEffect(() => {
    if (added) {
      setEditRow(added);
      setAdded(null);
    }
  }, [added]);

  const onValueChange = (row, field, value) => {
    row[field] = value;
    onChange([...data]);
  };

  const onSort = (field) =>
    setSortValue({
      field: field !== sortValue.field || !sortValue.desc ? field : null,
      desc: sortValue.field === field ? !sortValue.desc : false,
    });

  const pagination = {
    labelRowsPerPage: t("actions.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: processed.length,
    page: page,
    show: !editRow && processed.length > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pagePagination) => setPage(pagePagination),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const paged = processed.length > rowsPerPage ? [...processed].splice(page * rowsPerPage, rowsPerPage) : processed;

  const columns = [
    {
      field: "name",
      headerText: t("action.name"),
      headerClassName: "name-column capitalized",
      sortable: true,
      template: nameColumnTemplate(canEdit),
    },
    {
      field: "description",
      headerText: t("action.description"),
      headerClassName: "description-column",
      sortable: true,
      template: descriptionColumnTemplate(canEdit, onAccept, onReject, t),
    },
    {
      field: "listTags",
      headerText: t("action.listTags"),
      headerClassName: "tags-column",
      template: listTagsColumnTemplate(canEdit),
    },
  ];

  if (canEdit) {
    columns.push({
      headerText: "",
      className: "actions-column",
      template: actionsColumnTemplate(onSave, onCancel, setEditRow, onDelete),
    });
  }

  return (
    <SimpleTableWithSort
      className="actions-table repaint"
      data={paged}
      sortValue={!editRow ? sortValue : { field: null, desc: false }}
      disableSort={!!editRow}
      onSort={onSort}
      pagination={pagination}
      passThrough={{ t, editRow, onValueChange, submitted }}
      columns={columns}
    />
  );
};

ActionsTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  submitted: PropTypes.bool,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  isAdd: PropTypes.bool,
  onCancel: PropTypes.func,
  setEditRow: PropTypes.func,
  editRow: PropTypes.object,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
};
