import { forEachRecursive, mapRecursive } from "../utils";
import { processMessage } from "../utils";
import { GROUPING_TYPE_CATEGORY, GROUPING_TYPE_CRITERION, GROUPING_TYPE_KPI } from "../constants/kpiSystem";
import {
  VALIDATION_ERROR_KO_VALUE,
  VALIDATION_ERROR_MAX_LENGTH,
  VALIDATION_ERROR_REQUIRED,
  VALIDATION_ERROR_SUM_WEIGHT,
  VALIDATION_ERROR_UNIQUE,
  VALIDATION_ERROR_WEIGHT_FACTOR
} from "../constants/validation";

export const conditionIsKPI = item => item.type === GROUPING_TYPE_KPI;
export const conditionNotKPI = item => item.type !== GROUPING_TYPE_KPI;
export const conditionIsCriterion = item => item.type === GROUPING_TYPE_CRITERION;
export const conditionIsCategory = item => item.type === GROUPING_TYPE_CATEGORY;

const validations = [
  {
    field: 'name',
    type: VALIDATION_ERROR_REQUIRED,
    condition: conditionNotKPI
  }, {
    field: 'name',
    type: VALIDATION_ERROR_MAX_LENGTH,
    maxLength: 255,
    condition: conditionNotKPI
  }, {
    field: 'kpiName',
    type: VALIDATION_ERROR_REQUIRED,
    condition: conditionIsKPI
  }, {
    field: 'kpiName',
    type: VALIDATION_ERROR_MAX_LENGTH,
    maxLength: 255,
    condition: conditionNotKPI
  }
];

export const validationErrorMessage = (validationErrors = [], t) => {
  if (validationErrors.find(item => VALIDATION_ERROR_UNIQUE === item.type)) {
    return t('kpiSystem.uniqueValidationError');
  }
  if (validationErrors.find(item => [VALIDATION_ERROR_REQUIRED, VALIDATION_ERROR_MAX_LENGTH, VALIDATION_ERROR_WEIGHT_FACTOR].indexOf(item.type) !== -1)) {
    return t('kpiSystem.validationError');
  }
  if (validationErrors.find(item => [VALIDATION_ERROR_KO_VALUE].indexOf(item.type) !== -1)) {
    return t('kpiSystem.koValueSizeError');
  }
  let parent = null;
  const weightErrors = validationErrors.filter(item => {
    if (item.type === VALIDATION_ERROR_SUM_WEIGHT) {
      if (!parent) {
        parent = item.parentPath;
      }
      return parent === item.parentPath;
    }
    return false;
  });
  if (weightErrors.length) {
    return processMessage(t('kpiSystem.weightValidationError'), [
      weightErrors.map(item => item.name).join(', '),
      weightErrors[0].sum
    ]);
  }
};

export const validateGrouping = (grouping = []) => {
  const validationErrors = [];

  const uniques = {};
  forEachRecursive(grouping, (node, tree, index, level, parent, path) => {
    for (let validation of validations) {
      const value = node[validation.field];
      if (!!validation.condition && !validation.condition(node)) {
        continue;
      }
      if (validation.type === VALIDATION_ERROR_REQUIRED && !value) {
        validationErrors.push({ ...validation, path });
      }
      if (validation.type === VALIDATION_ERROR_MAX_LENGTH && value && value.length > validation.maxLength) {
        validationErrors.push({ ...validation, path });
      }
      if (validation.type === VALIDATION_ERROR_UNIQUE && value) {
        if (!uniques[validation.field]) {
          uniques[validation.field] = [];
        }
        if (uniques[validation.field].indexOf(value) !== -1) {
          validationErrors.push({ ...validation, path });
        } else {
          uniques[validation.field].push(value);
        }
      }
    }
  });

  return validationErrors;
};

export const processGrouping = grouping => {
  return mapRecursive(grouping, ({
                                   id,
                                   uuid,
                                   kpiSystemId,
                                   kpiName,
                                   kpiUnit,
                                   name,
                                   type,
                                   links,
                                   files,
                                   originKPIElementUuid,
                                   originGroupingElementUuid
                                 }, children) => {
    if (type !== GROUPING_TYPE_KPI) {
      const kpiElements = children.filter(conditionIsKPI);
      const groupingElements = children.filter(conditionNotKPI);
      return {
        id,
        uuid,
        name,
        type,
        kpiElements,
        groupingElements,
        originGroupingElementUuid
      };
    }
    return {
      id,
      uuid,
      type,
      kpiName,
      kpiUnit,
      links,
      files,
      originKPIElementUuid
    };
  });
};
