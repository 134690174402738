import BarChartIcon from "@mui/icons-material/BarChart";
import EditIcon from "@mui/icons-material/Edit";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { ContentBlock } from "../../components/ContentBlock/ContentBlock";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { ProjectGroupHierarchy } from "../../components/ProjectGroup/ProjectGroupHierarchy/ProjectGroupHierarchy";
import { ProjectGroupMainBlock } from "../../components/ProjectGroup/ProjectGroupMainBlock/ProjectGroupMainBlock";
import { ProjectGroupProjects } from "../../components/ProjectGroup/ProjectGroupProjects/ProjectGroupProjects";
import { ProjectGroupPermissionsDialog } from "../../components/ProjectGroupPermissionsDialog/ProjectGroupPermissionsDialog";
import { PERMISSION_PROJECT_GROUP_EDIT, PERMISSION_PROJECT_GROUP_READ } from "../../constants/permissions";
import { hasPermission } from "../../helpers/permission";
import { canUserEditProjectGroup, canUserEditProjectGroupPermissions } from "../../helpers/projectGroups";
import { useUser } from "../../hooks/auth";
import { showDialog } from "../../hooks/dialog";
import { getProjectAvailableUsers, useProjectAvailableUsersResponse } from "../../hooks/project";
import { getProjectGroup, useProjectGroupResponse } from "../../hooks/projectGroup";
import { showError } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { CloseSVG } from "../../svg";
import "./ProjectGroup.scss";

export const ProjectGroupPage = () => {
  const mode = new URLSearchParams(window.location.search).get("mode");
  const { projectGroupId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(mode === "edit" && hasPermission(PERMISSION_PROJECT_GROUP_EDIT));
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [resetProjects, setResetProjects] = useState(0);

  const projectGroupResponse = useProjectGroupResponse();
  const projectGroup = projectGroupResponse.data || {};

  useEffect(() => {
    if (projectGroupId) {
      if (mode === "edit" && hasPermission(PERMISSION_PROJECT_GROUP_EDIT)) {
        window.history.replaceState(null, null, window.location.pathname);
      }
      load();
    }
  }, [projectGroupId]);

  const load = useEvent(() => {
    getProjectGroup(projectGroupId)
      .then(getProjectAvailableUsers)
      .catch(() => {
        showError(t("error.500"));
        navigate("/projectGroup");
      });
  });

  const reloadProjects = () => setResetProjects(resetProjects + 1);

  const breadCrumb = useMemo(
    () => [{ url: `/projectGroup`, text: t("menu.projectGroups.title") }, { text: projectGroup.name || "" }],
    [projectGroup.name]
  );

  const loading = projectGroupResponse.loading;

  const { user } = useUser();
  const canEditProjectGroupPermissions = canUserEditProjectGroupPermissions(user, projectGroup);
  const canEditProjectGroup = canUserEditProjectGroup(user, projectGroup);

  const projectAvailableUsersResponse = useProjectAvailableUsersResponse();
  const projectAvailableUsers = projectAvailableUsersResponse.data || [];

  const showPermissionsModal = () => {
    showDialog({
      className: "large",
      closeOnClickOutside: false,
      getContent: (onClose) => (
        <ProjectGroupPermissionsDialog
          data={projectGroup?.projectGroupPermissions}
          onClose={onClose}
          projectAvailableUsers={projectAvailableUsers}
          onChange={() => {
            onClose();
            load();
          }}
          projectId={projectGroup.id}
          isEdit={isEdit && canEditProjectGroupPermissions}
        />
      ),
    });
  };
  return (
    <LoadingOverlay spinner active={loading} className="project-group-page">
      <div className="page-header flex-row">
        <Breadcrumb pathParts={breadCrumb} />
        <div className="flex-auto text-right">
          {hasPermission(PERMISSION_PROJECT_GROUP_READ) && !!projectGroupId && (
            <>
              <Button color="primary" className="right-header-button" onClick={showPermissionsModal}>
                <GroupOutlinedIcon />
                {t("projectGroups.team")}
              </Button>
            </>
          )}
          <NavLink to={"/projectGroup/analyze/" + projectGroupId}>
            <Button color="primary" className="right-header-button">
              <BarChartIcon />
              {t("main.analyze")}
            </Button>
          </NavLink>
        </div>
      </div>

      <div className="page-layout">
        <div className="top-block">
          <ContentBlock className="main-block">
            <ProjectGroupMainBlock key={isEdit} data={projectGroup} readOnly={!isEdit} />
          </ContentBlock>
          <ContentBlock className="flex-auto right-block">
            <ProjectGroupHierarchy
              projectGroupId={projectGroupId}
              readOnly={!isEdit}
              onSelectGroup={setSelectedGroup}
              onAddProject={reloadProjects}
            />
          </ContentBlock>
        </div>
        <div className="projectGroups">
          <ContentBlock>
            <ProjectGroupProjects
              key={resetProjects}
              selectedProjectGroupId={selectedGroup || projectGroupId}
              projectGroupId={projectGroupId}
              readOnly={!isEdit}
            />
          </ContentBlock>
        </div>
      </div>

      {hasPermission(PERMISSION_PROJECT_GROUP_EDIT) && canEditProjectGroup && (
        <div className="fabs">
          {!isEdit && (
            <Fab
              type="button"
              color="primary"
              onClick={() => {
                setIsEdit(true);
              }}
            >
              <EditIcon className="fab-svg" />
            </Fab>
          )}
          {isEdit && (
            <Fab
              color="secondary"
              onClick={() => {
                setIsEdit(false);
              }}
            >
              <CloseSVG className="fab-svg" />
            </Fab>
          )}
        </div>
      )}
    </LoadingOverlay>
  );
};
