import React from "react";
import { SelectMigrationTargetDialog } from "../../SelectMigrationTargetDialog/SelectMigrationTargetDialog";
import { showError, showSuccess } from "../../../hooks/toast";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../hooks/auth";
import { migrateKpiSystem, useKpiSystemMigrationResponse } from "../../../hooks/kpiSystem";
import { getUserAvailableTenantsForMigration } from "../../../utils";

export const KPISystemCopyToTenantDialog = React.memo(({ kpiSystemId, ...props }) => {
  const { t } = useTranslation();
  const user = useUser();
  
  const availableTenants = getUserAvailableTenantsForMigration(user);
  const kpiSystemMigrationResponse = useKpiSystemMigrationResponse();

  return (
    <SelectMigrationTargetDialog
      title={t('kpiSystem.migrationDialogHeader')}
      onSelect={tenantId => {
        migrateKpiSystem(kpiSystemId, tenantId)
          .then(() => showSuccess(t('kpiSystem.benchmarkMigrated')))
          .catch(error => {
            showError(t('error.500'));
            console.error(error);
          });
      }}
      migrationTargets={availableTenants}
      loading={kpiSystemMigrationResponse.loading}
      {...props}
    />
  );
});
