import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { setProjectAttributeDrag, setProjectAttributeDragStart } from "../../../hooks/projectAttribute";
import { useEvent } from "../../../hooks/utils/useEvent";
import "./ProjectAttributeItem.scss";

export const ProjectAttributeItem = ({ data, onEdit, onDelete, className }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const onEditClick = useEvent(() => onEdit && onEdit(data));
  const onDeleteClick = useEvent(() => onDelete && onDelete(data));
  const onMouseDown = useEvent((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (data) {
      const { clientX, clientY, currentTarget } = event;
      setProjectAttributeDragStart({ clientX, clientY, target: currentTarget, data });
      setProjectAttributeDrag({ clientX, clientY });
    }
  });

  return (
    <div className={"project-attribute-item flex-row box-shadow " + (className || "")} id={data?.uuid}>
      <div className="dnd-trigger">
        <DragIndicatorIcon onMouseDown={onMouseDown} />
      </div>
      <div className="flex-auto w-100">
        <div className="flex-row">
          <div>
            <span className="text-muted">{t("projectAttributes.label")}:</span>{" "}
            {data?.details ? data?.details[language] : ""}
          </div>
          <div className="text-right flex-auto">
            <span className="text-muted">{t("projectAttributes.type.title")}:</span>{" "}
            {data?.type ? t("projectAttributes.type." + data?.type) : ""}
          </div>
        </div>
        <div className="flex-row">
          <div>{data?.name}</div>
          <div className="text-right flex-auto text-danger">{data?.required ? t("main.required") : ""}</div>
        </div>
      </div>
      <div className="actions-column">
        <IconButton color="primary" size="small" onClick={onEditClick}>
          <VisibilityOutlinedIcon />
        </IconButton>
        <IconButton color="secondary" size="small" onClick={onDeleteClick}>
          <DeleteOutlineIcon />
        </IconButton>
      </div>
    </div>
  );
};
