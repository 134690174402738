import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { FilesTable } from "../FilesTable/FilesTable";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";

export const SelectFileDialog = ({ open, onClose, onSelect, files, isLoading, getFilePath }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} className="large">
      <DialogTitle>
        {t("selectFile.headerText")}{" "}
        <IconButton onClick={onClose} size="small">
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <LoadingOverlay spinner active={isLoading} className="select-file-dialog auto-height">
        <div className="dialog-body">
          <FilesTable readOnly files={files} getFilePath={getFilePath} onClick={(item) => onSelect(item)} />
        </div>
      </LoadingOverlay>
      <div className="buttons text-right">
        <Button onClick={() => onClose()}>{t("main.close")}</Button>
      </div>
    </Dialog>
  );
};
