import EqualizerIcon from "@mui/icons-material/Equalizer";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TENANT_TYPE_ESG, TENANT_TYPE_PAST } from "../../../constants/tenant";
import { getUserTenantType } from "../../../hooks/auth";
import { ActionsTab } from "../ActionsTab/ActionsTab";
import { GoalsTab } from "../GoalsTab/GoalsTab";
import { GroupingElementsTabESG } from "../GroupingElementsTab/GroupingElementsTabESG";
import { GroupingElementsTabPAST } from "../GroupingElementsTab/GroupingElementsTabPAST";
import { KPITab } from "../KPITab/KPITab";
import "./DashboardTabs.scss";

const TabsTopRightButtons = ({ unfolded, setUnfolded }) => (
  <div className="tabs-top-right-buttons">
    <UnfoldButton unfolded={unfolded} setUnfolded={setUnfolded} />
  </div>
);

const UnfoldButton = ({ unfolded, setUnfolded }) => (
  <IconButton className="expand-collapse" size="small" onClick={() => setUnfolded(!unfolded)}>
    {!unfolded ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
  </IconButton>
);

export const DashboardTabs = ({ data, revisionNumber, lastRevision, kpiTabs, unfolded, setUnfolded }) => {
  const { t } = useTranslation();
  const tenantType = getUserTenantType();
  const [activeTab, setActiveTab] = useState(0);
  const [loadKPI, setLoadKPI] = useState(false);

  useEffect(() => {
    const isKpiTab = ((kpiTabs && activeTab === 2) || (!kpiTabs && activeTab === 3)) && tenantType === TENANT_TYPE_ESG;
    if (isKpiTab) {
      setLoadKPI(true);
      return;
    }
    setLoadKPI(false);
  }, [activeTab]);

  let tabs;

  if (kpiTabs) {
    tabs = (
      <>
        <Tabs value={activeTab} onChange={(event, index) => setActiveTab(index)}>
          aq
          <Tab icon={<FlagOutlinedIcon />} label={t("dashboard.tabs.goals")} />
          <Tab icon={<ShowChartOutlinedIcon />} label={t("dashboard.tabs.actions")} className="capitalized" />
          <Tab icon={<EqualizerIcon />} label={t("dashboard.tabs.kpi")} />
          <TabsTopRightButtons unfolded={unfolded} setUnfolded={setUnfolded} />
        </Tabs>
        <div hidden={activeTab !== 0}>
          <GoalsTab ratingSystem={data} isKpiSystem={kpiTabs} />
        </div>
        <div hidden={activeTab !== 1}>
          <ActionsTab ratingSystem={data} isKpiSystem={kpiTabs} />
        </div>
        <div hidden={activeTab !== 2}>
          <KPITab
            ratingSystem={data}
            isKpiSystem={kpiTabs}
            revisionNumber={revisionNumber}
            lastRevision={lastRevision}
            load={loadKPI}
            unfolded={unfolded}
          />
        </div>
      </>
    );
  } else if (tenantType === TENANT_TYPE_PAST) {
    tabs = (
      <>
        <Tabs value={activeTab} onChange={(event, index) => setActiveTab(index)}>
          <Tab icon={<InfoOutlinedIcon />} label={t("dashboard.tabs.details")} />
          <Tab icon={<ShowChartOutlinedIcon />} label={t("dashboard.tabs.actions")} className="capitalized" />
          <TabsTopRightButtons unfolded={unfolded} setUnfolded={setUnfolded} />
        </Tabs>
        <div hidden={activeTab !== 0}>
          <GroupingElementsTabPAST groupingElements={(data || {}).groupingElements || []} />
        </div>
        <div hidden={activeTab !== 1}>
          <ActionsTab ratingSystem={data} />
        </div>
      </>
    );
  } else {
    // ESG
    tabs = (
      <>
        <Tabs value={activeTab} onChange={(event, index) => setActiveTab(index)}>
          <Tab icon={<InfoOutlinedIcon />} label={t("dashboard.tabs.details")} />
          <Tab icon={<FlagOutlinedIcon />} label={t("dashboard.tabs.goals")} />
          <Tab icon={<ShowChartOutlinedIcon />} label={t("dashboard.tabs.actions")} className="capitalized" />
          <Tab icon={<EqualizerIcon />} label={t("dashboard.tabs.kpi")} />
          <TabsTopRightButtons unfolded={unfolded} setUnfolded={setUnfolded} />
        </Tabs>
        <div hidden={activeTab !== 0}>
          <GroupingElementsTabESG groupingElements={(data || {}).groupingElements || []} />
        </div>
        <div hidden={activeTab !== 1}>
          <GoalsTab ratingSystem={data} />
        </div>
        <div hidden={activeTab !== 2}>
          <ActionsTab ratingSystem={data} />
        </div>
        <div hidden={activeTab !== 3}>
          <KPITab
            ratingSystem={data}
            revisionNumber={revisionNumber}
            lastRevision={lastRevision}
            load={loadKPI}
            unfolded={unfolded}
          />
        </div>
      </>
    );
  }

  return <div className={"dashboard-tabs" + (unfolded ? " unfolded" : "")}>{tabs}</div>;
};
