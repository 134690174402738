import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../../constants/main";
import { ATTRIBUTE_STATUS_ACTIVE } from "../../../constants/projectAttribute";
import {
  getAdminGeneralProjectAttributeList,
  resetGeneralAttributeAddPageState,
  useAdminGeneralProjectAttributeListResponse,
  useGeneralAttributeAddPageLogic,
  useProjectTypeAttributes,
} from "../../../hooks/projectAttribute";
import { showError } from "../../../hooks/toast";
import { useTimeout } from "../../../hooks/utils/useTimeout";
import { processMessage } from "../../../utils";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import "./GeneralAttributesDialog.scss";

export const GeneralAttributesDialog = ({ addAttribute, onClose }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const inputRef = useRef(null);
  const [, setSearchTimeout] = useTimeout();
  const [processed, setProcessed] = useState([]);

  const response = useAdminGeneralProjectAttributeListResponse();
  const data = (response.data || []).filter((item) => item.status === ATTRIBUTE_STATUS_ACTIVE);

  const { searchValue, setSearchValue, page, rowsPerPage, setPage, setRowsPerPage, resetState } =
    useGeneralAttributeAddPageLogic();

  const projectTypeAttributes = useProjectTypeAttributes();

  const usedAttributes = useMemo(() => {
    const values = {};
    data.forEach((attribute) => {
      if (projectTypeAttributes.some((item) => item.name === attribute.name)) {
        values[attribute.name] = true;
      }
    });
    return values;
  }, [projectTypeAttributes, data]);

  const load = () =>
    getAdminGeneralProjectAttributeList()
      .then()
      .catch((error) => {
        console.error(error);
        showError(t("error.500"));
      });

  useEffect(() => {
    load();
    resetGeneralAttributeAddPageState();
  }, []);

  useEffect(() => {
    if (!response.loading) {
      let updated = [...data];
      // search
      if (searchValue) {
        updated = updated.filter(
          (item) =>
            ((item.details || {})[language] || "").toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
            (item.name || "").toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        );
      }
      setProcessed(updated);
      if (page * rowsPerPage > updated.length) {
        setPage(Math.floor(updated.length / rowsPerPage));
      }
    }
  }, [response.loading, searchValue, page, rowsPerPage]);

  const search = (value) => {
    setSearchTimeout(
      setTimeout(() => {
        setSearchValue(String(value || "").trim());
      }, 500)
    );
  };

  const columns = useMemo(() => {
    return [
      {
        field: "label",
        headerText: t("generalAttributes.label"),
        headerClassName: "name-column",
        template: (row) => (row?.details || {})[language],
      },
      {
        field: "name",
        headerText: t("generalAttributes.name"),
        headerClassName: "name-column",
      },
      {
        field: "type",
        headerText: t("projectAttributes.type.title"),
        headerClassName: "name-column",
        template: (row) => t("projectAttributes.type." + row.type),
      },
      {
        className: "action-cell text-right",
        headerClassName: "action-header-cell",
        headerText: " ",
        template: (row) => (
          <div>
            <IconButton
              size="small"
              color="primary"
              disabled={usedAttributes[row.name]}
              onClick={() => addAttribute({ ...row, general: true, id: undefined })}
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
          </div>
        ),
      },
    ];
  }, [usedAttributes]);

  const pagination = {
    labelRowsPerPage: t("pagination.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: processed?.length,
    page: page,
    show: processed?.length > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pageOnChange) => setPage(pageOnChange),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const paged = [...processed].splice(page * rowsPerPage, rowsPerPage);
  const loading = response.loading;

  return (
    <div className="general-attribute-dialog">
      <h2>
        {t("projectAttributes.dialog.generalTitle")}
        <IconButton onClick={onClose} size="small" className={"pull-right"}>
          <CloseOutlinedIcon />
        </IconButton>
      </h2>

      <div className="flex-row-filter search-filter-box">
        <div className="search-box flex-auto" onClick={() => inputRef.current.focus()}>
          <SearchIcon />
          <input
            key={resetState}
            type="text"
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            ref={inputRef}
            placeholder={t("main.search")}
            onChange={(event) => search(event.target.value)}
          />
        </div>
      </div>

      <LoadingOverlay spinner active={loading} className="auto-height">
        <SimpleTableWithSort
          key={loading}
          className="overflow-visible"
          tableClassName="modal-table"
          data={paged}
          columns={columns}
          pagination={pagination}
        />
      </LoadingOverlay>

      <div className="buttons text-right">
        <Button onClick={onClose}>{t("main.close")}</Button>
      </div>
    </div>
  );
};
