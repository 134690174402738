import { ProjectLocationField } from "../Project/ProjectFields/ProjectLocationField";

export const LocationFieldController = ({ control, name, required, label, readOnly, getValues }) => {
  return (
    <ProjectLocationField
      getValues={getValues}
      control={control}
      name={name}
      label={label}
      disabled={readOnly}
      required={required}
      objectAsValue
    />
  );
};
