import { MB, MEGA } from "../constants/fileUpload";
import { roundDecimal } from "../utils";

export const getFileSizeErrors = (event, maxSizeMB) => {
  const errors = [];
  [...event.target.files].forEach(file => {
    let fileSizeInMB = file.size / MEGA;
    if (fileSizeInMB > maxSizeMB) {
      errors.push({
        fileName: file.name,
        fileSize: roundDecimal(fileSizeInMB)
      });
    }
  });
  return errors.length ? errors : null;
};


export const getRestrictedFileSize = ({ maxFileSize }) => `${maxFileSize / MEGA}${MB}`;
export const getRestrictedFileExtension = ({ allowedExtensions }) => allowedExtensions.join(', ');

export const validateFileRestrictions = (file, restrictions) => { 
    if (!restrictions?.allowedExtensions.some(x => file?.name.endsWith(x))) { 
        return 'invalidFileExtension';
    }

    if (restrictions?.maxFileSize < file?.size) { 
        return 'invalidMaxFileSize';
    }
}

export const showKendoValidationErrors = ({ affectedFiles }, messages, showError) => {
    const fileWithError = affectedFiles?.find(x => !!x.validationErrors);
    if (fileWithError) { 
        const [ error ] = fileWithError.validationErrors;
        showError(messages[`upload.${error}`]);
    }
}