import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";
import { useTranslation } from "react-i18next";
import { AZURE_MAPS_SUBSCRIPTION_KEY, WAIT_TIME_TO_SEARCH_ADDRESS } from "../../../constants/azure";
import { azureAddressSearch } from "../../../hooks/project";
import { showError } from "../../../hooks/toast";
import { FormController } from "../../FormController/FormController";

const waitTime2SearchAddress = WAIT_TIME_TO_SEARCH_ADDRESS;
const azureSubscriptionKey = AZURE_MAPS_SUBSCRIPTION_KEY;

const createObjectValue = (isNewValueSet, selectedValue, locationInputValue, getValues) => ({
  location: isNewValueSet ? locationInputValue : getValues("location"),
  latitude: selectedValue ? selectedValue.latitude : getValues("latitude"),
  longitude: selectedValue ? selectedValue.longitude : getValues("longitude"),
  city: selectedValue ? selectedValue.city : getValues("city"),
});

export const ProjectLocationField = ({
  control,
  disabled,
  setFormFieldValue,
  required,
  name,
  label,
  objectAsValue,
  getValues,
}) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [locationInputValue, setLocationInputValue] = React.useState();
  const [isNewValueSet, newValueSet] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const [previousInputValue, setPreviousInputValue] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [userLang, setUserLang] = React.useState(i18n.language);

  const noLocationSet = t("project.noLocationSet");

  i18n.on("languageChanged", () => {
    setUserLang(i18n.language);
  });

  React.useEffect(() => {
    setLocationInputValue(getValues("location"));
    setSelectedValue({
      location: getValues("location"),
      latitude: getValues("latitude"),
      longitude: getValues("longitude"),
      city: getValues("city"),
    });
    setUserLang(i18n.language);
  }, []);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    if (locationInputValue?.length > 0 && locationInputValue !== previousInputValue) {
      const delayDebounceFn = setTimeout(() => {
        setPreviousInputValue(locationInputValue);
        load();
      }, waitTime2SearchAddress);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [locationInputValue]);

  const load = () => {
    setLoading(true);
    azureAddressSearch(azureSubscriptionKey, userLang, locationInputValue)
      .then(setupResponse)
      .catch((error) => {
        console.error(error);
        setLoading(false);
        showError(t("error.500"));
      });
  };

  const setupResponse = (apiResponse) => {
    apiResponse.json().then(setupResponseOptions);
  };

  const setupResponseOptions = (apiResponse) => {
    setOptions(
      apiResponse.results.map((m) => {
        return {
          location: getLocation(m.address),
          latitude: m.position.lat,
          longitude: m.position.lon,
          city: getCity(m.address),
        };
      })
    );
    setLoading(false);
  };

  const getCity = (addressParts) => {
    return addressParts.municipality || "";
  };

  /**
   * Formating in International format : Brückenstraße 16, 10179 Berlin, Germany (streetName streetNumber, postalCode municipality, country)
   * @param {*} addressParts
   * @returns
   */
  const getLocation = (addressParts) => {
    return (
      (addressParts.streetName ? addressParts.streetName + " " : "") +
      (addressParts.streetNumber ? addressParts.streetNumber + ", " : "") +
      (addressParts.postalCode ? addressParts.postalCode + " " : "") +
      (addressParts.municipality ? addressParts.municipality + ", " : "") +
      (addressParts.country || "")
    );
  };

  const updateValues = (onChange, autocompleteValue) => {
    if (objectAsValue) {
      onChange(
        autocompleteValue
          ? {
              location: autocompleteValue.location,
              latitude: String(autocompleteValue.latitude),
              longitude: String(autocompleteValue.longitude),
              city: String(autocompleteValue.city),
            }
          : null
      );
    } else {
      onChange(autocompleteValue ? autocompleteValue.location : noLocationSet);
    }
    setFormFieldValue && setFormFieldValue("latitude", autocompleteValue ? autocompleteValue.latitude : null);
    setFormFieldValue && setFormFieldValue("longitude", autocompleteValue ? autocompleteValue.longitude : null);
  };

  return (
    <FormController
      control={control}
      name={name || "location"}
      required={required}
      label={label || t("project.location")}
      render={({ field: { onChange, value }, fieldState, label: labelInner }) => (
        <Autocomplete
          id="location-autocomplete"
          value={
            objectAsValue ? value ?? "" : createObjectValue(isNewValueSet, selectedValue, locationInputValue, getValues)
          }
          sx={{ width: 300 }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onInputChange={(event, newInputValue) => {
            newValueSet(true);
            setLocationInputValue(newInputValue);
          }}
          onChange={(event, valueOnChange) => {
            updateValues(onChange, valueOnChange);
          }}
          onFocus={(event) => {
            event.target.value === noLocationSet
              ? setLocationInputValue("")
              : setLocationInputValue(event.target.value);
          }}
          filterOptions={(x) => x}
          getOptionLabel={(option) => (option.location ? option.location : "")}
          isOptionEqualToValue={() => true}
          options={options}
          loading={loading}
          lang={i18n.language}
          renderInput={(params) => (
            <TextField
              disabled={disabled}
              {...params}
              error={fieldState.invalid}
              label={labelInner + (required ? " *" : "")}
              InputProps={{
                ...params.InputProps,
                maxLength: 255,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            ></TextField>
          )}
        />
      )}
    />
  );
};
