import React from "react";
import { useTranslation } from "react-i18next";
import { setUserRolesPopover } from "../../../hooks/users";
import { processMessage } from "../../../utils";

export const UserRolesColumnTemplate = (props) => {
  const { t } = useTranslation();
  const roles = props?.roles || [];

  if (!props?.id) return null;

  return (
    <div
      className="user-roles-column"
      onMouseEnter={(event) => setUserRolesPopover({ data: props, target: event.currentTarget })}
      onMouseLeave={() => setUserRolesPopover(null)}
    >
      {processMessage(t("users.rolesAmount"), [roles.length])}
    </div>
  );
};
