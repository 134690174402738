import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_CLIENT } from "../../../constants/main";
import "./ProjectGroupAnalyzeMainBlock.scss";

export const ProjectGroupAnalyzeMainBlock = ({ data, collapsed }) => {
  const { t } = useTranslation();

  const { name, description, createdDateTime, ownerName, projectGroupLeadName } = data || {};

  return (
    <div className="project-group-analyze-main-block">
      <h2 className="block-header">{name}</h2>
      {!collapsed && <div className="description">{description}</div>}
      <br />
      <div className="group-data-container">
        {ownerName && (
          <div className="group-data-info">
            <b>{t("projectGroups.ownerName")}:</b>
            <span className="k-ml-2">{ownerName}</span>
          </div>
        )}
        {createdDateTime && (
          <div className="group-data-info">
            <b>{t("projectGroups.creationDate")}:</b>
            <span className="k-ml-2">{moment.utc(createdDateTime).local().format(DATE_FORMAT_CLIENT)}</span>
          </div>
        )}
        {projectGroupLeadName && (
          <div className="group-data-info">
            <b>{t("projectGroups.projectGroupLeaderName")}:</b>
            <span className="k-ml-2">{projectGroupLeadName}</span>
          </div>
        )}
      </div>
    </div>
  );
};
