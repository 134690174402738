import {
  setProjectAttributeDrag,
  setProjectAttributeDragStart,
  useProjectAttributeDrag,
  useProjectAttributeDragStart,
  useProjectAttributeLandings
} from "../../hooks/projectAttribute";
import { useEvent } from "../../hooks/utils/useEvent";
import "./ProjectAttributeDragOverlay.scss";
import { useEffect, useMemo, useRef } from "react";
import { ProjectAttributeItem } from "./ProjectAttributeItem/ProjectAttributeItem";
import { LEFT_COLUMN_PLACEHOLDER_ID, RIGHT_COLUMN_PLACEHOLDER_ID } from "../../constants/projectAttribute";

const LEFT_MENU_WIDTH = 100;
const LANDINGS_VERTICAL_GAP = 16;

export const ProjectAttributeDragOverlay = ({ onChangePosition }) => {
  const projectAttributeDragStart = useProjectAttributeDragStart();
  const projectAttributeDrag = useProjectAttributeDrag();
  const projectAttributeLandings = useProjectAttributeLandings();
  const ghostRef = useRef(null);

  const data = projectAttributeDragStart?.data;
  const startX = projectAttributeDragStart?.clientX;
  const startY = projectAttributeDragStart?.clientY;
  const currentX = projectAttributeDrag?.clientX;
  const currentY = projectAttributeDrag?.clientY;
  const triggerTarget = projectAttributeDragStart?.target;
  const dragTarget = triggerTarget?.parentNode?.parentNode;
  const left = currentX || startX;
  const top = currentY || startY;

  const landings = [...projectAttributeLandings];
  let minLeft, maxLeft;
  landings.forEach(item => {
    if (!minLeft || minLeft > item.left) {
      minLeft = item.left;
    }
    if (!maxLeft || maxLeft < item.left) {
      maxLeft = item.left;
    }
  });
  const leftLandings = landings.filter(item => item.left === minLeft);
  const rightLandings = landings.filter(item => item.left === maxLeft);
  const oppositeLandings = data?.details?.columnIndex ? leftLandings : rightLandings;
  const lastLanding = oppositeLandings[oppositeLandings.length - 1];
  if (lastLanding && lastLanding.uuid !== LEFT_COLUMN_PLACEHOLDER_ID && lastLanding.uuid !== RIGHT_COLUMN_PLACEHOLDER_ID) {
    landings.push({
      uuid: data?.details?.columnIndex ? LEFT_COLUMN_PLACEHOLDER_ID : RIGHT_COLUMN_PLACEHOLDER_ID,
      top: lastLanding.top + lastLanding.height + LANDINGS_VERTICAL_GAP,
      left: lastLanding.left,
      width: lastLanding.width,
      height: lastLanding.height
    })
  }

  const [targetWidth, targetHeight] = useMemo(() => {
    return [
      dragTarget?.offsetWidth,
      dragTarget?.offsetHeight
    ];
  }, [dragTarget]);

  const activeLanding = landings.find(({ left: leftInner, top: topInner, width, height }) => {
    const middleX = currentX + (targetWidth / 2) - LEFT_MENU_WIDTH;
    const middleY = currentY;
    return middleX > leftInner && middleY > topInner && middleX < (leftInner + width) && middleY < (topInner + height);
  });


  useEffect(() => {
    if (activeLanding && activeLanding.uuid !== data?.uuid) {
      onChangePosition && onChangePosition(data, activeLanding.uuid);
    }
  }, [activeLanding]);

  const onMouseMove = useEvent(event => {
    event.preventDefault();
    event.stopPropagation();
    const { clientX, clientY } = event;
    setProjectAttributeDrag({ clientX, clientY });
  });

  const onMouseUp = useEvent(event => {
    event.preventDefault();
    event.stopPropagation();
    setProjectAttributeDragStart(null);
    setProjectAttributeDrag(null);
  });

  const getActiveClassName = item =>
    ((!activeLanding && data?.uuid === item.uuid) || (activeLanding?.uuid === item.uuid) ? ' active' : '');

  return (
    <div className="project-attribute-drag-overlay" onMouseMove={onMouseMove} onMouseUp={onMouseUp}
         hidden={!projectAttributeDrag}>
      {landings.map(item => (
        <div
          key={item.uuid}
          className={'landing' + getActiveClassName(item)}
          style={{
            left: item.left + LEFT_MENU_WIDTH,
            top: item.top,
            width: item.width,
            height: item.height
          }}
        />
      ))}
      <div
        ref={ghostRef}
        className="ghost"
        style={{
          left: left,
          top: top,
          width: targetWidth,
          height: targetHeight
        }}>
        <ProjectAttributeItem data={data}/>
      </div>
    </div>
  );
}
