import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ESG_TYPES, OTHER_TYPES } from "../../../../constants/sustainabilitySystem";
import { TENANT_TYPE_ESG } from "../../../../constants/tenant";
import { getUserTenantType } from "../../../../hooks/auth";
import { forEachRecursive, mapRecursive, processMessage } from "../../../../utils";
import { CheckTree } from "../../../CheckTree/CheckTree";

export const getProjectTypeName = (name, types, t) => {
  let parts = [];
  const tenantType = getUserTenantType();
  const typesValue = types || (tenantType === TENANT_TYPE_ESG ? ESG_TYPES : OTHER_TYPES);

  const parentsByName = {};
  forEachRecursive(
    typesValue,
    (item) => {
      (item.items || []).forEach((child) => {
        parentsByName[child.name] = item.name;
      });
    },
    "items"
  );

  let tmp = name;
  while (tmp) {
    if (tmp) {
      parts.push(t("sustainabilitySystem.type." + tmp));
    }
    tmp = parentsByName[tmp];
  }

  return parts.reverse().join(" - ");
};

export const ProjectTypeSelect = ({ control, disabled, types, singleSelect }) => {
  const { t } = useTranslation();
  const tenantType = getUserTenantType();
  const [open, setOpen] = useState(false);
  const typesValue = types || (tenantType === TENANT_TYPE_ESG ? ESG_TYPES : OTHER_TYPES);

  return (
    <Controller
      control={control}
      name="projectTypes"
      rules={{ required: true }}
      render={({ field, formState, fieldState }) => {
        return (
          <FormControl fullWidth error={fieldState.invalid} className="project-type-select">
            <InputLabel htmlFor="sustainabilitySystemType">{t("sustainabilitySystem.type.title") + " *"}</InputLabel>
            <Select
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              fullWidth
              label={t("sustainabilitySystem.type.title") + " *"}
              {...field}
              disabled={disabled}
              id="sustainabilitySystemType"
              displayEmpty
              renderValue={(value) =>
                (Array.isArray(value) ? value : []).map((item) => getProjectTypeName(item, types, t)).join(", ")
              }
            >
              <MenuItem value={field.value} className="menu-item-close-button">
                <CloseIcon />
              </MenuItem>
              <CheckTree
                multiple={!singleSelect}
                lastLevelOnly={singleSelect}
                items={mapRecursive(
                  typesValue,
                  (item, children) => ({
                    ...item,
                    text: t("sustainabilitySystem.type." + item.name),
                    items: children,
                  }),
                  "items"
                )}
                values={field.value?.length ? (singleSelect ? field.value[0] : field.value) : []}
                onChange={(event) => {
                  field.onChange(event ? (singleSelect ? [event] : event) : []);
                  !!singleSelect && !!event && setOpen(false);
                }}
              />
            </Select>
            {formState.isSubmitted && fieldState.invalid && (
              <FormHelperText error>
                {processMessage(t("validation.fieldRequired"), [t("sustainabilitySystem.type.title")])}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
