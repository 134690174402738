import TimelineIcon from "@mui/icons-material/Timeline";
import Popover from "@mui/material/Popover";
import * as moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_CLIENT, DATE_FORMAT_SERVER } from "../../../constants/main";
import "./DashboardTimeline.scss";

export const DashboardTimeline = ({ data, revisionNumber, setRevisionNumber }) => {
  const { t } = useTranslation();
  const timeline = useMemo(
    () =>
      (data || [])
        .filter((item, index) => index < 25)
        .map((item) => {
          const analysisDate = item.analysisDate ? moment(item.analysisDate).format(DATE_FORMAT_SERVER) : null;
          const originalDate = moment(item.dateTime).format(DATE_FORMAT_SERVER);
          return {
            revisionNumber: item.revisionNumber,
            status: item.status,
            sortDate: analysisDate || originalDate,
            date: moment(analysisDate || originalDate).format(DATE_FORMAT_CLIENT),
            originalDate:
              analysisDate && analysisDate !== originalDate ? moment(originalDate).format(DATE_FORMAT_CLIENT) : null,
          };
        })
        .sort((a, b) => {
          if (a.sortDate > b.sortDate) {
            return 1;
          }
          if (a.sortDate < b.sortDate) {
            return -1;
          }
          return 0;
        }),
    [data]
  );

  const [popover, setPopover] = React.useState(null);

  const closePopover = () => setPopover(null);

  return (
    <div className="dashboard-timeline">
      <h2>
        <TimelineIcon />
        {t("dashboard.dashboardTitle")}
      </h2>
      <div
        className={
          "timeline-items" + (timeline.length === 1 ? " one-item" : "") + (timeline.length >= 15 ? " too-many" : "")
        }
      >
        {timeline.length === 2 && <div key={-1} className="timeline-item" />}
        {timeline.map((item, index) => (
          <div key={index} className="timeline-item" onClick={() => setRevisionNumber(item.revisionNumber)}>
            <div
              className={
                "timeline-dot status-" + item.status + (revisionNumber === item.revisionNumber ? " active" : "")
              }
              onMouseEnter={(event) => setPopover({ target: event.currentTarget, data: item })}
              onMouseLeave={closePopover}
            >
              <div className="timeline-circle" />
              <div className="timeline-text">{item.date}</div>
            </div>
          </div>
        ))}
      </div>
      <Popover
        className="tooltip-popover dashboard-timeline-tooltip-popover"
        open={!!popover && popover?.data?.originalDate}
        anchorEl={popover?.target}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={closePopover}
        disableRestoreFocus
      >
        <div>
          <div className="muted nowrap">{t("dashboard.originalAnalysisDate")}:</div>
          {popover?.data?.originalDate}
        </div>
      </Popover>
    </div>
  );
};
