import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useElementSize } from "usehooks-ts";
import "./ChartBase.scss";

export const ChartBase = React.memo(
  ({
    className,
    children,
    pageLimit,
    xRange,
    yRange,
    xLabel,
    yLabel,
    renderMethod,
    values,
    heightInPoints,
    rightContent,
    topContent,
    footerContent,
    xNumeric,
    forwardedRef,
    xFieldName,
    focusLast = true,
  }) => {
    const { i18n } = useTranslation();
    const [start, setStart] = useState(0);
    const canvasRef = useRef(null);
    const [resizeRef, { width, height }] = useElementSize();

    values = values || [];
    xRange = xRange || [];
    yRange = yRange || [];
    xLabel = xLabel === false ? null : xLabel || "X";
    yLabel = yLabel === false ? null : yLabel || "Y";
    heightInPoints = heightInPoints || 1;

    let limitedXRange = [];
    if (pageLimit && xRange.length > pageLimit) {
      for (let i = 0, index = start; i < pageLimit; i++, index++) {
        limitedXRange.push(xRange[index]);
      }
    } else {
      limitedXRange = xRange;
    }

    const lastPage = xRange.length - pageLimit;
    let lastPageWithValue = lastPage;
    let lastX = null;
    if (xFieldName) {
      const lastValue = (values?.values || [])
        .toSorted((a, b) => {
          if (a[xFieldName] > b[xFieldName]) {
            return focusLast ? -1 : 1;
          }
          if (a[xFieldName] < b[xFieldName]) {
            return focusLast ? 1 : -1;
          }
          return 0;
        })
        .find((x) => x?.value != null);
      if (lastValue) {
        lastX = lastValue[xFieldName];
        lastPageWithValue = Math.max(0, xRange.indexOf(lastX) - pageLimit + 1);
      }
    }
    if (lastPageWithValue < 0) {
      lastPageWithValue = 0;
    }

    useEffect(() => {
      const finalPage = lastX ? lastPageWithValue : lastPage;
      if (xRange.length && xRange.length > finalPage) {
        setStart(finalPage);
      }
    }, [xRange, values]);

    useEffect(() => {
      const canvas = canvasRef?.current;
      if (canvas && width && height) {
        canvas.width = width;
        canvas.height = height;
        const props = {
          canvas,
          width,
          height,
          heightInPoints,
          values,
          xRange,
          yRange,
          limitedXRange,
          start,
        };
        !!renderMethod && renderMethod(props);
      }
    }, [!!canvasRef?.current, width, height, start, values]);

    const prevPage = () => {
      let updated = start - 1;
      updated = updated < 0 ? 0 : updated;
      setStart(updated);
    };

    const nextPage = () => {
      let updated = start + 1;
      updated = updated > lastPage ? lastPage : updated;
      setStart(updated);
    };

    return (
      <div ref={forwardedRef} className={"chart-base" + (className ? " " + className : "")}>
        <div className="top-part">{topContent}</div>
        <div className="middle-part flex-row">
          <div className="left-part">
            <div className="y-label">
              <div className="rotated-label muted-label">{yLabel}</div>
            </div>
            <div className="y-range">
              {[...yRange].reverse().map((item, index) => (
                <div className="y-range-item" key={index}>
                  <div className="range-item-value">{typeof item === "object" ? item.label : item}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="center-part flex-auto">
            <div className="chart" ref={resizeRef}>
              <canvas ref={canvasRef} />
              {children}
            </div>
            <div className="x-range-part">
              <div className={"x-range flex-row" + (xNumeric ? " numeric-range" : "")}>
                {limitedXRange.map((item, index) => (
                  <div className="x-range-item k-flex-1 text-hyphens" lang={i18n.language} key={index}>
                    <span className="range-item-value">{typeof item === "object" ? item.label : item}</span>
                  </div>
                ))}
              </div>
              {!!pageLimit && pageLimit < xRange.length && (
                <div className="navigation-buttons">
                  <IconButton size="small" className="pull-left" disabled={start === 0} onClick={prevPage}>
                    <ChevronLeftIcon />
                  </IconButton>
                  <IconButton size="small" className="pull-right" disabled={start >= lastPage} onClick={nextPage}>
                    <ChevronRightIcon />
                  </IconButton>
                </div>
              )}
              <div className="x-label muted-label">{xLabel}</div>
            </div>
          </div>
          {!!rightContent && <div className="right-part">{rightContent}</div>}
        </div>
        {footerContent}
      </div>
    );
  }
);
