import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { processMessage } from "../../../utils";
import "./SystemSource.scss";

export const SystemSource = ({ systemSource, control, editDisabled }) => {
  const { t } = useTranslation();
  return (
    <>
      {editDisabled && <h2 className="page-title">{systemSource}</h2>}
      {!editDisabled && (
        <div className="sustainability-system-source">
          <Controller
            control={control}
            name="systemSource"
            rules={{ required: true }}
            render={({ field, formState, fieldState }) => (
              <>
                <TextField
                  error={fieldState.invalid}
                  fullWidth
                  label={t("sustainabilitySystem.systemSource") + " *"}
                  {...field}
                  disabled={editDisabled}
                  inputProps={{ maxLength: 255 }}
                />
                {formState.isSubmitted && fieldState.invalid && (
                  <FormHelperText error>
                    {fieldState.error.type === "required" &&
                      processMessage(t("validation.fieldRequired"), [t("sustainabilitySystem.systemSource")])}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </div>
      )}
    </>
  );
};
