import React from "react";
import "./WidgetWrapper.scss";
import { LoadingOverlay } from "../../../LoadingOverlay/LoadingOverlay";

export const WidgetWrapper = ({title, subTitle, data, loading}) => {

  return (
    <LoadingOverlay spinner active={loading} className="widget-wrapper">
        <h1>{title}</h1>
        <p style={{ fontWeight: '300' }}>{subTitle}</p>
        <div className="flex-row">
          {data.map((d, i) => <div className="flex-column" style={{ color: d.color || '' }} key={i}>
            <h2>{d.value}</h2>
            <p>{d.title}</p>
            {d.bottomLine && <div className="bottom-line" style={{ background: d.bottomLine || "transparent" }} />}
          </div>)}
        </div>
    </LoadingOverlay>
  );
};