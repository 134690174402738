import TextField from "@mui/material/TextField";
import React from "react";
import "./ResponsibleIndicator.scss";

export const ResponsibleIndicator = ({ value, onChange, onBlur, readOnly }) => {
  return (
    <div className="responsible">
      <TextField
        fullWidth
        value={value || ""}
        onBlur={onBlur}
        onChange={(event) => onChange(event.target.value)}
        multiline
        disabled={readOnly}
        inputProps={{ maxLength: 255 }}
      />
    </div>
  );
};
