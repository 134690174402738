export const DOC_NODE_NAME = 'doc';
export const DIV_NODE_NAME = 'div';
export const IMAGE_NODE_NAME = 'image';
export const PARAGRAPH_NODE_NAME = 'paragraph';
export const BULLET_LIST_NODE_NAME = 'bullet_list';
export const ORDERED_LIST_NODE_NAME = 'ordered_list';
export const LIST_ITEM_NODE_NAME = 'list_item';
export const IMAGE_GRID_NODE_NAME = 'imagegrid';
export const IMAGE_GRID_CELL_NODE_NAME = 'imagegridcell';
export const IMAGE_CONTAINER_NODE_NAME = 'imagecontainer';

export const RICH_TEXT_STYLE = `
    p, h1, h2 { 
        font-family: Calibri;
    }

    img { 
        position: relative;
    }

    img.drag-hover { 
        outline: 2px dashed red !important;
        z-index: 3;
    }

    .axi { 
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        position: absolute;
        background-color: white;
        display: none;
        border-radius: 100%;
        border: 2px solid black;
    }

    .k-content .ProseMirror-selectednode { 
        z-index: 1;
    }

    .axi.active { 
        display: block;
    }
    
    .resize-north-axi { 
        cursor: n-resize;
    }

    .resize-east-axi { 
        cursor: e-resize;
    }

    .resize-south-axi {
        cursor: s-resize;
    }

    .resize-west-axi {
        cursor: w-resize;
    }
`;
export const RICH_TEXT_EDITOR_HEIGHT = 400
