import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KPIControl } from "../../KPIControl/KPIControl";

export const PotentialBlock = ({ onChange, row, readOnly }) => {
  const { t } = useTranslation();
  const data = row || {};
  const { UID } = data;
  const [kpi, setKpi] = useState(null);

  useEffect(() => {
    const { kpi: kpiInner } = (data || {});
    setKpi(kpiInner);
  }, [UID]);

  const saveChanges = (updatedKpi) => onChange({ UID, fields: { kpi: updatedKpi || kpi } });

  const onKpiChange = ({ value }) => {
    const updated = { ...kpi, potentialValue: value };
    setKpi(updated);
    saveChanges(updated);
  };

  return (
    <div className="padding-bottom">
      <h4 className="header no-margin">{t('ratingSystem.columnGroup.kpi')}</h4>
      <KPIControl
        kpiUnit={kpi?.unit} kpiName={kpi?.name} value={kpi?.potentialValue} noEstimated onChange={onKpiChange}
        readOnly={readOnly} linkedGeneralAttributeName={kpi?.linkedGeneralAttributeName}
      />
    </div>
  )
};
