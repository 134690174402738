import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { FormController } from "../FormController/FormController";
import "./IndicatorValueInput.scss";

const CUSTOM_VALUE = "CUSTOM";

export const IndicatorValueInput = ({
  allowCustomValue,
  valueRangeList,
  title,
  control,
  name,
  required,
  onSelect,
  onBlur,
  disabled,
  onInputChange,
  enableCustomValueOption,
  customValueTag,
  maxCustomValue,
  value,
}) => {
  const { t } = useTranslation();
  const [showCustomValue, setShowCustomValue] = useState(value === CUSTOM_VALUE);
  const customValue = valueRangeList.find((item) => item?.custom && item?.customValueTag === customValueTag);
  const [customFieldValue, setCustomFieldValue] = useState(customValue?.value ?? "");
  const [customSystemScoreValue, setCustomSystemScoreValue] = useState(customValue?.systemScore ?? "");

  useEffect(() => {
    const customValueInner = valueRangeList.find((item) => item?.custom && item?.customValueTag === customValueTag);
    setCustomFieldValue(customValueInner?.value ?? "");
    setCustomSystemScoreValue(customValueInner?.systemScore ?? "");
  }, [valueRangeList]);

  useEffect(() => {
    setShowCustomValue(value === CUSTOM_VALUE);
  }, [value]);

  const scoreByValue = {};
  (valueRangeList || []).forEach((item) => {
    scoreByValue[item.value] = item.systemScore;
  });

  const onValueSelect = (value, field) => {
    field.onChange(value);
    if (value !== CUSTOM_VALUE) {
      !!onSelect && onSelect(value, scoreByValue[value]);
      setShowCustomValue(false);
    } else {
      setShowCustomValue(true);
      setCustomFieldValue(customValue?.value ?? "");
      setCustomSystemScoreValue(customValue?.systemScore ?? "");
      onCustomFieldsBlur();
    }
  };

  const onCustomValueChange = (event) => setCustomFieldValue(event.target.value);

  const onCustomSystemScoreChange = (event) => {
    const value =
      event.target.value != null && event.target.value !== "" && !Number.isNaN(event.target.value)
        ? Number(event.target.value)
        : "";
    setCustomSystemScoreValue(value !== "" ? Math.min(Math.max(-maxCustomValue, value), maxCustomValue) : value);
  };

  const onCustomFieldsBlur = () => {
    if (customFieldValue !== "" && customSystemScoreValue !== "") {
      !!onSelect && onSelect(customFieldValue, customSystemScoreValue, true);
    }
  };

  return (
    <div className="indicator-value-input">
      <FormController
        control={control}
        name={name}
        required={required}
        label={title}
        render={({ field, fieldState, label }) => (
          <FormControl error={fieldState.invalid} fullWidth>
            {allowCustomValue && (
              <Autocomplete
                {...field}
                fullWidth
                clearOnEscape
                noOptionsText={t("main.empty")}
                options={(valueRangeList || []).map((item) => item?.value)}
                clearOnBlur={!allowCustomValue}
                freeSolo={allowCustomValue}
                value={field.value || ""}
                onInputChange={onInputChange}
                disabled={disabled}
                onBlur={(event) => {
                  if (allowCustomValue) {
                    field.onChange(event.target.value);
                  }
                  !!onBlur && onBlur(event);
                }}
                onChange={(event, value) => {
                  onValueSelect(value, field);
                  !!onBlur && onBlur(event);
                }}
                renderInput={(params) => <TextField {...params} label={label + (required ? " *" : "")} />}
                renderTags={({ key, id }) => (
                  <Typography noWrap key={id}>
                    {key}
                    {scoreByValue[key] ? " : " : ""}
                    {scoreByValue[key] || ""}
                  </Typography>
                )}
              />
            )}
            {!allowCustomValue && (
              <>
                <InputLabel htmlFor={"selectControl_" + name}>{label + (required ? " *" : "")}</InputLabel>
                <Select
                  {...field}
                  fullWidth
                  value={field.value}
                  disabled={disabled}
                  label={label + (required ? " *" : "")}
                  id={"selectControl_" + name}
                  onChange={(event) => {
                    onValueSelect(event.target.value, field);
                    !!onBlur && onBlur(event);
                  }}
                >
                  <MenuItem value="" key={-1}>
                    {t("main.unselected")}
                  </MenuItem>
                  {(valueRangeList || [])
                    .filter((item) => !item?.custom)
                    .map((option, key) => (
                      <MenuItem value={option?.value} key={getUUID()}>
                        {option?.value} : {scoreByValue[option?.value] !== undefined ? scoreByValue[option?.value] : ""}
                      </MenuItem>
                    ))}
                  {enableCustomValueOption && (
                    <MenuItem value={CUSTOM_VALUE} key={-3}>
                      {t("grouping.valueType.customValueOption")}
                    </MenuItem>
                  )}
                </Select>
              </>
            )}
          </FormControl>
        )}
      />
      {showCustomValue && (
        <div className="custom-value-div">
          <TextField
            className="custom-value-input"
            value={customFieldValue}
            disabled={disabled || !enableCustomValueOption}
            label={t("grouping.valueType.valueRangeShort")}
            onChange={onCustomValueChange}
            onBlur={onCustomFieldsBlur}
          />
          <TextField
            className="custom-system-score-input"
            value={customSystemScoreValue}
            disabled={disabled || !enableCustomValueOption}
            type="number"
            label={t("grouping.valueType.systemScore")}
            onChange={onCustomSystemScoreChange}
            onBlur={onCustomFieldsBlur}
          />
        </div>
      )}
    </div>
  );
};
