import React from "react";
import { SelectMigrationTargetDialog } from "../../SelectMigrationTargetDialog/SelectMigrationTargetDialog";
import { showError, showSuccess } from "../../../hooks/toast";
import { useTranslation } from "react-i18next";
import { migrateBenchmark, useBenchmarkMigrationResponse } from "../../../hooks/benchmark";
import { useUser } from "../../../hooks/auth";
import { getUserAvailableTenantsForMigration } from "../../../utils";

export const BenchmarkCopyToTenantDialog = React.memo(({ benchmarkId, ...props }) => {
  const { t } = useTranslation();
  const user = useUser();
  const availableTenants = getUserAvailableTenantsForMigration(user);
  const benchmarkMigrationResponse = useBenchmarkMigrationResponse();

  return (
    <SelectMigrationTargetDialog
      title={t('benchmark.migrationDialogHeader')}
      onSelect={tenantId => {
        migrateBenchmark(benchmarkId, tenantId)
          .then(() => showSuccess(t('benchmark.benchmarkMigrated')))
          .catch(error => {
            showError(t('error.500'));
            console.error(error);
          });
      }}
      migrationTargets={availableTenants}
      loading={benchmarkMigrationResponse.loading}
      {...props}
    />
  );
});
