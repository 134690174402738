import CloseIcon from "@mui/icons-material/Close";
import { DialogActions, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DATA_SOURCE_KPI,
  DATA_SOURCE_PROJECT,
  DECISION_CASE_EMPTY_VALUE,
  DECISION_CASE_EXISTING_VALUE,
  DECISION_CASE_REPLACE_ATTRIBUTE,
  KPI_DECISION_ACCEPT,
  KPI_DECISION_REJECT,
} from "../../constants/project";
import { KPI_STATUS_ACTIVE, KPI_STATUS_CHANGED } from "../../constants/ratingSystem";
import {
  activateProjectKpiLink,
  getProjectFields,
  projectFieldsKPIDecisionsSubject,
  setProjectFieldsKPIDecisions,
  useProjectFieldsKPIDecisions,
  useProjectKpiLinkActivateResponse,
} from "../../hooks/project";
import { useEvent } from "../../hooks/utils/useEvent";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import { DecisionSection } from "./DecisionSection";

export const KPIProjectFieldsDecisions = memo(({ projectId, linkedKpis, disabled, onFinish }) => {
  const { t } = useTranslation();
  const projectFieldsDecisions = useProjectFieldsKPIDecisions();
  const projectKpiLinkActivateResponse = useProjectKpiLinkActivateResponse();
  const [open, setOpen] = useState(false);

  const onClose = useEvent(() => {
    onFinish && onFinish();
    setOpen(false);
  });

  const makeDecision = useEvent((foundIndex, decision) => {
    const decisionData = projectFieldsKPIDecisionsSubject.getValue().find((item, index) => index === foundIndex);
    if (decisionData) {
      return activateProjectKpiLink(
        projectId,
        decisionData.kpi.id,
        decision === KPI_DECISION_ACCEPT ? DATA_SOURCE_PROJECT : DATA_SOURCE_KPI
      ).then(() => {
        setProjectFieldsKPIDecisions(
          projectFieldsKPIDecisionsSubject.getValue().map((item, index) => {
            if (index === foundIndex) {
              return { ...item, decision };
            } else {
              return item;
            }
          })
        );
      });
    }
  });

  const onAccept = useEvent((nameAndUnit) => {
    const foundIndex = projectFieldsKPIDecisionsSubject
      .getValue()
      .findIndex(({ kpi }) => kpi.nameAndUnit === nameAndUnit);
    if (foundIndex !== -1) {
      return makeDecision(foundIndex, KPI_DECISION_ACCEPT);
    }
    return null;
  });

  const onReject = useEvent((nameAndUnit) => {
    const foundIndex = projectFieldsKPIDecisionsSubject
      .getValue()
      .findIndex(({ kpi }) => kpi.nameAndUnit === nameAndUnit);
    if (foundIndex !== -1) {
      return makeDecision(foundIndex, KPI_DECISION_REJECT);
    }
    return null;
  });

  const caseEmptyValueItems = projectFieldsDecisions.filter((item) => item.case === DECISION_CASE_EMPTY_VALUE);
  const caseExistingValueItems = projectFieldsDecisions.filter((item) => item.case === DECISION_CASE_EXISTING_VALUE);
  const caseReplaceAttributeItems = projectFieldsDecisions.filter(
    (item) => item.case === DECISION_CASE_REPLACE_ATTRIBUTE
  );
  const notDecidedItems = projectFieldsDecisions.filter((item) => !item.decision);

  const loading = projectKpiLinkActivateResponse.loading;

  useEffect(() => {
    setProjectFieldsKPIDecisions([]);
    if (projectId && linkedKpis?.length && !disabled) {
      getProjectFields(projectId)
        .then((fields) => {
          const decisions = linkedKpis.map((kpi) => {
            const field = fields.find(
              (item) => item.field.name === kpi?.linkedGeneralAttributeName && kpi.linkStatus !== KPI_STATUS_ACTIVE
            );
            return {
              kpi,
              field,
              decision: null,
              case: !kpi.currentValue
                ? DECISION_CASE_EMPTY_VALUE
                : kpi.linkStatus === KPI_STATUS_CHANGED
                ? DECISION_CASE_REPLACE_ATTRIBUTE
                : DECISION_CASE_EXISTING_VALUE,
            };
          });
          setProjectFieldsKPIDecisions(decisions);
        })
        .catch(console.error);
    }
  }, [projectId, linkedKpis, disabled]);

  useEffect(() => {
    const accept = async (arg) => onAccept(arg);

    if (caseEmptyValueItems.length) {
      for (let i = 0; i < caseEmptyValueItems.length; i++) {
        accept(caseEmptyValueItems[i].kpi.nameAndUnit).catch(console.error);
      }
    }
  }, [caseEmptyValueItems.length]);

  useEffect(() => {
    setOpen(!disabled && !!projectFieldsDecisions.length);
  }, [projectFieldsDecisions.length, disabled]);

  const onDialogClose = useEvent((event, reason) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  });

  return (
    <Dialog
      onClose={onDialogClose}
      open={open}
      maxWidth="md"
      fullWidth={true}
      className="kpi-project-fields-decisions"
      disableEscapeKeyDown
    >
      <LoadingOverlay
        spinner
        active={loading}
        className="select-migration-tenant-dialog auto-height fit-dialog-content"
      >
        <DialogTitle>
          {t("kpi.decisions.title")}
          <IconButton className="close-button" size="small" onClick={onClose} disabled={!!notDecidedItems.length}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!!caseEmptyValueItems.length && (
            <DecisionSection title={t("kpi.decisions.sectionEmptyValue")} rows={caseEmptyValueItems} />
          )}
          {!!caseExistingValueItems.length && (
            <DecisionSection
              title={t("kpi.decisions.sectionExistingValue")}
              rows={caseExistingValueItems}
              onAccept={onAccept}
              onReject={onReject}
            />
          )}
          {!!caseReplaceAttributeItems.length && (
            <DecisionSection
              title={t("kpi.decisions.sectionReplaceAttribute")}
              rows={caseReplaceAttributeItems}
              onAccept={onAccept}
              onReject={onReject}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={!!notDecidedItems.length}>
            {t("main.close")}
          </Button>
        </DialogActions>
      </LoadingOverlay>
    </Dialog>
  );
});
