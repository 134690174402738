import Button from "@mui/material/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import "./FilterBlocks.scss";

export const FilterBlocks = ({ filterOptions, value, onChange, allowDeselect, showReset, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className={"actions-filter filter-blocks" + (disabled ? " disabled" : "")}>
      {filterOptions.map((item, index) => {
        return (
          <div
            key={index}
            className={
              "filter-block " +
              item.name +
              (item.name === value ? " active" : "") +
              (allowDeselect ? " deselectable" : "")
            }
            onClick={!disabled ? () => onChange(allowDeselect && item.name === value ? null : item.name) : null}
          >
            <div className="block-content">
              <div className="amount">{item.amount}</div>
              <h3>{item.title}</h3>
            </div>
            <div className="line" />
          </div>
        );
      })}
      {!!showReset && (
        <div key={-1} className={"reset-button"}>
          <Button color="primary" onClick={() => onChange(null)} disabled={disabled}>
            {t("main.reset")}
          </Button>
        </div>
      )}
    </div>
  );
};
