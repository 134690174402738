import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MenuItem, Popover } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../constants/main";
import { ATTRIBUTE_STATUS_ACTIVE, ATTRIBUTE_STATUS_INACTIVE } from "../../constants/projectAttribute";
import { useGeneralProjectAttributePageLogic } from "../../hooks/projectAttribute";
import { processMessage } from "../../utils";
import { SimpleTableWithSort } from "../SimpleTableWithSort/SimpleTableWithSort";
import { AttributeStatusFilter } from "./AttributeStatusFilter/AttributeStatusFilter";

export const GeneralAttributesTable = ({ data, loading, onEdit, onDeactivate, onActivate, onClear }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const { searchValue, sortValue, onSort, page, rowsPerPage, setPage, setRowsPerPage, setFilterValue, filterValue } =
    useGeneralProjectAttributePageLogic();

  const [processed, setProcessed] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [popoverTarget, setPopoverTarget] = useState(null);

  useEffect(() => {
    if (!loading) {
      let updated = [...data].map((item) => ({ ...item, label: (item.details || {})[language] }));
      // search
      const searchQuery = String(searchValue).trim().toLowerCase();
      if (searchQuery) {
        updated = updated.filter((item) => {
          return (
            String(item.name).toLowerCase().indexOf(searchQuery) !== -1 ||
            String((item.details || {})[language])
              .toLowerCase()
              .indexOf(searchQuery) !== -1 ||
            String(t("projectAttributes.type." + item.type))
              .toLowerCase()
              .indexOf(searchQuery) !== -1
          );
        });
      }
      setFiltered(updated);
      if (filterValue) {
        updated = updated.filter((item) => item.status === filterValue);
      }
      // sort
      updated.sort((a, b) => {
        if (sortValue.field) {
          if (a[sortValue.field] > b[sortValue.field]) {
            return sortValue.desc ? -1 : 1;
          }
          if (a[sortValue.field] < b[sortValue.field]) {
            return sortValue.desc ? 1 : -1;
          }
        }
        return 0;
      });
      setProcessed(updated);
      if (page * rowsPerPage > updated.length) {
        setPage(Math.floor(updated.length / rowsPerPage));
      }
    }
  }, [loading, searchValue, sortValue, filterValue, page, rowsPerPage, data]);

  const pagination = {
    labelRowsPerPage: t("pagination.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: processed.length,
    page: page,
    show: processed.length > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pageInner) => setPage(pageInner),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const columns = [
    {
      field: "name",
      headerText: t("generalAttributes.name"),
      headerClassName: "name-column",
      sortable: true,
    },
    {
      field: "label",
      headerText: t("generalAttributes.label"),
      headerClassName: "company-name-column",
      sortable: true,
    },
    {
      field: "type",
      headerText: t("generalAttributes.type"),
      headerClassName: "type-column",
      sortable: true,
      template: (row) => t("projectAttributes.type." + row.type),
    },
    {
      field: "status",
      headerText: t("generalAttributes.status.title"),
      headerClassName: "type-column",
      sortable: true,
      template: (row) => t("generalAttributes.status." + row.status),
    },
    {
      field: "actions",
      headerClassName: "actions-column",
      className: "text-right",
      template: (row) => (
        <IconButton
          onClick={(e) => setPopoverTarget({ target: e.target, row: { ...row, label: undefined } })}
          size="small"
        >
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  const paged = [...processed].splice(page * rowsPerPage, rowsPerPage);

  return (
    <>
      <AttributeStatusFilter value={filterValue} onChange={setFilterValue} values={filtered} />
      <SimpleTableWithSort
        className="general-attributes-table"
        data={paged}
        sortValue={sortValue}
        onSort={onSort}
        pagination={pagination}
        columns={columns}
      />
      <Popover
        open={!!popoverTarget}
        anchorEl={popoverTarget?.target}
        onClose={() => setPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            onEdit(popoverTarget?.row);
            setPopoverTarget(null);
          }}
        >
          {t("generalAttributes.edit")}
        </MenuItem>
        {(!filterValue || filterValue === ATTRIBUTE_STATUS_INACTIVE) && (
          <MenuItem
            disabled={popoverTarget?.row?.status === ATTRIBUTE_STATUS_ACTIVE}
            onClick={() => {
              !!onActivate && onActivate({ ...popoverTarget?.row, status: ATTRIBUTE_STATUS_ACTIVE });
              setPopoverTarget(null);
            }}
          >
            {t("generalAttributes.activate")}
          </MenuItem>
        )}
        {(!filterValue || filterValue === ATTRIBUTE_STATUS_ACTIVE) && (
          <MenuItem
            disabled={popoverTarget?.row?.status === ATTRIBUTE_STATUS_INACTIVE}
            onClick={() => {
              !!onDeactivate && onDeactivate({ ...popoverTarget?.row, status: ATTRIBUTE_STATUS_INACTIVE });
              setPopoverTarget(null);
            }}
          >
            {t("generalAttributes.deactivate")}
          </MenuItem>
        )}
        {filterValue === ATTRIBUTE_STATUS_INACTIVE && (
          <MenuItem
            onClick={() => {
              !!onClear && onClear(popoverTarget?.row);
              setPopoverTarget(null);
            }}
          >
            {t("generalAttributes.clear")}
          </MenuItem>
        )}
      </Popover>
    </>
  );
};
