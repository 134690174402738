import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_PAST_SUSTAINABILITY_SYSTEM_MAX_SYSTEM_SCORE,
  DEFAULT_PAST_SUSTAINABILITY_SYSTEM_WEIGHTING_FACTOR,
} from "../../../constants/ratingSystem";
import {
  GROUPING_TYPE_CATEGORY,
  GROUPING_TYPE_CRITERION,
  GROUPING_TYPE_INDICATOR,
} from "../../../constants/sustainabilitySystem";
import { expandRowCondition } from "../../../helpers/advancedTable";
import { copyElement, matchDropLogic, sortGroupings } from "../../../helpers/sustainabilitySystem";
import { showDialog } from "../../../hooks/dialog";
import { useEvent } from "../../../hooks/utils/useEvent";
import { GroupingElement } from "../../../models/GroupingElement";
import { CategorySVG, IndicatorSVG } from "../../../svg";
import { findRecursive, forEachRecursive } from "../../../utils";
import {
  conditionIsIndicator,
  conditionNotCategory,
  conditionNotIndicator,
} from "../../../validation/sustainabilitySystem";
import { AdvancedTable } from "../../AdvancedTable/AdvancedTable";
import { COLUMN_TYPE_CHECKBOX, COLUMN_TYPE_NUMBER, COLUMN_TYPE_TEXT } from "../../AdvancedTable/AdvancedTableCell";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import { IndicatorDataDialogPAST } from "../../IndicatorDataDialog/IndicatorDataDialogPAST";
import { SearchBadge } from "../../SearchBadge/SearchBadge";
import { AddMenuPopover } from "./AddMenuPopover";
import "./SustainabilitySystemGrouping.scss";

export const SustainabilitySystemGroupingPAST = React.memo((props) => {
  const { values, onChange, readOnly, submitted, errors, errorMessage } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState({});
  const [addPopoverTarget, setAddPopoverTarget] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const showIndicatorDialog = (row, passThroughShowIndicator) => {
    const {
      readOnly: readOnlyShowIndicator,
      changeEvaluationStandard: changeEvaluationStandardShowIndicator,
      submitted: submittedShowIndicator,
    } = passThroughShowIndicator || {};
    showDialog({
      title: t("grouping.evaluationStandard"),
      className: "large",
      closeOnClickOutside: !!readOnlyShowIndicator,
      getContent: (onClose) => (
        <IndicatorDataDialogPAST
          row={row}
          onClose={onClose}
          onChange={changeEvaluationStandardShowIndicator}
          readOnly={readOnlyShowIndicator}
          submitted={submittedShowIndicator}
        />
      ),
    });
  };

  const columns = useMemo(
    () => [
      {
        field: "systemReference",
        headerText: t("grouping.systemReference"),
        type: COLUMN_TYPE_TEXT,
        width: 120,
        minWidth: 120,
        maxLength: 255,
        search: true,
      },
      {
        field: "name",
        headerText: t("grouping.groupingTitle"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionNotIndicator,
        getIcon: (row) => (row.type === GROUPING_TYPE_CATEGORY ? <CategorySVG /> : null),
        groupingColumn: true,
        minWidth: 200,
        width: 335,
        maxLength: 255,
        search: true,
      },
      {
        field: "indicatorName",
        headerText: t("grouping.indicatorName"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionIsIndicator,
        startAdornment: <IndicatorSVG />,
        getEndAdornment: (row, passThroughEndAdornment) => (
          <IconButton
            color={
              (passThroughEndAdornment || {}).submitted && (row.error || {}).field === "evaluationStandard"
                ? "secondary"
                : undefined
            }
            onClick={() => showIndicatorDialog(row, passThroughEndAdornment)}
            className={"no-padding"}
          >
            <DescriptionOutlinedIcon />
          </IconButton>
        ),
        minWidth: 200,
        width: 335,
        maxLength: 255,
        search: true,
      },
      {
        field: "weightingFactor",
        headerText: t("grouping.pastWeight"),
        required: true,
        type: COLUMN_TYPE_NUMBER,
        step: 0.0001,
        width: 150,
        minWidth: 150,
      },
      {
        field: "koValue",
        headerText: t("grouping.koValue"),
        required: false,
        type: COLUMN_TYPE_NUMBER,
        showCondition: conditionIsIndicator,
        allowEmpty: true,
        step: 1,
        min: 0,
        max: 999,
        width: 110,
        minWidth: 110,
      },
      {
        field: "excludable",
        type: COLUMN_TYPE_CHECKBOX,
        headerText: t("grouping.excludable"),
        showCondition: conditionNotCategory,
        editableCondition: conditionNotCategory,
        width: 185,
        minWidth: 175,
        onChange: (UID, fieldName, value, { getValues: getValuesExcludable }) => {
          if (value) {
            const valuesExcludable = getValuesExcludable();
            const toSelectByUID = {};
            toSelectByUID[UID] = true;
            const updated = [...valuesExcludable];
            let amount = 0;
            forEachRecursive(updated, (node) => {
              if (toSelectByUID[node.UID]) {
                if (!node.koValue) {
                  node.excludable = true;
                }
                node.children?.forEach((item) => {
                  toSelectByUID[item.UID] = true;
                  amount++;
                });
              }
            });
            !!amount && onChange(updated);
          }
        },
      },
    ],
    []
  );

  const searched = columns.filter((item) => !!item.search && search[item.field]);
  const errorsByPath = {};
  errors.forEach((item) => {
    const path = item.path.join("_");
    if (!errorsByPath[path]) {
      errorsByPath[path] = item;
    }
  });

  const changeEvaluationStandard = useEvent(({ UID, standard }) => {
    const updated = [...values];
    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        node.evaluationStandard = standard;
        return true;
      }
    });
    onChange(updated);
  });

  const getValues = useEvent(() => values);

  const addRootGroupingElement = () => {
    const rootGroupingElement = new GroupingElement();
    rootGroupingElement.weightingFactor = 1;
    rootGroupingElement.maxSystemScore = 5;
    const updated = [...values, rootGroupingElement];
    onChange(updated);
  };

  const addCategory = (row, hideControls, type) => {
    setAddPopoverTarget(null);
    hideControls();
    const groupingElement = new GroupingElement({ type });
    groupingElement.weightingFactor = type === GROUPING_TYPE_CRITERION ? 5 : 1;
    groupingElement.maxSystemScore = 5;
    const updated = [...values];
    const { parent, index } = row;
    if (parent) {
      const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
      if (foundParent) {
        foundParent.children.splice(index + 1, 0, groupingElement);
      }
    } else {
      updated.splice(index + 1, 0, groupingElement);
    }
    onChange(updated);
  };

  const addChildCategory = (row, hideControls, type) => {
    setAddPopoverTarget(null);
    hideControls();
    const { index } = row;
    const childCategoryGroupingElement = new GroupingElement({ type });
    childCategoryGroupingElement.weightingFactor = type === GROUPING_TYPE_CRITERION ? 5 : 1;
    childCategoryGroupingElement.maxSystemScore = 5;
    const updated = [...values];
    const found = findRecursive(updated, (node) => node.UID === row.UID);
    if (found) {
      found.children.splice(index + 1, 0, childCategoryGroupingElement);
    }
    onChange(updated);
  };

  const addIndicator = (row, hideControls) => {
    setAddPopoverTarget(null);
    hideControls();
    const updated = [...values];
    const { parent, index } = row;
    const indicator = new GroupingElement({ type: GROUPING_TYPE_INDICATOR });
    indicator.maxSystemScore = DEFAULT_PAST_SUSTAINABILITY_SYSTEM_MAX_SYSTEM_SCORE;
    indicator.weightingFactor = DEFAULT_PAST_SUSTAINABILITY_SYSTEM_WEIGHTING_FACTOR;
    if (row.type === GROUPING_TYPE_INDICATOR) {
      if (parent) {
        const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
        if (foundParent) {
          foundParent.children.splice(index + 1, 0, indicator);
        }
      } else {
        updated.splice(index + 1, 0, indicator);
      }
    } else {
      const found = findRecursive(updated, (node) => node.UID === row.UID);
      if (found) {
        found.children.splice(index + 1, 0, indicator);
      }
    }
    onChange(updated);
  };

  const copyIndicator = (row, hideControls) => {
    hideControls();
    const updated = [...values];
    const { parent } = row;
    const indicator = copyElement(row);
    if (parent) {
      const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
      if (foundParent) {
        foundParent.children.push(indicator);
      }
    }
    onChange(updated);
  };

  const copyGrouping = (row, hideControls) => {
    hideControls();
    const updated = [...values];
    const { parent } = row;
    const element = copyElement(row);
    if (parent) {
      const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
      if (foundParent) {
        foundParent.children.push(element);
      }
    } else {
      updated.push(element);
    }
    onChange(updated);
  };

  const renderAddPopover = (row, hideControls) => {
    return (
      <AddMenuPopover
        row={row}
        addCategory={addCategory}
        addChildCategory={addChildCategory}
        addIndicator={addIndicator}
        addPopoverTarget={addPopoverTarget}
        hideControls={hideControls}
        setAddPopoverTarget={setAddPopoverTarget}
      />
    );
  };

  const renderHoverControlButtons = (row, hideControls) => {
    return (
      <>
        {row?.type === GROUPING_TYPE_INDICATOR && (
          <Fab color="primary" size="small" onClick={() => copyIndicator(row, hideControls)}>
            <FileCopyOutlinedIcon />
          </Fab>
        )}
        {row?.type !== GROUPING_TYPE_INDICATOR && (
          <Fab color="primary" size="small" onClick={() => copyGrouping(row, hideControls)}>
            <FileCopyOutlinedIcon />
          </Fab>
        )}
        <Fab color="primary" size="small" onClick={(event) => setAddPopoverTarget(event.target)}>
          <AddIcon />
        </Fab>
        <Fab color="secondary" size="small" onClick={() => setDeleteConfirmation({ row, hideControls })}>
          <DeleteOutlineIcon />
        </Fab>
      </>
    );
  };

  const deleteHandler = () => {
    setDeleteConfirmation(false);
    const { row, hideControls } = deleteConfirmation;
    hideControls();
    const updated = [...values];
    const { parent, index } = row;
    if (parent) {
      const foundParent = findRecursive(updated, (node) => node.UID === parent.UID);
      if (foundParent) {
        foundParent.children.splice(index, 1);
      }
    } else {
      updated.splice(index, 1);
    }
    onChange(updated);
  };

  const getRowClassName = (row) => {
    return "sustainability-row-" + row.type;
  };

  const deleteRow = (deleteConfirmation || {}).row;
  const isDeleteIndicator = !!deleteRow && deleteRow.type === GROUPING_TYPE_INDICATOR;

  const expandCondition = useCallback((node, path) => expandRowCondition(node, path, errors), [errors]);

  const passThrough = useMemo(
    () => ({
      readOnly,
      submitted,
      changeEvaluationStandard,
      getValues,
    }),
    [readOnly, submitted]
  );

  return (
    <div className="sustainability-system-grouping">
      {!!searched.length && (
        <div className="filters">
          {searched.map((item, index) => (
            <SearchBadge
              key={index}
              field={item.headerText}
              value={search[item.field].data}
              onClose={() => setSearch({ ...search, [item.field]: undefined })}
            />
          ))}
          <Button className="clear-filters" onClick={() => setSearch({})}>
            {t("main.clear")}
          </Button>
        </div>
      )}
      <AdvancedTable
        data={values}
        errorMessage={errorMessage}
        expandRowsByCondition={!!errorMessage}
        expandRowCondition={expandCondition}
        errorMarkRowsByCondition={!!errorMessage}
        errorMarkRowCondition={expandCondition}
        onChange={onChange}
        onChangePosition={onChange}
        getRowClassName={getRowClassName}
        readOnly={readOnly}
        submitted={submitted}
        errorsByPath={errorsByPath}
        groupingField={"children"}
        onSearch={setSearch}
        search={search}
        columns={columns}
        footer={
          <div>
            {!readOnly && !(values || []).length && (
              <Button variant="contained" color="primary" onClick={addRootGroupingElement}>
                <AddIcon /> {t("grouping.addCategory")}
              </Button>
            )}
          </div>
        }
        sortRows={sortGroupings}
        renderAddPopover={renderAddPopover}
        renderHoverControlButtons={renderHoverControlButtons}
        matchDropLogic={matchDropLogic}
        passThrough={passThrough}
        disableColumnLock
      />

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={t(
          isDeleteIndicator ? "grouping.deleteIndicatorConfirmationTitle" : "grouping.deleteConfirmationTitle"
        )}
        bodyText={t(isDeleteIndicator ? "grouping.deleteIndicatorConfirmation" : "grouping.deleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />
    </div>
  );
});
