import Popover from "@mui/material/Popover";
import React from "react";
import { useTranslation } from "react-i18next";

export const ProjectAwardTooltipPopover = ({ showTooltip, setShowTooltip, award, container }) => {
  const { t } = useTranslation();

  return (
    <Popover
      className={"award-popover"}
      open={!!showTooltip}
      anchorEl={(showTooltip || {}).target}
      onClose={() => setShowTooltip(null)}
      container={container}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      disableRestoreFocus
    >
      {!award ? (
        <div className="tooltip-header">{t("projects.noAwardData")}</div>
      ) : (
        <>
          <div className="award-header">{t("projects.awardData")}</div>
          <img className="award-image" src={`/api/sustainability/award/file/${award.fileId}/preview`} alt="" />
          <div className="award-title">{award.title}</div>
        </>
      )}
    </Popover>
  );
};
