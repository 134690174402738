import TextField from "@mui/material/TextField";
import { FormController } from "../FormController/FormController";

export const NumberFieldController = ({ control, name, required, label, readOnly, decimalPrecision }) => {
  return (
    <FormController
      control={control}
      name={name}
      required={required}
      label={label}
      render={({ field, fieldState, label: labelInner }) => (
        <TextField
          type="number"
          error={fieldState.invalid}
          fullWidth
          disabled={readOnly}
          label={labelInner + (required ? " *" : "")}
          {...field}
          value={field.value || ""}
          inputProps={{
            onBlur: (event) => {
              if (decimalPrecision === 0) {
                field.onChange(Math.floor(+event.target.value || ""));
              } else if (decimalPrecision) {
                field.onChange(Number(+String(event.target.value) || "").toFixed(decimalPrecision));
              } else {
                field.onChange(+String(event.target.value) || "");
              }
            },
          }}
        />
      )}
    />
  );
};
