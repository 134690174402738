export const TYPE_TEXT_INPUT = "TEXT_INPUT";
export const TYPE_TEXT_AREA = "TEXT_AREA";
export const TYPE_NUMBER_INTEGER = "NUMBER_INTEGER";
export const TYPE_NUMBER_DECIMAL = "NUMBER_DECIMAL";
export const TYPE_DATE = "DATE";
export const TYPE_SELECT = "SELECT";
export const TYPE_COMBOBOX = "COMBOBOX";
export const TYPE_CHECKBOX = "CHECKBOX";
export const TYPE_LOCATION = "LOCATION";

export const ATTRIBUTE_TYPES = [
  TYPE_TEXT_INPUT,
  TYPE_TEXT_AREA,
  TYPE_NUMBER_INTEGER,
  TYPE_NUMBER_DECIMAL,
  TYPE_DATE,
  TYPE_SELECT,
  TYPE_CHECKBOX,
  TYPE_LOCATION
];

Object.freeze(ATTRIBUTE_TYPES);

export const ATTRIBUTE_TYPES_WITH_OPTIONS = [TYPE_SELECT, TYPE_COMBOBOX];

Object.freeze(ATTRIBUTE_TYPES_WITH_OPTIONS);

export const LEFT_COLUMN_PLACEHOLDER_ID = 'left-field-placeholder';
export const RIGHT_COLUMN_PLACEHOLDER_ID = 'right-field-placeholder';

export const ATTRIBUTE_STATUS_ACTIVE = 'ACTIVE';
export const ATTRIBUTE_STATUS_INACTIVE = 'INACTIVE';

export const UNDERSCORE_LOCATION = '__location';
export const LOCATION = 'location';
export const CONDITION = 'condition';
export const USAGE_TYPE = 'usageType';
export const BUILDING_YEAR = 'buildingYear';
export const GROSS_FLOOR_AREA = 'grossFloorArea';
export const NET_FLOOR_AREA = 'netFloorArea';
export const ENERGY_REFERENCE_AREA = 'energyReferenceArea';
export const COST_REFERENCE_AREA = 'costReferenceArea';
