import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { PromiseSubjectState } from "react-rxjs-easy";
import {
  calculateOverallSystemScore,
  calculatePotentialOverallSystemScore,
  calculateUniversalScore,
  recalculateGroupingScore,
  recalculatePotentialGroupingScore,
} from "../../../helpers/ratingSystem";
import { indicatorActionsListSubject } from "../../../hooks/action";
import { showDialog } from "../../../hooks/dialog";
import { getRatingSystemPotentialsChanged, useIndicatorsWithActionsById } from "../../../hooks/ratingSystem";
import { forEachRecursive, potentialEvaluationSystemScoreESGVisualAid, roundDecimal } from "../../../utils";
import { AddActionsDialog } from "../../AddActionsDialog/AddActionsDialog";
import { CollapsiblePanel } from "../../CollapsiblePanel/CollapsiblePanel";
import { ResponsibleBlock } from "../../ResponsibleIndicator/ResponsibleBlock";
import { ActionsBlock } from "../IndicatorElementDockBlocks/ActionsBlock";
import { CurrentBlock } from "./CurrentBlock";
import { PotentialBlock } from "./PotentialBlock";

export const IndicatorElementDockESG = ({
  rows,
  data,
  readOnly,
  projectId,
  onChange,
  setOverallSystemScore,
  setPotentialOverallSystemScore,
  systemLogic,
  ratingSystemId,
  permissions,
  awards,
}) => {
  const { t } = useTranslation();
  const { indicatorName, maxSystemScore, maxSystemScoreProportion, weighting, koValue } = data || {};

  const indicatorsWithActionsById = useIndicatorsWithActionsById();
  const calculateTotalSystemScore = (dataCalculateTotalSystemScore) =>
    calculateOverallSystemScore(dataCalculateTotalSystemScore || rows, systemLogic);
  const calculatePotentialTotalSystemScore = (dataCalculatePotentialTotalSystemScore) =>
    calculatePotentialOverallSystemScore(dataCalculatePotentialTotalSystemScore || rows, systemLogic);
  const getAwards = (elementAwardThresholds, awardsInner) => {
    const foundAwards = [];

    if (elementAwardThresholds && awardsInner && awardsInner.length > 0) {
      Object.entries(elementAwardThresholds).forEach(([uuid, threshold]) => {
        const award = awardsInner.find((a) => a.internalId === uuid);
        if (award) {
          foundAwards.push({ ...award, threshold });
        }
      });
    }
    foundAwards.sort((a, b) => a.title.localeCompare(b.title));
    return foundAwards;
  };

  const awardsThresholds = getAwards(data.elementAwardThresholds, awards);

  const onReasonChange = ({ UID, fields, links, files, valueRangeList }) => {
    const updated = [...rows];
    const { kpi } = fields || {};
    if (kpi) {
      forEachRecursive(updated, (node) => {
        if (node.kpi) {
          if (UID === node.UID || (node.kpi.name === kpi.name && node.kpi.unit === kpi.unit)) {
            node.kpi.currentValue = kpi.currentValue;
            node.kpi.estimation = kpi.estimation;
          }
        }
      });
    }
    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        const {
          reason,
          evaluationValue,
          evaluationScoringValue,
          evaluationSystemScore,
          universalScore,
          customCurrentValue,
          customPotentialValue,
        } = fields || {};

        const potentialsChanged = getRatingSystemPotentialsChanged();
        if (
          !potentialsChanged[UID] &&
          !node.manualPotentialEvaluationSystemScore &&
          node.evaluationSystemScore !== evaluationSystemScore
        ) {
          node.potentialEvaluationSystemScore = evaluationSystemScore;
          node.potentialUniversalScore = calculateUniversalScore(evaluationSystemScore, maxSystemScore);
        }
        if (
          !potentialsChanged[UID] &&
          !node.manualPotentialEvaluationSystemScore &&
          node.evaluationValue !== evaluationValue
        ) {
          node.potentialEvaluationValue = evaluationValue;
        }
        if (
          !potentialsChanged[UID] &&
          !node.manualPotentialEvaluationSystemScore &&
          node.customPotentialValue !== customPotentialValue
        ) {
          node.customPotentialValue = customPotentialValue;
        }

        node.reason = reason;
        node.evaluationValue = evaluationValue;
        node.evaluationScoringValue = evaluationScoringValue;
        node.customCurrentValue = customCurrentValue;
        node.evaluationSystemScore = evaluationSystemScore;
        node.universalScore = universalScore;
        node.valueRangeList = valueRangeList || [];

        node.evaluationLinks = links || [];
        node.evaluationFiles = files || [];
        return true;
      }
    });

    recalculateGroupingScore(updated, systemLogic);
    setOverallSystemScore(calculateTotalSystemScore(updated));
    recalculatePotentialGroupingScore(updated, systemLogic);

    setPotentialOverallSystemScore(calculatePotentialTotalSystemScore(updated));
    onChange(updated);
  };

  const onPotentialChange = ({ UID, fields, valueRangeList }) => {
    const updated = [...rows];
    const { kpi } = fields || {};
    if (kpi) {
      forEachRecursive(updated, (node) => {
        if (node.kpi) {
          if (UID === node.UID || (node.kpi.name === kpi.name && node.kpi.unit === kpi.unit)) {
            node.kpi.potentialValue = kpi.potentialValue;
          }
        }
      });
    }
    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        const {
          potentialEvaluationValue,
          customPotentialValue,
          potentialEvaluationSystemScore,
          potentialUniversalScore,
          manualPotentialEvaluationSystemScore,
          potentialReason,
          potentialEvaluationScoringValue,
        } = fields || {};
        node.potentialEvaluationValue = potentialEvaluationValue;
        node.customPotentialValue = customPotentialValue;
        node.potentialEvaluationSystemScore = potentialEvaluationSystemScore;
        node.potentialUniversalScore = potentialUniversalScore;
        node.manualPotentialEvaluationSystemScore = manualPotentialEvaluationSystemScore;
        node.potentialReason = potentialReason;
        node.valueRangeList = valueRangeList || [];
        node.potentialEvaluationScoringValue = potentialEvaluationScoringValue;

        return true;
      }
    });
    recalculatePotentialGroupingScore(updated, systemLogic);
    setPotentialOverallSystemScore(calculatePotentialTotalSystemScore(updated));
    onChange(updated);
  };

  const onResponsibleChange = ({ UID, fields = [] }) => {
    const updated = [...rows];
    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        const { responsible } = fields || {};
        node.responsible = responsible;
        return true;
      }
    });
    onChange(updated);
  };

  const onActionsChange = ({ UID, actions = [] }) => {
    const updated = [...rows];
    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        node.actions = actions;
        return true;
      }
    });
    onChange(updated);
    indicatorActionsListSubject.next(new PromiseSubjectState(actions));
  };

  const showAddActionsDialog = (row, passThrough) => {
    const { readOnly: readOnlyShowAddActions } = passThrough || {};
    showDialog({
      className: "xlarge",
      closeOnClickOutside: !!readOnlyShowAddActions,
      getContent: (onClose) => (
        <AddActionsDialog
          row={row}
          onClose={onClose}
          readOnly={readOnlyShowAddActions}
          onChange={onActionsChange}
          grouping={rows}
          changeGrouping={onChange}
        />
      ),
    });
  };

  return (
    <div className="indicator-element-dock">
      <div className="data-block white-block">
        <h3>{indicatorName}</h3>
      </div>

      <div className="data-block gray-block">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.metrics")}</h3>}>
          <table>
            <tbody>
              <tr className="title-row">
                <td>
                  <label>{t("grouping.maxSystemScore")}</label>
                </td>
                <td>
                  <label>{t("grouping.maxSystemScoreProportion")}</label>
                </td>
              </tr>
              <tr className="data-row">
                <td>
                  <span>{roundDecimal(maxSystemScore)}</span>
                </td>
                <td>
                  <span>{roundDecimal(maxSystemScoreProportion)}%</span>
                </td>
              </tr>
              <tr className="title-row">
                <td>
                  <label>{t("grouping.weight")}</label>
                </td>
                <td>
                  <label>{t("grouping.koValue")}</label>
                </td>
              </tr>
              <tr className="data-row">
                <td>
                  <span>{roundDecimal(weighting)}</span>
                </td>
                <td>
                  <span>{koValue || ""}</span>
                </td>
              </tr>
              {awardsThresholds.length > 0 && (
                <tr className="title-row">
                  <td>
                    <label>{t("grouping.awardSide")}</label>
                  </td>
                  <td>
                    <label>{t("grouping.threshold")}</label>
                  </td>
                </tr>
              )}
              {awardsThresholds.map((award, index) => (
                <tr className="data-row" key={index}>
                  <td>
                    <span>{award.title}</span>
                  </td>
                  <td>
                    <span>{award.threshold}%</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CollapsiblePanel>
      </div>

      <div className="data-block current">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.currentESG")}</h3>}>
          <CurrentBlock
            row={data}
            readOnly={readOnly}
            onChange={onReasonChange}
            projectId={projectId}
            ratingSystemId={ratingSystemId}
            permissions={permissions}
          />
        </CollapsiblePanel>
      </div>
      {potentialEvaluationSystemScoreESGVisualAid(data, indicatorsWithActionsById[data.id]) && (
        <div className="indicatorPotentialVisualAid">
          <div className="infoIcon">
            <InfoOutlined />
          </div>
          <div className="text">{t("ratingSystem.columnGroup.potentialEvaluationSystemScoreESGVisualAid")}</div>
        </div>
      )}
      <div className="data-block potential">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.potential")}</h3>}>
          <PotentialBlock row={data} readOnly={readOnly} onChange={onPotentialChange} ratingSystemId={ratingSystemId} />
        </CollapsiblePanel>
      </div>

      <div className="data-block">
        <CollapsiblePanel
          header={
            <h3>
              {t("ratingSystem.columnGroup.actions")}
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  showAddActionsDialog(data, { readOnly });
                }}
                size="small"
              >
                <DescriptionOutlinedIcon />
              </IconButton>
            </h3>
          }
        >
          <ActionsBlock row={data} readOnly={readOnly} onChange={onActionsChange} />
        </CollapsiblePanel>
      </div>

      <div className="data-block">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.responsible")}</h3>}>
          <ResponsibleBlock row={data} readOnly={readOnly} onChange={onResponsibleChange} />
        </CollapsiblePanel>
      </div>
    </div>
  );
};
