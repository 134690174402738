import React from "react";
import { useTranslation } from "react-i18next";
import {
  ACTION_STATE_REJECTED,
  ACTION_STATE_WAITING,
} from "../../../constants/action";
import { FilterBlocks } from "../../FilterBlocks/FilterBlocks";
import "./ActionsFilter.scss";

export const ActionsFilter = ({ value, onChange, records, disabled }) => {
  const { t } = useTranslation();

  const amounts = {
    [ACTION_STATE_WAITING]: 0,
    [ACTION_STATE_REJECTED]: 0
  };

  (records || []).forEach(item => {
    if (item.state === ACTION_STATE_WAITING) {
      amounts[ACTION_STATE_WAITING]++;
    } else if (item.state === ACTION_STATE_REJECTED) {
      amounts[ACTION_STATE_REJECTED]++;
    } else {
      amounts[item.type]++;
    }
  });

  const filterOptions = [
    {
      name: ACTION_STATE_WAITING,
      title: t('actions.filter.' + ACTION_STATE_WAITING),
      amount: amounts[ACTION_STATE_WAITING]
    },
    {
      name: ACTION_STATE_REJECTED,
      title: t('actions.filter.' + ACTION_STATE_REJECTED),
      amount: amounts[ACTION_STATE_REJECTED]
    }
  ];

  return (
    <FilterBlocks filterOptions={filterOptions} value={value} onChange={onChange} allowDeselect showReset={!!value}
                  disabled={disabled}/>
  );
};
