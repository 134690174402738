import { GROUPING_TYPE_INDICATOR } from "../../../constants/sustainabilitySystem";
import React from "react";
import { roundDecimal } from "../../../utils";

export const MaxSystemScoreTemplate = (row) => {
  const isIndicator = row.type === GROUPING_TYPE_INDICATOR;
  let maxSystemScore = +row.maxSystemScore;
  let maxSystemScoreLimit;
  let value = maxSystemScore;
  if (!isIndicator) {
    maxSystemScoreLimit = row.maxSystemScoreLimit ? +row.maxSystemScoreLimit : null;
    if (maxSystemScoreLimit) {
      value = maxSystemScore <= maxSystemScoreLimit ? maxSystemScore : maxSystemScoreLimit;
    }
  }
  if (maxSystemScore) {
    maxSystemScore = roundDecimal(maxSystemScore);
  }
  if (value) {
    value = roundDecimal(value);
  }

  return (<div className="text-right">
    <span>{value === 0 ? 0 : value || ''}</span>
    {!!maxSystemScoreLimit && maxSystemScore > maxSystemScoreLimit &&
    <span className="ignored-value">{maxSystemScore}</span>
    }
  </div>);
};
