export const ACTION_STATE_APPROVED = "APPROVED";
export const ACTION_STATE_REJECTED = "REJECTED";
export const ACTION_STATE_WAITING = "WAITING";

export const ACTION_STATES = [ACTION_STATE_APPROVED, ACTION_STATE_REJECTED, ACTION_STATE_WAITING];

export const ACTION_CUSTOM = "CUSTOM";
export const ACTION_GLOBAL = "GLOBAL";

export const ACTION_STATUS_PROPOSED = "proposed";
export const ACTION_STATUS_APPROVED = "approved";
export const ACTION_STATUS_IN_PROGRESS = "in_progress";
export const ACTION_STATUS_COMPLETED = "completed";

export const ACTION_STATUS_OPTIONS = [ACTION_STATUS_PROPOSED, ACTION_STATUS_APPROVED, ACTION_STATUS_IN_PROGRESS, ACTION_STATUS_COMPLETED];
