import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PERMISSION_GROUPS, PERMISSION_TENANT_ADMIN } from "../../constants/permissions";
import { ROLE_STATUSES, ROLE_STATUS_ENABLED } from "../../constants/role";
import { handleError } from "../../helpers/error";
import { roleCreate, roleUpdate, useRoleCreateResponse, useRoleUpdateResponse } from "../../hooks/role";
import { useTimeout } from "../../hooks/utils/useTimeout";
import { FormController } from "../FormController/FormController";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import "./RoleDataDialog.scss";

export const RoleDataDialog = ({ row, tenantId, onClose, onChange, readOnly }) => {
  const { t } = useTranslation();
  const data = row || {};

  const updateResponse = useRoleUpdateResponse();
  const createResponse = useRoleCreateResponse();
  const [, setResponseTimeout] = useTimeout();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { name: "", description: "", roleIsDefault: false, status: "" },
  });

  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    reset({
      name: data.name,
      description: data.description,
      roleIsDefault: !!data.roleIsDefault,
      status: data.status || ROLE_STATUS_ENABLED,
    });
    setPermissions(row.permissions || []);
  }, [row]);

  const setPermission = (permission, checked) => {
    const updated = permissions.filter((item) => item !== permission);
    if (checked) {
      updated.push(permission);
    }
    setPermissions(updated);
  };

  const afterChange = () =>
    setResponseTimeout(
      setTimeout(() => {
        onChange();
        onClose();
      }, 0)
    );

  const onSubmit = (values) => {
    if (data.id) {
      roleUpdate(data.id, { ...values, permissions })
        .then(afterChange)
        .catch(handleError);
    } else {
      roleCreate(tenantId, { ...values, permissions })
        .then(afterChange)
        .catch(handleError);
    }
  };

  const permissionsByName = {};
  permissions.forEach((item) => {
    permissionsByName[item] = true;
  });

  const loading = updateResponse.loading || createResponse.loading;

  return (
    <LoadingOverlay spinner active={loading} className="role-data-dialog auto-height">
      <div className="flex-row custom-dialog-header">
        <h2 className="flex-auto">{t("role.dialogHeader")}</h2>
        <IconButton className="close-button" size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} className="custom-dialog-body">
        <div className="dialog-body">
          <FormController
            control={control}
            name="name"
            required
            label={t("role.name")}
            render={({ field, fieldState, label }) => (
              <TextField
                {...field}
                fullWidth
                error={fieldState.invalid}
                label={label + " *"}
                disabled={readOnly}
                inputProps={{ maxLength: 255 }}
                value={field.value || ""}
              />
            )}
          />
          <FormController
            control={control}
            name="description"
            required
            label={t("role.description")}
            render={({ field, fieldState, label }) => (
              <TextField
                {...field}
                fullWidth
                error={fieldState.invalid}
                label={label + " *"}
                disabled={readOnly}
                inputProps={{ maxLength: 255 }}
                value={field.value || ""}
              />
            )}
          />
          <FormController
            control={control}
            name="status"
            label={t("role.status.label")}
            render={({ field, fieldState, label }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="roleStatusSelect">{label}</InputLabel>
                <Select
                  {...field}
                  id="roleStatusSelect"
                  fullWidth
                  error={fieldState.invalid}
                  label={label}
                  disabled={readOnly}
                  value={field.value || ""}
                >
                  {ROLE_STATUSES.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {t("role.status." + item)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <FormController
            control={control}
            name="tenantAdmin"
            label={t("roles.permissions.TENANT_ADMIN")}
            render={({ field, fieldState, label }) => (
              <FormControl fullWidth>
                <div>
                  <Checkbox
                    {...field}
                    onChange={(event) => setPermission(PERMISSION_TENANT_ADMIN, event.target.checked)}
                    checked={!!permissionsByName[PERMISSION_TENANT_ADMIN]}
                    id="tenantAdminCheckbox"
                    label={label}
                    disabled={readOnly}
                  />
                  <label htmlFor="tenantAdminCheckbox">{label}</label>
                </div>
              </FormControl>
            )}
          />
          <FormController
            control={control}
            name="roleIsDefault"
            label={t("role.roleIsDefault")}
            render={({ field, fieldState, label }) => (
              <FormControl fullWidth>
                <div>
                  <Checkbox
                    {...field}
                    checked={!!field.value}
                    id="roleIsDefaultCheckbox"
                    label={label}
                    disabled={readOnly}
                  />
                  <label htmlFor="roleIsDefaultCheckbox">{label}</label>
                </div>
              </FormControl>
            )}
          />
          {Object.keys(PERMISSION_GROUPS).map((key) => (
            <div key={key} className="permission-group">
              <h3>{t("roles.permissionsGroup." + key)}</h3>
              {PERMISSION_GROUPS[key].map((item, index) => (
                <div key={index}>
                  <Checkbox
                    id={"rolePermissionCheckbox_" + item}
                    name={item}
                    checked={!!permissionsByName[item]}
                    onChange={(event) => setPermission(item, event.target.checked)}
                    disabled={readOnly}
                  />
                  <label htmlFor={"rolePermissionCheckbox_" + item}>{t("roles.permissions." + item)}</label>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="buttons text-right">
          {!!readOnly && <Button onClick={onClose}>{t("main.close")}</Button>}
          {!readOnly && (
            <>
              <Button onClick={onClose}>{t("main.cancel")}</Button>
              <Button type="submit" color="primary">
                {t("main.save")}
              </Button>
            </>
          )}
        </div>
      </form>
    </LoadingOverlay>
  );
};
