import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import { CircularProgress, Grid, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { v4 as getUUID } from "uuid";
import {
  AZURE_MAPS_INITIAL_POSITION,
  AZURE_MAPS_INITIAL_ZOOM,
  AZURE_MAPS_LANG_DE_DE,
  AZURE_MAPS_LANG_EN_GB,
} from "../../constants/azure";
import { LANGUAGE_EN } from "../../constants/language";
import { PERMISSION_PROJECT_READ } from "../../constants/permissions";
import { TENANT_TYPE_ESG, TENANT_TYPE_PAST } from "../../constants/tenant";
import {
  GET_LEFT_INFOBOX_IMAGE_DEFAULT_PATH,
  GET_LEFT_INFOBOX_IMAGE_PATH,
  GET_LEFT_INFOBOX_URL,
  GET_LOGO_PATH,
  GET_RIGHT_INFOBOX_IMAGE_DEFAULT_PATH,
  GET_RIGHT_INFOBOX_IMAGE_PATH,
  GET_RIGHT_INFOBOX_URL,
} from "../../constants/whitelabels";
import { hasPermission } from "../../helpers/permission";
import { getUserTenantName, getUserTenantType, useUser } from "../../hooks/auth";
import { getProject, getProjectsMap, getProjectsUserIsMemberUser } from "../../hooks/project";
import { showError } from "../../hooks/toast";
import { getMapCoordinatesFromAPIResponse } from "../../utils";
import { LastUpdated } from "../Dashboard/Widgets/LastUpdated/LastUpdated";
import { TotalRatingSystem } from "../Dashboard/Widgets/TotalRatingSystem/TotalRatingSystem";
import { ProjectDetailsCard } from "../ProjectDetailsCard/ProjectDetailsCard";
import { ProjectsMap } from "../ProjectsMap/ProjectsMap";
import { UserWidget } from "../UserWidget/UserWidget";
import "./HomeDashboard.scss";

export const HomeDashboard = () => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [coordinates, setCoordinates] = useState();
  const [projectDetails, setProjectDetails] = useState();
  const [isLoadingProjectDetails, setIsLoadingProjectDetails] = useState(false);
  const [projsOnSameLatLong, setProjsOnSameLatLong] = useState(null);
  const [projsOnSameLatLongIndex, setProjsOnSameLatLongIndex] = useState(0); // used to render projs pagination when multiple projs exists on same lat-long
  const [mapLanguage, setMapLanguage] = useState(
    i18n.language === LANGUAGE_EN ? AZURE_MAPS_LANG_EN_GB : AZURE_MAPS_LANG_DE_DE
  );
  const tenantType = getUserTenantType();
  const tenantName = getUserTenantName();
  const [projectsByUser, setProjectsByUser] = useState(null);

  const user = useUser();
  const { firstName, lastName, encodedPicture } = user?.user || {};
  const fullName = [firstName || "", lastName || ""].join(" ");
  const { companyName } = user?.tenant || {};

  const { leftInfoboxImageExists, leftInfoboxLink, rightInfoboxImageExists, rightInfoboxLink } =
    user?.whitelabelDetails || {};

  useEffect(() => {
    getCoordinates();
  }, []);

  i18n.on("languageChanged", () => {
    setMapLanguage(i18n.language);
  });

  useEffect(() => {
    if (!projsOnSameLatLong) {
      return;
    }
    setIsLoadingProjectDetails(true);
    getProject(projsOnSameLatLong[projsOnSameLatLongIndex])
      .then(setProjectData)
      .catch((errorInner) => {
        console.error(errorInner);
        setIsLoadingProjectDetails(false);
        showError(t("error.500"));
      });
  }, [projsOnSameLatLongIndex]); // fetch the next proj under the same lat/long

  const setProjectData = (projectDetailsInner) => {
    setIsLoadingProjectDetails(false);
    setProjectDetails(projectDetailsInner);
  };

  const getCoordinates = () => {
    setLoading(true);

    getProjectsMap()
      .catch((errorInner) => {
        console.error(errorInner);
        setError(true);
        showError(t("error.500"));
      })
      .then(setProjectCoordinates);
  };

  useEffect(() => {
    getProjectsByUser();
  }, []);

  const getProjectsByUser = () => {
    getProjectsUserIsMemberUser()
      .catch((errorInner) => {
        console.error(errorInner);
        setError(true);
        showError(t("error.500"));
      })
      .then(setProjectsByUser);
  };

  const setProjectCoordinates = (coordinatesInner) => {
    setCoordinates(getMapCoordinatesFromAPIResponse(coordinatesInner));
    setLoading(false);
  };

  const getLoading = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  };
  const getError = () => {
    return (
      <div style={{ textAlign: "center" }}>
        {" "}
        There was an error loading the map.{" "}
        <Link style={{ cursor: "pointer" }} onClick={() => getCoordinates()}>
          Click here
        </Link>{" "}
        to try again.{" "}
      </div>
    );
  };
  const setupProjsOnSameLatLong = (projectInfo) => {
    setProjsOnSameLatLongIndex(0);
    if (projectInfo.projsOnSameLatLong) {
      setProjsOnSameLatLong([projectInfo.projectId].concat(projectInfo.projsOnSameLatLong));
    } else {
      setProjsOnSameLatLong(null);
    }
  };
  const openProjectDetailsCard = (projectInfo) => {
    setIsLoadingProjectDetails(true);
    setupProjsOnSameLatLong(projectInfo);
    getProject(projectInfo.projectId)
      .then(setProjectData)
      .catch((errorInner) => {
        console.error(errorInner);
        setIsLoadingProjectDetails(false);
        showError(t("error.500"));
      });
  };
  const closeProjectDetailsCard = () => {
    setProjectDetails(null);
  };

  const getTenantType = () => {
    if (tenantType === TENANT_TYPE_PAST) {
      return t("dashboard.pastTitle");
    }
    return t("dashboard.esgTitle");
  };

  return (
    <>
      <div className="home-dashboard">
        <h1>{tenantName}</h1>
        <h2>{getTenantType()}</h2>
        <div className="home-dashboard-grid">
          <div className="home-dashboard-widgets">
            {hasPermission(PERMISSION_PROJECT_READ) && tenantType === TENANT_TYPE_ESG && <TotalRatingSystem />}
            <LastUpdated />
          </div>
          <div className="home-dashboard-map">
            {loading
              ? getLoading()
              : error
              ? getError()
              : (tenantType === TENANT_TYPE_ESG || tenantType === TENANT_TYPE_PAST) && (
                  <>
                    <div className={`map-proj-details-card-wrapper ${projectDetails ? "card-visible" : ""}`}>
                      <ProjectsMap
                        initialPosition={AZURE_MAPS_INITIAL_POSITION}
                        initialZoom={AZURE_MAPS_INITIAL_ZOOM}
                        coordinates={coordinates}
                        getCoordinates={getCoordinates}
                        projectDetailsCallback={openProjectDetailsCard}
                        mapLanguage={mapLanguage}
                      />
                      {!!projectDetails && (
                        <ProjectDetailsCard
                          projectDetails={projectDetails}
                          closeProjectDetailsCard={closeProjectDetailsCard}
                          isLoadingProjectDetails={isLoadingProjectDetails}
                          projsOnSameLatLong={projsOnSameLatLong}
                          projsOnSameLatLongIndex={projsOnSameLatLongIndex}
                          setProjsOnSameLatLongIndex={setProjsOnSameLatLongIndex}
                        />
                      )}
                    </div>
                  </>
                )}
          </div>
          <div className="home-dashboard-social">
            <a target="_blank" rel="noreferrer" href={leftInfoboxLink || GET_LEFT_INFOBOX_URL}>
              <img
                src={leftInfoboxImageExists ? GET_LEFT_INFOBOX_IMAGE_PATH : GET_LEFT_INFOBOX_IMAGE_DEFAULT_PATH}
                alt="img"
              />
            </a>
            <a target="_blank" rel="noreferrer" href={rightInfoboxLink || GET_RIGHT_INFOBOX_URL}>
              <img
                src={rightInfoboxImageExists ? GET_RIGHT_INFOBOX_IMAGE_PATH : GET_RIGHT_INFOBOX_IMAGE_DEFAULT_PATH}
                alt="img"
              />
            </a>
          </div>
          <div className="home-dashboard-user">
            <div className="table">
              <div className="row">
                <div className="cell">
                  <UserWidget fullName={fullName} encodedPicture={encodedPicture} />
                </div>
                <div className="cell">
                  <h2 className="user-tenant-name">{tenantName}</h2>
                  <img className="logo" src={GET_LOGO_PATH} alt="logo" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="cell">
                <h1 className="full-name">{fullName}</h1>
                <h1 className="company">{companyName}</h1>
                {hasPermission(PERMISSION_PROJECT_READ) && projectsByUser && projectsByUser?.length > 0 && (
                  <div>
                    <h1 className="full-name">{t("dashboard.projectsMember")}</h1>
                    {projectsByUser?.map((item) => (
                      <div key={getUUID()}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <NavLink to={"/project/" + item.id}>
                              <div className="projects">
                                <span>{item.name}</span>
                                <AttachmentOutlinedIcon />
                              </div>
                            </NavLink>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
