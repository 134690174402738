import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Fab from "@mui/material/Fab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { ActionsFilter } from "../../components/Actions/ActionsFilter/ActionsFilter";
import { ActionsTable } from "../../components/Actions/ActionsTable/ActionsTable";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { ACTION_STATE_APPROVED, ACTION_STATE_REJECTED } from "../../constants/action";
import { PERMISSION_ACTION_CREATE } from "../../constants/permissions";
import { hasPermission } from "../../helpers/permission";
import { checkPopState } from "../../hooks";
import {
  createAction,
  deleteAction,
  getActionList,
  resetActionsPageState,
  updateAction,
  useActionListResponse,
  useActionUpdateResponse,
  useActionsPageLogic,
} from "../../hooks/actionTemplate";
import { showError } from "../../hooks/toast";
import { useTimeout } from "../../hooks/utils/useTimeout";
import { processMessage } from "../../utils";
import { getDuplicatedAction, getInvalidRequiredAction } from "../../validation/action";
import "./SettingsActions.scss";

export const SettingsActions = () => {
  const { t } = useTranslation();

  const [editRow, setEditRow] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const response = useActionListResponse();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateResponse = useActionUpdateResponse();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const { filterValue, setFilterValue, searchValue, setSearchValue, resetState } = useActionsPageLogic();
  const [, setSearchTimeout] = useTimeout();

  const [submitted, setSubmitted] = useState(false);
  const [filtered, setFiltered] = useState([]);

  const load = () => {
    setLoading(true);
    getActionList()
      .then()
      .catch((error) => {
        console.error(error);
        showError(t("error.500"));
      });
  };

  useEffect(() => {
    if (!checkPopState()) {
      resetActionsPageState();
    }
    load();
  }, []);

  useEffect(() => {
    if (!response.loading) {
      resetData();
      setLoading(false);
    }
  }, [response.loading]);

  useEffect(() => {
    let updated = [...data];
    // search
    const searchQuery = String(searchValue).trim().toLowerCase();
    if (searchQuery) {
      updated = updated.filter((item) => {
        return (
          String(item.name).toLowerCase().indexOf(searchQuery) !== -1 ||
          String(item.description).toLowerCase().indexOf(searchQuery) !== -1 ||
          !!(item.listTags || []).find((tag) => String(tag).toLowerCase().indexOf(searchQuery) !== -1)
        );
      });
    }
    setFiltered(updated);
  }, [searchValue, data]);

  const updateRow = (row, update) => {
    Object.keys(update).forEach((key) => (row[key] = update[key]));
    setData([...data]);
  };

  const resetData = (values, disableEditing) => {
    if (disableEditing) {
      setIsAdd(false);
      setEditRow(null);
      setSubmitted(false);
    }
    const dataInner = values || response.data || [];
    setData(
      dataInner.map((item) => ({
        ...item,
        listTags: [...(item.listTags || [])],
        UID: item.id || item.UID || getUUID(),
      }))
    );
  };

  const onChange = (updated) => setData([...updated]);

  const validateActions = () => {
    let valid = true;
    if (getInvalidRequiredAction(data)) {
      valid = false;
      showError(t("actions.requiredValidationFailed"));
    }
    const duplicated = getDuplicatedAction(data);
    if (duplicated) {
      valid = false;
      showError(processMessage(t("actions.uniqueValidationFailed"), [duplicated.name]));
    }
    return valid;
  };

  const onSaved = () => {
    setSubmitted(false);
    setEditRow(null);
    setIsAdd(false);
    load();
  };

  const onAccept = (action) => {
    updateAction({ ...action, state: ACTION_STATE_APPROVED })
      .catch(console.error)
      .then(load);
  };

  const onReject = (action) => {
    updateAction({ ...action, state: ACTION_STATE_REJECTED })
      .catch(console.error)
      .then(load);
  };

  const handleError = (error) => {
    console.error(error);
    showError(t("error.500"));
  };

  const onSave = (row) => {
    setSubmitted(true);
    if (!row.name || !row.description) {
      showError(t("actions.requiredValidationFailed"));
    } else if (validateActions()) {
      if (row.id) {
        updateAction({
          ...row,
          UID: undefined,
          state: row.state || ACTION_STATE_APPROVED,
        })
          .then(onSaved)
          .catch(handleError);
      } else {
        createAction({ ...row, UID: undefined })
          .then(onSaved)
          .catch(handleError);
      }
    }
  };

  const onCancel = () => resetData(null, true);

  const deleteHandler = () => {
    deleteAction(deleteConfirmation.id).then(onSaved).catch(handleError);
    setDeleteConfirmation(null);
  };

  const search = (value) => {
    setSearchTimeout(setTimeout(() => setSearchValue(String(value || "").trim()), 500));
  };

  return (
    <div className="actions-page page-with-table">
      <div className="page-header large flex-row">
        <div className="flex-col title-box no-margin">
          <h1>{t("actions.title")}</h1>
        </div>
        <div className="flex-col search-box no-margin">
          <SearchIcon />
          <input
            type="text"
            key={resetState}
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            placeholder={t("main.search")}
            onChange={(event) => search(event.target.value)}
            disabled={!!editRow}
          />
        </div>
      </div>

      <div className="page-layout">
        <ActionsFilter onChange={setFilterValue} value={filterValue} records={filtered} disabled={!!editRow} />
        <LoadingOverlay spinner active={response.loading || updateResponse.loading} className="table-loading">
          <ActionsTable
            data={data}
            loading={loading}
            onChange={onChange}
            updateRow={updateRow}
            submitted={submitted}
            onDelete={(row) => setDeleteConfirmation(row)}
            setEditRow={setEditRow}
            editRow={editRow}
            onSave={onSave}
            isAdd={isAdd}
            onCancel={onCancel}
            onAccept={onAccept}
            onReject={onReject}
          />
        </LoadingOverlay>
      </div>

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={t("actions.actionDeleteConfirmationTitle")}
        bodyText={t("actions.actionDeleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />

      {hasPermission(PERMISSION_ACTION_CREATE) && (
        <div className="fabs">
          <Fab
            type="button"
            color="primary"
            onClick={() => {
              resetActionsPageState();
              setIsAdd(true);
            }}
            disabled={!!editRow}
          >
            <AddIcon className="fab-svg" />
          </Fab>
        </div>
      )}
    </div>
  );
};
