export const RatingSystemIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32.068">
    <g id="icon-rating-system" transform="translate(5 1)">
      <rect
        id="Rectangle_102"
        data-name="Rectangle 102"
        width="30"
        height="30"
        transform="translate(-4 0)"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        opacity="0"
      />
      <g id="Group_114" data-name="Group 114" transform="translate(-1.839 -1.249)">
        <g id="Group_119" data-name="Group 119">
          <path
            id="Path_157"
            data-name="Path 157"
            d="M12,3h5.091a2.727,2.727,0,0,1,2.727,2.727V15.954"
            transform="translate(4.25 0.977)"
            fill="none"
            stroke="#002965"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_158"
            data-name="Path 158"
            d="M9.5,27.545H2.727A2.727,2.727,0,0,1,0,24.818V5.727A2.727,2.727,0,0,1,2.727,3H7.818"
            transform="translate(0.25 0.977)"
            fill="none"
            stroke="#002965"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
        <path
          id="Path_159"
          data-name="Path 159"
          d="M15.327,24.3l-2.962,1.552a.534.534,0,0,1-.775-.559L12.157,22l-2.4-2.328a.531.531,0,0,1,.3-.907l3.311-.48,1.481-2.99a.535.535,0,0,1,.955,0l1.481,2.99,3.311.48a.532.532,0,0,1,.3.907L18.5,22l.565,3.286a.533.533,0,0,1-.434.615.542.542,0,0,1-.34-.055Z"
          transform="translate(4.377 5.341)"
          fill="none"
          stroke="#002965"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_160"
          data-name="Path 160"
          d="M6,3.727A2.727,2.727,0,0,1,8.727,1h2.727a2.727,2.727,0,1,1,0,5.455H8.727A2.727,2.727,0,0,1,6,3.727Z"
          transform="translate(2.068 0.25)"
          fill="none"
          stroke="#002965"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_169"
          data-name="Path 169"
          d="M0,0H5.091"
          transform="translate(6.705 18.977)"
          fill="none"
          stroke="#002965"
          stroke-linecap="round"
          stroke-width="2"
        />
        <path
          id="Path_168"
          data-name="Path 168"
          d="M0,0H9.182"
          transform="translate(6.705 13.522)"
          fill="none"
          stroke="#002965"
          stroke-linecap="round"
          stroke-width="2"
        />
      </g>
    </g>
  </svg>
);
