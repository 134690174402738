import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, IconButton, Tooltip } from "@mui/material";
import { FilterOperator, process } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import { HeaderThElement, setGroupIds } from "@progress/kendo-react-data-tools";
import { TextBox } from "@progress/kendo-react-inputs";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { v4 as getUUID } from "uuid";
import { PERCENT_SIGN } from "../../../constants/main";
import { TENANT_TYPE_PAST } from "../../../constants/tenant";
import { getBreadcrumbPathParts } from "../../../helpers/dashboard";
import { getUserTenantType } from "../../../hooks/auth";
import { useEditSafeExit } from "../../../hooks/editing";
import { useDashboardSelectedGrouping } from "../../../hooks/project";
import { updateActions } from "../../../hooks/ratingSystem";
import { showPromise } from "../../../hooks/toast";
import { useEvent } from "../../../hooks/utils/useEvent";
import { someMap } from "../../../pages/RatingSystem/NewRatingSystemESG.util";
import { forEachRecursive, localeString, onlyDecimals } from "../../../utils";
import { conditionIsCategory, conditionIsCriterion } from "../../../validation/sustainabilitySystem";
import { Breadcrumb } from "../../Breadcrumb/Breadcrumb";
import "./ActionsTab.scss";
import { ActionTable } from "./ActionTable";

export const ACTION_TABLE_EDIT_FIELD = "inEdit";

const actionsDataSubject = new BehaviorSubject([]);
const setActionsData = (v) => actionsDataSubject.next(typeof v === "function" ? v(actionsDataSubject.getValue()) : v);
const getActionsData = () => actionsDataSubject.getValue();
const useActionsData = hookFromSubject(actionsDataSubject);

const kpiListByActionIdSubject = new BehaviorSubject({});
const setKpiListByActionId = (data) => kpiListByActionIdSubject.next(data);
const useKpiListByActionId = hookFromSubject(kpiListByActionIdSubject);

const getTotal = (value) => {
  const actionsData = getActionsData();
  return actionsData?.reduce((prev, current) => prev + (!isNaN(+current[value]) ? current[value] : 0), 0);
};

const InvestmentAmountHeader = (props) => (
  <HeaderThElement {...props.thProps}>
    {" "}
    <span className="k-cell-inner">
      <Box className="k-link" style={{ flexDirection: "column" }} onClick={props.onClick}>
        <div className="k-column-title">{props.title}</div>
        <div style={{ color: "var(--PRIMARY_BACKGROUND_COLOR)" }}>TOTAL: {getTotal("investmentAmount")}</div>
      </Box>
    </span>
  </HeaderThElement>
);

InvestmentAmountHeader.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  thProps: PropTypes.object,
};

const RoiStaticHeader = (props) => (
  <HeaderThElement {...props.thProps}>
    <span className="k-cell-inner">
      <Box className="k-link" style={{ flexDirection: "column" }} onClick={props.onClick}>
        <div className="k-column-title">{props.title}</div>
        <div style={{ color: "var(--PRIMARY_BACKGROUND_COLOR)" }}>TOTAL: {getTotal("roiStatic")}</div>
      </Box>
    </span>
  </HeaderThElement>
);

RoiStaticHeader.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  thProps: PropTypes.object,
};

const LocalString = (props) => <td {...props.tdProps}>{localeString(props?.dataItem[props?.field])}</td>;

LocalString.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
  tdProps: PropTypes.object,
};

const TrafficLightMono = (props) => {
  const dataItem = props.dataItem[props.field];
  let children = null;
  if (dataItem) {
    const circleClass = `circle selected ${dataItem.toLowerCase() || ""}`;
    children = (
      <div className="traffic-light">
        <div className={circleClass}></div>
      </div>
    );
  }
  return <td {...props.tdProps}>{children}</td>;
};

TrafficLightMono.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
  tdProps: PropTypes.object,
};

const ActionStatus = (props) => {
  const { t } = useTranslation();
  const status = props.dataItem?.status ? t("action.statuses." + props.dataItem?.status) : "";
  return <td {...props.tdProps}>{status}</td>;
};

ActionStatus.propTypes = {
  dataItem: PropTypes.object,
  tdProps: PropTypes.object,
};

const ScoringDifference = (props) => {
  const scoringDifference = props.dataItem?.scoringDifference
    ? onlyDecimals(props.dataItem?.scoringDifference, 2) + PERCENT_SIGN
    : "";
  return <td {...props.tdProps}>{scoringDifference}</td>;
};

ScoringDifference.propTypes = {
  dataItem: PropTypes.object,
  tdProps: PropTypes.object,
};

const editMapSubject = new BehaviorSubject({});
const setEditMap = (v) => editMapSubject.next(typeof v === "function" ? v(editMapSubject.getValue()) : v);
const useEditMap = hookFromSubject(editMapSubject);

const ActionTypeColumn = (props) => {
  const { t } = useTranslation();
  const { dataIndex, dataItem, field } = props;
  const editMap = useEditMap();
  const editing = editMap?.[dataItem?.id];
  const [previousValue, setPreviousValue] = useState();

  const toggleEdit = useEvent(() =>
    setEditMap((prev) => {
      const result = { ...prev, [dataItem?.id]: !editMap?.[dataItem?.id] };
      if (result[dataItem?.id]) setPreviousValue(dataItem?.[field]);
      else props?.onChange({ dataIndex, dataItem, field, value: previousValue });

      setUnsaved(someMap(result, (edit) => edit));
      return result;
    })
  );

  return (
    <td {...props.tdProps}>
      <div className="k-d-flex k-align-items-center k-gap-2">
        {!editing && (
          <>
            <span>{dataItem?.[field]}</span>
            <div className="k-flex-grow" />
            <Tooltip title={t("dashboard.actionsTable.editType")}>
              <IconButton onClick={toggleEdit} size="small">
                <EditOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </>
        )}
        {editing && (
          <>
            <TextBox
              value={props?.dataItem?.[props?.field] ?? ""}
              onChange={({ value }) => props?.onChange({ dataIndex, dataItem, field, value })}
              fillMode="flat"
              size="small"
            />
            <div className="k-flex-grow" />
            <Tooltip title={t("dashboard.actionsTable.cancel")}>
              <IconButton onClick={toggleEdit} size="small">
                <CloseOutlinedIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </td>
  );
};

ActionTypeColumn.propTypes = {
  tdProps: PropTypes.object,
  dataItem: PropTypes.object,
  dataIndex: PropTypes.number,
  field: PropTypes.string,
  onChange: PropTypes.func,
};

const ActionKPIValueAbs = (props) => {
  const kpiListByActionId = useKpiListByActionId();
  const templateId = props.dataItem?.templateId;
  let children = null;
  if (templateId && Object.keys(kpiListByActionId).length > 0) {
    const kpiList = kpiListByActionId[templateId];
    children = kpiList?.length
      ? kpiList.map(({ name, unit, currentValue, potentialValue }, index) => (
          <div key={getUUID()} className="kpi-line">
            {name} {Math.abs((potentialValue || currentValue) - currentValue)} {unit}
          </div>
        ))
      : "-";
  }
  return <td {...props.tdProps}>{children}</td>;
};

ActionKPIValueAbs.propTypes = {
  dataItem: PropTypes.object,
  tdProps: PropTypes.object,
};

const kpiListRecursive = (node, res, elementsFieldName) =>
  node?.[elementsFieldName]?.forEach((indicator) => {
    if (indicator.kpi && indicator.actions?.length)
      indicator.actions.forEach((action) => {
        if (!res[action.templateId]) res[action.templateId] = [];

        if (!res[action.templateId]?.some((item) => item.id === indicator.kpi.id))
          res[action.templateId]?.push(indicator.kpi);
      });
  });

const getKpiListByActionId = (ratingSystem, elementsFieldName) => {
  const res = {};
  forEachRecursive(
    ratingSystem.groupingElements,
    (node) => kpiListRecursive(node, res, elementsFieldName),
    "groupingElements"
  );
  return res;
};

const columnsESG = [
  {
    field: "criteriaValue",
    title: "dashboard.actionsTable.criteriaValue",
    width: "300px",
    show: true,
  },
  {
    field: "indicatorValue",
    title: "dashboard.actionsTable.indicatorValue",
    width: "300px",
    show: true,
  },
  {
    field: "name",
    title: "dashboard.actionsTable.actions",
    width: "300px",
    show: true,
  },
  {
    field: "description",

    title: "action.description",
    width: "600px",
    show: true,
  },
  {
    field: "type",
    cells: { data: ActionTypeColumn },
    title: "action.type",
    width: "200px",
    show: true,
  },
  {
    field: "investmentAmount",
    title: "dashboard.actionsTable.investmentEuro",
    cells: { data: LocalString, headerCell: InvestmentAmountHeader },
    width: "200px",
    show: true,
  },
  {
    field: "savingsOverLifecycle",
    title: "action.savingsOverLifecycle",
    width: "150px",
    show: true,
  },
  {
    field: "lifecycleInYears",
    title: "action.lifecycleInYears",
    width: "150px",
    show: true,
  },
  {
    field: "roiStatic",

    title: "dashboard.actionsTable.roiStatic",
    cells: { data: LocalString, headerCell: RoiStaticHeader },
    width: "180px",
    show: true,
  },
  {
    field: "operational",
    title: "dashboard.actionsTable.operational",
    cells: { data: TrafficLightMono },
    width: "200px",
    show: true,
  },
  {
    field: "suggestion",
    title: "dashboard.actionsTable.suggestion",
    cells: { data: TrafficLightMono },
    width: "200px",
    show: true,
  },
  { field: "remarks", title: "action.remarks", width: "250px", show: true },
  {
    field: "responsible",
    title: "action.responsible",
    width: "250px",
    show: true,
  },
  {
    field: "startDate",
    title: "action.startDate",
    width: "150px",
    show: true,
  },
  { field: "endDate", title: "action.endDate", width: "150px", show: true },
  {
    field: "status",
    title: "action.status",
    cells: { data: ActionStatus },
    width: "150px",
    show: true,
  },
  {
    field: "kpi",
    title: "action.kpi",
    cells: { data: ActionKPIValueAbs },
    width: "250px",
    show: true,
  },
  {
    field: "scoringDifference",
    title: "action.scoringDifference",
    cells: { data: ScoringDifference },
    width: "150px",
    show: true,
  },
];

const columnsPAST = [
  {
    field: "criteriaValue",
    title: "dashboard.actionsTable.criteriaValue",
    width: "300px",
    show: true,
  },
  {
    field: "indicatorValue",
    title: "dashboard.actionsTable.indicatorValue",
    width: "300px",
    show: true,
  },
  { field: "name", title: "dashboard.actionsTable.actions", width: "300px", show: true },
  { field: "description", title: "action.description", width: "600px", show: true },
  {
    field: "suggestion",
    title: "dashboard.actionsTable.priority",
    width: "200px",
    show: true,
    cell: TrafficLightMono,
  },
  { field: "remarks", title: "action.remarks", width: "600px", show: true },
  { field: "responsible", title: "action.responsible", width: "400px", show: true },
  { field: "endDate", title: "action.endDate", width: "250px", show: true },
  {
    field: "status",
    title: "action.status",
    width: "200px",
    show: true,
    cell: ActionStatus,
  },
];

const unsavedSubject = new BehaviorSubject(false);
export const setUnsaved = (v) => unsavedSubject.next(v);
const useUnsaved = hookFromSubject(unsavedSubject);

const defatulFilter = {
  logic: "and",
  filters: [],
};

const initialDataState = {
  take: 10,
  skip: 0,
  group: [],
  filter: defatulFilter,
  sort: [],
};

const updateActionMap = (field, value, dataItem) => (item) =>
  dataItem?.id === item?.id ? { ...dataItem, [field]: value } : item;

const createCriteriaValue = (ratingSystem, element) => {
  let criteria = element;

  while (!conditionIsCategory(ratingSystem?.groupingElementMap[criteria.parentUuid]))
    criteria = ratingSystem?.groupingElementMap[criteria.parentUuid];

  const systemReference = criteria?.systemReference ? criteria.systemReference + " - " : "";
  return conditionIsCriterion(criteria) ? systemReference + criteria.name : null;
};

const createIndicatorValue = (element) => {
  const systemReference = element?.systemReference ? element.systemReference + " - " : "";
  return !element?.type ? systemReference + element.name : null;
};

const actionMap = (ratingSystem, element) => (action) => ({
  ...action,
  indicatorValue: createIndicatorValue(element),
  criteriaValue: createCriteriaValue(ratingSystem, element),
});

const actionsDataMaker = (obj, ratingSystem, data, elementsFieldName, parents = ["all"]) => {
  if (!obj["all"]) obj["all"] = [];

  if (data?.groupingElements?.length)
    data.groupingElements.forEach((d) => {
      if (d.actions.length) {
        const actions = d.actions?.map(actionMap(ratingSystem, d)) ?? [];
        obj[d.name] = [actions].flat();
        obj["all"] = [...obj["all"], actions].flat();
      } else {
        obj[d.name] = [];
      }
      return actionsDataMaker(obj, ratingSystem, d, elementsFieldName, [...parents, d.name]);
    });

  if (data?.[elementsFieldName]?.length)
    data[elementsFieldName].forEach((elem) =>
      parents.forEach((p) => {
        if (elem.actions.length) {
          const actions = elem.actions?.map(actionMap(ratingSystem, elem)) ?? [];
          obj[p] = [...obj[p], actions].flat();
        }
      })
    );
};

export const ActionsTab = ({ ratingSystem, isKpiSystem }) => {
  const { t } = useTranslation();
  const selectedGrouping = useDashboardSelectedGrouping();
  const pathParts = getBreadcrumbPathParts(selectedGrouping, t("dashboard.tabs.actions"));
  const tenantType = getUserTenantType();
  const actionsData = useActionsData();
  const elementsFieldName = isKpiSystem ? "kpiElements" : "indicatorElements";
  const idGetter = getter("id");
  const DATA_ITEM_KEY = "id";
  const SELECTED_FIELD = "selected";

  useEffect(() => {
    const obj = {};
    actionsDataMaker(obj, ratingSystem, ratingSystem, elementsFieldName);
    setActionsData(obj.all ? obj.all : null);
    setKpiListByActionId(getKpiListByActionId(ratingSystem, elementsFieldName));
  }, [ratingSystem]);

  useEffect(
    () => () => {
      setKpiListByActionId({});
      setActionsData([]);
      setEditMap({});
    },
    []
  );

  const [currentSelectedState, setCurrentSelectedState] = useState({});
  const [dataState, setDataState] = useState(initialDataState);
  const [dataResult, setDataResult] = useState({ data: [], total: 0 });
  const [filteredData, setFilteredData] = useState();
  const [data, setData] = useState();
  const unsaved = useUnsaved();

  useEditSafeExit(unsaved);

  useEffect(() => {
    if (actionsData?.length > 0) {
      setFilteredData(actionsData);
      setData(filteredData);
      const processedData = process(actionsData, dataState);
      setDataResult(processedData);
    }
  }, [actionsData, dataState]);

  const processWithGroups = (data, dataState) => {
    const newDataState = process(data, dataState);
    setGroupIds({
      data: newDataState.data,
      group: dataState.group,
    });
    return newDataState;
  };

  const onFilterChange = ({ value }) => {
    setDataState((prev) => {
      const filterIndex = prev.filter?.filters?.findIndex((f) => f.field === "all");
      if (filterIndex !== -1 && filterIndex !== undefined) prev.filter?.filters?.splice(filterIndex, 1);
      if (!prev.filter) prev.filter = defatulFilter;
      if (value) {
        const result = prev.filter.filters.concat([
          {
            field: "all",
            logic: "or",
            filters: (tenantType === TENANT_TYPE_PAST ? columnsPAST : columnsESG).map((column) => ({
              field: column.field,
              value,
              operator: FilterOperator.Contains,
            })),
          },
        ]);

        prev.filter.filters = result;
      }

      return { ...prev };
    });
  };

  const onItemChange = useEvent(({ dataItem, field, value }) =>
    setDataResult((prev) => {
      setUnsaved(true);
      setActionsData((prev) => prev?.map(updateActionMap(field, value, dataItem)));

      return {
        ...prev,
        data: prev?.data?.map(updateActionMap(field, value, dataItem)),
      };
    })
  );

  const [resultState] = useState(
    processWithGroups(
      actionsData.map((item) => ({
        ...item,
        selected: currentSelectedState[idGetter(item)],
      })),
      initialDataState
    )
  );

  const onSave = () => {
    showPromise(
      new Promise((resolve, reject) =>
        updateActions(ratingSystem?.id, getActionsData())
          .then(resolve)
          .catch(reject)
          .finally(() => {
            setUnsaved(false);
            setEditMap({});
          })
      ),
      {
        pending: t("dashboard.actionsTable.actionsSaving"),
        success: t("dashboard.actionsTable.actionsSaved"),
        error: t("dashboard.actionsTable.actionsSaveError"),
      }
    );
  };

  const dataStateChange = (event) => {
    const processedData = process(filteredData, event.dataState);
    processedData.data = processedData.data.map((item) => ({
      ...item,
      selected: currentSelectedState[item[DATA_ITEM_KEY]],
    }));
    setDataResult(processedData);
    setDataState(event.dataState);
  };

  const onExpandChange = useCallback(
    (event) => {
      const newData = [...(dataResult?.data ?? [])];
      const item = event.dataItem;
      if (item.groupId) {
        const targetGroup = newData.find((d) => d.groupId === item.groupId);
        if (targetGroup) {
          targetGroup.expanded = event.value;
          setDataResult({
            ...dataResult,
            data: newData,
          });
        }
      } else {
        item.expanded = event.value;
        setDataResult({
          ...dataResult,
          data: newData,
        });
      }
    },
    [dataResult]
  );

  const onHeaderSelectionChange = useCallback(
    (event) => {
      const checkboxElement = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState = {};
      data?.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setCurrentSelectedState(newSelectedState);
      const newData = data?.map((item) => ({
        ...item,
        [SELECTED_FIELD]: checked,
      }));
      const newDataResult = processWithGroups(newData, dataState);
      setDataResult(newDataResult);
    },
    [data, dataState]
  );

  const onSelectionChange = (event) => {
    const selectedProductId = event.dataItem.id;
    const newSelectedState = {
      ...currentSelectedState,
      [selectedProductId]: !currentSelectedState[selectedProductId],
    };
    setCurrentSelectedState(newSelectedState);
    const newData = data?.map((item) => {
      return {
        ...item,
        selected: newSelectedState[idGetter(item)],
      };
    });
    const newDataResult = processWithGroups(newData, dataState);
    setDataResult(newDataResult);
  };

  return (
    <div className="actions-tab">
      <Breadcrumb pathParts={pathParts} />
      {actionsData?.length > 0 && (
        <ActionTable
          actionsData={actionsData}
          dataResult={dataResult}
          resultState={resultState}
          dataStateChange={dataStateChange}
          dataState={dataState}
          onExpandChange={onExpandChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          onSelectionChange={onSelectionChange}
          onFilterChange={onFilterChange}
          onItemChange={onItemChange}
          mainColumns={tenantType === TENANT_TYPE_PAST ? columnsPAST : columnsESG}
          onSave={onSave}
        />
      )}
      {!actionsData?.length && (
        <div className="k-d-flex k-align-items-center k-gap-3">
          <InfoOutlinedIcon />
          <span>{t("dashboard.actionsTable.noActions")}</span>
        </div>
      )}
    </div>
  );
};

ActionsTab.propTypes = {
  ratingSystem: PropTypes.object,
  isKpiSystem: PropTypes.bool,
};
