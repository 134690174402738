import SearchIcon from "@mui/icons-material/Search";
import Fab from "@mui/material/Fab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import {
  ActionsColumnTemplate,
  GeneralAttributeColumnTemplate,
  TagsColumnTemplate,
  TextColumnTemplate,
  UnitColumnTemplate,
} from "../../components/KPI/KPIColumnTemplates";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { SimpleTableWithSort } from "../../components/SimpleTableWithSort/SimpleTableWithSort";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../constants/main";
import {
  PERMISSION_KPI_CREATE,
  PERMISSION_KPI_EDIT,
  PERMISSION_KPI_LINK_GENERAL_ATTRIBUTE,
} from "../../constants/permissions";
import { DEFAULT_UNIT } from "../../constants/unit";
import { hasPermission } from "../../helpers/permission";
import { checkPopState } from "../../hooks";
import {
  createKPI,
  deleteKPI,
  getKPIGeneralAttributes,
  resetKPIPageState,
  searchKPI,
  updateKPI,
  useKPICreateResponse,
  useKPIDeleteResponse,
  useKPIGeneralAttributesResponse,
  useKPIPageLogic,
  useKPIPageResponse,
  useKPIUpdateResponse,
} from "../../hooks/kpi";
import { showError, showInfo } from "../../hooks/toast";
import { usePageData } from "../../hooks/utils/usePageData";
import { useTimeout } from "../../hooks/utils/useTimeout";
import { PlusSVG } from "../../svg";
import { processMessage } from "../../utils";
import "./KPI.scss";

export const KPIPage = () => {
  const { t } = useTranslation();
  const response = useKPIPageResponse();
  const createResponse = useKPICreateResponse();
  const updateResponse = useKPIUpdateResponse();
  const deleteResponse = useKPIDeleteResponse();
  const kpiGeneralAttributesResponse = useKPIGeneralAttributesResponse();
  const [data, total] = usePageData(response);
  const {
    searchValue,
    setSearchValue,
    sortValue,
    onSort,
    resetSort,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    resetState,
  } = useKPIPageLogic();
  const [, setSearchTimeout] = useTimeout();
  const [init, setInit] = useState(false);

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [processed, setProcessed] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (hasPermission(PERMISSION_KPI_LINK_GENERAL_ATTRIBUTE)) {
      getKPIGeneralAttributes();
    }
  }, []);

  useEffect(() => {
    setProcessed(isAdd ? [{ unit: DEFAULT_UNIT, tags: [] }, ...data] : [...data.map((item) => ({ ...item }))]);
  }, [isAdd, data]);

  const load = () =>
    searchKPI({
      page: page + 1,
      itemsPerPage: rowsPerPage,
      sort: sortValue.field ? (sortValue.desc ? "-" : "") + sortValue.field : undefined,
      search: searchValue,
    }).catch((error) => {
      console.error(error);
      showError(t("error.500"));
    });

  useEffect(() => {
    if (!checkPopState()) {
      resetKPIPageState();
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      load();
    }
  }, [init, searchValue, sortValue.field, sortValue.desc, page, rowsPerPage]);

  const deleteHandler = () => {
    deleteKPI(deleteConfirmation).then(
      () => {
        showInfo(t("kpi.deletedMessage"));
        afterChange();
      },
      () => showError(t("kpi.deleteError"))
    );
    setDeleteConfirmation(null);
  };

  const onValueChange = (props, field, value) => {
    props[field] = value;
    setProcessed([...processed]);
  };

  const showAddRow = () => {
    resetSort();
    setPage(0);
    setIsAdd(true);
  };

  const cancelEdit = () => {
    setIsAdd(false);
    setEditRow(null);
    setSubmitted(false);
    setProcessed([...data.map((item) => ({ ...item }))]);
  };

  const afterChange = () => {
    if (page === 0) {
      load();
    } else {
      setPage(0);
    }
  };

  const afterSave = () => {
    setEditRow(null);
    setIsAdd(false);
    setSubmitted(false);
    afterChange();
  };

  const handleError = (error) => {
    console.error(error);
    if (error.status === 409) {
      showError(t("kpi.uniqueError"));
    } else {
      showError(t("error.500"));
    }
  };

  const save = (values) => {
    setSubmitted(true);
    if (values.name && values.unit) {
      updateKPI(values).then(afterSave).catch(handleError);
    } else {
      showError(t("kpi.requiredError"));
    }
  };
  const create = (values) => {
    setSubmitted(true);
    if (values.name && values.unit) {
      createKPI(values).then(afterSave).catch(handleError);
    } else {
      showError(t("kpi.requiredError"));
    }
  };

  const pagination = {
    labelRowsPerPage: t("kpi.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: total,
    page: page,
    show: !editRow && !isAdd && total > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pageInner) => setPage(pageInner),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  const search = (value) => {
    setSearchTimeout(setTimeout(() => setSearchValue(String(value || "").trim()), 500));
  };

  const isLoading = response.loading || deleteResponse.loading || createResponse.loading || updateResponse.loading;

  return (
    <div className="kpi-page page-with-table">
      <div className="page-header large flex-row">
        <div className="flex-col title-box no-margin">
          <h1>{t("kpi.title")}</h1>
        </div>
        <div className="flex-col search-box no-margin">
          <SearchIcon />
          <input
            type="text"
            key={resetState}
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            placeholder={t("main.search")}
            onChange={(event) => search(event.target.value)}
            disabled={isAdd || editRow}
          />
        </div>
      </div>

      <div className="page-layout">
        <LoadingOverlay spinner className="table-loading auto-height" active={isLoading}>
          <SimpleTableWithSort
            className="repaint"
            data={processed}
            pagination={pagination}
            sortValue={sortValue}
            onSort={onSort}
            disableSort={!!editRow || !!isAdd}
            passThrough={{
              editRow,
              setEditRow,
              isAdd,
              cancelEdit,
              setDeleteConfirmation,
              save,
              create,
              onValueChange,
              submitted,
              t,
              kpiGeneralAttributesResponse,
            }}
            columns={[
              {
                field: "name",
                headerText: t("kpi.name"),
                headerClassName: "name-column",
                template: TextColumnTemplate,
                sortable: true,
              },
              {
                field: "description",
                headerText: t("kpi.description"),
                headerClassName: "description-column",
                template: TextColumnTemplate,
                sortable: true,
              },
              {
                field: "unit",
                headerText: t("kpi.unit"),
                headerClassName: "unit-column",
                template: UnitColumnTemplate,
                sortable: true,
              },
              {
                field: "generalAttributeName",
                headerText: t("kpi.generalAttributeName"),
                headerClassName: "general-attribute-column",
                template: GeneralAttributeColumnTemplate,
                sortable: true,
              },
              {
                field: "tags",
                headerText: t("kpi.tags"),
                headerClassName: "tags-column",
                template: TagsColumnTemplate,
              },
              {
                className: "action-cell",
                headerClassName: "action-header-cell",
                headerText: hasPermission(PERMISSION_KPI_EDIT) && t("main.actions"),
                template: ActionsColumnTemplate,
              },
            ]}
          />
        </LoadingOverlay>
      </div>

      {hasPermission(PERMISSION_KPI_CREATE) && (
        <div className="fabs">
          <Fab type="button" color="primary" disabled={isAdd || editRow} onClick={showAddRow}>
            <PlusSVG className="fab-svg" />
          </Fab>
        </div>
      )}

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        titleText={t("kpi.deleteConfirmationTitle")}
        bodyText={t("kpi.deleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />
    </div>
  );
};
