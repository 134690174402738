import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox, TextBox } from "@progress/kendo-react-inputs";
import { FloatingLabel } from "@progress/kendo-react-labels";
import PropTypes from "prop-types";
import "./FloatingInputs.scss";

const TextBoxSuffix = ({ suffix }) => (suffix ? <span className="floating-inputs-suffix">{suffix}</span> : null);

TextBoxSuffix.propTypes = {
  suffix: PropTypes.string,
};

export const FloatingLabelTextBox = ({
  label,
  name,
  className,
  value,
  inputClassName = "",
  suffix,
  onChange = () => null,
  autoSelect = false,
  readOnly = false,
}) => (
  <FloatingLabel label={label} editorValue={value} className={"floating-inputs" + (className ? ` ${className}` : "")}>
    <TextBox
      fillMode="flat"
      value={value}
      name={name}
      className={inputClassName}
      disabled={readOnly}
      onClick={(e) => autoSelect && !readOnly && e.target?.select?.()}
      onChange={({ value }) => onChange(value)}
      suffix={() => TextBoxSuffix({ suffix })}
    />
  </FloatingLabel>
);

FloatingLabelTextBox.propTypes = {
  label: PropTypes.object,
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  inputClassName: PropTypes.string,
  suffix: PropTypes.string,
  onChange: PropTypes.func,
  autoSelect: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export const FloatingLabelNumericTextBox = ({
  label,
  name,
  className,
  value,
  min = 0,
  max,
  suffix,
  inputClassName = "",
  onChange = () => null,
  autoSelect = false,
  readOnly = false,
}) => (
  <FloatingLabel label={label} editorValue={value} className={"floating-inputs" + (className ? ` ${className}` : "")}>
    <NumericTextBox
      fillMode="flat"
      value={value}
      name={name}
      className={inputClassName}
      step={0}
      spinners={false}
      min={min}
      max={max}
      disabled={readOnly}
      inputStyle={{ textAlign: "right" }}
      onClick={(e) => autoSelect && !readOnly && e.target?.select?.()}
      onChange={({ value }) => onChange(value)}
      suffix={() => TextBoxSuffix({ suffix })}
    />
  </FloatingLabel>
);

FloatingLabelNumericTextBox.propTypes = {
  label: PropTypes.object,
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  min: PropTypes.number,
  max: PropTypes.number,
  inputClassName: PropTypes.string,
  suffix: PropTypes.string,
  onChange: PropTypes.func,
  autoSelect: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export const FloatingLabelDropdownList = ({
  label,
  name,
  className,
  value,
  itemRender,
  valueRender,
  inputClassName = "",
  data = [],
  textField = "text",
  dataItemKey = "value",
  onChange = () => null,
  readOnly = false,
}) => (
  <FloatingLabel label={label} editorValue={value} className={"floating-inputs" + (className ? ` ${className}` : "")}>
    <DropDownList
      fillMode="flat"
      value={value}
      name={name}
      className={inputClassName}
      data={data}
      textField={textField}
      dataItemKey={dataItemKey}
      itemRender={itemRender}
      valueRender={valueRender}
      disabled={readOnly}
      onChange={({ value }) => onChange(value)}
    />
  </FloatingLabel>
);

FloatingLabelDropdownList.propTypes = {
  label: PropTypes.object,
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  itemRender: PropTypes.func,
  valueRender: PropTypes.func,
  inputClassName: PropTypes.string,
  data: PropTypes.array,
  textField: PropTypes.string,
  dataItemKey: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
