import { TextFieldController } from "./TextFieldController";
import {
  TYPE_CHECKBOX,
  TYPE_COMBOBOX,
  TYPE_DATE,
  TYPE_LOCATION,
  TYPE_NUMBER_DECIMAL,
  TYPE_NUMBER_INTEGER,
  TYPE_SELECT,
  TYPE_TEXT_AREA,
  TYPE_TEXT_INPUT
} from "../../constants/projectAttribute";
import { NumberFieldController } from "./NumberFieldController";
import { DateFieldController } from "./DateFieldController";
import { SelectFieldController } from "./SelectFieldController";
import { ComboFieldController } from "./ComboFieldController";
import { CheckboxFieldController } from "./CheckboxFieldController";
import { LocationFieldController } from "./LocationFieldController";

export const FieldController = (props) => {
  const { type, decimalPrecision } = props;

  if (!type || type === TYPE_TEXT_INPUT || type === TYPE_TEXT_AREA) {
    return (
      <TextFieldController {...props} multiline={type === TYPE_TEXT_AREA}/>
    );
  } else if (type === TYPE_NUMBER_INTEGER || type === TYPE_NUMBER_DECIMAL) {
    return (
      <NumberFieldController {...props} decimalPrecision={type === TYPE_NUMBER_INTEGER ? '' : decimalPrecision}/>
    );
  } else if (type === TYPE_DATE) {
    return (
      <DateFieldController {...props}/>
    );
  } else if (type === TYPE_SELECT) {
    return (
      <SelectFieldController {...props}/>
    );
  } else if (type === TYPE_COMBOBOX) {
    return (
      <ComboFieldController {...props}/>
    );
  } else if (type === TYPE_CHECKBOX) {
    return (
      <CheckboxFieldController {...props}/>
    );
  } else if (type === TYPE_LOCATION) {
    return (
      <LocationFieldController {...props}/>
    );
  }

  return null;
};
