import {
  NumericTd,
  PercentTd,
  SPTNumericFilter,
  SPTTextFilter,
  TextTd,
  TranslateTh,
} from "../../components/TreeList/treeListComponents";
import {
  ActualNumericFilter,
  ActualPogressSquare,
  ActualSuperScoreTd,
  ActualSystemScoreTd,
  ActualTh,
  ExcludeTd,
  IndicatorResponsible,
  IndicatorStatus,
  IndicatorSystemScore,
  MaxSystemScoreTd,
  PotentialNumericFilter,
  PotentialPogressSquare,
  PotentialScoreTd,
  PotentialSuperScoreTd,
  PotentialTh,
  SPTExcludeFilter,
  SPTStatusFilter,
  SPTTrafficLightFilter,
  StatusRatingSystemESG,
  StatusTh,
  TrafficLightRatingSystemESG,
} from "./NewRatingSystemESG.components";
import { initializeColumns } from "./NewRatingSystemESG.util";

export const columns = initializeColumns([
  {
    field: "systemReference",
    title: "newRatingSystem.esg.columns.systemReference",
    width: 100,
    minResizableWidth: 100,
    reorderable: false,
    locked: true,
    filterCell: SPTTextFilter,
    headerCell: TranslateTh,
    cell: TextTd,
  },
  {
    field: "name",
    title: "newRatingSystem.esg.columns.name",
    expandable: true,
    width: 200,
    minResizableWidth: 200,
    reorderable: false,
    locked: true,
    filterCell: SPTTextFilter,
    headerCell: TranslateTh,
    cell: TextTd,
  },
  {
    field: "trafficLight",
    title: "newRatingSystem.esg.columns.status.trafficLight",
    cell: TrafficLightRatingSystemESG,
    width: 97,
    minResizableWidth: 97,
    filterCell: SPTTrafficLightFilter,
    headerCell: TranslateTh,
  },
  {
    field: "indicatorStatus",
    title: "newRatingSystem.esg.columns.status.indicatorStatus",
    cell: StatusRatingSystemESG,
    width: 180,
    minResizableWidth: 180,
    filterCell: SPTStatusFilter,
    headerCell: StatusTh,
  },
  {
    headerClassName: "primary",
    field: "evaluationSystemScore",
    title: "newRatingSystem.esg.columns.actualScore.systemScore",
    width: 100,
    minResizableWidth: 100,
    filterCell: ActualNumericFilter,
    cell: ActualSystemScoreTd,
    headerCell: TranslateTh,
  },
  {
    headerClassName: "primary",
    field: "universalScore",
    title: "newRatingSystem.esg.columns.actualScore.superScore",
    width: 100,
    minResizableWidth: 100,
    filterCell: ActualNumericFilter,
    cell: ActualSuperScoreTd,
    headerCell: TranslateTh,
  },
  {
    headerClassName: "primary",
    field: "progress",
    title: "newRatingSystem.esg.columns.actualScore.progress",
    cell: ActualPogressSquare,
    width: 75,
    minResizableWidth: 75,
    filterCell: ActualTh,
    sortable: false,
    headerCell: TranslateTh,
  },
  {
    headerClassName: "secondary",
    field: "potentialEvaluationSystemScore",
    title: "newRatingSystem.esg.columns.potentialScore.systemScore",
    width: 100,
    minResizableWidth: 100,
    filterCell: PotentialNumericFilter,
    cell: PotentialScoreTd,
    headerCell: TranslateTh,
  },
  {
    headerClassName: "secondary",
    field: "potentialUniversalScore",
    title: "newRatingSystem.esg.columns.potentialScore.superScore",
    width: 100,
    minResizableWidth: 100,
    filterCell: PotentialNumericFilter,
    cell: PotentialSuperScoreTd,
    headerCell: TranslateTh,
  },
  {
    headerClassName: "secondary",
    field: "potentialProgress",
    title: "newRatingSystem.esg.columns.potentialScore.progress",
    cell: PotentialPogressSquare,
    width: 75,
    minResizableWidth: 75,
    filterCell: PotentialTh,
    sortable: false,
    headerCell: TranslateTh,
  },
  {
    field: "responsible",
    title: "newRatingSystem.esg.columns.responsibility",
    width: 200,
    minResizableWidth: 200,
    hidable: true,
    filterCell: SPTTextFilter,
    headerCell: TranslateTh,
    cell: TextTd,
  },
  {
    field: "weightedSystemScore",
    title: "newRatingSystem.esg.columns.actualFulfillment.weightedSystemScore",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTTextFilter,
    cell: NumericTd,
    headerCell: TranslateTh,
  },
  {
    field: "degreeOfFulfillment",
    title: "newRatingSystem.esg.columns.actualFulfillment.relativeDegreeOfFulfillment",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: PercentTd,
    headerCell: TranslateTh,
  },
  {
    field: "absoluteDegreeOfFulfillment",
    title: "newRatingSystem.esg.columns.actualFulfillment.absoluteDegreeOfFulfillment",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: PercentTd,
    headerCell: TranslateTh,
  },
  {
    field: "weightedPotentialSystemScore",
    title: "newRatingSystem.esg.columns.potentialFulfillment.weightedSystemScore",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: NumericTd,
    headerCell: TranslateTh,
  },
  {
    field: "relativeDegreeOfFulfillmentPotential",
    title: "newRatingSystem.esg.columns.potentialFulfillment.relativeDegreeOfFulfillment",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: PercentTd,
    headerCell: TranslateTh,
  },
  {
    field: "absoluteDegreeOfFulfillmentPotential",
    title: "newRatingSystem.esg.columns.potentialFulfillment.absoluteDegreeOfFulfillment",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: PercentTd,
    headerCell: TranslateTh,
  },
  {
    field: "differenceRelativeDegreeOfFulfillment",
    title: "newRatingSystem.esg.columns.differenceFulfillment.relativeDegreeOfFulfillment",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: PercentTd,
    headerCell: TranslateTh,
  },
  {
    field: "differenceAbsoluteDegreeOfFulfillment",
    title: "newRatingSystem.esg.columns.differenceFulfillment.absoluteDegreeOfFulfillment",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: PercentTd,
    headerCell: TranslateTh,
  },
  {
    field: "weightingFactor",
    title: "newRatingSystem.esg.columns.weight",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: NumericTd,
    headerCell: TranslateTh,
  },
  {
    field: "maxSystemScore",
    title: "newRatingSystem.esg.columns.maxSystemScore",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: MaxSystemScoreTd,
    headerCell: TranslateTh,
  },
  {
    field: "maxSystemScoreProportion",
    title: "newRatingSystem.esg.columns.proportionOfMaxSystemScore",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: PercentTd,
    headerCell: TranslateTh,
  },
  {
    field: "koValue",
    title: "newRatingSystem.esg.columns.koValue",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: NumericTd,
    headerCell: TranslateTh,
  },
  {
    field: "weightedMaxSystemScore",
    title: "newRatingSystem.esg.columns.weightedMaxSystemScore",
    width: 100,
    minResizableWidth: 100,
    hidable: true,
    filterCell: SPTNumericFilter,
    cell: NumericTd,
    headerCell: TranslateTh,
  },
  {
    field: "excluded",
    title: "newRatingSystem.esg.columns.exclude",
    width: 100,
    minResizableWidth: 100,
    hidable: false,
    filterCell: SPTExcludeFilter,
    cell: ExcludeTd,
    headerCell: TranslateTh,
  },
]);

export const editCellMap = {
  indicatorStatus: IndicatorStatus,
  evaluationSystemScore: IndicatorSystemScore,
  potentialEvaluationSystemScore: IndicatorSystemScore,
  responsible: IndicatorResponsible,
};

export const filterCellMap = {
  systemReference: SPTTextFilter,
  name: SPTTextFilter,
  trafficLight: SPTTrafficLightFilter,
  indicatorStatus: SPTStatusFilter,
  evaluationSystemScore: ActualNumericFilter,
  universalScore: ActualNumericFilter,
  progress: ActualTh,
  potentialEvaluationSystemScore: PotentialNumericFilter,
  potentialUniversalScore: PotentialNumericFilter,
  potentialProgress: PotentialTh,
  responsible: SPTTextFilter,
  weightedSystemScore: SPTTextFilter,
  degreeOfFulfillment: SPTNumericFilter,
  absoluteDegreeOfFulfillment: SPTNumericFilter,
  weightedPotentialSystemScore: SPTNumericFilter,
  relativeDegreeOfFulfillmentPotential: SPTNumericFilter,
  absoluteDegreeOfFulfillmentPotential: SPTNumericFilter,
  differenceRelativeDegreeOfFulfillment: SPTNumericFilter,
  differenceAbsoluteDegreeOfFulfillment: SPTNumericFilter,
  weightingFactor: SPTNumericFilter,
  maxSystemScore: SPTNumericFilter,
  maxSystemScoreProportion: SPTNumericFilter,
  koValue: SPTNumericFilter,
  weightedMaxSystemScore: SPTNumericFilter,
  excluded: SPTExcludeFilter,
};
