import * as d3 from "d3";

const radius = 150;
const width = radius * 2;
const lineWidth = 12;

const grayArc = d3.arc()
  .innerRadius(radius - lineWidth)
  .outerRadius(radius)
  .startAngle(0)
  .endAngle(Math.PI * 2);

const createScoreArc = score => d3.arc()
  .innerRadius(radius - lineWidth)
  .outerRadius(radius)
  .startAngle(0)
  .endAngle(Math.PI * 2 * ((score - 1) / 4 * 100) / 100);

export const generateGroupingElementSvgPAST = (universalScore = 0) => {
  const svg = d3.create("svg");
  const element = svg.node();

  svg
    .attr("viewBox", `${-radius} ${-radius} ${width} ${width}`)
    .style("max-width", `${width}px`)
    .style("font-size", "20px");

  svg
    .append("g")
    .attr("fill", 'var(--NEUTRAL_BACKGROUND_COLOR)')
    .append("path")
    .attr("d", grayArc);

  if (universalScore !== 0) {
    svg
      .append("g")
      .attr("fill", 'var(--PRIMARY_BACKGROUND_COLOR)')
      .append("path")
      .attr("d", createScoreArc(universalScore));
  }

  return element;
};
