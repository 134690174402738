import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import { Checkbox, FormControlLabel, IconButton, Popover, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEvent } from "../../hooks/utils/useEvent";

const ColumnCheckbox = ({ column, value, onChange, onChildChange, translate = false }) => {
  const { t } = useTranslation();

  const hasChildren = !!column?.children?.length;
  const some = hasChildren && column.children.some((c) => !value[c.field]?.hidden);
  const every = hasChildren && column.children.every((c) => !value[c.field]?.hidden);

  const checked = (!hasChildren || every) && !value[column.field ?? column.title]?.hidden;
  const indeterminate = hasChildren && !every && some;

  const handleChange = useEvent((event) => {
    let result = { [event.target.name]: { ...value[event.target.name], hidden: !event.target.checked } };
    if (hasChildren) {
      const override = event.target.name === column.title;
      column.children
        .filter((child) => !result[child.field ?? child.title])
        .map((child) => ({
          [child.field ?? child.title]: {
            ...value[child.field ?? child.title],
            hidden: (override && !event.target.checked) || (!override && value[child.field ?? child.title].hidden),
          },
        }))
        .forEach((r) => (result = { ...result, ...r }));

      if (!override) {
        const allHidden = Object.values(result).every((v) => v.hidden);
        result = { ...result, [column.title]: { ...value[column.title], hidden: allHidden } };
      }
    }

    return onChange(result);
  });

  return (
    <div className="nested" key={column.id}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onChange={onChildChange ?? handleChange}
            name={column.field ?? column.title}
          />
        }
        label={translate ? t(column.title) : column.title}
      />
      {column?.children?.map((child) => (
        <ColumnCheckbox
          key={child.id}
          column={child}
          value={value}
          onChange={onChange}
          onChildChange={handleChange}
          translate
        />
      ))}
    </div>
  );
};

ColumnCheckbox.propTypes = {
  column: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onChildChange: PropTypes.func,
  translate: PropTypes.bool,
};

export const HideColumnMenu = React.memo(({ columns, hiddenColumnMap, onChange }) => {
  const target = useRef(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleAllChange = useEvent((event) => {
    const { checked } = event.target;
    const result = { ...hiddenColumnMap };
    for (const key in result) result[key] = { ...result[key], hidden: !checked };

    onChange(result);
  });

  const handleChange = useEvent((event) => {
    const result = {
      ...hiddenColumnMap,
      ...event,
    };
    onChange(result);
  });

  const some = Object.values(hiddenColumnMap).some(({ hidden }) => !hidden);
  const every = Object.values(hiddenColumnMap).every(({ hidden }) => !hidden);

  return (
    <>
      <Tooltip title={t("newRatingSystem.esg.tableOptions.showHideColumns")} PopperProps={{ style: { zIndex: 999 } }}>
        <IconButton ref={target} onClick={() => setOpen((prev) => !prev)} size="small" className="k-p-0">
          <ViewWeekIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className="hide-column-menu"
        open={open}
        anchorEl={target.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        onClose={() => setOpen(false)}
      >
        <div className="root">
          <FormControlLabel
            control={<Checkbox indeterminate={some && !every} checked={every} onChange={handleAllChange} />}
            label="Select / Deselect All"
          />
          {columns
            .filter((column) => column?.hidable || column?.children?.some((child) => child?.hidable))
            .map((column) => (
              <ColumnCheckbox
                key={column.id}
                column={column}
                value={hiddenColumnMap}
                onChange={handleChange}
                translate
              />
            ))}
        </div>
      </Popover>
    </>
  );
});

HideColumnMenu.propTypes = {
  columns: PropTypes.array,
  hiddenColumnMap: PropTypes.object,
  onChange: PropTypes.func,
};
