import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { KEY_CODE_ENTER, KEY_CODE_SPACE, KEY_CODE_TAB } from "../../constants/main";
import "./Chips.scss";

const TAG_KEY_CODES = [KEY_CODE_ENTER, KEY_CODE_TAB, KEY_CODE_SPACE];

export const Chips = ({ chips, onChange, readOnly, noWrapper, className, changeOnBlur }) => {
  const { t } = useTranslation();
  const items = chips || [];

  const [value, setValue] = useState("");

  const addTag = (valueAddTag) => {
    const tag = valueAddTag.trim().split(" ").join("");
    if (tag && items.indexOf(tag) === -1) {
      onChange([...items, valueAddTag]);
    }
  };

  const onKeyDown = (event) => {
    if (TAG_KEY_CODES.indexOf(event.keyCode) !== -1) {
      event.stopPropagation();
      event.preventDefault();
      addTag(value);
      setValue("");
    }
  };

  const onKeyUp = (event) => {
    if (TAG_KEY_CODES.indexOf(event.keyCode) !== -1) {
      event.stopPropagation();
      event.preventDefault();
    }
    setValue(event.target.value);
  };

  const onDelete = (index) => {
    const updated = [...items];
    updated.splice(index, 1);
    onChange(updated);
  };

  const onBlur = (event) => {
    if (changeOnBlur) {
      addTag(event.target.value);
      setValue("");
    }
  };

  const content = (
    <>
      {items.map((item, index) => (
        <Chip
          key={getUUID()}
          clickable={false}
          onDelete={readOnly ? undefined : () => onDelete(index)}
          label={item}
          icon={<LocalOfferOutlinedIcon fontSize="small" />}
        />
      ))}
      {!readOnly && (
        <div className="MuiChip-root add-chip">
          <LocalOfferOutlinedIcon fontSize="small" className="MuiChip-icon" />
          <input
            type="text"
            value={value}
            placeholder={t("files.addTag")}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onChange={onKeyUp}
            onBlur={onBlur}
          />
        </div>
      )}
    </>
  );

  return noWrapper ? content : <div className={"chips " + (className || "")}>{content}</div>;
};

Chips.propTypes = {
  chips: PropTypes.array,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  noWrapper: PropTypes.bool,
  className: PropTypes.string,
  changeOnBlur: PropTypes.bool,
};
