import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ESG_TYPES, KPI_PREFIX } from "../../../constants/project";
import { OTHER_TYPES } from "../../../constants/sustainabilitySystem";
import { TENANT_TYPE_ESG } from "../../../constants/tenant";
import { processSystemsTree } from "../../../helpers/sustainabilitySystem";
import { getUserTenantType } from "../../../hooks/auth";
import { getProjectSystems, useProjectSystemsResponse } from "../../../hooks/project";
import { useEvent } from "../../../hooks/utils/useEvent";
import {
  EXPAND_FIELD,
  getProjectsTableState,
  setProjectsTableState,
  useProjectsTableState,
} from "../../../pages/Projects/ProjectsTable";
import { mapRecursive } from "../../../utils";
import { FilterPopover } from "../../FilterPopover/FilterPopover";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import "./ProjectFilter.scss";

export const ProjectFilter = ({ filters, onChangeFilters, hideSystem }) => {
  const { t } = useTranslation();
  const tenantType = getUserTenantType();

  useEffect(() => {
    getProjectSystems().catch(console.error);
  }, []);
  const response = useProjectSystemsResponse();
  const systems = response.data?.sustainabilitySystems || [];
  const kpiSystems = (response.data?.kpiSystems || []).map((item) => ({ ...item, id: KPI_PREFIX + item.id }));
  const systemsTree = processSystemsTree([...systems, ...kpiSystems]);

  const selectedSystems = useMemo(() => {
    const selectedSystemsInner = [];
    (filters?.sustainabilitySystemId || []).forEach((id) => selectedSystemsInner.push(id));
    (filters?.kpiSystemId || []).forEach((id) => selectedSystemsInner.push(id));
    return selectedSystemsInner;
  }, [filters?.sustainabilitySystemId, filters?.kpiSystemId]);

  const onChange = useEvent((selectedSystemsOnChange) => {
    const sustainabilitySystemsInner = [];
    const kpiSystemsInner = [];

    selectedSystemsOnChange?.forEach((item) => {
      if (String(item).indexOf(KPI_PREFIX) !== -1) {
        kpiSystemsInner.push(item);
      } else {
        sustainabilitySystemsInner.push(item);
      }
    });
    onChangeFilters({ ...filters, sustainabilitySystemId: sustainabilitySystemsInner, kpiSystemId: kpiSystemsInner });
  });

  const { collapseAll } = useProjectsTableState();
  const onExpandCollapse = useEvent(() => {
    const { collapseAll, skip, take } = getProjectsTableState();
    setProjectsTableState((prev) => {
      for (let index = skip; index < skip + take; index++) {
        prev.data[index][EXPAND_FIELD] = !collapseAll;
        if (prev.data[index][EXPAND_FIELD]) prev.visibleRatingSystems += prev.data[index]?.children?.length;
        else prev.visibleRatingSystems -= prev.data[index]?.children?.length;
      }

      prev.collapseAll = !collapseAll;
      return { ...prev };
    });
  });

  return (
    <div className="project-filter k-d-flex k-align-items-start">
      <Button onClick={onExpandCollapse}>
        {collapseAll ? t("sustainabilitySystem.filter.collapseAll") : t("sustainabilitySystem.filter.expandAll")}
        {collapseAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Button>
      {tenantType === TENANT_TYPE_ESG && (
        <FilterPopover
          values={filters?.projectTypes || []}
          onChange={(projectTypes) => onChangeFilters({ ...filters, projectTypes })}
          title={t("sustainabilitySystem.filter.projectTypes")}
          items={mapRecursive(
            tenantType === TENANT_TYPE_ESG ? ESG_TYPES : OTHER_TYPES,
            (item, children) => ({
              ...item,
              text: t("sustainabilitySystem.type." + item.name),
              items: children,
            }),
            "items"
          )}
          multiple
          collapsible
          defaultExpanded={true}
        />
      )}

      {!hideSystem && (
        <LoadingOverlay spinner active={response.loading} className="auto-height">
          <FilterPopover
            values={selectedSystems}
            onChange={onChange}
            title={t("project.filter.sustainabilitySystems")}
            items={systemsTree}
            multiple
            collapsible
          />
        </LoadingOverlay>
      )}
    </div>
  );
};

ProjectFilter.propTypes = {
  filters: PropTypes.object,
  onChangeFilters: PropTypes.func,
  hideSystem: PropTypes.bool,
};
