import { SimpleSelectController } from "../SimpleSelectController/SimpleSelectController";

export const SelectFieldController = ({ control, name, required, label, readOnly, options }) => {
  return (
    <SimpleSelectController
      control={control} name={name} required={required} disabled={readOnly} allowClear={!required} label={label}
      options={(options || []).map(({ name: nameInner, text }) => ({ name: nameInner, text }))}
    />
  )
};
