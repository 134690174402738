import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DialogActions, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { IntlProvider, LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { Upload } from "@progress/kendo-react-upload";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMAGE_UPLOAD_RESTRICTIONS } from "../../../../constants/fileUpload";
import { SUCCESS_STATUS_CODE } from "../../../../constants/http";
import { AWARD_CALCULATION_TYPE_PERCENT } from "../../../../constants/sustainabilitySystem";
import {
  getRestrictedFileExtension,
  getRestrictedFileSize,
  showKendoValidationErrors,
} from "../../../../helpers/fileUpload";
import { usePostAwardFileResponse } from "../../../../hooks/awardFile";
import { showError } from "../../../../hooks/toast";
import { FormController } from "../../../FormController/FormController";
import { LoadingOverlay } from "../../../LoadingOverlay/LoadingOverlay";
import "./AwardDialog.scss";

import kendoDeMessages from "./../../../../locales/kendo-de.json";
import kendoEnMessages from "./../../../../locales/kendo-en.json";

loadMessages(kendoEnMessages, "en");
loadMessages(kendoDeMessages, "de");

export const AwardDialog = ({
  open,
  onClose,
  data,
  onSave,
  setFile,
  readOnly,
  awardCalculationType,
  restrictions = IMAGE_UPLOAD_RESTRICTIONS,
}) => {
  const { t } = useTranslation();
  const postAwardFileResponse = usePostAwardFileResponse();
  const [fileValidationError, setFileValidationError] = useState(false);

  const apiFileUrl = "/api/sustainability/award/file";
  const messages = i18next.language === "de" ? kendoDeMessages : kendoEnMessages;

  const { control, reset, getValues, trigger } = useForm({
    defaultValues: { title: "", scoreFrom: "", scoreTo: "", actualSideThreshold: "", potentialSideThreshold: "" },
  });

  useEffect(() => {
    if (open) {
      reset({
        title: data.title || "",
        scoreFrom: data.scoreFrom || "",
        scoreTo: data.scoreTo || "",
        actualSideThreshold: data.actualSideThreshold || "",
        potentialSideThreshold: data.potentialSideThreshold || "",
      });
    }
  }, [open]);

  const isPercent = awardCalculationType === AWARD_CALCULATION_TYPE_PERCENT || awardCalculationType == null;
  const unit = isPercent ? "%" : "";

  const onAdd = ({ affectedFiles }) => {
    showKendoValidationErrors({ affectedFiles }, messages, showError);
    setFileValidationError(affectedFiles.some((x) => !!x.validationErrors && x.validationErrors.length > 0));
  };

  const onStatusChange = ({ response }) => {
    if (response?.status === SUCCESS_STATUS_CODE) {
      setFile(response.response.id);
    }
  };

  const onSubmit = async () => {
    const result = await trigger();
    if (result) {
      onSave({ ...data, ...getValues() });
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="award-dialog">
      <LoadingOverlay spinner active={postAwardFileResponse.loading} className="auto-height">
        <DialogTitle>
          <IconButton onClick={onClose} size="small">
            <CloseOutlinedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormController
            control={control}
            name="title"
            required
            label={t("award.title")}
            render={({ field, fieldState, label }) => (
              <TextField
                error={fieldState.invalid}
                fullWidth
                inputProps={{ maxLength: 50 }}
                label={label + " *"}
                {...field}
                disabled={readOnly}
              />
            )}
          />
          <div className="flex-row">
            <label className="label-with-margin field-label">
              {isPercent ? t("award.rangePercents") : t("award.rangePoints")}
            </label>
            <FormController
              rules={{ min: 0, max: isPercent ? 100 : undefined }}
              control={control}
              name="scoreFrom"
              required
              label={t("award.scoreFrom")}
              render={({ field, fieldState, label }) => (
                <TextField
                  type="number"
                  error={fieldState.invalid}
                  className="input-with-margin"
                  inputProps={{ step: 0.001 }}
                  InputProps={{ endAdornment: <InputAdornment position="end">{unit}</InputAdornment> }}
                  label={label + " *"}
                  {...field}
                  disabled={readOnly}
                />
              )}
            />
            <label className="label-with-margin">{t("award.to")}</label>
            <FormController
              rules={{ min: 0, max: isPercent ? 100 : undefined }}
              control={control}
              name="scoreTo"
              required
              label={t("award.scoreTo")}
              render={({ field, fieldState, label }) => (
                <TextField
                  type="number"
                  error={fieldState.invalid}
                  className="input-with-margin"
                  inputProps={{ step: 0.001 }}
                  InputProps={{ endAdornment: <InputAdornment position="end">{unit}</InputAdornment> }}
                  label={label + " *"}
                  {...field}
                  disabled={readOnly}
                />
              )}
            />
          </div>

          <div className="award-image-wrapper">
            {!!data.fileId && (
              <img alt="" className="award-image" src={`/api/sustainability/award/file/${data.fileId}/preview`} />
            )}
          </div>

          <LocalizationProvider language={i18next.language}>
            <IntlProvider locale={i18next.language}>
              <Upload
                batch={false}
                multiple={false}
                defaultFiles={[]}
                withCredentials={false}
                saveUrl={apiFileUrl}
                saveField="file"
                onAdd={onAdd}
                onStatusChange={onStatusChange}
                restrictions={restrictions}
              />
            </IntlProvider>
          </LocalizationProvider>
          {fileValidationError && (
            <div className="file-upload-restrictions">
              <div>
                <small>
                  {t("fileUpload.allowedExtensions")} {getRestrictedFileExtension(IMAGE_UPLOAD_RESTRICTIONS)}
                </small>
              </div>
              <div>
                <small>
                  {t("fileUpload.maximumFileSize")} {getRestrictedFileSize(IMAGE_UPLOAD_RESTRICTIONS)}
                </small>
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          {!!data.fileId && (
            <Button color="secondary" className="pull-left" onClick={() => setFile(null)}>
              {t("award.deletePicture")}
            </Button>
          )}
          <Button onClick={() => onClose()}>{t("main.close")}</Button>
          {!readOnly && (
            <Button color="primary" onClick={onSubmit}>
              {t("main.save")}
            </Button>
          )}
        </DialogActions>
      </LoadingOverlay>
    </Dialog>
  );
};
