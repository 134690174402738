import { Hint } from "@progress/kendo-react-labels";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { EvaluationReason } from "../../../components/RatingSystem/EvaluationReason/EvaluationReason";
import {
  CUSTOM_VALUE,
  INDICATOR_VALUE_TYPE_RANGE,
  POTENTIAL_CUSTOM_TAG,
} from "../../../constants/sustainabilitySystem";
import { addBreakLines, adjustRGBFormat, removeExtraBreakLines } from "../../../helpers/richtext";
import { roundDecimal } from "../../../utils";
import { FloatingLabelDropdownList, FloatingLabelNumericTextBox, FloatingLabelTextBox } from "../FloatingInputs";
import { systemScoreItemRender, systemScoreValueRender } from "../NewRatingSystemESG.util";
import { conditionIsIndicator, conditionNotIndicator } from "../NewRatingSystemESG.validation";
import { BaseBlock } from "./BaseBlock";

export const POTENTIAL_BLOCK_ESG_ID = "potentialBlock";
export const POTENTIAL_KPI_SUB_BLOCK_ID = "potentialSystemScoreSubBlock";
export const POTENTIAL_SYSTEM_SCORE_SUB_BLOCK_ID = "potentialKpiSubBlock";
export const POTENTIAL_REASON_SUB_BLOCK_ID = "potentialReasonSubBlock";
export const POTENTIAL_VALUE_SUB_BLOCK_ID = "potentialValueSubBlock";

const KPISubBlock = ({ dataItem, readOnly, onChange = () => null }) => {
  const { t } = useTranslation();
  if (!dataItem?.kpi || conditionNotIndicator(dataItem)) return null;

  const onKpiValueChange = (value) => {
    const kpi = { ...dataItem.kpi, potentialValue: value };
    onChange([{ dataItem, field: "kpi", value: kpi }]);
  };

  return (
    <div className="k-d-flex k-flex-col k-gap-3 k-px-5" id={POTENTIAL_KPI_SUB_BLOCK_ID}>
      <span className="k-text-uppercase k-font-bold">{t("newRatingSystem.esg.sideMenu.potentialBlock.kpi")}</span>
      <FloatingLabelNumericTextBox
        label={dataItem?.kpi?.name}
        className="k-flex-1"
        suffix={dataItem?.kpi?.unit}
        readOnly={readOnly}
        name="potentialValue"
        value={dataItem?.kpi?.potentialValue}
        onChange={onKpiValueChange}
        autoSelect
      />
      <Hint className="kpi-warning">
        {t("ratingSystem.indicator.kpiWarning", { name: dataItem?.kpi?.linkedGeneralAttributeName })}
      </Hint>
    </div>
  );
};

KPISubBlock.propTypes = {
  dataItem: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

const ReasonSubBlock = ({ dataItem, system, readOnly, onChange = () => null }) => {
  const { t } = useTranslation();
  if (conditionNotIndicator(dataItem)) return null;

  const saveChangesReason = (reasonEditorValue) => {
    if (reasonEditorValue) {
      const { event } = reasonEditorValue;
      let html = event?.html ?? "";

      html = adjustRGBFormat(html);
      html = addBreakLines(html);
      html = removeExtraBreakLines(html);
      onChange([{ dataItem, field: "potentialReason", value: html }]);
    }
  };

  return (
    <div className="k-px-5 k-d-flex k-flex-col k-gap-3" id={POTENTIAL_REASON_SUB_BLOCK_ID}>
      <span className="k-text-uppercase k-font-bold">{t("newRatingSystem.esg.sideMenu.potentialBlock.reason")}</span>
      <EvaluationReason
        itemUid={dataItem?.uuid}
        value={dataItem?.potentialReason ?? ""}
        onBlur={saveChangesReason}
        ratingSystemId={system?.id}
        readOnly={readOnly}
      />
      {readOnly && (
        <div className="rich-text-content" dangerouslySetInnerHTML={{ __html: dataItem?.potentialReason ?? "" }} />
      )}
    </div>
  );
};

ReasonSubBlock.propTypes = {
  dataItem: PropTypes.object,
  system: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

const ValueSubBlock = ({ dataItem, readOnly, onChange = () => null }) => {
  const { t } = useTranslation();
  if (!dataItem?.visibleOnTable || conditionNotIndicator(dataItem)) return null;

  return (
    <div className="k-px-5" id={POTENTIAL_VALUE_SUB_BLOCK_ID}>
      <FloatingLabelTextBox
        label={t("newRatingSystem.esg.sideMenu.potentialBlock.value")}
        className="k-w-full"
        suffix={dataItem?.unit}
        name="potentialEvaluationScoringValue"
        value={dataItem?.potentialEvaluationScoringValue ?? ""}
        onChange={(value) => onChange([{ dataItem, field: "potentialEvaluationScoringValue", value }])}
        readOnly={readOnly}
        autoSelect
      />
    </div>
  );
};

ValueSubBlock.propTypes = {
  dataItem: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

const SystemScoreSubBlock = ({ dataItem, readOnly, onChange = () => null }) => {
  const { t } = useTranslation();

  const isIndicator = conditionIsIndicator(dataItem);
  const isValueRange = isIndicator && dataItem?.valueType === INDICATOR_VALUE_TYPE_RANGE;
  const isCustomValue =
    isIndicator && isValueRange && dataItem?.allowCustomValue && dataItem?.potentialEvaluationValue === CUSTOM_VALUE;

  const valueRangeList =
    isValueRange &&
    (dataItem?.valueRangeList ?? [])
      .concat(
        dataItem?.allowCustomValue && dataItem?.valueRangeList?.every((item) => item?.value !== CUSTOM_VALUE)
          ? [{ value: CUSTOM_VALUE, systemScore: "" }]
          : []
      )
      .filter((item) => !item?.custom);

  const valueRangeValue = isValueRange && valueRangeList?.find((x) => x.value === dataItem?.potentialEvaluationValue);

  const customValue =
    isValueRange &&
    dataItem?.valueRangeList?.find((item) => item?.custom && item?.customValueTag === POTENTIAL_CUSTOM_TAG);

  const systemScoreChange =
    !isValueRange && ((value) => onChange([{ dataItem, field: "potentialEvaluationSystemScore", value }]));

  const evaluationValueChange =
    isValueRange &&
    (({ systemScore, value }) => {
      const appendCustomValue = customValue
        ? []
        : [
            {
              dataItem,
              field: "valueRangeList",
              value: dataItem?.valueRangeList?.concat([
                { custom: true, value: "", systemScore: "", customValueTag: POTENTIAL_CUSTOM_TAG },
              ]),
            },
          ];

      onChange([
        ...(value !== CUSTOM_VALUE
          ? [{ dataItem, field: "potentialEvaluationSystemScore", value: systemScore }]
          : appendCustomValue),
        { dataItem, field: "potentialEvaluationValue", value },
      ]);
    });

  const customValueChange =
    isCustomValue &&
    ((value) =>
      onChange([
        {
          dataItem,
          field: "valueRangeList",
          value: dataItem?.valueRangeList?.map((item) =>
            item?.custom && item?.customValueTag === POTENTIAL_CUSTOM_TAG ? { ...item, value } : item
          ),
        },
      ]));

  const customSystemScoreChange =
    isCustomValue &&
    ((value) =>
      onChange([
        { dataItem, field: "potentialEvaluationSystemScore", value },
        {
          dataItem,
          field: "valueRangeList",
          value: dataItem?.valueRangeList?.map((item) =>
            item?.custom && item?.customValueTag === POTENTIAL_CUSTOM_TAG ? { ...item, systemScore: value } : item
          ),
        },
      ]));

  return (
    <div id={POTENTIAL_SYSTEM_SCORE_SUB_BLOCK_ID}>
      {!isIndicator && (
        <div className="k-d-flex k-flex-wrap k-gap-3 k-px-5">
          <div className="k-flex-basis-1/3 k-flex-grow k-text-right k-text-uppercase">
            {t("newRatingSystem.esg.sideMenu.potentialBlock.systemScore")}
          </div>
          <div className="k-flex-basis-1/3 k-flex-grow k-text-right k-text-uppercase">
            {t("newRatingSystem.esg.sideMenu.potentialBlock.superScore")}
          </div>
          <div className="k-flex-basis-1/3 k-flex-grow k-text-right">
            {roundDecimal(dataItem?.potentialEvaluationSystemScore, 100)}
          </div>
          <div className="k-flex-basis-1/3 k-flex-grow k-text-right">
            {roundDecimal(dataItem?.universalScore, 100)}%
          </div>
        </div>
      )}
      {isIndicator && (
        <>
          {!isValueRange && (
            <div className="k-px-5">
              <FloatingLabelNumericTextBox
                label={t("newRatingSystem.esg.sideMenu.potentialBlock.systemScore")}
                name="potentialEvaluationSystemScore"
                className="k-w-full"
                value={dataItem?.potentialEvaluationSystemScore}
                onChange={systemScoreChange}
                max={dataItem?.maxSystemScore}
                readOnly={readOnly}
                autoSelect
              />
              <Hint className="system-score-range">
                -{dataItem?.maxSystemScore} - {dataItem?.maxSystemScore}
              </Hint>
            </div>
          )}
          {isValueRange && (
            <>
              <div className="k-px-5">
                <FloatingLabelDropdownList
                  label={t("newRatingSystem.esg.sideMenu.potentialBlock.systemScore")}
                  name="potentialEvaluationValue"
                  className="k-w-full"
                  value={valueRangeValue ?? ""}
                  data={valueRangeList}
                  textField="systemScore"
                  dataItemKey="value"
                  itemRender={systemScoreItemRender}
                  valueRender={systemScoreValueRender}
                  onChange={evaluationValueChange}
                  readOnly={readOnly}
                />
              </div>
              {isCustomValue && (
                <div className="k-px-5 k-d-flex k-gap-3">
                  <FloatingLabelTextBox
                    label={t("newRatingSystem.esg.sideMenu.potentialBlock.value")}
                    name="customValue"
                    className="k-flex-1"
                    value={customValue?.value}
                    onChange={customValueChange}
                    readOnly={readOnly}
                    autoSelect
                  />
                  <FloatingLabelNumericTextBox
                    label={t("newRatingSystem.esg.sideMenu.potentialBlock.systemScore")}
                    name="potentialEvaluationSystemScore"
                    className="k-flex-1"
                    value={dataItem?.potentialEvaluationSystemScore}
                    onChange={customSystemScoreChange}
                    readOnly={readOnly}
                    autoSelect
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

SystemScoreSubBlock.propTypes = {
  dataItem: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

export const PotentialBlockESG = ({ dataItem, system, tableFn, readOnly }) => {
  const { t } = useTranslation();
  return (
    <BaseBlock title={t("newRatingSystem.esg.sideMenu.potentialBlock.title")} id={POTENTIAL_BLOCK_ESG_ID}>
      <div className="k-d-flex k-flex-col k-gap-3">
        <KPISubBlock dataItem={dataItem} onChange={tableFn?.onItemChange} readOnly={readOnly} />
        <SystemScoreSubBlock dataItem={dataItem} onChange={tableFn?.onItemChange} readOnly={readOnly} />
        <ValueSubBlock dataItem={dataItem} onChange={tableFn?.onItemChange} readOnly={readOnly} />
        <ReasonSubBlock dataItem={dataItem} system={system} onChange={tableFn?.onItemChange} readOnly={readOnly} />
      </div>
    </BaseBlock>
  );
};

PotentialBlockESG.propTypes = {
  dataItem: PropTypes.object,
  system: PropTypes.object,
  tableFn: PropTypes.object,
  readOnly: PropTypes.bool,
};
