import { Editor, EditorTools, ProseMirror, getHtml } from "@progress/kendo-react-editor";
import { PropTypes } from "prop-types";
import { DOMParser as ProseDOMParser, Schema } from "prosemirror-model";
import * as React from "react";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import { SPTBackColor } from "./components/SPTBackColor";
import { SPTForeColor } from "./components/SPTForeColor";
import { SPTInsertImageTool } from "./components/SPTInsertImageTool";
import {
  BULLET_LIST_NODE_NAME,
  DIV_NODE_NAME,
  IMAGE_CONTAINER_NODE_NAME,
  IMAGE_GRID_CELL_NODE_NAME,
  IMAGE_GRID_NODE_NAME,
  IMAGE_NODE_NAME,
  LIST_ITEM_NODE_NAME,
  ORDERED_LIST_NODE_NAME,
  PARAGRAPH_NODE_NAME,
  RICH_TEXT_STYLE,
} from "./constants";
import { insertPastedContentPlugin } from "./plugins/InsertPastedContentPlugin";
import { getDeletedNodes, insertImageFile } from "./utils";

import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import Filter4Icon from "@mui/icons-material/Filter4";
import Filter5Icon from "@mui/icons-material/Filter5";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import {
  alignCenterIcon,
  alignLeftIcon,
  alignRightIcon,
  arrowRotateCcwIcon,
  columnsIcon,
  imageIcon,
  imageResizeIcon,
} from "@progress/kendo-svg-icons";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { CENTER_ALIGN, LEFT_ALIGN, RIGHT_ALIGN } from "../../constants/main";
import { removeBreakLines, replaceImageUrl } from "../../helpers/richtext";
import { useRichTextConfiguration } from "../../hooks/auth";
import { setImageSourceToMap, substractImageCount, useRichTextImageSourceState } from "../../hooks/richtext";
import "./RichText.scss";
import { SPTFormatBlock } from "./components/SPTFormatBlock";
import {
  ImageHandlingPlugin,
  addImageToGrid,
  convertToGrid,
  convertToSingle,
  createAxis,
  getAllAttributes,
  getDOMUID,
  imageContainerNodeSpec,
  imageGridCellNodeSpec,
  imageGridNodeSpec,
  restoreSelectedImageToDefault,
  setSelectedImageAlign,
  setSelectedImageDimension,
  toggleSelectedImageKeepAspectRatio,
  useSelectedImage,
} from "./plugins/ImageHandlingPlugin";

const GRID_POSITION_ICONS = [Filter1Icon, Filter2Icon, Filter3Icon, Filter4Icon, Filter5Icon];

const { Bold, Italic, Underline, OrderedList, UnorderedList, Link, Unlink, Undo, Redo } = EditorTools;

const onImageInsert = (args) => {
  const { richTextConfiguration, fileUrl, view, event, uid } = args;
  const position =
    event.type === "drop"
      ? view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })
      : null;

  insertImageFile({
    view,
    fileUrl,
    position,
    richTextConfiguration,
    uid,
  });
};

const onMount = (event, uploadFunction, getResultUrl, richTextConfiguration, uid, disableImageUpload = false) => {
  const iframeDocument = event.dom.ownerDocument;
  const style = iframeDocument.createElement("style");
  style.appendChild(iframeDocument.createTextNode(RICH_TEXT_STYLE));
  iframeDocument.head.appendChild(style);

  const state = event.viewProps.state;

  /**
   * NodeSpec modification to being able to be attached in doc node, and behave as a block (prevent other elements to be rendered in it's sides)
   * Documentation: https://prosemirror.net/docs/ref/#model.NodeSpec
   */
  const paragraphNodeSpec = state.schema.spec.nodes.get(PARAGRAPH_NODE_NAME);
  paragraphNodeSpec.attrs = {};

  const listItemNodeSpec = state.schema.spec.nodes.get(LIST_ITEM_NODE_NAME);
  listItemNodeSpec.content = PARAGRAPH_NODE_NAME;

  const bulletListNodeSpec = state.schema.spec.nodes.get(BULLET_LIST_NODE_NAME);
  paragraphNodeSpec.attrs = {};

  const orderedListNodeSpec = state.schema.spec.nodes.get(ORDERED_LIST_NODE_NAME);
  paragraphNodeSpec.attrs = {};

  const headingNodeSpec = state.schema.spec.nodes.get("heading");
  headingNodeSpec.attrs = { level: { default: 1 } };

  const imageNodeSpec = state.schema.spec.nodes.get(IMAGE_NODE_NAME);
  imageNodeSpec.inline = false;
  imageNodeSpec.group = "image";
  imageNodeSpec.block = true;
  imageNodeSpec.attrs = {
    ...imageNodeSpec.attrs,
    style: { default: "width: auto; height: auto;" },
    uid: { default: null },
    empty: { default: null },
    onload: { default: null },
    onerror: { default: null },
  };
  imageNodeSpec.parseDOM = [
    {
      tag: "img[src]",
      getAttrs: (dom) => ({
        ...getAllAttributes(dom),
        uid: getDOMUID(dom),
      }),
    },
  ];
  imageNodeSpec.toDOM = (node) => ["img", { ...node.attrs }];

  /**
   * Create new Schema for the Editor, so it will render images with new NodeSpec
   * Documentation: https://prosemirror.net/docs/ref/#model.Schema
   * Examples:
   *  - https://prosemirror.net/examples/schema/
   *  - https://prosemirror.net/examples/dino/
   */
  const updatedSchema = new Schema({
    nodes: state.schema.spec.nodes
      .update(IMAGE_NODE_NAME, imageNodeSpec)
      .update(PARAGRAPH_NODE_NAME, paragraphNodeSpec)
      .update(BULLET_LIST_NODE_NAME, bulletListNodeSpec)
      .update(ORDERED_LIST_NODE_NAME, orderedListNodeSpec)
      .update(LIST_ITEM_NODE_NAME, listItemNodeSpec)
      .addBefore(DIV_NODE_NAME, IMAGE_GRID_CELL_NODE_NAME, imageGridCellNodeSpec)
      .addBefore(DIV_NODE_NAME, IMAGE_GRID_NODE_NAME, imageGridNodeSpec)
      .addBefore(DIV_NODE_NAME, IMAGE_CONTAINER_NODE_NAME, imageContainerNodeSpec),
    marks: state.schema.spec.marks,
  });

  const insertPastedContenProps = {
    onImageInsert,
    disableImageUpload,
    uploadFunction,
    getResultUrl,
    richTextConfiguration,
    uid,
  };
  const plugins = [...state.plugins, ImageHandlingPlugin, insertPastedContentPlugin(insertPastedContenProps)];

  const view = new ProseMirror.EditorView(
    {
      mount: event.dom,
    },
    {
      ...event.viewProps,
      state: ProseMirror.EditorState.create({
        schema: updatedSchema,
        plugins,
      }),
    }
  );

  createAxis(iframeDocument, view);
  view.dom.setAttribute("uid", uid);

  return view;
};

/**
 * RichText Component, this component is based in KendoReact Editor which is based in Prosemirror library.
 * Interesting Kendo links:
 *  - [Editor tools information](https://www.telerik.com/kendo-react-ui/components/editor/tools/)
 *  - [Editor API information](https://www.telerik.com/kendo-react-ui/components/editor/api/)
 *  - [Editor image handling](https://www.telerik.com/kendo-react-ui/components/editor/images/)
 *  - [Editor custom tools](https://www.telerik.com/kendo-react-ui/components/editor/tools/#toc-customizing-built-in-tools)
 *
 * Interesting Prosemirror links:
 *  - [Prosemirror examples](https://prosemirror.net/examples/)
 *  - [Prosemirror documentation reference](https://prosemirror.net/docs/ref/)
 *
 * @param {{onChange, content, uploadUrl, uploadFunction, getResultUrl}} props
 * @returns RichText Component
 */
export const RichText = React.memo(
  React.forwardRef(
    (
      {
        onChange = () => null,
        onLoading = () => null,
        onBlur = () => null,
        uploadUrl,
        uploadFunction,
        getResultUrl,
        uid,
        content = "",
        disableImageUpload = false,
        editorHeight = 800,
      },
      richTextRef
    ) => {
      const { t } = useTranslation();
      const editorRef = React.useRef();
      const [localContent, setLocalContent] = React.useState(null);
      const [loading, setLoading] = React.useState(false);
      const imageSourceState = useRichTextImageSourceState(uid);
      const richTextConfiguration = useRichTextConfiguration();
      const selectedImageData = useSelectedImage(uid);

      const updateContent = React.useCallback(
        (newContent) => {
          const doc = new DOMParser().parseFromString(newContent ?? "", "text/html");
          const proseDoc = ProseDOMParser.fromSchema(editorRef.current.view.state.schema).parse(doc.body);
          const state = ProseMirror.EditorState.create({
            doc: proseDoc,
            plugins: editorRef.current?.view?.state?.plugins,
          });

          editorRef.current.view.updateState(state);
          return {
            event: {
              get html() {
                return getHtml(editorRef.current.view.state);
              },
            },
            imageSources: imageSourceState.imageSource,
          };
        },
        [editorRef.current, imageSourceState.imageSource]
      );

      React.useImperativeHandle(richTextRef, () => ({
        updateContent,
      }));

      React.useEffect(() => {
        if (editorRef.current?.view?.state?.schema) updateContent(localContent);
      }, [editorRef.current, localContent, uid]);

      const onUpload = (firstFile) => {
        return new Promise((resolve) => {
          setLoading(true);
          uploadFunction(firstFile).then((result) => {
            setLoading(false);
            resolve(result);
          });
        });
      };

      /**
       * NOTE: event.html is a getter function that returns the html content of the editor, it's not a property of the event.
       * Which means that depending on document size, it could be a heavy operation. Avoid using it on this function.
       * @param {EditorChangeEvent} event - Change event triggered by the editor [EditorChangeEvent API](https://www.telerik.com/kendo-react-ui/components/editor/api/EditorChangeEvent/#toc-html)
       */
      const onEditorChange = (event) => {
        const { transaction } = event;
        const { before } = transaction;
        if (before?.content?.content?.length > transaction.doc.content.content.length) {
          const [node] = getDeletedNodes(transaction.doc, before);
          if (node?.type.name === IMAGE_CONTAINER_NODE_NAME || node?.type.name === IMAGE_GRID_CELL_NODE_NAME) {
            substractImageCount();
          }
        }

        onChange({ event, imageSources: imageSourceState.imageSource });
      };

      const onBlurChange = () =>
        onBlur({
          event: {
            get html() {
              return getHtml(editorRef.current.view.state);
            },
          },
          imageSources: imageSourceState.imageSource,
        });

      React.useEffect(() => {
        const formattedContent = removeBreakLines(content);
        replaceImageUrl(formattedContent).then(({ html, count, imageSource }) => {
          setLocalContent(html);
          setImageSourceToMap(uid, imageSource, count);

          onChange({ event: { html }, imageSources: imageSource });
        });
      }, [content]);

      const loadingStatus = localContent === null;
      const tools = [[Bold, Italic, Underline], SPTFormatBlock, SPTForeColor, SPTBackColor];

      if (!disableImageUpload && !!richTextConfiguration && richTextConfiguration.imageQuantityLimit > 0)
        tools.push(SPTInsertImageTool(uploadUrl, getResultUrl, richTextConfiguration, uid));

      React.useEffect(() => {
        onLoading(loadingStatus);
      }, [loadingStatus]);

      return (
        <LoadingOverlay spinner active={loadingStatus || imageSourceState.imageLock || loading}>
          {loadingStatus && (
            <Editor
              tools={[
                [Bold, Italic, Underline],
                SPTForeColor,
                SPTBackColor,
                SPTInsertImageTool(uploadUrl, getResultUrl),
                [Link, Unlink],
                [UnorderedList, OrderedList],
                [Undo, Redo],
              ]}
              contentStyle={{ height: editorHeight }}
            />
          )}
          {!loadingStatus && (
            <Editor
              tools={[...tools, [Link, Unlink], [UnorderedList, OrderedList], [Undo, Redo]]}
              onMount={(event) =>
                onMount(event, onUpload, getResultUrl, richTextConfiguration, uid, disableImageUpload)
              }
              contentStyle={{ height: editorHeight }}
              ref={editorRef}
              onChange={onEditorChange}
              defaultContent={localContent}
              onBlur={onBlurChange}
            />
          )}
          {selectedImageData?.isOnGrid === false && (
            <Window
              title={"Image tools"}
              initialLeft={100}
              initialTop={100}
              width={500}
              height={200}
              className="image-tool-dialog grid-tool-dialog-no-close-button"
              stage="default"
              onStageChange={() => false}
              resizable={false}
            >
              {selectedImageData && (
                <>
                  <NumericTextBox
                    value={selectedImageData.width}
                    onChange={(event) => setSelectedImageDimension(event.value, null, editorRef.current?.view)}
                    label={t("richtext.imageWidth")}
                  />
                  <NumericTextBox
                    value={selectedImageData.height}
                    onChange={(event) => setSelectedImageDimension(null, event.value, editorRef.current?.view)}
                    label={t("richtext.imageHeight")}
                  />
                  <ButtonGroup>
                    <Button
                      svgIcon={alignLeftIcon}
                      disabled={selectedImageData.align === LEFT_ALIGN}
                      onClick={() => setSelectedImageAlign(LEFT_ALIGN, editorRef.current?.view)}
                      title={t("richtext.alignImageLeft")}
                    />
                    <Button
                      svgIcon={alignCenterIcon}
                      disabled={selectedImageData.align === CENTER_ALIGN}
                      onClick={() => setSelectedImageAlign(CENTER_ALIGN, editorRef.current?.view)}
                      title={t("richtext.alignImageCenter")}
                    />
                    <Button
                      svgIcon={alignRightIcon}
                      disabled={selectedImageData.align === RIGHT_ALIGN}
                      onClick={() => setSelectedImageAlign(RIGHT_ALIGN, editorRef.current?.view)}
                      title={t("richtext.alignImageRight")}
                    />
                  </ButtonGroup>
                  <Button
                    svgIcon={columnsIcon}
                    onClick={() => convertToGrid(editorRef.current?.view)}
                    title={t("richtext.convertImageToGrid")}
                  />
                  <Button
                    svgIcon={imageResizeIcon}
                    togglable={true}
                    onClick={() => toggleSelectedImageKeepAspectRatio(editorRef.current?.view)}
                    title={t("richtext.keepAspectRatio")}
                  />
                  <Button
                    svgIcon={arrowRotateCcwIcon}
                    onClick={() => restoreSelectedImageToDefault(editorRef.current?.view)}
                    title={t("richtext.restoreDefault")}
                  />
                </>
              )}
            </Window>
          )}
          {selectedImageData?.isOnGrid && (
            <Window
              title="Grid tools"
              initialTop={100}
              initialLeft={100}
              className="grid-tool-dialog grid-tool-dialog-no-close-button"
              width={500}
              height={140}
              resizable={false}
              stage="default"
              onStageChange={() => false}
            >
              <ButtonGroup disableElevation={false}>
                {GRID_POSITION_ICONS.map((Icon, i) => (
                  <Button
                    key={getUUID()}
                    disabled={selectedImageData?.imageGridNode.content.content.length > i}
                    title={t("richtext.addImageToGrid")}
                    onClick={() => {
                      const currentQuantity = selectedImageData?.imageGridNode.content.content.length;
                      const nodesToAdd = i + 1 - currentQuantity;
                      addImageToGrid(editorRef.current?.view, nodesToAdd);
                    }}
                  >
                    <Icon />
                  </Button>
                ))}
              </ButtonGroup>
              <Button
                svgIcon={imageIcon}
                disabled={selectedImageData?.imageGridNode.content.content.length > 1}
                onClick={() => convertToSingle(editorRef.current?.view)}
                title={t("richtext.convertGridToImage")}
              />
            </Window>
          )}
        </LoadingOverlay>
      );
    }
  )
);

RichText.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onLoading: PropTypes.func,
  disableImageUpload: PropTypes.bool,
  content: PropTypes.string,
  uploadUrl: PropTypes.string,
  uploadFunction: PropTypes.func,
  getResultUrl: PropTypes.func,
  uid: PropTypes.string,
  editorHeight: PropTypes.number,
};
