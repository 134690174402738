import React, { useEffect, useMemo, useState } from "react";
import { ChartBase } from "../../ChartBase/ChartBase";
import {
  getProjectGroupStatisticChartKpiVsKpi,
  useProjectGroupStatisticChartKpiVsKpiResponse
} from "../../../hooks/projectGroup";
import { generatePalette, getHeightInPoints } from "../../../helpers/canvas";
import { renderKpiVsKPIChart } from "./renderChart";
import { TooltipPopover } from "../../TooltipPopover/TooltipPopover";

export const KPIVsKPIChart = React.memo(({ projectGroupId, kpiY, kpiX }) => {
  const projectGroupStatisticChartKpiVsKpiResponse = useProjectGroupStatisticChartKpiVsKpiResponse();
  const data = projectGroupStatisticChartKpiVsKpiResponse.data;
  const projects = data?.projects || [];
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [popover, setPopover] = useState(null);

  useEffect(() => {
    if (projectGroupId && kpiY && kpiX) {
      setPopover(null);
      getProjectGroupStatisticChartKpiVsKpi(projectGroupId, kpiY?.kpiName, kpiY?.kpiUnit, kpiX?.kpiName, kpiX?.kpiUnit).then().catch(console.error);
    }
  }, [projectGroupId, kpiY, kpiX]);

  const [xRangeInner, yRangeInner, heightInPoints, widthInPoints, valuesByIdInner] = useMemo(() => {
    const xRange = [];
    const yRange = [];
    const valuesById = {};
    let maxValue = 0;
    let maxCounterValue = 0;
    projects.forEach(project => {
      const { projectId, projectName, ratingSystems } = project;
      (ratingSystems || []).forEach(item => {
        const { values, ratingSystemName, ratingSystemId } = item;
        const valuePair = values[0];
        const { value, counterValue } = valuePair || {};
        valuesById[ratingSystemId] = { ...valuePair, ratingSystemName, projectId, projectName };
        if (value > maxValue) {
          maxValue = value;
        }
        if (counterValue > maxCounterValue) {
          maxCounterValue = counterValue;
        }
      });
    });
    let heightInPointsInner = getHeightInPoints(maxValue + maxValue * 0.1);
    const heightStep = heightInPointsInner / 5;
    if (heightInPointsInner) {
      for (let i = 0; i <= heightInPointsInner; i += heightStep) {
        yRange.push(heightInPointsInner > 100 ? Math.round(i) : Math.round(i * 100) / 100);
      }
    }
    let widthInPointsInner = getHeightInPoints(maxCounterValue + maxCounterValue * 0.1);
    const widthStep = widthInPointsInner / 5;
    if (widthInPointsInner) {
      for (let i = 0; i <= widthInPointsInner; i += widthStep) {
        xRange.push(widthInPointsInner > 100 ? Math.round(i) : Math.round(i * 100) / 100);
      }
    }
    return [xRange, yRange, heightInPointsInner, widthInPointsInner, valuesById];
  }, [data]);

  const renderMethod = ({ canvas, width: widthRenderMethod, height: heightRenderMethod, heightInPoints: heightInPointsRenderMethod, values }) => {
    setHeight(heightRenderMethod);
    setWidth(widthRenderMethod);
    if (canvas && widthRenderMethod && heightRenderMethod) {
      renderKpiVsKPIChart(canvas, widthRenderMethod, heightRenderMethod, values, {
        xRange: xRangeInner,
        yRange: yRangeInner,
        valuesById: valuesByIdInner,
        heightInPoints: heightInPointsRenderMethod || 1,
        widthInPoints: widthInPoints || 1
      });
    }
  };

  const palette = useMemo(() => {
    const colors = generatePalette(projects.length);
    const colorsByProjectId = {};
    projects.forEach((item, index) => {
      colorsByProjectId[item.projectId] = colors[index];
    });
    return colorsByProjectId;
  }, [projects.length]);

  const rightContent = useMemo(() => {
    return (
      <div className="projects">
        {projects.map((item, index) => (
          <div className="project-item flex-row" key={index}>
            <div className="value-dot" style={{ backgroundColor: palette[item.projectId] }}/>
            <div className="flex-auto">{item?.projectName}</div>
          </div>
        ))}
      </div>
    );
  }, [data]);

  const footerContent = useMemo(() => {
    const { open, target, data: dataFooterContent } = popover || {};
    const { value, counterValue, projectName, ratingSystemName } = dataFooterContent || {};

    return (
      <TooltipPopover
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        content={<div>
          <div>{projectName} - {ratingSystemName}</div>
          <div>
            <span className="muted">{kpiY?.kpiName} {kpiY?.kpiUnit}: </span>
            <span>{value}</span>
          </div>
          <div>
            <span className="muted">{kpiX?.kpiName} {kpiX?.kpiUnit}: </span>
            <span>{counterValue}</span>
          </div>
        </div>} target={target}
        open={open} className="text-tooltip"
      />
    );
  }, [popover]);

  const guideLinesContent = useMemo(() => {
    const { value, counterValue } = popover?.data || {};
    return (
      <div
        className="guide-lines"
        style={{
          opacity: popover?.open ? 1 : 0,
          width: width * counterValue / widthInPoints + 'px',
          height: height * value / heightInPoints + 'px'
        }}
      />
    );
  }, [popover, width, height, widthInPoints, heightInPoints]);

  const chartInnerContent = useMemo(() => (
    <div className="chart-inner">
      {Object.keys(valuesByIdInner).map((ratingSystemId) => {
        const item = valuesByIdInner[ratingSystemId];
        const color = palette[item.projectId];

        return (
          <div className="value-dot-wrapper"
               key={ratingSystemId}
               style={{
                 top: height - item.value / heightInPoints * height + 'px',
                 left: item.counterValue / widthInPoints * width + 'px'
               }}
               onMouseOver={event => setPopover({
                 target: event.currentTarget,
                 data: item,
                 open: true
               })}
               onMouseOut={() => setPopover({ ...popover, open: false })}>
            <div className="value-dot" style={{ backgroundColor: color }}/>
          </div>
        );
      })}
    </div>
  ), [data, height, width, heightInPoints, widthInPoints, popover]);

  return (
    <ChartBase className="kpi-vs-kpi-chart"
               yRange={yRangeInner}
               xRange={xRangeInner}
               xNumeric
               key={data}
               yLabel={kpiY?.kpiName + ' ' + kpiY?.kpiUnit}
               xLabel={kpiX?.kpiName + ' ' + kpiX?.kpiUnit}
               heightInPoints={heightInPoints}
               renderMethod={renderMethod}
               rightContent={rightContent}
               footerContent={footerContent}
    >
      {chartInnerContent}
      {guideLinesContent}
    </ChartBase>
  );
});
