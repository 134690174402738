import { userSubject } from "../hooks/auth";

const VERSION = 2;

export const loadLayout = (ratingSystemId) => {
  const { tenant } = userSubject.getValue();
  const itemKey = `ratingSystemLayout_${tenant.id}_${ratingSystemId}`;
  const layout = localStorage.getItem(itemKey) ? JSON.parse(localStorage.getItem(itemKey)) : null;
  return layout?.version === VERSION ? layout : null;
};

export const saveLayout = (ratingSystemId, layout) => {
  const { tenant } = userSubject.getValue();
  const itemKey = `ratingSystemLayout_${tenant.id}_${ratingSystemId}`;

  if (!layout?.version) layout.version = VERSION;
  if (layout?.version === VERSION) localStorage.setItem(itemKey, JSON.stringify(layout));
  else console.warn("Layout version mismatch, not saving layout.");
};
