import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./StatusFilter.scss";
import { useBenchmarkStatusCountResponse } from "../../../hooks/benchmark";
import { STATUS_IN_PROGRESS, STATUS_RELEASED } from "../../../constants/benchmark";
import { FilterBlocks } from "../../FilterBlocks/FilterBlocks";

const getCountsByStatuses = (data) => {
  const res = {};
  (data || []).forEach(item => res[item.status] = item.count);
  return res;
};

export const StatusFilter = ({ value, onChange, disabled }) => {
  const { t } = useTranslation();

  const countsResponse = useBenchmarkStatusCountResponse();

  const [status, setStatus] = useState(getCountsByStatuses(countsResponse.data));

  useEffect(() => {
    if (!countsResponse.loading) {
      setStatus(getCountsByStatuses(countsResponse.data));
    }
  }, [countsResponse.loading, countsResponse.data]);

  const filterOptions = [
    {
      name: STATUS_IN_PROGRESS,
      title: t('status.filter.' + STATUS_IN_PROGRESS),
      amount: status[STATUS_IN_PROGRESS] || 0
    },
    {
      name: STATUS_RELEASED,
      title: t('status.filter.' + STATUS_RELEASED),
      amount: status[STATUS_RELEASED] || 0
    }
  ];

  return (
    <FilterBlocks filterOptions={filterOptions} value={value} onChange={onChange} allowDeselect showReset={!!value}
                  disabled={disabled}/>
  );
};
