import { mapTree, TreeList } from "@progress/kendo-react-treelist";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { kendoOperatorConversionMap } from "../../components/TreeList/treeListComponents";
import { TENANT_TYPE_PAST } from "../../constants/tenant";
import { useWindowSize } from "../../hooks";
import { getUserTenantType } from "../../hooks/auth";
import { useProjectsPageLogic } from "../../hooks/project";
import { useEvent } from "../../hooks/utils/useEvent";
import { columnsESG, columnsPAST } from "./Projects.columns";
import { ProjectsPager } from "./Projects.components";
import "./Projects.scss";

const SUB_ITEMS_FIELD = "children";
export const EXPAND_FIELD = "expanded";
const FILTER_RESPONSE_TIME = 500;
const TABLE_PADDING = 245;

const defaultState = { data: [], sort: [], filter: [], skip: 0, take: 25, collapseAll: false, visibleRatingSystems: 0 };

const convertFilterToSPT = ({ field, operator, value }) => ({
  [field === "systemSource" ? "ratingSystems" : field]: `${kendoOperatorConversionMap[operator]}:${value}`,
});

const listToJSON = (acc, item) => ({ ...acc, ...item });

const projectsTableStateSubject = new BehaviorSubject(defaultState);
export const useProjectsTableState = hookFromSubject(projectsTableStateSubject);
export const setProjectsTableState = (v) =>
  projectsTableStateSubject.next(typeof v === "function" ? v(projectsTableStateSubject.getValue()) : v);
export const getProjectsTableState = () => projectsTableStateSubject.getValue();

export const ProjectsTable = React.memo(({ projects }) => {
  const tenantType = getUserTenantType();
  const state = useProjectsTableState();
  const [timeoutId, setTimeoutId] = React.useState(null);
  const { height } = useWindowSize();

  const { setSortValue, kendoFilters, setKendoFilters, updateState } = useProjectsPageLogic();

  useEffect(() => {
    setProjectsTableState((prev) => ({
      ...prev,
      data: mapTree(projects, SUB_ITEMS_FIELD, (item) => ({ ...item, [EXPAND_FIELD]: false })),
      collapseAll: false,
      visibleRatingSystems: 0,
    }));
  }, [projects]);

  useEffect(
    () => () => {
      clearTimeout(timeoutId);
      setProjectsTableState(defaultState);
    },
    []
  );

  const onSortChange = useEvent(({ sort }) => {
    setProjectsTableState((prev) => ({ ...prev, sort }));
    if (sort[0]) setSortValue({ ...sort[0], desc: sort[0].dir === "desc" });
    else setSortValue(null);
  });

  const onFilterChange = useEvent(({ filter }) => {
    setProjectsTableState((prev) => ({ ...prev, filter }));

    if (timeoutId) clearTimeout(timeoutId);
    let id;

    if (filter?.length)
      id = setTimeout(() => setKendoFilters(filter.map(convertFilterToSPT).reduce(listToJSON)), FILTER_RESPONSE_TIME);
    else if (Object.keys(kendoFilters)?.length) id = setTimeout(() => setKendoFilters({}), FILTER_RESPONSE_TIME);

    setTimeoutId(id);
  });

  const onPageChange = useEvent(({ skip, take }) => {
    setProjectsTableState((prev) => ({ ...prev, skip, take, visibleRatingSystems: 0 }));
    updateState({ page: skip / take, rowsPerPage: take });
  });

  const onExpandChange = useEvent(({ level }) =>
    setProjectsTableState((prev) => {
      prev.data[level[0]][EXPAND_FIELD] = !prev.data[level]?.[EXPAND_FIELD];
      if (prev.data[level[0]][EXPAND_FIELD]) prev.visibleRatingSystems += prev.data[level[0]]?.children?.length;
      else prev.visibleRatingSystems -= prev.data[level[0]]?.children?.length;
      prev.collapseAll = prev.data.some((item) => !!item?.[EXPAND_FIELD]);

      return { ...prev };
    })
  );

  const columns = useMemo(() => (tenantType === TENANT_TYPE_PAST ? columnsPAST : columnsESG), [tenantType]);

  return (
    <div>
      <TreeList
        id="new-projects-table"
        className="k-flex-1"
        style={{ maxHeight: `${height - TABLE_PADDING}px`, overflow: "auto" }}
        data={state.data}
        expandField={EXPAND_FIELD}
        subItemsField={SUB_ITEMS_FIELD}
        onExpandChange={onExpandChange}
        onFilterChange={onFilterChange}
        onSortChange={onSortChange}
        onPageChange={onPageChange}
        columns={columns}
        sort={state.sort}
        filter={state.filter}
        skip={state.skip}
        take={state.take + state.visibleRatingSystems}
        pager={ProjectsPager}
        sortable
      />
    </div>
  );
});

ProjectsTable.propTypes = {
  projects: PropTypes.array,
};
