import Switch from "@mui/material/Switch";
import React from "react";
import "./TwoOptionsToggle.scss";

export const TwoOptionsToggle = ({ value, onChange, option1, option2, className, disabled }) => {
  const switchHandler = (event) => onChange(event.target.checked ? option2.value : option1.value);

  return (
    <div className={"two-options-toggle " + (disabled ? "disabled " : "") + (className || "")}>
      <label
        className={value === option1.value ? " active" : ""}
        onClick={disabled ? null : () => onChange(option1.value)}
      >
        {option1.label}
      </label>
      <Switch disabled={disabled} checked={value === option2.value} onChange={switchHandler} color="primary" />
      <label
        className={value === option2.value ? " active" : ""}
        onClick={disabled ? null : () => onChange(option2.value)}
      >
        {option2.label}
      </label>
    </div>
  );
};
