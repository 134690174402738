import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip } from "@mui/material";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, NumericTextBox, TextBox } from "@progress/kendo-react-inputs";
import PropTypes from "prop-types";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProgressSquare } from "../../components/ProgressSquare/ProgressSquare";
import { NewRatingSystemStatusIndicator } from "../../components/RatingSystem/RatingSystemGrouping/RatingSystemStatusIndicator";
import { StatusBadge } from "../../components/StatusBadge/StatusBadge";
import {
  FilterTh,
  MultiSelectFilter,
  NumericTd,
  SPTNumericFilter,
  TextTd,
} from "../../components/TreeList/treeListComponents";
import { RANGE_COLORS_ESG, STATUS_NOT_RATED } from "../../constants/ratingSystem";
import { CUSTOM_VALUE, INDICATOR_VALUE_TYPE_RANGE, SYSTEM_LOGIC_DGNB } from "../../constants/sustainabilitySystem";
import { statusItemRender, statusValueRender } from "./NewRatingSystem.util";
import { roundDecimal } from "./NewRatingSystem.utils";
import { StatusThContextMenu } from "./NewRatingSystemContextMenu";
import { getSystemPageState } from "./NewRatingSystemESG";
import { excludeFilter, statusFilterValues, statusValues, trafficLightFilterValues } from "./NewRatingSystemESG.data";
import { isKOFailed } from "./NewRatingSystemESG.ratingSystem";
import { systemScoreItemRender, systemScoreValueRender } from "./NewRatingSystemESG.util";
import { conditionIsIndicator, conditionNotIndicator } from "./NewRatingSystemESG.validation";
import { useSetPopoverTarget } from "./NewRatingsystemESG.subject";

export const StatusTh = (props) => {
  const { t } = useTranslation();
  const [showContextMenu, setShowContextMenu] = useState(false);
  const boxRef = useRef(null);
  const { editable, readOnly } = getSystemPageState();
  const { title, field, render } = props;
  const dir = props?.sort[0]?.field === field && props?.sort?.[0]?.dir;
  const thElement = (
    <>
      <Box
        className="k-cell-inner"
        ref={boxRef}
        onContextMenu={(event) => {
          if (!editable || readOnly) return;
          event.preventDefault();
          setShowContextMenu(true);
        }}
      >
        <Box
          className="k-link"
          onClick={(e) => {
            if (!props?.sortable) return;

            const sort = [];
            if (dir === "asc") sort.push({ field, dir: "desc" });
            else if (props?.sort[0]?.field !== field) sort.push({ field, dir: "asc" });

            props?.sortable && props?.sortChange(e, sort, field);
          }}
        >
          <span className="k-column-title k-text-uppercase">{t(title)}</span>
          {props?.sortable && dir === "asc" && <ArrowUpwardIcon />}
          {props?.sortable && dir === "desc" && <ArrowDownwardIcon />}
        </Box>
      </Box>
      <StatusThContextMenu
        open={showContextMenu}
        anchorEl={boxRef.current}
        onClose={() => setShowContextMenu(false)}
        editable={editable}
        readOnly={readOnly}
      />
    </>
  );

  return render ? render(thElement, props) : thElement;
};

StatusTh.propTypes = {
  render: PropTypes.func,
  title: PropTypes.string,
  field: PropTypes.string,
  sort: PropTypes.array,
  sortable: PropTypes.bool,
  sortChange: PropTypes.func,
};

export const ExcludeTd = (props) => {
  if (props?.dataItem?.isFooter) return <td className="k-table-td" />;
  const { dataItem, field } = props;
  const { readOnly } = getSystemPageState();

  const tdElement = (
    <td className="k-table-td" onClick={!dataItem?.isFooter ? props.selectionChange : null} style={props?.style}>
      {props?.dataItem?.excludable && (
        <Checkbox
          checked={dataItem?.excluded}
          onChange={({ value }) =>
            props?.onChange([
              { dataItem, field, value },
              { dataItem, field: "expanded", value: false },
            ])
          }
          disabled={readOnly}
        />
      )}
    </td>
  );

  return props.render ? props.render(tdElement, props) : tdElement;
};

ExcludeTd.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
  render: PropTypes.func,
  selectionChange: PropTypes.func,
  style: PropTypes.object,
  onChange: PropTypes.func,
};

export const MaxSystemScoreTd = (props) => {
  if (props?.dataItem?.isFooter && !props.dataItem[props.field]) return <td className="k-table-td" />;
  const field = conditionIsIndicator(props?.dataItem) ? "maxSystemScore" : "maxSystemScoreLimit";

  const tdElement = (
    <td
      className={"k-table-td numeric-td" + (props?.className ? ` ${props.className}` : "")}
      onClick={!props?.dataItem?.isFooter ? props?.selectionChange : null}
    >
      <span className="k-mr-1">{roundDecimal(props?.dataItem?.[field], 100)}</span>
      {!!props?.dataItem?.maxSystemScoreLimit &&
        props?.dataItem?.maxSystemScore > props?.dataItem?.maxSystemScoreLimit && (
          <span className="ignored-value">{props?.dataItem?.maxSystemScore}</span>
        )}
    </td>
  );
  return props?.render ? props.render(tdElement, props) : tdElement;
};

MaxSystemScoreTd.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
  render: PropTypes.func,
  className: PropTypes.string,
  selectionChange: PropTypes.func,
};

export const SuperScoreTd = (props) => {
  const tdElement = (
    <td
      className={"k-table-td numeric-td" + (props?.className ? ` ${props.className}` : "")}
      onClick={!props?.dataItem?.isFooter ? props?.selectionChange : null}
    >
      {roundDecimal(props?.dataItem?.[props?.field], 100)} %
    </td>
  );
  return props?.render ? props.render(tdElement, props) : tdElement;
};

SuperScoreTd.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
  render: PropTypes.func,
  className: PropTypes.string,
  selectionChange: PropTypes.func,
};

export const ActualSystemScoreTd = (props) => {
  const koClassName = isKOFailed(props?.dataItem) ? " error" : "";
  const { system } = getSystemPageState();

  const value =
    system?.systemLogic === SYSTEM_LOGIC_DGNB && conditionNotIndicator(props?.dataItem) && !props?.dataItem?.isFooter
      ? props?.dataItem?.weightedSystemScore
      : props?.dataItem?.evaluationSystemScore;

  const tdElement = (
    <td
      className={"k-table-td numeric-td" + (props?.className ? ` ${props.className}` : "") + koClassName}
      onClick={!props?.dataItem?.isFooter ? props?.selectionChange : null}
    >
      {typeof props?.dataItem?.[props?.field] === "number" && !isNaN(props?.dataItem?.[props?.field])
        ? roundDecimal(value, 100)
        : "-"}
    </td>
  );

  return props?.render ? props.render(tdElement, props) : tdElement;
};

ActualSystemScoreTd.propTypes = {
  dataItem: PropTypes.object,
  field: PropTypes.string,
  render: PropTypes.func,
  className: PropTypes.string,
  selectionChange: PropTypes.func,
};

export const ActualSuperScoreTd = (props) => {
  const koClassName = isKOFailed(props?.dataItem) ? " error" : "";
  return SuperScoreTd({ ...props, className: (props?.className ?? "") + koClassName });
};

export const PotentialScoreTd = (props) => {
  const { t } = useTranslation();

  const isIndicator = conditionIsIndicator(props?.dataItem);

  const effectivePotentialEvaluationSystemScore = isIndicator
    ? props?.dataItem?.[props?.field] ?? props?.dataItem?.effectivePotentialEvaluationSystemScore
    : props?.dataItem?.potentialEvaluationSystemScore;

  const isPotentialLowerThanCurrent =
    effectivePotentialEvaluationSystemScore != null &&
    !isNaN(effectivePotentialEvaluationSystemScore) &&
    effectivePotentialEvaluationSystemScore < props?.dataItem?.evaluationSystemScore;

  const className = isKOFailed(props?.dataItem) || isPotentialLowerThanCurrent ? "error" : "";
  const showTooltip = isIndicator && props?.dataItem?.[props?.field] && !props?.dataItem?.actions?.length;

  const { system } = getSystemPageState();

  const value =
    system?.systemLogic === SYSTEM_LOGIC_DGNB && conditionNotIndicator(props?.dataItem) && !props?.dataItem?.isFooter
      ? props?.dataItem?.weightedPotentialSystemScore
      : effectivePotentialEvaluationSystemScore;

  const tdElement = (
    <td
      className={
        "k-table-td numeric-td" + (props?.className ? ` ${props.className}` : "") + (className ? ` ${className}` : "")
      }
      onClick={!props?.dataItem?.isFooter ? props?.selectionChange : null}
    >
      <div className="k-d-flex k-align-items-center k-justify-content-end k-gap-2">
        {typeof value === "number" && !isNaN(value) ? roundDecimal(value, 100) : "-"}
        {!!showTooltip && (
          <Tooltip title={t("ratingSystem.columnGroup.potentialEvaluationSystemScoreESGVisualAid")}>
            <InfoOutlinedIcon className="indicatorPotentialHelpIcon" />
          </Tooltip>
        )}
      </div>
    </td>
  );
  return props?.render ? props.render(tdElement, props) : tdElement;
};

PotentialScoreTd.propTypes = {
  dataItem: PropTypes.object,
  render: PropTypes.func,
  className: PropTypes.string,
  field: PropTypes.string,
  selectionChange: PropTypes.func,
};

export const PotentialSuperScoreTd = (props) => {
  const className =
    props?.dataItem?.potentialUniversalScore < props?.dataItem?.universalScore || isKOFailed(props?.dataItem)
      ? "error"
      : "";

  const tdElement = (
    <td
      className={
        "k-table-td numeric-td" + (props?.className ? ` ${props.className}` : "") + (className ? ` ${className}` : "")
      }
      onClick={!props?.dataItem?.isFooter ? props?.selectionChange : null}
    >
      {roundDecimal(props?.dataItem?.potentialUniversalScore, 100)} %
    </td>
  );
  return props?.render ? props.render(tdElement, props) : tdElement;
};

PotentialSuperScoreTd.propTypes = {
  dataItem: PropTypes.object,
  render: PropTypes.func,
  className: PropTypes.string,
  selectionChange: PropTypes.func,
};

export const TrafficLightRatingSystemESG = (props) => {
  const setPopoverTarget = useSetPopoverTarget();

  const tdElement = (
    <td className="k-table-td" onClick={props.selectionChange}>
      {conditionIsIndicator(props.dataItem) && (
        <div className="k-d-flex k-align-items-center k-justify-content-center k-gap-1">
          <NewRatingSystemStatusIndicator row={props.dataItem} setPopoverTarget={setPopoverTarget} />
        </div>
      )}
    </td>
  );

  return props.render ? props.render(tdElement, props) : tdElement;
};

TrafficLightRatingSystemESG.propTypes = {
  dataItem: PropTypes.object,
  render: PropTypes.func,
  selectionChange: PropTypes.func,
};

export const StatusRatingSystemESG = (props) => {
  const { t } = useTranslation();
  const tdElement = (
    <td className="k-table-td" onClick={props.selectionChange}>
      {conditionIsIndicator(props.dataItem) && (
        <div className="k-d-flex k-align-items-center k-justify-content-center k-gap-1">
          <StatusBadge className={"status-" + (props.dataItem.indicatorStatus ?? STATUS_NOT_RATED)}>
            {t("grouping.status." + (props.dataItem.indicatorStatus ?? STATUS_NOT_RATED))}
          </StatusBadge>
        </div>
      )}
    </td>
  );

  return props.render ? props.render(tdElement, props) : tdElement;
};

StatusRatingSystemESG.propTypes = {
  dataItem: PropTypes.object,
  render: PropTypes.func,
  selectionChange: PropTypes.func,
};

export const ActualPogressSquare = (props) => {
  if (props?.dataItem?.isFooter) return <td className="k-table-td" />;

  const tdElement = (
    <td className="k-table-td" onClick={props.selectionChange}>
      <ProgressSquare
        value={!props.dataItem.excludedFromCalculation ? props.dataItem.universalScore : undefined}
        rangeColors={props.dataItem.maxSystemScore ? RANGE_COLORS_ESG : null}
      />
    </td>
  );

  return props.render ? props.render(tdElement, props) : tdElement;
};

ActualPogressSquare.propTypes = {
  dataItem: PropTypes.object,
  render: PropTypes.func,
  selectionChange: PropTypes.func,
};

export const PotentialPogressSquare = (props) => {
  if (props?.dataItem?.isFooter) return <td className="k-table-td" />;

  const tdElement = (
    <td className="k-table-td" onClick={props.selectionChange}>
      <ProgressSquare
        value={!props.dataItem.excludedFromCalculation ? props.dataItem.potentialUniversalScore : undefined}
        rangeColors={props.dataItem.maxSystemScore ? RANGE_COLORS_ESG : null}
      />
    </td>
  );

  return props.render ? props.render(tdElement, props) : tdElement;
};

PotentialPogressSquare.propTypes = {
  dataItem: PropTypes.object,
  render: PropTypes.func,
  selectionChange: PropTypes.func,
};

export const IndicatorSystemScore = (props) => {
  const { field, dataItem, render } = props;
  const isIndicator = conditionIsIndicator(dataItem);
  const evaluationField = field === "evaluationSystemScore" ? "evaluationValue" : "potentialEvaluationValue";

  if (!isIndicator) return <NumericTd {...props} />;

  const isValueRange = isIndicator && dataItem?.valueType === INDICATOR_VALUE_TYPE_RANGE;
  const isCustomValue =
    isIndicator && isValueRange && dataItem?.allowCustomValue && dataItem?.[evaluationField] === CUSTOM_VALUE;

  const valueRangeList =
    isValueRange &&
    (dataItem?.valueRangeList ?? []).concat(
      dataItem?.allowCustomValue ? [{ value: CUSTOM_VALUE, systemScore: "" }] : []
    );

  const valueRangeValue = isValueRange && valueRangeList?.find((x) => x.value === dataItem?.[evaluationField]);

  const tdElement = (
    <td className="k-table-td" onClick={props.selectionChange}>
      {(!isValueRange || isCustomValue) && (
        <NumericTextBox
          step={0}
          spinners={false}
          value={dataItem[field] ?? ""}
          onChange={({ value }) => props?.onChange([{ dataItem, field, value }])}
          fillMode="flat"
          inputStyle={{ textAlign: "right" }}
          max={dataItem?.maxSystemScore}
          min={-dataItem?.maxSystemScore}
        />
      )}
      {isValueRange && !isCustomValue && (
        <DropDownList
          fillMode="flat"
          data={valueRangeList}
          value={valueRangeValue}
          popupSettings={{ width: "auto" }}
          textField="systemScore"
          dataItemKey="value"
          itemRender={systemScoreItemRender}
          valueRender={systemScoreValueRender}
          onChange={({ value }) =>
            props?.onChange([
              { dataItem, field, value: value.systemScore },
              { dataItem, field: evaluationField, value: value.value },
            ])
          }
        />
      )}
    </td>
  );

  return render ? render(tdElement, props) : tdElement;
};

IndicatorSystemScore.propTypes = {
  onChange: PropTypes.func,
  field: PropTypes.string,
  dataItem: PropTypes.object,
  render: PropTypes.func,
  selectionChange: PropTypes.func,
};

export const IndicatorStatus = (props) => {
  const { field, dataItem, render } = props;

  const value = dataItem[field] ? statusValues.find((x) => x.value === dataItem[field]) : statusValues[0];

  const onChangeInternal = ({ value }) => props?.onChange([{ dataItem, field, value: value?.value }]);

  const tdElement = (
    <td className="k-table-td" onClick={props.selectionChange}>
      <DropDownList
        className="status-dropdown"
        fillMode="flat"
        data={statusValues}
        value={value}
        onChange={onChangeInternal}
        textField="text"
        dataItemKey="value"
        popupSettings={{ width: "auto" }}
        valueRender={statusValueRender}
        itemRender={statusItemRender}
      />
    </td>
  );

  const element = render ? render(tdElement, props) : tdElement;

  return conditionIsIndicator(dataItem) ? element : <StatusRatingSystemESG {...props} />;
};

IndicatorStatus.propTypes = {
  onChange: PropTypes.func,
  field: PropTypes.string,
  dataItem: PropTypes.object,
  render: PropTypes.func,
  selectionChange: PropTypes.func,
};

export const IndicatorResponsible = (props) => {
  const { field, dataItem, render } = props;
  const [value, setValue] = useState(dataItem[field] ?? "");

  const onChangeInternal = (event) => setValue(event.value);

  const tdElement = (
    <td className="k-table-td" onClick={props.selectionChange}>
      <TextBox
        value={value}
        onChange={onChangeInternal}
        onBlur={() => props?.onChange([{ dataItem, field, value }])}
        fillMode="flat"
      />
    </td>
  );

  const element = render ? render(tdElement, props) : tdElement;

  return conditionIsIndicator(dataItem) ? element : <TextTd {...props} />;
};

IndicatorResponsible.propTypes = {
  onChange: PropTypes.func,
  field: PropTypes.string,
  dataItem: PropTypes.object,
  render: PropTypes.func,
  selectionChange: PropTypes.func,
};

export const SPTExcludeFilter = (props) => {
  const { t, i18n } = useTranslation();
  const translated = useMemo(
    () =>
      excludeFilter.map((item) => ({
        ...item,
        text: t(item.text),
      })),
    [i18n.language]
  );

  const filterValue = props?.filter?.find((f) => f.field === props?.field);
  const value = filterValue?.filters
    ?.map((f) => excludeFilter.find((s) => s.filterValue?.[0]?.value === f.value))
    .filter((f) => f);

  return <MultiSelectFilter {...props} data={translated} value={value} />;
};

SPTExcludeFilter.propTypes = {
  field: PropTypes.string,
  filter: PropTypes.array,
};

export const SPTTrafficLightFilter = (props) => {
  const { t, i18n } = useTranslation();
  const translated = useMemo(
    () =>
      trafficLightFilterValues.map((item) => ({
        ...item,
        text: t(item.text),
      })),
    [i18n.language]
  );

  const filterValue = props?.filter?.find((f) => f.field === props?.field);
  const value = filterValue?.filters
    ?.map((f) => trafficLightFilterValues.find((s) => s.filterValue?.[0]?.filters?.some((fv) => fv.field === f.field)))
    .filter((f) => f);

  return <MultiSelectFilter {...props} data={translated} value={value} />;
};

SPTTrafficLightFilter.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string,
  filter: PropTypes.array,
};

export const SPTStatusFilter = (props) => {
  const { className = "" } = props;
  const filterValue = props?.filter?.find((f) => f.field === props?.field);
  const value = filterValue?.filters
    ?.map((f) =>
      statusFilterValues.find((s) =>
        s.filterValue?.some((fv) => !!fv.value && fv.value === f.value && fv.field === f.field)
      )
    )
    .filter((f) => f);

  return (
    <MultiSelectFilter
      {...props}
      data={statusFilterValues}
      className={className}
      itemRender={statusItemRender}
      value={value}
    />
  );
};

SPTStatusFilter.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string,
  filter: PropTypes.array,
};

export const ActualNumericFilter = (props) => <SPTNumericFilter {...props} className="primary" />;
export const PotentialNumericFilter = (props) => <SPTNumericFilter {...props} className="secondary" />;
export const ActualTh = () => <FilterTh className="filler-th primary" />;
export const PotentialTh = () => <FilterTh className="filler-th secondary" />;
