import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import jp from "jsonpath";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { KEY } from "../../constants/main";
import { GROUPING_TYPE_INDICATOR } from "../../constants/sustainabilitySystem";
import { getColumnGroupStates } from "../../helpers/advancedTable";
import { useWindowSize } from "../../hooks";
import {
  advancedTableDataSubject,
  columnRangesSubject,
  columnWidthsSubject,
  dragColumnSubject,
  dragRowSubject,
  resizeColumnSubject,
  selectedRowSubject,
  setAdvancedTableData,
  setColumnRanges,
  setColumnWidths,
  setDragColumn,
  setDragRow,
  setResizeColumn,
  setTableHoverControls,
  setTableOffsetX,
  setTableScrollWidth,
  setTableScrollX,
  setTableScrollY,
  tableHoverControlsSubject,
  tableOffsetXSubject,
  tableScrollXSubject,
  tableScrollYSubject,
  useAdvancedTableData,
} from "../../hooks/advancedTable";
import { useEvent } from "../../hooks/utils/useEvent";
import {
  findParentNode,
  findRecursive,
  forEachRecursive,
  forEachRecursiveReverse,
  getOffsetLeftRecursive,
  getOffsetTopRecursive,
  mapRecursive,
} from "../../utils";
import { ContentBlockError } from "../ContentBlock/ContentBlockError/ContentBlockError";
import "./AdvancedTable.scss";
import { AdvancedTableBodyFooterRow } from "./AdvancedTableBodyFooterRow";
import { AdvancedTableBodyRowWrapper } from "./AdvancedTableBodyRowWrapper";
import { AdvancedTableDragOverlay } from "./AdvancedTableDragOverlay";
import { AdvancedTableFooter } from "./AdvancedTableFooter";
import { AdvancedTableHead } from "./AdvancedTableHead";
import { AdvancedTableHeaderExpandCollapse } from "./AdvancedTableHeaderExpandCollapse";
import { AdvancedTableHeaderSearch } from "./AdvancedTableHeaderSearch";
import { HoverControls } from "./HoverControls";

const COLUMN_MIN_WIDTH = 100;
const ROW_LANDING_BETWEEN_HEIGHT = 16;
const DEFAULT_STICKY_AMOUNT = 3;

let resizeColumnsTimeout = null;

const defaultStringSearch = (node, field, value) => {
  return (node[field] || "").toLowerCase().indexOf(value.toLowerCase()) === -1;
};

const defaultArraySearch = (node, field, value, connector) => {
  if (value.length === 0) {
    return false;
  }

  return value.reduce(
    (result, currentValue) =>
      connector === "OR"
        ? result || defaultStringSearch(node, field, currentValue)
        : result && defaultStringSearch(node, field, currentValue),
    connector !== "OR"
  );
};

const DEFAULT_SEARCH_EVALUATION_FUNCTION = (node, field, value, connector = "AND") =>
  typeof value === "string"
    ? defaultStringSearch(node, field, value)
    : defaultArraySearch(node, field, value, connector);

const getSearchValue = (search, field) => {
  const data = search[field] && search[field].data;
  if (data == null) {
    return data;
  }

  return typeof data === "string" ? data.trim() : data.map((x) => x.value.trim());
};

export const ADVANCED_TABLE_ID = "advanced-table";

export const AdvancedTable = React.memo((props) => {
  const { t } = useTranslation();

  const {
    data,
    previousData,
    onChange,
    additionalUpdateLogic,
    onChangePosition,
    readOnly,
    sortRows,
    disableColumnDrag,
    disableColumnLock,
    disableRowDrag,
    disableHoverControls,
    getNodeChangedFields,
    columns,
    className,
    getRowClassName,
    emptyText,
    submitted,
    errorsByPath,
    groupingField,
    onSearch,
    search,
    footer,
    renderAddPopover,
    renderHoverControlButtons,
    matchDropLogic,
    passThrough,
    columnGroups,
    onRowSelect,
    selectedRow,
    singleLineHeader,
    errorMessage,
    expandRowCondition,
    expandRowsByCondition,
    errorMarkRowsByCondition,
    errorMarkRowCondition,
    collapsedLevel,
    hiddenColumns,
    setHiddenColumns,
    stickyAmount,
    collapseRowsByCondition,
    collapseRowCondition,
  } = props;

  const sticky = stickyAmount !== undefined ? stickyAmount : DEFAULT_STICKY_AMOUNT;

  useEffect(() => {
    selectedRowSubject.next(selectedRow);
  }, [selectedRow]);

  const filterOutHiddenColumns = (columnsFilter, hiddenColumnsColumns) =>
    columnsFilter.filter((item) => (hiddenColumnsColumns || []).indexOf(item.field || item.fieldKey) === -1);

  const [columnsProcessed, setColumnsProcessed] = useState(
    filterOutHiddenColumns([...columns.map((item) => ({ ...item }))], hiddenColumns)
  );
  const [popoverTarget, setPopoverTarget] = useState(null);
  const [collapsed, setCollapsed] = useState({});
  const [hiddenRows, setHiddenRows] = useState({});
  const [errorRows, setErrorRows] = useState({});
  const [refreshHiddenRows, setRefreshHiddenRows] = useState(0);
  const processedData = useAdvancedTableData();
  const tableRef = useRef(null);
  const windowSize = useWindowSize();

  const passThroughMemo = useMemo(
    () => passThrough,
    [...Object.keys(passThrough || {}).map((key) => passThrough[key])]
  );

  const expandCollapse = (levelToChange, value) => {
    const updateCollapsed = { ...collapsed };
    forEachRecursive(
      processedData,
      (node, tree, i, level) => {
        if (level === levelToChange) {
          if (value) {
            updateCollapsed[node.UID] = true;
          } else {
            delete updateCollapsed[node.UID];
          }
        }
      },
      groupingField
    );
    setCollapsed(updateCollapsed);
    updateHiddenRows(updateCollapsed);
  };

  const onSearchChanged = (field, dataOnSearchChanged, searchFunction) => {
    let updated;
    searchFunction = searchFunction ?? DEFAULT_SEARCH_EVALUATION_FUNCTION;

    if (!dataOnSearchChanged) {
      updated = { ...search, [field]: null };
    } else {
      updated = { ...search, [field]: { data: dataOnSearchChanged, searchFunction } };
    }

    onSearch(updated);
  };
  const resetSearchField = (field) => {
    onSearchChanged(field, undefined);
    setPopoverTarget(null);
  };
  const onTableScroll = (event) => {
    setTableScrollX(event.target.scrollLeft || 0);
    setTableScrollY(event.target.scrollTop || 0);
  };

  const recalculateColumnWidths = () => {
    if (tableRef && tableRef.current) {
      const columnNodes = getColumnNodes(tableRef.current);
      const widths = columnNodes.map((item) => item.offsetWidth);
      setColumnsProcessed(
        filterOutHiddenColumns(
          columnsProcessed.map((item, index) => ({
            ...item,
            width: !!item.minWidth && item.minWidth > widths[index] ? item.minWidth : widths[index],
          })),
          hiddenColumns
        )
      );
      setTableScrollWidth(tableRef.current.scrollWidth);
    }
  };

  useEffect(
    () => setColumnsProcessed(filterOutHiddenColumns([...columns.map((item) => ({ ...item }))], hiddenColumns)),
    [columns]
  );

  useEffect(() => {
    setTableScrollX(0);
    setTableOffsetX(0);
    setColumnRanges([]);
    setColumnWidths([]);
    setTableHoverControls(null);
    setDragColumn(null);
    setResizeColumn(null);
    setDragRow(null);
    setAdvancedTableData([]);
  }, []);

  useEffect(() => {
    updateColumns(copyColumns(columns));
  }, [hiddenColumns]);

  useEffect(() => {
    if (!!collapsedLevel && collapsedLevel.level !== undefined) {
      expandCollapse(collapsedLevel.level, !!collapsedLevel.value);
    }
  }, [collapsedLevel]);

  useEffect(() => {
    if (expandRowsByCondition && expandRowCondition) {
      const UIDs = Object.keys(collapsed).filter((UID) => collapsed[UID]);
      if (UIDs.length) {
        const toExpand = {};
        forEachRecursiveReverse(
          processedData,
          (node, tree, i, level, parent, path) => {
            if (node[groupingField].some((item) => toExpand[item.UID]) || expandRowCondition(node, path)) {
              toExpand[parent ? parent.UID : node.UID] = true;
            }
          },
          groupingField
        );
        const collapsedUIDs = UIDs.filter((UID) => !toExpand[UID]);
        const updateCollapsed = {};
        collapsedUIDs.forEach((UID) => {
          updateCollapsed[UID] = true;
        });
        setCollapsed(updateCollapsed);
        updateHiddenRows(updateCollapsed);
      }
    }
  }, [expandRowsByCondition, expandRowCondition]);

  useEffect(() => {
    if (collapseRowsByCondition && collapseRowCondition) {
      const UIDs = Object.keys(collapsed).filter((UID) => collapsed[UID]);
      const updateCollapsed = {};
      UIDs.forEach((UID) => {
        updateCollapsed[UID] = true;
      });
      let count = 0;
      forEachRecursiveReverse(
        processedData,
        (node) => {
          if (!updateCollapsed[node.UID] && collapseRowCondition(node)) {
            updateCollapsed[node.UID] = true;
            count++;
          }
        },
        groupingField
      );
      if (count) {
        setCollapsed(updateCollapsed);
        updateHiddenRows(updateCollapsed);
      }
    }
  }, [collapseRowsByCondition, collapseRowCondition]);

  useEffect(() => {
    if (errorMarkRowsByCondition && errorMarkRowCondition) {
      const errorRowsMarkRowsCondition = {};
      forEachRecursiveReverse(
        processedData,
        (node, tree, i, level, parent, path) => {
          if (errorMarkRowCondition(node, path)) {
            errorRowsMarkRowsCondition[node.UID] = true;
          }
        },
        groupingField
      );
      setErrorRows(errorRowsMarkRowsCondition);
    }
  }, [errorMarkRowsByCondition, errorMarkRowCondition]);

  useEffect(() => {
    if (refreshHiddenRows) {
      updateHiddenRows();
    }
  }, [refreshHiddenRows]);

  useEffect(() => {
    setAdvancedTableData(
      mapRecursive(
        data,
        (node, children) => {
          return {
            ...node,
            [groupingField]: children.sort((a, b) => {
              if (a.type === b.type) {
                return 0;
              }
              return a.type === GROUPING_TYPE_INDICATOR ? -1 : 1;
            }),
          };
        },
        groupingField
      )
    );
  }, [data]);

  useEffect(() => {
    if (windowSize.width) {
      if (resizeColumnsTimeout) {
        clearTimeout(resizeColumnsTimeout);
      }
      resizeColumnsTimeout = setTimeout(recalculateColumnWidths, 0);
    }
    return () => {
      if (resizeColumnsTimeout) {
        clearTimeout(resizeColumnsTimeout);
      }
    };
  }, [windowSize.width]);

  const getColumnNodes = (target) => {
    const tableNode = findParentNode(target, ".advanced-table");
    return [...tableNode.querySelectorAll(".MuiTableCell-head.table-cell-head")];
  };

  const getColumnPosition = useCallback(
    (index) => {
      const widths = columnsProcessed.map((item) => item.width);
      let res = 0;
      for (let i = 0; i < index; i++) {
        res += widths[i];
      }
      return res;
    },
    [columnsProcessed]
  );

  const columnGrabStart = (event, column, order) => {
    const { pageX, target } = event;
    const tableScrollX = tableScrollXSubject.getValue();
    const tableNode = findParentNode(target.parentNode, ".advanced-table");
    if (tableNode) {
      const ranges = [];
      let offsetLeft = getOffsetLeftRecursive(tableNode) - tableScrollX;
      setTableOffsetX(offsetLeft);
      let from = 0;
      const columnNodes = getColumnNodes(tableNode);
      setColumnWidths(columnNodes.map((item) => item.offsetWidth));
      columnNodes.forEach((item, index) => {
        const itemWidth = item.offsetWidth;
        const to = columnNodes.length - 1 === index ? 0 : offsetLeft + itemWidth;
        ranges.push({ from, to });
        from = to;
        offsetLeft += itemWidth;
      });
      const columnNode = findParentNode(target, ".MuiTableCell-head");

      setColumnRanges(ranges);
      setDragColumn({
        column,
        startX: pageX,
        offsetX: pageX - getOffsetLeftRecursive(columnNode),
        currentX: pageX,
        currentY: tableNode.offsetTop,
        startOrder: order,
        order,
      });
    }
  };

  const rowClassName = useCallback((row, index) => {
    let res = getRowClassName ? getRowClassName(row, index) : "";
    const dragRow = dragRowSubject.getValue();
    if (dragRow && row.UID === dragRow.UID) {
      res += " drag";
    }
    return res;
  }, []);

  const rowGrabStart = useCallback((event, UID) => {
    event.stopPropagation();
    const tableScrollX = tableScrollXSubject.getValue();
    const tableScrollY = tableScrollYSubject.getValue();
    const { pageY, target } = event;
    const rowNode = findParentNode(target, ".MuiTableRow-root");
    const tableNode = findParentNode(rowNode.parentNode, ".advanced-table");

    const rows = [...tableNode.querySelectorAll("tbody .MuiTableRow-root")].map((row) => {
      return {
        top: row.offsetTop,
        height: row.offsetHeight,
        UID: row.id,
      };
    });

    const landings = [];
    const halfHeightBetween = ROW_LANDING_BETWEEN_HEIGHT / 2;
    let isFirst = true;
    rows.forEach((row) => {
      const bottomPosition = row.top + row.height;
      landings.push({
        from: isFirst ? row.top : row.top + halfHeightBetween,
        to: bottomPosition - halfHeightBetween,
        asChild: true,
        UID: row.UID,
      });
      isFirst = false;
      landings.push({
        from: bottomPosition - halfHeightBetween,
        to: bottomPosition + halfHeightBetween,
        asChild: false,
        UID: row.UID,
      });
    });

    const tableY = getOffsetTopRecursive(tableNode);
    const position = pageY - tableY + tableScrollY;
    let currentY = tableY;
    let height = rowNode.offsetHeight;
    const landing = landings.find((item) => item.from <= position && item.to > position);
    if (landing) {
      currentY += landing.from;
      height = landing.to - landing.from;
    }

    setTableHoverControls(null);
    setTableOffsetX(getOffsetLeftRecursive(tableNode) - tableScrollX);
    setDragRow({
      offsetY: pageY - getOffsetTopRecursive(rowNode),
      width: tableNode.offsetWidth,
      targetUID: null,
      asChild: false,
      height,
      currentY,
      tableY,
      UID,
      landings,
    });
  }, []);

  const columnResizeStart = (event, column, index) => {
    event.preventDefault();
    event.stopPropagation();
    const { pageX } = event;
    const columnNode = findParentNode(event.target, ".MuiTableCell-head");

    setResizeColumn({
      startX: pageX,
      startWidth: "number" === typeof column.width ? column.width : columnNode.clientWidth,
      index: index,
    });
  };

  const onPointerMove = (event) => {
    event.stopPropagation();
    const tableScrollX = tableScrollXSubject.getValue();
    const tableScrollY = tableScrollYSubject.getValue();
    const dragColumn = dragColumnSubject.getValue();
    const resizeColumn = resizeColumnSubject.getValue();
    const dragRow = dragRowSubject.getValue();
    const columnRanges = columnRangesSubject.getValue();
    const columnWidths = columnWidthsSubject.getValue();
    const currentX = event.pageX;
    if (dragColumn) {
      let center;
      if (dragColumn.column.locked) {
        center = currentX - dragColumn.offsetX - tableScrollX - tableScrollX + columnWidths[dragColumn.startOrder] / 2;
      } else {
        center = currentX - dragColumn.offsetX - tableScrollX + columnWidths[dragColumn.startOrder] / 2;
      }
      const order = columnRanges.findIndex(
        (item) => (!item.from || center >= item.from) && (!item.to || center < item.to)
      );
      let newOrder = dragColumn.startOrder;
      if (
        order !== -1 &&
        ((dragColumn.column.locked && order < lockedColumns.length) ||
          (!dragColumn.column.locked && order >= lockedColumns.length))
      ) {
        newOrder = order;
      }
      setDragColumn({ ...dragColumn, currentX, order: newOrder });
    } else if (resizeColumn) {
      if (resizeColumnsTimeout) {
        clearTimeout(resizeColumnsTimeout);
      }
      resizeColumnsTimeout = setTimeout(() => {
        const updated = copyColumns(columnsProcessed);
        const column = updated[resizeColumn.index];
        const { startWidth, startX } = resizeColumn;
        const width = startWidth + currentX - startX;
        const minWidth = column.minWidth || COLUMN_MIN_WIDTH;
        const element = tableRef.current.querySelector(
          `.table-cell-head:nth-child(${resizeColumn.index + (lockedColumns.length ? 2 : 1)})`
        );
        if (element) {
          element.style.width = (width < minWidth ? minWidth : width) + "px";
        }
      }, 0);
    } else if (dragRow) {
      const { pageY } = event;
      const { landings, tableY } = dragRow;
      const position = pageY - tableY + tableScrollY;
      const landing = landings.find((item) => item.from <= position && item.to > position);
      if (landing) {
        const currentY = tableY + landing.from;
        const height = landing.to - landing.from;
        setDragRow({ ...dragRow, currentY, height, targetUID: landing.UID, asChild: landing.asChild });
      }
    }
  };

  const onPointerEnd = (event) => {
    event.stopPropagation();
    const dragColumn = dragColumnSubject.getValue();
    const resizeColumn = resizeColumnSubject.getValue();
    const dragRow = dragRowSubject.getValue();
    if (dragColumn) {
      const { startOrder, order } = dragColumn;
      if (startOrder < order) {
        const updated = copyColumns(columnsProcessed);
        for (let i = startOrder; i < order; i++) {
          const tmp = updated[i];
          const next = i + 1;
          updated[i] = updated[next];
          updated[next] = tmp;
        }
        updateColumns(updated);
      }
      if (startOrder > order) {
        const updated = copyColumns(columnsProcessed);
        for (let i = startOrder; i > order; i--) {
          const tmp = updated[i];
          const next = i - 1;
          updated[i] = updated[next];
          updated[next] = tmp;
        }
        updateColumns(updated);
      }
      setDragColumn(null);
    } else if (resizeColumn) {
      const currentX = event.pageX;
      const updated = copyColumns(columnsProcessed);
      const column = updated[resizeColumn.index];
      const { startWidth, startX } = resizeColumn;
      const width = startWidth + currentX - startX;
      const minWidth = column.minWidth || COLUMN_MIN_WIDTH;
      column.width = width < minWidth ? minWidth : width;
      updateColumns(updated);
      setResizeColumn(null);
      if (resizeColumnsTimeout) {
        clearTimeout(resizeColumnsTimeout);
      }
      resizeColumnsTimeout = setTimeout(recalculateColumnWidths, 0);
    } else if (dragRow) {
      const { UID, targetUID, asChild } = dragRow;
      if (UID !== targetUID) {
        const source = flatData.find((item) => item.UID === UID);
        const target = flatData.find((item) => item.UID === targetUID);

        if (!source || !target) {
          setDragRow(null);
          return false;
        }

        let isParent = false;
        forEachRecursive(
          source[groupingField],
          (node) => {
            if (node.UID === targetUID) {
              isParent = true;
              return true;
            }
          },
          groupingField
        );

        if (!isParent && (!matchDropLogic || matchDropLogic(source, target, asChild))) {
          const updated = [...processedData];
          let sourceParentChildren = source.parent ? source.parent[groupingField] : updated;
          const [sourceNode] = sourceParentChildren.splice(source.index, 1);

          if (asChild) {
            target[groupingField].splice(0, 0, sourceNode);
          } else {
            let targetParentChildren = target.parent ? target.parent[groupingField] : updated;
            targetParentChildren.splice(target.index + 1, 0, sourceNode);
          }
          !!onChangePosition && onChangePosition(updated);
          setRefreshHiddenRows(refreshHiddenRows + 1);
        }
      }
      setDragRow(null);
    }
  };

  const getColumnX = (order, locked) => {
    const tableScrollX = tableScrollXSubject.getValue();
    const tableOffsetX = tableOffsetXSubject.getValue();
    const columnWidths = columnWidthsSubject.getValue();
    let res = tableOffsetX + (locked ? tableScrollX : 0);
    for (let i = 0; i < order; i++) {
      if (columnWidths[i]) {
        res += columnWidths[i];
      }
    }
    return res;
  };

  const copyColumns = (columnsCopyColumns) => [...columnsCopyColumns.map((item) => ({ ...item }))];

  const updateColumns = (columnsUpdate) => {
    const updated = [];
    columnsUpdate.filter((item) => item.locked).forEach((item) => updated.push(item));
    columnsUpdate.filter((item) => !item.locked).forEach((item) => updated.push(item));
    setColumnsProcessed(filterOutHiddenColumns(updated, hiddenColumns));
  };

  const fixedColumnWidths = (target, updated) => {
    const widths = getColumnNodes(target).map((item) => item.offsetWidth);
    updated.forEach((item, index) => {
      item.width = widths[index];
    });
    return updated;
  };

  const toggleColumnLocked = (index, target) => {
    setPopoverTarget(null);
    setPopoverTarget(null);
    const updated = copyColumns(columnsProcessed);
    const column = updated[index];
    column.locked = !column.locked;
    updateColumns(fixedColumnWidths(target, updated));
  };

  const showHoverControls = useCallback((event, index, UID) => {
    event.stopPropagation();
    const { target } = event;
    const node = findParentNode(target, ".MuiTableCell-root.MuiTableCell-body");
    const tableNode = findParentNode(target.parentNode, ".advanced-table");

    if (!node || !tableNode) {
      return null;
    }

    const offsetTop = node.offsetParent.offsetTop;

    setTableHoverControls({
      index,
      target,
      UID,
      top: offsetTop + node.offsetHeight + "px",
      width: tableNode.offsetWidth - 2 + "px",
      hidden: false,
    });
  }, []);

  const updateValue = useCallback((UID, field, value, jsonPath) => {
    const processedDataUpdateValue = advancedTableDataSubject.getValue();
    const row = findRecursive(processedDataUpdateValue, (node) => node.UID === UID);
    if (row && !jsonPath) {
      const prevValue = row[field];
      row[field] = value;
      let updated = [...processedDataUpdateValue];
      if (additionalUpdateLogic) {
        updated = additionalUpdateLogic(updated, row, field, value, prevValue);
      }
      onChange(updated);
    } else if (row && jsonPath) {
      jp.apply(row, jsonPath, () => value);
      let updated = [...processedDataUpdateValue];
      onChange(updated);
    }
  }, []);

  const updateHiddenRows = useEvent((updateCollapsed = collapsed) => {
    const hidden = {};
    forEachRecursive(
      data,
      (node, tree, index, level, parent) => {
        if (parent && (updateCollapsed[parent.UID] || hidden[parent.UID])) {
          hidden[node.UID] = true;
        }
      },
      groupingField
    );
    setHiddenRows(hidden);
  });

  const toggleRowCollapsed = useCallback(
    (UID) => {
      const updateCollapsed = { ...collapsed, [UID]: !collapsed[UID] };
      setCollapsed(updateCollapsed);
      updateHiddenRows(updateCollapsed);
    },
    [collapsed]
  );

  const processPreviousNode = (node) => {
    previousByUID[node.UID] = node;
    if (nodesByUID[node.UID]) {
      (getNodeChangedFields(nodesByUID[node.UID], node) || []).forEach((field) => {
        if (!changedByUIDField[node.UID]) {
          changedByUIDField[node.UID] = {};
        }
        changedByUIDField[node.UID][field] = true;
      });
    }
  };

  const lockedColumns = columnsProcessed.filter((item) => item.locked);

  const nodesByUID = {};
  const previousByUID = {};
  const changedByUIDField = {};
  const changedByUID = {};
  const visibleByUID = {};
  const flatData = groupingField ? [] : processedData;
  let searching = false;
  const searchKeys = Object.keys(search);
  if (searchKeys.length) {
    for (let field of searchKeys) {
      if (getSearchValue(search, field)) {
        searching = true;
        break;
      }
    }
  }
  if (groupingField) {
    if (searching) {
      mapRecursive(
        processedData,
        (node, children) => {
          let found = true;
          for (let field of searchKeys) {
            const value = getSearchValue(search, field);
            if (!!value && search[field].searchFunction(node, field, value)) {
              found = false;
              break;
            }
          }
          if (!found && children) {
            for (let child of children) {
              if (visibleByUID[child.UID]) {
                found = true;
                break;
              }
            }
          }
          visibleByUID[node.UID] = found;
          return { ...node, [groupingField]: children };
        },
        groupingField
      );
    }
    forEachRecursive(
      processedData,
      (node, tree, index, level, parent, path) => {
        nodesByUID[node.UID] = node;
        if (!node[groupingField]) {
          node[groupingField] = [];
        }
        flatData.push({ ...node, index, level, parent, error: (errorsByPath || {})[path.join("_")] });
      },
      groupingField
    );
    if (previousData && getNodeChangedFields) {
      forEachRecursive(previousData, processPreviousNode, groupingField);
    }
  } else {
    flatData.forEach((node, index) => {
      nodesByUID[node.UID] = node;
      node.error = (errorsByPath || {})[index];
    });
    if (previousData && getNodeChangedFields) {
      previousData.forEach(processPreviousNode);
    }
  }
  if (previousData && getNodeChangedFields) {
    flatData.forEach((node) => {
      if (!previousByUID[node.UID]) {
        changedByUID[node.UID] = true;
      }
    });
  }
  let processed = (flatData || []).filter((item) => !hiddenRows[item.UID] && (!searching || visibleByUID[item.UID]));
  if (sortRows) {
    processed = processed.sort(sortRows);
  }
  const processedSorted = useMemo(() => processed, [search, processedData, hiddenRows]);
  const columnGroupStates = useMemo(
    () => getColumnGroupStates(columnsProcessed, columnGroups),
    [columnsProcessed, columnGroups]
  );


  const handleKeyPress = (event) => {
    if (event.key === KEY.ENTER) {
      event.preventDefault();
      setPopoverTarget(null);
    }
  }

  const levels = useMemo(() => {
    let expand = 0;
    let collapse = 0;
    const invisible = {};
    forEachRecursive(
      processedData,
      (node, tree, i, level, parent) => {
        if (collapsed[node.id]) {
          (node[groupingField] || []).forEach((item) => {
            invisible[item.id] = true;
          });
        }
        if (!parent || !invisible[node.id]) {
          if (collapsed[node.id] && level > expand) {
            expand = level;
          }
          if (!collapsed[node.id] && !!node[groupingField] && !!node[groupingField].length && level > collapse) {
            collapse = level;
          }
        }
      },
      groupingField
    );
    return { expand, collapse };
  }, [processedData, collapsed]);

  return (
    <>
      <ContentBlockError text={errorMessage} show={!!errorMessage} className="advanced-table-error" />
      <TableContainer
        ref={tableRef}
        component={Paper}
        className={
          "advanced-table " + (readOnly ? "read-only " : "") + (className || "") + (errorMessage ? "with-error" : "")
        }
        id={ADVANCED_TABLE_ID}
        onScroll={onTableScroll}
        onMouseLeave={
          !readOnly
            ? (event) =>
                event.stopPropagation() &
                setTableHoverControls({
                  ...tableHoverControlsSubject.getValue(),
                  hidden: true,
                })
            : null
        }
      >
        <AdvancedTableDragOverlay
          className={singleLineHeader ? "single-line-overlay" : ""}
          onPointerMove={onPointerMove}
          onPointerEnd={onPointerEnd}
          getColumnX={getColumnX}
        />

        <Popover
          onKeyDown={handleKeyPress}
          open={!!(popoverTarget || {}).target}
          anchorEl={(popoverTarget || {}).target}
          onClose={() => setPopoverTarget(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <div className="popover-inner">
            {!!popoverTarget &&
              !!popoverTarget.column.search &&
              !popoverTarget.column.searchTemplate &&
              (popoverTarget.column.groupingColumn ? (
                <>
                  <AdvancedTableHeaderSearch
                    column={popoverTarget.column}
                    search={search}
                    onSearchChanged={onSearchChanged}
                    resetSearchField={resetSearchField}
                  />
                  <AdvancedTableHeaderExpandCollapse
                    expandCollapse={expandCollapse}
                    expandLevel={levels.expand}
                    collapseLevel={levels.collapse}
                  />
                </>
              ) : (
                <AdvancedTableHeaderSearch
                  column={popoverTarget.column}
                  search={search}
                  onSearchChanged={onSearchChanged}
                  resetSearchField={resetSearchField}
                />
              ))}
            {!!popoverTarget &&
              !!popoverTarget.column.searchTemplate &&
              popoverTarget.column.searchTemplate(
                { ...popoverTarget.column },
                search,
                onSearchChanged,
                resetSearchField
              )}
            {!disableColumnLock && (
              <MenuItem
                className="advanced-table-column-lock"
                onClick={() => toggleColumnLocked(popoverTarget.index, popoverTarget.target)}
              >
                {!!popoverTarget && popoverTarget.column.locked ? (
                  <>
                    <LockOpenOutlinedIcon />
                    {t("main.unlock")}
                  </>
                ) : (
                  <>
                    <LockOutlinedIcon />
                    {t("main.lock")}
                  </>
                )}
              </MenuItem>
            )}
          </div>
        </Popover>
        {!readOnly && !disableHoverControls && (
          <HoverControls
            flatData={flatData}
            renderAddPopover={renderAddPopover}
            renderHoverControlButtons={renderHoverControlButtons}
          />
        )}
        <Table>
          <AdvancedTableHead
            columnGrabStart={columnGrabStart}
            columnResizeStart={columnResizeStart}
            columns={columns}
            columnsProcessed={columnsProcessed}
            disableColumnDrag={disableColumnDrag}
            disableColumnLock={disableColumnLock}
            setPopoverTarget={setPopoverTarget}
            singleLineHeader={singleLineHeader}
            columnGroups={columnGroups}
            hiddenColumns={hiddenColumns}
            passThrough={passThroughMemo}
            setHiddenColumns={setHiddenColumns}
            stickyAmount={sticky}
          />
          <TableBody>
            {processedSorted.map((row, rowIndex) => {
              const rowCollapsed = collapsed[row.UID];
              const changedRow = changedByUID[row.UID];
              const changedFieldsByUID = changedByUIDField[row.UID];
              return (
                <AdvancedTableBodyRowWrapper
                  key={row.UID + "_" + rowIndex}
                  stickyAmount={sticky}
                  {...{
                    columnsProcessed,
                    getColumnPosition,
                    row,
                    readOnly,
                    showHoverControls,
                    rowIndex,
                    onRowSelect,
                    rowClassName,
                    groupingField,
                    changedRow,
                    changedFieldsByUID,
                    passThrough: passThroughMemo,
                    columnGroupStates,
                    disableRowDrag,
                    rowGrabStart,
                    rowCollapsed,
                    toggleRowCollapsed,
                    submitted,
                    updateValue,
                    errorRows,
                  }}
                />
              );
            })}
            {!!processed.length && !!(columnsProcessed || []).filter((column) => !!column.footerTemplate).length && (
              <AdvancedTableBodyFooterRow
                getColumnPosition={getColumnPosition}
                columnsProcessed={columnsProcessed}
                processedData={processedData}
                columnGroupStates={columnGroupStates}
                passThrough={passThrough}
                stickyAmount={sticky}
              />
            )}
          </TableBody>
        </Table>
        <AdvancedTableFooter emptyText={emptyText} footer={footer} processed={processed} />
      </TableContainer>
    </>
  );
});
