import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  createProjectGroup,
  updateProjectSubGroup,
  useProjectGroupCreateResponse,
  useProjectSubGroupUpdateResponse,
} from "../../../hooks/projectGroup";
import { FormController } from "../../FormController/FormController";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";
import "./ProjectGroupDialog.scss";

const TEXT_MAX_LENGTH = 1000;

export const ProjectGroupDialog = ({ data, onClose, onChange, parentProjectGroupId }) => {
  const { t } = useTranslation();

  const projectGroupCreateResponse = useProjectGroupCreateResponse();
  const projectSubGroupUpdateResponse = useProjectSubGroupUpdateResponse();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { name: "", description: "" },
  });

  useEffect(() => {
    if (data) {
      reset({ name: data.name, description: data.description });
    }
  }, [data]);

  const isEdit = !!data;
  const isSubGroup = !!parentProjectGroupId;
  const loading = projectGroupCreateResponse.loading || projectSubGroupUpdateResponse.loading;

  const onSubmit = (values) => {
    if (isEdit) {
      updateProjectSubGroup(data.id, values).then(onChange).catch(console.error);
    } else if (isSubGroup) {
      createProjectGroup(values, parentProjectGroupId).then(onChange).catch(console.error);
    } else {
      createProjectGroup(values).then(onChange).catch(console.error);
    }
  };

  return (
    <LoadingOverlay spinner className="auto-height project-group-dialog" active={loading}>
      <h2>
        {isSubGroup
          ? t("projectGroup.modalCreateSubGroupTitle")
          : isEdit
          ? t("projectGroup.modalEditGroupTitle")
          : t("projectGroup.modalCreateGroupTitle")}
        <IconButton onClick={onClose} size="small" className={"pull-right"}>
          <CloseOutlinedIcon />
        </IconButton>
      </h2>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"} className="custom-dialog-body">
        <div className="dialog-body">
          <FormController
            control={control}
            name="name"
            required
            label={t("projectGroup.name")}
            render={({ field, fieldState, label }) => (
              <TextField
                {...field}
                fullWidth
                error={fieldState.invalid}
                label={label + " *"}
                inputProps={{ maxLength: 255 }}
                value={field.value || ""}
              />
            )}
          />
          <FormController
            control={control}
            name="description"
            required
            label={t("projectGroup.description")}
            render={({ field, fieldState, label }) => (
              <>
                <TextField
                  {...field}
                  fullWidth
                  error={fieldState.invalid}
                  label={label + " *"}
                  inputProps={{ maxLength: TEXT_MAX_LENGTH }}
                  value={field.value || ""}
                  multiline
                />
                <div className="chars-left">
                  {t("main.charactersLeft")}: {TEXT_MAX_LENGTH - (field.value || "").length}
                </div>
              </>
            )}
          />
        </div>

        <div className="buttons text-right">
          <Button onClick={onClose}>{t("main.cancel")}</Button>
          <Button type="submit" color="primary">
            {t("main.save")}
          </Button>
        </div>
      </form>
    </LoadingOverlay>
  );
};
