import { BehaviorSubject } from "rxjs";
import { hookFromSubject } from "react-rxjs-easy";

let imageSourceMapSubject = new BehaviorSubject({});
let imageCountMapSubject = new BehaviorSubject({});

export const useImageSourceMap = hookFromSubject(imageSourceMapSubject);
export const useImageCountMap = hookFromSubject(imageCountMapSubject);
export const imageLockSubject = new BehaviorSubject(false);
export const useImageLock = hookFromSubject(imageLockSubject);

export const resetImageSourceMap = () => {
    imageSourceMapSubject.next({});
    imageCountMapSubject.next({});
}

export const setImageSourceToMap = (uid, imageSource = {}, count = 0) => {
    const imageSourceSubjectMap = {...imageSourceMapSubject.getValue()}; 
    const imageCountSubjectMap = {...imageCountMapSubject.getValue()};
    imageSourceSubjectMap[uid] = imageSource;
    imageCountSubjectMap[uid] = count;
    imageSourceMapSubject.next(imageSourceSubjectMap);
    imageCountMapSubject.next(imageCountSubjectMap);
}

export const removeImageSourceFromMap = (uid) => {
    const imageSourceSubjectMap = {...imageSourceMapSubject.getValue()}; 
    const imageCountSubjectMap = {...imageCountMapSubject.getValue()};
    delete imageSourceSubjectMap[uid];
    delete imageCountSubjectMap[uid];
    imageSourceMapSubject.next(imageSourceSubjectMap);
    imageCountMapSubject.next(imageCountSubjectMap);
}

export const getImageSourceFromMap = (uid) => {
    const imageSourceSubjectMap = {...imageSourceMapSubject.getValue()};
    return imageSourceSubjectMap[uid];
};

export const getImageCountFromMap = (uid) => {
    const imageCountSubjectMap = {...imageCountMapSubject.getValue()};
    return imageCountSubjectMap[uid];
};

export const useRichTextImageSourceState = (uid) => {
    const imageSource = useImageSourceMap();
    const count = useImageCountMap();
    const imageLock = useImageLock();
    
    return { imageSource: imageSource[uid], count: count[uid], imageLock };
}

export const setImageCount = (uid, value) => {
    const imageCountSubjectMap = {...imageCountMapSubject.getValue()};
    imageCountSubjectMap[uid] = value;
    imageCountMapSubject.next(imageCountSubjectMap);
};
export const addImageCount = (uid, increment = 1) => setImageCount(uid, imageCountMapSubject.getValue()[uid] + increment);
export const substractImageCount = (uid, decrement = 1) => setImageCount(uid, imageCountMapSubject.getValue()[uid] - decrement);

export const addImageSource = (uid, { url, data }) => {
    const imageSourceMap = {...imageSourceMapSubject.getValue()};

    if (imageSourceMap[uid]) { 
        imageSourceMap[uid][data] = url;
    } else { 
        imageSourceMap[uid] = { [data]: url };
    }
    
    imageSourceMapSubject.next(imageSourceMap);
    addImageCount(uid);
};

export const setImageLock = (imageLock) => imageLockSubject.next(imageLock);