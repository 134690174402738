import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../../hooks/auth";
import { getSinceLastVisitCount, useSinceLastVisitCountSubjectResponse } from "../../../../hooks/project";
import { WidgetWrapper } from "../WidgetWrapper/WidgetWrapper";

export const LastUpdated = () => {
  const user = useUser();
  const lastUpdated = useSinceLastVisitCountSubjectResponse();
  const { t } = useTranslation();

  useEffect(() => {
    getSinceLastVisitCount(user.user.lastLogin || new Date().toISOString().split('T')[0] + "T00:00:00")
  }, [user]);

  return (
    <WidgetWrapper data={lastUpdated.data?.map((d, i) => ({ value: d.count || 0, title: t("project.count." + d.countName) || ''})) || []}
                   title={t("project.count.title")} loading={lastUpdated.loading} />
  );
};