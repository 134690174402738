import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useTranslation } from "react-i18next";
import { useKPIListResponse } from "../../../hooks/kpi";
import { findInValue } from "../../../utils";

export const KPITemplate = (row) => {
  const value = row["kpis"];
  return (
    <div className="text-right">
      {(value && value.length ? value : []).map((item) => (item ? item.name + " (" + item.unit + ")" : "")).join(", ")}
    </div>
  );
};

const filterOption = (option, search) =>
  !search ||
  findInValue(option.name, search) ||
  findInValue(option.unit, search) ||
  !!(option.tags || []).find((item) => findInValue(item, search));

export const KPIEditTemplate = (row, column, updateValue) => {
  const { t } = useTranslation();
  const response = useKPIListResponse();
  const options = (response.data || []).map((item) => ({
    name: item.name,
    unit: item.unit,
    description: item.description,
    tags: item.tags,
  }));

  const onChange = (value) => {
    updateValue(row.UID, "kpis", value);
  };

  const values = row["kpis"] || [];

  return (
    <Autocomplete
      fullWidth
      clearOnEscape
      value={values[0] || null}
      onChange={(event, newValue) => {
        onChange(newValue ? [newValue] : []);
      }}
      isOptionEqualToValue={(val1, val2) => val1.name === val2.name && val1.unit === val2.unit}
      loading={response.loading}
      options={options}
      renderTags={(option) => {
        if (!option) {
          return "";
        }
        return (
          <div className="option-with-icon">
            <div className="option-with-icon-title">{option.name + " (" + option.unit + ")"}</div>
            {!!option.description && (
              <Tooltip arrow title={option.description} placement="top">
                <InfoOutlinedIcon />
              </Tooltip>
            )}
          </div>
        );
      }}
      getOptionLabel={(option) => {
        if (!option) {
          return "";
        }
        return option.name + " (" + option.unit + ")";
      }}
      filterOptions={(optionsFilter, { inputValue }) => optionsFilter.filter((item) => filterOption(item, inputValue))}
      noOptionsText={t("main.empty")}
      renderInput={(params) => <TextField {...params} label="" />}
    />
  );
};
