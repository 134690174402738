import { ActionEndpointApi, Configuration } from "../generated-api";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";

const actionApi = new ActionEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const indicatorActionsListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useIndicatorActionListResponse = hookFromSubject(indicatorActionsListSubject);

export const actionsProjectIdSubject = new BehaviorSubject(null);
export const useActionsProjectId = hookFromSubject(actionsProjectIdSubject);
export const setActionsProjectId = projectId => actionsProjectIdSubject.next(projectId);

export const actionFindSubject = new BehaviorSubject(new PromiseSubjectState());
export const useActionFindResponse = hookFromSubject(actionFindSubject);
export const findAction = (actionId) =>
  promiseToSubject(actionApi.apiActionFindActionGet({actionId}), actionFindSubject);

export const actionSuggestionSubject = new BehaviorSubject(new PromiseSubjectState());
export const useActionSuggestionResponse = hookFromSubject(actionSuggestionSubject);
export const getActionSuggestion = (ratingSystemId, kpiRatingSystemId) =>
  promiseToSubject(actionApi.apiActionSuggestionGet({ ratingSystemId, kpiRatingSystemId }), actionSuggestionSubject);
