import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";

const AFTER_CLOSE_DELAY = 300;

export const dialogsSubject = new BehaviorSubject([]);
export const useDialogs = hookFromSubject(dialogsSubject);

export const showDialog = (dialogData) => {
  const dialogs = [...dialogsSubject.getValue()];
  const index = dialogs.length;
  dialogs.push({
    dialogData,
    open: true,
    closeTimeout: null
  });
  dialogsSubject.next(dialogs);
  return index;
};

export const hideDialog = (index) => {
  const dialogs = [...dialogsSubject.getValue()];
  const dialog = dialogs[index];
  if (dialog) {
    if (dialog.closeTimeout) {
      clearTimeout(dialog.closeTimeout);
    }
    dialog.closeTimeout = setTimeout(() => {
      const dialogsInner = [...dialogsSubject.getValue()];
      delete dialogsInner[index];
      dialogsSubject.next(dialogsInner);
    }, AFTER_CLOSE_DELAY);

    !!dialog?.dialogData?.onClose && dialog.dialogData.onClose();
    dialog.open = false;
    dialogsSubject.next(dialogs);
  }
};


export const muiDialogsSubject = new BehaviorSubject([]);
export const useMuiDialogs = hookFromSubject(muiDialogsSubject);

export const showMuiDialog = (component, onClose) => {
  const dialogs = [...muiDialogsSubject.getValue()];
  const index = dialogs.length;
  dialogs.push({
    component,
    onClose,
    open: true,
    closeTimeout: null
  });
  muiDialogsSubject.next(dialogs);
  return index;
};

export const hideMuiDialog = (index) => {
  const dialogs = [...muiDialogsSubject.getValue()];
  const dialog = dialogs[index];
  if (dialog) {
    if (dialog.closeTimeout) {
      clearTimeout(dialog.closeTimeout);
    }
    dialog.closeTimeout = setTimeout(() => {
      const dialogsInner = [...muiDialogsSubject.getValue()];
      dialogsInner[index] = null;
      muiDialogsSubject.next(dialogsInner);
    }, AFTER_CLOSE_DELAY);

    !!dialog?.onClose && dialog.onClose();
    dialog.open = false;
    muiDialogsSubject.next(dialogs);
  }
};
