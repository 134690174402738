import FileDownloadOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  ESG_DD_REPORT_TYPE,
  ESG_GREEN_BUILDING_REPORT_TYPE,
  ESG_GREEN_BUILDING_SHORT_REPORT_TYPE,
  ESG_STANDARD_REPORT_TYPE,
  EXCEL_FILE_TYPE,
  PAST_REPORT_TYPE,
  PDF_FILE_TYPE,
  RTF_FILE_TYPE,
} from "../../constants/reports";
import { TENANT_TYPE_ESG } from "../../constants/tenant";
import "./PrintReportButton.scss";

export const PrintReportButton = ({ projectId, ratingSystemId, language, tenantType }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [documentMenu, setDocumentMenu] = React.useState(null);
  const [documentFileType, setDocumentFileType] = React.useState(null);

  const openDocumentMenu = (fileType, target) => {
    setDocumentFileType(fileType);
    setDocumentMenu(target);
  };

  const openDocument = (reportType = null, fileType = documentFileType) => {
    let url = `/api/project/${projectId}/report/${ratingSystemId}?language=${language}&reportFileType=${fileType}`;
    if (reportType) {
      url += `&reportType=${reportType}`;
    }
    window.open(url, "_blank", "noreferrer");

    setDocumentFileType(null);
    setDocumentMenu(null);
    setAnchorEl(null);
  };

  const closeDocumentMenu = () => {
    setDocumentMenu(null);
    setDocumentFileType(null);
  };

  return (
    <>
      <Button color="primary" className="right-header-button" onClick={(event) => setAnchorEl(event.target)}>
        {t("main.createReport")} <FileDownloadOutlinedIcon/>
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        getContentAnchorEl={null}
        MenuListProps={{ disablePadding: true }}
        className="print-report-button"
      >
        <MenuItem
          onClick={(event) =>
            tenantType === TENANT_TYPE_ESG
              ? openDocumentMenu(PDF_FILE_TYPE, event.target)
              : openDocument(PAST_REPORT_TYPE, PDF_FILE_TYPE)
          }
          className={documentFileType === PDF_FILE_TYPE ? "submenu-opened" : ""}
        >
          {t("main.printPDF")}
        </MenuItem>
        {tenantType !== TENANT_TYPE_ESG && (
          <MenuItem onClick={() => openDocument(PAST_REPORT_TYPE, RTF_FILE_TYPE)}>
            {t("main.printRTF")}
          </MenuItem>
        )}
        {tenantType === TENANT_TYPE_ESG && (
          <MenuItem onClick={() => openDocument(ESG_STANDARD_REPORT_TYPE, EXCEL_FILE_TYPE)}>
            {t("main.printEXCEL")}
          </MenuItem>
        )}
      </Menu>
      <Menu
        open={!!documentMenu}
        anchorEl={documentMenu}
        onClose={() => closeDocumentMenu()}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        getContentAnchorEl={null}
        MenuListProps={{ disablePadding: true }}
        className="print-report-button"
      >
        <MenuItem onClick={() => openDocument(ESG_GREEN_BUILDING_REPORT_TYPE)}>{t("main.esgGreenBuilding")}</MenuItem>
        <MenuItem onClick={() => openDocument(ESG_GREEN_BUILDING_SHORT_REPORT_TYPE)}>{t("main.esgGreenBuildingShort")}</MenuItem>
        <MenuItem onClick={() => openDocument(ESG_DD_REPORT_TYPE)}>{t("main.esgDD")}</MenuItem>
      </Menu>
    </>
  );
};
