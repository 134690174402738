import { ProseMirror } from "@progress/kendo-react-editor";
import { NodeSelection } from "prosemirror-state";
import { hookFromSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { v4 as getUUID } from "uuid";
import { isArrowKey, KEY, LEFT_ALIGN } from "../../../constants/main";
import {
  IMAGE_CONTAINER_NODE_NAME,
  IMAGE_GRID_CELL_NODE_NAME,
  IMAGE_GRID_NODE_NAME,
  IMAGE_NODE_NAME,
} from "../constants";
import { findNode, getModifiedNodes, ONERROR_EVENT_HANDLER, ONLOAD_EVENT_HANDLER, setSelectionToPos } from "../utils";

let axiList = [];
const WHITE_PIXEL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=";

let dragSourceNode = null;
let dragDestinyNode = null;

/**
 * Object that contains image related data. In the attributes the object could contain:
 * - **node (required)**: Prosemirror Node of the image selected
 * - **pos (required)**: Position of the image Node selected
 * - **path (required)**: Path of the image Node selected
 * - **target (required)**: Image HTMLElement
 * - **isOnGrid (required)**: Boolean if the image is on a grid
 * - align: Alignment of the image
 * - keepAspectRatio: Boolean if the image should keep the aspect ratio
 * - width: Original Width of the image HTMLElement
 * - height: Original Height of the image HTMLElement
 * - imageGridNode: Nodes of the image grid (if the image is on a grid)
 *
 */
let selectedImage = null;
let activeEditorUid = null;
export const selectedImageSubject = new BehaviorSubject(null);
const useSelectedImageHook = hookFromSubject(selectedImageSubject);
export const useSelectedImage = (uid) => {
  const result = useSelectedImageHook();
  if (uid !== activeEditorUid) {
    return null;
  }

  return result;
};

let imageClicked = null;

/**
 * @param {Node} node - Old node version
 * @param {Node} newNode - New node version
 * @returns {*} Path of the image Node updated
 */
const updatePath = (node, newNode) =>
  (selectedImage.path = selectedImage.path.map((x) => (node.eq(x.node) ? { ...x, node: newNode } : x)));

/**
 * @param {Node} node - Prosemirror Node of the image selected
 * @param {Node} newNode - New Prosemirror Node of the image selected
 */
const updateSelectedImage = (node, newNode) => {
  selectedImage.node = newNode;
  updatePath(node, newNode);
};

const updateSelectedImageDimension = () => {
  const { target } = selectedImage;
  selectedImage.width = target.offsetWidth;
  selectedImage.height = target.offsetHeight;
};

/**
 * @param {Document} iframeDocument
 */
const updateSelectedImageTarget = (iframeDocument) => {
  const { node } = selectedImage;
  selectedImage.target = iframeDocument.body.querySelector(`img[uid="${node.attrs.uid}"]`);
  updateSelectedImageDimension();
};

/**
 * @param {*} path - Path of the image Node selected
 * @returns {string} Alignment of the image.
 */
const getSelectedImageAlign = (path) => {
  const imageContainer = path.find((x) => x.node.type.name === IMAGE_CONTAINER_NODE_NAME);
  let align = LEFT_ALIGN;
  if (imageContainer) {
    const style = imageContainer.node.attrs.style;
    const alignResult = style.match(/text-align:\s?(.*?);/);
    if (alignResult) {
      align = alignResult[1];
    }
  }

  return align;
};

const setSelectedImage = (value, editorUid = null) => {
  selectedImage = value;
  activeEditorUid = editorUid;
};

export const resetSelectedImageValues = () => {
  setSelectedImage(null);
  selectedImageSubject.next(null);
  deactivateAxis();
};

const updateImageAndAxi = (selectedImage) => {
  selectedImageSubject.next({ ...selectedImage });
  axiList.forEach((axi) => calculateAxiPosition(axi));
};

/**
 * @param {number} width - Width of the image
 * @param {number} height - Height of the image
 * @param {EditorView} view - EditorView of the editor
 */
export const setSelectedImageDimension = (width, height, view) => {
  const { node, pos, target, keepAspectRatio } = selectedImage;
  const widthString = width || !keepAspectRatio ? `${width ?? selectedImage.width}px` : "auto";
  const heightString = height || !keepAspectRatio ? `${height ?? selectedImage.height}px` : "auto";
  target.style.width = widthString;
  target.style.height = heightString;

  const style = node.attrs.style
    .replace(/width:\s?(.*?);/, `width: ${target.offsetWidth}px;`)
    .replace(/height:\s?(.*?);/, `height: ${target.offsetHeight}px;`);

  const transaction = view.state.tr.setNodeAttribute(pos, "style", style);
  const [imageContainerModified] = getModifiedNodes(transaction.doc, view.state.doc);
  view.dispatch(transaction);

  updateSelectedImage(node, imageContainerModified?.content?.content[0]);
  updateSelectedImageTarget(view.dom.ownerDocument);
  updateImageAndAxi(selectedImage);
};

/**
 *
 * @param {EditorView} view - EditorView of the editor
 */
export const restoreSelectedImageToDefault = (view) => {
  const { target, node, pos } = selectedImage;
  target.style.width = "auto";
  target.style.height = "auto";

  const style = node.attrs.style
    .replace(/width:\s?(.*?);/, `width: ${target.offsetWidth}px;`)
    .replace(/height:\s?(.*?);/, `height: ${target.offsetHeight}px;`);
  const transaction = view.state.tr.setNodeAttribute(pos, "style", style);
  const [imageContainerModified] = getModifiedNodes(transaction.doc, view.state.doc);
  view.dispatch(transaction);

  updateSelectedImage(node, imageContainerModified?.content?.content[0]);
  updateSelectedImageTarget(view.dom.ownerDocument);

  updateImageAndAxi(selectedImage);
};

/**
 *
 * @param {EditorView} view - EditorView of the editor
 */
export const toggleSelectedImageKeepAspectRatio = (view) => {
  const { keepAspectRatio, target, node, pos } = selectedImage;
  target.style.height = "auto";

  const style = node.attrs.style
    .replace(/width:\s?(.*?);/, `width: ${target.offsetWidth}px;`)
    .replace(/height:\s?(.*?);/, `height: ${target.offsetHeight}px;`);
  const transaction = view.state.tr.setNodeAttribute(pos, "style", style);
  const [imageContainerModified] = getModifiedNodes(transaction.doc, view.state.doc);
  view.dispatch(transaction);

  updateSelectedImage(node, imageContainerModified?.content?.content[0]);
  updateSelectedImageTarget(view.dom.ownerDocument);

  selectedImage.keepAspectRatio = !keepAspectRatio;
  updateImageAndAxi(selectedImage);
};

/**
 * @param {string} align - Alignment of the image
 * @param {EditorView} view - EditorView of the editor
 */
export const setSelectedImageAlign = (align, view) => {
  const { path } = selectedImage;
  const { node, pos } = path.find((x) => x.node.type.name === IMAGE_CONTAINER_NODE_NAME);
  const style = node.attrs.style.replace(/text-align:\s?(.*?);/, `text-align: ${align};`);

  const transaction = view.state.tr.setNodeAttribute(pos, "style", style);
  const [imageContainerModified] = getModifiedNodes(transaction.doc, view.state.doc);
  view.dispatch(transaction);

  updateSelectedImage(selectedImage.node, imageContainerModified?.content?.content[0]);
  updatePath(node, imageContainerModified);
  updateSelectedImageTarget(view.dom.ownerDocument);
  setSelectedImage({ ...selectedImage, align }, view.dom.getAttribute("uid"));

  updateImageAndAxi(selectedImage);
};

/**
 * @param {EditorView} view - EditorView of the editor
 */
export const convertToGrid = (view) => {
  const { node, pos } = selectedImage;

  node.attrs.style = node.attrs.style
    .replace(/width:\s?(.*?);/, "width: 100%;")
    .replace(/height:\s?(.*?);/, "height: auto;");
  const imageGridCell = view.state.schema.nodes[IMAGE_GRID_CELL_NODE_NAME].create({ uid: getUUID() }, [node]);
  const imageGrid = view.state.schema.nodes[IMAGE_GRID_NODE_NAME].create({ uid: getUUID() }, [imageGridCell]);

  setSelectionToPos(view, pos);
  const replacementTransaction = view.state.tr.replaceSelectionWith(imageGrid);
  view.dispatch(replacementTransaction);

  deactivateAxis();
  const nodeInfo = findNode(view.state.doc, (child) => child.eq(node));

  if (nodeInfo) {
    const { path, posConvertToGrid } = nodeInfo;
    const target = view.dom.querySelector(`img[uid="${nodeInfo.node.attrs.uid}"]`);
    setSelectedImage(
      {
        node: nodeInfo.node,
        path,
        target,
        posConvertToGrid,
        isOnGrid: true,
        imageGridNode: imageGrid,
      },
      view.dom.getAttribute("uid")
    );

    selectedImageSubject.next({ ...selectedImage });
  }
};

/**
 * @param {EditorView} view - EditorView of the editor
 */
export const convertToSingle = (view) => {
  const { node, pos } = selectedImage;

  node.attrs.style = node.attrs.style
    .replace(/width:\s?(.*?);/, "width: auto;")
    .replace(/height:\s?(.*?);/, "height: auto;");
  const imageContainer = view.state.schema.nodes[IMAGE_CONTAINER_NODE_NAME].create({ uid: getUUID() }, [node]);

  setSelectionToPos(view, pos);
  const replacementTransaction = view.state.tr.replaceSelectionWith(imageContainer);
  view.dispatch(replacementTransaction);

  const nodeInfo = findNode(view.state.doc, (child) => child.eq(node));
  if (nodeInfo) {
    const { path, posConvertToSingle } = nodeInfo;
    const target = view.dom.querySelector(`img[uid="${nodeInfo.node.attrs.uid}"]`);
    const align = getSelectedImageAlign(path);
    setSelectedImage(
      {
        node: nodeInfo.node,
        posConvertToSingle,
        path,
        target,
        align,
        width: target.offsetWidth,
        height: target.offsetHeight,
        isOnGrid: false,
      },
      view.dom.getAttribute("uid")
    );

    activateAxis();
    selectedImageSubject.next({ ...selectedImage });
  }
};

/**
 * @param {EditorView} view - EditorView of the editor
 * @param {number} number - Number of images to add to the grid
 */
export const addImageToGrid = (view, number) => {
  const { node, path, pos, imageGridNode } = selectedImage;
  const nodeSelection = new NodeSelection(view.state.doc.resolve(pos));

  for (let i = 0; i < number; i++) {
    const whiteImageNode = view.state.schema.nodes[IMAGE_NODE_NAME].create({
      uid: getUUID(),
      src: WHITE_PIXEL,
      empty: true,
      onload: ONLOAD_EVENT_HANDLER,
      onerror: ONERROR_EVENT_HANDLER,
    });
    whiteImageNode.attrs.style = whiteImageNode.attrs.style
      .replace(/width:\s?(.*?);/, "width: 100%;")
      .replace(/height:\s?(.*?);/, "height: 100%;");
    const imageGridCell = view.state.schema.nodes[IMAGE_GRID_CELL_NODE_NAME].create({ uid: getUUID() }, [
      whiteImageNode,
    ]);

    const insertTransaction = view.state.tr.insert(nodeSelection.$head.pos, imageGridCell);
    view.dispatch(insertTransaction);
  }

  const nodeInfo = findNode(view.state.doc, (child) => child.attrs.uid === imageGridNode.attrs.uid);

  if (nodeInfo) {
    const target = view.dom.querySelector(`img[uid="${node.attrs.uid}"]`);
    setSelectedImage(
      {
        node,
        path,
        target,
        pos,
        isOnGrid: true,
        imageGridNode: nodeInfo.node,
      },
      view.dom.getAttribute("uid")
    );

    selectedImageSubject.next({ ...selectedImage });
  }
};

/**
 * Object that contains mouse and axi related data. Should contain:
 * - **originX (required)**: X coordinate of the mouse when the axi was clicked
 * - **originY (required)**: Y coordinate of the mouse when the axi was clicked
 */
let mouseAxiData = null;

const activateAxis = () => {
  axiList.forEach((axi) => {
    calculateAxiPosition(axi);
    axi.classList.add("active");
  });
};

const deactivateAxis = () => {
  axiList.forEach((axi) => {
    axi.classList.remove("active");
  });
};

const onImageContainerActive = (node, path, pos, target, view) => {
  const align = getSelectedImageAlign(path);

  setSelectedImage(
    {
      node,
      pos,
      path,
      target,
      align,
      width: target.offsetWidth,
      height: target.offsetHeight,
      isOnGrid: false,
      keepAspectRatio: !!selectedImage?.keepAspectRatio,
    },
    view.dom.getAttribute("uid")
  );

  activateAxis();
  selectedImageSubject.next({ ...selectedImage });
};

const onImageGridCellActive = (node, path, pos, target, view) => {
  setSelectedImage(
    {
      node,
      path,
      target,
      pos,
      isOnGrid: true,
      imageGridNode: path.find((x) => x.node.type.name === IMAGE_GRID_NODE_NAME).node,
    },
    view.dom.getAttribute("uid")
  );
  selectedImageSubject.next({ ...selectedImage });
};

export const ImageHandlingPlugin = new ProseMirror.Plugin({
  props: {
    handleDOMEvents: {
      dragenter: (view, event) => {
        if (
          dragSourceNode &&
          event.target.nodeName === "IMG" &&
          event.target.parentElement.parentElement.nodeName === "IMAGEGRID" &&
          event.target.parentElement.parentElement.attributes["uid"].value === selectedImage.imageGridNode?.attrs.uid
        ) {
          const editorPos = view.posAtDOM(event.target, event.offsetX);
          dragDestinyNode = findNode(
            view.state.doc,
            (child, childPos) => child.type.name === IMAGE_NODE_NAME && editorPos === childPos
          );

          const targetClass = event.target.attributes["class"]?.value ?? "";
          event.target.setAttribute("class", targetClass + " drag-hover");
        }
      },
      dragleave: (_, event) => {
        if (
          dragSourceNode &&
          event.target.nodeName === "IMG" &&
          event.target.parentElement.parentElement.nodeName === "IMAGEGRID" &&
          event.target.parentElement.parentElement.attributes["uid"].value === selectedImage.imageGridNode?.attrs.uid
        ) {
          const targetClass = event.target.attributes["class"]?.value ?? "";
          event.target.setAttribute("class", targetClass.replace(/\s?drag-hover/, ""));
        }
      },
      drag: (view, event) => {
        if (!dragSourceNode) {
          const editorPos = view.posAtDOM(event.target, event.offsetX);
          dragSourceNode = findNode(
            view.state.doc,
            (child, childPos) => child.type.name === IMAGE_NODE_NAME && editorPos === childPos
          );
        }
      },
      dragend: (view) => {
        if (
          dragSourceNode &&
          dragDestinyNode &&
          dragSourceNode.path.some((x) => selectedImage.imageGridNode && x.node.eq(selectedImage.imageGridNode)) &&
          dragDestinyNode.path.some((x) => selectedImage.imageGridNode && x.node.eq(selectedImage.imageGridNode))
        ) {
          const sourcePos = dragSourceNode.pos;
          const destinyPos = dragDestinyNode.pos;

          setSelectionToPos(view, sourcePos);

          const sourceReplaceTransaction = view.state.tr.replaceSelectionWith(dragDestinyNode.node);
          view.dispatch(sourceReplaceTransaction);

          setSelectionToPos(view, destinyPos);

          const destinyReplaceTransaction = view.state.tr.replaceSelectionWith(dragSourceNode.node);
          view.dispatch(destinyReplaceTransaction);

          const sourceUpdatedNodeInfo = findNode(
            view.state.doc,
            (child) => child.type.name === IMAGE_NODE_NAME && child.attrs.uid === dragSourceNode.node.attrs.uid
          );
          if (sourceUpdatedNodeInfo) {
            const { node, path, pos } = sourceUpdatedNodeInfo;
            const target = view.dom.querySelector(`img[uid="${node.attrs.uid}"]`);
            setSelectionToPos(view, pos);
            onImageGridCellActive(node, path, pos, target, view);
          }
        }

        view.dom
          .querySelectorAll("img.drag-hover")
          .forEach((x) => x.setAttribute("class", x.attributes["class"].value.replace(/\s?drag-hover/, "")));

        dragSourceNode = null;
        dragDestinyNode = null;
      },
      mousemove: (_, event) => {
        if (axiList.some((axi) => axi.classList.contains("resize-dragged-axi"))) {
          event.preventDefault();
        }
      },
      mousedown: (view, event) => {
        const editorPos = view.posAtDOM(event.target, event.offsetX);
        const nodeInfo = findNode(view.state.doc, (_, childPos) => editorPos === childPos);

        if (nodeInfo) {
          const { node, path, pos } = nodeInfo;

          let target = event.target;
          if (event.target.nodeName !== "IMG") {
            target = view.dom.querySelector(`img[uid="${node.attrs.uid}"]`);
          }

          if (node.type.name !== "image") {
            setSelectedImage(null);
            selectedImageSubject.next(null);
            deactivateAxis();
          } else if (path.some((x) => x.node.type.name === IMAGE_GRID_CELL_NODE_NAME)) {
            deactivateAxis();
            onImageGridCellActive(node, path, pos, target, view);
          } else if (path.some((x) => x.node.type.name === IMAGE_CONTAINER_NODE_NAME)) {
            setSelectionToPos(view, pos);
            onImageContainerActive(node, path, pos, target, view);
            imageClicked = { pos };
          }
        } else {
          setSelectedImage(null);
          selectedImageSubject.next(null);
          deactivateAxis();
        }
      },
      click: (view) => {
        if (imageClicked) {
          const { pos } = imageClicked;
          setSelectionToPos(view, pos);
          imageClicked = null;
        }
      },
      keyup: (view, event) => {
        if (
          event.key === KEY.DELETE ||
          event.key === KEY.BACKSPACE ||
          event.key === KEY.ENTER ||
          event.key === KEY.HOME ||
          event.key === KEY.END ||
          isArrowKey(event.key)
        ) {
          const nodeInfo = findNode(
            view.state.doc,
            (child, childPos) => child.type.name === IMAGE_NODE_NAME && childPos === view.state.selection.$from.pos
          );
          if (nodeInfo) {
            const { node, path, pos } = nodeInfo;
            const target = view.dom.querySelector(`img[uid="${node.attrs.uid}"]`);
            if (!target) {
              return;
            } else if (path.some((x) => x.node.type.name === IMAGE_GRID_CELL_NODE_NAME)) {
              onImageGridCellActive(node, path, pos, target, view);
            } else if (path.some((x) => x.node.type.name === IMAGE_CONTAINER_NODE_NAME)) {
              onImageContainerActive(node, path, pos, target, view);
            }
          } else {
            setSelectedImage(null);
            selectedImageSubject.next(null);
            deactivateAxis();
          }
        }
      },
    },
  },
});

const AXI_SIZE = 16;
const AXI_HALF_SIZE = AXI_SIZE / 2;
const AXI_OFFSET = AXI_SIZE + AXI_HALF_SIZE;

const calculateAxiPosition = (axi) => {
  const { target } = selectedImage;
  const { min, abs, floor } = Math;
  const x = axi.attributes["x"];
  const y = axi.attributes["y"];
  const halfWidth = floor(target.offsetWidth / 2);
  const halfHeight = floor(target.offsetHeight / 2);
  axi.style.left =
    target.offsetLeft +
    halfWidth +
    (halfWidth + AXI_HALF_SIZE) * x +
    min(x, 0) * AXI_OFFSET -
    abs(abs(x) - 1) * AXI_HALF_SIZE +
    "px";
  axi.style.top =
    target.offsetTop +
    halfHeight +
    (halfHeight + AXI_HALF_SIZE) * y +
    min(y, 0) * AXI_OFFSET -
    abs(abs(y) - 1) * AXI_HALF_SIZE +
    "px";
};

const onAxiClick = (event) => {
  const axi = event.target;

  axi.classList.add("resize-dragged-axi");
  mouseAxiData = {
    originX: event.clientX,
    originY: event.clientY,
  };

  updateSelectedImageDimension();
};

export const createAxis = (iframeDocument, view) => {
  const northAxi = iframeDocument.createElement("div");
  northAxi.classList.add("resize-north-axi");
  northAxi.classList.add("axi");
  northAxi.attributes["x"] = 0;
  northAxi.attributes["y"] = -1;

  northAxi.addEventListener("mousedown", onAxiClick);

  const eastAxi = iframeDocument.createElement("div");
  eastAxi.classList.add("resize-east-axi");
  eastAxi.classList.add("axi");
  eastAxi.attributes["x"] = 1;
  eastAxi.attributes["y"] = 0;

  eastAxi.addEventListener("mousedown", onAxiClick);

  const southAxi = iframeDocument.createElement("div");
  southAxi.classList.add("resize-south-axi");
  southAxi.classList.add("axi");
  southAxi.attributes["x"] = 0;
  southAxi.attributes["y"] = 1;

  southAxi.addEventListener("mousedown", onAxiClick);

  const westAxi = iframeDocument.createElement("div");
  westAxi.classList.add("resize-west-axi");
  westAxi.classList.add("axi");
  westAxi.attributes["x"] = -1;
  westAxi.attributes["y"] = 0;

  westAxi.addEventListener("mousedown", onAxiClick);

  iframeDocument.addEventListener("mousedown", (event) => {
    if (imageClicked) {
      event.preventDefault();
    }
  });

  iframeDocument.addEventListener("mousemove", (event) => {
    const resizeDragged = axiList.find((axi) => axi.classList.contains("resize-dragged-axi"));
    if (resizeDragged) {
      const { target, width, height, keepAspectRatio } = selectedImage;
      const { originX, originY } = mouseAxiData;
      const x = resizeDragged.attributes["x"];
      const y = resizeDragged.attributes["y"];

      target.style.width = !Math.abs(x) && keepAspectRatio ? "auto" : width + (event.clientX - originX) * x + "px";
      target.style.height = !Math.abs(y) && keepAspectRatio ? "auto" : height + (event.clientY - originY) * y + "px";
      axiList.forEach((axi) => calculateAxiPosition(axi));
    }
  });

  iframeDocument.addEventListener("mouseup", () => {
    const resizeDragged = axiList.find((x) => x.classList.contains("resize-dragged-axi"));
    if (resizeDragged) {
      resizeDragged.classList.remove("resize-dragged-axi");

      if (selectedImage) {
        const { target, node, pos } = selectedImage;
        const style = node.attrs.style
          .replace(/width:\s?(.*?);/, `width: ${target.offsetWidth}px;`)
          .replace(/height:\s?(.*?);/, `height: ${target.offsetHeight}px;`);
        const transaction = view.state.tr.setNodeAttribute(pos, "style", style);

        const [imageContainerModified] = getModifiedNodes(transaction.doc, view.state.doc);
        view.dispatch(transaction);

        setSelectionToPos(view, pos);

        updateSelectedImage(node, imageContainerModified?.content?.content[0]);
        updateSelectedImageTarget(iframeDocument);
        selectedImageSubject.next({ ...selectedImage });
      }
    }
  });

  axiList.push(northAxi);
  axiList.push(eastAxi);
  axiList.push(southAxi);
  axiList.push(westAxi);

  iframeDocument.body.appendChild(northAxi);
  iframeDocument.body.appendChild(eastAxi);
  iframeDocument.body.appendChild(southAxi);
  iframeDocument.body.appendChild(westAxi);
};

export const getAllAttributes = (dom, filter) => {
  if (!filter) {
    return [...(dom?.attributes || [])]
      .map((attribute) => ({ [attribute.name]: attribute.value }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});
  }

  return [...(dom?.attributes || [])]
    .filter(filter)
    .map((attribute) => ({ [attribute.name]: attribute.value }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});
};

export const getDOMUID = (dom) => dom.getAttribute("uid") ?? getUUID();

export const imageGridCellNodeSpec = {
  attrs: { style: { default: "flex: 1 1; width: 0;" }, class: { default: null }, uid: { default: null } },
  group: IMAGE_GRID_CELL_NODE_NAME,
  content: "image",
  block: true,
  inline: false,
  selectable: false,
  toDOM: (node) => [
    IMAGE_GRID_CELL_NODE_NAME,
    {
      ...node.attrs,
    },
    0,
  ],
  parseDOM: [
    {
      tag: IMAGE_GRID_CELL_NODE_NAME,
      getAttrs: (dom) => ({
        ...getAllAttributes(dom),
        uid: getDOMUID(dom),
      }),
    },
  ],
};

export const imageGridNodeSpec = {
  attrs: {
    style: { default: "display: flex; width: 100%; justify-content: flex-start;" },
    class: { default: null },
    uid: { default: null },
    selected: { default: false },
  },
  group: "block",
  content: `imagegridcell+`,
  block: true,
  selectable: false,
  toDOM: (node) => [
    IMAGE_GRID_NODE_NAME,
    {
      ...node.attrs,
    },
    0,
  ],
  parseDOM: [
    {
      tag: IMAGE_GRID_NODE_NAME,
      getAttrs: (dom) => ({
        ...getAllAttributes(dom),
        uid: getDOMUID(dom),
      }),
    },
  ],
};

export const imageContainerNodeSpec = {
  attrs: {
    style: { default: "display:block; width: 100%; text-align: left;" },
    class: { default: null },
    uid: { default: null },
  },
  group: "block",
  content: `image`,
  block: true,
  selectable: false,
  toDOM: (node) => [
    IMAGE_CONTAINER_NODE_NAME,
    {
      ...node.attrs,
    },
    0,
  ],
  parseDOM: [
    {
      tag: IMAGE_CONTAINER_NODE_NAME,
      getAttrs: (dom) => ({
        ...getAllAttributes(dom),
        uid: getDOMUID(dom),
      }),
    },
  ],
};
