import * as React from "react";
import { rowClassName } from "./NewRatingSystemESG.util";
export class Renderers {
  constructor(enterEdit, exitEdit, enterContextMenu, editFieldName) {
    this.enterEdit = enterEdit;
    this.exitEdit = exitEdit;
    this.enterContextMenu = enterContextMenu;
    this.editFieldName = editFieldName;
  }

  cellRender = (tdElement, cellProps) => {
    const dataItem = cellProps.dataItem;
    const field = cellProps.field;
    const editedField = cellProps.dataItem[this.editFieldName];
    const additionalProps =
      editedField && cellProps.field === editedField
        ? {
            ref: (td) => {
              const input = td?.querySelector("input, .k-dropdownlist");
              if (!input || input === document.activeElement) {
                return;
              }
              if (input.type === "checkbox") {
                input.focus();
              } else if (input.tagName === "SPAN") {
                input.focus();
              } else {
                input.select();
              }
            },
            onBlur: () => this.exitEdit.call(null),
          }
        : {
            onClick: (event) => {
              if (dataItem?.isFooter) return;

              if (tdElement.props.onClick) tdElement.props.onClick(event);
              const targetClasses = event.target.classList;
              if (targetClasses?.contains("k-i-expand") || targetClasses?.contains("k-i-collapse")) return;
              this.enterEdit.call(null, dataItem, field);
            },
            onContextMenu: (event) => {
              if (dataItem?.isFooter) return;
              this.enterContextMenu.call(null, { dataItem, event });
            },
          };

    return React.cloneElement(
      tdElement,
      {
        ...tdElement.props,
        ...additionalProps,
        className: `${tdElement?.props?.className ?? ""} ${
          (dataItem?.excludedFromCalculation || dataItem?.excluded) && field !== "excluded" ? " excluded-cell" : ""
        }`,
      },
      tdElement.props.children
    );
  };
  rowRender = (trElement, { dataItem }) => {
    const trProps = {
      ...trElement.props,
      className: `${trElement?.props?.className ?? ""} ${rowClassName(dataItem)}`,
      onMouseDown: () => {
        this.preventExit = true;
        clearTimeout(this.preventExitTimeout);
        this.preventExitTimeout = setTimeout(() => {
          this.preventExit = null;
        });
      },
      onBlur: () => {
        clearTimeout(this.blurTimeout);
        if (!this.preventExit) {
          this.blurTimeout = setTimeout(() => {
            this.exitEdit.call(null);
          });
        }
      },
      onFocus: () => {
        clearTimeout(this.blurTimeout);
      },
    };
    return React.cloneElement(
      trElement,
      {
        ...trProps,
      },
      trElement.props.children
    );
  };
}
