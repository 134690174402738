import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { processMessage } from "../../utils";

export const SimpleSelectController = ({
  control,
  name,
  label,
  disabled,
  options,
  required,
  renderValue,
  allowClear,
}) => {
  const { t } = useTranslation();

  const optionNames = {};
  (options || []).forEach((item) => {
    optionNames[item.name] = item.text;
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: !!required }}
      render={({ field, formState, fieldState }) => {
        return (
          <FormControl fullWidth error={fieldState.invalid} className="simple-select-controller">
            <InputLabel htmlFor={"simpleSelectControl_" + name}>{label + (required ? " *" : "")}</InputLabel>
            <Select
              {...field}
              value={field.value || ""}
              fullWidth
              label={label + (required ? " *" : "")}
              disabled={disabled}
              id={"simpleSelectControl_" + name}
              displayEmpty
              renderValue={(value) => {
                if (renderValue) {
                  return renderValue(value, optionNames, options);
                }
                return optionNames[value];
              }}
            >
              {!!allowClear && <MenuItem value={""}>{t("main.unselected")}</MenuItem>}
              {(options || []).map((item, index) => (
                <MenuItem key={index} value={item.name || ""}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
            {formState.isSubmitted && fieldState.invalid && (
              <FormHelperText error>{processMessage(t("validation.fieldRequired"), [label])}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
