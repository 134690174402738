/* eslint-disable */
/*
  Swagger SuPer Tool generated
  Drees & Sommer SuPer Tool server.
  version: 1.0.0

*/

export function Configuration(config) {
  this.basePath = '';
  this.fetchMethod = window.fetch;
  this.headers = {};
  this.getHeaders = () => { return {} };
  this.responseHandler = null;
  this.errorHandler = null;

  if (config) {
    if (config.basePath) {
      this.basePath = config.basePath;
    }
    if (config.fetchMethod) {
      this.fetchMethod = config.fetchMethod;
    }
    if (config.headers) {
      this.headers = config.headers;
    }
    if (config.getHeaders) {
      this.getHeaders = config.getHeaders;
    }
    if (config.responseHandler) {
      this.responseHandler = config.responseHandler;
    }
    if (config.errorHandler) {
      this.errorHandler = config.errorHandler;
    }
  }
}

const setAdditionalParams = (params = [], additionalParams = {}) => {
  Object.keys(additionalParams).forEach(key => {
    if(additionalParams[key]) {
      params.append(key, additionalParams[key]);
    }
  });
};

export function ActionEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiActionFindGet = (args, options = {}) => { 
    const { name, projectId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/action/find';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (name !== undefined) {
      params.append('name', name);
    }
    if (projectId !== undefined) {
      params.append('projectId', projectId);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiActionFindActionGet = (args, options = {}) => { 
    const { actionId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/action/findAction';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (actionId !== undefined) {
      params.append('actionId', actionId);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiActionSuggestionGet = (args, options = {}) => { 
    const { kpiRatingSystemId, ratingSystemId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/action/suggestion';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (kpiRatingSystemId !== undefined) {
      params.append('kpiRatingSystemId', kpiRatingSystemId);
    }
    if (ratingSystemId !== undefined) {
      params.append('ratingSystemId', ratingSystemId);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function ActionTemplateEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiActionTemplatePost = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/action/template';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiActionTemplatePut = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/action/template';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiActionTemplateListGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/action/template/list';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiActionTemplateIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/action/template/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiActionTemplateIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/action/template/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function ApplicationVersionEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiApplicationVersionCurrentGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/application/version/current';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiApplicationVersionListGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/application/version/list';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiApplicationVersionPageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/application/version/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiApplicationVersionIdRevisionsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/application/version/{id}/revisions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function BenchmarkEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiBenchmarkPost = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/benchmark';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkPut = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/benchmark';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkFileTemporaryPost = (args, options = {}) => { 
    const { file } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/benchmark/file/temporary';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkFileTemporaryDelete = (args, options = {}) => { 
    const { creationDateTime, id, name } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/benchmark/file/temporary';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (creationDateTime !== undefined) {
      params.append('creationDateTime', creationDateTime);
    }
    if (id !== undefined) {
      params.append('id', id);
    }
    if (name !== undefined) {
      params.append('name', name);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkFileFileIdGet = (args, options = {}) => { 
    const { fileId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/benchmark/file/{fileId}';
    url = url.split(['{', '}'].join('fileId')).join(encodeURIComponent(String(fileId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkListGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/benchmark/list';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkPageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/benchmark/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkStatusCountGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/benchmark/status/count';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/benchmark/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/benchmark/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkIdClonePost = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/benchmark/{id}/clone';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkIdMigrationExecuteTenantIdPost = (args, options = {}) => { 
    const { id, tenantId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/benchmark/{id}/migration/execute/{tenantId}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkIdRevisionRevisionIdGet = (args, options = {}) => { 
    const { id, revisionId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/benchmark/{id}/revision/{revisionId}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('revisionId')).join(encodeURIComponent(String(revisionId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiBenchmarkIdRevisionsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/benchmark/{id}/revisions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function KpiSystemEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiKpiSystemPost = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/kpi/system';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiSystemPut = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/kpi/system';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiSystemFileTemporaryPost = (args, options = {}) => { 
    const { file } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/kpi/system/file/temporary';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiSystemListGet = (args, options = {}) => { 
    const { status } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/kpi/system/list';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (status !== undefined) {
      params.append('status', status);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiSystemPageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/kpi/system/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiSystemIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/kpi/system/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiSystemIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/kpi/system/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiSystemIdClonePost = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/kpi/system/{id}/clone';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiSystemIdFileFileIdDownloadGet = (args, options = {}) => { 
    const { fileId, id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/kpi/system/{id}/file/{fileId}/download';
    url = url.split(['{', '}'].join('fileId')).join(encodeURIComponent(String(fileId)));
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiSystemIdMigrationExecuteTenantIdPost = (args, options = {}) => { 
    const { id, tenantId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/kpi/system/{id}/migration/execute/{tenantId}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiSystemIdRevisionRevisionIdGet = (args, options = {}) => { 
    const { id, revisionId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/kpi/system/{id}/revision/{revisionId}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('revisionId')).join(encodeURIComponent(String(revisionId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiSystemIdRevisionsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/kpi/system/{id}/revisions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function KpiTemplateEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiKpiTemplatePost = (args, options = {}) => { 
    const { item } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/kpi/template';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof item ? JSON.stringify(item) : item
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiTemplatePut = (args, options = {}) => { 
    const { item } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/kpi/template';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof item ? JSON.stringify(item) : item
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiTemplateGeneralAttributesGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/kpi/template/general-attributes';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiTemplateListGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/kpi/template/list';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiTemplatePageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/kpi/template/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiTemplateIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/kpi/template/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiKpiTemplateIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/kpi/template/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function ProjectEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiProjectPost = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectPut = (args, options = {}) => { 
    const { requestDTO } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof requestDTO ? JSON.stringify(requestDTO) : requestDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectCopyGenerateTimelineTenantTenantIdProjectProjectIdPost = (args, options = {}) => { 
    const { projectId, tenantId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/copy/generateTimeline/tenant/{tenantId}/project/{projectId}';
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectCopyProjectIdFromTenantSourceTenantIdToTargetTenantIdPost = (args, options = {}) => { 
    const { projectId, skipKPI, skipRS, sourceTenantId, targetTenantId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/copy/{projectId}/fromTenant/{sourceTenantId}/to/{targetTenantId}';
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    url = url.split(['{', '}'].join('sourceTenantId')).join(encodeURIComponent(String(sourceTenantId)));
    url = url.split(['{', '}'].join('targetTenantId')).join(encodeURIComponent(String(targetTenantId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (skipKPI !== undefined) {
      params.append('skipKPI', skipKPI);
    }
    if (skipRS !== undefined) {
      params.append('skipRS', skipRS);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectCriteriumReportRatingSystemIdGet = (args, options = {}) => { 
    const { elementUuid, language, ratingSystemId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/criterium-report/{ratingSystemId}';
    url = url.split(['{', '}'].join('ratingSystemId')).join(encodeURIComponent(String(ratingSystemId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (elementUuid !== undefined) {
      params.append('elementUuid', elementUuid);
    }
    if (language !== undefined) {
      params.append('language', language);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectFieldsProjectTypeGet = (args, options = {}) => { 
    const { projectType } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/fields/{projectType}';
    url = url.split(['{', '}'].join('projectType')).join(encodeURIComponent(String(projectType)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectFilterFieldsGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/filter/fields';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectMapGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/map';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectPageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectProjectsUserIsMemberGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/projectsUserIsMember';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectRatingStatusCountGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/rating/status/count';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectSinceLastVisitCountGet = (args, options = {}) => { 
    const { date } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/sinceLastVisitCount';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (date !== undefined) {
      params.append('date', date);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectSustainabilityKpiSystemsInProjectsGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/sustainabilityKpiSystemsInProjects';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectUsersAvailableGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/users/available';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdDashboardGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/dashboard';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdFieldsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/fields';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdFileTemporaryPost = (args, options = {}) => { 
    const { file, id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/file/temporary';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdFilesGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/files';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdImageTemporaryPost = (args, options = {}) => { 
    const { file, id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/image/temporary';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdKpiKpiSystemIdsPut = (args, options = {}) => { 
    const { id, kpiSystemIds } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/kpi/{kpiSystemIds}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('kpiSystemIds')).join(encodeURIComponent(String(kpiSystemIds)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdKpiProjectKpiIdLinkActivateDataSourcePut = (args, options = {}) => { 
    const { dataSource, id, projectKpiId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/kpi/{projectKpiId}/link/activate/{dataSource}';
    url = url.split(['{', '}'].join('dataSource')).join(encodeURIComponent(String(dataSource)));
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('projectKpiId')).join(encodeURIComponent(String(projectKpiId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdKpiProjectKpiIdLinkDeactivatePut = (args, options = {}) => { 
    const { id, projectKpiId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/kpi/{projectKpiId}/link/deactivate';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('projectKpiId')).join(encodeURIComponent(String(projectKpiId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdPermissionsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/permissions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdPermissionsPut = (args, options = {}) => { 
    const { id, request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/permissions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdPermissionsUserGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/permissions/user';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdPicturePost = (args, options = {}) => { 
    const { file, id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/picture';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdProtectPut = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/protect';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdRatingSustainabilitySystemIdsPut = (args, options = {}) => { 
    const { id, sustainabilitySystemIds } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/rating/{sustainabilitySystemIds}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('sustainabilitySystemIds')).join(encodeURIComponent(String(sustainabilitySystemIds)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectIdUnprotectPut = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{id}/unprotect';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectProjectIdFileTemporaryDelete = (args, options = {}) => { 
    const { creationDateTime, editable, generatedTags, id, name, projectId, userTags } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{projectId}/file/temporary';
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (creationDateTime !== undefined) {
      params.append('creationDateTime', creationDateTime);
    }
    if (editable !== undefined) {
      params.append('editable', editable);
    }
    if (generatedTags !== undefined) {
      params.append('generatedTags', generatedTags);
    }
    if (id !== undefined) {
      params.append('id', id);
    }
    if (name !== undefined) {
      params.append('name', name);
    }
    if (userTags !== undefined) {
      params.append('userTags', userTags);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectProjectIdFileIdGet = (args, options = {}) => { 
    const { id, projectId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{projectId}/file/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectProjectIdImageTemporaryDelete = (args, options = {}) => { 
    const { creationDateTime, editable, generatedTags, id, name, projectId, userTags } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{projectId}/image/temporary';
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (creationDateTime !== undefined) {
      params.append('creationDateTime', creationDateTime);
    }
    if (editable !== undefined) {
      params.append('editable', editable);
    }
    if (generatedTags !== undefined) {
      params.append('generatedTags', generatedTags);
    }
    if (id !== undefined) {
      params.append('id', id);
    }
    if (name !== undefined) {
      params.append('name', name);
    }
    if (userTags !== undefined) {
      params.append('userTags', userTags);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectProjectIdKpiKpiRatingSystemIdDelete = (args, options = {}) => { 
    const { kpiRatingSystemId, projectId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{projectId}/kpi/{kpiRatingSystemId}';
    url = url.split(['{', '}'].join('kpiRatingSystemId')).join(encodeURIComponent(String(kpiRatingSystemId)));
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectProjectIdPictureIdGet = (args, options = {}) => { 
    const { id, projectId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{projectId}/picture/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectProjectIdPictureIdDelete = (args, options = {}) => { 
    const { id, projectId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{projectId}/picture/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectProjectIdRatingRatingSystemIdDelete = (args, options = {}) => { 
    const { projectId, ratingSystemId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{projectId}/rating/{ratingSystemId}';
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    url = url.split(['{', '}'].join('ratingSystemId')).join(encodeURIComponent(String(ratingSystemId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectProjectIdReportRatingSystemIdGet = (args, options = {}) => { 
    const { language, projectId, ratingSystemId, reportFileType, reportType } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/{projectId}/report/{ratingSystemId}';
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    url = url.split(['{', '}'].join('ratingSystemId')).join(encodeURIComponent(String(ratingSystemId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (language !== undefined) {
      params.append('language', language);
    }
    if (reportFileType !== undefined) {
      params.append('reportFileType', reportFileType);
    }
    if (reportType !== undefined) {
      params.append('reportType', reportType);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function ProjectGroupEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiProjectGroupPost = (args, options = {}) => { 
    const { item } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/group';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof item ? JSON.stringify(item) : item
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupPut = (args, options = {}) => { 
    const { item } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/group';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof item ? JSON.stringify(item) : item
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupChartStructureGet = (args, options = {}) => { 
    const { projectId, systemHash } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/group/chart/structure';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (projectId !== undefined) {
      params.append('projectId', projectId);
    }
    if (systemHash !== undefined) {
      params.append('systemHash', systemHash);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupListGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/group/list';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupPageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/project/group/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupPathsProjectIdGet = (args, options = {}) => { 
    const { projectId, selectedGroupId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/paths/{projectId}';
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (selectedGroupId !== undefined) {
      params.append('selectedGroupId', selectedGroupId);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupGroupIdAddProjectIdPut = (args, options = {}) => { 
    const { groupId, projectId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{groupId}/add/{projectId}';
    url = url.split(['{', '}'].join('groupId')).join(encodeURIComponent(String(groupId)));
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupGroupIdChartBenchmarkBenchmarkIdGet = (args, options = {}) => { 
    const { benchmarkId, groupId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{groupId}/chart/benchmark/{benchmarkId}';
    url = url.split(['{', '}'].join('benchmarkId')).join(encodeURIComponent(String(benchmarkId)));
    url = url.split(['{', '}'].join('groupId')).join(encodeURIComponent(String(groupId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupGroupIdChartStructureProjectsGet = (args, options = {}) => { 
    const { groupId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{groupId}/chart/structure/projects';
    url = url.split(['{', '}'].join('groupId')).join(encodeURIComponent(String(groupId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupGroupIdRemoveProjectIdPut = (args, options = {}) => { 
    const { groupId, projectId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{groupId}/remove/{projectId}';
    url = url.split(['{', '}'].join('groupId')).join(encodeURIComponent(String(groupId)));
    url = url.split(['{', '}'].join('projectId')).join(encodeURIComponent(String(projectId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupGroupIdStatisticChartKpiDetailsGet = (args, options = {}) => { 
    const { groupId, kpiName, kpiUnit } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{groupId}/statistic/chart/kpi/details';
    url = url.split(['{', '}'].join('groupId')).join(encodeURIComponent(String(groupId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (kpiName !== undefined) {
      params.append('kpiName', kpiName);
    }
    if (kpiUnit !== undefined) {
      params.append('kpiUnit', kpiUnit);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupIdRevisionsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{id}/revisions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupParentGroupIdStatisticActionGet = (args, options = {}) => { 
    const { parentGroupId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{parentGroupId}/statistic/action';
    url = url.split(['{', '}'].join('parentGroupId')).join(encodeURIComponent(String(parentGroupId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupParentGroupIdStatisticChartKpiPairGet = (args, options = {}) => { 
    const { counterKpiName, counterKpiUnit, kpiName, kpiUnit, parentGroupId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{parentGroupId}/statistic/chart/kpi/pair';
    url = url.split(['{', '}'].join('parentGroupId')).join(encodeURIComponent(String(parentGroupId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (counterKpiName !== undefined) {
      params.append('counterKpiName', counterKpiName);
    }
    if (counterKpiUnit !== undefined) {
      params.append('counterKpiUnit', counterKpiUnit);
    }
    if (kpiName !== undefined) {
      params.append('kpiName', kpiName);
    }
    if (kpiUnit !== undefined) {
      params.append('kpiUnit', kpiUnit);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupParentGroupIdStatisticChartKpiProjectsGet = (args, options = {}) => { 
    const { kpiName, kpiUnit, parentGroupId, scoreType } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{parentGroupId}/statistic/chart/kpi/projects';
    url = url.split(['{', '}'].join('parentGroupId')).join(encodeURIComponent(String(parentGroupId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (kpiName !== undefined) {
      params.append('kpiName', kpiName);
    }
    if (kpiUnit !== undefined) {
      params.append('kpiUnit', kpiUnit);
    }
    if (scoreType !== undefined) {
      params.append('scoreType', scoreType);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupParentGroupIdStatisticKpiGet = (args, options = {}) => { 
    const { parentGroupId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{parentGroupId}/statistic/kpi';
    url = url.split(['{', '}'].join('parentGroupId')).join(encodeURIComponent(String(parentGroupId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupParentGroupIdSystemsCountGet = (args, options = {}) => { 
    const { parentGroupId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{parentGroupId}/systems/count';
    url = url.split(['{', '}'].join('parentGroupId')).join(encodeURIComponent(String(parentGroupId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupParentIdAddPost = (args, options = {}) => { 
    const { item, parentId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{parentId}/add';
    url = url.split(['{', '}'].join('parentId')).join(encodeURIComponent(String(parentId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof item ? JSON.stringify(item) : item
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupParentIdAttachChildIdPut = (args, options = {}) => { 
    const { childId, parentId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{parentId}/attach/{childId}';
    url = url.split(['{', '}'].join('childId')).join(encodeURIComponent(String(childId)));
    url = url.split(['{', '}'].join('parentId')).join(encodeURIComponent(String(parentId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupParentIdChildrenGet = (args, options = {}) => { 
    const { parentId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{parentId}/children';
    url = url.split(['{', '}'].join('parentId')).join(encodeURIComponent(String(parentId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupParentIdDetachChildIdPut = (args, options = {}) => { 
    const { childId, parentId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{parentId}/detach/{childId}';
    url = url.split(['{', '}'].join('childId')).join(encodeURIComponent(String(childId)));
    url = url.split(['{', '}'].join('parentId')).join(encodeURIComponent(String(parentId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiProjectGroupProjectGroupIdPermissionsPut = (args, options = {}) => { 
    const { projectGroupId, projectGroupPermissions } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/project/group/{projectGroupId}/permissions';
    url = url.split(['{', '}'].join('projectGroupId')).join(encodeURIComponent(String(projectGroupId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof projectGroupPermissions ? JSON.stringify(projectGroupPermissions) : projectGroupPermissions
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function KpiRatingSystemEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiRatingKpiEvaluatePut = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/rating/kpi/evaluate';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingKpiIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/kpi/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingKpiIdAcceptChangesPost = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/kpi/{id}/acceptChanges';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingKpiIdChartKpiGet = (args, options = {}) => { 
    const { id, revision } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/kpi/{id}/chart/kpi';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (revision !== undefined) {
      params.append('revision', revision);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingKpiIdDeclineChangesPost = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/kpi/{id}/declineChanges';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingKpiIdRevisionRevisionIdGet = (args, options = {}) => { 
    const { id, revisionId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/kpi/{id}/revision/{revisionId}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('revisionId')).join(encodeURIComponent(String(revisionId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingKpiIdRevisionsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/kpi/{id}/revisions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingKpiIdTimelineGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/kpi/{id}/timeline';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingKpiRatingSystemIdChartBenchmarkBenchmarkIdGet = (args, options = {}) => { 
    const { benchmarkId, ratingSystemId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/kpi/{ratingSystemId}/chart/benchmark/{benchmarkId}';
    url = url.split(['{', '}'].join('benchmarkId')).join(encodeURIComponent(String(benchmarkId)));
    url = url.split(['{', '}'].join('ratingSystemId')).join(encodeURIComponent(String(ratingSystemId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function RatingSystemEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiRatingSystemEvaluatePut = (args, options = {}) => { 
    const { evaluationDTO } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/rating/system/evaluate';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof evaluationDTO ? JSON.stringify(evaluationDTO) : evaluationDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemPageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/rating/system/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemRichtextImagePost = (args, options = {}) => { 
    const { file } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/rating/system/richtext/image';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemSuperScoreCountGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/rating/system/super/score/count';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemIdAcceptChangesPost = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{id}/acceptChanges';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemIdChartKpiGet = (args, options = {}) => { 
    const { id, revision } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{id}/chart/kpi';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (revision !== undefined) {
      params.append('revision', revision);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemIdDeclineChangesPost = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{id}/declineChanges';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemIdFileFileIdPreviewGet = (args, options = {}) => { 
    const { fileId, id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{id}/file/{fileId}/preview';
    url = url.split(['{', '}'].join('fileId')).join(encodeURIComponent(String(fileId)));
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemIdFilesDownloadGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{id}/files/download';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemIdRevisionRevisionIdGet = (args, options = {}) => { 
    const { id, revisionId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{id}/revision/{revisionId}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('revisionId')).join(encodeURIComponent(String(revisionId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemIdRevisionsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{id}/revisions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemIdRichtextImagePost = (args, options = {}) => { 
    const { file, id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{id}/richtext/image';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemIdRichtextImageImageIdGet = (args, options = {}) => { 
    const { height, id, imageId, width } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{id}/richtext/image/{imageId}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('imageId')).join(encodeURIComponent(String(imageId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (height !== undefined) {
      params.append('height', height);
    }
    if (width !== undefined) {
      params.append('width', width);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemIdTimelineGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{id}/timeline';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemRatingSystemIdChartBenchmarkBenchmarkIdGet = (args, options = {}) => { 
    const { benchmarkId, ratingSystemId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{ratingSystemId}/chart/benchmark/{benchmarkId}';
    url = url.split(['{', '}'].join('benchmarkId')).join(encodeURIComponent(String(benchmarkId)));
    url = url.split(['{', '}'].join('ratingSystemId')).join(encodeURIComponent(String(ratingSystemId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiRatingSystemRatingSystemIdUpdateActionsPut = (args, options = {}) => { 
    const { actions, ratingSystemId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/rating/system/{ratingSystemId}/updateActions';
    url = url.split(['{', '}'].join('ratingSystemId')).join(encodeURIComponent(String(ratingSystemId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof actions ? JSON.stringify(actions) : actions
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function ProjectFieldsEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiSecurityProjectFieldsGeneralGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/project/fields/general';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityProjectFieldsGeneralPost = (args, options = {}) => { 
    const { fieldDTO } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/project/fields/general';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof fieldDTO ? JSON.stringify(fieldDTO) : fieldDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityProjectFieldsGeneralPut = (args, options = {}) => { 
    const { fieldDTO } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/project/fields/general';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof fieldDTO ? JSON.stringify(fieldDTO) : fieldDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityProjectFieldsGeneralIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/project/fields/general/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityProjectFieldsGeneralIdClearPut = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/project/fields/general/{id}/clear';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityProjectFieldsProjectTypeGet = (args, options = {}) => { 
    const { projectType } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/project/fields/{projectType}';
    url = url.split(['{', '}'].join('projectType')).join(encodeURIComponent(String(projectType)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityProjectFieldsProjectTypePut = (args, options = {}) => { 
    const { descriptorDTO, projectType } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/project/fields/{projectType}';
    url = url.split(['{', '}'].join('projectType')).join(encodeURIComponent(String(projectType)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof descriptorDTO ? JSON.stringify(descriptorDTO) : descriptorDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function RoleEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiSecurityRolePut = (args, options = {}) => { 
    const { roleDTO } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/role';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof roleDTO ? JSON.stringify(roleDTO) : roleDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityRolePermissionsGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/role/permissions';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityRoleTenantTenantIdGet = (args, options = {}) => { 
    const { tenantId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/role/tenant/{tenantId}';
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityRoleTenantTenantIdPost = (args, options = {}) => { 
    const { roleDTO, tenantId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/role/tenant/{tenantId}';
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof roleDTO ? JSON.stringify(roleDTO) : roleDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityRoleIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/role/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityRoleIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/role/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function RoleTemplateEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiSecurityRoleTemplatePost = (args, options = {}) => { 
    const { item } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/role/template';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof item ? JSON.stringify(item) : item
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityRoleTemplatePut = (args, options = {}) => { 
    const { item } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/role/template';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof item ? JSON.stringify(item) : item
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityRoleTemplateListGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/role/template/list';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityRoleTemplatePageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/role/template/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityRoleTemplateIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/role/template/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityRoleTemplateIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/role/template/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityRoleTemplateIdRevisionsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/role/template/{id}/revisions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function TenantEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiSecurityTenantPost = (args, options = {}) => { 
    const { tenantDTO } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/tenant';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof tenantDTO ? JSON.stringify(tenantDTO) : tenantDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityTenantPut = (args, options = {}) => { 
    const { tenantDTO } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/tenant';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof tenantDTO ? JSON.stringify(tenantDTO) : tenantDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityTenantListGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/tenant/list';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityTenantPageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/tenant/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityTenantIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/tenant/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityTenantIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/tenant/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityTenantIdRevisionsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/tenant/{id}/revisions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityTenantTenantIdRolePost = (args, options = {}) => { 
    const { roleDTO, tenantId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/tenant/{tenantId}/role';
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof roleDTO ? JSON.stringify(roleDTO) : roleDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityTenantTenantIdRolesGet = (args, options = {}) => { 
    const { tenantId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/tenant/{tenantId}/roles';
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityTenantTenantIdRolesUserUserIdPut = (args, options = {}) => { 
    const { roleIds, tenantId, userId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/tenant/{tenantId}/roles/user/{userId}';
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    url = url.split(['{', '}'].join('userId')).join(encodeURIComponent(String(userId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof roleIds ? JSON.stringify(roleIds) : roleIds
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function UserEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiSecurityUserPost = (args, options = {}) => { 
    const { city, company, country, email, encodedPicture, firstName, id, language, lastName, postalCode, status, streetAddress, superAdmin, title, userIsRegistered, uuid } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/user';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (city !== undefined) {
      params.append('city', city);
    }
    if (company !== undefined) {
      params.append('company', company);
    }
    if (country !== undefined) {
      params.append('country', country);
    }
    if (email !== undefined) {
      params.append('email', email);
    }
    if (encodedPicture !== undefined) {
      params.append('encodedPicture', encodedPicture);
    }
    if (firstName !== undefined) {
      params.append('firstName', firstName);
    }
    if (id !== undefined) {
      params.append('id', id);
    }
    if (language !== undefined) {
      params.append('language', language);
    }
    if (lastName !== undefined) {
      params.append('lastName', lastName);
    }
    if (postalCode !== undefined) {
      params.append('postalCode', postalCode);
    }
    if (status !== undefined) {
      params.append('status', status);
    }
    if (streetAddress !== undefined) {
      params.append('streetAddress', streetAddress);
    }
    if (superAdmin !== undefined) {
      params.append('superAdmin', superAdmin);
    }
    if (title !== undefined) {
      params.append('title', title);
    }
    if (userIsRegistered !== undefined) {
      params.append('userIsRegistered', userIsRegistered);
    }
    if (uuid !== undefined) {
      params.append('uuid', uuid);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserPut = (args, options = {}) => { 
    const { item } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/user';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof item ? JSON.stringify(item) : item
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserAuthenticatedGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/user/authenticated';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserAuthenticatedTenantTenantIdValueGet = (args, options = {}) => { 
    const { tenantIdValue } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/user/authenticated/tenant/{tenantIdValue}';
    url = url.split(['{', '}'].join('tenantIdValue')).join(encodeURIComponent(String(tenantIdValue)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserItemsGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/user/items';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserLanguagePut = (args, options = {}) => { 
    const { languageDTO } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/user/language';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof languageDTO ? JSON.stringify(languageDTO) : languageDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserLanguagesGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/user/languages';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserListGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/user/list';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserPageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/user/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserProfileUpdatePut = (args, options = {}) => { 
    const { userDTO } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/user/profile/update';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof userDTO ? JSON.stringify(userDTO) : userDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserProfileIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/user/profile/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserRegisterPut = (args, options = {}) => { 
    const { userRegistrationDTO } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/user/register';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof userRegistrationDTO ? JSON.stringify(userRegistrationDTO) : userRegistrationDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserTenantItemsGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/user/tenant/items';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/user/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/user/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserIdRevisionsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/user/{id}/revisions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserUserIdTenantTenantIdAddPut = (args, options = {}) => { 
    const { tenantId, userId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/user/{userId}/tenant/{tenantId}/add';
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    url = url.split(['{', '}'].join('userId')).join(encodeURIComponent(String(userId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityUserUserIdTenantTenantIdRemovePut = (args, options = {}) => { 
    const { tenantId, userId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/user/{userId}/tenant/{tenantId}/remove';
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    url = url.split(['{', '}'].join('userId')).join(encodeURIComponent(String(userId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function WhitelabelEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiSecurityWhitelabelPut = (args, options = {}) => { 
    const { whitelabelDTO } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/whitelabel';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof whitelabelDTO ? JSON.stringify(whitelabelDTO) : whitelabelDTO
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityWhitelabelInfoboxImageLeftGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/whitelabel/infobox/image/left';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityWhitelabelInfoboxImageRightGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/whitelabel/infobox/image/right';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityWhitelabelLogoGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/whitelabel/logo';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityWhitelabelLogoCollapsedGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/whitelabel/logo/collapsed';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityWhitelabelLogoExpandedGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/whitelabel/logo/expanded';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityWhitelabelLogoTemporaryPost = (args, options = {}) => { 
    const { logo } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/security/whitelabel/logo/temporary';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('logo', logo);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityWhitelabelLogoTenantTenantIdValueAvailableGet = (args, options = {}) => { 
    const { tenantIdValue } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/whitelabel/logo/tenant/{tenantIdValue}/available';
    url = url.split(['{', '}'].join('tenantIdValue')).join(encodeURIComponent(String(tenantIdValue)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityWhitelabelLogoTenantTenantIdGet = (args, options = {}) => { 
    const { tenantId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/whitelabel/logo/tenant/{tenantId}';
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityWhitelabelPictureIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/whitelabel/picture/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSecurityWhitelabelTenantTenantIdGet = (args, options = {}) => { 
    const { tenantId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/security/whitelabel/tenant/{tenantId}';
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function TextBlockEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiSettingsTextblockPost = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/settings/textblock';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSettingsTextblockPut = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/settings/textblock';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSettingsTextblockAutoTaggedTextBlockIdRatingSystemIdIndicatorIdPut = (args, options = {}) => { 
    const { indicatorId, ratingSystemId, textBlockId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/settings/textblock/autoTagged/{textBlockId}/{ratingSystemId}/{indicatorId}';
    url = url.split(['{', '}'].join('indicatorId')).join(encodeURIComponent(String(indicatorId)));
    url = url.split(['{', '}'].join('ratingSystemId')).join(encodeURIComponent(String(ratingSystemId)));
    url = url.split(['{', '}'].join('textBlockId')).join(encodeURIComponent(String(textBlockId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSettingsTextblockKpiAutoTaggedTextBlockIdKpiRatingSystemIdKpiIdPut = (args, options = {}) => { 
    const { kpiId, kpiRatingSystemId, textBlockId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/settings/textblock/kpi/autoTagged/{textBlockId}/{kpiRatingSystemId}/{kpiId}';
    url = url.split(['{', '}'].join('kpiId')).join(encodeURIComponent(String(kpiId)));
    url = url.split(['{', '}'].join('kpiRatingSystemId')).join(encodeURIComponent(String(kpiRatingSystemId)));
    url = url.split(['{', '}'].join('textBlockId')).join(encodeURIComponent(String(textBlockId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSettingsTextblockKpiSuggestedKpiRatingSystemIdKpiIdGet = (args, options = {}) => { 
    const { kpiId, kpiRatingSystemId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/settings/textblock/kpi/suggested/{kpiRatingSystemId}/{kpiId}';
    url = url.split(['{', '}'].join('kpiId')).join(encodeURIComponent(String(kpiId)));
    url = url.split(['{', '}'].join('kpiRatingSystemId')).join(encodeURIComponent(String(kpiRatingSystemId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSettingsTextblockPageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/settings/textblock/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSettingsTextblockSuggestedRatingSystemIdIndicatorIdGet = (args, options = {}) => { 
    const { indicatorId, ratingSystemId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/settings/textblock/suggested/{ratingSystemId}/{indicatorId}';
    url = url.split(['{', '}'].join('indicatorId')).join(encodeURIComponent(String(indicatorId)));
    url = url.split(['{', '}'].join('ratingSystemId')).join(encodeURIComponent(String(ratingSystemId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSettingsTextblockIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/settings/textblock/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSettingsTextblockIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/settings/textblock/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function AwardFileEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiSustainabilityAwardFilePost = (args, options = {}) => { 
    const { file } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/sustainability/award/file';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilityAwardFileIdPreviewGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/sustainability/award/file/{id}/preview';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function IndicatorFileEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiSustainabilityIndicatorFilePost = (args, options = {}) => { 
    const { file } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/sustainability/indicator/file';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilityIndicatorFileIdPreviewGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/sustainability/indicator/file/{id}/preview';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}

export function SustainabilitySystemEndpointApi(config) {
  this.config = config || new Configuration();

  this.apiSustainabilitySystemPost = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/sustainability/system';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemPut = (args, options = {}) => { 
    const { request } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/sustainability/system';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'put',
        headers: { 'Content-Type': 'application/json', ...headers, ...getHeaders(), ...options.headers},
        body: 'object' === typeof request ? JSON.stringify(request) : request
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemImportPost = (args, options = {}) => { 
    const { file } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/sustainability/system/import';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemListGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/sustainability/system/list';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemListUsedGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/sustainability/system/list/used';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemMigrationTargetsGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/sustainability/system/migration/targets';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemPageGet = (options = {}) => { 
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/sustainability/system/page';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemRichtextImagePost = (args, options = {}) => { 
    const { file } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    const url = '/api/sustainability/system/richtext/image';
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers},
        body: formData
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemIdGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/sustainability/system/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemIdDelete = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/sustainability/system/{id}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'delete',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemIdClonePost = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/sustainability/system/{id}/clone';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemIdExportGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/sustainability/system/{id}/export';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemIdMigrationExecuteTenantIdPost = (args, options = {}) => { 
    const { id, tenantId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/sustainability/system/{id}/migration/execute/{tenantId}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('tenantId')).join(encodeURIComponent(String(tenantId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'post',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.text();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemIdRevisionRevisionIdGet = (args, options = {}) => { 
    const { id, revisionId } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/sustainability/system/{id}/revision/{revisionId}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('revisionId')).join(encodeURIComponent(String(revisionId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemIdRevisionsGet = (args, options = {}) => { 
    const { id } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/sustainability/system/{id}/revisions';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemIdRichtextImageImageIdGet = (args, options = {}) => { 
    const { height, id, imageId, width } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/sustainability/system/{id}/richtext/image/{imageId}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('imageId')).join(encodeURIComponent(String(imageId)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    if (height !== undefined) {
      params.append('height', height);
    }
    if (width !== undefined) {
      params.append('width', width);
    }
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };

  this.apiSustainabilitySystemIdScoreScoreGet = (args, options = {}) => { 
    const { id, score } = args;
    const {fetchMethod, basePath, headers, getHeaders, responseHandler, errorHandler} = this.config;
    let url = '/api/sustainability/system/{id}/score/{score}';
    url = url.split(['{', '}'].join('id')).join(encodeURIComponent(String(id)));
    url = url.split(['{', '}'].join('score')).join(encodeURIComponent(String(score)));
    const params = new URLSearchParams();
    setAdditionalParams(params, options.params);
    const query = params.toString();
    const controller = new AbortController();
    const promise = new Promise((resolve, reject) => {
      const promise = fetchMethod(basePath + url + (query ? '?' + query : ''), {
        signal: controller.signal,
        method: 'get',
        headers: { ...headers, ...getHeaders(), ...options.headers}
      });
      !!errorHandler && promise.catch(errorHandler);
      const catcher = error => error?.name !== "AbortError" && reject(error);
      !!responseHandler && promise.then(responseHandler, catcher);
      if (options.returnResponse) {
        promise.then(response => resolve(response), catcher);
      } else {
        promise.then(response => {
          if (response.status === 200 || response.status === 204 || response.status === 304) {
            return response.json();
          } else {
            reject(response);
          }
        }, catcher).then(data => resolve(data), catcher);
      }
    });
    promise.abort = () => controller.abort();
    return promise;
  };
}
