import Popover from "@mui/material/Popover";
import React from "react";

export const TooltipPopover = ({ target, open, onClose, content, className, anchorOrigin, transformOrigin }) => {
  return (
    <Popover
      className={"tooltip-popover " + (className || "")}
      open={open}
      anchorEl={target}
      onClose={onClose}
      anchorOrigin={anchorOrigin || { vertical: "top", horizontal: "center" }}
      transformOrigin={transformOrigin || { vertical: "bottom", horizontal: "left" }}
      disableRestoreFocus
    >
      {content}
    </Popover>
  );
};
