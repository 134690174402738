import { toast } from "react-toastify";

export const showToast = ({ message, severity = "info" }) => {
  (toast[severity] || toast.info)(message, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    theme: "light",
  });
};

export const showPromise = (promise, messages) => toast.promise(promise, messages);

export const showError = (message) => showToast({ message, severity: "error" });
export const showSuccess = (message) => showToast({ message, severity: "success" });
export const showWarning = (message) => showToast({ message, severity: "warning" });
export const showInfo = (message) => showToast({ message, severity: "info" });
