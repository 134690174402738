import { InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import "@progress/kendo-theme-material/dist/all.scss";
import React, { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { loginRequest } from "./authConfig";
import { CustomDialogs } from "./components/CustomDialogs/CustomDialogs";
import { LoadingOverlay } from "./components/LoadingOverlay/LoadingOverlay";
import { Toasts } from "./components/Toasts/Toasts";
import { WhitelabelControl } from "./components/WhitelabelControl/WhitelabelControl";
import { acquireToken, resetAuthCookie, setMsalInstance, useAccessToken, useAccount, useUser } from "./hooks/auth";
import { getUserAuthenticated } from "./hooks/users";
import { routes } from "./routes";
import { disableInputNumberMouseWheel } from "./utils";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  setMsalInstance(instance);
  const account = useAccount();
  const accessToken = useAccessToken();
  const user = useUser();
  const [popoverShown, setPopoverShown] = useState(false);

  const handleStyleChange = () => {
    const isPaddingSet = !!window.document.body.style.paddingRight;
    if (isPaddingSet) {
      window.document.body.style.overflow = "hidden";
    }
  };

  const handleAriaHiddenChange = () => {
    const isPopoverShown = !!window.document.getElementById("root").ariaHidden;
    setPopoverShown(isPopoverShown);
    window.document.body.style.overflow = isPopoverShown ? "hidden" : "auto";
  };

  useEffect(() => {
    new MutationObserver(handleStyleChange).observe(window.document.body, {
      attributes: true,
      attributeFilter: ["style"],
    });

    new MutationObserver(handleAriaHiddenChange).observe(window.document.getElementById("root"), {
      attributes: true,
      attributeFilter: ["aria-hidden"],
    });

    disableInputNumberMouseWheel();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequest(),
        account: account || accounts[0],
      };
      acquireToken(request, instance);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      resetAuthCookie();
      instance.loginRedirect(loginRequest());
    }
  }, [inProgress, isAuthenticated]);

  useEffect(() => {
    if (accessToken) {
      getUserAuthenticated();
    }
  }, [accessToken]);

  const router = createBrowserRouter(routes);

  return (
    <div className={"App" + (popoverShown ? " popover-shown" : "")}>
      <LoadingOverlay spinner active={!user}>
        <AuthenticatedTemplate>{<RouterProvider router={router} />}</AuthenticatedTemplate>
      </LoadingOverlay>
      <Toasts />
      <CustomDialogs />
      <WhitelabelControl />
    </div>
  );
}

export default App;
