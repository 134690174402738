import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Button, Checkbox, MenuItem, Select } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SPT_SEARCH_MENU_CLASS = " spt-search-menu";
const SELECTED_MENU_ITEM_CLASS = " spt-search-selected";

export const SELECT_TYPE = "select";

export const MENU_TYPE = "menu";

export const AdvancedTableHeaderSearch = ({ column, search, onSearchChanged, resetSearchField }) => {
  const { t } = useTranslation();
  const { searchConfig } = column;
  const [menuValue, setMenuValue] = useState(
    Array.isArray(search[column.field]?.data) ? search[column.field]?.data?.map((x) => x.value) : []
  );
  let className = "advanced-table-search-field";

  let input = (
    <TextField
      className="search"
      value={search[column.field]?.data || ""}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        ),
        placeholder: t("main.search"),
      }}
      onChange={(event) => onSearchChanged(column.field, event.target.value, searchConfig?.searchFunction)}
    />
  );

  if (searchConfig && searchConfig.type === SELECT_TYPE) {
    const items = searchConfig.items ?? [];

    const menuItems = items.map((item, i) => (
      <MenuItem key={i} value={item.value}>
        {item.text}
      </MenuItem>
    ));
    input = (
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        multiple={searchConfig?.multiple ?? false}
        onChange={(event) =>
          onSearchChanged(
            column.field,
            items.filter((x) => event.target.value.includes(x.value)),
            searchConfig.searchFunction
          )
        }
        value={search[column.field]?.data.map((x) => x.value) || []}
        placeholder={t("main.search")}
        startAdornment={
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        }
      >
        {menuItems}
      </Select>
    );
  }

  if (searchConfig && searchConfig.type === MENU_TYPE) {
    const items = searchConfig.items ?? [];
    className += SPT_SEARCH_MENU_CLASS;
    const onMenuValueChange = (event, value) => {
      if (menuValue.includes(value)) {
        if (event.currentTarget.className.includes(SELECTED_MENU_ITEM_CLASS)) {
          event.currentTarget.className = event.currentTarget.className.replace(SELECTED_MENU_ITEM_CLASS, "");
        }
        setMenuValue(menuValue.filter((x) => x !== value));
      } else {
        event.currentTarget.className += SELECTED_MENU_ITEM_CLASS;
        setMenuValue([...menuValue, value]);
      }
    };

    const menuItems = items.map((item, i) => {
      const checked = menuValue.includes(item.value);
      const classNameMenuItems = "search-option" + (checked ? SELECTED_MENU_ITEM_CLASS : "");
      return (
        <Button key={i} onClick={(event) => onMenuValueChange(event, item.value)} className={classNameMenuItems}>
          <Checkbox checked={checked} />
          {item.text}
        </Button>
      );
    });

    input = <>{menuItems}</>;
  }

  useEffect(() => {
    if (searchConfig) {
      const items = searchConfig.items ?? [];
      const value = items.filter((x) => menuValue.includes(x.value));
      onSearchChanged(column.field, value.length > 0 ? value : null, searchConfig.searchFunction);
    }
  }, [menuValue]);

  return (
    <div className={className}>
      {input}
      {!!search[column.field] && (!searchConfig || searchConfig.type !== MENU_TYPE) && (
        <IconButton size="small" onClick={() => resetSearchField(column.field)}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
};
