import React from "react";
import "./UserWidget.scss";

export const UserWidget = ({fullName, encodedPicture}) => {
  return (
      <>
        {!encodedPicture && <div className="user-placeholder"></div>}
        {encodedPicture && <div className="user">
        <img src={encodedPicture} alt={fullName}></img>
        </div>}
      </>
  )
};
