import { Box } from "@mui/material";
import React from "react";
import { useMaxPercentsVisible } from "../../../hooks/dashboard";
import { roundDecimal } from "../../../utils";
import { GroupingElementBeforeBarChart } from "../GroupingElementBeforeBarChart/GroupingElementBeforeBarChart";
import "./GroupingElementBarChart.scss";

const MAX_BAR_HEIGHT = 370;
const TEXT_HIGHT = 27;

const enoughSpaceSmallerBar = (potentialScore, universalScore, proportion, maxProportion) => {
  const score = potentialScore > universalScore ? universalScore : potentialScore;
  return Math.abs((score / 100) * (proportion / maxProportion) * MAX_BAR_HEIGHT) - TEXT_HIGHT > 0;
};

export const GroupingElementBarChartESG = ({ data, isFirst, isBreak, maxProportion, onClick }) => {
  const { potentialScore, universalScore, title, proportion, uuid } = data;
  const maxPercentsVisible = useMaxPercentsVisible();
  const chartBarStyle = { height: (proportion / maxProportion) * MAX_BAR_HEIGHT + "px" };
  const potentialBarStyle = { height: potentialScore + "%" };
  const currentBarStyle = { height: universalScore + "%" };

  const spaceBetweenBars = Math.round(
    Math.abs(
      (universalScore / 100) * (proportion / maxProportion) * MAX_BAR_HEIGHT -
        (potentialScore / 100) * (proportion / maxProportion) * MAX_BAR_HEIGHT
    )
  );

  return (
    <div className={"grouping-element-bar-chart" + (isBreak ? " last-in-row" : "")} data-cy={data.systemReference}>
      {!!isFirst && <GroupingElementBeforeBarChart />}
      <Box
        className="chart-bar"
        style={chartBarStyle}
        title={roundDecimal(proportion, 100) + "%"}
        onClick={() => onClick(uuid)}
      >
        <div className="maximum-text" style={{ opacity: maxPercentsVisible ? 1 : 0 }}>
          {roundDecimal(proportion, 100)}%
        </div>
        <div className="bar-inner" title="">
          {potentialScore > universalScore && (
            <div className="potential-bar" style={potentialBarStyle} title={roundDecimal(potentialScore, 100) + "%"}>
              {spaceBetweenBars > TEXT_HIGHT && (
                <div className="potential-text">{roundDecimal(potentialScore, 100)}%</div>
              )}
            </div>
          )}
          <div className="current-bar" style={currentBarStyle} title={roundDecimal(universalScore, 100) + "%"}>
            {(potentialScore < universalScore && spaceBetweenBars > TEXT_HIGHT) ||
            (potentialScore >= universalScore &&
              enoughSpaceSmallerBar(potentialScore, universalScore, proportion, maxProportion)) ? (
              <div className="current-text">{roundDecimal(universalScore, 100)}%</div>
            ) : (
              ""
            )}
          </div>
          {potentialScore < universalScore && (
            <div
              className="potential-bar potential-bar-low"
              style={potentialBarStyle}
              title={roundDecimal(potentialScore, 100) + "%"}
            >
              {enoughSpaceSmallerBar(potentialScore, universalScore, proportion, maxProportion) && (
                <div className="potential-text">{roundDecimal(potentialScore, 100)}%</div>
              )}
            </div>
          )}
        </div>
      </Box>
      <div className="chart-footer">
        <div className="title-text">{title}</div>
      </div>
    </div>
  );
};
