import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";

export const AdvancedTableBodyFooterRow = ({
  columnsProcessed,
  getColumnPosition,
  processedData,
  columnGroupStates,
  stickyAmount,
  passThrough,
}) => {
  const lockedColumns = columnsProcessed.filter((item) => item.locked);

  return (
    <TableRow>
      {(columnsProcessed || []).map((column, index) => {
        let className = "footer-row";
        const { columnGroup, firstInGroup, lastInGroup } = columnGroupStates[index];
        if (column.columnGroup) {
          className += " " + (columnGroup.className || "");
          if (firstInGroup) {
            className += " first-in-group";
          }
          if (lastInGroup) {
            className += " last-in-group";
          }
        }
        className += index < stickyAmount ? " sticky" : "";

        return (
          <TableCell
            key={index}
            className={
              className + (column.locked ? " locked" : "") + (lockedColumns.length === index + 1 ? " last" : "")
            }
            style={{
              left: index < stickyAmount ? getColumnPosition(index) + "px" : "0",
              width: ("number" === typeof column.width ? column.width + "px" : column.width) || "auto",
            }}
          >
            {!!column.footerTemplate && column.footerTemplate(column.field, processedData, passThrough)}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
