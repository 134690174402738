import {
  areParentsChangedInRecursiveStructure,
  isObjectChanged,
  isObjectDeepChanged,
  mergeArray,
  mergeArrayOfObjects,
  mergeObject,
  mergeRecursive
} from "./merge";
import { mapSustainabilitySystemGroupings } from "../sustainabilitySystem";
import { GROUPING_TYPE_INDICATOR } from "../../constants/sustainabilitySystem";

export const mergeSustainabilitySystem = (initial = {}, current = {}, updated = {}) => {
  let withConflicts = false;

  const fieldsToMerge = [
    'name',
    'systemSource',
    'systemVersion',
    'description',
    'status',
    'systemLogic',
    'overallMaxSystemScore',
    'awardCalculationType'
  ];

  const [merged, objectConflicts] = mergeObject(initial, current, updated, fieldsToMerge);
  if (objectConflicts) {
    withConflicts = true;
  }
  merged.modifiedDateTime = current.modifiedDateTime;

  const [projectTypes, projectTypesConflicts] = mergeArray(initial.projectTypes, current.projectTypes, updated.projectTypes);
  if (projectTypesConflicts) {
    withConflicts = true;
  }

  const awardFieldsToMerge = [
    'actualSideThreshold',
    'fileId',
    'potentialSideThreshold',
    'scoreFrom',
    'scoreTo',
    'title'
  ];
  const [awards, awardsConflicts] = mergeArrayOfObjects(initial.awards, current.awards, updated.awards, awardFieldsToMerge, 'id');
  if (awardsConflicts) {
    withConflicts = true;
  }

  const groupingFieldsToMerge = [
    'UID',
    'id',
    'sortingOrder',
    'type',

    'systemReference',
    'name',
    'weight',
    'weighting',
    'weightingFactor',
    'byFactor',
    'maxSystemScore',
    'maxSystemScoreLimit',
    'koValue',
    'sideRequirement',
    'excludable',

    'indicatorName',
    'unit',
    'allowCustomValue',
    'evaluationStandard',
    'valueType',
    'visibleOnTable'
  ];

  const mergeFn = (a, b, c) => {
    let withConflict = false;
    let result;
    if (b) {
      const [mergedInner, mergeConflict] = mergeObject(a, b, c, groupingFieldsToMerge);
      result = mergedInner;
      if (mergeConflict) {
        withConflict = true;
      }
      if (result.type === GROUPING_TYPE_INDICATOR) {
        const [valueRangeList, valueRangeListConflict] = mergeArrayOfObjects(a?.valueRangeList, b?.valueRangeList, c?.valueRangeList, null, "value");
        result.valueRangeList = valueRangeList;
        if (valueRangeListConflict) {
          withConflict = true;
        }
        const [kpis, kpisConflict] = mergeArrayOfObjects(a?.kpis, b?.kpis, c?.kpis, null, "name");
        result.kpis = kpis;
        if (kpisConflict) {
          withConflict = true;
        }
        const [evaluationStandardFiles, evaluationStandardFilesConflict] = mergeArrayOfObjects(
          a?.evaluationStandardFiles,
          b?.evaluationStandardFiles,
          c?.evaluationStandardFiles,
          null, "id");
        result.evaluationStandardFiles = evaluationStandardFiles;
        if (evaluationStandardFilesConflict) {
          withConflict = true;
        }
        const [evaluationStandardLinks, evaluationStandardLinksConflict] = mergeArrayOfObjects(
          a?.evaluationStandardLinks,
          b?.evaluationStandardLinks,
          c?.evaluationStandardLinks,
          null, "id");
        result.evaluationStandardLinks = evaluationStandardLinks;
        if (evaluationStandardLinksConflict) {
          withConflict = true;
        }
      }
    } else {
      result = c;
      withConflict = true;
    }

    return [result, withConflict];
  };

  const isChangedFn = (a, b) => {
    let isChanged = isObjectChanged(a, b, groupingFieldsToMerge);
    if (isObjectDeepChanged(a?.valueRangeList, b?.valueRangeList)
      || isObjectDeepChanged(a?.kpis, b?.kpis)
      || isObjectDeepChanged(a?.evaluationStandardFiles, b?.evaluationStandardFiles)
      || isObjectDeepChanged(a?.evaluationStandardLinks, b?.evaluationStandardLinks)) {
      isChanged = true;
    }
    return isChanged;
  };

  let children;
  if (areParentsChangedInRecursiveStructure(current.children, updated.children, 'groupingElements')) {
    children = updated.groupingElements;
  } else {
    const [mergedChildren, childrenConflicts] = mergeRecursive(
      mapSustainabilitySystemGroupings(initial.groupingElements),
      mapSustainabilitySystemGroupings(current.groupingElements),
      mapSustainabilitySystemGroupings(updated.groupingElements),
      mergeFn, isChangedFn, 'children', 'UID'
    );
    if (childrenConflicts) {
      withConflicts = true;
    }
    children = mergedChildren;
  }

  return [{ ...merged, projectTypes, awards, children }, withConflicts];
};

