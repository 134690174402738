import React from "react";
import { useTableScrollX } from "../../hooks/advancedTable";
import { useTranslation } from "react-i18next";

export const AdvancedTableFooter = ({processed, emptyText, footer}) => {
  const {t} = useTranslation();
  const tableScrollX = useTableScrollX();

  return (
    <div className="footer-buttons" style={{ left: tableScrollX + 'px' }}>
      {!processed.length && <div className="empty-cell">
        {emptyText || t('main.empty')}
      </div>}
      {footer}
    </div>
  );
};
