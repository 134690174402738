import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { TENANT_TYPE_ESG } from "../../../constants/tenant";
import { canEdit } from "../../../helpers/ratingSystem";
import { getUserTenantType } from "../../../hooks/auth";
import { roundDecimal } from "../../../utils";
import { DateColumnTemplate } from "../../ColumnTemplate/DateColumnTemplate";
import { SimpleTableWithSort } from "../../SimpleTableWithSort/SimpleTableWithSort";
import { ProjectAwardTooltipPopover } from "../ProjectAwardTooltipPopover/ProjectAwardTooltipPopover";
import { ProjectKPISystemsDialog } from "../ProjectKPISystemsDialog/ProjectKPISystemsDialog";
import { ProjectSystemsDialog } from "../ProjectSystemsDialog/ProjectSystemsDialog";
import {
  RatingSuperScoreColumnTemplate,
  RatingSystemNameColumnTemplate,
  StatusColumnTemplate,
} from "../ProjectTemplates/ProjectTemplates";
import "./ProjectRatingSystems.scss";

export const ProjectRatingSystems = ({ project, readOnly }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenantType = getUserTenantType();

  const permissions = project.projectPermission ?? {};
  const [showTooltip, setShowTooltip] = useState(null);
  const [addSystemToProject, setAddSystemToProject] = useState(null);
  const [addKpiSystemToProject, setAddKpiSystemToProject] = useState(null);
  const data = [...(project.ratingSystems || []), ...(project.kpiRatingSystems || [])];

  const onAddSystems = (dataAddSystems) => {
    setAddSystemToProject(null);
    setAddKpiSystemToProject(null);
    if (addSystemToProject) {
      const { ratingSystems } = dataAddSystems;
      if (ratingSystems.length) {
        const system = ratingSystems[ratingSystems.length - 1];
        navigate("/project/ratingSystem/" + system.id);
      }
    } else if (addKpiSystemToProject) {
      const { kpiRatingSystems } = dataAddSystems;
      if (kpiRatingSystems.length) {
        const system = kpiRatingSystems[kpiRatingSystems.length - 1];
        navigate("/project/kpiRatingSystem/" + system.id);
      }
    }
  };

  const award = ((showTooltip || {}).data || {}).selectedAward;

  const columns = useMemo(() => {
    const items = [];

    if (tenantType === TENANT_TYPE_ESG) {
      items.push(
        ...items,
        ...[
          {
            field: "systemSource",
            headerText: t("projects.ratingSystem"),
            template: (childRow, field, row) =>
              RatingSystemNameColumnTemplate(childRow, field, row, showTooltip, setShowTooltip),
          },
          {
            field: "score",
            className: "text-right nowrap",
            headerClassName: "text-right",
            headerText: t("projects.score"),
            template: (props, field) => (props.kpiSystemId ? "-" : props[field] ? roundDecimal(props[field]) : ""),
          },
          {
            field: "superScore",
            className: "text-right nowrap",
            headerClassName: "text-right",
            headerText: t("projects.superScore"),
            template: RatingSuperScoreColumnTemplate,
          },
        ]
      );
    } else {
      items.push(
        ...items,
        ...[
          {
            field: "systemSource",
            headerText: t("projects.ratingSystem"),
            template: (childRow, field, row) =>
              RatingSystemNameColumnTemplate(childRow, field, row, showTooltip, setShowTooltip),
          },
          {
            field: "score",
            className: "text-right nowrap",
            headerClassName: "text-right",
            headerText: t("projects.pastScore"),
            template: (props) => (props.score ? roundDecimal(props.score) : ""),
          },
        ]
      );
    }

    return [
      ...items,
      ...[
        {
          field: "ratingStatus",
          className: "status-cell",
          headerText: t("projects.status"),
          template: (props) => StatusColumnTemplate(props, t),
        },
        {
          field: "modifiedDateTime",
          className: "status-cell",
          headerText: t("projects.lastUpdate"),
          template: DateColumnTemplate,
        },
      ],
    ];
  }, [tenantType]);

  if ((readOnly || !canEdit(permissions)) && !data.length) {
    return null;
  }

  return (
    <div className="project-rating-systems content-block">
      {!!data.length && (
        <div>
          <SimpleTableWithSort className="repaint" data={data} columns={columns} />
        </div>
      )}
      <ProjectAwardTooltipPopover award={award} setShowTooltip={setShowTooltip} showTooltip={showTooltip} />
      <ProjectSystemsDialog
        project={addSystemToProject}
        open={!!addSystemToProject}
        onClose={() => setAddSystemToProject(null)}
        onSave={onAddSystems}
      />
      <ProjectKPISystemsDialog
        project={addKpiSystemToProject}
        open={!!addKpiSystemToProject}
        onClose={() => setAddKpiSystemToProject(null)}
        onSave={onAddSystems}
      />
      {!readOnly && canEdit(permissions) && (
        <div className={"text-center " + (!data?.length ? "no-records" : "")}>
          <Button onClick={() => setAddSystemToProject(project)} className="header-button">
            <AddIcon /> {t("project.addRatingSystem")}
          </Button>
          <Button onClick={() => setAddKpiSystemToProject(project)} className="header-button">
            <AddIcon /> {t("groupingContext.addKpiSystem")}
          </Button>
        </div>
      )}
    </div>
  );
};
