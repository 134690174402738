import { FilterOperator } from "@progress/kendo-data-query";
import {
  INDICATOR_GROUPING_TYPE,
  STATUS_CLOSED,
  STATUS_IN_PROCESSING,
  STATUS_NOT_APPLICABLE,
  STATUS_NOT_RATED,
  STATUS_RATED,
} from "../../constants/ratingSystem";

export const statusValues = [
  { text: `grouping.status.${STATUS_NOT_RATED}`, value: STATUS_NOT_RATED },
  { text: `grouping.status.${STATUS_IN_PROCESSING}`, value: STATUS_IN_PROCESSING },
  { text: `grouping.status.${STATUS_RATED}`, value: STATUS_RATED },
  { text: `grouping.status.${STATUS_CLOSED}`, value: STATUS_CLOSED },
  { text: `grouping.status.${STATUS_NOT_APPLICABLE}`, value: STATUS_NOT_APPLICABLE },
];

export const excludeFilter = [
  {
    text: "newRatingSystem.esg.filter.notExcluded",
    filterValue: [
      {
        field: "excluded",
        value: false,
        operator: FilterOperator.EqualTo,
      },
    ],
  },
  {
    text: "newRatingSystem.esg.filter.excluded",
    filterValue: [
      {
        field: "excluded",
        value: true,
        operator: FilterOperator.EqualTo,
      },
    ],
  },
];

export const defaultStatusFilter = [
  {
    field: "indicatorStatus",
    value: STATUS_NOT_RATED,
    operator: FilterOperator.EqualTo,
  },
  {
    field: "indicatorStatus",
    value: null,
    operator: FilterOperator.IsNull,
  },
];

export const statusFilterValues = statusValues.map((status) => ({
  ...status,
  filterValue:
    status.value === STATUS_NOT_RATED
      ? defaultStatusFilter
      : [{ value: status.value, field: "indicatorStatus", operator: FilterOperator.EqualTo }],
}));

export const trafficLightFilterValues = [
  {
    text: "ratingSystem.status.missingScore",
    filterValue: [
      {
        field: "trafficLight",
        logic: "and",
        filters: [
          { field: "trafficLightScore", value: -1, operator: FilterOperator.EqualTo },
          { field: "type", value: INDICATOR_GROUPING_TYPE, operator: FilterOperator.EqualTo },
        ],
      },
    ],
  },
  {
    text: "ratingSystem.status.missingReason",
    filterValue: [
      {
        field: "trafficLightReason",
        logic: "and",
        filters: [
          { field: "trafficLightReason", value: -1, operator: FilterOperator.EqualTo },
          { field: "type", value: INDICATOR_GROUPING_TYPE, operator: FilterOperator.EqualTo },
        ],
      },
    ],
  },
  {
    text: "ratingSystem.status.missingAction",
    filterValue: [
      {
        field: "trafficLightActions",
        logic: "and",
        filters: [
          {
            field: "trafficLightActions",
            logic: "or",
            filters: [
              { field: "trafficLightActions", value: 0, operator: FilterOperator.EqualTo },
              { field: "trafficLightActions", value: -1, operator: FilterOperator.EqualTo },
            ],
          },
          { field: "type", value: INDICATOR_GROUPING_TYPE, operator: FilterOperator.EqualTo },
        ],
      },
    ],
  },
];
