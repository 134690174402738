import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AWARD_CALCULATION_TYPE_PERCENT } from "../../../../constants/sustainabilitySystem";
import { AwardBarFlagSVG } from "../../../../svg";
import { roundDecimal } from "../../../../utils";
import { TextPopover } from "../../../TextPopover/TextPopover";
import "./SystemScoreProgress.scss";

export const SystemScoreProgressESG = ({ data }) => {
  const { t } = useTranslation();
  const [showActualTooltip, setShowActualTooltip] = useState(null);
  const [showPotentialTooltip, setShowPotentialTooltip] = useState(null);
  const [actualTooltip, setActualTooltip] = useState(false);
  const [potentialTooltip, setPotentialTooltip] = useState(false);
  const actualRef = useRef(null);
  const potentialRef = useRef(null);

  const {
    awards,
    awardCalculationType,
    score,
    superScore,
    potentialScore,
    potentialSuperScore,
    overallMaxSystemScore,
  } = data || {};

  useEffect(() => {
    setPotentialTooltip(isPopover(true));
    setActualTooltip(isPopover(false));
  }, [potentialSuperScore, superScore, potentialRef?.current?.offsetWidth, actualRef?.current?.offsetWidth]);

  const isPopover = (isPotential) => {
    const potentialWidth = potentialRef?.current?.offsetWidth;
    const actualWidth = actualRef?.current?.offsetWidth;
    let maxWidth = actualRef?.current?.children[0].offsetWidth || 60;

    if (isPotential) {
      maxWidth = potentialRef?.current?.children[0].offsetWidth || 60;
      if (actualWidth > potentialWidth) {
        return potentialWidth < maxWidth;
      } else {
        return potentialWidth - actualWidth < maxWidth;
      }
    }
    if (actualWidth > potentialWidth) {
      return actualWidth - potentialWidth < maxWidth;
    } else {
      return actualWidth < maxWidth;
    }
  };

  const processWidth = (valueProcessWidth) => {
    if (valueProcessWidth < 0) {
      return 0;
    }

    return isPercent ? valueProcessWidth : (valueProcessWidth / overallMaxSystemScore) * 100;
  };

  const isPercent = awardCalculationType === AWARD_CALCULATION_TYPE_PERCENT;
  const value = +((isPercent ? superScore : score) || 0).toFixed(4);
  const potential = +((isPercent ? potentialSuperScore : potentialScore) || 0).toFixed(4);
  const unit = isPercent ? "%" : "";

  return (
    <div className="system-score-progress">
      <span className="title">{t("dashboard.awardProgress")}</span>
      <div className="progress-bar">
        <section className="scores">
          <AwardBarFlagSVG />
          {potential > value ? (
            <Box
              className="score potential"
              style={{ width: `${processWidth(potential)}%` }}
              ref={potentialRef}
              onMouseEnter={(e) => setShowPotentialTooltip({ data: {}, target: e.currentTarget })}
              onMouseLeave={() => setShowPotentialTooltip(null)}
            >
              <span className={potentialTooltip ? "hidden" : ""}>{roundDecimal(potential, 100) + unit}</span>
              <TextPopover setShowTooltip={setShowPotentialTooltip} showTooltip={showPotentialTooltip}>
                {roundDecimal(potential, 100) + unit}
              </TextPopover>
            </Box>
          ) : null}
          <Box
            className="score actual"
            style={{ width: `${processWidth(value)}%` }}
            ref={actualRef}
            onMouseEnter={(e) => setShowActualTooltip({ data: {}, target: e.currentTarget })}
            onMouseLeave={() => setShowActualTooltip(null)}
          >
            <span className={actualTooltip ? "hidden" : ""}>{roundDecimal(value, 100) + unit}</span>
            <TextPopover setShowTooltip={setShowActualTooltip} showTooltip={showActualTooltip}>
              {roundDecimal(value, 100) + unit}
            </TextPopover>
          </Box>
          {potential < value ? (
            <Box
              className="score negative-potential"
              style={{ width: `${processWidth(potential)}%` }}
              ref={potentialRef}
              onMouseEnter={(e) => setShowPotentialTooltip({ data: {}, target: e.currentTarget })}
              onMouseLeave={() => setShowPotentialTooltip(null)}
            >
              <span className={potentialTooltip ? "hidden" : ""}>{roundDecimal(potential, 100) + unit}</span>
              <TextPopover setShowTooltip={setShowPotentialTooltip} showTooltip={showPotentialTooltip}>
                {roundDecimal(potential, 100) + unit}
              </TextPopover>
            </Box>
          ) : null}
        </section>
        <section className={"awards"} data-max={isPercent ? "100%" : overallMaxSystemScore}>
          {(awards || []).map((a) => (
            <div
              className="award-case"
              key={a.id}
              style={{
                left: `${processWidth(a.scoreFrom)}%`,
                width: `${processWidth(a.scoreTo - a.scoreFrom)}%`,
              }}
            >
              <span className="from">{a.scoreFrom + unit}</span>
              <span className="name">{a.title}</span>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

SystemScoreProgressESG.propTypes = {
  data: PropTypes.object,
};
