import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { BenchmarkCopyToTenantDialog } from "../../components/Benchmark/BenchmarkCopyToTenantDialog/BenchmarkCopyToTenantDialog";
import { StatusFilter } from "../../components/Benchmark/StatusFilter/StatusFilter";
import { Chips } from "../../components/Chips/Chips";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { SimpleTableWithSort } from "../../components/SimpleTableWithSort/SimpleTableWithSort";
import { StatusBadge } from "../../components/StatusBadge/StatusBadge";
import { MIN_PAGE_SIZE, PAGE_SIZES } from "../../constants/main";
import {
  PERMISSION_BENCHMARK_CREATE,
  PERMISSION_BENCHMARK_EDIT,
  PERMISSION_BENCHMARK_MIGRATION,
} from "../../constants/permissions";
import { hasPermission } from "../../helpers/permission";
import { checkPopState } from "../../hooks";
import {
  cloneBenchmark,
  deleteBenchmark,
  getBenchmarkStatusCount,
  resetBenchmarkPageState,
  searchBenchmarks,
  useBenchmarkCloneResponse,
  useBenchmarkDeleteResponse,
  useBenchmarkPageLogic,
  useBenchmarkPageResponse,
} from "../../hooks/benchmark";
import { showMuiDialog } from "../../hooks/dialog";
import { showError, showInfo, showSuccess } from "../../hooks/toast";
import { useTimeout } from "../../hooks/utils/useTimeout";
import { PlusSVG } from "../../svg";
import { processMessage } from "../../utils";

const NameColumnTemplate = (props, field) => (
  <NavLink to={"/sustainabilitySystem/benchmark/" + props.id} className="column-link">
    {props[field]}
  </NavLink>
);

const StatusColumnTemplate = (props, t) =>
  !!props.status && (
    <StatusBadge className={"status-" + props.status}>{t("status.filter." + props.status)}</StatusBadge>
  );

const ActionsColumnTemplate = (props, setDeleteConfirmation, rootProps, setKebabPopoverTarget) => (
  <>
    {hasPermission(PERMISSION_BENCHMARK_EDIT) && (
      <NavLink to={"/sustainabilitySystem/benchmark/" + props.id + "?mode=edit"}>
        <IconButton color="primary" size="small" disabled={props.readOnly}>
          <EditIcon />
        </IconButton>
      </NavLink>
    )}
    {!hasPermission(PERMISSION_BENCHMARK_EDIT) && (
      <NavLink to={"/sustainabilitySystem/benchmark/" + props.id}>
        <IconButton color="primary" size="small">
          <VisibilityIcon />
        </IconButton>
      </NavLink>
    )}
    {hasPermission(PERMISSION_BENCHMARK_EDIT) && (
      <IconButton size="small" onClick={(event) => setKebabPopoverTarget({ target: event.target, props, rootProps })}>
        <MoreVertIcon />
      </IconButton>
    )}
  </>
);

export const BenchmarksPage = () => {
  const { t } = useTranslation();
  const response = useBenchmarkPageResponse();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const deleteResponse = useBenchmarkDeleteResponse();
  const cloneResponse = useBenchmarkCloneResponse();

  const {
    searchValue,
    setSearchValue,
    filterValue,
    setFilterValue,
    sortValue,
    setSortValue,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    resetState,
  } = useBenchmarkPageLogic();
  const [, setSearchTimeout] = useTimeout();
  const [init, setInit] = useState(false);
  const [processed, setProcessed] = useState([]);
  const [total, setTotal] = useState(0);
  const [kebabPopoverTarget, setKebabPopoverTarget] = useState(null);

  const load = () =>
    searchBenchmarks({
      page: page + 1,
      itemsPerPage: rowsPerPage,
      sort: sortValue.field ? (sortValue.desc ? "-" : "") + sortValue.field : undefined,
      search: searchValue,
      status: filterValue,
    }).catch((error) => {
      console.error(error);
      showError(t("error.500"));
    });

  const loadCounts = () =>
    getBenchmarkStatusCount({
      search: searchValue,
    }).catch((error) => {
      console.error(error);
      showError(t("error.500"));
    });

  useEffect(() => {
    if (!checkPopState()) {
      resetBenchmarkPageState();
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      load();
    }
  }, [init, searchValue, sortValue.field, sortValue.desc, page, rowsPerPage, filterValue]);

  useEffect(() => {
    if (init) {
      loadCounts();
    }
  }, [init, searchValue]);

  useEffect(() => {
    if (!response.loading) {
      const responseData = response.data || {};
      const records = responseData.content || [];
      const totalValue = (responseData.meta || {}).total || 0;
      setProcessed(records);
      setTotal(totalValue);
    }
  }, [response.loading, response.data]);

  const onSort = (field) =>
    setSortValue({
      field: field !== sortValue.field || !sortValue.desc ? field : null,
      desc: sortValue.field === field ? !sortValue.desc : false,
    });

  const search = (value) => {
    setSearchTimeout(setTimeout(() => setSearchValue(String(value || "").trim()), 500));
  };

  const afterChange = () => {
    setPage(0);
    load();
    loadCounts();
  };

  const openMigrateSystemDialog = (benchmarkId) =>
    showMuiDialog((props) => <BenchmarkCopyToTenantDialog benchmarkId={benchmarkId} {...props} />);

  const deleteHandler = () => {
    const benchmarkId = deleteConfirmation;
    setDeleteConfirmation(false);
    deleteBenchmark(benchmarkId).then(
      () => {
        showInfo(t("benchmark.deletedMessage"));
        afterChange();
      },
      () => showError(t("benchmark.deleteError"))
    );
  };

  const cloneHandler = (id) => {
    cloneBenchmark(id).then(
      () => {
        showSuccess(t("benchmark.cloneCreatedSuccessMessage"));
        afterChange();
      },
      () => showError(t("benchmark.cloneCreatedErrorMessage"))
    );
  };

  const pagination = {
    labelRowsPerPage: t("pagination.rowsPerPage"),
    labelDisplayedRows: ({ from, to, count }) => processMessage(t("pagination.displayedRows"), [from, to, count]),
    rowsPerPageOptions: PAGE_SIZES,
    count: total,
    page: page,
    show: total > MIN_PAGE_SIZE ? "true" : "false",
    onPageChange: (event, pagePagination) => setPage(pagePagination),
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: (event) => setRowsPerPage(event.target.value),
  };

  return (
    <div className="benchmarks-page page-with-table">
      <div className="page-header large flex-row">
        <div className="flex-col title-box no-margin">
          <h1>{t("menu.benchmark.title")}</h1>
        </div>
        <div className="flex-col search-box no-margin">
          <SearchIcon />
          <input
            type="text"
            key={resetState}
            defaultValue={searchValue || ""}
            className="search-input input-shadow"
            placeholder={t("main.search")}
            onChange={(event) => search(event.target.value)}
          />
        </div>
      </div>

      <div className="page-layout">
        <div className="flex-row-filter">
          <StatusFilter onChange={setFilterValue} value={filterValue} />
        </div>

        <LoadingOverlay
          spinner
          className="table-loading"
          active={response.loading || deleteResponse.loading || cloneResponse.loading}
        >
          <SimpleTableWithSort
            className="repaint"
            data={processed}
            pagination={pagination}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            sortValue={sortValue}
            onSort={onSort}
            columns={[
              {
                field: "name",
                sortable: true,
                headerText: t("benchmark.name"),
                template: NameColumnTemplate,
              },
              {
                field: "description",
                headerText: t("benchmark.description"),
              },
              {
                field: "kpiUnit",
                headerText: t("benchmark.unit"),
              },
              {
                field: "status",
                sortable: true,
                className: "status-cell",
                headerText: t("benchmark.status"),
                template: (props) => StatusColumnTemplate(props, t),
              },
              {
                field: "tags",
                sortable: true,
                className: "tags-cell",
                headerText: t("benchmark.tags"),
                template: (props) => <Chips chips={props.tags || []} readOnly />,
              },
              {
                className: "action-cell",
                headerClassName: "action-header-cell",
                headerText: t("main.actions"),
                template: (props, rootProps) =>
                  ActionsColumnTemplate(props, setDeleteConfirmation, rootProps, setKebabPopoverTarget),
              },
            ]}
          />
        </LoadingOverlay>
      </div>

      {hasPermission(PERMISSION_BENCHMARK_CREATE) && (
        <div className="fabs">
          <Fab type="button" color="primary">
            <NavLink to="/sustainabilitySystem/benchmark/create" className="link">
              <PlusSVG />
            </NavLink>
          </Fab>
        </div>
      )}

      <Popover
        open={!!kebabPopoverTarget}
        anchorEl={(kebabPopoverTarget || {}).target}
        onClose={() => setKebabPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {hasPermission(PERMISSION_BENCHMARK_CREATE) && (
          <MenuItem
            onClick={() => {
              setKebabPopoverTarget(null);
              cloneHandler((kebabPopoverTarget || {}).props?.id);
            }}
          >
            {t("main.clone")}
          </MenuItem>
        )}
        {hasPermission(PERMISSION_BENCHMARK_MIGRATION) && (
          <MenuItem
            onClick={() => {
              setKebabPopoverTarget(null);
              openMigrateSystemDialog((kebabPopoverTarget || {}).props?.id);
            }}
          >
            {t("benchmark.migrate")}
          </MenuItem>
        )}
        <MenuItem
          disabled={(kebabPopoverTarget || {}).props?.readOnly}
          onClick={() => {
            setKebabPopoverTarget(null);
            setDeleteConfirmation((kebabPopoverTarget || {}).props?.id);
          }}
        >
          {t("main.delete")}
        </MenuItem>
      </Popover>

      <ConfirmationDialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={deleteHandler}
        showLineBreaks
        titleText={t("benchmark.deleteConfirmationTitle")}
        bodyText={t("benchmark.deleteConfirmation")}
        confirmText={t("main.delete")}
        color="secondary"
      />
    </div>
  );
};
