import { drawBackgroundLine, drawValueBar } from "../../../helpers/canvas";

export const renderProjectVsKPIChart = (canvas, width, height, values, { linesAmount, limitedXRange, valuesById, heightInPoints }) => {
  const offset = 20;
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.reset();
  const amount = linesAmount || 20;
  const lineStepHeight = height / amount;
  for (let i = 0; i < amount; i++) {
    const y = i * lineStepHeight;
    drawBackgroundLine(ctx, 0, y, width, y, '#ECECEC', 2);
  }

  let valuesSum = 0;
  let valuesAmount = 0;
  Object.keys(valuesById).forEach(projectId => {
    valuesSum += valuesById[projectId];
    valuesAmount++;
  });
  const average = valuesAmount ? valuesSum / valuesAmount : 0;

  if (average) {
    const y = height - average / heightInPoints * height;
    drawBackgroundLine(ctx, 0, y, width, y, '#62B0FF', 2);
  }

  const xAmount = limitedXRange.length;
  if (xAmount) {
    const stepWidth = (width - (offset * 2)) / xAmount;

    for (let index = 0; index <= limitedXRange.length; index++) {
      const { projectId } = limitedXRange[index] || {};
      if (projectId && valuesById[projectId]) {
        drawValueBar(ctx, index * stepWidth + stepWidth / 2 + offset, height, 30, valuesById[projectId] / heightInPoints * height);
      }
    }
  }

  drawBackgroundLine(ctx, 0, height, width, height, '#B9BCBE', 2);
};

export const renderKpiVsKPIChart = (canvas, width, height, values, { xRange, yRange, valuesById, heightInPoints, widthInPoints }) => {
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.reset();
  const yAmount = yRange.length - 1;
  const yLineStepHeight = height / yAmount;
  for (let i = 0; i < yAmount; i++) {
    const y = i * yLineStepHeight;
    drawBackgroundLine(ctx, 0, y, width, y, '#ECECEC', 2);
  }
  const xAmount = xRange.length - 1;
  const xLineStepWidth = width / xAmount;
  for (let i = 1; i <= xAmount; i++) {
    const x = i * xLineStepWidth;
    drawBackgroundLine(ctx, x, 0, x, height, '#ECECEC', 2);
  }

  drawBackgroundLine(ctx, 0, 0, 0, height, '#B9BCBE', 2);
  drawBackgroundLine(ctx, 0, height, width, height, '#B9BCBE', 2);
};
