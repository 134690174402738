import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PERCENT_SIGN, ZERO_PERCENT } from "../../../constants/main";
import { INDICATOR_STATUSES, RANGE_COLORS_ESG, STATUS_CLOSED, STATUS_NOT_RATED } from "../../../constants/ratingSystem";
import {
  GROUPING_TYPE_CATEGORY,
  GROUPING_TYPE_INDICATOR,
  SYSTEM_LOGIC_DGNB,
} from "../../../constants/sustainabilitySystem";
import { getMissingStatusesSearchConfig, getNodeChangedFields, isKOFailed } from "../../../helpers/ratingSystem";
import { sortGroupings } from "../../../helpers/sustainabilitySystem";
import { useUser } from "../../../hooks/auth";
import { indicatorsWithActionsByIdSubject, setSelectedRow } from "../../../hooks/ratingSystem";
import { showError, showWarning } from "../../../hooks/toast";
import { useEvent } from "../../../hooks/utils/useEvent";
import { CategorySVG, IndicatorSVG } from "../../../svg";
import {
  findRecursive,
  forEachRecursive,
  getValueOrDash,
  isExcludedElement,
  isIncludedElement,
  onlyDecimals,
  potentialEvaluationSystemScoreESGVisualAid,
  processMessage,
  roundDecimal,
  setupIndicatorStatus,
} from "../../../utils";
import {
  calculateGroupingMaxScore,
  conditionIsCategory,
  conditionIsCriterion,
  conditionIsIndicator,
  conditionIsIndicatorIncluded,
  conditionNotCategory,
  conditionNotIndicator,
  sumGroupingWeightedMaxSystemScore,
} from "../../../validation/sustainabilitySystem";
import { AdvancedTable } from "../../AdvancedTable/AdvancedTable";
import {
  COLUMN_TYPE_CHECKBOX,
  COLUMN_TYPE_NUMBER,
  COLUMN_TYPE_PERCENT,
  COLUMN_TYPE_TEXT,
} from "../../AdvancedTable/AdvancedTableCell";
import { ProgressSquare } from "../../ProgressSquare/ProgressSquare";
import { SearchBadge } from "../../SearchBadge/SearchBadge";
import { StatusBadge } from "../../StatusBadge/StatusBadge";
import { MaxSystemScoreTemplate } from "../../SustainabilitySystem/SustainabilitySystemGrouping/MaxSystemScoreTemplate";
import { WeightingColumnTemplate } from "../../SustainabilitySystem/SustainabilitySystemGrouping/WeightingColumnTemplate";
import { headerStatusTemplate } from "./RatingSystemGrouping";
import "./RatingSystemGrouping.scss";
import { RatingSystemStatusIndicator, RatingSystemStatusIndicatorPopover } from "./RatingSystemStatusIndicator";

const actionCondition = (row) => row?.evaluationSystemScore !== row?.potentialEvaluationSystemScore;

const getPotentialEvaluationSystemScore = (row, systemLogic, effectivePotentialEvaluationSystemScore, title) => {
  let result;
  if (systemLogic === SYSTEM_LOGIC_DGNB && conditionNotIndicator(row)) {
    result = row.weightedPotentialSystemScore != null ? roundDecimal(row.weightedPotentialSystemScore, 100) : "-";
  } else if (effectivePotentialEvaluationSystemScore != null) {
    result = potentialEvaluationSystemScoreESGVisualAid(row, indicatorsWithActionsByIdSubject.getValue()[row.id]) ? (
      <span className="potentialEvaluationSystemScoreESGVisualAid">
        ({roundDecimal(effectivePotentialEvaluationSystemScore, 100)})
        <Tooltip title={title}>
          <InfoOutlinedIcon className="indicatorPotentialHelpIcon" />
        </Tooltip>
      </span>
    ) : (
      roundDecimal(effectivePotentialEvaluationSystemScore, 100)
    );
  } else {
    result = "-";
  }

  return result;
};

export const RatingSystemGroupingESG = React.memo((props) => {
  const {
    values,
    previousValues,
    onChange,
    readOnly,
    submitted,
    projectId,
    selectedRow,
    score,
    potentialScore,
    superScore,
    potentialSuperScore,
    systemLogic,
    errors,
    errorMessage,
    ratingSystemId,
  } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState({});
  const [nodesByUUID, setNodesByUUID] = useState({});
  const [popoverTarget, setPopoverTarget] = useState(null);
  const [reset, setReset] = useState(false);
  const { user, tenant } = useUser();
  const storagePath = "hiddenColumns." + user?.id + "." + tenant?.id + "." + ratingSystemId;
  const hiddenColumnsString = localStorage.getItem(storagePath);
  let storedHiddenColumns = [];
  if (hiddenColumnsString) {
    try {
      storedHiddenColumns = JSON.parse(hiddenColumnsString);
    } catch (e) {
      console.error(e);
    }
  }
  const updateHiddenColumns = (hiddenColumnsUpdate) => {
    setHiddenColumns(hiddenColumnsUpdate);
    localStorage.setItem(storagePath, JSON.stringify(hiddenColumnsUpdate));
  };
  const [hiddenColumns, setHiddenColumns] = useState(storedHiddenColumns);

  useEffect(() => {
    const nodesByUUIDInner = {};
    if (previousValues) {
      forEachRecursive(previousValues, (node) => {
        nodesByUUIDInner[node.uuid] = node;
      });
    }
    setNodesByUUID(nodesByUUIDInner);
  }, [previousValues]);

  const hasExcludable = useMemo(() => {
    let res = false;
    forEachRecursive(values, (node) => {
      if (node.excludable) {
        res = true;
        return true;
      }
    });
    return res;
  }, [values]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (hasExcludable) {
      setReset(true);
    }
  }, [hasExcludable]);

  const selectStatus = (event) => {
    event.stopPropagation();
    changeAllStatuses(event.target.value);
  };

  const selectChildrenStatus = (event, parentUuid) => {
    event.stopPropagation();
    changeChildrenStatuses(parentUuid, event.target.value);
  };

  const selectIndicatorStatus = (event, uuid) => {
    event.stopPropagation();
    changeIndicatorStatus(uuid, event.target.value);
  };

  const getValues = useEvent(() => values);

  const passThrough = useMemo(
    () => ({
      submitted,
      readOnly,
      nodesByUUID,
      projectId,
      score,
      potentialScore,
      superScore,
      potentialSuperScore,
      systemLogic,
      getValues,
      selectStatus,
      t,
      selectChildrenStatus,
      selectIndicatorStatus,
    }),
    [submitted, readOnly, nodesByUUID, projectId, score, potentialScore, superScore, potentialSuperScore, systemLogic]
  );

  const calculateTotalPercentagePotential = (grouping) => {
    let totalAbsoluteDegreeOfFulfillmentPotential = 0;
    grouping.forEach((item) => {
      if (conditionIsCategory(item)) {
        totalAbsoluteDegreeOfFulfillmentPotential += +item.absoluteDegreeOfFulfillmentPotential;
      }
    });
    return totalAbsoluteDegreeOfFulfillmentPotential || 0;
  };

  const columns = useMemo(() => {
    const columnsInner = [
      {
        field: "systemReference",
        headerText: t("grouping.systemReference"),
        type: COLUMN_TYPE_TEXT,
        readOnly: true,
        width: 110,
        minWidth: 110,
        search: true,
      },
      {
        field: "name",
        headerText: t("grouping.groupingTitle"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionNotIndicator,
        readOnly: true,
        getIcon: (row) => (row.type === GROUPING_TYPE_CATEGORY ? <CategorySVG /> : null),
        groupingColumn: true,
        minWidth: 200,
        width: 250,
        search: true,
      },
      {
        field: "indicatorName",
        headerText: t("grouping.indicatorName"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionIsIndicator,
        startAdornment: <IndicatorSVG />,
        readOnly: true,
        minWidth: 150,
        width: 200,
        search: true,
      },
      {
        field: "indicatorStatus",
        headerText: t("grouping.status.title"),
        headerTemplate: headerStatusTemplate,
        template: (row) => {
          if (conditionIsIndicatorIncluded(row)) {
            return (
              <div className="flex-row">
                <RatingSystemStatusIndicator row={row} setPopoverTarget={setPopoverTarget} />
                <StatusBadge className={"status-" + (row.indicatorStatus || STATUS_NOT_RATED)}>
                  {t("grouping.status." + (row.indicatorStatus || STATUS_NOT_RATED))}
                </StatusBadge>
              </div>
            );
          }
        },
        editTemplate: (
          row,
          column,
          updateValue,
          submittedEdit,
          { selectChildrenStatus: selectChildrenStatusEdit, selectIndicatorStatus: selectIndicatorStatusEdit }
        ) => {
          if (row.excludedFromCalculation) {
            return null;
          }
          if (conditionIsIndicator(row)) {
            return (
              <div className="flex-row">
                <RatingSystemStatusIndicator row={row} setPopoverTarget={setPopoverTarget} />
                <Select
                  fullWidth
                  value={row.indicatorStatus || STATUS_NOT_RATED}
                  className="indicator-status-select"
                  onChange={(event) => selectIndicatorStatusEdit(event, row.uuid)}
                >
                  {INDICATOR_STATUSES.map((item) => (
                    <MenuItem key={item} value={item} onClick={(event) => event.stopPropagation()}>
                      <StatusBadge className={"status-" + item}>{t("grouping.status." + item)}</StatusBadge>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            );
          } else if (row.indicatorElements.length > 0) {
            return (
              <div className="column-header-addon">
                <Select
                  fullWidth
                  value={""}
                  displayEmpty
                  className="indicator-status-select"
                  onChange={(event) => selectChildrenStatusEdit(event, row.uuid)}
                >
                  <MenuItem key={-1} value="" disabled>
                    {t("grouping.status.changeTo")}
                  </MenuItem>
                  {INDICATOR_STATUSES.map((item) => (
                    <MenuItem key={item} value={item} onClick={(event) => event.stopPropagation()}>
                      <StatusBadge className={"status-" + item}>{t("grouping.status." + item)}</StatusBadge>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            );
          }
        },
        width: 185,
        minWidth: 150,
        search: true,
        searchConfig: getMissingStatusesSearchConfig(t, actionCondition),
      },
      {
        field: "evaluationSystemScore",
        headerText: t("ratingSystem.indicator.evaluationSystemScore"),
        type: COLUMN_TYPE_NUMBER,
        allowEmpty: true,
        readOnly: true,
        className: (row, index, { nodesByUUID: nodesByEvaluationSystemUUID }) => {
          const previous = nodesByEvaluationSystemUUID[row.uuid];
          if (previous && row.type === GROUPING_TYPE_INDICATOR) {
            return row.maxSystemScore !== previous.maxSystemScore ? "danger" : "";
          }
          return "";
        },
        template: (row, _field, passThroughTemplate) => (
          <div className={"text-right " + (isKOFailed(row) ? "validation-failed" : "")}>
            {passThroughTemplate.systemLogic === SYSTEM_LOGIC_DGNB && conditionNotIndicator(row)
              ? row.weightedSystemScore != null
                ? roundDecimal(row.weightedSystemScore, 100)
                : "-"
              : row.evaluationSystemScore != null
              ? roundDecimal(row.evaluationSystemScore, 100)
              : "-"}
          </div>
        ),
        footerTemplate: (row, data, passThroughEvaluationFooter) => (
          <div className="text-right footer-total">{roundDecimal(passThroughEvaluationFooter.score, 100)}</div>
        ),
        width: 125,
        minWidth: 125,
        columnGroup: "current",
      },
      {
        field: "universalScore",
        headerText: t("ratingSystem.indicator.universalScore"),
        type: COLUMN_TYPE_PERCENT,
        allowEmpty: true,
        readOnly: true,
        template: (row) => (
          <div className={"text-right " + (isKOFailed(row) ? "validation-failed" : "")}>
            {+row.universalScore >= 0 ? roundDecimal(row.universalScore, 100) + PERCENT_SIGN : ""}
          </div>
        ),
        footerTemplate: (row, data, passThroughUniversalFooter) => (
          <div className="text-right footer-total">{roundDecimal(passThroughUniversalFooter.superScore, 100)}%</div>
        ),
        width: 125,
        minWidth: 125,
        columnGroup: "current",
      },
      {
        field: "progress",
        readOnly: true,
        template: (row) => (
          <ProgressSquare
            value={!row.excludedFromCalculation ? row.universalScore : undefined}
            rangeColors={row.maxSystemScore ? RANGE_COLORS_ESG : null}
          />
        ),
        width: 80,
        minWidth: 80,
        columnGroup: "current",
      },
      {
        field: "potentialEvaluationSystemScore",
        headerText: t("ratingSystem.indicator.evaluationSystemScore"),
        type: COLUMN_TYPE_NUMBER,
        allowEmpty: true,
        readOnly: true,
        className: (row, index, { nodesByUUID: nodesByPotentialUUID }) => {
          const previous = nodesByPotentialUUID[row.uuid];
          if (previous && row.type === GROUPING_TYPE_INDICATOR) {
            return row.maxSystemScore !== previous.maxSystemScore ? "danger" : "";
          }
          return "";
        },
        template: (row, field, { systemLogic: systemLogicPotential }) => {
          const effectivePotentialEvaluationSystemScore = conditionIsIndicator(row)
            ? row.effectivePotentialEvaluationSystemScore
            : row.potentialEvaluationSystemScore;

          const isPotentialLowerThanCurrent =
            effectivePotentialEvaluationSystemScore != null &&
            !isNaN(effectivePotentialEvaluationSystemScore) &&
            effectivePotentialEvaluationSystemScore < row.evaluationSystemScore;
          const className = "text-right " + (isKOFailed(row) || isPotentialLowerThanCurrent ? "validation-failed" : "");

          return (
            <div className={className}>
              {getPotentialEvaluationSystemScore(
                row,
                systemLogicPotential,
                effectivePotentialEvaluationSystemScore,
                t("ratingSystem.columnGroup.potentialEvaluationSystemScoreESGVisualAid")
              )}
            </div>
          );
        },
        footerTemplate: (row, data, passThroughPotentialFooter) => (
          <div className="text-right footer-total">{roundDecimal(passThroughPotentialFooter.potentialScore, 100)}</div>
        ),
        width: 125,
        minWidth: 125,
        columnGroup: "potential",
      },
      {
        field: "potentialUniversalScore",
        headerText: t("ratingSystem.indicator.universalScore"),
        type: COLUMN_TYPE_PERCENT,
        allowEmpty: true,
        readOnly: true,
        template: (row) => (
          <div
            className={
              "text-right " +
              (isKOFailed(row) ||
              (row.potentialUniversalScore != null &&
                !isNaN(row.potentialUniversalScore) &&
                row.potentialUniversalScore < row.universalScore)
                ? "validation-failed"
                : "")
            }
          >
            {+row.potentialUniversalScore >= 0 ? roundDecimal(row.potentialUniversalScore, 100) + PERCENT_SIGN : ""}
          </div>
        ),
        footerTemplate: (row, data) => (
          <div className="text-right footer-total">{roundDecimal(calculateTotalPercentagePotential(data), 100)}%</div>
        ),
        width: 125,
        minWidth: 125,
        columnGroup: "potential",
      },
      {
        field: "potentialProgress",
        readOnly: true,
        template: (row) => (
          <ProgressSquare
            value={!row.excludedFromCalculation ? row.potentialUniversalScore : undefined}
            rangeColors={row.maxSystemScore ? RANGE_COLORS_ESG : null}
          />
        ),
        width: 80,
        minWidth: 80,
        columnGroup: "potential",
      },
      {
        field: "responsible",
        headerText: t("ratingSystem.columnGroup.responsible"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        readOnly: true,
        hideable: true,
        width: 200,
        minWidth: 110,
        search: true,
      },
      {
        field: "weightedSystemScore",
        headerText: t("grouping.weightedSystemScore"),
        type: COLUMN_TYPE_NUMBER,
        readOnly: true,
        hideable: true,
        template: (row) => <div className="text-right ">{roundDecimal(row.weightedSystemScore)}</div>,
        width: 170,
        minWidth: 170,
        columnGroup: "currentFulfillment",
      },
      {
        field: "degreeOfFulfillment",
        headerText: t("grouping.degreeOfFulfillment"),
        type: COLUMN_TYPE_NUMBER,
        readOnly: true,
        hideable: true,
        template: (row) => (
          <div className={"text-right "}>
            {row.degreeOfFulfillment
              ? onlyDecimals(roundDecimal(row.degreeOfFulfillment), 2) + PERCENT_SIGN
              : ZERO_PERCENT}
          </div>
        ),
        width: 200,
        minWidth: 200,
        columnGroup: "currentFulfillment",
      },
      {
        field: "absoluteDegreeOfFulfillment",
        headerText: t("grouping.absoluteDegreeOfFulfillment"),
        type: COLUMN_TYPE_NUMBER,
        readOnly: true,
        hideable: true,
        template: (row) => (
          <div className={"text-right "}>
            {row.absoluteDegreeOfFulfillment
              ? onlyDecimals(roundDecimal(row.absoluteDegreeOfFulfillment), 2) + PERCENT_SIGN
              : ZERO_PERCENT}
          </div>
        ),
        width: 200,
        minWidth: 200,
        columnGroup: "currentFulfillment",
      },
      {
        field: "weightedPotentialSystemScore",
        headerText: t("grouping.weightedSystemScore"),
        type: COLUMN_TYPE_NUMBER,
        readOnly: true,
        hideable: true,
        template: (row) => <div className="text-right ">{roundDecimal(row.weightedPotentialSystemScore)}</div>,
        width: 170,
        minWidth: 170,
        columnGroup: "potentialFulfillment",
      },
      {
        field: "relativeDegreeOfFulfillmentPotential",
        headerText: t("grouping.relativeDegreeOfFulfillmentPotential"),
        type: COLUMN_TYPE_NUMBER,
        readOnly: true,
        hideable: true,
        template: (row) => (
          <div className={"text-right "}>{getValueOrDash(row.relativeDegreeOfFulfillmentPotential)}</div>
        ),
        width: 215,
        minWidth: 200,
        columnGroup: "potentialFulfillment",
      },
      {
        field: "absoluteDegreeOfFulfillmentPotential",
        headerText: t("grouping.absoluteDegreeOfFulfillmentPotential"),
        type: COLUMN_TYPE_NUMBER,
        readOnly: true,
        hideable: true,
        template: (row) => (
          <div className={"text-right "}>{getValueOrDash(row.absoluteDegreeOfFulfillmentPotential)}</div>
        ),
        width: 215,
        minWidth: 200,
        columnGroup: "potentialFulfillment",
      },
      {
        field: "differenceRelativeDegreeOfFulfillment",
        headerText: t("grouping.differenceRelativeDegreeOfFulfillment"),
        type: COLUMN_TYPE_NUMBER,
        readOnly: true,
        hideable: true,
        template: (row) => (
          <div className={"text-right "}>{getValueOrDash(row.differenceRelativeDegreeOfFulfillment)}</div>
        ),
        width: 215,
        minWidth: 200,
        columnGroup: "differenceScore",
      },
      {
        field: "differenceAbsoluteDegreeOfFulfillment",
        headerText: t("grouping.differenceAbsoluteDegreeOfFulfillment"),
        type: COLUMN_TYPE_NUMBER,
        readOnly: true,
        hideable: true,
        template: (row) => (
          <div className={"text-right "}>{getValueOrDash(row.differenceAbsoluteDegreeOfFulfillment)}</div>
        ),
        width: 215,
        minWidth: 200,
        columnGroup: "differenceScore",
      },
      {
        field: "weightingFactor",
        headerText: t("grouping.weight"),
        required: true,
        type: COLUMN_TYPE_PERCENT,
        step: 0.0001,
        template: WeightingColumnTemplate,
        readOnly: true,
        hideable: true,
        width: 150,
        minWidth: 150,
        columnGroup: "metrics",
      },
      {
        fieldKey: "maxSystemScore",
        getField: (row) => (row.type === GROUPING_TYPE_INDICATOR ? "maxSystemScore" : "maxSystemScoreLimit"),
        headerText: t("grouping.maxSystemScore"),
        getHeaderText: (row) =>
          t(row.type === GROUPING_TYPE_INDICATOR ? "grouping.maxSystemScore" : "grouping.maxSystemScoreLimit"),
        required: false,
        type: COLUMN_TYPE_NUMBER,
        template: MaxSystemScoreTemplate,
        readOnly: true,
        hideable: true,
        step: 0.0001,
        min: 0,
        max: 999,
        width: 145,
        minWidth: 145,
        columnGroup: "metrics",
      },
      {
        field: "maxSystemScoreProportion",
        headerText: t("grouping.maxSystemScoreProportion"),
        type: COLUMN_TYPE_PERCENT,
        template: (row) => (
          <div className="text-right ">
            {+row.maxSystemScoreProportion > 0
              ? onlyDecimals(roundDecimal(row.maxSystemScoreProportion), 2) + PERCENT_SIGN
              : ""}
          </div>
        ),
        allowEmpty: true,
        readOnly: true,
        hideable: true,
        width: 215,
        minWidth: 215,
        columnGroup: "metrics",
      },
      {
        field: "koValue",
        headerText: t("grouping.koValue"),
        required: false,
        type: COLUMN_TYPE_NUMBER,
        allowEmpty: true,
        readOnly: true,
        hideable: true,
        template: (row) => (
          <div className={"text-right " + (isKOFailed(row) ? "validation-failed" : "")}>{row.koValue || ""}</div>
        ),
        step: 1,
        min: 0,
        max: 999,
        width: 120,
        minWidth: 120,
        columnGroup: "metrics",
      },
      {
        field: "weightedMaxSystemScore",
        headerText: t("grouping.weightedMaxSystemScore"),
        type: COLUMN_TYPE_NUMBER,
        readOnly: true,
        hideable: true,
        template: (row) => <div className="text-right ">{roundDecimal(row.weightedMaxSystemScore)}</div>,
        footerTemplate: (field, data, passThroughWeightMaxSystemScore) => (
          <div className="text-right footer-total">
            {t("main.total") +
              ": " +
              roundDecimal(
                passThroughWeightMaxSystemScore.systemLogic === SYSTEM_LOGIC_DGNB
                  ? sumGroupingWeightedMaxSystemScore(data)
                  : calculateGroupingMaxScore(data)
              )}
          </div>
        ),
        width: 200,
        minWidth: 200,
        columnGroup: "metrics",
      },
      !!hasExcludable && {
        field: "excluded",
        type: COLUMN_TYPE_CHECKBOX,
        headerText: t("grouping.excluded"),
        showCondition: (row) => conditionNotCategory(row) && row.excludable,
        editableCondition: conditionNotCategory,
        width: 175,
        minWidth: 175,
        isDisabled: (row) => !!row.parent.excluded,
        onChange: (UID, fieldName, value, { getValues: getValuesExcluded }) => {
          const valuesExcluded = getValuesExcluded();
          console.log(valuesExcluded);
          const updated = [...valuesExcluded];
          if (value) {
            const toSelectByUID = {};
            toSelectByUID[UID] = true;
            let excludedNode = null;
            forEachRecursive(updated, (node, tree, i, level, parent) => {
              if (toSelectByUID[node.UID]) {
                node.excluded = true;
                node.universalScore = 0;
                node.potentialUniversalScore = 0;
              }
              if (node.UID === UID) {
                if (conditionIsCriterion(node) || (conditionIsCriterion(parent) && isExcludedElement(parent))) {
                  excludedNode = node;
                }
              }
            });
            if (excludedNode) {
              showWarning(processMessage(t("grouping.excludedWarning"), [excludedNode.name]));
            }
            onChange(updated);
          }
        },
      },
    ];
    while (columnsInner.some((item) => !item)) {
      const index = columnsInner.findIndex((item) => !item);
      columnsInner.splice(index, 1);
    }
    return columnsInner;
  }, [hasExcludable]);

  const columnGroups = useMemo(
    () => ({
      current: {
        title: t("ratingSystem.columnGroup.currentESG"),
        className: "column-group-current",
      },
      potential: {
        title: t("ratingSystem.columnGroup.potential"),
        className: "column-group-potential",
      },
      metrics: {
        title: t("ratingSystem.columnGroup.metrics"),
        className: "column-group-metrics",
      },
      currentFulfillment: {
        title: t("ratingSystem.columnGroup.currentFulfillment"),
        className: "column-group-current-fulfillment",
      },
      potentialFulfillment: {
        title: t("ratingSystem.columnGroup.potentialFulfillment"),
        className: "column-group-potential-fulfillment",
      },
      differenceScore: {
        title: t("ratingSystem.columnGroup.differenceScore"),
        className: "column-group-difference-score",
      },
    }),
    []
  );

  const searched = useMemo(() => columns.filter((item) => !!item.search && search[item.field]), [search]);

  const getRowClassName = useCallback((row) => {
    return (
      "sustainability-row-" +
      row.type +
      (row.indicatorStatus === STATUS_CLOSED ? " status-closed" : "") +
      (row.excluded ? " excluded" : "") +
      (row.excludedFromCalculation ? " calculation-excluded" : "")
    );
  }, []);

  const changeAllStatuses = (status) => {
    const updated = [...values];
    let error = false;
    let changed = false;
    forEachRecursive(updated, (node) => {
      if (isExcludedElement(node)) {
        return null;
      }
      if (node.type === GROUPING_TYPE_INDICATOR) {
        const setupIndicatorStatusResultTemp = setupIndicatorStatus(node, status);
        if (setupIndicatorStatusResultTemp.changed) {
          changed = true;
        }
        if (setupIndicatorStatusResultTemp.error) {
          error = true;
        }
      }
    });
    if (error) {
      showError(t("ratingSystem.errorStatusChange"));
    }
    if (changed) {
      onChange(updated);
    }
  };

  const changeIndicatorStatus = (uuid, status) => {
    const updated = [...values];
    const node = findRecursive(updated, (item) => item.uuid === uuid);
    if (node.type === GROUPING_TYPE_INDICATOR && uuid === node.uuid) {
      const setupIndicatorStatusResultTemp = setupIndicatorStatus(node, status);
      if (setupIndicatorStatusResultTemp.error) {
        showError(t("ratingSystem.errorStatusChange"));
      } else if (setupIndicatorStatusResultTemp.changed) {
        onChange(updated);
      }
    }
  };

  const changeChildrenStatuses = (parentUuid, status) => {
    const updated = [...values];
    let error = false;
    let changed = false;
    forEachRecursive(updated, (node) => {
      if (isExcludedElement(node)) {
        return null;
      }
      if (node.type === GROUPING_TYPE_INDICATOR && parentUuid === node.parentUuid) {
        const setupIndicatorStatusResultTemp = setupIndicatorStatus(node, status);
        if (setupIndicatorStatusResultTemp.changed) {
          changed = true;
        }
        if (setupIndicatorStatusResultTemp.error) {
          error = true;
        }
      }
    });
    if (error) {
      showError(t("ratingSystem.errorStatusChange"));
    }
    if (changed) {
      onChange(updated);
    }
  };

  const expandCondition = useCallback(
    (node) =>
      isIncludedElement(node) && node.kpi && node.kpi.currentValue && !node.kpi.estimation && node.kpi.estimation !== 0,
    [errors]
  );

  const collapseCondition = (node) => node.excluded;

  if (reset) {
    return null;
  }

  return (
    <div className="rating-system-grouping">
      {!!searched.length && (
        <div className="filters">
          {searched.map((item, index) =>
            (typeof search[item.field].data === "object" && search[item.field].data.length > 0) ||
            (typeof search[item.field].data === "string" && search[item.field].data) ? (
              <SearchBadge
                key={index}
                field={item.headerText}
                value={search[item.field].data}
                onClose={() => setSearch({ ...search, [item.field]: undefined })}
              />
            ) : null
          )}
          <Button className="clear-filters" onClick={() => setSearch({})}>
            {t("main.clear")}
          </Button>
        </div>
      )}
      <AdvancedTable
        key={hasExcludable}
        data={values}
        errorMessage={errorMessage}
        previousData={previousValues}
        expandRowsByCondition={true}
        expandRowCondition={expandCondition}
        collapseRowsByCondition={true}
        collapseRowCondition={collapseCondition}
        errorMarkRowsByCondition={true}
        errorMarkRowCondition={expandCondition}
        getNodeChangedFields={getNodeChangedFields}
        onChange={onChange}
        getRowClassName={getRowClassName}
        groupingField={"children"}
        onSearch={setSearch}
        search={search}
        columns={columns}
        sortRows={sortGroupings}
        onRowSelect={setSelectedRow}
        selectedRow={selectedRow}
        submitted={submitted}
        readOnly={readOnly}
        passThrough={passThrough}
        disableRowDrag
        disableHoverControls
        columnGroups={columnGroups}
        disableColumnLock
        hiddenColumns={hiddenColumns}
        setHiddenColumns={updateHiddenColumns}
      />
      <RatingSystemStatusIndicatorPopover
        setPopoverTarget={setPopoverTarget}
        popoverTarget={popoverTarget}
        actionCondition={actionCondition}
      />
    </div>
  );
});
