import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { PromiseSubjectState } from "react-rxjs-easy";
import { GROUPING_TYPE_CRITERION } from "../../../constants/sustainabilitySystem";
import { indicatorActionsListSubject } from "../../../hooks/action";
import { showDialog } from "../../../hooks/dialog";
import { forEachRecursive } from "../../../utils";
import { AddActionsDialog } from "../../AddActionsDialog/AddActionsDialog";
import { CollapsiblePanel } from "../../CollapsiblePanel/CollapsiblePanel";
import { ActionsBlock } from "../../RatingSystem/IndicatorElementDockBlocks/ActionsBlock";

export const GroupingElementDock = ({ rows, data, readOnly, onChange }) => {
  const { t } = useTranslation();
  const { name } = data || {};

  const showAddActionsDialog = (row, passThrough) => {
    const { readOnly: readOnlyInner } = passThrough || {};
    showDialog({
      className: "xlarge",
      closeOnClickOutside: !!readOnlyInner,
      getContent: (onClose) => (
        <AddActionsDialog
          row={row}
          onClose={onClose}
          readOnly={readOnlyInner}
          onChange={onActionsChange}
          grouping={rows}
          changeGrouping={onChange}
        />
      ),
    });
  };

  const onActionsChange = ({ UID, actions = [] }) => {
    const updated = [...rows];
    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        node.actions = actions;
        return true;
      }
    });
    onChange(updated);
    indicatorActionsListSubject.next(new PromiseSubjectState(actions));
  };

  return (
    <div className="grouping-element-dock">
      <div className="data-block white-block">
        <h3>{name}</h3>
      </div>

      {data.type === GROUPING_TYPE_CRITERION && (
        <div className="data-block">
          <CollapsiblePanel
            header={
              <h3>
                {t("ratingSystem.columnGroup.actions")}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    showAddActionsDialog(data, { readOnly });
                  }}
                  size="small"
                >
                  <DescriptionOutlinedIcon />
                </IconButton>
              </h3>
            }
          >
            <ActionsBlock row={data} readOnly={readOnly} onChange={onActionsChange} />
          </CollapsiblePanel>
        </div>
      )}
    </div>
  );
};
