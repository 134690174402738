import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ACTION_CUSTOM } from "../../constants/action";
import { useIndicatorActionListResponse } from "../../hooks/action";
import { resetUploadingFiles } from "../../hooks/fileUpload";
import { showError } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { forEachRecursive, processMessage, substrIfLenghty } from "../../utils";
import { getDuplicatedAction, validateIndicatorActionsRequired } from "../../validation/action";
import { AddActionsTable } from "../Actions/ActionsTable/AddActionsTable";
import { LoadingOverlay } from "../LoadingOverlay/LoadingOverlay";
import "./AddActionsDialog.scss";

const updateAction = (node, actionsUpdate) => {
  let actionsChanged = 0;

  actionsUpdate.forEach((sourceAction) => {
    node.actions.forEach((destinationAction) => {
      if (
        !!sourceAction.name &&
        (sourceAction.name === destinationAction.name ||
          (sourceAction.actionId && destinationAction.actionId && sourceAction.actionId === destinationAction.actionId))
      ) {
        for (const key in destinationAction) destinationAction[key] = undefined;
        for (const key in sourceAction) destinationAction[key] = sourceAction[key];

        actionsChanged++;
      }
    });
  });
  return actionsChanged;
};

export const AddActionsDialog = ({ onClose, onChange, row, readOnly, title, grouping, changeGrouping }) => {
  const { t } = useTranslation();

  const response = useIndicatorActionListResponse();
  const data = row.actions || response.data || [];

  const [actions, setActions] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setActions(
      data.map((item) => ({
        ...item,
        source: item.source || ACTION_CUSTOM,
        UID: item.id,
      }))
    );
    setSubmitted(false);
  }, [data]);

  const updateActions = useCallback(
    (actionsUpdate) => {
      if (actionsUpdate?.length) {
        let actionsChanged = 0;
        forEachRecursive(grouping, (node) => {
          if (node?.actions?.length && node.UID !== row.UID) actionsChanged += updateAction(node, actionsUpdate);
        });
        if (actionsChanged) {
          const updated = [...grouping];
          changeGrouping(updated);
        }
      }
    },
    [grouping, changeGrouping, row]
  );

  const onSave = useEvent(() => {
    const requiredValid = validateIndicatorActionsRequired(actions);
    const duplicated = getDuplicatedAction(actions);
    if (requiredValid && !duplicated) {
      onClose();
      onChange({ UID: row.UID, actions });
      updateActions(actions);
      setSubmitted(false);
    } else {
      setSubmitted(true);
      if (!requiredValid) {
        showError(t("actions.requiredValidationFailed"));
      } else if (duplicated) {
        showError(processMessage(t("actions.uniqueValidationFailed"), [duplicated.name]));
      }
    }
  });

  const name = useMemo(() => {
    if (title) return title?.substring(0, 25);
    return row.indicatorName ? row.indicatorName?.substring(0, 25) : row.name?.substring(0, 25);
  }, [row, title]);

  return (
    <LoadingOverlay spinner className="indicator-actions-dialog auto-height">
      <h2>{substrIfLenghty(name, 25, "...") + " " + t("ratingSystem.actions.modalTitle")}</h2>

      <div className="dialog-body">
        <AddActionsTable
          data={actions}
          readOnly={readOnly}
          onChange={setActions}
          submitted={submitted}
          sustainabilityIndicatorElementUuid={row.sustainabilityIndicatorElementUuid}
          originIndicatorElementUuid={row.originIndicatorElementUuid}
          originGroupingElementUuid={row.originGroupingElementUuid}
          sustainabilityGroupingElementUuid={row.sustainabilityGroupingElementUuid}
          kpiSystemElementUuid={row.kpiSystemElementUuid}
          grouping={grouping}
        />
      </div>

      <div className="buttons text-right">
        {!!readOnly && <Button onClick={() => onClose()}>{t("main.close")}</Button>}
        {!readOnly && (
          <>
            <Button
              onClick={() => {
                resetUploadingFiles();
                onClose();
              }}
            >
              {t("main.cancel")}
            </Button>
            <Button color="primary" onClick={onSave}>
              {t("main.save")}
            </Button>
          </>
        )}
      </div>
    </LoadingOverlay>
  );
};

AddActionsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  grouping: PropTypes.array.isRequired,
  changeGrouping: PropTypes.func.isRequired,
};
