import Button from "@mui/material/Button";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./KPIDataDialog.scss";

import { resetUploadingFiles, useUploadingFiles } from "../../../hooks/fileUpload";
import { addKPIFile, removeKPIFile, setKPIFiles, uploadKPIFile, useKPIFiles } from "../../../hooks/kpiSystem";
import { FilesList } from "../../FilesList/FilesList";
import { LinksList } from "../../LinksList/LinksList";
import { LoadingOverlay } from "../../LoadingOverlay/LoadingOverlay";

export const KPIDataDialog = ({ onClose, onChange, row, readOnly, kpiSystemId }) => {
  const { t } = useTranslation();
  const data = row || {};
  const { UID } = data;
  const [links, setLinks] = useState([]);
  const files = useKPIFiles();
  const uploadingFiles = useUploadingFiles();

  useEffect(() => {
    setKPIFiles(data.files || []);
    setLinks(data.links || []);
    resetUploadingFiles();
  }, [UID]);

  const getFilePath = useCallback(
    (fileId) => {
      return `/api/kpi/system/${kpiSystemId}/file/${fileId}/download`;
    },
    [UID, kpiSystemId]
  );

  const onSave = () => {
    onChange(UID, links, files);
    onClose();
  };

  const postFilesLoading = !!uploadingFiles.length;

  return (
    <LoadingOverlay spinner className="kpi-data-dialog auto-height">
      <div className="dialog-head text-center">
        <h2>{!data?.kpiName ? t("grouping.kpiDialogHeader") : `${data?.kpiName} (${data?.kpiUnit})`}</h2>
      </div>
      <div className="dialog-body">
        <LinksList links={links} onChange={setLinks} readOnly={readOnly} />
        <FilesList
          files={files}
          readOnly={readOnly}
          addFile={addKPIFile}
          removeFile={removeKPIFile}
          uploadFile={uploadKPIFile}
          getFilePath={getFilePath}
        />
      </div>

      <div className="buttons text-right">
        {!!readOnly && <Button onClick={() => onClose()}>{t("main.close")}</Button>}
        {!readOnly && (
          <>
            <Button
              onClick={() => {
                resetUploadingFiles();
                onClose();
              }}
            >
              {t("main.cancel")}
            </Button>
            <Button color="primary" onClick={onSave} disabled={postFilesLoading}>
              {t("main.save")}
            </Button>
          </>
        )}
      </div>
    </LoadingOverlay>
  );
};
