import { EditorUtils } from "@progress/kendo-react-editor";
import i18n from "i18next";
import { NodeSelection } from "prosemirror-state";
import { insertPoint } from "prosemirror-transform";
import { v4 as getUUID } from "uuid";
import { toDataURL } from "../../helpers/image";
import { addImageSource, getImageCountFromMap, setImageLock } from "../../hooks/richtext";
import { showError } from "../../hooks/toast";
import { DOC_NODE_NAME, IMAGE_CONTAINER_NODE_NAME, IMAGE_NODE_NAME, PARAGRAPH_NODE_NAME } from "./constants";
import { selectedImageSubject } from "./plugins/ImageHandlingPlugin";

export const ONLOAD_EVENT_HANDLER =
  "window.dispatchEvent(new CustomEvent('rich-text-image-onload', { detail: { event } }))";
export const ONERROR_EVENT_HANDLER =
  "window.dispatchEvent(new CustomEvent('rich-text-image-onerror', { detail: { event } }))";

const insertImageContainer = (view, position, image, imageContainerNodeType) => {
  const imageContainer = imageContainerNodeType.create({ uid: getUUID() }, image);

  if (position) {
    view.dispatch(view.state.tr.insert(position, imageContainer));
  } else {
    EditorUtils.insertNode(view, imageContainer, true);
  }
};

const replaceImageGridCell = (view, selectedImage, image) => {
  const { pos } = selectedImage;
  setSelectionToPos(view, pos);
  image.attrs.style = selectedImage.imageGridNode.attrs.style
    .replace(/width:\s?(.*?);/, "width: 100%;")
    .replace(/height:\s?(.*?);/, "height: auto;");
  view.dispatch(view.state.tr.replaceSelectionWith(image));
};

export const insertImageFile = ({ view, fileUrl, position, richTextConfiguration, uid, attrs = {} }) => {
  const imageCount = getImageCountFromMap(uid) ?? 0;
  const imageContainerNodeType = view.state.schema.nodes[IMAGE_CONTAINER_NODE_NAME];
  const imageNodeType = view.state.schema.nodes[IMAGE_NODE_NAME];

  position = position ? view.state.doc.resolve(position.pos) : view.state.selection.$head;
  let nextValidPosition = insertPoint(view.state.doc, position.pos, imageContainerNodeType);
  nextValidPosition = !nextValidPosition
    ? insertPoint(view.state.doc, position.after(), imageContainerNodeType)
    : nextValidPosition;
  const selectedImage = selectedImageSubject.getValue();

  if (
    (richTextConfiguration?.imageQuantityLimit < 0 || imageCount < richTextConfiguration?.imageQuantityLimit) &&
    (EditorUtils.canInsert(view.state, imageContainerNodeType) || nextValidPosition !== null)
  ) {
    toDataURL(fileUrl).then((imageSource) => {
      const image = imageNodeType.create({
        ...attrs,
        uid: getUUID(),
        onload: ONLOAD_EVENT_HANDLER,
        onerror: ONERROR_EVENT_HANDLER,
        src: imageSource.data ?? "",
        empty: false,
      });

      if (selectedImage?.imageGridNode) {
        replaceImageGridCell(view, selectedImage, image);
      } else {
        insertImageContainer(view, nextValidPosition, image, imageContainerNodeType);
      }

      addImageSource(uid, imageSource);
    });
  } else if (imageCount === richTextConfiguration?.imageQuantityLimit) {
    showError(i18n.t("richtext.fileQuantityError") + richTextConfiguration?.imageQuantityLimit);
  }

  setImageLock(false);
};

export const insertText = ({ view, text }) => {
  const { schema } = view.state;

  const docContent = text.split("\n").map((x) => {
    const content = x.replace("\r", "");
    const contentList = content.length ? [schema.text(content)] : [];
    return schema.node(PARAGRAPH_NODE_NAME, null, contentList);
  });

  let doc = schema.node(DOC_NODE_NAME, null, docContent);
  view.dispatch(view.state.tr.replaceSelectionWith(doc));
};

/**
 * @param {EditorView} view - EditorView of the editor
 * @param {number} pos - Position to change the selection to
 * @returns {Transaction} Transaction to change selection to desired position
 */
export const setSelectionToPos = (view, pos) => {
  const selection = new NodeSelection(view.state.doc.resolve(pos));
  const selectionTransaction = view.state.tr.setSelection(selection);
  view.dispatch(selectionTransaction);
  return selectionTransaction;
};

export const getModifiedNodes = (modifiedDoc, previousDoc) =>
  modifiedDoc.content.content.filter((node) => !previousDoc.content.content.includes(node));
export const getDeletedNodes = (modifiedDoc, previousDoc) =>
  previousDoc.content.content.filter((node) => !modifiedDoc.content.content.includes(node));

const createPath = (child, parent, pos, path = []) => {
  const previous = path.at(-1);
  if (previous?.node.eq(parent)) {
    path.push({
      node: child,
      pos,
    });
  } else if (path.length) {
    let index = null;
    const foundNode = path.find((x, i) => {
      if (x.node.eq(parent)) {
        index = i;
      }

      return index != null;
    });

    if (foundNode && index != null) {
      path.splice(index + 1, path.length);
      path.push({
        node: child,
        pos,
      });
    } else {
      path.splice(index, path.length);
      path.push(
        {
          node: parent,
          pos,
        },
        {
          node: child,
          pos,
        }
      );
    }
  } else {
    path.push(
      {
        node: parent,
        pos,
      },
      {
        node: child,
        pos,
      }
    );
  }
};

export const findNode = (node, expression) => {
  let result = null;

  if (node) {
    const path = [];
    node.descendants((child, pos, parent, index) => {
      if (result) {
        return false;
      } else if (expression(child, pos, parent, index)) {
        result = {
          node: child,
          pos,
          path,
        };
      }

      createPath(child, parent, pos, path);
    });
  }

  return result;
};

export const isNotEmpty = (html) => /<.*?>([^<>]+?)<\/.*?>/im.test(html);
