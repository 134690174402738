import { toDataURLParallel } from "./image";

const IMAGE_TAG_REGEX = /<img[^>]*>/gm

const IMAGE_SRC_ATTR_REGEX = /src="(?<src>[^"]*)/m;

const IMAGE_EMPTY_ATTR_REGEX = /empty="true"/m;

const RGB_STYLE_REGEX = /(?<color>color:\srgb)a(?<red>\(\d{1,3},)(?<green>\s\d{1,3},)(?<blue>\s\d{1,3}),\s1/gm;

const SPAN_TAG_REGEX = /<span style="[^"]*/gm;

const BREAK_LINE_TAG_REGEX = /<(\/ul|\/ol|\/h1|\/h2|\/imagecontainer|\/imagegrid|\/p(?!><\/li))>/gm;

const IMAGE_CONTAINER_REGEX = /<imagecontainer.*?>/gm;
const IMAGE_GRID_CELL_REGEX = /<imagegridcell.*?><img.*?>/gm;

const RGB_REPLACE_FORMAT = '$<color>$<red>$<green>$<blue>';

const IMAGE_HTTP_START = 'http';

const BREAK_LINE_REPLACE_FORMAT = "$&\r\n";

const SVG_RICH_TEXT_SPINNER = "<svg class=\"rich-text-spinner\" fill=\"#002965\" width=\"256\" height=\"256\" viewBox=\"0 0 32 32\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M16 1.25a.75.75 0 0 0 0 1.5c7.318.001 13.25 5.933 13.25 13.251a13.21 13.21 0 0 1-3.881 9.37.75.75 0 1 0 1.062 1.059 14.704 14.704 0 0 0 4.318-10.428c0-8.146-6.604-14.751-14.75-14.751z\"/></svg>";

const SVG_ERROR_404 = "<svg class=\"rich-text-404\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 512 512\" style=\"enable-background:new 0 0 512 512\" xml:space=\"preserve\"><circle style=\"fill:#e04f5f\" cx=\"256\" cy=\"256\" r=\"256\"/><path style=\"fill:#fff\" d=\"M147.592 316v-34.496h-61.48v-16.728l55.416-84.688h30.32v81.968h17.568v19.448h-17.568V316h-24.256zm0-53.944V225.04c0-7.736.208-15.68.832-23.632h-.832c-4.176 8.576-7.736 15.472-11.912 23l-24.88 37.224-.208.416h37v.008zm151.384-14.848c0 43.696-17.144 71.088-49.552 71.088-31.368 0-48.096-28.44-48.304-69.832 0-42.24 17.984-70.672 49.768-70.672 32.824-.008 48.088 29.264 48.088 69.416zm-71.928 1.256c-.208 33.04 8.992 50.176 23.208 50.176 15.056 0 23-18.4 23-51.016 0-31.576-7.52-50.184-23-50.184-13.8 0-23.424 16.936-23.208 51.024zM371.736 316v-34.496h-61.48v-16.728l55.416-84.688h30.32v81.968h17.568v19.448h-17.568V316h-24.256zm0-53.944V225.04c0-7.736.208-15.68.832-23.632h-.832c-4.176 8.576-7.736 15.472-11.912 23l-24.88 37.224-.208.416h37v.008z\"/></svg>";

const IMAGE_INNER_SVG_PATTERN = `$&${SVG_RICH_TEXT_SPINNER}${SVG_ERROR_404}`;

export const adjustRGBFormat = (html) => {
    if (!html) { 
        return '';
    }

    let htmlResult = html;   
    const matches = htmlResult.matchAll(SPAN_TAG_REGEX);

    for (const match of matches) {
        let result = match[0];

        result = result.replace(RGB_STYLE_REGEX, RGB_REPLACE_FORMAT);

        htmlResult = htmlResult.replace(match[0], result);
    }
    
    return htmlResult;
}

export const replaceImageData = (html, imageSource) => {
    if (!html) { 
        return '';
    }

    let htmlResult = html;
    const matches = htmlResult.match(IMAGE_TAG_REGEX);

    matches?.forEach((imgTag) => { 
        let srcAttr = imgTag.match(IMAGE_SRC_ATTR_REGEX);
        if (srcAttr && !srcAttr.groups?.src.startsWith(IMAGE_HTTP_START) && !IMAGE_EMPTY_ATTR_REGEX.test(imgTag)) { 
            let url = imageSource[srcAttr.groups.src];
            htmlResult = htmlResult.replace(srcAttr.groups.src, url);
        }
    }); 

    return htmlResult;
}

export const replaceImageUrl = (html) => new Promise((resolve, reject) => {
    if (!html) { 
        resolve({ html: '', count: 0, imageSource: {} });
    }
    
    let htmlResult = html;
    const matches = htmlResult.match(IMAGE_TAG_REGEX);
    const imageSource = {};
    let images = matches?.filter(imgTag => imgTag && !IMAGE_EMPTY_ATTR_REGEX.test(imgTag))
        .map(imgTag => imgTag.match(IMAGE_SRC_ATTR_REGEX)?.groups?.src);
        
    images = new Set(images);

    toDataURLParallel(images).then((sources) => {
        sources.forEach(source => {
            imageSource[source.data] = source.url;
            htmlResult = htmlResult.replaceAll(source.url, source.data);
        });

        resolve({ html: htmlResult, count: (matches?.length ?? 0), imageSource });
    }).catch(reject);
});
    
export const addBreakLines = (html) => html.replace(BREAK_LINE_TAG_REGEX, BREAK_LINE_REPLACE_FORMAT);
export const removeBreakLines = (html) => html?.replace(/[\r\n]/gm, '');
export const removeExtraBreakLines = (html) => html.replace(/^\r?\n$/gm, "");

const addSVGToImageContainer = (html) => html.replace(IMAGE_CONTAINER_REGEX, IMAGE_INNER_SVG_PATTERN);
const addSVGToImageGridCell = (html) => html.replace(IMAGE_GRID_CELL_REGEX, IMAGE_INNER_SVG_PATTERN);
export const addSVGToImages = (html) => addSVGToImageGridCell(addSVGToImageContainer(html));

export const show404Image = (target) => {
    if (target) {
        target.parentElement.classList.add('rich-text-image-error');
    }
}

export const hideSpinnerImage = (target) => {
    if (target) { 
        target.parentElement.classList.add('rich-text-image-loaded');
    }
}