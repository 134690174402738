export const ProjectsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="icon-project" transform="translate(1 1)">
      <path
        id="Path_43"
        data-name="Path 43"
        d="M0,0H30V30H0Z"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        opacity="0"
      />
      <path
        id="Path_44"
        data-name="Path 44"
        d="M6.333,4H13l5,5H29.667A3.333,3.333,0,0,1,33,12.333V25.667A3.333,3.333,0,0,1,29.667,29H6.333A3.333,3.333,0,0,1,3,25.667V7.333A3.333,3.333,0,0,1,6.333,4"
        transform="translate(-3 -1.5)"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
);
