import i18n from "i18next";
import { DASH, PERCENT_SIGN } from "../../constants/main";
import { PERMISSION_SUPER_ADMIN } from "../../constants/permissions";
import { MAX_NUMBER_VALUE } from "../../constants/project";
import { STATUS_CLOSED, STATUS_RATED } from "../../constants/ratingSystem";
import {
  GROUPING_TYPE_CATEGORY,
  GROUPING_TYPE_CRITERION,
  GROUPING_TYPE_INDICATOR,
  SYSTEM_LOGIC_DGNB,
  SYSTEM_LOGIC_STANDARD,
} from "../../constants/sustainabilitySystem";
import { TENANT_STATUS_ENABLED } from "../../constants/tenant";
import { hasPermission } from "../../helpers/permission";
import { userSubject } from "../../hooks/auth";

export const forEachRecursive = (tree, fn, childrenPropName = "children", level = 0, parent = null, path = []) => {
  const items = tree || [];
  let isBreak = false;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if ("function" === typeof fn) {
      isBreak = fn(item, tree, i, level, parent, [...path, i]);
      if (!isBreak && item[childrenPropName]) {
        isBreak = forEachRecursive(item[childrenPropName], fn, childrenPropName, level + 1, item, [...path, i]);
      }
    }
    if (isBreak) {
      break;
    }
  }
  return isBreak;
};

export const forEachRecursiveReverse = (
  tree,
  fn,
  childrenPropName = "children",
  level = 0,
  parent = null,
  path = []
) => {
  const items = tree || [];
  let isBreak = false;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if ("function" === typeof fn) {
      if (item[childrenPropName]) {
        isBreak = forEachRecursiveReverse(item[childrenPropName], fn, childrenPropName, level + 1, item, [...path, i]);
      }
      if (!isBreak) {
        isBreak = fn(item, tree, i, level, parent, [...path, i]);
      }
    }
    if (isBreak) {
      break;
    }
  }
  return isBreak;
};

export const forEachRecursiveReverseStrict = (tree, fn, childrenPropName = "children") => {
  const levels = [];
  forEachRecursive(
    tree,
    (node, nodes, index, level, parent, path) => {
      if (!levels[level]) {
        levels[level] = [];
      }
      levels[level].push({ node, nodes, index, level, parent, path });
    },
    childrenPropName
  );
  let isBreak = false;
  levels.toReversed().forEach((level) => {
    if (!isBreak) {
      level.forEach(({ node, nodes, index, level: levelInner, parent, path }) => {
        if (!isBreak) {
          isBreak = fn(node, nodes, index, levelInner, parent, [...path, index]);
        }
      });
    }
  });
  return isBreak;
};

export const filterRecursive = (tree, fn, childrenPropName = "children") => {
  const items = [...(tree || [])];
  if ("function" !== typeof fn) {
    return items;
  }

  const res = items.filter(fn);
  for (const element of res)
    element[childrenPropName] = filterRecursive(element[childrenPropName], fn, childrenPropName);

  return res;
};

export const findRecursive = (tree, fn, childrenPropName = "children") => {
  if (!tree || !fn || typeof fn !== "function") {
    return null;
  }

  // Use a stack-based iterative approach instead of recursion
  const stack = [...tree];
  while (stack.length > 0) {
    const node = stack.pop();
    if (fn(node)) {
      return node;
    }
    if (node[childrenPropName]) {
      stack.push(...node[childrenPropName]);
    }
  }
  return null;
};

export const mapRecursive = (tree, fn, childrenPropName = "children") => {
  return (tree || []).map((item) => {
    if ("function" !== typeof fn) {
      return item;
    }
    let children = [];
    if (item[childrenPropName]) {
      children = mapRecursive(item[childrenPropName], fn, childrenPropName);
    }
    return fn(item, children, tree);
  });
};

export const findParentNode = (node, selector) => {
  if (node.tagName.toLowerCase() === "body") {
    return null;
  }
  const found = node.matches(selector) ? node : null;
  if (found) {
    return found;
  } else {
    return findParentNode(node.parentNode, selector);
  }
};

export const getOffsetLeftRecursive = (node) => {
  if (!node.tagName || node.tagName.toLowerCase() === "body") {
    return node.offsetLeft || 0;
  } else {
    return node.offsetLeft + (node.offsetParent ? getOffsetLeftRecursive(node.offsetParent) : 0);
  }
};

export const getOffsetTopRecursive = (node) => {
  if (!node.tagName || node.tagName.toLowerCase() === "body") {
    return node.offsetTop || 0;
  }
  return node.offsetTop + (node.offsetParent ? getOffsetTopRecursive(node.offsetParent) : 0);
};

export const areArraysIntersect = (a1, a2) => {
  const a1Keys = {};
  a1.forEach((key) => (a1Keys[key] = true));
  for (const element of a2) {
    if (a1Keys[element]) {
      return true;
    }
  }
  return false;
};

export const roundDecimal = (value, ratio = 10000) => {
  const numberValue = +value;
  if (isNaN(numberValue)) {
    return 0;
  }
  return Math.round(numberValue * ratio) / ratio;
};

export const getArraysIntersection = (a1, a2) => a1.filter((n) => a2.indexOf(n) !== -1);

export const min = (value, minValue = 0) => (value < minValue ? minValue : value);
export const max = (value, maxValue = 0) => (value > maxValue ? maxValue : value);
export const decimals = (value, decimalsAmount = 0) =>
  decimalsAmount && value ? Number(value).toFixed(decimalsAmount) : value;
export const onlyDecimals = (value, decimalsAmount = 0) =>
  decimalsAmount && value && value % 1 !== 0 ? Number(value).toFixed(decimalsAmount) : value;
export const minMaxDecimals = (value, minValue, maxValue, decimalsAmount) =>
  value === 0 ? "" : decimals(max(min(value, minValue), maxValue), decimalsAmount);

export const processNumberValue = (value, maxValue) => minMaxDecimals(+value, 0, maxValue ?? MAX_NUMBER_VALUE, 2);

export const findInValue = (value, search) => String(value).toLowerCase().indexOf(String(search).toLowerCase()) !== -1;

export const localeString = (number, fractionDigits) => {
  const numberValue = +number;
  if (isNaN(numberValue)) {
    return "0";
  }
  return Number(numberValue).toLocaleString(i18n.language, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
};

export const substrIfLenghty = (string, maxLength, string2ConcatifLengthy) => {
  return ("" + string)?.length > maxLength ? string.substring(0, maxLength) + string2ConcatifLengthy : string;
};

export const disableInputNumberMouseWheel = () => {
  document.addEventListener("wheel", function () {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });
};

export const difPreviousWithCurrent = (prev, curr) => {
  const res = {};
  Object.keys(curr).forEach((key) => {
    if (curr[key] !== prev[key]) {
      res[key] = { curr: curr[key], prev: prev[key] };
    }
  });
  return res;
};

/**
 * Transform the API response in the object used by the azure map component - ProjectsMap.js
 * it processes the following object: [ { id: 1, name: "name", latitude: "1", longitude: "2" } ]
 * @param {*} apiResponse
 * @returns
 */
export const getMapCoordinatesFromAPIResponse = (apiResponse) => {
  const features = [];
  let shouldPush = true;

  apiResponse?.forEach((e) => {
    const longitude = e?.customFields?.location__longitude || e?.longitude;
    const latitude = e?.customFields?.location__latitude || e?.latitude;
    if (!longitude || !latitude) {
      return;
    }
    const existingMapMarker = features?.find(
      (f) =>
        f.geometry.coordinates[0] === +longitude && f.geometry.coordinates[1] === +latitude && f.properties.id !== e.id
    );
    if (existingMapMarker) {
      shouldPush = false;
      existingMapMarker.properties["projsOnSameLatLong"] = existingMapMarker.properties["projsOnSameLatLong"]
        ? existingMapMarker.properties["projsOnSameLatLong"]
        : [];
      existingMapMarker.properties["projsOnSameLatLong"].push(e.id);

      // the number to be shown in the pin if not a cluster.
      // Currently, we are summing up the number of projects
      existingMapMarker.properties["pinNumber"] = existingMapMarker.properties["pinNumber"]
        ? 1 + existingMapMarker.properties["pinNumber"]
        : 2; // 1 + existing currently else 2 (first one found + incoming one)
    }

    shouldPush &&
      features.push({
        type: "Feature",
        geometry: { type: "Point", coordinates: [+longitude, +latitude] },
        properties: { id: e.id, Name: `${e.name}`, pinNumber: "" },
      });
    shouldPush = true;
  });
  return {
    type: "FeatureCollection",
    features: features,
  };
};

export const setupIndicatorStatus = (node, status) => {
  let changed = false;
  let error = false;
  if (status === STATUS_RATED || status === STATUS_CLOSED) {
    if ((!node.evaluationSystemScore && node.evaluationSystemScore !== 0) || !node.reason) {
      error = true;
      return { changed: false, error };
    }
  }
  if (node.indicatorStatus !== status) {
    node.indicatorStatus = status;
    changed = true;
  }
  return { changed, error };
};

export const isExcludedElement = (node) =>
  node.excluded ||
  (node.children?.length && node.children?.every((item) => isExcludedElement(item) || item.excludedFromCalculation));

export const isExcludedGroupingElement = (node) =>
  node.excluded ||
  node.parent?.excluded ||
  (!!node.groupingElements?.length && !node.groupingElements?.some((item) => !isExcludedGroupingElement(item))) ||
  (!!node.indicatorElements?.length && !node.indicatorElements?.some((item) => !item.excluded));

export const isIncludedElement = (node) => !isExcludedElement(node);

export const getIndicatorsWithActionsById = (groupings) => {
  const indicatorsById = {};
  const parentsWithActionsById = {};
  forEachRecursive(groupings || [], (item, tree, i, level, parent) => {
    if (item?.actions.length || (parent && parentsWithActionsById[parent.id])) {
      if (item.type === GROUPING_TYPE_INDICATOR) {
        indicatorsById[item.id] = true;
      } else {
        parentsWithActionsById[item.id] = true;
      }
    }
  });
  return indicatorsById;
};

export const potentialEvaluationSystemScoreESGVisualAid = (row) => {
  const hasActions = row.actions?.length > 0;
  return (
    (!!row.potentialEvaluationSystemScore || row.potentialEvaluationSystemScore === 0) &&
    row.type === GROUPING_TYPE_INDICATOR &&
    row.potentialEvaluationSystemScore !== row.evaluationSystemScore &&
    !hasActions
  );
};

export const getScrollbarWidth = () => {
  const scrollDiv = window.document.createElement("div");
  const scrollDivStyle = scrollDiv.style;
  scrollDivStyle.width = "100px";
  scrollDivStyle.height = "100px";
  scrollDivStyle.overflow = "scroll";
  scrollDivStyle.position = "absolute";
  scrollDivStyle.top = "-9999px";
  window.document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  window.document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

export const processMessage = (message, values) => {
  let res = message;
  if (values) {
    Object.keys(values).forEach((key) => {
      res = res.split("{" + key + "}").join(values[key]);
    });
  }
  return res;
};

export const deepCopy = (obj) => {
  return !!obj && "object" === typeof obj ? JSON.parse(JSON.stringify(obj)) : obj;
};

export const getUserAvailableTenantsForMigration = (user) => {
  return (
    user?.availableTenants?.filter(
      (m) => !!m.id && m.id !== user.tenant.id && m.status === TENANT_STATUS_ENABLED && m.type === user.tenant.type
    ) || []
  );
};

export const getElementByMatchingField = (valueToMatch, elements, matchingField) => {
  return findRecursive(elements, (node) => node[matchingField] === valueToMatch);
};

export const getElementChidrenByMatchingField = (valueToMatch, elements, matchingField) => {
  if (elements.groupingElements?.length > 0) {
    return getElementByMatchingField(valueToMatch, elements.groupingElements, matchingField);
  }
  if (elements.indicatorElements?.length > 0) {
    return getElementByMatchingField(valueToMatch, elements.indicatorElements, matchingField);
  }
  if (elements.children?.length > 0) {
    return getElementByMatchingField(valueToMatch, elements.children, matchingField);
  }
};

export const getNodeRelativeDegreeOfFulfillment = (node, systemLogic) => {
  switch (node.type) {
    case GROUPING_TYPE_INDICATOR:
      return getIndicatorRelativeDegreeOfFulfillment(node);
    case GROUPING_TYPE_CATEGORY: {
      let result = getCategoryRelativeDegreeOfFulfillment(node);
      return systemLogic === SYSTEM_LOGIC_DGNB ? Math.min(result, 100) : result;
    }
    case GROUPING_TYPE_CRITERION:
      return getCriterionRelativeDegreeOfFulfillment(node);
    default:
      console.error(`relative degree of fulfillment for node type ${node.type} not yet implemented`);
      break;
  }
};

export const getIndicatorRelativeDegreeOfFulfillment = (node) => {
  if (node.evaluationSystemScore > 0) {
    return (node.evaluationSystemScore / node.maxSystemScore) * 100 || 0;
  } else {
    return 0;
  }
};

export const getCategoryRelativeDegreeOfFulfillment = (node) => {
  const { weightedMaxSystemScore, weightedSystemScore } = node;
  return weightedMaxSystemScore ? (weightedSystemScore / weightedMaxSystemScore) * 100 : 0;
};

export const getCriterionRelativeDegreeOfFulfillment = (node) => {
  const { weightedMaxSystemScore, weightedSystemScore } = node;
  return weightedMaxSystemScore ? (weightedSystemScore / weightedMaxSystemScore) * 100 : 0;
};

export const getNodeRelativeDegreeOfFulfillmentPotential = (node, systemLogic) => {
  switch (node.type) {
    case GROUPING_TYPE_INDICATOR:
      return getIndicatorRelativeDegreeOfFulfillmentPotential(node);
    case GROUPING_TYPE_CATEGORY: {
      let result = getCategoryRelativeDegreeOfFulfillmentPotential(node);
      return systemLogic === SYSTEM_LOGIC_DGNB ? Math.min(result, 100) : result;
    }
    case GROUPING_TYPE_CRITERION:
      return getCriterionRelativeDegreeOfFulfillmentPotential(node);
    default:
      console.error(`relative degree of fulfillment potential for node type ${node.type} not yet implemented`);
      break;
  }
};

export const getIndicatorRelativeDegreeOfFulfillmentPotential = (node) => {
  if (node.potentialEvaluationSystemScore > 0) {
    return (node.potentialEvaluationSystemScore / node.maxSystemScore) * 100 || 0;
  } else {
    return 0;
  }
};

// TODO: Check if this is related to the calculation bug
export const getCategoryRelativeDegreeOfFulfillmentPotential = (node) => {
  const { weightedPotentialSystemScore, weightedMaxSystemScore } = node;
  return (
    (weightedMaxSystemScore &&
      weightedPotentialSystemScore &&
      (weightedPotentialSystemScore / weightedMaxSystemScore) * 100) ||
    0
  );
};

export const getCriterionRelativeDegreeOfFulfillmentPotential = getCategoryRelativeDegreeOfFulfillmentPotential;

export const getNodeAbsoluteDegreeOfFulfillment = (node, map, parentField, systemLogic) => {
  switch (node.type) {
    case GROUPING_TYPE_INDICATOR:
      return getIndicatorAbsoluteDegreeOfFulfillment(node, map, parentField, systemLogic);
    case GROUPING_TYPE_CATEGORY:
      return getCategoryAbsoluteDegreeOfFulfillment(node);
    case GROUPING_TYPE_CRITERION:
      return getCriterionAbsoluteDegreeOfFulfillment(node, systemLogic);
    default:
      console.error(`absolute degree of fulfillment for node type ${node.type} not yet implemented`);
      break;
  }
};

export const getIndicatorAbsoluteDegreeOfFulfillment = (node, map, parentField, systemLogic) => {
  const parentElement = map[node[parentField]];
  const baseProportion =
    systemLogic === SYSTEM_LOGIC_STANDARD
      ? parentElement.relativeProportionMaxSystemScore
      : parentElement.maxSystemScoreProportion;
  return (baseProportion / 100) * node.evaluationSystemScore;
};

export const getCategoryAbsoluteDegreeOfFulfillment = (node) => {
  return (node.degreeOfFulfillment * node.maxSystemScoreProportion) / 100;
};

export const getCriterionAbsoluteDegreeOfFulfillment = (node, systemLogic) => {
  const multiply =
    systemLogic === SYSTEM_LOGIC_STANDARD ? node.relativeProportionMaxSystemScore : node.maxSystemScoreProportion;
  return (node.degreeOfFulfillment * multiply) / 100;
};

export const getNodeAbsoluteDegreeOfFulfillmentPotential = (node, map, parentField, systemLogic) => {
  switch (node.type) {
    case GROUPING_TYPE_INDICATOR:
      return getIndicatorAbsoluteDegreeOfFulfillmentPotential(node, map, parentField, systemLogic);
    case GROUPING_TYPE_CATEGORY:
      return getCategoryAbsoluteDegreeOfFulfillmentPotential(node);
    case GROUPING_TYPE_CRITERION:
      return getCriterionAbsoluteDegreeOfFulfillmentPotential(node, systemLogic);
    default:
      console.error(`absolute degree of fulfillment potential for node type ${node.type} not yet implemented`);
      break;
  }
};

export const getIndicatorAbsoluteDegreeOfFulfillmentPotential = (node, map, parentField, systemLogic) => {
  const parentElement = map[node[parentField]];
  const baseProportion =
    systemLogic === SYSTEM_LOGIC_STANDARD
      ? parentElement.relativeProportionMaxSystemScore
      : parentElement.maxSystemScoreProportion;
  return (baseProportion / 100) * node.potentialEvaluationSystemScore;
};

export const getCategoryAbsoluteDegreeOfFulfillmentPotential = (node) => {
  return (node.relativeDegreeOfFulfillmentPotential * node.maxSystemScoreProportion) / 100;
};

export const getCriterionAbsoluteDegreeOfFulfillmentPotential = (node, systemLogic) => {
  const multiply =
    systemLogic === SYSTEM_LOGIC_STANDARD ? node.relativeProportionMaxSystemScore : node.maxSystemScoreProportion;
  return (node.relativeDegreeOfFulfillmentPotential * multiply) / 100;
};

export const getNodeDifferenceRelativeDegreeOfFulfillment = (node) => {
  return node.relativeDegreeOfFulfillmentPotential - node.degreeOfFulfillment;
};

export const getNodeDifferenceAbsoluteDegreeOfFulfillment = (node) => {
  return node.absoluteDegreeOfFulfillmentPotential - node.absoluteDegreeOfFulfillment;
};

export const isUserSuperAdmin = () => {
  const user = userSubject.getValue()?.user || {};
  return user.superAdmin || hasPermission(PERMISSION_SUPER_ADMIN) || false;
};

export const shouldShowPotentialColumn = (node) => {
  const hasActions = node.actions?.length > 0;
  const isPotentialGreaterThanCurrent = node.potentialEvaluationSystemScore > node.evaluationSystemScore;
  return node.type === GROUPING_TYPE_CATEGORY || node.type === GROUPING_TYPE_CRITERION
    ? isPotentialGreaterThanCurrent
    : hasActions && isPotentialGreaterThanCurrent;
};

export const getValueOrDash = (value) => {
  if (value === DASH) {
    return value;
  }
  return onlyDecimals(roundDecimal(value), 2) + PERCENT_SIGN;
};
export const nullIfDash = (node) => {
  if (node.relativeDegreeOfFulfillmentPotential === DASH) {
    node.relativeDegreeOfFulfillmentPotential = null;
  }
  if (node.absoluteDegreeOfFulfillmentPotential === DASH) {
    node.absoluteDegreeOfFulfillmentPotential = null;
  }
  if (node.differenceRelativeDegreeOfFulfillment === DASH) {
    node.differenceRelativeDegreeOfFulfillment = null;
  }
  if (node.differenceAbsoluteDegreeOfFulfillment === DASH) {
    node.differenceAbsoluteDegreeOfFulfillment = null;
  }
};

export const scrollElementByIdTo = (elementId, scrollToXYCoordinates) => {
  const element2Scroll = document.getElementById(elementId);
  if (element2Scroll) {
    element2Scroll.scrollTo(scrollToXYCoordinates.x, scrollToXYCoordinates.y);
    return;
  }
  console.error(`Unable to scroll element id ${elementId}`);
};

export const createChapterFileArray = (textImageOriginal) => {
  let textImage = {
    text: textImageOriginal?.text,
    fileIds: [...(textImageOriginal.fileIds || [])],
  };

  const idSet = new Set(textImage.fileIds);

  const regexp = /<img src="[^"\s]+\/(?<imgId>\d+)"/gm;
  if (textImage.text) {
    const matches = textImage.text.matchAll(regexp);
    for (const match of matches) {
      if (match.groups.imgId) {
        idSet.add(match.groups.imgId);
      }
    }

    textImage.fileIds = [...idSet];
  }

  return textImage;
};
