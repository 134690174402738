import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridColumn as Column, Grid, GridToolbar } from "@progress/kendo-react-grid";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as getUUID } from "uuid";
import { TENANT_TYPE_ESG } from "../../../constants/tenant";
import { getUserTenantType } from "../../../hooks/auth";

export const ActionTable = ({
  actionsData,
  dataResult,
  resultState,
  dataStateChange,
  dataState,
  onExpandChange,
  onHeaderSelectionChange,
  onSelectionChange,
  onItemChange,
  onSave,
  onFilterChange,
  mainColumns,
}) => {
  const tenantType = getUserTenantType();
  const [columns, setColumns] = useState(mainColumns);
  const [columnsPopoverTarget, setColumnsPopoverTarget] = useState(null);
  const { t, i18n } = useTranslation();
  const columnTitles = useMemo(
    () =>
      mainColumns.map((column) => ({ [column.title]: t(column.title) })).reduce((acc, cur) => ({ ...acc, ...cur }), {}),
    [mainColumns, i18n.language]
  );

  let _pdfExport;
  const exportPDF = () => {
    _pdfExport.save();
  };

  let _export;
  const exportExcel = () => {
    _export.save();
  };

  const onChange = (event) => {
    const field = event.target.name;
    if (field === "selectAll") {
      const value = event.target.value;
      const newColumns = columns.map((column) => ({
        ...column,
        show: value,
      }));
      setColumns(newColumns);
    } else {
      const newColumns = columns.map((column) => {
        if (column.field === field) {
          return {
            ...column,
            show: !column.show,
          };
        }
        return column;
      });
      setColumns(newColumns);
    }
  };

  const showColumns = useMemo(() => columns.some((i) => i.show), [columns]);

  return (
    <>
      <Popover
        open={!!columnsPopoverTarget}
        anchorEl={columnsPopoverTarget}
        onClose={() => setColumnsPopoverTarget(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <div className="popover-check-tree">
          <div className="action-check-box">
            <ul className="check-tree">
              <li className={"check-tree-node"}>
                <Checkbox
                  type="checkbox"
                  id="selectAll"
                  name="selectAll"
                  checked={columns.every((column) => column.show)}
                  onChange={onChange}
                  label={t("main.selectOrDeselectAll")}
                />
              </li>
            </ul>
          </div>
          {columns.map((column) => (
            <div key={getUUID()} className="action-check-box">
              <ul key={getUUID()} className="check-tree">
                <li key={getUUID()} className={"check-tree-node"}>
                  <Checkbox
                    type="checkbox"
                    id={column.field}
                    name={column.field}
                    checked={column.show}
                    onChange={onChange}
                    label={column.title}
                  />
                </li>
              </ul>
            </div>
          ))}
        </div>
      </Popover>

      <ExcelExport
        data={dataResult.data}
        ref={(exporter) => {
          _export = exporter;
        }}
      >
        <Grid
          className="action-table-grid"
          style={{ height: "600px" }}
          pageable={{ pageSizes: true }}
          data={dataResult.data}
          total={resultState?.total}
          onDataStateChange={dataStateChange}
          onExpandChange={onExpandChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          onSelectionChange={onSelectionChange}
          onItemChange={onItemChange}
          navigatable={true}
          sortable={true}
          groupable={true}
          resizable={true}
          reorderable={true}
          filterable={true}
          {...dataState}
        >
          <GridToolbar>
            <IconButton size="small" onClick={(event) => setColumnsPopoverTarget(event.currentTarget)}>
              <ViewWeekIcon />
            </IconButton>

            <Input
              onChange={onFilterChange}
              className="action-input-search"
              placeholder={t("action.searchAllColumns")}
            />
            <div className="export-btns-container">
              <Button onClick={exportExcel}>{t("action.exportExcel")}</Button>
              <Button onClick={exportPDF}>{t("action.exportPdf")}</Button>
              {tenantType === TENANT_TYPE_ESG && (
                <Button onClick={onSave}>{t("dashboard.actionsTable.saveAllChanges")}</Button>
              )}
            </div>
          </GridToolbar>

          {showColumns ? (
            columns.map(
              (column) => column.show && <Column key={getUUID()} {...column} title={columnTitles?.[column.title]} />
            )
          ) : (
            <Column />
          )}
        </Grid>
      </ExcelExport>

      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
      >
        <Grid data={dataResult}>
          {showColumns ? (
            columns.map(
              (column) => column.show && <Column key={getUUID()} {...column} title={columnTitles?.[column.title]} />
            )
          ) : (
            <Column />
          )}
        </Grid>
      </GridPDFExport>
    </>
  );
};

ActionTable.propTypes = {
  actionsData: PropTypes.array,
  dataResult: PropTypes.array,
  resultState: PropTypes.object,
  dataStateChange: PropTypes.func,
  dataState: PropTypes.object,
  onExpandChange: PropTypes.func,
  onHeaderSelectionChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onItemChange: PropTypes.func,
  onSave: PropTypes.func,
  onFilterChange: PropTypes.func,
  mainColumns: PropTypes.array,
};
