import { ActionTemplateEndpointApi, Configuration } from "../generated-api";
import { BehaviorSubject } from "rxjs";
import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { createTablePageLogic } from "./tablePageLogic";

const actionApi = new ActionTemplateEndpointApi(new Configuration({
  basePath: window.location.origin,
  getHeaders: () => ({ 'Authorization': getAuthorizationHeader() }),
  responseHandler: handleUnauthorized
}));

export const actionsListSubject = new BehaviorSubject(new PromiseSubjectState());
export const useActionListResponse = hookFromSubject(actionsListSubject);
export const getActionList = () =>
  promiseToSubject(actionApi.apiActionTemplateListGet(), actionsListSubject);

export const actionUpdateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useActionUpdateResponse = hookFromSubject(actionUpdateSubject);
export const updateAction = (request) =>
  promiseToSubject(actionApi.apiActionTemplatePut({ request }), actionUpdateSubject);

export const actionDeleteSubject = new BehaviorSubject(new PromiseSubjectState());
export const useActionDeleteResponse = hookFromSubject(actionDeleteSubject);
export const deleteAction = (id) =>
  promiseToSubject(actionApi.apiActionTemplateIdDelete({ id }), actionDeleteSubject);

export const actionCreateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useActionCreateResponse = hookFromSubject(actionCreateSubject);
export const createAction = (request) =>
  promiseToSubject(actionApi.apiActionTemplatePost({ request }), actionCreateSubject);

export const [useActionsPageLogic, resetActionsPageState] = createTablePageLogic();
