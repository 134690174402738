import Switch from "@mui/material/Switch";
import React from "react";
import "./MonoToggle.scss";

export const MonoToggle = ({ isChecked, onChange, label, className, disabled }) => {
  const switchHandler = (event) => onChange(event.target.checked);

  return (
    <div className={"mono-toggle " + (disabled ? "disabled " : "") + (className || "")}>
      <h5>{label}</h5>
      <label className={isChecked ? " active" : ""} onClick={disabled ? null : () => onChange(isChecked)}>
        <Switch disabled={disabled} checked={isChecked} onChange={switchHandler} color="primary" />
      </label>
    </div>
  );
};
