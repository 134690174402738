import React from "react";
import { DefaultLayout } from "./components/Layout/DefaultLayout";
import { Redirect } from "./components/Redirect/Redirect";
import { TenantSelect } from "./components/TenantSelect/TenantSelect";
import { UserProfile } from "./components/UserProfile/UserProfile";
import {
  PERMISSION_ACTION_READ,
  PERMISSION_BENCHMARK_CREATE,
  PERMISSION_BENCHMARK_READ,
  PERMISSION_KPI_READ,
  PERMISSION_KPI_SYSTEM_CREATE,
  PERMISSION_KPI_SYSTEM_READ,
  PERMISSION_PROJECT_CREATE,
  PERMISSION_PROJECT_GROUP_READ,
  PERMISSION_PROJECT_READ,
  PERMISSION_SUSTAINABILITY_SYSTEM_CREATE,
  PERMISSION_SUSTAINABILITY_SYSTEM_READ,
  PERMISSION_TEXTBLOCK_READ,
} from "./constants/permissions";
import { hasPermission } from "./helpers/permission";
import { isSuperAdmin } from "./hooks/auth";
import { IndexPage } from "./pages";
import { BenchmarkPage } from "./pages/Benchmark/Benchmark";
import { BenchmarksPage } from "./pages/Benchmarks/Benchmarks";
import { CreateKPISystemPage } from "./pages/CreateKPISystem/CreateKPISystem";
import { CreateSustainabilitySystem } from "./pages/CreateSustainabilitySystem/CreateSustainabilitySystem";
import { DashboardPage } from "./pages/Dashboard/Dashboard";
import { GeneralAttributesPage } from "./pages/GeneralAttributes/GeneralAttributes";
import { KPIPage } from "./pages/KPI/KPI";
import { KPIDashboardPage } from "./pages/KPIDashboard/KPIDashboard";
import { KPIRatingSystemPage } from "./pages/KPIRatingSystem/KPIRatingSystem";
import { KPISystemPage } from "./pages/KPISystem/KPISystem";
import { KPISystemsPage } from "./pages/KPISystems/KPISystems";
import { ProjectPage } from "./pages/Project/Project";
import { ProjectAttributesPage } from "./pages/ProjectAttributes/ProjectAttributes";
import { ProjectEditPage } from "./pages/ProjectEdit/ProjectEdit";
import { ProjectGroupPage } from "./pages/ProjectGroup/ProjectGroup";
import { ProjectGroupAnalyzePage } from "./pages/ProjectGroupAnalyze/ProjectGroupAnalyze";
import { ProjectGroupsPage } from "./pages/ProjectGroups/ProjectGroups";
import { ProjectsPage } from "./pages/Projects/Projects";
import { RatingSystem } from "./pages/RatingSystem/RatingSystem";
import { ReportingPage } from "./pages/Reporting";
import { Roles } from "./pages/Roles/Roles";
import { SettingsActions } from "./pages/SettingsActions/SettingsActions";
import { SettingsTextBlockPage } from "./pages/SettingsTextBlock/SettingsTextBlock";
import { SustainabilitySystemPage } from "./pages/SustainabilitySystem/SustainabilitySystem";
import { SustainabilitySystemsPage } from "./pages/SustainabilitySystems/SustainabilitySystems";
import { TenantUsersPage } from "./pages/TenantUsers/TenantUsers";
import { TenantsPage } from "./pages/Tenants/Tenants";
import { UsersPage } from "./pages/Users/Users";

export const routes = [
  {
    path: "/",
    Component: () => <DefaultLayout />,
    children: [
      {
        path: "/sustainabilitySystem",
        Component: () =>
          hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_READ) ? (
            <SustainabilitySystemsPage />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          ),
      },
      {
        path: "/sustainabilitySystem/kpiSystem/create",
        Component: () =>
          hasPermission(PERMISSION_KPI_SYSTEM_CREATE) ? <CreateKPISystemPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/sustainabilitySystem/kpiSystem/:kpiSystemId",
        Component: () =>
          hasPermission(PERMISSION_KPI_SYSTEM_READ) ? <KPISystemPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/sustainabilitySystem/kpiSystem",
        Component: () =>
          hasPermission(PERMISSION_KPI_SYSTEM_READ) ? <KPISystemsPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/sustainabilitySystem/benchmark/create",
        Component: () =>
          hasPermission(PERMISSION_BENCHMARK_CREATE) ? <BenchmarkPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/sustainabilitySystem/benchmark/:benchmarkId",
        Component: () =>
          hasPermission(PERMISSION_BENCHMARK_READ) ? <BenchmarkPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/sustainabilitySystem/benchmark",
        Component: () =>
          hasPermission(PERMISSION_BENCHMARK_READ) ? <BenchmarksPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/sustainabilitySystem/create",
        Component: () =>
          hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_CREATE) ? (
            <CreateSustainabilitySystem />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          ),
      },
      {
        path: "/sustainabilitySystem/:sustainabilitySystemId",
        Component: () =>
          hasPermission(PERMISSION_SUSTAINABILITY_SYSTEM_READ) ? (
            <SustainabilitySystemPage />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          ),
      },
      {
        path: "/projectGroup/create",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_GROUP_READ) ? <ProjectGroupPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/projectGroup/analyze/:groupId/project/ratingSystem/:ratingSystemId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <RatingSystem analyze /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/projectGroup/analyze/:groupId/project/kpiRatingSystem/:kpiRatingSystemId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? (
            <KPIRatingSystemPage analyze />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          ),
      },
      {
        path: "/projectGroup/analyze/:groupId/project/:projectId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <ProjectPage analyze /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/projectGroup/analyze/:projectGroupId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_GROUP_READ) ? (
            <ProjectGroupAnalyzePage />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          ),
      },
      {
        path: "/projectGroup/:groupId/project/ratingSystem/:ratingSystemId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <RatingSystem /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/projectGroup/:groupId/project/kpiRatingSystem/:kpiRatingSystemId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <KPIRatingSystemPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/projectGroup/:groupId/project/:projectId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <ProjectPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/projectGroup/:projectGroupId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_GROUP_READ) ? <ProjectGroupPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/projectGroup",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_GROUP_READ) ? <ProjectGroupsPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/project/create",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_CREATE) ? <ProjectEditPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/project/ratingSystem/:ratingSystemId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <RatingSystem /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/project/old/ratingSystem/:ratingSystemId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <RatingSystem old /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/project/kpiRatingSystem/:kpiRatingSystemId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <KPIRatingSystemPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/project/:projectId/edit",
        Component: () => <ProjectEditPage />,
      },
      {
        path: "/project/:projectId/dashboard/:ratingSystemId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <DashboardPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/project/:projectId/dashboard",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <DashboardPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/project/:projectId/kpiDashboard/:kpiRatingSystemId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <KPIDashboardPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/project/:projectId",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <ProjectPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/project",
        Component: () =>
          hasPermission(PERMISSION_PROJECT_READ) ? <ProjectsPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/reporting",
        Component: () => <ReportingPage />,
      },
      {
        path: "/settings/actions",
        Component: () =>
          hasPermission(PERMISSION_ACTION_READ) ? <SettingsActions /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/settings/kpi",
        exact: true,
        Component: () => (hasPermission(PERMISSION_KPI_READ) ? <KPIPage /> : <Redirect to={{ pathname: "/" }} />),
      },
      {
        path: "/settings/textblock",
        exact: true,
        Component: () =>
          hasPermission(PERMISSION_TEXTBLOCK_READ) ? <SettingsTextBlockPage /> : <Redirect to={{ pathname: "/" }} />,
      },
      {
        path: "/settings",
        exact: true,
        Component: () => (
          <Redirect to={{ pathname: hasPermission(PERMISSION_ACTION_READ) ? "/settings/actions" : "/settings/kpi" }} />
        ),
      },
      {
        path: "/admin/users",
        exact: true,
        Component: UsersPage,
      },
      {
        path: "/admin/userProfile",
        exact: true,
        Component: UserProfile,
      },
      {
        path: "/admin/tenant/users",
        exact: true,
        Component: TenantUsersPage,
      },
      {
        path: "/admin/roles",
        exact: true,
        Component: Roles,
      },
      {
        path: "/admin/tenants",
        exact: true,
        Component: TenantsPage,
      },
      {
        path: "/admin/projectAttributes",
        exact: true,
        Component: ProjectAttributesPage,
      },
      {
        path: "/admin/generalAttributes",
        exact: true,
        Component: GeneralAttributesPage,
      },
      {
        path: "/admin",
        exact: true,
        Component: () => <Redirect to={{ pathname: isSuperAdmin() ? "/admin/users" : "/admin/tenant/users" }} />,
      },
      {
        path: "/",
        exact: true,
        Component: () => (!isSuperAdmin() ? <IndexPage /> : <Redirect to={{ pathname: "/admin/users" }} />),
      },
    ],
  },
  {
    path: "/tenants",
    Component: () => <TenantSelect />,
  },
];
