import { useState } from "react";
import { MIN_PAGE_SIZE } from "../../constants/main";

export const usePagination = (defaultPage = 0, defaultRowsPerPage = MIN_PAGE_SIZE) => {
  const [page, setPage] = useState(defaultPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  return {
    page, rowsPerPage, setPage, setRowsPerPage: value => {
      setRowsPerPage(value);
      setPage(0);
    }
  };
};
