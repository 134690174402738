import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { WidgetWrapper } from "../WidgetWrapper/WidgetWrapper";
import { getRatingSystemPerformance, useRatingSystemPerformanceResponse } from "../../../../hooks/ratingSystem";

const colors = ['var(--RED_COLOR)', 'var(--ORANGE_COLOR)', 'var(--BLUE_COLOR)', 'var(--GREEN_COLOR)'];

export const TotalRatingSystem = () => {
  const { t } = useTranslation();
  const performance = useRatingSystemPerformanceResponse();

  useEffect(() => {
    getRatingSystemPerformance();
  }, []);

  return (
    <WidgetWrapper data={performance.data?.map((d, i) => ({value: d.count || 0, title: d.filterCriteria || '', bottomLine: colors[i] })) || []}
                   title={t('dashboard.totalRatingSystem.title')} loading={performance.loading} />
  );
};