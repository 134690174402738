import React from "react";
import "./UserAvatar.scss";

export const UserAvatar = ({ firstName, lastName, pictureId, className }) => {
  return (
    <div className={'user-avatar ' + (className || '')}>
      <span>{((firstName || '')[0] || '').toUpperCase()}{((lastName || '')[0] || '').toUpperCase()}</span>
    </div>
  );
};
