import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Grid, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider, LocalizationProvider, load, loadMessages } from "@progress/kendo-react-intl";
import weekData from 'cldr-core/supplemental/weekData.json';
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from "cldr-dates-full/main/de/dateFields.json";
import deNumbers from "cldr-numbers-full/main/de/numbers.json";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GOAL_TYPES, SCORE_TYPES } from "../../../../constants/ratingSystem";
import kendoDeMessages from '../../../../locales/kendo-de.json';
import { DynamicFormController } from "../../../DynamicFormController/DynamicFormController";
import { FormController } from "../../../FormController/FormController";
import { GoalTypeSelect } from "../GoalTypeSelect";
import "./GoalsDialog.scss";
import { GoalsDialogCalendar } from "./GoalsDialogCalendar";

load(
  deNumbers,
  deDateFields,
  deCaGregorian,
  weekData
)
loadMessages(kendoDeMessages, 'de');

export const GoalsDialog = ({ open, onClose, readOnly, data, onSave, kpiOnly, kpis }) => {
  const { t, i18n } = useTranslation();
  const [unit, setUnit] = useState(null);

  const options = kpiOnly
    ? []
    : GOAL_TYPES.map((item) => ({
        name: item,
        text: t("ratingSystem.goalType." + item),
      }));

  kpis.forEach((kpi) => {
    const key = SCORE_TYPES.KPI_TYPE + "_" + kpi.name + "_" + kpi.unit;
    options.push({
      name: key,
      text: kpi.name,
    });
  });

  const { control, reset, getValues, trigger, watch, setValue } = useForm({
    defaultValues: {
      name: "",
      typeKey: "",
      type: "",
      kpiName: "",
      kpiUnit: "",
      goals: [
        { value: "", date: null },
        { value: "", date: null },
      ],
    },
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "typeKey") {
        const { typeKey } = value;
        if (typeKey !== SCORE_TYPES.SYSTEM_SCORE_TYPE && typeKey !== SCORE_TYPES.SUPER_SCORE_TYPE) {
          const kpi = kpis.find((item) => item.key === typeKey) || {};
          setValue("type", SCORE_TYPES.KPI_TYPE);
          setValue("kpiName", kpi.name);
          setValue("kpiUnit", kpi.unit);
        } else {
          setValue("type", typeKey);
          setValue("kpiName", null);
          setValue("kpiUnit", null);
        }
        updateUnit();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, kpis]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "goals",
  });

  useEffect(() => {
    if (open) {
      reset({
        name: data.name || "",
        typeKey: data.type
          ? data.type + (data.kpiName ? "_" + data.kpiName : "") + (data.kpiUnit ? "_" + data.kpiUnit : "") || ""
          : "",
        type: data.type || "",
        kpiName: data.kpiName || "",
        kpiUnit: data.kpiUnit || "",
        goals: data.goals
          ? data.goals.map((item) => ({ ...item }))
          : [
              { value: "", date: null },
              { value: "", date: null },
            ],
      });
      updateUnit();
    }
  }, [open]);

  const updateUnit = () => {
    const { type, kpiUnit } = getValues();
    setUnit(
      type === SCORE_TYPES.SYSTEM_SCORE_TYPE
        ? t("ratingSystem.points")
        : type === SCORE_TYPES.SUPER_SCORE_TYPE
        ? "%"
        : kpiUnit
    );
  };

  const onSubmit = async () => {
    const result = await trigger();
    if (result) {
      const value = { ...data, ...getValues() };
      const formattedValue = {
        ...data,
        ...value,
        goals: value.goals.map((goal) => ({
          ...goal,
          date: moment(goal.date).toISOString(),
        })),
      };
      onSave(formattedValue);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} className="goals-dialog">
      <div className="dialog-body">
        <Grid container spacing={1}>
          <Grid xs={6} item>
            <FormController
              control={control}
              name="name"
              required
              label={t("award.title")}
              render={({ field, fieldState, label }) => (
                <TextField
                  error={fieldState.invalid}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  label={label + " *"}
                  {...field}
                  disabled={readOnly}
                />
              )}
            />
          </Grid>
          <Grid xs={6} item>
            <GoalTypeSelect control={control} required options={options} />
          </Grid>
        </Grid>
        <div className="divider">
          <div className="header-wert">{t("ratingSystem.value")}</div>
          <div className="header-date">{t("ratingSystem.date")}</div>
        </div>
        {fields.map((item, index) => (
          <React.Fragment key={item.id}>
            <Grid container spacing={1}>
              <Grid xs={6} item>
                <div className="field-wrapper">
                  <DynamicFormController
                    control={control}
                    name={`goals.${index}.value`}
                    required
                    label={index === 0 ? t("ratingSystem.baseline") : t("ratingSystem.value")}
                    defaultValue={item.value}
                    render={({ field, fieldState, label }) => (
                      <TextField
                        error={fieldState.invalid}
                        fullWidth
                        inputProps={{ maxLength: 50 }}
                        type="number"
                        label={label + " *"}
                        {...field}
                        value={field.value}
                        disabled={readOnly}
                        className="number-no-appearance"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                        }}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid xs={5} item>
                <div className="field-wrapper">
                  <DynamicFormController
                    control={control}
                    name={`goals.${index}.date`}
                    required
                    label={t("ratingSystem.date")}
                    defaultValue={item.date}
                    render={({ field, fieldState, label }) => (
                      <LocalizationProvider language={i18n.language}>
                        <IntlProvider locale={i18n.language}>
                          <DatePicker
                            className="goals-datepicker"
                            valid={!fieldState.invalid}
                            fillMode="flat"
                            format="yyyy"
                            calendar={GoalsDialogCalendar}
                            label={label + " *"}
                            placeholder=""
                            {...field}
                            value={field.value? new Date(field?.value) : null}
                            ref={null}
                          />
                        </IntlProvider>
                      </LocalizationProvider>
                    )}
                  />
                </div>
              </Grid>
              {index > 1 && (
                <Grid xs={1} item className="centered">
                  <IconButton size="small" onClick={() => remove(index)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <div className="divider" />
          </React.Fragment>
        ))}
        <Button onClick={() => append({ value: "", date: null })}>
          <span className="value-button">
            <AddIcon /> {t("ratingSystem.addValue")}
          </span>
        </Button>
        <div className="divider" />
      </div>
      <div className="buttons text-right">
        <Button className="close-goal-dialog" onClick={() => onClose()}>
          {t("main.close")}
        </Button>
        {!readOnly && (
          <Button color="primary" className="save-goal" onClick={onSubmit}>
            {t("main.save")}
          </Button>
        )}
      </div>
    </Dialog>
  );
};
