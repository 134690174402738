import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { BenchmarkBaseData } from "../../components/Benchmark/BenchmarkBaseData/BenchmarkBaseData";
import { BenchmarkFiles } from "../../components/Benchmark/BenchmarkFiles/BenchmarkFiles";
import { BenchmarkGoals } from "../../components/Benchmark/BenchmarkGoals/BenchmarkGoals";
import { Breadcrumb } from "../../components/Breadcrumb/Breadcrumb";
import { ContentBlock } from "../../components/ContentBlock/ContentBlock";
import { LoadingOverlay } from "../../components/LoadingOverlay/LoadingOverlay";
import { StatusSelect } from "../../components/StatusSelect/StatusSelect";
import { VersionsDialog } from "../../components/Versions/VersionsDialog/VersionsDialog";
import { STATUS_IN_PROGRESS, STATUS_RELEASED } from "../../constants/benchmark";
import { STATUS_LOCKED } from "../../constants/kpiSystem";
import {
  PERMISSION_BENCHMARK_DOCUMENT_UPLOAD,
  PERMISSION_BENCHMARK_EDIT,
  PERMISSION_BENCHMARK_STATUS_CHANGE,
  PERMISSION_KPI_READ,
} from "../../constants/permissions";
import { hasAnyPermissions, hasPermission } from "../../helpers/permission";
import {
  createBenchmark,
  getBenchmark,
  getBenchmarkRevisionById,
  getBenchmarkRevisions,
  updateBenchmark,
  useBenchmarkCreateResponse,
  useBenchmarkResponse,
  useBenchmarkRevisionByIdResponse,
  useBenchmarkRevisionsResponse,
  useBenchmarkUpdateResponse,
} from "../../hooks/benchmark";
import { listKPI, useKPIListResponse } from "../../hooks/kpi";
import { showError, showSuccess } from "../../hooks/toast";
import { useEvent } from "../../hooks/utils/useEvent";
import { CloseSVG, ManageHistorySVG, SaveSVG } from "../../svg";
import { processMessage } from "../../utils";
import "./Benchmark.scss";

const STATUSES_BY_STATUS = {
  undefined: [STATUS_IN_PROGRESS],
  [STATUS_IN_PROGRESS]: [STATUS_IN_PROGRESS, STATUS_RELEASED],
  [STATUS_RELEASED]: [STATUS_IN_PROGRESS, STATUS_RELEASED],
};

export const BenchmarkPage = () => {
  const mode = new URLSearchParams(window.location.search).get("mode");
  const { benchmarkId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const editPermissions = hasAnyPermissions([PERMISSION_BENCHMARK_EDIT, PERMISSION_BENCHMARK_STATUS_CHANGE]);

  const response = useBenchmarkResponse();
  const createResponse = useBenchmarkCreateResponse();
  const updateResponse = useBenchmarkUpdateResponse();
  const [tags, setTags] = useState([]);
  const [files, setFiles] = useState([]);
  const isCreate = !benchmarkId;
  const [isEdit, setIsEdit] = useState((mode === "edit" || isCreate) && editPermissions);
  const [submitted, setSubmitted] = useState(false);
  const [versionDialog, setVersionDialog] = useState(false);
  const [editIsDisabled, setEditIsDisabled] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const versions = useBenchmarkRevisionsResponse();
  const versionById = useBenchmarkRevisionByIdResponse();
  const kpiListResponse = useKPIListResponse();

  const { control, handleSubmit, reset, getValues, setValue } = useForm({
    defaultValues: {
      name: "",
      description: "",
      status: STATUS_IN_PROGRESS,
      kpiName: "",
      kpiUnit: "",
      unit: "",
      goals: [
        { value: "", date: null },
        { value: "", date: null },
      ],
    },
  });

  useEffect(() => {
    if (benchmarkId) {
      if (response.data && String(response.data.id) === benchmarkId) {
        resetData();
      }
      load();
      getVersions();
    }
  }, [benchmarkId]);

  useEffect(() => {
    if (hasPermission(PERMISSION_KPI_READ)) {
      listKPI();
    }
  }, []);

  const load = () =>
    getBenchmark(benchmarkId)
      .then(resetData)
      .catch(() => {
        console.error(t("error.500"));
        navigate("/sustainabilitySystem/benchmark");
      });

  const getVersions = (id) => {
    getBenchmarkRevisions(id || benchmarkId)
      .then((data) => {
        setSelectedVersion(data[data.length - 1]?.revisionNumber || null);
      })
      .catch(() => {
        showError(t("error.500"));
      });
  };

  const sortedVersions = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return [...data].sort((a, b) => b.revisionNumber - a.revisionNumber);
  };

  const versionHandler = useEvent((revisionNumber, isCurrent) => {
    getBenchmarkRevisionById(benchmarkId, revisionNumber)
      .then((data) => {
        resetData(data);
        setSelectedVersion(revisionNumber);
        setEditIsDisabled(!isCurrent);
        setIsEdit(false);
      })
      .catch(() => {
        showError(t("error.500"));
      });
    setVersionDialog(false);
  });

  const resetData = (values, disableEditing) => {
    setSubmitted(false);
    if (disableEditing) {
      setIsEdit(false);
    }
    const data = values || response.data || {};
    reset({ ...data });
    setTags(data?.tags || []);
    setFiles([...data.files]);
  };

  const onSubmit = async (values) => {
    try {
      setSubmitted(true);
      if (values.kpiUnit && values.kpiName && values.unit) {
        const updated = { ...values, tags, files: files };
        if (isCreate) {
          const data = await createBenchmark(updated);
          navigate("/sustainabilitySystem/benchmark/" + data?.id);
          showSuccess(t("benchmark.createdMessage"));
          getVersions(data?.id);
        } else {
          const data = await updateBenchmark(benchmarkId, updated);
          resetData(data, true);
          showSuccess(t("benchmark.updatedMessage"));
          getVersions();
        }
        setSubmitted(false);
      }
    } catch (error) {
      if (error.status === 409) {
        showError(processMessage(t("benchmark.error.409"), [values.name]));
      } else {
        showError(t("error.500"));
      }
    }
  };

  const handleCancel = useEvent(() => {
    if (isCreate) {
      navigate("/sustainabilitySystem/benchmark");
    } else {
      resetData(null, true);
    }
  });

  const onStatusChange = useEvent(async (event) => {
    if (currentStatus === STATUS_RELEASED) {
      try {
        const data = await updateBenchmark(benchmarkId, { ...getValues(), status: event.target.value });
        resetData(data, false);
      } catch (error) {
        console.error(error);
        showError(t("error.500"));
      }
    }
  });

  const versionsMemo = useMemo(() => sortedVersions(versions.data), [versions?.data]);

  const canChangeStatus = isEdit && hasPermission(PERMISSION_BENCHMARK_STATUS_CHANGE);
  const currentStatus = (response.data || {}).status;
  const canStartEdit = hasPermission(PERMISSION_BENCHMARK_EDIT);
  const canEdit = isCreate || [STATUS_RELEASED, STATUS_LOCKED].indexOf(currentStatus) === -1;
  const loading = response.loading || createResponse.loading || updateResponse.loading || kpiListResponse.loading;

  return (
    <LoadingOverlay spinner active={loading} className="benchmark-page">
      <form autoComplete={"off"} id="benchmarkForm" className="benchmark-form">
        <div className="page-header">
          <div className="flex-row">
            <Breadcrumb
              pathParts={[
                { url: "/sustainabilitySystem/benchmark", text: t("menu.benchmark.title") },
                { text: isCreate ? t("main.create") : response?.data?.name },
              ]}
            />
            {!isCreate && (
              <div className="flex-auto text-right">
                <Button
                  color="primary"
                  className="right-header-button"
                  disabled={isEdit}
                  onClick={() => setVersionDialog(true)}
                >
                  <ManageHistorySVG fontSize="inherit" style={{ fill: isEdit ? "rgba(0, 0, 0, 0.26)" : "" }} />
                  {t("main.version")}
                </Button>
              </div>
            )}
          </div>
          {!isCreate && (
            <div className="flex-row">
              <div>
                <StatusSelect
                  control={control}
                  disabled={!canChangeStatus}
                  value={currentStatus}
                  onChange={onStatusChange}
                  statuesByStatus={STATUSES_BY_STATUS}
                  labelTranslationPrefix="status.filter."
                />
              </div>
              <div className="flex-auto" />
            </div>
          )}
        </div>

        <div className="page-layout">
          <BenchmarkBaseData control={control} editDisabled={!isEdit || !canEdit} setTags={setTags} tags={tags} />

          <div className="flex-row">
            <div className="k-flex-1 flex-col">
              <BenchmarkGoals
                readOnly={!isEdit || !canEdit}
                control={control}
                getValues={getValues}
                setValue={setValue}
                submitted={submitted}
              />
            </div>
            {hasPermission(PERMISSION_BENCHMARK_DOCUMENT_UPLOAD) && (
              <ContentBlock className="k-flex-1 flex-col">
                <h2 className="files-header">{t("main.files.title")}</h2>
                <BenchmarkFiles
                  readOnly={!isEdit || !canEdit}
                  value={files}
                  onChange={(filesInner) => setFiles([...filesInner])}
                  benchmarkId={benchmarkId}
                />
              </ContentBlock>
            )}
          </div>
        </div>

        {canStartEdit && (
          <div className="fabs">
            {!isEdit && (
              <Fab type="button" color="primary" onClick={() => setIsEdit(true)} disabled={editIsDisabled}>
                <EditIcon className="fab-svg" />
              </Fab>
            )}
            {isEdit && (
              <>
                <Fab color="secondary" onClick={handleCancel}>
                  <CloseSVG className="fab-svg" />
                </Fab>
                <Fab color="primary" onClick={handleSubmit(onSubmit)}>
                  <SaveSVG className="fab-svg" />
                </Fab>
              </>
            )}
          </div>
        )}
        <VersionsDialog
          open={versionDialog}
          onClose={() => setVersionDialog(false)}
          onAction={versionHandler}
          titleText={t("benchmark.versions")}
          data={versionsMemo}
          selectedVersion={selectedVersion}
          loading={versionById.loading}
        />
      </form>
    </LoadingOverlay>
  );
};
