export const HomeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="icon-home" transform="translate(-2 -2)">
      <path
        id="Path_32"
        data-name="Path 32"
        d="M6.333,18H3L18,3,33,18H29.667"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_33"
        data-name="Path 33"
        d="M5,12V23.667A3.333,3.333,0,0,0,8.333,27H25a3.333,3.333,0,0,0,3.333-3.333V12"
        transform="translate(1.333 6)"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Path_34"
        data-name="Path 34"
        d="M9,26.333v-10A3.333,3.333,0,0,1,12.333,13h3.333A3.333,3.333,0,0,1,19,16.333v10"
        transform="translate(4 6.667)"
        fill="none"
        stroke="#002965"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
);
