import React from "react";
import { useTranslation } from "react-i18next";
import { FieldController } from "../../FieldController/FieldController";
import "./ProjectCustomFields.scss";

export const ProjectCustomFields = ({ control, editDisabled, fields, namePrefix, getValues }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const leftFields = (fields || []).filter((item) => item?.columnIndex !== 1);
  const rightFields = (fields || []).filter((item) => item?.columnIndex === 1);
  const columns = [leftFields, rightFields];

  return (
    <div className="project-custom-fields flex-row k-gap-4">
      {columns.map((column, index) => (
        <div key={index} className="k-flex-1">
          {column.map(({ required, details, name, type }, indexInner) => (
            <div key={indexInner}>
              <FieldController
                getValues={getValues}
                control={control}
                type={type}
                name={namePrefix ? namePrefix + "." + name : name}
                label={(details || {})[language] || name}
                required={required}
                readOnly={editDisabled}
                options={(details?.options || []).map((item) => ({
                  name: item.value,
                  text: (item.details || {})[language] || item.value,
                }))}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
