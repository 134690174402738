import * as d3 from "d3";
import { findRecursive, mapRecursive } from "../../../utils";
import { setSunburstData } from "../../../hooks/dashboard";

const width = 535;
const radius = width / 2;

const arc = d3
  .arc()
  .startAngle(d => d.x0)
  .endAngle(d => d.x1)
  .innerRadius(d => 90 + d.depth * 45)
  .outerRadius(d => 90 + (d.depth + 1) * 45);

const partition = data => {
  const partitionInner = d3.partition().size([2 * Math.PI, radius]);
  const processed = mapRecursive(data.children || [], (node, children) => {
    return { ...node, value: children.length ? 0 : node.value, children };
  });
  const root = d3.hierarchy({ children: processed }).sum(d => d.value);
  return partitionInner(root);
};

export function generateSunburstSvgESG({ data, value, onSelect, middleContent, superScore, potentialSuperScore }) {
  const root = partition(data);
  const svg = d3.create("svg");
  const element = svg.node();
  element.value = { sequence: [], percentage: 0.0 };
  let selected = null;

  const headerLabel = middleContent.querySelector('.header-text');
  const valueLabel = middleContent.querySelector('.value-text');
  const potentialLabel = middleContent.querySelector('.potential-text');

  if (value) {
    selected = findRecursive(root, node => node.data.uuid === value.data.uuid);
  }

  svg
    .attr("viewBox", `${-radius} ${-radius} ${width} ${width}`)
    .style("max-width", `${width}px`)
    .style("font", "12px sans-serif");

  const descendants = root.descendants().filter(d => {
    return d.depth && d.x1 - d.x0 > 0.001;
  });

  setSunburstData(descendants);

  const grayPath = svg
    .append("g")
    .selectAll("path")
    .data(descendants)
    .join("path")
    .style('stroke-width', '5px')
    .style('stroke', '#F2F4F7')
    .style('transition', 'fill 0.1s linear')
    .attr("fill", 'var(--NEUTRAL_BACKGROUND_COLOR)')
    .attr("d", arc);

  const mark = d => {
    const sequence = d
      .ancestors()
      .reverse()
      .slice(1);
    grayPath.select(function (node) {
      if (sequence[sequence.length - 1] === node) {
        return this;
      }
    }).attr('fill', '#7E899B');
    grayPath.select(function (node) {
      if (sequence[sequence.length - 1] !== node) {
        return this;
      }
    }).attr('fill', 'var(--NEUTRAL_BACKGROUND_COLOR)');

    headerLabel.innerText = d.data.title; //.length > 20 ? d.data.title.substr(0, 17) + '...' : d.data.title;
    valueLabel.innerText = d.data.universalScore + '%';
    potentialLabel.innerText = !!d.data.potentialScore && d.data.potentialScore > d.data.universalScore ? ` (${d.data.potentialScore}%)` : '';
  };

  const unmark = () => {
    grayPath.attr('fill', 'var(--NEUTRAL_BACKGROUND_COLOR)');

    headerLabel.innerText = '';
    valueLabel.innerText = superScore + '%';
    potentialLabel.innerText = !!potentialSuperScore && potentialSuperScore > superScore ? `(${potentialSuperScore}%)` : '';
  };

  if (selected) {
    mark(selected);
  } else {
    unmark();
  }

  svg
    .append("g")
    .attr("fill", "none")
    .attr("pointer-events", "all")
    .on("mouseleave", () => {
      if (!selected) {
        unmark();
      } else {
        mark(selected);
      }
      element.value = { sequence: [], percentage: 0.0 };
      element.dispatchEvent(new CustomEvent("input"));
    })
    .selectAll("path")
    .data(descendants)
    .join("path")
    .attr("d", arc)
    .on("mousedown", (event, d) => {
      if (onSelect) {
        selected = selected === d ? null : d;
        onSelect(selected);
        if (selected) {
          mark(d);
        }
      }
    })
    .on("mouseenter", (event, d) => {
      const sequence = d
        .ancestors()
        .reverse()
        .slice(1);
      mark(d);
      const percentage = ((100 * d.value) / root.value).toPrecision(3);
      element.value = { sequence, percentage };
      element.dispatchEvent(new CustomEvent("input"));
    });

  return element;
}
