import { roundDecimal } from "../../../../utils";
import React from "react";

export const SystemScoreTableRow = ({ position, systemScore, superScore, award }) => {
  return <>
    <span className={`left ${position.potential ? 'potential' : 'actual'}`}>{position.name}</span>
    <span className="score">{systemScore ? roundDecimal(systemScore, 100) : '-'}</span>
    <span className="score">{superScore ? roundDecimal(superScore, 100) + '%' : '-'}</span>
    <div className="award-case">
      {!!award && <>
        {award.fileId ? <img src={`/api/sustainability/award/file/${award.fileId}/preview`} alt="award"/> : null}
        <span>{award.title || ''}</span>
      </>}
    </div>
  </>
};
