export const TYPE_COMPANY = "COMPANY";
export const TYPE_REAL_ESTATE = "REAL_ESTATE";
export const TYPE_BUILDING = "BUILDING";
export const TYPE_NEW_BUILDING = "NEW_BUILDING";
export const TYPE_PORTFOLIO = "PORTFOLIO";
export const TYPE_CARBON_PATH = "CARBON_PATH";
export const TYPE_DISTRICT = "DISTRICT";
export const TYPE_PRODUCT = "PRODUCT";
export const TYPE_PAST = "PAST";
export const TYPE_OTHERS = "OTHERS";
export const TYPE_ESG = "ESG";
export const TYPE_WELLBEING = "WELLBEING";
export const TYPE_AVIATION = "AVIATION";
export const TYPE_OFFICE = "OFFICE";
export const TYPE_HEALTH_CARE = "HEALTH_CARE";
export const TYPE_HOSPITALITY = "HOSPITALITY";
export const TYPE_LIVING = "LIVING";
export const TYPE_SPORT = "SPORT";
export const TYPE_FOOTBALL = "FOOTBALL";

export const BUILDING_TYPES = [{ name: TYPE_NEW_BUILDING }, { name: TYPE_PORTFOLIO }, { name: TYPE_CARBON_PATH }];

export const ESG_TYPES = [
  { name: TYPE_COMPANY },
  {
    name: TYPE_REAL_ESTATE,
    items: [
      {
        name: TYPE_BUILDING,
        items: BUILDING_TYPES,
      },
      { name: TYPE_DISTRICT },
    ],
  },
  { name: TYPE_PRODUCT },
  {
    name: TYPE_WELLBEING,
    items: [
      { name: TYPE_AVIATION },
      { name: TYPE_OFFICE },
      { name: TYPE_HEALTH_CARE },
      { name: TYPE_HOSPITALITY },
      { name: TYPE_LIVING },
    ],
  },
  {
    name: TYPE_SPORT,
    items: [{ name: TYPE_FOOTBALL }],
  },
];

export const OTHER_TYPES = [{ name: TYPE_PAST }];

export const SUSTAINABILITY_SYSTEM_TYPES = [
  {
    name: TYPE_ESG,
    items: ESG_TYPES,
  },
  {
    name: TYPE_OTHERS,
    items: OTHER_TYPES,
  },
];

export const STATUS_IN_PROGRESS = "IN_PROGRESS";
export const STATUS_RELEASED = "APPROVED";
export const STATUS_LOCKED = "LOCKED";

export const SUSTAINABILITY_SYSTEM_STATUSES = [STATUS_IN_PROGRESS, STATUS_RELEASED, STATUS_LOCKED];

export const GROUPING_TYPE_CATEGORY = "CATEGORY";
export const GROUPING_TYPE_CRITERION = "CRITERION";
export const GROUPING_TYPE_INDICATOR = "INDICATOR";

export const AWARD_CALCULATION_TYPE_PERCENT = "PERCENT";
export const AWARD_CALCULATION_TYPE_POINT = "POINT";

export const INDICATOR_VALUE_TYPE_TEXT = "TEXT";
export const INDICATOR_VALUE_TYPE_RANGE = "RANGE";

export const SYSTEM_LOGIC_STANDARD = "STANDARD";
export const SYSTEM_LOGIC_DGNB = "DGNB";

export const CREATED = "CREATED";
export const DUPLICATED = "DUPLICATED";
export const MIGRATED = "MIGRATED";

export const CUSTOM_VALUE = "CUSTOM";
export const CURRENT_CUSTOM_TAG = "CURRENT";
export const POTENTIAL_CUSTOM_TAG = "POTENTIAL";
