import { setDashboardSelectedGrouping } from "../hooks/project";

export const getBreadcrumbPathParts = (selectedGrouping, rootLabel) => {
  const pathParts = [
    {
      onClick: selectedGrouping ? () => {
        setDashboardSelectedGrouping(null);
      } : undefined,
      text: rootLabel
    }
  ];

  if (selectedGrouping) {
    let tmp = selectedGrouping;
    let parts = [];
    while (tmp.data.uuid) {
      parts = [tmp, ...parts];
      tmp = tmp.parent;
    }
    parts.forEach((item, index) => {
      if ((index + 1) < parts.length) {
        pathParts.push({
          text: item.data.title,
          onClick: selectedGrouping ? () => {
            setDashboardSelectedGrouping(item);
          } : undefined
        });
      } else {
        pathParts.push({ text: item.data.title });
      }
    })
  }

  return pathParts;
};
