import { hookFromSubject, PromiseSubjectState, promiseToSubject } from "react-rxjs-easy";
import { BehaviorSubject } from "rxjs";
import { Configuration, RatingSystemEndpointApi } from "../generated-api";
import { getAuthorizationHeader, handleUnauthorized } from "./auth";
import { createTablePageLogic } from "./tablePageLogic";

const ratingSystemApi = new RatingSystemEndpointApi(
  new Configuration({
    basePath: window.location.origin,
    getHeaders: () => ({ Authorization: getAuthorizationHeader() }),
    responseHandler: handleUnauthorized,
  })
);

export const ratingSystemSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRatingSystemResponse = hookFromSubject(ratingSystemSubject);
export const getRatingSystem = (id) =>
  promiseToSubject(ratingSystemApi.apiRatingSystemIdGet({ id }), ratingSystemSubject);

export const ratingSystemEvaluateSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRatingSystemEvaluateResponse = hookFromSubject(ratingSystemEvaluateSubject);
export const evaluateRatingSystem = (evaluationDTO) =>
  promiseToSubject(ratingSystemApi.apiRatingSystemEvaluatePut({ evaluationDTO }), ratingSystemEvaluateSubject);

export const ratingSystemRichTextImageUploadSubject = new BehaviorSubject(new PromiseSubjectState());
export const ratingSystemRichTextImageUploadResponse = hookFromSubject(ratingSystemRichTextImageUploadSubject);
export const ratingSystemRichTextImageUpload = (id, file) =>
  promiseToSubject(
    ratingSystemApi.apiRatingSystemIdRichtextImagePost({ id, file }),
    ratingSystemRichTextImageUploadSubject
  );

export const ratingSystemAcceptChangesSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRatingSystemAcceptChangesResponse = hookFromSubject(ratingSystemAcceptChangesSubject);
export const acceptRatingSystemChanges = (id) =>
  promiseToSubject(ratingSystemApi.apiRatingSystemIdAcceptChangesPost({ id }), ratingSystemAcceptChangesSubject);

ratingSystemAcceptChangesSubject.subscribe((response) => {
  if (!response.loading && response.data) {
    ratingSystemSubject.next(response);
  }
});

export const ratingSystemDeclineChangesSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRatingSystemDeclineChangesResponse = hookFromSubject(ratingSystemDeclineChangesSubject);
export const declineRatingSystemChanges = (id) =>
  promiseToSubject(ratingSystemApi.apiRatingSystemIdDeclineChangesPost({ id }), ratingSystemDeclineChangesSubject);

export const selectedRowSubject = new BehaviorSubject(null);
export const useSelectedRow = hookFromSubject(selectedRowSubject);
export const setSelectedRow = (value) => selectedRowSubject.next(value);

export const projectDashboardTimelineSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectDashboardTimelineResponse = hookFromSubject(projectDashboardTimelineSubject);
export const getProjectDashboardTimeline = (id) =>
  promiseToSubject(ratingSystemApi.apiRatingSystemIdTimelineGet({ id }), projectDashboardTimelineSubject);

export const projectDashboardRevisionSubject = new BehaviorSubject(new PromiseSubjectState());
export const useProjectDashboardRevisionResponse = hookFromSubject(projectDashboardRevisionSubject);
export const getProjectDashboardRevision = (id, revisionId, lastRevision) => {
  if (lastRevision) {
    promiseToSubject(
      ratingSystemApi.apiRatingSystemIdGet({
        id,
      }),
      projectDashboardRevisionSubject
    );
  } else {
    promiseToSubject(
      ratingSystemApi.apiRatingSystemIdRevisionRevisionIdGet({
        id,
        revisionId,
      }),
      projectDashboardRevisionSubject
    );
  }
};

export const ratingSystemGroupingSubject = new BehaviorSubject([]);
export const useRatingSystemGrouping = hookFromSubject(ratingSystemGroupingSubject);
export const setRatingSystemGrouping = (grouping) => ratingSystemGroupingSubject.next(grouping);

export const ratingSystemKPIChartSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRatingSystemKPIChartResponse = hookFromSubject(ratingSystemKPIChartSubject);
export const getRatingSystemKPIChart = (id, revision, lastRevision) => {
  if (revision === lastRevision) {
    return promiseToSubject(ratingSystemApi.apiRatingSystemIdChartKpiGet({ id }), ratingSystemKPIChartSubject);
  } else {
    return promiseToSubject(
      ratingSystemApi.apiRatingSystemIdChartKpiGet({ id }, { params: { revision } }),
      ratingSystemKPIChartSubject
    );
  }
};

export const ratingSystemBenchmarkChartSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRatingSystemBenchmarkChartResponse = hookFromSubject(ratingSystemBenchmarkChartSubject);
export const getRatingSystemBenchmarkChart = (ratingSystemId, benchmarkId) =>
  promiseToSubject(
    ratingSystemApi.apiRatingSystemRatingSystemIdChartBenchmarkBenchmarkIdGet({
      ratingSystemId,
      benchmarkId,
    }),
    ratingSystemBenchmarkChartSubject
  );

export const benchmarksLoadedForKpiSubject = new BehaviorSubject(null);
export const setBenchmarksLoadedForKpi = (value) => benchmarksLoadedForKpiSubject.next(value);
export const useBenchmarksLoadedForKpi = hookFromSubject(benchmarksLoadedForKpiSubject);

export const ratingSystemRevisionsSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRatingSystemRevisionsResponse = hookFromSubject(ratingSystemRevisionsSubject);
export const getRatingSystemRevisions = (id) =>
  promiseToSubject(ratingSystemApi.apiRatingSystemIdRevisionsGet({ id }), ratingSystemRevisionsSubject);

export const ratingSystemRevisionByIdSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRatingSystemRevisionByIdResponse = hookFromSubject(ratingSystemRevisionByIdSubject);
export const getRatingSystemRevisionById = (id, revisionId) =>
  promiseToSubject(
    ratingSystemApi.apiRatingSystemIdRevisionRevisionIdGet({
      id,
      revisionId,
    }),
    ratingSystemRevisionByIdSubject
  );

export const ratingSystemPerformanceSubject = new BehaviorSubject(new PromiseSubjectState());
export const useRatingSystemPerformanceResponse = hookFromSubject(ratingSystemPerformanceSubject);
export const getRatingSystemPerformance = () =>
  promiseToSubject(ratingSystemApi.apiRatingSystemSuperScoreCountGet(), ratingSystemPerformanceSubject);

export const ratingSystemPotentialsChangedSubject = new BehaviorSubject({});
export const getRatingSystemPotentialsChanged = () => ratingSystemPotentialsChangedSubject.getValue();
export const resetRatingSystemPotentialsChanged = () => ratingSystemPotentialsChangedSubject.next({});
export const setRatingSystemPotentialChanged = (key) =>
  ratingSystemPotentialsChangedSubject.next({ ...ratingSystemPotentialsChangedSubject.getValue(), [key]: true });

export const indicatorsWithActionsByIdSubject = new BehaviorSubject({});
export const useIndicatorsWithActionsById = hookFromSubject(indicatorsWithActionsByIdSubject);

export const [useRatingSystemBenchmarksLogic, resetRatingSystemBenchmarksState] = createTablePageLogic();

export const updateActions = (ratingSystemId, actions) =>
  ratingSystemApi.apiRatingSystemRatingSystemIdUpdateActionsPut({ ratingSystemId, actions });
