import jp from "jsonpath";
import { v4 as getUUID } from "uuid";
import { GROUPING_TYPE_KPI } from "../../constants/kpiSystem";
import {
  GROUPING_TYPE_CATEGORY,
  GROUPING_TYPE_CRITERION,
  GROUPING_TYPE_INDICATOR,
} from "../../constants/sustainabilitySystem";
import { GroupingElement } from "../../models/GroupingElement";
import { deepCopy, forEachRecursive, mapRecursive } from "./NewRatingSystem.utils";
import { forEachMap, forEachReverseMap } from "./NewRatingSystemESG.util";
import {
  calculateGroupingMaxScore,
  conditionIsIndicatorIncluded,
  conditionNotIndicator,
  conditionNotIndicatorIncluded,
  setIndicatorsWeighting,
  setProportionalWeights,
} from "./NewRatingSystemESG.validation";

export const processSystemsTree = (systems) => {
  const systemsTree = [];
  const systemSource = {};
  const systemName = {};
  const systemVersion = {};

  systems.forEach((item) => {
    const systemKey = item.systemSource;
    if (!systemSource[systemKey]) {
      const system = { name: item.systemSource, text: item.systemSource, items: [] };
      systemSource[systemKey] = system;
      systemsTree.push(system);
    }
    const nameKey = systemKey + "_" + item.name;
    if (!systemName[nameKey]) {
      const name = { name: nameKey, text: item.name, items: [] };
      systemName[nameKey] = name;
      systemSource[systemKey].items.push(name);
    }
    const versionKey = nameKey + "_" + item.systemVersion;
    if (!systemVersion[versionKey]) {
      const version = { name: item.id, text: item.systemVersion };
      systemVersion[versionKey] = version;
      systemName[nameKey].items.push(version);
    }
  });
  return systemsTree;
};

export const sortGroupings = (a, b) => {
  if (a.parent === b.parent) {
    if (a.type === GROUPING_TYPE_INDICATOR && b.type !== GROUPING_TYPE_INDICATOR) {
      return -1;
    }
    if (a.type !== GROUPING_TYPE_INDICATOR && b.type === GROUPING_TYPE_INDICATOR) {
      return 1;
    }
  }
  return 0;
};

export const sortKPIGroupings = (a, b) => {
  if (a.parent === b.parent) {
    if (a.type === GROUPING_TYPE_KPI && b.type !== GROUPING_TYPE_KPI) {
      return -1;
    }
    if (a.type !== GROUPING_TYPE_KPI && b.type === GROUPING_TYPE_KPI) {
      return 1;
    }
  }
  return 0;
};

export const matchDropLogic = (source, target, asChild) => {
  if (source.type === GROUPING_TYPE_CATEGORY) {
    if (asChild) {
      return target.type === GROUPING_TYPE_CATEGORY;
    } else {
      return !target.parent || target.parent.type === GROUPING_TYPE_CATEGORY;
    }
  } else if (source.type === GROUPING_TYPE_CRITERION) {
    if (asChild) {
      return target.type === GROUPING_TYPE_CATEGORY || target.type === GROUPING_TYPE_CRITERION;
    } else {
      return (
        target.parent &&
        (target.parent.type === GROUPING_TYPE_CATEGORY || target.parent.type === GROUPING_TYPE_CRITERION)
      );
    }
  }
  if (asChild) {
    return target.type === GROUPING_TYPE_CRITERION;
  } else {
    return target.parent && target.parent.type === GROUPING_TYPE_CRITERION;
  }
};

export const processMaxSystemScoreProportions = (dataItem, map, grouping, parentField, systemLogic, levelArray) => {
  const totalScore = calculateGroupingMaxScore(grouping);
  setProportionalWeights(dataItem, map, parentField, totalScore, systemLogic, levelArray);
};

export const processMaxSystemScore = (dataItem, map, parentField, levelArray) =>
  forEachMap(
    dataItem,
    map,
    parentField,
    (node) => {
      if (conditionNotIndicatorIncluded(node)) {
        let maxSystemScore = 0;
        if (node.maxSystemScoreLimit !== 0) {
          const grouping = node.children?.filter(conditionNotIndicatorIncluded);
          const indicators = node.children?.filter(conditionIsIndicatorIncluded);
          if (!grouping?.length && indicators?.length)
            maxSystemScore = indicators
              .map((item) => (item.maxSystemScore ? +item.maxSystemScore : 0))
              .reduce((sum, val) => sum + val, 0);
          else if (grouping?.length) maxSystemScore = calculateGroupingMaxScore(grouping);
        } else {
          forEachRecursive(dataItem?.children, (nodeInner) => {
            if (conditionNotIndicator(nodeInner)) nodeInner.maxSystemScore = 0;

            nodeInner.maxSystemScoreProportion = 0;
          });
        }

        node.maxSystemScore = maxSystemScore;
      }
    },
    levelArray
  );

export const processAllIndicatorsWeighting = (dataItem, map, parentField, levelArray) =>
  forEachReverseMap(
    dataItem,
    map,
    parentField,
    (node) => {
      if (conditionNotIndicatorIncluded(node)) {
        const indicators = node.children?.filter(conditionIsIndicatorIncluded);
        if (indicators?.length) setIndicatorsWeighting(indicators);
      }
      return node;
    },
    levelArray
  );

const createNullElementAwardThresholds = (elementAwardThresholds, awards) => {
  if (elementAwardThresholds == null) {
    elementAwardThresholds = {};
  }

  const elementAwardThresholdsIds = jp.paths(elementAwardThresholds, "$.*").map((path) => path[1]);
  const nonPresentIdList = awards
    .map((award) => award?.internalId?.toLowerCase())
    .filter((id) => !elementAwardThresholdsIds.includes(id));

  nonPresentIdList.forEach((id) => {
    elementAwardThresholds[id] = null;
  });

  return elementAwardThresholds;
};

export const mapSustainabilitySystemGroupings = (groupingElements, awards) =>
  mapRecursive(
    groupingElements || [],
    (node, children) => {
      return {
        UID: String(node.id || getUUID()),
        ...node,
        elementAwardThresholds: awards ? createNullElementAwardThresholds(node.elementAwardThresholds, awards) : null,
        children: [
          ...(node.indicatorElements || []).map((item) => {
            return {
              UID: item.id ? "i_" + item.id : getUUID(),
              ...item,
              indicatorName: item.name,
              name: node.name,
              elementAwardThresholds: awards
                ? createNullElementAwardThresholds(item.elementAwardThresholds, awards)
                : null,
              type: GROUPING_TYPE_INDICATOR,
            };
          }),
          ...children,
        ],
      };
    },
    "groupingElements"
  );

export const copyElement = (row, isChild) => {
  const element = new GroupingElement({ type: row.type });
  element.systemReference = row.systemReference;
  element.weightingFactor = row.weightingFactor;
  element.maxSystemScore = row.maxSystemScore;
  element.excludable = row.excludable;
  element.koValue = row.koValue;
  element.elementAwardThresholds = { ...(row.elementAwardThresholds ?? {}) };
  if (row.type === GROUPING_TYPE_INDICATOR) {
    element.name = row.name;
    element.indicatorName = row.indicatorName + (!isChild ? " (copy)" : "");
    element.unit = row.unit;
    element.visibleOnTable = row.visibleOnTable;
    element.valueType = row.valueType;
    element.valueRangeList = row.valueRangeList ? deepCopy(row.valueRangeList) : [];
    element.kpis = row.kpis ? deepCopy(row.kpis) : [];
    element.evaluationStandard = row.evaluationStandard;
    element.weighting = row.weighting;
    element.allowCustomValue = row.allowCustomValue;
  } else {
    element.name = row.name + (!isChild ? " (copy)" : "");
    element.weight = row.weight;
    element.byFactor = row.byFactor;
    element.maxSystemScoreLimit = row.maxSystemScoreLimit;
    element.sideRequirement = row.sideRequirement;
    element.children = (row.children || []).map((item) => copyElement(item, true));
  }
  return element;
};
