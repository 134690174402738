import React from "react";
import { useTranslation } from "react-i18next";
import { forEachRecursive, roundDecimal } from "../../../utils";
import { CollapsiblePanel } from "../../CollapsiblePanel/CollapsiblePanel";

import IconButton from "@mui/material/IconButton";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { PromiseSubjectState } from "react-rxjs-easy";
import { GROUPING_TYPE_CRITERION } from "../../../constants/sustainabilitySystem";
import { indicatorActionsListSubject } from "../../../hooks/action";
import { showDialog } from "../../../hooks/dialog";
import { AddActionsDialog } from "../../AddActionsDialog/AddActionsDialog";
import { ActionsBlock } from "../IndicatorElementDockBlocks/ActionsBlock";

export const GroupingElementDockESG = ({ rows, data, readOnly, onChange, awards }) => {
  const { t } = useTranslation();
  const {
    name,
    maxSystemScore,
    maxSystemScoreLimit,
    maxSystemScoreProportion,
    weightingFactor,
    koValue,
    evaluationSystemScore,
    universalScore,
    potentialEvaluationSystemScore,
    potentialUniversalScore,
  } = data || {};

  const maxScore = roundDecimal(maxSystemScoreLimit ? maxSystemScoreLimit : maxSystemScore);

  const showAddActionsDialog = (row, passThrough) => {
    const { readOnly: readOnlyShowAddAction } = passThrough || {};
    showDialog({
      className: "xlarge",
      closeOnClickOutside: !!readOnlyShowAddAction,
      getContent: (onClose) => (
        <AddActionsDialog
          row={row}
          onClose={onClose}
          readOnly={readOnlyShowAddAction}
          onChange={onActionsChange}
          grouping={rows}
          changeGrouping={onChange}
        />
      ),
    });
  };

  const onActionsChange = ({ UID, actions = [] }) => {
    const updated = [...rows];
    forEachRecursive(updated, (node) => {
      if (node.UID === UID) {
        node.actions = actions;
        return true;
      }
    });
    onChange(updated);
    indicatorActionsListSubject.next(new PromiseSubjectState(actions));
  };

  const getAwards = (elementAwardThresholds, awardsInner) => {
    const foundAwards = [];

    if (elementAwardThresholds && awardsInner && awardsInner.length > 0) {
      Object.entries(elementAwardThresholds).forEach(([uuid, threshold]) => {
        const award = awardsInner.find((a) => a.internalId === uuid);
        if (award) {
          foundAwards.push({ ...award, threshold });
        }
      });
    }
    foundAwards.sort((a, b) => a.title.localeCompare(b.title));
    return foundAwards;
  };

  const awardsThresholds = getAwards(data.elementAwardThresholds, awards);

  return (
    <div className="grouping-element-dock">
      <div className="data-block white-block">
        <h3>{name}</h3>
      </div>
      <div className="data-block gray-block">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.metrics")}</h3>}>
          <table>
            <tbody>
              <tr className="title-row">
                <td>
                  <label>{t("grouping.maxSystemScore")}</label>
                </td>
                <td>
                  <label>{t("grouping.maxSystemScoreProportion")}</label>
                </td>
              </tr>
              <tr className="data-row">
                <td>
                  <span>{maxScore}</span>
                </td>
                <td>
                  <span>{roundDecimal(maxSystemScoreProportion)}%</span>
                </td>
              </tr>
              <tr className="title-row">
                <td>
                  <label>{t("grouping.weight")}</label>
                </td>
                <td>
                  <label>{t("grouping.koValue")}</label>
                </td>
              </tr>
              <tr className="data-row">
                <td>
                  <span>{roundDecimal(weightingFactor)}</span>
                </td>
                <td>
                  <span>{koValue || ""}</span>
                </td>
              </tr>
              {awardsThresholds.length > 0 && (
                <tr className="title-row">
                  <td>
                    <label>{t("grouping.awardSide")}</label>
                  </td>
                  <td>
                    <label>{t("grouping.threshold")}</label>
                  </td>
                </tr>
              )}
              {awardsThresholds.map((award, index) => (
                <tr className="data-row" key={index}>
                  <td>
                    <span>{award.title}</span>
                  </td>
                  <td>
                    <span>{award.threshold}%</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CollapsiblePanel>
      </div>

      <div className="data-block current">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.currentESG")}</h3>}>
          <table>
            <tbody>
              <tr className="title-row">
                <td>
                  <label>{t("ratingSystem.indicator.evaluationSystemScore")}</label>
                </td>
                <td>
                  <label>{t("ratingSystem.indicator.universalScore")}</label>
                </td>
              </tr>
              <tr className="data-row">
                <td>
                  <span>{roundDecimal(evaluationSystemScore)}</span>
                </td>
                <td>
                  <span>{roundDecimal(universalScore)}%</span>
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsiblePanel>
      </div>

      <div className="data-block potential">
        <CollapsiblePanel header={<h3>{t("ratingSystem.columnGroup.potential")}</h3>}>
          <table>
            <tbody>
              <tr className="title-row">
                <td>
                  <label>{t("ratingSystem.indicator.evaluationSystemScore")}</label>
                </td>
                <td>
                  <label>{t("ratingSystem.indicator.universalScore")}</label>
                </td>
              </tr>
              <tr className="data-row">
                <td>
                  <span>{roundDecimal(potentialEvaluationSystemScore)}</span>
                </td>
                <td>
                  <span>{roundDecimal(potentialUniversalScore)}%</span>
                </td>
              </tr>
            </tbody>
          </table>
        </CollapsiblePanel>
      </div>

      {data.type === GROUPING_TYPE_CRITERION && (
        <div className="data-block">
          <CollapsiblePanel
            header={
              <h3>
                {t("ratingSystem.columnGroup.actions")}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    showAddActionsDialog(data, { readOnly });
                  }}
                  size="small"
                >
                  <DescriptionOutlinedIcon />
                </IconButton>
              </h3>
            }
          >
            <ActionsBlock row={data} readOnly={readOnly} onChange={onActionsChange} />
          </CollapsiblePanel>
        </div>
      )}
    </div>
  );
};
